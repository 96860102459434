import styled from "styled-components";
import { useState, useEffect } from "react";
import { Label } from "@material-ui/icons";

import { postTableHeaderLabel } from "api/services/chatService";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import Tooltip from "components/ui/Tooltip";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
  overflow: auto;

  display: grid;
  align-content: start;
  justify-content: start;
  align-items: center;
  gap: 10px;

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const CreateButton = styled(Button)`
  justify-self: start;
  align-self: end;
  grid-template-columns: auto;
  gap: 0;
  margin-top: 20px;
`;

const TriggerButtonContainer = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const BottomAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  ${props =>
    props.isTooltipRightAligned &&
    "transform: translateX(calc(-100% + 15px)) translateY(10%);"}
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const PayloadPreview = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 5px;
  width: 600px;
  max-height: 400px;
  overflow: auto;
  white-space: pre;
`;

const Error = styled.div`
  color: ${props => props.theme.color.error};
  max-width: 600px;
`;

const AddLabelForMLModalTrigger = ({
  isDisabled,
  rowColStrs = [],
  tableColumns,
  tablePreview,
  fileId,
  selectedCellRowColStr,
  blockId,
  pageNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(false);
    setError(null);
    setLabelName("");
  }, [isOpen]);

  let payload = {
    label: labelName,
    fileId,
    pageNumber,
    blockId,
    rowIndex: parseInt(selectedCellRowColStr?.split(",")?.[0]) || null,
    columnIndex: parseInt(selectedCellRowColStr?.split(",")?.[1]) || null,
    cellValues: [],
  };
  rowColStrs.forEach(rowColStr => {
    const [rowIndex, colIndex] = rowColStr.split(",");
    const row = tablePreview?.[rowIndex];
    const colName = tableColumns?.[colIndex];

    payload.cellValues.push(row?.[colName]);
  });

  return (
    <>
      <BottomAlignedTooltip
        title={isDisabled ? "Select a cell to label it" : "Add ML label"}
      >
        <TriggerButtonContainer
          onClick={() => setIsOpen(true)}
          isDisabled={isDisabled}
        >
          <Label />
        </TriggerButtonContainer>
      </BottomAlignedTooltip>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <select
            value={labelName}
            onChange={e => setLabelName(e.target.value)}
          >
            <option value="">-</option>
            <option value="DATA">DATA</option>
            <option value="HEADER">HEADER</option>
          </select>
          <PayloadPreview>{JSON.stringify(payload, null, 2)}</PayloadPreview>
          {error && <Error>{JSON.stringify(error)}</Error>}
          <CreateButton
            value="Add label"
            variant="highlighted"
            onClick={async () => {
              setIsLoading(true);
              const { error } = await postTableHeaderLabel({}, payload);
              setIsLoading(false);
              setError(error);

              if (!error) {
                setIsOpen(false);
              }
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddLabelForMLModalTrigger;
