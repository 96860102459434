import { getUserProfileByUserName } from "api/backend/projectServiceEndpoints";
import {
  decodeStoredJwt,
  getLoggedInUserName,
} from "api/services/authenticationService";
import { getWordDoc } from "api/services/wordDocsService";
import FileViewModal from "components/FileViewModal";
import {
  AirplaneIcon,
  FoodIcon,
  MagnifyingGlassIcon,
  MarkerIcon,
  MoneyCardIcon,
  TableIcon,
} from "components/IconsNew";
import { PencilIcon } from "components/ui/Icons";
import { SKIP_NAME } from "pages/login-my-account";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { sleep } from "utils/common";

export const EXAMPLE_CHATS = [
  {
    wordDocId: "6690e08d40697e57401d01af",
    titleSegments: [
      { text: "Review " },
      { text: "government reports", wordDocId: "6690e08d40697e57401d01af" },
      { text: " for annual borrowing" },
    ],
    icon: <MoneyCardIcon />,
    iconColor: "#4285F4",
  },
  {
    wordDocId: "669f8443e0c8e71bd651da61",
    titleSegments: [
      { text: "Pull the key terms for the bond from " },
      { text: "the OM.", wordDocId: "669f8443e0c8e71bd651da61" },
    ],
    icon: <TableIcon />,
    iconColor: "#34A853",
  },
  {
    wordDocId: "668e87fb1bba83fc07777e1c",
    titleSegments: [
      { text: "Find the start date of the Olympics using " },
      { text: "the official website", wordDocId: "668e87fb1bba83fc07777e1c" },
    ],
    icon: <MarkerIcon />,
    iconColor: "#FBBC05",
  },
  {
    wordDocId: "668e87921bba83fc07777e10",
    titleSegments: [
      { text: "Check the " },
      { text: "FAQ", wordDocId: "668e87fb1bba83fc07777e1c" },
      { text: " for info on missed payments from British Gas" },
    ],
    icon: <MagnifyingGlassIcon />,
    iconColor: "#F6831E",
  },
];

const EXAMPLE_CHATS_LOGGED_IN = [
  {
    title: "Write a blog post about the benefits of AI",
    icon: <PencilIcon style={{ fill: "#4285F4" }} />,
    iconColor: "#4285F4",
  },
  {
    title: "How do I make a perfect lasagna?",
    icon: <FoodIcon />,
    iconColor: "#34A853",
  },
  {
    title: "What can I do in London this summer?",
    icon: <AirplaneIcon />,
    iconColor: "#FBBC05",
  },
  {
    title: "Tell me a fun fact about the Roman Empire?",
    icon: <MagnifyingGlassIcon />,
    iconColor: "#F6831E",
  },
];

const Container = styled.div`
  width: 100%;
  z-index: 1;
  display: grid;
  align-content: center;
  grid-template-rows: auto auto 1fr auto;
  transition: opacity 0.5s;

  @media (max-width: 1240px) {
    padding: 0 20px;
  }
`;

const Card = styled.div`
  position: relative;
  height: 230px;
  border: 1px solid rgb(240, 244, 249);
  border-radius: 12px;
  padding: 1rem;
  background-color: rgb(240, 244, 249);
  cursor: pointer;
  transition: opacity 1s;
  min-width: 0;

  svg {
    fill: ${props => props.iconColor};
  }

  .docLink {
    color: #497ff3;
  }

  :hover {
    background: linear-gradient(176.39deg, #497ff3 39.89%, #38d0da 105.35%);
    * {
      color: white;
      fill: white;
      #plus {
        stroke: #497ff3;
      }
    }
    .docLink {
      color: white;
    }
  }

  @media (max-width: 1240px) {
    min-width: 180px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-top: 80px;
  align-items: start;
  gap: 10px;

  @media (max-width: 1240px) {
    overflow: auto;
    width: 100%;
    padding-top: 20px;
  }
`;

const CardTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  gap: 10px;
`;

const Title = styled.div`
  color: #000000;
  padding-top: 32px;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 0px;

  background: linear-gradient(88.57deg, ${COLOR1} 0%, ${COLOR2} 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SubTitle = styled.div`
  color: rgb(196, 199, 197);
  padding-bottom: 0px;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 0px;
  transition: opacity 0.5s;
`;

const FileName = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
`;

const TITLE_TEXT = "Hello there";
const SUB_TITLE_TEXT = "What can I do for you?";

const WordDocSourceItem = ({
  wordDocId = "",
  hasLoggedIn = false,
  label = "file",
}) => {
  const [wordDoc, setWordDoc] = useState(null);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId, hasLoggedIn]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
  };

  if (!wordDoc) {
    return <div>...</div>;
  }

  return (
    <span className="docLink">
      <FileViewModal
        style={{
          display: "inline",
          textDecoration: "underline",
        }}
        trigger={`${label}`}
        fileId={wordDoc?.content?.sources?.[0]?.fileId}
      />
    </span>
  );
};

const EmptyChatContent = ({
  hasLoggedIn = false,
  onAnimationFinish = () => {},
}) => {
  const tokenObj = decodeStoredJwt();
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState(null);
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(true);

  const [typedText, setTypedText] = useState("");
  const [subOpacity, setSubOpacity] = useState(0);
  const [visibleCardIndex, setVisibleCardIndex] = useState(-1);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    doFetchUserProfile();
  }, []);

  let greetText = TITLE_TEXT;
  if (userProfile?.name && userProfile?.name !== SKIP_NAME) {
    greetText = `Hello, ${userProfile?.name}`;
  }

  const doFetchUserProfile = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setUserProfile(data);
    setIsLoadingUserProfile(false);
  };

  // ANIMATIONS
  const doFillTypedText = async () => {
    await sleep(40);
    if (typedText === greetText || isLoadingUserProfile) {
      return;
    }
    const letterToAdd = greetText[typedText?.length];
    setTypedText(prev => prev + letterToAdd);
  };

  useEffect(() => {
    doFillTypedText();
  }, [typedText, isLoadingUserProfile]);

  useEffect(() => {
    if (typedText === greetText) {
      setSubOpacity(1);
    }
  }, [typedText, greetText]);

  useEffect(() => {
    if (!subOpacity) {
      return;
    }
    const id = setInterval(() => {
      setVisibleCardIndex(prev => prev + 1);
    }, 100);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [subOpacity]);

  useEffect(() => {
    if (visibleCardIndex === chatsToUse?.length) {
      clearInterval(intervalId);
      onAnimationFinish();
    }
  }, [visibleCardIndex]);
  // ^^ ANIMATIONS

  let chatsToUse = EXAMPLE_CHATS_LOGGED_IN;
  if (tokenObj?.authorities?.includes("ROLE_TRIAL_USER")) {
    chatsToUse = EXAMPLE_CHATS;
  }

  return (
    <Container style={{ opacity: isLoadingUserProfile ? 0 : 1 }}>
      <Title style={{ height: 178, paddingTop: 120, paddingBottom: 8 }}>
        {typedText}
      </Title>
      <SubTitle style={{ opacity: subOpacity }}>{SUB_TITLE_TEXT}</SubTitle>
      <Cards>
        {chatsToUse.map((chat, chatIndex) => (
          <Card
            iconColor={chat?.iconColor}
            style={{
              opacity: visibleCardIndex >= chatIndex ? 1 : 0,
            }}
            key={chat?.title}
            onClick={() => {
              if (!chat?.wordDocId) {
                navigate(`/chat?initialQuery=${chat.title}`);
                return;
              }

              navigate(`/chat?wordDocId=${chat.wordDocId}`);
            }}
          >
            <CardTitle>
              {chat?.title}
              {chat?.titleSegments?.map(segment => {
                if (segment?.wordDocId) {
                  return (
                    <WordDocSourceItem
                      wordDocId={chat?.wordDocId}
                      hasLoggedIn={hasLoggedIn}
                      label={segment?.text}
                    />
                  );
                }

                return segment?.text;
              })}
            </CardTitle>

            <div
              style={{
                position: "absolute",
                bottom: 10,
                display: "grid",
                gridTemplateColumns: "auto 1fr auto",
                width: "calc(100% - 2rem)",
                alignItems: "center",
                gap: 8,
              }}
            >
              <div />
              <div />
              <div>{chat.icon}</div>
            </div>
          </Card>
        ))}
      </Cards>
    </Container>
  );
};

export default EmptyChatContent;
