import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const Container = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const LoadingSpinner = () => (
  <Container>
    <CircularProgress thickness={8} />
  </Container>
);

export default LoadingSpinner;
