import styled from "styled-components";

const StyledTextArea = styled.textarea`
  resize: none;
  font-size: 16px;
  padding: 10px 15px;
  height: 100px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  border: 2px solid ${props => props.theme.color.closer1_5};
  font-family: "Montserrat";
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const TextArea = ({ maxLength, value, onNewInput, isDisabled, className, placeholder, onKeyDown, onFocus, onBlur }) => (
  <StyledTextArea
    maxLength={maxLength}
    className={className}
    isDisabled={isDisabled}
    value={value}
    onFocus={onFocus}
    onBlur={onBlur}
    onChange={e => onNewInput(e.target.value)}
    placeholder={placeholder}
    onKeyDown={onKeyDown}
  />
);

export default TextArea;
