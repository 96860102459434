import * as backend from "api/backend/tagsEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getTags = async (params = {}) => {
  const { data, error } = await backend.getTags(params);

  if (params?.tagIds) {
    return { data: data?.filter(tag => params?.tagIds?.includes(tag?.id)), error: getHandlingFromError(error) };
  }
  return { data, error: getHandlingFromError(error) };
};

export const getTagById = async tagId => {
  return { data: {}, error: null };
};

export const postTag = async body => {
  const { data, error } = await backend.postTag({}, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteTagById = async tagId => {
  const { data, error } = await backend.deleteTagById(tagId);
  return { data, error: getHandlingFromError(error) };
};
