import styled from "styled-components";
import { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { PictureAsPdf } from "@material-ui/icons";

import {
  CalculateIcon,
  EmailIcon,
  ExcelIcon,
  GearIcon,
  LogoInner,
  PdfIcon,
  TickIcon,
} from "components/ui/Icons";
import { getCubesFindByKey } from "api/services/projectService";
import BlackTooltip from "components/ui/BlackTooltip";

const Container = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.color.closer1};
  max-width: 250px;
  box-shadow: ${props => props.theme.shadow};

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
  cursor: default;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ProfilePic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  border: 2px solid ${props => props.theme.color.primary};
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  overflow: hidden;
`;

const ModelAndVersion = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-auto-flow: column;
  gap: 2px;
  align-content: start;
`;

const ModelName = styled.div`
  font-weight: 600;
`;

const ModelVersion = styled.a`
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.color.primary};
`;

const SourceNameAndButton = styled.div`
  grid-column: span 2;
  display: grid;
  gap: 4px;
`;

const FileNameSpan = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: auto 1fr;
  align-items: center;
  line-height: 1.2;
  gap: 8px;
  font-size: 16px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
  max-width: 250px;

  :hover {
    text-decoration: underline;
  }
`;

const VerifiedBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 4px;
  font-size: 12px;
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.primary}88;
  justify-self: end;
  /* margin-left: 42px; */

  svg {
    color: ${props => props.theme.color.primary};
  }

  :hover {
    background-color: ${props => props.theme.color.closer1_5}66;
  }
`;

const BlueTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.primary};
`;

const UnverifiedBtn = styled(VerifiedBtn)`
  border: 1px solid ${props => props.theme.color.closer2}88;
  color: ${props => props.theme.color.closer2};
  svg {
    color: ${props => props.theme.color.closer2};
  }
`;

const GreyTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.closer2};
`;

const GearIconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const InProgressGearIcon = styled(GearIcon)`
  fill: ${props => props.theme.color.in_progress};
  animation: spin 8s linear infinite, pulse 1s infinite;

  @keyframes pulse {
    0% {
      fill: ${props => props.theme.color.closer2};
    }

    50% {
      fill: ${props => props.theme.color.in_progress};
    }

    100% {
      fill: ${props => props.theme.color.closer2};
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const fileTypeToIcon = {
  PDF: <PdfIcon height="18px" />,
  EXCEL_MODEL: <ExcelIcon height="18px" />,
  CALCULATED: <CalculateIcon height="18px" style={{ fill: "#9650ff" }} />,
  EMAI: <EmailIcon height="18px" />,
};

const ReferenceCard = ({
  sourceModalMeta,
  style,
  onClickFile,
  onClickVerify,
  onClickUnverify,
  isSavingApproval,
}) => {
  const [cubeId, setCubeId] = useState(null);
  const [isTraining, setIsTraining] = useState(false);

  useEffect(() => {
    doPopulateCubeId();
  }, []);

  const doPopulateCubeId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setCubeId(data?.id);
  };

  const baseUrl = "https://hub.boltzbit.com";

  return (
    <Container
      style={style}
      onMouseDown={e => e.stopPropagation()}
      onMouseMove={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}
      isGreen={sourceModalMeta?.isApproved}
      isRed={sourceModalMeta?.isApproved === false}
    >
      <ProfilePic>
        <LogoInner height="60%" />
      </ProfilePic>
      <ModelAndVersion>
        <ModelName>Your Boltzflow Assistant</ModelName>
        <ModelVersion
          href={`${baseUrl}/cube/${cubeId}`}
          target="_blank"
          rel="noreferrer"
        >
          trained yesterday
        </ModelVersion>
        <BlackTooltip
          style={{ justifySelf: "end", gridRow: "span 2" }}
          title={isTraining ? "Training in progress" : "Start training"}
        >
          <GearIconButton onClick={() => setIsTraining(!isTraining)}>
            {isTraining ? (
              <InProgressGearIcon height="16px" />
            ) : (
              <GearIcon height="16px" />
            )}
          </GearIconButton>
        </BlackTooltip>
      </ModelAndVersion>

      <div
        style={{
          gridColumn: "span 2",
          lineHeight: 1.2,
          whiteSpace: "pre-wrap",
        }}
      >
        {sourceModalMeta?.description}
      </div>

      <SourceNameAndButton>
        <FileNameSpan onClick={onClickFile}>
          {fileTypeToIcon?.[sourceModalMeta?.fileType]}
          {sourceModalMeta?.fileName
            ?.replaceAll("_", " ")
            ?.replaceAll("-", " ")
            ?.replaceAll(".pdf", "")}
        </FileNameSpan>
        {sourceModalMeta?.isApproved ? (
          <VerifiedBtn onClick={onClickUnverify}>
            Verified
            {isSavingApproval ? (
              <CircularProgress size={16} thickness={8} />
            ) : (
              <BlueTickIcon />
            )}
          </VerifiedBtn>
        ) : (
          <UnverifiedBtn onClick={onClickVerify}>
            Unverified
            {isSavingApproval ? (
              <CircularProgress size={16} thickness={8} />
            ) : (
              <GreyTickIcon />
            )}
          </UnverifiedBtn>
        )}
      </SourceNameAndButton>
    </Container>
  );
};

export default ReferenceCard;
