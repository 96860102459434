import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/conversationsEndpoints";
import { sleep } from "utils/common";

export const getConversations = async () => {
  const { data, error } = await backend.getConversations();
  return { data, error: getHandlingFromError(error) };
};

export const getConversation = async ({ fileId }) => {
  const { data: allConversations, error } = await backend.getConversations();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const conversation = allConversations?.find(c => c.fileIds?.includes(fileId));
  return { data: conversation, error: null };
};

export const getChatAnswer = async ({ userInputString }) => {
  await sleep(500);
  return { data: { author: "AI", textContent: "I don't know, ask something else." }, error: null };
};

export const postConversation = async body => {
  const { data, error } = await backend.postConversation(body);
  return { data, error: getHandlingFromError(error) };
};

export const postConversationChat = async body => {
  const { data, error } = await backend.postConversationChat(body);
  return {
    data,
    error: getHandlingFromError(error),
  };
};

export const getChatPromptRecommendation = async params => {
  if (!params?.userInputString) {
    return { data: "", error: null };
  }

  const { data, error } = await backend.getChatPromptRecommendation(params);

  try {
    return { data: decodeURI(data), error: getHandlingFromError(error) };
  } catch {
    return { data, error: getHandlingFromError(error) };
  }
};
