import { useState } from "react";
import styled from "styled-components";
import CreateTagModal from "components/widgets/CreateTagModal";
import { useEffect } from "react";
import { getTags } from "api/services/tagsService";

const Container = styled.div`
  padding: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

const TagName = styled.div`
  padding: 5px;
  color: ${props => props.theme.color.closer2};
  background-color: ${props => props.color}55;
  cursor: pointer;
  border: 1px solid grey;

  :hover {
    background-color: lightgrey;
  }
`;

const TagsSelect = styled.select`
  min-width: 80px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const StyledCreateTagModal = styled(CreateTagModal)`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.color.closer1};
`;

const TagsEditor = ({
  isDisabled,
  tagIds = [],
  onNewTagIds,
  onClickTag = tagId => {},
  isSelectVisible = true,
  isEditingDisabled = false,
  className,
}) => {
  const [tags, setTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);

  useEffect(() => {
    doPopulateExistingTags();
  }, []);

  useEffect(() => {
    doPopulateTags();
  }, [tagIds?.length]);

  const doPopulateTags = async () => {
    if (tagIds?.length === 0) {
      setTags([]);
      return;
    }
    const { data } = await getTags({ tagIds });
    setTags(data);
  };

  const doPopulateExistingTags = async () => {
    const { data } = await getTags();
    setExistingTags(data);
  };

  const tagsOptions = existingTags?.filter(tag => !tags?.find(t => t?.id === tag?.id));

  return (
    <Container className={className} isDisabled={isDisabled}>
      {tags?.map(tag => (
        <TagName onClick={() => onClickTag(tag?.id)} color={tag?.color}>
          {tag?.name}
        </TagName>
      ))}
      {!isEditingDisabled && (
        <>
          <TagsSelect
            value=""
            isVisible={isSelectVisible}
            onChange={e => {
              const newTag = tagsOptions?.find(tag => tag?.id === e.target.value);
              onNewTagIds([...tagIds, newTag?.id]);
            }}
          >
            <option value="">-</option>
            {tagsOptions?.map(tag => (
              <option value={tag?.id}>{tag?.name}</option>
            ))}
          </TagsSelect>

          <StyledCreateTagModal
            isVisible={isSelectVisible}
            onTagCreated={newTag => {
              onNewTagIds([...tagIds, newTag?.id]);
            }}
          />
        </>
      )}
    </Container>
  );
};

export default TagsEditor;
