import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
`;

const TableOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const BlueSpan = styled.span`
  color: ${props => props.theme.color.primary};
`;

const OverlayMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 600;
  font-size: 16px;
  transform: translate(-50%, 50%);
  background-color: ${props => props.theme.color.closer0};
  padding: 8px 14px;
  box-shadow: ${props => props.theme.shadow};
`;

const TableContainer = styled.div`
  margin-top: 20px;
  overflow: auto;
  max-height: 600px;
`;

const StyledTable = styled.table`
  /* width: 100%; */
`;

const Td = styled.td`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  opacity: 0.8;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 14px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;

  position: sticky;
  top: 30px;
`;

const TableTitle = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  padding: 8px 0;
  font-weight: 600;
  background-color: ${props => props.theme.color.furthest};

  display: flex;
  gap: 8px;
  align-items: center;
`;

const PulsingDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};

  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      background-color: ${props => props.theme.color.primary};
    }

    50% {
      background-color: ${props => props.theme.color.primary}55;
    }

    100% {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;

const DummyTable = () => {
  return (
    <Container>
      <TableOverlay />
      <TableContainer>
        <TableTitle>All Records</TableTitle>
        <StyledTable>
          <tr>
            <Th>Email Subject</Th>
            <Th>Recieved At</Th>
            <Th>Column 1</Th>
            <Th>Column 2</Th>
            <Th>Column 3</Th>
            <Th>Column 4</Th>
            <Th>...</Th>
          </tr>
          <tr>
            <Td>About the Ferrari Report</Td>
            <Td>2021-07-12 12:00:00</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
          </tr>
          <tr>
            <Td>Price of Ferrari</Td>
            <Td>2021-07-12 12:00:00</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
          </tr>
          <tr>
            <Td>Re: Ferrari Report</Td>
            <Td>2021-07-12 12:00:00</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
          </tr>
          <tr>
            <Td>Re: Ferrari Report</Td>
            <Td>2021-07-12 12:00:00</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
            <Td>...</Td>
          </tr>
        </StyledTable>
      </TableContainer>
      <OverlayMessage>
        Send an email to&nbsp;
        <BlueSpan>
          {window?.location?.host?.includes("boltztest")
            ? "data@boltztest.com"
            : "data@boltzbit.io"}
        </BlueSpan>
        &nbsp;to start
      </OverlayMessage>
    </Container>
  );
};

export default DummyTable;
