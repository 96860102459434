import FileFromUrlInput from "components/FileFromUrlInput";
import { Gap } from "components/Layout";
import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 12px;
`;

const IngestWebpageModal = ({ trigger = "open" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{trigger}</div>
      <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <div>Webpage</div>
          <Gap />
          <FileFromUrlInput />
        </ModalContent>
      </Modal>
    </>
  );
};

export default IngestWebpageModal;
