import { useState } from "react";
import styled from "styled-components";

import { GroupAdd } from "@material-ui/icons";
import Modal from "components/ui/Modal";
import { CrossIcon } from "components/ui/Icons";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import { patchFileSystemUpdateAccess } from "api/services/fileSystem";
import { useEffect } from "react";
import AutocompleteTextInputDropdown from "components/ui/AutocompleteTextInputDropdown";
import { getUserGroups, getUsers } from "api/services/authenticationService";
import {
  postPipelineConfigsShare,
  postPipelineConfigsUnshare,
} from "api/backend/fileSystemEndpoints";

const ActionOption = styled.div`
  height: 30px;
  /* padding: 0 8px; */
  padding-right: 50px;
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  gap: 8px;

  :hover {
    background-color: #d9d9d9;
  }

  svg {
    height: 14px;
    width: 14px;
  }
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.closer0_5};
  padding: 20px;
  display: grid;
  gap: 10px;
  justify-items: start;
  align-content: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  min-width: 400px;
  white-space: pre;
  min-height: 300px;
`;

const FileName = styled.div`
  font-size: 18px;
  padding-bottom: 20px;
`;

const Contributor = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 8px 12px;
  width: 100%;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}

  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  gap: 10px;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.closer2}22,
    ${props => props.theme.color.closer2}22
  );
`;

const GreySpan = styled.span`
  color: ${props => props.theme.color.closer2};
`;

const SharedWithText = styled.div`
  font-weight: bold;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  :hover {
    background-color: ${props => props.theme.color.furthest};
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const ContirbutorsContainer = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

const ErrorMsg = styled.div`
  color: ${props => props.theme.color.error};
`;

const InputAndAddButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const StyledButtonCreateNewAsset = styled(ButtonCreateNewAsset)`
  width: 32px;
  height: 32px;
  justify-self: end;
  background-color: ${props => props.theme.color.closer0};
  border: 2px solid ${props => props.theme.color.closer1};
`;

const SharePipelineModalTrigger = ({
  pipelineConfig = {},
  trigger = (
    <ActionOption>
      <GroupAdd /> Share
    </ActionOption>
  ),
  pipelineId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sharedWith, setSharedWith] = useState(
    pipelineConfig?.sharedWith || []
  );
  const [newUserGroupID, setNewUserGroupID] = useState("");
  const [idPatching, setIdPatching] = useState(null);
  const [error, setError] = useState(null);
  const [userNamesAndGroupNames, setUserNamesAndGroupNames] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    doPopulateUserNamesAndGroupNames();
  }, [isOpen]);

  useEffect(() => {
    setSharedWith(pipelineConfig?.sharedWith);
  }, [pipelineConfig?.sharedWith]);

  const doPatchUpdateAccess = async ({ userGroupID, accessLevel }) => {
    if (userGroupID === "") {
      return;
    }
    setIdPatching(userGroupID);
    const { data, error } = await postPipelineConfigsShare(
      pipelineId,
      {},
      {
        userGroupID,
        accessLevel,
      }
    );

    setSharedWith(data.sharedWith || sharedWith);
    setError(error);
    setIdPatching(null);
  };

  const doUnshare = async userGroupID => {
    if (userGroupID === "") {
      return;
    }
    setIdPatching(userGroupID);
    const { data, error } = await postPipelineConfigsUnshare(
      pipelineId,
      {},
      {
        userGroupID,
      }
    );

    setSharedWith(data.sharedWith || sharedWith);
    setError(error);
    setIdPatching(null);
  };

  const doPopulateUserNamesAndGroupNames = async () => {
    const { data: userGroups } = await getUserGroups();
    const { data: users } = await getUsers();
    const allNames = [
      ...userGroups?.map(group => group?.name),
      ...users?.map(user => user?.userName),
    ];
    setUserNamesAndGroupNames(allNames);
  };

  return (
    <>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <FileName>
            Share <BoldSpan>{pipelineConfig?.fileName}</BoldSpan>
          </FileName>
          <SharedWithText>Add people:</SharedWithText>
          <InputAndAddButton>
            <AutocompleteTextInputDropdown
              inputStyle={{
                padding: "4px 12px",
              }}
              placeholder="Username or group"
              value={newUserGroupID}
              onChangeValue={newValue => setNewUserGroupID(newValue)}
              options={userNamesAndGroupNames}
              onPressEnterOnSelection={newValue => {
                doPatchUpdateAccess({
                  userGroupID: newValue,
                  accessLevel: "CONTRIBUTOR",
                });
                setNewUserGroupID("");
              }}
            />
            <StyledButtonCreateNewAsset
              onClick={() => {
                doPatchUpdateAccess({
                  userGroupID: newUserGroupID,
                  accessLevel: "CONTRIBUTOR",
                });
                setNewUserGroupID("");
              }}
            />
          </InputAndAddButton>

          <ContirbutorsContainer>
            {sharedWith?.map(contributor => (
              <Contributor
                key={contributor.userGroupID}
                isDisabled={idPatching === contributor?.userGroupID}
              >
                {contributor?.userGroupID}
                <GreySpan>{contributor?.accessLevel}</GreySpan>
                <StyledCrossIcon
                  onClick={() => doUnshare(contributor?.userGroupID)}
                  isHidden={contributor?.accessLevel === "OWNER"}
                />
              </Contributor>
            ))}
          </ContirbutorsContainer>
          {error && <ErrorMsg>{JSON.stringify(error)}</ErrorMsg>}
        </ModalContent>
      </Modal>
      <div onClick={() => setIsOpen(true)}>{trigger}</div>
    </>
  );
};

export default SharePipelineModalTrigger;
