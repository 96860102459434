import styled from "styled-components";
import { useState } from "react";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SelectInput from "components/ui/SelectInput";
import { useEffect } from "react";
import { Loyalty } from "@material-ui/icons";
import Tooltip from "components/ui/Tooltip";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
  overflow: auto;

  display: grid;
  align-content: start;
  justify-content: start;
  align-items: center;
  gap: 10px;
  grid-template-columns: auto 1fr;
`;

const SelectedValue = styled.div`
  width: 200px;
  overflow: auto;
  font-size: 20px;
  color: ${props => props.theme.color.primary};
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
  grid-column: span 2;
`;

const ExampleText = styled.div``;

const NameText = styled.div``;

const NameInput = styled(TextInputSquare)`
  label {
    padding: 5px 15px;
  }
`;

const CreateButton = styled(Button)`
  justify-self: start;
  align-self: end;
  grid-template-columns: auto;
  gap: 0;
  margin-top: 20px;
`;

const ValueTypeText = styled.div``;

const ValueTypeInput = styled(SelectInput)``;

const TriggerButtonContainer = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const BottomLeftAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const AddLabelModalTrigger = ({
  isDisabled,
  selectedStr,
  onPartialAnnotationAdded = ({ content, labelProperties }) => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [valueType, setValueType] = useState("TEXT");

  useEffect(() => {
    if (isOpen) {
      setLabelName("");
      setValueType("TEXT");
    }
  }, [isOpen]);

  return (
    <>
      <BottomLeftAlignedTooltip title={isDisabled ? "Select text to label it" : "Add label"}>
        <TriggerButtonContainer
          onClick={e => {
            e.preventDefault();
            setIsOpen(true);
          }}
          isDisabled={isDisabled}
        >
          <Loyalty />
        </TriggerButtonContainer>
      </BottomLeftAlignedTooltip>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Add label</Title>
          <ExampleText>Selected</ExampleText>
          <SelectedValue>{selectedStr}</SelectedValue>
          <ValueTypeText>Value Type</ValueTypeText>
          <ValueTypeInput value={valueType} onSetNewValue={newValueType => setValueType(newValueType)}>
            <option>TEXT</option>
            <option value="NUMERICAL">NUMBER</option>
            <option>DATE</option>
            <option>CURRENCY</option>
          </ValueTypeInput>

          <NameText>Label</NameText>
          <NameInput value={labelName} onNewInput={newLabel => setLabelName(newLabel)} />

          <CreateButton
            isDisabled={!labelName || !selectedStr}
            value="Add label"
            variant="highlighted"
            onClick={() => {
              const partialAnnotation = {
                content: selectedStr,
                labelProperties: JSON.stringify([
                  { name: "Label name", value: labelName },
                  { name: "Value type", value: valueType },
                ]),
              };
              onPartialAnnotationAdded(partialAnnotation);
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddLabelModalTrigger;
