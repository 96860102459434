import { useEffect, useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import TooltipNew from "components/ui/TooltipNew";
import { getFilePageResults } from "api/services/filesService";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import ButtonWord from "components/ui/ButtonWord";
import { uuidv4 } from "utils/common";
import {
  getBlockTextSegments,
  getSegments,
  isListBlock,
} from "pages/chat-docs/:wordDocId";
import { Block } from "@material-ui/icons";
import { PdfIcon, RoundTickIcon } from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";

export const useIsDebug = (initialValue = false) => {
  const [isDebug, setIsDebug] = useState(initialValue);

  useEffect(() => {
    const toggleDebug = e => {
      if (e.key === "D" && e.shiftKey) {
        setIsDebug(prev => !prev);
      }
    };

    document.addEventListener("keydown", toggleDebug);
    return () => document.removeEventListener("keydown", toggleDebug);
  }, []);

  return isDebug;
};

export const getMetaId = (meta, value = "") => {
  const refCoords = meta?.references
    ?.map(ref => `${ref?.x}-${ref?.y}`)
    ?.join("-");
  return `${meta?.fileId}-${meta?.pageNumber}-${refCoords}-${value}`;
};

const Span = styled.span`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none; 
    opacity: 0.6;
    svg {
      fill: #9a9a9a;
    }  
  `}
`;

const ModalContent = styled.div`
  padding-left: 0px;
  padding-top: 0px;
  border-radius: 24px;
  background-color: white;
  width: 1000px;
  display: grid;
  grid-template-columns: 300px 1fr;

  align-content: start;
  white-space: pre-wrap;
  overflow: auto;

  @media (max-width: 1240px) {
    width: 90vw;
    height: auto;
    font-size: 14px;
    padding: 20px;
    line-height: 1.2;
  }
`;

const FileLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  background-color: white;
  padding: 8px;
`;

const Tabs = styled.div`
  display: flex;
  overflow: auto;
`;

const Tab = styled.div`
  cursor: pointer;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props?.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer0};
  ${props => props.isSelected && `color: ${props.theme.color.primary};`}
`;

const ShortDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 200px;
`;

const BlockText = styled.div`
  padding: 20px;
  background-color: white;
  /* position: sticky;
  top: 0;
  height: 600px; */
  line-height: 1.5;
  border-right: 1px solid #eaeaea;
`;

const TitleContainer = styled.div`
  background-color: white;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 20px;
`;

const ImgWithRectangleFlexibleStyled = styled(ImgWithRectangleFlexible)``;

const HoverSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const StyledRoundTickIcon = styled(RoundTickIcon)`
  margin-left: 2px;
  fill: #00c85355;
`;

const ReferenceModalSpanWithContext = ({
  style = {},
  blocks = [{}],
  children,
  isTooltipDisabled = false,
  metas = [],
  clickedMetaId = "",
  contentHtml = "",
  isDisabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const isDebug = useIsDebug();
  const [pageResults, setPageResults] = useState(null);
  const [error, setError] = useState(null);
  const [selectedMetaId, setSelectedMetaId] = useState("");
  const [zoomFactor, setZoomFactor] = useState(1);

  const selectedMeta = metas?.find(meta => meta?.id === selectedMetaId);

  useEffect(() => {
    if (!metas?.length) {
      return;
    }

    setSelectedMetaId(metas[0]?.id);
  }, [JSON.stringify(metas)]);

  useEffect(() => {
    setZoomFactor(1);
    if (!isOpen) {
      return;
    }

    doPopulatePageResults();
  }, [isOpen, selectedMeta?.fileId, selectedMeta?.pageNumber]);

  const doPopulatePageResults = async () => {
    const { data, error } = await getFilePageResults(
      selectedMeta?.fileId,
      selectedMeta?.pageNumber ?? 0
    );
    setPageResults(data);
    setError(error);
  };

  const isMobile = window?.screen?.width < 1240;

  if (isMobile) {
    return (
      <>
        <Span
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {children}
        </Span>
        <Modal open={isOpen} handleClose={() => setOpen(false)}>
          <ModalContent>
            Reference checking only available on desktop
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      {isTooltipDisabled ? (
        <Span
          isDisabled={isDisabled}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {children}
        </Span>
      ) : (
        <TooltipNew style={style} tipText="Check the source">
          <Span onClick={() => setOpen(true)}>{children}</Span>
        </TooltipNew>
      )}
      <Modal open={isOpen} handleClose={() => setOpen(false)}>
        <ModalContent>
          <TitleContainer
            style={{ paddingLeft: 20, borderRight: "1px solid #eaeaea" }}
          >
            Content
          </TitleContainer>
          <TitleContainer style={{ paddingLeft: 20 }}>Sources</TitleContainer>
          {contentHtml && (
            <div
              style={{ paddingLeft: 20 }}
              dangerouslySetInnerHTML={{ __html: contentHtml }}
            />
          )}
          {!contentHtml && (
            <BlockText>
              {blocks?.map(block => {
                const segments = getSegments(block);
                return segments?.map(segment => {
                  if (segment?.metas || segment?.meta) {
                    let headMeta = segment?.meta || segment?.metas?.[0];
                    if (headMeta?.id !== clickedMetaId) {
                      return null;
                    }

                    return (
                      <span>
                        <StyledRoundTickIcon
                          style={
                            segment?.isWholeBlockMeta
                              ? { fill: "#9650ff44" }
                              : {}
                          }
                          height="12px"
                        />
                      </span>
                    );
                  }

                  return (
                    <span
                      style={{
                        fontWeight: segment?.isBold ? 600 : 400,
                      }}
                    >
                      {segment?.text}
                    </span>
                  );
                });
              })}
            </BlockText>
          )}
          {/* <BlockText>{blocks?.map(block => block?.text)?.join("\n")}</BlockText> */}
          {/* {isDebug && JSON.stringify({ selectedMeta, error }, null, 2)} */}
          <div>
            <div style={{ backgroundColor: "white" }}>
              <FileLink
                style={{ position: "absolute", top: 120, right: 0 }}
                href={`/files/${selectedMeta?.fileId}?pageNumber=${selectedMeta?.pageNumber}`}
                target="_blank"
              >
                <PdfIcon />
              </FileLink>
              <Tabs>
                {metas?.map(meta => (
                  <Tab
                    isSelected={selectedMetaId === meta?.id}
                    onClick={() => setSelectedMetaId(meta?.id)}
                    key={meta?.id}
                  >
                    {/* <ShortDiv style={{ backgroundColor: "#fff70088" }}>
                      {meta?.value}
                    </ShortDiv> */}
                    <ShortDiv>{meta?.fileName}</ShortDiv>
                    (page {meta?.pageNumber + 1})
                  </Tab>
                ))}
              </Tabs>
            </div>

            <div
              style={{
                // margin: "0px 0px",
                padding: "40px",
                height: "500px",
                overflow: "auto",
                backgroundColor: "#eaeaea",
                border: "1px solid #eaeaea",
                borderRadius: "0",
                width: 700,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  zIndex: 10000,
                  transform: "translate(-35px, -35px)",
                  display: "grid",
                  gridTemplateColumns: "auto auto 1fr",
                  width: 685,
                  gap: 4,
                  // top: 0,
                  // left: 0,
                }}
              >
                <ButtonWord
                  style={{
                    padding: "0",
                    width: 14,
                    height: 14,
                    lineHeight: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setZoomFactor(prev => Math.max(prev - 0.5, 1))}
                >
                  -
                </ButtonWord>
                <ButtonWord
                  style={{
                    padding: "0",
                    width: 14,
                    height: 14,
                    lineHeight: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    setZoomFactor(prev => Math.min(prev + 0.5, 10))
                  }
                >
                  +
                </ButtonWord>

                <a
                  style={{
                    justifySelf: "end",
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`/files/${selectedMeta?.fileId}?pageNumber=${selectedMeta?.pageNumber}`}
                >
                  View file
                  <PdfIcon />
                </a>
              </div>
              <ImgWithRectangleFlexibleStyled
                zoomFactor={zoomFactor}
                isLoading={!pageResults?.image}
                rectangles={selectedMeta?.references}
                src={`data:image/png;base64,${pageResults?.image || ""}`}
                alt="page preview"
              />
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferenceModalSpanWithContext;
