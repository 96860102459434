// import { createGlobalStyle } from "styled-components";

// import MontserratBold from "fonts/Montserrat-Bold.ttf";
// import MontserratRegular from "fonts/Montserrat-Regular.ttf";
// import MontserratSemiBold from "fonts/Montserrat-SemiBold.ttf";

// const Fonts = createGlobalStyle`
//   @font-face {
//     font-family: "Montserrat";
//     src: url(${MontserratBold}) format("truetype");
//     font-weight: 700;
//   }

//   @font-face {
//     font-family: "Montserrat";
//     src: url(${MontserratSemiBold}) format("truetype");
//     font-weight: 600;
//   }

//   @font-face {
//     font-family: "Montserrat";
//     src: url(${MontserratRegular}) format("truetype");
//     font-weight: 400;
//   }

//   body {
//     font-family: "Montserrat", sans-serif;
//     font-weight: 400;
//     color: ${props => props.textColor};
//     font-size: 14px;
//   }
// `;

// export default Fonts;

import { createGlobalStyle } from "styled-components";

import MontserratVariable from "fonts/Montserrat-VariableFont_wght.ttf";

const Fonts = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratVariable}) format("woff2-variations");
    font-weight: 100 900;
    font-size: 14px;
  }

  body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: ${props => props.textColor};
    font-size: 14px;
  }
`;

export default Fonts;
