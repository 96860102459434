import styled from "styled-components";
import { PlusIcon } from "components/ui/Icons";

const ButtonSquareContainer = styled.button`
  position: relative;
  font-size: 14px;
  width: 41px;
  height: 41px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  font-family: "Montserrat";
  transition: background-color 0.1s, color 0.1s;
  font-weight: 600;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  border: 2px solid ${props => props.theme.color.closer0};

  ${props =>
    props.variant === "highlighted" &&
    `
    border: 2px solid;
    border-image: linear-gradient(
      to right,
      ${props.theme.color.feature},
      ${props.theme.color.primary}
    ) 1;
  `};

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  svg {
    fill: ${props => props.theme.color.closest};
  }

  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.primary};
    ${props =>
      props.variant === "highlighted" &&
      `background: linear-gradient(
      to right,
      ${props.theme.color.feature},
      ${props.theme.color.primary}
    );`}

    ${props => props.variant !== "highlighted" && `border: 2px solid ${props.theme.color.primary}`};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const CornerRect = styled.div`
  width: 2px;
  height: 2px;
  position: absolute;
  background-color: ${props => props.theme.color.primary};
`;

const ButtonCreateNewAsset = ({ isDisabled, onClick, dataTutorialId, className, icon, variant = "default" }) => {
  return (
    <ButtonSquareContainer
      isDisabled={isDisabled}
      className={className}
      variant={variant}
      onClick={onClick}
      dataTutorialId={dataTutorialId}
    >
      {variant === "default" && (
        <>
          <CornerRect style={{ top: "-2px", left: "-2px" }} />
          <CornerRect style={{ top: "-2px", right: "-2px" }} />
          <CornerRect style={{ bottom: "-2px", left: "-2px" }} />
          <CornerRect style={{ bottom: "-2px", right: "-2px" }} />
        </>
      )}

      <IconContainer>{icon || <PlusIcon height="8px" />}</IconContainer>
    </ButtonSquareContainer>
  );
};

export default ButtonCreateNewAsset;
