import { useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";

import Modal from "components/ui/Modal";
import SelectInput from "components/ui/SelectInput";
import Button from "components/ui/Button";
import FileInput from "components/inputs/FileInput";
import { postExtractSigmaConfigsFromFile } from "api/services/sigmaService";
import LoadingSpinner from "components/ui/LoadingSpinner";
import TextInputSquare from "components/inputs/TextInputSquare";

const TrggerLink = styled.span`
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  text-decoration: underline;
  line-height: 1.5;
`;

const ModalContent = styled.div`
  padding: 20px;
  width: 850px;
`;

const ExcelFileInputContainer = styled.div`
  grid-area: excel-file-input;
  display: flex;
  gap: 20px;
  align-items: end;
  margin-bottom: 20px;
`;

const LoadingContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  justify-content: start;
`;

const SheetNameSelect = styled(SelectInput)``;

const NextButton = styled(Button)``;

const PopulateColumnsFromExcelModal = ({ onNewSigmaConfigs }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [sheetNamesOptions, setSheetNamesOptions] = useState([]);
  const [selectedSheetName, setSelectedSheetName] = useState("");
  const [columnRangeStr, setColumnRangeStr] = useState("");

  const [excelFile, setExcelFile] = useState(null);
  const [isUploadingExcel, setIsUploadingExcel] = useState(false);

  return (
    <>
      <TrggerLink onClick={() => setIsOpen(true)}>import from Excel</TrggerLink>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ExcelFileInputContainer>
            <FileInput
              isDisabled={isUploadingExcel}
              title="Table columns file (.xlsx)"
              buttonText="Choose excel file"
              onFileSelect={async file => {
                setExcelFile(file);

                const excelFileArrayBuffer = await file.arrayBuffer();
                const result = XLSX.read(excelFileArrayBuffer);

                setSheetNamesOptions(result?.SheetNames);
                setSelectedSheetName(result?.SheetNames?.[0]);
              }}
            />
            {sheetNamesOptions?.length > 0 && (
              <>
                <SheetNameSelect
                  titleAbove="Column names sheet"
                  value={selectedSheetName}
                  onSetNewValue={newSheetName => setSelectedSheetName(newSheetName)}
                >
                  {sheetNamesOptions?.map(sheetName => (
                    <option value={sheetName}>{sheetName}</option>
                  ))}
                </SheetNameSelect>
                <TextInputSquare
                  titleAbove="Column names range"
                  placeholder="B1:F1"
                  value={columnRangeStr}
                  onNewInput={newColumnRangeStr => setColumnRangeStr(newColumnRangeStr)}
                />
              </>
            )}
          </ExcelFileInputContainer>

          <LoadingContainer>
            {excelFile && (
              <NextButton
                isDisabled={isUploadingExcel}
                value="Import columns"
                onClick={async () => {
                  setIsUploadingExcel(true);
                  const params = { sheetName: selectedSheetName };
                  if (columnRangeStr) {
                    params.columnNamesRange = columnRangeStr;
                  }

                  const { data } = await postExtractSigmaConfigsFromFile(params, excelFile);
                  onNewSigmaConfigs(data?.sigma_configs);
                  setIsUploadingExcel(false);
                  setIsOpen(false);
                }}
              />
            )}
            {isUploadingExcel && <LoadingSpinner />}
          </LoadingContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PopulateColumnsFromExcelModal;
