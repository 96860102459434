import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/searchServiceEndpoints";
import { parseJson, sleep, uuidv4 } from "utils/common";
import { shuffle, uniq } from "lodash";
import { addStyle } from "utils/word-utils";
import { getTableBlocks } from "api/services/wordDocsService";

export const getTextSearchResults = async query => {
  const { data, error } = await backend.getTextSearchResults({
    query,
    start: 0,
    rows: 10,
  });
  return { data, error: getHandlingFromError(error) };
};

export const getTableSearchResults = async query => {
  return { data: [], error: getHandlingFromError(null) };
};

export const getSmartSearchResults = async query => {
  const { data: records, error } = await backend.getSmartSearchResults({
    query,
  });
  return { data: records, error: getHandlingFromError(error) };
};

export const getSmartRecords = async params => {
  const { data, error } = await backend.getSmartSearchResults(params);
  return { data, error: getHandlingFromError(error) };
};

export const getSmartRecordsV2 = async params => {
  if (!params?.columnName || !params?.columnValue) {
    return { data: [], error: getHandlingFromError(null) };
  }
  const { data, error } = await backend.getSmartSearchResultsV2(params);
  return { data, error: getHandlingFromError(error) };
};

export const getSmartSearchSingleTableResults = async query => {
  const { data, error } = await backend.getSmartSearchSingleTableResults({
    query,
  });
  return { data, error: getHandlingFromError(error) };
};

export const getTablesResultsByValue = async params => {
  const { data, error } = await backend.getTablesResultsByValue(params);
  return { data, error: getHandlingFromError(error) };
};

export const getMetaRecordByFileId = async fileId => {
  const { data, error } = await backend.getMetaRecordByFileId(fileId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  try {
    let dataWithParsedJson = { ...data };
    dataWithParsedJson.jsonKey = JSON.parse(data?.jsonKey);
    return { data: dataWithParsedJson, error: getHandlingFromError(error) };
  } catch {
    return { data, error: getHandlingFromError(error) };
  }
};

export const patchMetaRecord = async (recordId, fieldsToUpdate) => {
  const { data, error } = await backend.patchMetaRecord(
    recordId,
    fieldsToUpdate
  );
  return { data, error: getHandlingFromError(error) };
};

const getUniqueValuesOfJsonKey = (records, key) => {
  const values = uniq(
    records
      ?.map(record => parseJson(record?.jsonKey))
      ?.map(jsonKey => jsonKey?.[key])
      ?.filter(value => !!value)
      ?.sort()
  );
  return values?.length > 0 ? values : null;
};

export const getCompaniesYearsLineItemsFromMetaRecords = async () => {
  const { data: metaRecords, error } = await backend.getMetaRecords();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const { data: tableLineItems } = await getTableLineItems();

  const data = {
    companies: getUniqueValuesOfJsonKey(metaRecords, "company"),
    years: getUniqueValuesOfJsonKey(metaRecords, "year"),
    lineItems: tableLineItems?.map(
      tableLineItem => tableLineItem?.humanLineItemName
    ),
  };

  return { data, error: getHandlingFromError(error) };
};

export const getRecommendationsForQuery = async query => {
  const { data, error } = await backend.getRecommendationsForQuery({ query });
  return { data, error: getHandlingFromError(error) };
};

export const getKeywordRecommendation = async (
  params = {},
  abortController = {}
) => {
  const { data, error } = await backend.getKeywordRecommendation(
    params,
    abortController
  );
  return { data, error: getHandlingFromError(error) };
};

export const getKeywordRecommendationFirstCompletion = async (
  params = {},
  abortController = {}
) => {
  const { data, error } = await backend.getKeywordRecommendation(
    params,
    abortController
  );

  // const firstSuggestion = Object?.values(data || {})?.[0]?.[0];
  const completion = data?.replace(params?.query, "");

  return { data: completion, error: getHandlingFromError(error) };
};

export const getIntentionRecordsFromEmails = async () => {
  const { data, error } = await backend.getSmartRecordsForAxesEmails();
  return { data, error: getHandlingFromError(error) };
};

export const getSearchWithinDocument = async (fileId, query) => {
  if (!fileId || !query) {
    return { data: null, error: getHandlingFromError(null) };
  }
  const { data, error } = await backend.getSearchWithinDocument(fileId, {
    query,
  });
  if (data?.pageNumbers) {
    return {
      data: {
        ...data,
        pageNumbers: uniq(data?.pageNumbers).sort((a, b) => a - b),
      },
      error: getHandlingFromError(error),
    };
  }

  return { data, error: getHandlingFromError(error) };
};

export const getTableLineItems = async () => {
  const { data, error } = await backend.getTableLineItems();
  return { data, error: getHandlingFromError(error) };
};

export const postTableLineItems = async tableLineItems => {
  const { data, error } = await backend.postTableLineItems(
    null,
    tableLineItems
  );
  return { data, error: getHandlingFromError(error) };
};

export const getEntities = async params => {
  const { data, error } = await backend.getEntities(params);
  return { data, error: getHandlingFromError(error) };
};

export const deleteEntity = async id => {
  const { data, error } = await backend.deleteEntity(id);
  return { data, error: getHandlingFromError(error) };
};

export const postEntities = async body => {
  const { data, error } = await backend.postEntities({}, body);
  return { data, error: getHandlingFromError(error) };
};

export const getChatSearchResults = async params => {
  let { data, error } = await backend.getChatSearchResults(params);

  if (data?.content?.length > 0) {
    const newContent = [];
    data.content.forEach(block => {
      // block.text?.split("\n").forEach(text => {
      //   newContent.push({
      //     text,
      //     styles: block?.styles,
      //   });
      // });
      if (block?.tableContent) {
        const tableBlocks = getTableBlocks(block.tableContent);
        const tableBlocksWithMetaStyles = tableBlocks.map(tableBlock => {
          const { meta } = block;
          const newTableBlock = { ...tableBlock };
          if (meta) {
            newTableBlock.styles = addStyle(
              newTableBlock?.styles || [],
              { start: 0, end: newTableBlock?.text?.length, meta },
              block?.text?.length || 0
            );
          }

          return newTableBlock;
        });
        newContent.push(...tableBlocksWithMetaStyles);
      }

      const { meta } = block;
      if (meta) {
        block.styles = addStyle(
          block?.styles,
          { start: 0, end: block?.text?.length, meta },
          block?.text?.length || 0
        );
      }

      delete block.meta;
      newContent.push(block);
      newContent.push({
        text: "",
      });
    });
    data.content = newContent;
  }

  return { data, error: getHandlingFromError(error) };
};

export const getSmartRecord = async recordId => {
  const { data, error } = await backend.getSmartRecord(recordId);
  return { data, error: getHandlingFromError(error) };
};

export const patchSmartRecord = async (recordId, fieldsToUpdate) => {
  const { data, error } = await backend.patchSmartRecord(
    recordId,
    fieldsToUpdate
  );
  return { data, error: getHandlingFromError(error) };
};

export const getEntitesWithContext = async params => {
  const reqParams = {};
  if (params?.fileId) {
    reqParams.fileId = params.fileId;
  }
  if (params?.type) {
    reqParams.type = params.type;
  }
  const { data, error } = await backend.getEntities(reqParams);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  let entitiesWithContext = data;
  if (params?.context) {
    entitiesWithContext = data?.filter(entity => {
      const valueJson = parseJson(entity?.value?.[0]);
      return valueJson?.context === params.context;
    });
  }

  return { data: entitiesWithContext, error: getHandlingFromError(null) };
};

const SEARCH_RESULTS = {
  links: [
    {
      id: "1",
      fileName: "Tesla",
      url: "https://www.tesla.com/",
      description:
        "Tesla is accelerating the world's transition to sustainable energy with electric cars, solar and integrated renewable energy solutions for homes and businesses.",
      type: "WEB",
      metaTags: [
        { key: "industry", value: "Automotive" },
        { key: "industry", value: "Tech" },
      ],
    },
    {
      id: "2",
      fileName: "Apple",
      url: "https://www.apple.com/",
      description:
        "Apple Inc. is an American multinational technology company headquartered in Cupertino, California, that designs, develops, and sells consumer electronics, computer software, and online services.",
      type: "WEB",
      metaTags: [{ key: "industry", value: "Tech" }],
    },
    {
      id: "3",
      fileName: "Notion",
      url: "https://www.notion.so/",
      description:
        "A new tool that blends your everyday work apps into one. It's the all-in-one workspace for you and your team.",
      type: "WEB",
      metaTags: [{ key: "industry", value: "Tech" }],
    },
    {
      id: "4",
      fileName: "HSBC Annual Report",
      url: "https://www.hsbc.com/-/files/hsbc/investors/hsbc-results/2022/annual/pdfs/hsbc-holdings-plc/230221-annual-report-and-accounts-2022.pdf",
      description: "HSBC Holdings plc Annual Report and Accounts 2020",
      type: "WEB",
      metaTags: [{ key: "company", value: "HSBC" }],
    },
    {
      directoryPath: "/source-files",
      originalName: "small-report.pdf",
      fileName: "small-report (1).pdf",
      numberOfPages: 4,
      sizeKb: 186,
      domain: "boltzflow",
      status: "DONE",
      progress: 100,
      id: "64e88d521f406f33c301a52d",
      ingestedFileId: "64e88d521f406f33c301a52d",
      sharedWith: [
        {
          userGroupId: "test-user",
          accessLevel: "OWNER",
        },
      ],
      createdAt: "Aug 25, 2023 11:15:31 AM",
      createdBy: "test-user",
      type: "FILE",
      description: "Small financial report",
      metaTags: [{ key: "company", value: "HSBC" }],
    },
  ],
};

export const postChatflowGenerate = async (params, body, abortController) => {
  // if (body?.searchBarQuery) {
  //   await sleep(1000);
  //   return {
  //     data: {
  //       links: shuffle(SEARCH_RESULTS?.links),
  //     },
  //     error: null,
  //   };
  // }
  const { data, error } = await backend.postChatflowGenerate(
    params,
    body,
    abortController
  );
  return { data, error: getHandlingFromError(error) };
};

export const postIngestLink = async (params, body) => {
  await sleep(1000);
  return { data: uuidv4(), error: null };
};

export const postChatflowSearch = async (params = {}, body = {}) => {
  const { data, error } = await backend.postChatflowSearch(params, body);
  return { data, error: getHandlingFromError(error) };
};
