import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import PageComponentOnRoute from "routes";
import CssReset from "global-styles/css-reset";
import Fonts from "global-styles/fonts";
import ThemeSwitch from "components/ui/ThemeSwitch";
import { useState, useEffect } from "react";

export const lightTheme = {
  name: "light",
  // ms-shadow: 0 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12);
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.3)",
  shadowWeak: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  shadowHover: "1px 1px 4px rgba(0, 0, 0, 0.2)",
  color: {
    furthest: "#ffffff",
    closer0_5: "#f8f8f8",
    closer0: "#f1f1f1",
    closer1: "#e1e1e1",
    closer1_5: "#cacaca",
    closer2: "#737373",
    closest: "#24292F",
    highlightGrey: "#d7dbe0",

    in_progress: "#d4a600",
    status_done: "#00C853",

    primary: "#0191ff",
    error: "#d9534f",
    success: "#28a745",

    feature: "#9650ff",
    data: "#009933",

    closer0_hover: "#ebebeb",

    entities: {
      ORG: "#7aecec",
      PRODUCT: "#bfeeb7",
      GPE: "#feca74",
      LOC: "#ff9561",
      PERSON: "#aa9cfc",
      NORP: "#c887fb",
      FAC: "#9cc9cc",
      EVENT: "#ffeb80",
      LAW: "#ff8197",
      LANGUAGE: "#ff8197",
      WORK_OF_ART: "#f0d0ff",
      DATE: "#bfe1d9",
      TIME: "#bfe1d9",
      MONEY: "#e4e7d2",
      QUANTITY: "#e4e7d2",
      ORDINAL: "#e4e7d2",
      CARDINAL: "#e4e7d2",
      PERCENT: "#e4e7d2",
    },
  },
};

export const darkTheme = {
  name: "dark",
  color: {
    furthest: "#0c0e12",
    closer0_5: "#1f232a",
    closer0: "#252a32",
    closer1: "#363b44",
    closer1_5: "#49566f",
    closer2: "#a8a8a8",
    closest: "#e8e8e8",
    highlightGrey: "#455c87",

    in_progress: "#d4a600",
    status_done: "#00C853",

    primary: "#0191ff",
    error: "#d9534f",
    success: "#a4b9a8",

    feature: "#9650ff",
    data: "#009933",
  },
};

export const chartColours = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
  "#aec7e8",
  "#348686",
  "#c02d2d",
  "#25e092",
];

const PositionedThemeSwitch = styled(ThemeSwitch)`
  position: fixed;
  top: 35px;
  transform: translateY(-50%);
  right: 185px;
  z-index: 13;
`;

const App = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  useEffect(() => {
    const themeName = localStorage.getItem("theme");
    const isDarkTheme = themeName === "dark";
    setIsDarkTheme(false);
  }, []);

  const theme = isDarkTheme ? darkTheme : lightTheme;

  return (
    <BrowserRouter>
      <CssReset bgColor={theme.color.furthest} />
      <Fonts textColor={theme.color.closest} />
      <ThemeProvider theme={theme}>
        {/* <PositionedThemeSwitch
          onSwitch={() => {
            localStorage.setItem("theme", isDarkTheme ? "light" : "dark");
            setIsDarkTheme(!isDarkTheme);
          }}
          isOnLeft={!isDarkTheme}
        /> */}
        <PageComponentOnRoute />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
