export const WIKI_TEXT = `Doom is a 2016 first-person shooter video game developed by id Software and published by Bethesda Softworks. The game is the first major installment in the Doom series since 2004's Doom 3 and was a reboot of the franchise. It was released for PlayStation 4, Windows, and Xbox One in May 2016. A port for Nintendo Switch was co-developed with Panic Button and released in November 2017, and a version for Google Stadia was released in August 2020. Players take the role of an unnamed space marine, known as the "Doom Slayer", as he battles demonic forces within an energy-mining facility on Mars and in Hell.

Doom was announced as Doom 4 in 2008, and that version underwent an extensive development cycle with different builds and designs before the game was restarted in 2011 and revealed as simply Doom in 2014. It was tested by customers who pre-ordered the 2014 MachineGames game Wolfenstein: The New Order and the general public. Mick Gordon composed the music, with contributions by Richard Devine. The game also has an online multiplayer component and a level editor known as "SnapMap", co-developed with Certain Affinity and Escalation Studios respectively.

Doom was well received by critics and players. The single-player campaign, graphics, soundtrack, and gameplay received considerable praise, whereas the multiplayer mode drew significant criticism. It was the second best-selling video game in North America and the UK in the week of its release and sold over 500,000 copies for PCs by the end of May 2016. A sequel, Doom Eternal, was released in March 2020.

Gameplay
Screenshot from Doom showing the player fighting a Baron of Hell with the Super Shotgun
The Doom Slayer fighting against a Baron of Hell with the Super Shotgun
Doom is a first-person shooter.[5] Gameplay consists of fast movement and frenetic combat against aggressive and mobile opponents, as well as exploration of the game's environments via double-jumps and ledge climbing.[6][7] To progress through the game, the player wields an arsenal of weapons influenced by those of Doom and Doom II, such as a chainsaw and BFG 9000, against undead and demonic opponents also influenced by the original Doom games. Weapons do not need to be reloaded and can be augmented with various mods obtained during the campaign.[8][9][10]

To replenish their resources, the player must pick up items or kill enemies.[11] Players may recover health by using the "Glory Kill" mechanic, in which sufficiently damaged enemies enter a stunned state and may be killed by the player in a short melee animation.[12][13] Ammunition can be replenished by using the chainsaw on an enemy, which instantly kills them if there is enough fuel in the chainsaw.[9][14]

The single-player campaign has 13 levels,[15] which typically have multiple pathways and open areas for players to explore and find collectibles, secrets, and upgrades to their equipment.[10] Other pickups include Doomguy figurines and data files that expand on the setting and story.[16] Throughout the campaign are Easter egg references to Commander Keen, The Elder Scrolls V: Skyrim, Fallout 4, Terminator 2, and the preceding Doom games.[17] Each level contains a hidden lever which opens an area extracted from a classic level in the original games.[18] An arcade mode in which all upgrades in the single-player campaign are unlocked from the beginning of the game was added on October 20, 2016.[19][20]

Multiplayer
Doom supports an online multiplayer mode.[21] Gamemodes include a basic Team Deathmatch and variations thereof called Freeze Tag, in which defeated players are frozen in ice and may be revived by teammates,[22] and Soul Harvest, in which players must pick up "souls" dropped by slain opponents;[23] Warpath, a variation of King of the Hill in which the hill moves around the map;[24] Clan Arena, a team-based last man standing mode;[25] and Domination, in which teams must capture and hold three locations.[23] Players use a loadout consisting of two weapons chosen from an arsenal of weapons that may include weapons unique to the multiplayer mode.[26][27] During matches, players may obtain "hack modules", one-use power-ups that convey information to the player, such as the time until other power-ups respawn. They may also obtain "runes" that temporarily transform them into demons.[22][28] Playable demons include the Revenant, Baron of Hell, and Mancubus, also featured in the single-player campaign,[29] and the Prowler and Harvester, which are not.[19][30]

Level creation tool
Doom includes a level creation tool called SnapMap which allows players to create and edit maps.[31] Using in-game assets,[32] players can create single-player levels and co-operative or competitive multiplayer maps. Players can place enemies into their maps, with the exception of the campaign's bosses, and modify their artificial intelligence and stats. Alternatively, SnapMap can automatically generate enemies for player-made maps with the AI conductor feature.[33] Players can share their completed maps with other players, who can rate and make derivatives of their map.[4]

Synopsis
Doom takes place on Mars, where the Union Aerospace Corporation (UAC) operates a facility to exploit an energy seeping from Hell, an alternate dimension populated by hostile lifeforms known as demons. To solve an energy crisis on Earth, the UAC constructed the Argent Tower to extract and refine raw Hell energy into Argent energy, which deteriorates the physical and mental health of the facility's staff,[34] and allowed transit to and from Hell. After discovering that Argent energy had properties that defied their understanding of physics, the UAC began occult research into Argent energy and Hell, and the demons, who its staff began to worship and cybernetically modify.

The facility is run by Samuel Hayden, a UAC scientist who transferred his consciousness to an android body after being afflicted with terminal brain cancer during the construction of the Argent Tower. In addition to directing the energy extraction and refining process, Hayden organizes expeditions into Hell to retrieve captive demons and artifacts for study. Among them is a sarcophagus containing the player character, a being they call the Doom Slayer and fear immensely, along with his armor, the Praetor Suit. Assisting Hayden is VEGA, a self-aware artificial intelligence that controls and monitors the facility. Leading the UAC's research into biomechanics is Olivia Pierce, a protege of Hayden's who developed crippling scoliosis while working on Mars and makes a pact with the demons to open a portal between Mars and Hell and allow the demons to conquer humanity.

Plot
At an undetermined time, Pierce opens the portal for the demons, who overrun the facility and kill almost all of its staff. To repel them, Hayden awakens the Slayer, who recovers his armor and initially ignores Hayden's entreaties. Denied information unless he agrees to help Hayden, the Slayer acquiesces and begins to be guided by VEGA. After clearing out the facility core and preventing a meltdown in its foundry, the Slayer pursues Pierce up the tower, along the way destroying infrastructure critical to the refinement of Argent energy despite Hayden's protests. At the top of the tower, Pierce opens an explosive rift into Hell using a device called an Argent accumulator, destroying the Tower and sending the Slayer back to Hell.

In Hell, the Slayer fights his way to the tomb in which he was imprisoned and a teleporter back to Mars. The Slayer makes his way to Hayden's office, where Hayden installs a teleporting device in the Praetor Suit for more reliable teleportation. Hayden also tells the Slayer of the Helix Stone, an artifact used to study and harness Argent energy being housed in Pierce's office in the Lazarus Labs. Entering the Lazarus Labs, the Slayer observes the Helix Stone and learns of the Well, where the portal is powered, and of the Crucible, a magical blade. To reach the Well, he makes another excursion into Hell with the Argent accumulator within the Cyberdemon, a massive and cybernetically modified demon. After killing the Cyberdemon, the Slayer fights through a labyrinthine gauntlet and two more powerful demons to recover the Crucible.

Hayden teleports the Slayer to a facility in the frozen north of Mars that houses VEGA's core, which he plans to use to trigger an explosion powerful enough to send the Slayer to the Well. The Slayer triggers a meltdown of the core, but makes a backup copy of VEGA before the explosion. Entering the Well, the Slayer uses the Crucible to destroy the portal's power source and confronts Pierce, who is betrayed and transformed by the demons into the Spider Mastermind. Upon killing the Spider Mastermind, the Slayer is teleported back to Mars by Hayden, who confiscates the Crucible to continue his research into Argent energy. To keep the Slayer from interfering with his plans, Hayden teleports him to an undisclosed location.

Development

Marty Stratton (left) and Hugo Martin (right), the game's directors
As Doom 4
After releasing Doom 3 in 2004, id Software began working on a new intellectual property, Rage, and unsuccessfully sought to license the Doom franchise to another developer as it had with Wolfenstein. In 2007, however, id began development of Doom 4. The studio, which had 19 employees at the time of Doom 3's release,[35] struggled to simultaneously develop Rage and Doom 4.[35][36] Development of Doom was first revealed via job listings on id's website on May 7, 2008, for a project titled Doom 4.[37] On June 23, 2009, ZeniMax Media, parent company of Bethesda Softworks, acquired id and announced that Bethesda would publish its future games.[38] According to id creative director Tim Willits, the partnership allowed the company to have two teams, each having a project in parallel development.[39] Asked in April 2009 about whether Doom 4 would be a sequel to Doom 3 or a reboot, id CEO Todd Hollenshead stated that it was neither.[40]

Doom 4 was intended to feature a story written by British science fiction writer Graham Joyce.[1] The game was going to be set on Earth and was described as a "new take"[41] on Doom II (subtitled Hell on Earth).[35] In a 2016 video documentary by Noclip about the game's development, Doom creative director Hugo Martin described Doom 4 as being "about the global impact of a Hellish invasion"[42] and compared it to the 1997 film Contact. The game was influenced by the Call of Duty series of first-person shooters, featuring passive health regeneration, an emphasis on using cover to protect the player character,[41] and scripted cinematics; developers and fans derided the project as "Call of Doom".[43] In 2011, Rage was released to mixed reception.[35][41] id and Bethesda, feeling that Doom 4 was out of touch with the original games, decided to restart development.[36][44][45]

On April 3, 2013, Kotaku published an exposé that described Doom 4 as being trapped in "development hell" and allegedly mismanaged.[46] Bethesda's vice president of marketing, Pete Hines, acknowledged difficulty in the development of Doom 4 that same day.[47] In an August 2013 interview with IGN, Willits said that the pre-2011 Doom 4 "had a bit of schizophrenia, a little bit of an identity crisis."[48] Marty Stratton, Doom's director, described the period between 2011 and 2013 as a "rolling reboot".[49] This period contained numerous departures from id such as Hollenshead and company co-founder John Carmack.[50][51] In an interview by Nathan Grayson of Rock, Paper, Shotgun on August 6, 2013, Willits stated that there was no publicly available timeline for updates on Doom 4.[52]

As Doom

Various developers at QuakeCon 2016. From left to right: Marty Stratton, Tiago Sousa, Billy Khan, Shale Williams, and Robert Duffy
On June 10, 2014, Bethesda presented a teaser trailer at E3 2014,[53] followed by another at id's yearly convention, QuakeCon, on July 17, 2014, that revealed Doom 4 had been renamed to Doom and would be a series reboot.[54] id selected Stratton to be game director and hired Hugo Martin as creative director in August 2013.[12][55] Tiago Sousa, head R&D graphics engineer at Crytek, led development of the id Tech 6 engine for Doom.[56] Stratton, Martin, and id used the original Doom games as their template for Doom's artwork and gameplay, and abandoned the slower pace and survival horror themes of Doom 3.[12][35] Stratton highlighted id's desire to "[be] faithful to the legacy of [the original] Doom"[57] and the replication of its tone.[35] Willits explained that Doom was "built on the emotional core of the original game".[58]

Development of Doom focused primarily on refining its combat, dubbed "push forward combat".[12] The Glory Kill mechanic, which began as a "sync melee" system to be used in Doom 4,[59][b] was developed early and became crucial to the design of Doom's combat. To incentivize player aggression, id rewarded use of the Glory Kill mechanic and chainsaw by replenishing resources and built the game's levels to encourage movement during combat.[12][60] Enemies were also designed to visually resemble those of the original Doom games and encourage the player to be mobile.[61][62]

Id placed less emphasis on Doom's story,[63] which was written by Adam Gascoine.[1] Speaking to Noclip, Martin said that the story was one of the last elements of the game to be implemented,[64] and that he and Gascoine aimed for a lighter, self-aware narrative;[65] the game begins with a quotation of a 1996 comic parodying Doom.[1][62] In his direction for Doom's story, Martin was inspired by action movies such as RoboCop (1987), Evil Dead 2 (1987),[65] and The Last Boyscout (1991),[66] and paintings by American artist Frank Frazetta.[67]

Doom's multiplayer was co-developed with Certain Affinity,[1] though id parted ways with them after launch to work on the Windows-version multiplayer and introduce new features such as private matches, custom game settings, and an enhanced cheat detection system.[2][68] BattleCry Studios assisted id with post-release multiplayer updates.[3] SnapMap was developed with Escalation Studios.[4][69] Patches for Doom after its release introduced a new photo mode, a new game option for holding weapons in the center of the screen as in the original games,[70] and support for the Vulkan API.[71] The Vulkan patch was expected to enable playable frame rates on older hardware. Subsequent benchmarks show up to a 66% improvement in the frame rates on AMD graphics cards, with minor changes in the performance of Nvidia cards.[72]

Soundtrack
Doom's soundtrack was composed by Australian musician and composer Mick Gordon,[73] with contributions by American electronic musician and sound designer Richard Devine.[74] Gordon met with id at their Dallas headquarters in mid-2014 to discuss composing music for Doom.[75][76] At their meeting, id instructed Gordon not to use guitars or write a metal score,[77][78] despite the original Doom having an ambient, thrash metal soundtrack by Bobby Prince,[76][79] as id felt that the genre had grown "corny".[76] Gordon was encouraged to use synthesizers,[80] and used them to create the sound Argent energy might make.[81] He designed several chains of effects units through which he passed sub-bass sine waves,[80][82] layered with white noise to make them audible on widely available speaker equipment.[83] According to Gordon, after "six to nine months [of] doing just synthesisers",[76] he convinced id to allow the use of guitars and began experimenting with augmenting their sound.[84] For the main riff of the main menu track, Gordon combined a nine-string guitar with a sample of the chainsaw from the original Doom.[85]

Gordon devised different soundscapes for Mars and for Hell, saying in an interview with Revolver magazine, "As the [Mars] environments were created by humans ... the music needed to sound like humans created it, too. ... That lead to Hell being more atonal, dissonant and weird."[86] Some tracks, such as "At Doom's Gate", contain homages to Prince's work for the original Doom.[84] Gordon also included Easter eggs in the soundtrack; shortly after the game's release in May 2016, players discovered pentagrams and the number "666" hidden in the track "Cyberdemon" via spectrogram.[87][88] Speaking to the Game Development Conference about composing Doom's soundtrack in 2017, Gordon revealed the presence of a reserved message, "Jesus loves you", in an unidentified track.[89] On February 7, 2019, Gordon confirmed the discovery of the final Easter egg on Twitter.[90]

Release and marketing
Doom was released worldwide for PlayStation 4, Windows, and Xbox One on May 13, 2016, except in Japan, where it was released on May 19, 2016,[91] and Google Stadia on August 19, 2020.[92] It was the first game in the franchise to be released without censorship in Germany.[93] A Nintendo Switch port developed by Panic Button was released on November 10, 2017,[94][95] without SnapMap because of storage constraints on the game cartridge.[96][97] Review copies of the game were held back until release day, which prompted comment from several gaming outlets.[98][99][100] Bethesda partnered with Arrow Schmidt Peterson Motorsports for a special promotion in which Mikhail Aleshin drove a Doom-styled car at the Indianapolis 500 racing competition.[101] The vehicle crashed during the 2016 Indianapolis 500 and was eliminated from the race.[102]

On June 12, 2016, at E3 2016 Bethesda announced a virtual reality (VR) adaptation of Doom with a demo.[103][104] The critical reception of the VR adaptation was mixed.[103][105][106] A virtual pinball adaptation of Doom was released on December 6, 2016, as part of the Bethesda Pinball collection for Zen Pinball 2, Pinball FX 2,[107] and Pinball FX 3,[108] as well as a separate free-to-play app for iOS and Android mobile devices.[109] A VR spinoff of Doom, Doom VFR, was announced at E3 2017 and was released for the PlayStation VR and HTC Vive headsets on December 1, 2017.[110][111] The game was set after the events of Doom and was generally well received by critics.[112][113][114]

Trailers and cover art
Doom's alternate cover, inspired by the original Doom's cover. The Doom Slayer is shown fighting a horde of demons in Hell atop a mound of their corpses, in a pond of magma
The alternate cover that was picked via a poll on Twitter[115]
Initial reception of the QuakeCon 2014 trailer accumulated considerable acclaim among fans.[116] At E3 2015, on June 14, Bethesda showed gameplay from the singleplayer campaign and multiplayer,[117] which was simultaneously applauded and criticized for its graphic violence.[118] Hines responded by saying, "if you're not into violent, bloody games... Doom's probably not a game for you."[119] A live-action trailer directed by American filmmaker Joe Kosinski was released on March 31, 2016.[120]

On February 4, 2016, Bethesda revealed Doom's official box art, which was immediately criticized as "painfully boring and dull."[121] From March 3, to March 7, 2016, Bethesda held a poll on Twitter to decide an alternate cover that would be printed on the obverse of the official box art.[115][122] The winner, with 68% of the votes cast, was a cover inspired by the original Doom's, showing the Doom Slayer battling demons in Hell.[123]`;
