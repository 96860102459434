import { postFlowDatabaseTemplates } from "api/backend/chatServiceEndpoints";
import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  display: grid;
  justify-items: start;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  height: 100px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  resize: none;
`;

const SaveAsTemplateModal = ({
  dashboardConfig = {},
  wordDocTemplate = {},
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [prompt, setPrompt] = useState("");

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        style={{
          position: "absolute",
          right: "240px",
        }}
      >
        Save as template
      </button>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isCreating}>
          <StyledTextArea
            onChange={e => setPrompt(e.target.value)}
            placeholder="Prompt"
          />
          <button
            disabled={!prompt}
            onClick={async () => {
              setIsCreating(true);
              await postFlowDatabaseTemplates(
                {},
                {
                  prompt,
                  dashboardConfig,
                  wordDocTemplate,
                }
              );
              setIsCreating(false);
              setIsOpen(false);
            }}
          >
            Save
          </button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SaveAsTemplateModal;
