import styled from "styled-components";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { CloudDownloadOutlined } from "@material-ui/icons";
import { getArrayWithUpsertedItem, getArrayWithUpsertedItems, getTableId } from "utils/common";
import { postDownloadExcelFileOfTableFromPageResults } from "api/services/projectService";
import TooltipEditable from "components/ui/TooltipEditable";
import Button from "components/ui/Button";
import { useEffect } from "react";
import { getTableLineItems, postTableLineItems } from "api/services/searchService";
import { postFilesRerunForFileIdForPage } from "api/services/filesService";
import { range } from "lodash";
import SmallButton from "components/ui/SmallButton";

const ALTERNATE_COLORS = "columns";

const greyBgroundIfOdd = props => {
  if (ALTERNATE_COLORS === "columns" && props.col % 2 === 0) {
    return `background-color: ${props.theme.color.closer1}55;`;
  }
};

const TableContainer = styled.div`
  overflow: auto;
  position: relative;
  padding-bottom: 36px;
`;

const IconContainer = styled.span`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}

  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const TableNameAndActions = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: "transparent";
  z-index: 10;
`;

const TableName = styled.div`
  font-weight: 600;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numCols}, auto);
  width: max-content;

  border-top: 1px solid ${props => props.theme.color.closer1};
  border-left: 1px solid ${props => props.theme.color.closer1};
`;

const FileLink = styled(Link)`
  color: ${props => props.theme.color.primary};
`;

const ColHeader = styled.div`
  font-weight: 600;
  grid-row: 1;
  padding: 10px;
  max-width: 200px;

  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  ${greyBgroundIfOdd};

  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const DataCell = styled.div`
  display: grid;
  grid-row: ${props => props.row};
  grid-column: ${props => props.col};
  max-width: 200px;

  ${props => props.isOutlined && `outline: 1px solid ${props.theme.color.primary};`}
  ${props => props.isClickable && "cursor: pointer;"}
  :hover {
    cursor: pointer;
    :hover {
      outline: 1px solid ${props => props.theme.color.primary};
    }
  }

  ${props =>
    props.isBolded &&
    `
    font-weight: 600;
    color: ${props.theme.color.primary};
  `}

  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  ${greyBgroundIfOdd}
`;

const LineItemTooltip = styled(TooltipEditable)`
  padding: 10px;
`;

const AutomaticLineItemGridContainer = styled.div`
  padding: 10px;
  display: grid;
  gap: 5px;
  grid-template-columns: auto auto;
  border: 1px dashed ${props => props.theme.color.primary};
  max-width: 300px;
`;

const LabelText = styled.div``;

const Label = styled.div`
  font-weight: 600;
`;

const LabelColorGradient = styled.div`
  font-weight: 600;
  color: ${props => `rgb(${(1 - props.score) * 255}, ${props.score * 200}, 0)`};
`;

const RowHeaderCell = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  border-right: 1px solid ${props => props.theme.color.closer1};
  grid-row: ${props => props.row};
  grid-column: ${props => props.col};
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const AutomaticLineItemGrid = ({ cellAutomaticLineItemName = "" }) => {
  const rows = cellAutomaticLineItemName?.split("\n")?.map(rowStr => rowStr?.split(":"));

  return (
    <AutomaticLineItemGridContainer>
      {rows?.map(([key, value]) => (
        <>
          <LabelText>{key}:</LabelText>
          {key === "Score" ? (
            <LabelColorGradient score={parseFloat(value)}>{value}</LabelColorGradient>
          ) : (
            <Label>{value}</Label>
          )}
        </>
      ))}
    </AutomaticLineItemGridContainer>
  );
};

const copyEntityItemAcrossRow = ({ newCellEntity, rowIndex, fileId, pageNumber, outputItemId, table }) => {
  const colNames = table?.tableColumns;

  const newItems = range(1, colNames.length + 1)?.map(itemColNameIndex => {
    const automaticLineItemMapValue = table?.automaticLineItemNameMap?.[`${rowIndex},${itemColNameIndex - 1}`];
    const cellValue = table?.tablePreview?.[rowIndex]?.[colNames?.[itemColNameIndex - 1]];

    return {
      ...newCellEntity,
      fileId,
      value: JSON.stringify({
        pageNumber: pageNumber || 0,
        outputItemId,
        automaticLineItemMapValue,
        cellValue,
      }),

      cellPageNumOutputIdRowCol: `${pageNumber || 0},${outputItemId},${rowIndex},${itemColNameIndex}`,
    };
  });

  return newItems;
};

const copyEntityItemAcrossColumn = ({ newCellEntity, colIndex, fileId, pageNumber, outputItemId, table }) => {
  const colNames = table?.tableColumns;

  const newItems = range(0, table?.tablePreview.length)?.map(itemRowIndex => {
    const automaticLineItemMapValue = table?.automaticLineItemNameMap?.[`${itemRowIndex},${colIndex - 1}`];
    const cellValue = table?.tablePreview?.[itemRowIndex]?.[colNames?.[colIndex - 1]];

    return {
      ...newCellEntity,
      fileId,
      value: JSON.stringify({
        pageNumber: pageNumber || 0,
        outputItemId,
        automaticLineItemMapValue,
        cellValue,
      }),

      cellPageNumOutputIdRowCol: `${pageNumber || 0},${outputItemId},${itemRowIndex},${colIndex}`,
    };
  });

  return newItems;
};

const TableViewerScanned = ({
  className,
  table,
  outlinedCellValue,
  textOnBoldedValueHover,
  boldedCellValue,
  onCellClick,
  isTopBarTransparent,
  outputItemId,
  entities,
  onNewEntities,
  onAddReferenceCandidate = ({ pageNumber, annotationId, annotationContent }) => {},
}) => {
  const { fileId } = useParams();
  const [searchParams] = useSearchParams();
  const pageNumber = parseInt(searchParams.get("pageNumber"));

  const [tableLineItems, setTableLineItems] = useState([]);
  const [initialTableLineItems, setInitialTableLineItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [downloadErr, setDownloadErr] = useState(null);

  const [isPostingLineItems, setIsPostingLineItems] = useState(false);

  const colNames = table?.tableColumns || [];
  const sourceDocUrl = `/file/${table?.tableDocumentLocation?.fileId}?pageNumber=${table?.tableDocumentLocation?.pageNumber}&annotationIdToHighlight=${table?.tableDocumentLocation?.annotationId}&boldedCellValue=${boldedCellValue}&textOnBoldedValueHover=${textOnBoldedValueHover}`;
  const tableId = getTableId(table);

  const isEditngSolrRecord = !!searchParams.get("solrId");

  useEffect(() => {
    doPopulateTableLineItems();
  }, []);

  const doDownloadTableFile = async () => {
    setIsLoading(true);
    const { error } = await postDownloadExcelFileOfTableFromPageResults(table);
    setDownloadErr(error);
    setIsLoading(false);
  };

  const doPopulateTableLineItems = async () => {
    const { data } = await getTableLineItems(tableId);
    setTableLineItems(data || []);
    setInitialTableLineItems(data || []);
  };

  const doPostTableLineItems = async () => {
    setIsPostingLineItems(true);
    const { data } = await postTableLineItems(tableLineItems);
    setTableLineItems(data || []);
    setInitialTableLineItems(data || []);

    await postFilesRerunForFileIdForPage(fileId, pageNumber || 0);
    window.location = `${window.location.pathname}?pageNumber=${pageNumber || 0}`;

    setIsPostingLineItems(false);
  };

  const setTableLineItemsWithNewItem = item => {
    const newTableLineItemRecords = getArrayWithUpsertedItem({
      array: tableLineItems,
      item,
      key: "automaticLineItemName",
    });
    setTableLineItems(newTableLineItemRecords);
  };

  return (
    <TableContainer className={className}>
      <TableNameAndActions isTopBarTransparent={isTopBarTransparent}>
        <TableName>{table?.tableName}</TableName>
        <IconContainer onClick={() => doDownloadTableFile(table)} isDisabled={isLoading}>
          <CloudDownloadOutlined />
        </IconContainer>
        {isEditngSolrRecord && (
          <SmallButton
            value="Add to references"
            onClick={() => {
              onAddReferenceCandidate({
                pageNumber,
                annotationId: outputItemId,
                annotationContent: table?.tableName,
              });
            }}
          />
        )}
        <div>{downloadErr && JSON.stringify(downloadErr)}</div>
        {table?.tableDocumentLocation?.fileId && (
          <FileLink to={sourceDocUrl}>
            {table?.tableDocumentLocation?.fileName || "source doc"} (p.{table?.tableDocumentLocation?.pageNumber + 1})
          </FileLink>
        )}
      </TableNameAndActions>
      <Table numCols={colNames.length + 2}>
        {["", ...colNames].map((colName, colIndex) => (
          <ColHeader key={`${colName}-colheader`} col={colIndex + 1} row={1}>
            <LineItemTooltip
              cellEntity={null}
              placement="right"
              onNewCellEntity={newCellEntity => {
                const colEntityItems = copyEntityItemAcrossColumn({
                  newCellEntity,
                  colIndex,
                  fileId,
                  pageNumber,
                  outputItemId,
                  table,
                });

                onNewEntities(
                  getArrayWithUpsertedItems({
                    array: entities,
                    items: colEntityItems,
                    key: "cellPageNumOutputIdRowCol",
                  })
                );
              }}
            >
              {colName}
            </LineItemTooltip>
          </ColHeader>
        ))}
        {table?.tablePreview
          ?.map((row, originalRowIndex) => {
            if (!boldedCellValue) return [row, originalRowIndex];

            if (colNames.some(colName => row[colName] === boldedCellValue)) {
              return [row, originalRowIndex];
            }

            return null;
          })
          ?.filter(rowAndIndex => rowAndIndex?.length === 2)
          ?.map(([row, rowIndex]) =>
            ["", ...colNames].map((colName, colNameIndex) => {
              const cellAutomaticLineItemName =
                table?.automaticLineItemNameMap?.[`${rowIndex},${colNameIndex - 1}`] ||
                `temp_${fileId}_${pageNumber}_${rowIndex}_${colNameIndex}`;

              const cellPageNumOutputIdRowCol = `${pageNumber || 0},${outputItemId},${rowIndex},${colNameIndex}`;
              const cellEntity = entities?.find(
                entity => entity?.cellPageNumOutputIdRowCol === cellPageNumOutputIdRowCol
              );

              if (colNameIndex === 0) {
                return (
                  <RowHeaderCell row={rowIndex + 2} col={1}>
                    <LineItemTooltip
                      cellEntity={cellEntity}
                      placement="right"
                      onNewCellEntity={newCellEntity => {
                        const rowEntityItems = copyEntityItemAcrossRow({
                          newCellEntity,
                          rowIndex,
                          fileId,
                          pageNumber,
                          outputItemId,
                          table,
                        });

                        onNewEntities(
                          getArrayWithUpsertedItems({
                            array: entities,
                            items: rowEntityItems,
                            key: "cellPageNumOutputIdRowCol",
                          })
                        );
                      }}
                    />
                  </RowHeaderCell>
                );
              }

              return (
                <DataCell
                  key={`${colNameIndex}-${rowIndex}`}
                  row={rowIndex + 2}
                  col={colNameIndex + 1}
                  isClickable={!!onCellClick}
                  onClick={() => onCellClick(row[colName], tableId)}
                  isOutlined={!!outlinedCellValue && outlinedCellValue === row[colName]}
                  isBolded={!!boldedCellValue && row[colName] === boldedCellValue}
                >
                  <LineItemTooltip
                    // placement={[0, 1]?.includes(colNameIndex) ? "right" : "bottom"}
                    initialValue={
                      initialTableLineItems?.find(record => record?.automaticLineItemName === cellAutomaticLineItemName)
                        ?.humanLineItemName
                    }
                    value={<AutomaticLineItemGrid cellAutomaticLineItemName={cellAutomaticLineItemName} />}
                    cellEntity={cellEntity}
                    placement={colNameIndex < 2 ? "right" : "bottom"}
                    onNewCellEntity={newCellEntity => {
                      onNewEntities(
                        getArrayWithUpsertedItem({
                          array: entities,
                          item: {
                            ...newCellEntity,
                            fileId,
                            value: JSON.stringify({
                              pageNumber: pageNumber || 0,
                              outputItemId,
                              automaticLineItemMapValue: cellAutomaticLineItemName,
                              cellValue: row[colName],
                            }),

                            cellPageNumOutputIdRowCol,
                          },
                          key: "cellPageNumOutputIdRowCol",
                        })
                      );
                    }}
                  >
                    {row[colName]}
                  </LineItemTooltip>
                </DataCell>
              );
            })
          )
          ?.flat()}
      </Table>
    </TableContainer>
  );
};

export default TableViewerScanned;
