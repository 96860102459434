import { useState } from "react";

const InputWithStateNew = ({
  autoFocus = false,
  className = "",
  style = {},
  initialValue,
  onKeyDown,
  onBlur,
}) => {
  const [value, setValue] = useState(initialValue);

  return (
    <input
      autoFocus={autoFocus}
      className={className}
      style={style}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};

export default InputWithStateNew;
