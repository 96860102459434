import AreaChartParams from "components/AreaChartParams";
import AreaChartPercentageParams from "components/AreaChartPercentageParams";
import BandedChartParams from "components/BandedChartParams";
import BarChartParams from "components/BarChartParams";
import KeyFiguresParams from "components/KeyFiguresParams";
import LineChartParams from "components/LineChartParams";
import PieChartParams from "components/PieChartParams";
import ScatterChartParams from "components/ScatterChartParams";
import StackedBarChartParams from "components/StackedBarChartParams";

const PLOT_TYPE_TO_COMPONENT = {
  Table: null,
  "Bar Chart": BarChartParams,
  "Line Chart": LineChartParams,
  "Area Chart": AreaChartParams,
  "Area Chart Percentage": AreaChartPercentageParams,
  "Stacked Bar Chart": StackedBarChartParams,
  "Banded Chart": BandedChartParams,
  "Scatter Chart": ScatterChartParams,
  "Key Figures": KeyFiguresParams,
  "Pie Chart": PieChartParams,
};

export const PLOT_TYPES = Object.keys(PLOT_TYPE_TO_COMPONENT);

export const getPlotComponent = (plotType = "", plotProps = {}) => {
  const PlotComponent = PLOT_TYPE_TO_COMPONENT[plotType];
  if (!PlotComponent) {
    return null;
  }

  return <PlotComponent {...plotProps} />;
};
