import { useState, useEffect } from "react";

import { getDirectoryItems } from "api/services/fileSystem";

const usePollDirectoryItems = ({
  directoryPath,
  pageSize,
  type = "",
  offset,
}) => {
  const [itemsData, setItemsData] = useState({
    items: [],
    offset: 0,
    hasMore: false,
  });

  useEffect(() => {
    setItemsData({});
    doPopulateDirectoryItems();
    const intId = setInterval(doPopulateDirectoryItems, 2000);
    return () => clearInterval(intId);
  }, [directoryPath, pageSize, offset, type]);

  const doPopulateDirectoryItems = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
      pageSize,
      offset,
      type,
    });
    setItemsData(data);
  };

  return [itemsData, doPopulateDirectoryItems];
};

export default usePollDirectoryItems;
