import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import { postFlowDatabaseTemplates } from "api/backend/chatServiceEndpoints";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  gap: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-weight: 600;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 32px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
  z-index: 1;

  position: sticky;
  top: 0px;
`;

const TableContainer = styled.div`
  min-width: 800px;
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 120px;
  padding: 0;
`;

const StyledInput = styled(StyledBoldInput)`
  font-weight: 400;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const StyledTextArea = styled.textarea`
  height: 100px;
  resize: none;
`;

const INITIAL_COLUMNS = [
  { name: "Source Name", description: "Where the record comes from" },
  { name: "Inserted Time", description: "When the the record was extracted" },
];

const INITIAL_COLUMN_NAMES = INITIAL_COLUMNS.map(column => column.name);

const CreateFlowDatabaseModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pipelineName, setPipelineName] = useState("");
  const [prompt, setPrompt] = useState("");

  const [exampleInput, setExampleInput] = useState({ text: "", subject: "" });

  const [columns, setColumns] = useState(INITIAL_COLUMNS);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setColumns(INITIAL_COLUMNS);
  }, [isOpen]);

  const doSaveDocAsTemplate = async () => {
    setIsLoading(true);
    const trimmedColumns = columns.map(column => ({
      name: column?.name?.trim(),
      description: column?.description?.trim(),
    }));

    const wordDocTemplate = {
      fileName: pipelineName?.trim(),
      content: {
        dashboardConfig: {
          columns: trimmedColumns,
        },
        exampleInput,
        blocks: trimmedColumns.map(column => {
          let text = `extract "${column?.name}"`;
          if (column?.description) {
            text = `extract "${column?.name}", with description "${column?.description}"`;
          }

          return {
            isQuery: true,
            text,
            queryId: uuidv4(),
          };
        }),
      },
    };

    const dashboardConfig = {
      config: {
        components: [
          {
            id: "source_records",
            name: "Your Records",
            columns: trimmedColumns,
            pythonCode: "print(input())",
            records: [],
          },
        ],
        layoutDraggable: {},
      },
    };

    const { error } = await postFlowDatabaseTemplates(
      {},
      {
        prompt,
        wordDocTemplate,
        dashboardConfig,
        exampleInput: JSON.stringify(exampleInput),
      }
    );

    setError(error);
    setIsLoading(false);
    window.location.reload();
    if (!error) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <ButtonCreateNewAsset onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "8px",
            }}
          >
            <Title>Prompt</Title>
            <Title>Template Name</Title>
            <input
              placeholder="What user sees on boltzbit.com"
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
            />
            <input
              value={pipelineName}
              onChange={e => setPipelineName(e.target.value)}
              placeholder="What user sees in top left corner"
            />
          </div>
          <Title>Example subject</Title>
          <input
            value={exampleInput?.subject}
            onChange={e =>
              setExampleInput({ ...exampleInput, subject: e.target.value })
            }
          />
          <Title>Example body</Title>
          <StyledTextArea
            value={exampleInput?.text}
            onChange={e =>
              setExampleInput({ ...exampleInput, text: e.target.value })
            }
          />
          Add columns you want extracted:
          <TableContainer>
            <table>
              <tr>
                <Th>Column Name</Th>
                <Th style={{ width: "100%" }}>Prompt</Th>
              </tr>
              {columns?.map((column, i) => (
                <tr>
                  <Td key={i}>
                    <StyledBoldInput
                      style={{
                        pointerEvents: INITIAL_COLUMN_NAMES?.includes(
                          column?.name
                        )
                          ? "none"
                          : "all",
                      }}
                      value={column?.name}
                      placeholder="Column Name"
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].name = e.target.value;
                        setColumns(newColumns);
                      }}
                    />
                    <StyledTrashIcon
                      onClick={() => {
                        const newColumns = cloneDeep(columns);
                        newColumns.splice(i, 1);
                        setColumns(newColumns);
                      }}
                    />
                  </Td>
                  <Td>
                    <StyledInput
                      style={{
                        width: "100%",
                        pointerEvents: INITIAL_COLUMN_NAMES?.includes(
                          column?.name
                        )
                          ? "none"
                          : "all",
                      }}
                      value={column?.description}
                      placeholder="Description"
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].description = e.target.value;
                        setColumns(newColumns);
                      }}
                    />
                  </Td>
                </tr>
              ))}
              <tr>
                <Td style={{ border: "none" }}>
                  <StyledPlusIcon
                    onClick={() => {
                      setColumns([
                        ...columns,
                        {
                          name: "",
                          description: "",
                        },
                      ]);
                    }}
                  />
                </Td>
              </tr>
            </table>
          </TableContainer>
          <Gap />
          <StyledButton
            variant="highlighted"
            isDisabled={!pipelineName || !prompt}
            value="Create"
            onClick={doSaveDocAsTemplate}
          />
          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateFlowDatabaseModal;
