import { useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { Code, FormatColorFill, PlayArrow } from "@material-ui/icons";
import { Editor } from "@monaco-editor/react";
import {
  getCommandRecommendations,
  postDashboardConfigExecute,
  postGenerateComponent,
} from "api/services/chatService";
import { useParams } from "react-router-dom";
import { parseJson } from "utils/common";
import SmallButton from "components/ui/SmallButton";
import RecordsSummaryGeomap from "components/RecordsSummaryGeomap";
import RecordsSummaryBarChart from "components/RecordsSummaryBarChart";
import RecordsSummaryLineChart from "components/RecordsSummaryLineChart";
import { getDisplayValue } from "utils/dashboard-utils";
import RecordsSingleNumber from "components/RecordsSingleNumber";
import RecordsPieChart from "components/RecordsPieChart";
import AutocompleteTextInputDropdown from "components/ui/AutocompleteTextInputDropdown";
import RecordsSummaryMultiBarChart from "components/RecordsSummaryMultiBarChart";

const StyledTable = styled.table`
  display: block;
  width: 100%;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 14px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;

  position: sticky;
  top: 0;

  z-index: 100;
`;

const CrossTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 4px;
`;

const RefreshTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 0;

  cursor: pointer;

  svg {
    fill: ${props => props.theme.color.closest};
    height: 16px;
    :hover {
      opacity: 0.6;
    }
  }
`;

const Tr = styled.tr`
  :hover {
    background-color: ${props => props.theme.color.closer0}AA;
    ${CrossTd} {
      opacity: 1;
    }
    ${RefreshTd} {
      opacity: 1;
    }
  }
`;

const ModalContent = styled.div`
  padding: 10px;
  background-color: ${props => props.theme.color.furthest};

  display: grid;
  grid-template-columns: 1fr 500px;
  align-items: start;
  column-gap: 20px;

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const TriggerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledEditor = styled(Editor)`
  height: 400px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const StyledTextarea = styled.textarea`
  width: 800px;
  height: 60px;
  resize: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
  font-family: "Montserrat", sans-serif;
  padding: 10px;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
  font-family: "Montserrat", sans-serif;
`;

const StyledSmallButton = styled(SmallButton)`
  grid-template-columns: auto auto;
  gap: 4px;
  padding: 2px;
  padding-right: 8px;
`;

const StyledFormatColorFill = styled(FormatColorFill)`
  :hover {
    opacity: 0.6;
  }

  path:nth-child(2) {
    fill: ${props => props.colorValue};
    fill-opacity: 1;
  }
`;

export const COMPONENT_TYPES = [
  "Table",
  "Geomap",
  "Bar Chart",
  "Line Chart",
  "Single Number",
  "Pie Chart",
  "Multi Bar Chart",
];

export const NL_TO_DSL = {
  "sum Loan Amount, # Units grouping by City":
    "sum Loan Amount, # Units grouping by City",
  "sum Loan Amount then divide 5 - 2, # Units grouping by City":
    "sum Loan Amount then divide 5 - 2, # Units grouping by City",
  "sum Loan Amount, # Units grouping by City, where # Units > 200":
    "sum Loan Amount, # Units grouping by City, where # Units > 200",
  "sum Loan Amount, # Units grouping by Received Time":
    "sum Loan Amount, # Units grouping by Received At",
  // "count View group by Fund Name": "count View group by Fund Name",
  // "count Position Changes group by Report Date":
  //   "count Position Changes group by Report Date",
  "Compute the total loan amount and highest # units for each state":
    "compute the total loan amount and highest # units for each state",

  "Get the total loan amounts": "total Loan Amount",
  "For each state, compute the total loan amount then divide total loan amount by 10":
    "For each state, compute the total loan amount then divide total loan amount by 10",
  "Find the total units": "total # Units",

  "total Loan Amount": "total Loan Amount",
  "total # Units": "total # Units",
};

const getDsl = naturalLanguageCommand => {
  return NL_TO_DSL[naturalLanguageCommand] || naturalLanguageCommand;
};

const ComponentCodeModalTrigger = ({
  isInitiallyOpen = false,
  sourceRecordsColumns = [],
  component = {
    type: "",
    naturalLanguageCommand: "",
    pythonCode: "",
    backgroundColor: "#f1f1f1",
  },
  onConfirmEdit = newComponent => {},
  onClose = () => {},
}) => {
  const { wordDocId } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const [type, setType] = useState(COMPONENT_TYPES[0]);
  const [naturalLanguageCommand, setNaturalLanguageCommand] = useState("");
  const [pythonCode, setPythonCode] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#f1f1f1");
  const [recos, setRecos] = useState([]);

  const [previewResult, setPreviewResult] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (isInitiallyOpen) {
      setIsOpen(isInitiallyOpen);
    }
  }, [isInitiallyOpen]);

  useEffect(() => {
    doPopulateRecos();
  }, [naturalLanguageCommand]);

  useEffect(() => {
    setIsDisabled(false);
    doRunCode(pythonCode);
  }, [isOpen]);

  useEffect(() => {
    setType(component?.type || COMPONENT_TYPES[0]);
    setNaturalLanguageCommand(component?.naturalLanguageCommand || "");
    setPythonCode(component?.pythonCode || "");
    setBackgroundColor(component?.backgroundColor || "#f1f1f1");
  }, [JSON.stringify(component), isOpen]);

  const doPopulateRecos = async () => {
    setRecos([]);
    const { data: recos } = await getCommandRecommendations({
      wordDocId,
      naturalLanguageCommand,
    });
    setRecos(recos);
  };

  const doGenerateCode = async command => {
    setIsDisabled(true);
    const { data } = await postGenerateComponent(
      {},
      {
        naturalLanguageCommand: getDsl(command)?.replaceAll(
          "Received Time",
          "Received At"
        ),
        columnNames: sourceRecordsColumns.map(column => column?.name),
        templateId: wordDocId,
      }
    );

    setPythonCode(data?.pythonCode);
    setIsDisabled(false);
  };

  const doRunCode = async codeToRun => {
    setIsDisabled(true);

    const { data: newPreviewResult } = await postDashboardConfigExecute(
      {},
      {
        pythonCode: codeToRun,
        templateId: wordDocId,
      }
    );

    setPreviewResult(newPreviewResult);
    setIsDisabled(false);
  };

  const stdoutJson = parseJson(previewResult?.stdout);
  const stderrJson = parseJson(previewResult?.stderr);

  return (
    <>
      <TriggerContainer onClick={() => setIsOpen(true)}>
        <Code />
      </TriggerContainer>
      <Modal
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
          onClose();
        }}
      >
        <ModalContent
          isDisabled={isDisabled}
          onClick={e => e.stopPropagation()}
        >
          <div
            style={{
              fontWeight: "bold",
              gridColumn: "span 2",
              paddingBottom: "10px",
            }}
          >
            Configure component
          </div>
          <div
            style={{
              display: "grid",
              justifyItems: "start",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <div>Show as&nbsp;</div>
                <StyledSelect
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  {COMPONENT_TYPES.map(componentType => (
                    <option>{componentType}</option>
                  ))}
                </StyledSelect>
              </div>

              <StyledSmallButton
                style={{ justifySelf: "end" }}
                value="Get prompt"
                onClick={async () => {
                  const commands = Object.keys(NL_TO_DSL);
                  const randomCommand =
                    commands[Math.floor(Math.random() * commands.length)];

                  setNaturalLanguageCommand(randomCommand);
                  // doGenerateCode(randomCommand);
                }}
              />
            </div>
            <AutocompleteTextInputDropdown
              value={naturalLanguageCommand}
              onChangeValue={newVal => setNaturalLanguageCommand(newVal)}
              placeholder="sum Loan Amount grouping by State"
              options={Object.keys(NL_TO_DSL)}
              // onBlur={() => doGenerateCode(naturalLanguageCommand)}
              onPressEnterOnSelection={newCommand => doGenerateCode(newCommand)}
              isShowAsDisabled
              // isOptionClickDisabled
              // onKeyDown={e => {
              //   if (e.key === "Tab" && !naturalLanguageCommand) {
              //     e.preventDefault();
              //     setNaturalLanguageCommand(
              //       "sum loan amount grouping by state"
              //     );
              //   }
              // }}
            />
            <div
              style={{
                display: "grid",
                width: "100%",
                justifyContent: "space-between",
                gridTemplateColumns: "auto auto",
              }}
            >
              <StyledSmallButton
                icon={<Code />}
                // variant="highlighted"
                value="Generate"
                onClick={() => doGenerateCode(naturalLanguageCommand)}
              />
              <StyledSmallButton
                style={{ justifySelf: "end" }}
                icon={<PlayArrow />}
                value="Run"
                onClick={() => doRunCode(pythonCode)}
              />
            </div>
            <div style={{ height: "400px", width: "800px" }}>
              <StyledEditor
                options={{ minimap: { enabled: false } }}
                value={pythonCode}
                onChange={value => setPythonCode(value)}
                language="python"
              />
            </div>
          </div>

          {stdoutJson?.columns?.length > 0 && type === "Table" && (
            <StyledTable style={{ overflow: "auto" }}>
              <thead>
                <Tr>
                  {stdoutJson?.columns?.map(column => (
                    <Th>{column?.name}</Th>
                  ))}
                </Tr>
              </thead>
              <tbody>
                {stdoutJson?.records?.map(record => (
                  <Tr>
                    {stdoutJson?.columns?.map(column => (
                      <Td>
                        {getDisplayValue({ record, columnName: column?.name })}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </tbody>
            </StyledTable>
          )}

          {stdoutJson?.records?.length > 0 && type === "Geomap" && (
            <RecordsSummaryGeomap
              mapWidth={400}
              records={stdoutJson?.records}
            />
          )}

          {stdoutJson?.records?.length > 0 && type === "Bar Chart" && (
            <RecordsSummaryBarChart
              columns={stdoutJson?.columns}
              records={stdoutJson?.records}
            />
          )}

          {stdoutJson?.records?.length > 0 && type === "Multi Bar Chart" && (
            <RecordsSummaryMultiBarChart
              columns={stdoutJson?.columns}
              records={stdoutJson?.records}
            />
          )}

          {stdoutJson?.records?.length > 0 && type === "Line Chart" && (
            <RecordsSummaryLineChart
              columns={stdoutJson?.columns}
              records={stdoutJson?.records}
            />
          )}

          {stdoutJson?.records?.length > 0 && type === "Single Number" && (
            <div>
              <RecordsSingleNumber
                style={{
                  backgroundColor,
                }}
                records={stdoutJson?.records}
              />
              <label
                style={{
                  cursor: "pointer",
                  display: type === "Single Number" ? "block" : "none",
                  paddingTop: "8px",
                }}
              >
                <StyledFormatColorFill
                  style={{ height: "22px" }}
                  colorValue={backgroundColor}
                />
                <input
                  style={{ position: "absolute", opacity: 0, width: 0 }}
                  value={backgroundColor}
                  onChange={e => setBackgroundColor(e.target.value)}
                  type="color"
                />
              </label>
            </div>
          )}

          {stdoutJson?.records?.length > 0 && type === "Pie Chart" && (
            <RecordsPieChart
              columns={stdoutJson?.columns}
              records={stdoutJson?.records}
            />
          )}

          {typeof stderrJson === "string" && (
            <div
              style={{
                backgroundColor: "#eaeaea",
                padding: "10px",
                lineHeight: 1.2,
                whiteSpace: "pre-wrap",
                color: "salmon",
              }}
            >
              {stderrJson}
            </div>
          )}

          <SmallButton
            style={{
              gridColumn: "span 2",
              justifySelf: "end",
              alignSelf: "end",
              padding: "4px 20px",
              marginTop: "20px",
            }}
            onClick={() => {
              onConfirmEdit({
                type,
                naturalLanguageCommand,
                pythonCode,
                backgroundColor,
              });
              setIsOpen(false);
            }}
            value="Save"
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComponentCodeModalTrigger;
