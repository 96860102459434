import { RoundTickIcon } from "components/ui/Icons";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding-bottom: 80px;
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Td = styled.td`
  max-width: 780px;
  text-overflow: ellipsis;
  padding: 12px 16px;
  background-color: ${props =>
    props?.isLighter
      ? `${props.theme.color.closer0}77`
      : props.theme.color.closer0};
  vertical-align: middle;
  line-height: 1.2;
  position: relative;

  ${props =>
    props.isPriceCell &&
    `
    font-weight: bold;
  `}
`;

const AboveHeaderTd = styled(Td)`
  background-color: ${props => props.theme.color.primary};
  font-weight: bold;
  color: ${props => props.theme.color.furthest};
  padding: 4px;
  padding: 8px 16px;
`;

const CornerRect = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const HeaderTr = styled.tr`
  color: white;
  background: ${props => props.headerBg};
  font-weight: 600;
  text-align: center;

  ${Td} {
    background-color: transparent;
  }
`;

const BlueRoundTickIcon = styled(RoundTickIcon)`
  fill: ${props => props.theme.color.primary};
`;

const GreyRoundTickIcon = styled(RoundTickIcon)`
  fill: ${props => props.theme.color.closer2}33;
`;

const cellIcon = {
  tick: <BlueRoundTickIcon />,
  "": <GreyRoundTickIcon />,
};

const TableFlat = ({
  bodyRows = [],
  headerRow = [],
  aboveHeaderRow = [],
  footerRows = [],
  headerBg = "linear-gradient(to right, #9650ff 0%, #3769c8 100%)",
  tdStyle = {},
  style = {},
}) => {
  return (
    <Container>
      <Table style={style}>
        <thead>
          <tr>
            {aboveHeaderRow?.map(cell => {
              if (!cell) {
                return <td />;
              }
              return <AboveHeaderTd>{cell}</AboveHeaderTd>;
            })}
          </tr>
          <HeaderTr headerBg={headerBg}>
            {headerRow?.map((cell, i) => (
              <Td style={{ textAlign: i > 0 ? "center" : "left" }}>{cell}</Td>
            ))}
          </HeaderTr>
        </thead>
        <tbody>
          {bodyRows.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row?.map((cell, columnIndex) => (
                <Td
                  isLighter={rowIndex % 2 === 0}
                  // isPriceCell={rowIndex === bodyRows?.length - 2}
                  style={{
                    ...tdStyle,
                    textAlign: columnIndex === 0 ? "left" : "center",
                  }}
                >
                  {columnIndex === 0 && (
                    <>
                      <CornerRect style={{ top: 0, left: 0 }} />
                      <CornerRect style={{ bottom: 0, left: 0 }} />
                    </>
                  )}
                  {columnIndex === bodyRows?.[0].length - 1 && (
                    <>
                      <CornerRect style={{ top: 0, right: 0 }} />
                      <CornerRect style={{ bottom: 0, right: 0 }} />
                    </>
                  )}
                  {cellIcon[cell] || cell}
                </Td>
              ))}
            </Tr>
          ))}
          {footerRows?.map((row, rowIndex) => (
            <tr>{row}</tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TableFlat;
