import { isEqual, range } from "lodash";

export const SENTIMENT_LABELS = ["POSITIVE", "NEGATIVE", "NEUTRAL"];

export const getIndicesOfSubstring = (sourceStr, searchStr) =>
  [...sourceStr.matchAll(new RegExp(searchStr, "gi"))].map(a => a.index);

const getAnnotationAtI = (i, annotations) => {
  const annos = annotations.filter(annotation => i >= annotation.pos && i <= annotation.pos + annotation.span - 1);
  const sentenceAnno = annos.find(anno => SENTIMENT_LABELS?.includes(anno.label));
  const regularAnno = annos.find(anno => !SENTIMENT_LABELS?.includes(anno.label));
  const humanAnno = annos.find(anno => anno?.type === "TEXT_LABEL") || annos.find(anno => anno?.entityId);

  return humanAnno || regularAnno || sentenceAnno;
};

/*
segments = [
  { start: 3, end: 10, annototion }
  { start: 13, end: 18, annototion }
  ...
]
*/
export const getTextSegmentConfig = (text, annotations = []) => {
  let segments = [];

  let annoAtI = getAnnotationAtI(0, annotations);
  let currentSegment = { start: 0, end: 0, annotation: annoAtI };

  range(0, text?.length + 1).forEach(i => {
    currentSegment.end = i;
    currentSegment.content = text?.substring(currentSegment.start, currentSegment.end);
    annoAtI = getAnnotationAtI(i, annotations);

    if (!isEqual(currentSegment?.annotation, annoAtI)) {
      segments.push(currentSegment);
      currentSegment = {
        start: i,
        end: i,
        annotation: annoAtI,
        content: text?.substring(i, i + 1),
      };
    }

    if (i === text?.length) {
      segments.push(currentSegment);
    }
  });

  return segments;
};
