import styled from "styled-components";
import { CloudDownload, Delete, Edit, MoreVert } from "@material-ui/icons";

import ItemListPopover from "components/ItemListPopover";

const DotsContainer = styled.div`
  cursor: pointer;
  border-radius: 50px;
  height: max-content;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const ActionOption = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  svg {
    height: 16px;
    color: ${props => props.theme.color.closer2};
  }
`;

const TableActionsDropdown = ({ onClickEdit, onClickDelete, onClickDownload }) => {
  return (
    <ItemListPopover
      iconComponent={
        <DotsContainer>
          <MoreVert />
        </DotsContainer>
      }
    >
      {onClickEdit && (
        <ActionOption onClick={onClickEdit}>
          <Edit /> Edit
        </ActionOption>
      )}
      {onClickDownload && (
        <ActionOption onClick={onClickDownload}>
          <CloudDownload /> Download
        </ActionOption>
      )}
      <ActionOption onClick={onClickDelete}>
        <Delete /> Delete
      </ActionOption>
    </ItemListPopover>
  );
};

export default TableActionsDropdown;
