import styled from "styled-components";
import { useState } from "react";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import DirectoryItemsList from "components/DirectoryItemsList";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import useSearchParamsState from "hooks/useSearchParamsState";
import InputWithBottomSuggestions from "components/InputWithBottomSuggestions";
import { getUserProfileByUserName } from "api/services/projectService";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  BoltzhubLogoInner,
  FilesIcon,
  LogoInner,
  PaperClipIcon,
  PlusIcon,
  SendIcon,
  UploadFileIcon,
} from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";
import DirectoryItemsListPipelines from "components/DirectoryItemsListPipelines";
import ButtonIcon from "components/ui/ButtonIcon";
import TooltipClick from "components/ui/TooltipClick";
import { parseJson } from "utils/common";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import SmallButton from "components/ui/SmallButton";
import { Editor } from "@monaco-editor/react";
import { postAndStreamResponse } from "api/api-http-methods";
import { Code, FileCopy, PlayArrow, TextFields } from "@material-ui/icons";

const ENDPOINTS = [
  {
    id: "completion",
    label: "Completion",
    url: "POST /api/v1/chatflow/generate-streamed",
    exampleBody: {
      searchBarQuery: null,
      slashQuery: "tell me a joke",
      cursor: {
        blockIndex: 0,
        letterIndex: 14,
      },
      genContext: "word_query",
      blocks: [
        {
          isQuery: true,
          text: "tell me a joke",
          styles: [
            {
              isSelection: true,
              start: 14,
              end: 14,
            },
          ],
        },
      ],
      sources: [],
    },
  },
  {
    id: "document",
    label: "Extraction",
    url: "POST /api/v1/chatflow/extract",
  },
];

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding: 0;
  display: grid;
  background-color: #f7f9fd;
  height: 100vh;
`;

const CenteredContent = styled.div`
  padding-top: 50px;
  display: grid;
  /* justify-content: center; */
  align-content: start;
  /* padding-top: 100px; */
  /* background-color: #cdd4e0; */
`;

const StyledNavHeader = styled(NavHeader)`
  box-shadow: none;
  background-color: transparent;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  z-index: 3;
`;

const SideBar = styled.div`
  width: 300px;
  position: fixed;
  top: 0;
  background-color: #f7f8fc;
  height: 100vh;
  overflow: auto;
  z-index: 4;
  border-right: 1px solid ${props => props.theme.color.closer1}77;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DirectoryHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f7f8fc;
  padding: 10px 8px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  height: auto;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
`;

const BtnText = styled.span``;

const StyledEditor = styled(Editor)`
  height: calc(100vh - 160px);
  border-right: 1px solid ${props => props.theme.color.closer1};
`;

const Endpoint = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${props => props.isDisabled && "opacity: 0.5;"}
`;

const Url = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding: 12px 20px;
  /* font-family: monospace; */
  border-bottom: 1px solid ${props => props.theme.color.closer1};

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: 20px;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  :hover {
    background-color: #eaeaea;
  }
`;

const Request = styled.div``;

const Response = styled.div``;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com/";
}

const ApiPage = () => {
  const [searchParams] = useSearchParams();

  const [selectedEndpointId, setSelectedEndpointId] = useState(
    ENDPOINTS?.[0].id
  );
  const [requestBodyStr, setRequestBodyStr] = useState("");
  const [responseBody, setResponseBody] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const selectedEndpoint = ENDPOINTS.find(
    endpoint => endpoint.id === selectedEndpointId
  );

  useEffect(() => {
    setRequestBodyStr(
      JSON.stringify(selectedEndpoint?.exampleBody || {}, null, 2)
    );
    setResponseBody({});
  }, [selectedEndpoint?.id]);

  const openSidebarType = parseJson(searchParams.get("openSidebarType"));

  const doSubmitRequest = async () => {
    setIsLoading(true);
    if (selectedEndpoint?.id === "completion") {
      const { error } = await postAndStreamResponse({
        url: `${BASE_URL}/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed`,
        reqBody: parseJson(requestBodyStr),
        abortController: {},
        onDataReceived: data => {
          if (!data?.blocks) {
            return;
          }
          setResponseBody(data || []);
        },
      });
      setIsLoading(false);
      if (error) {
        setResponseBody(error);
      }
    }
  };

  return (
    <StyledCenteredWithTopNavLayout isTopBarHidden isHeaderEnabled={false}>
      {openSidebarType === "docs" && (
        <SideBar>
          <DirectoryItemsList
            renderHeaderComponent={({ tipContent }) => (
              <DirectoryHeaderContainer>
                <TooltipClick
                  tipStyle={{ marginLeft: "-282px" }}
                  tipContent={tipContent}
                >
                  <StyledButtonIcon isActive>
                    <PlusIcon height="10px" />
                    <BtnText>New workspace</BtnText>
                  </StyledButtonIcon>
                </TooltipClick>
              </DirectoryHeaderContainer>
            )}
            isHeaderSticky
            isInitiallyOpen
            isDeleteActionHidden
            directoryPath="/working-files"
            isIconDisabled
            folderActions={["new_word_doc", "upload_word_doc"]}
          />
        </SideBar>
      )}

      {openSidebarType === "sources" && (
        <SideBar>
          <DirectoryItemsList
            renderHeaderComponent={({ tipContent }) => (
              <DirectoryHeaderContainer>
                <TooltipClick
                  tipStyle={{ marginLeft: "-282px" }}
                  tipContent={tipContent}
                >
                  <StyledButtonIcon isActive>
                    <PlusIcon height="10px" />
                    <BtnText>New source</BtnText>
                  </StyledButtonIcon>
                </TooltipClick>
              </DirectoryHeaderContainer>
            )}
            isHeaderSticky
            isInitiallyOpen
            isDeleteActionHidden
            directoryPath="/source-files"
            isIconDisabled
            folderActions={["upload_files", "send_email", "upload_audio"]}
          />
        </SideBar>
      )}

      {openSidebarType === "pipelines" && (
        <SideBar>
          <DirectoryItemsListPipelines
            renderHeaderComponent={({ tipContent }) => (
              <DirectoryHeaderContainer>
                <TooltipClick
                  tipStyle={{ marginLeft: "-282px" }}
                  tipContent={tipContent}
                >
                  <StyledButtonIcon isActive>
                    <PlusIcon height="10px" />
                    <BtnText>New pipeline</BtnText>
                  </StyledButtonIcon>
                </TooltipClick>
              </DirectoryHeaderContainer>
            )}
            isHeaderSticky
            isInitiallyOpen
            isDeleteActionHidden
            directoryPath="/template-files"
            isIconDisabled
          />
        </SideBar>
      )}

      <CenteredContent>
        <TopBar>
          <StyledNavHeader />
        </TopBar>
        <NavWithTabsWithArrows
          tabNames={ENDPOINTS.map(endpoint => endpoint.label)}
          tabIcons={[<LogoInner />, <FileCopy />]}
          selectedTabName={selectedEndpoint.label}
          onTabSelect={tabLabel => {
            setSelectedEndpointId(
              ENDPOINTS.find(endpoint => endpoint.label === tabLabel).id
            );
          }}
        />
        <Endpoint isDisabled={isLoading}>
          <Url style={{ gridColumn: "span 2" }}>
            {selectedEndpoint?.url}
            {selectedEndpoint?.id === "completion" ? (
              <div />
            ) : (
              <TooltipNew
                style={{ fontSize: "14px", fontFamily: "Montserrat" }}
                tipText="Upload file"
              >
                <IconButton>
                  <UploadFileIcon />
                </IconButton>
              </TooltipNew>
            )}
            <TooltipNew
              style={{ fontSize: "14px", fontFamily: "Montserrat" }}
              tipText="Copy cURL"
            >
              <IconButton>
                <Code />
              </IconButton>
            </TooltipNew>
            <SmallButton
              icon={<SendIcon />}
              variant="highlighted"
              style={{ padding: "4px 8px", justifySelf: "end" }}
              value=""
              onClick={doSubmitRequest}
            />
          </Url>
          <Request>
            <StyledEditor
              language="json"
              value={requestBodyStr}
              onChange={value => setRequestBodyStr(value)}
              options={{
                minimap: { enabled: false },
                lineNumbers: "off",
              }}
            />
          </Request>
          <Response>
            <StyledEditor
              options={{
                readOnly: true,
                minimap: { enabled: false },
                lineNumbers: "off",
              }}
              language="json"
              value={JSON.stringify(responseBody, null, 2)}
            />
          </Response>
        </Endpoint>
      </CenteredContent>
    </StyledCenteredWithTopNavLayout>
  );
};

export default ApiPage;
