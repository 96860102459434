import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { difference } from "lodash";

import { CenteredWithTopNavLayout } from "components/Layout";
import TextInputSquare from "components/inputs/TextInputSquare";
import { getFiles } from "api/services/filesService";
import MultiSelectWithFilter from "components/ui/MultiSelectWithFilter";
import Button from "components/ui/Button";
import { getSigmaTableConfig, patchTableConfig, postRunSigmaTableConfig } from "api/services/sigmaService";
import SelectInput from "components/ui/SelectInput";
import ProgressBarFakeProgress from "components/widgets/ProgressBarFakeProgress";
import SigmaConfigEditor from "components/SigmaConfigEditor";
import usePollFilesStatuses from "api/services/usePollFilesStatuses";

const Container = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-areas:
    "back-link view-table-link"
    "page-title page-title"
    "page-sub-title page-sub-title"
    "section-heading-1 ."
    "table-name-input ."
    "section-heading-rows ."
    "id-column-dropdown ."
    "section-heading-columns ."
    "section-sub-heading-columns ."
    "sigma-configs-selector sigma-config-details"
    "section-heading-2 ."
    "data-files-selector ."
    "keyword-input ."
    "create-button file-status"
    "create-error .";
  gap: 20px;
  padding-bottom: 100px;
  width: 100%;
  align-items: start;
`;

const ViewTableLink = styled(Link)`
  grid-area: view-table-link;
  justify-self: end;
`;

const BackLink = styled(Link)`
  grid-area: back-link;
  margin-bottom: 20px;
`;

const PageTitle = styled.div`
  font-size: 36px;
  font-weight: 600;
  grid-area: page-title;
`;

const SectionSubHeadingColumns = styled.div`
  grid-area: section-sub-heading-columns;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const TableNameInput = styled(TextInputSquare)`
  grid-area: table-name-input;
`;

const KeywordInput = styled(TextInputSquare)`
  grid-area: keyword-input;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const DataFilesSelector = styled(MultiSelectWithFilter)`
  grid-area: data-files-selector;
  width: 100%;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const SigmaConfigsSelector = styled(MultiSelectWithFilter)`
  width: 100%;
  grid-area: sigma-configs-selector;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  height: 100%;
`;

const IdColumnDropdown = styled(SelectInput)`
  grid-area: id-column-dropdown;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const IdColumnsSelect = styled(MultiSelectWithFilter)`
  grid-area: id-column-dropdown;
  width: 100%;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const CreateButton = styled(Button)``;

const SectionHeading1 = styled.div`
  grid-area: section-heading-1;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;

  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const SectionHeading2 = styled(SectionHeading1)`
  margin-top: 40px;
  grid-area: section-heading-2;
`;

const SectionHeadingRows = styled(SectionHeading1)`
  margin-top: 40px;
  grid-area: section-heading-rows;
`;

const SectionHeadingColumns = styled(SectionHeading1)`
  margin-top: 40px;
  margin-bottom: 0;
  grid-area: section-heading-columns;
`;

const CreateBtnAndStatus = styled.div`
  margin-top: 80px;
  grid-area: create-button;
  transition: opacity 0.4s;
  opacity: ${props => (props.isHidden ? 0 : 1)};

  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
`;

const CreateError = styled.div`
  margin-top: 80px;
  grid-area: create-error;
  color: ${props => props.theme.color.error};
`;

const EditTableConfigPage = () => {
  const { tableConfigId } = useParams();

  const [originalTableConfig, setOriginalTableConfig] = useState(null);

  const [name, setName] = useState("");
  const [dataFilesOptions, setDataFilesOptions] = useState([]);
  const [selectedDataFileNames, setSelectedDataFileNames] = useState([]);

  const [sigmaConfigs, setSigmaConfigs] = useState([]);
  const [selectedSigmaConfigName, setSelectedSigmaConfigName] = useState(null);
  const [primarySigmaConfigNames, setPrimarySigmaConfigNames] = useState([]);

  const [keywordFilter, setKeywordFilter] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isSavingTable, setIsSavingTable] = useState(false);
  const [patchErr, setPatchErr] = useState(null);

  const [fileIdsToPoll, setFileIdsToPoll] = useState([]);
  const fileStatuses = usePollFilesStatuses(fileIdsToPoll);

  const navigate = useNavigate();

  useEffect(() => {
    if (fileStatuses?.length > 0 && fileStatuses?.every(status => status === "DONE")) {
      navigate(`/table-configs/${tableConfigId}/results`);
    }
  }, [JSON.stringify(fileStatuses)]);

  useEffect(() => {
    doPopulateFiles();
  }, []);

  useEffect(() => {
    doPopulateTableConfig();
  }, [tableConfigId, dataFilesOptions?.items]);

  const doPopulateFiles = async () => {
    const { data } = await getFiles({ pageSize: 30 });
    setDataFilesOptions(data);
    setIsLoading(false);
  };

  const doPopulateTableConfig = async () => {
    const { data } = await getSigmaTableConfig(tableConfigId);
    setOriginalTableConfig(data);
    setSelectedSigmaConfigName(data?.sigmaConfigs?.[0]?.name);
    setSigmaConfigs(data?.sigmaConfigs);
    setPrimarySigmaConfigNames(data?.primarySigmaConfigNames);

    const selectedFileNames = data?.fileIds?.map(
      fileId => dataFilesOptions?.items?.find(file => file.id === fileId)?.fileName
    );
    setSelectedDataFileNames(selectedFileNames);
    setKeywordFilter(data?.keywordFilter);
    setName(data?.name);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  let fileStatusContent = null;
  if ((fileStatuses?.length > 0 && fileStatuses?.some(status => status === "IN_PROGRESS")) || isSavingTable) {
    fileStatusContent = <ProgressBarFakeProgress />;
  }
  if (fileStatuses?.length > 0 && fileStatuses?.every(status => status === "DONE")) {
    fileStatusContent = <ProgressBarFakeProgress isDone />;
  }
  if (fileStatuses?.length > 0 && fileStatuses?.some(status => status === "FAILED")) {
    fileStatusContent = `FAILED, file id: ${fileIdsToPoll?.[fileStatuses?.indexOf("FAILED")]}`;
  }

  const selectedFileIds = dataFilesOptions?.items
    ?.filter(file => selectedDataFileNames?.includes(file?.fileName))
    ?.map(file => file?.id);

  return (
    <CenteredWithTopNavLayout>
      <Container>
        <BackLink to="/table-configs">&lt;- Back to all tables</BackLink>
        <ViewTableLink to={`/table-configs/${tableConfigId}/results`}>View table</ViewTableLink>
        <PageTitle>Edit aggregation table</PageTitle>

        <SectionHeading1>Table name</SectionHeading1>
        <TableNameInput value={name} placeholder="Properties Summary" onNewInput={newValue => setName(newValue)} />
        <SectionHeadingRows isHidden={!sigmaConfigs?.length}>Row ID column</SectionHeadingRows>
        <IdColumnsSelect
          isHidden={!sigmaConfigs?.length}
          options={sigmaConfigs?.map(config => config?.name)}
          onSelectOptions={newNames => setPrimarySigmaConfigNames(newNames)}
          selectedOptions={primarySigmaConfigNames}
          title="ID Columns"
        />
        <SectionHeadingColumns>Data extraction rules</SectionHeadingColumns>
        <SectionSubHeadingColumns>Edit extraction rules for each column:</SectionSubHeadingColumns>
        <SigmaConfigsSelector
          maxOptionsHeight="100%"
          options={sigmaConfigs?.map(config => config?.name)}
          onSelectOptions={newNames => {
            setSelectedSigmaConfigName(newNames?.[newNames?.length - 1]);
          }}
          selectedOptions={
            selectedSigmaConfigName
              ? [sigmaConfigs?.find(config => config?.name === selectedSigmaConfigName)?.name]
              : []
          }
          title="Columns"
        />
        <SigmaConfigEditor
          sigmaConfig={sigmaConfigs?.find(config => config?.name === selectedSigmaConfigName)}
          onNewSigmaConfig={newSigmaConfig => {
            setSigmaConfigs(
              sigmaConfigs?.map(config => {
                if (config?.name === newSigmaConfig?.name) {
                  return newSigmaConfig;
                }
                return config;
              })
            );
          }}
        />

        <SectionHeading2>Source documents</SectionHeading2>
        <DataFilesSelector
          options={dataFilesOptions?.items?.map(file => file?.fileName)}
          onSelectOptions={newNames => setSelectedDataFileNames(newNames)}
          selectedOptions={selectedDataFileNames}
          title="Document"
          shouldShowFakeNewButton
        />
        <KeywordInput
          value={keywordFilter}
          titleAbove="Filter pages by keyword (optional)"
          placeholder="Rent comp"
          onNewInput={newValue => setKeywordFilter(newValue)}
        />

        <CreateBtnAndStatus>
          <CreateButton
            variant="highlighted"
            value="Save & run table"
            isDisabled={isSavingTable || !selectedDataFileNames?.length}
            onClick={async () => {
              setIsSavingTable(true);
              const { data: updatedTableConfig, error } = await patchTableConfig(
                tableConfigId,
                {
                  name,
                  fileIds: selectedFileIds,
                  sigmaConfigs,
                  primarySigmaConfigNames,
                  keywordFilter,
                },
                originalTableConfig
              );
              setPatchErr(error);

              const newlyAddedFileIds = difference(selectedFileIds, originalTableConfig?.fileIds);
              const { error: runError } = await postRunSigmaTableConfig(tableConfigId, {
                fileIds: newlyAddedFileIds,
              });
              setPatchErr(runError);

              setFileIdsToPoll(updatedTableConfig?.fileIds);
            }}
          />
          {fileStatusContent}
        </CreateBtnAndStatus>
        {patchErr && <CreateError>{JSON.stringify(patchErr)}</CreateError>}
      </Container>
    </CenteredWithTopNavLayout>
  );
};

export default EditTableConfigPage;
