/* eslint-disable no-loop-func */
import {
  chunk,
  cloneDeep,
  groupBy,
  inRange,
  isNil,
  isNumber,
  last,
  range,
  sum,
  uniq,
} from "lodash";
import { isTruthy } from "utils/common";

const CHAR_WIDTH = 9;
const PAGE_LEFT_X = 200;
const LINE_SPACING = 4;
const PAGE_TOP_Y = 50;
const TOP_MARGIN = 20;
const TOP_PADDING = 70;
const LINE_HEIGHT = 18;
const CELL_PADDING = 0;

export const SF = 4;
const LEFT_PADDING = 80;
const RIGHT_PADDING = 160;
export const MAX_CHARS_PER_LINE = 100;
export const PAGE_WIDTH_PX = MAX_CHARS_PER_LINE * CHAR_WIDTH;
export const START_X = PAGE_LEFT_X + LEFT_PADDING;

const PAGE_INNER_HEIGHT = 920;
const PAGE_HEIGHT = TOP_PADDING + PAGE_INNER_HEIGHT + TOP_PADDING;

/* 
// BE representation
blocks = [
  { text: "This could be very very long ..."}
  { tableContent: 
    [
      ["a", "b"], 
      ["c", "d"]
    ] 
  }
]

// canvas representation
boxes = [
  { text: "This could be", blockIndex: 0, blockStartIndex: 0 },
  { text: "very very long ...", blockIndex: 0, blockStartIndex: 14 },
  ...
]
*/

const MAX_WORD_CHARS = 30;

export const UNTICKED_PREFIX = "☐";
export const TICKED_PREFIX = "☑";
export const TICKBOX_PREFIXES = [UNTICKED_PREFIX, TICKED_PREFIX];

const BLOCK_STYLE_FIELD_DEFAULTS = {
  fontSize: 14,
  leftIndent: 0,
  prefix: "",
  isQuery: false,
};

const INLINE_STYLE_FIELD_DEFAULTS = {
  fontColor: "#000000",
  bgColor: "transparent",
  url: "",
  meta: null,
  isSelection: false,
  isStrikethrough: false,
  isUnderlined: false,
  isContext: false,
};

const splitStringIntoSegments = (s, segmentLength) => {
  const segments = [];
  let i = 0;
  while (i < s.length) {
    segments.push(s.slice(i, i + segmentLength));
    i += segmentLength;
  }

  return segments;
};

const getFirstTextSegmentCutAtWord = ({
  text,
  ctx,
  fontSize = 14,
  maxBoxWidth = PAGE_WIDTH_PX,
}) => {
  if (!text) {
    return "";
  }
  if (typeof text !== "string") {
    text = `${text}`;
  }

  // return text.slice(0, Math.floor(88 * (maxBoxWidth / PAGE_WIDTH_PX)));

  const prevFontStr = ctx.font;
  ctx.font = `normal ${fontSize}px Arial`;

  const words = text
    .split(" ")
    .map(wordText => ({ text: wordText, isWholeWord: true }))
    .map(word => {
      if (word?.text?.length <= MAX_WORD_CHARS) {
        return word;
      }
      return splitStringIntoSegments(word?.text, 4)?.map(textSegment => ({
        text: textSegment,
        isWholeWord: false,
      }));
    })
    .flat();
  let line = "";
  let lineWidthPx = 0;
  let wordIndex = 0;
  while (lineWidthPx < maxBoxWidth && wordIndex < words.length) {
    const spacer = words[wordIndex]?.isWholeWord ? " " : "";

    lineWidthPx = ctx.measureText(line + spacer + words[wordIndex]).width;
    if (lineWidthPx > maxBoxWidth) {
      break;
    }

    line += (words[wordIndex]?.text || "") + spacer;
    wordIndex++;
  }

  ctx.font = prevFontStr;
  return line;
};

const getBoxStyles = (blockStartIndex, blockStyles) => {
  const stylesWithAdjustedIndices = blockStyles?.map(style => ({
    ...style,
    start: style.start - blockStartIndex,
    end: style.end - blockStartIndex,
  }));

  if (!stylesWithAdjustedIndices) {
    return [];
  }

  const stylesWithValidIndices = stylesWithAdjustedIndices
    .filter(s => s.start >= 0 || s.end >= 0)
    .map(s => ({
      ...s,
      start: Math.max(s.start, 0),
    }));

  return stylesWithValidIndices;
};

const getBoxWidth = (boxText, ctx, fontSize = 14) => {
  const prevFontStr = ctx.font;
  ctx.font = `normal ${fontSize * SF}px Arial`;

  const width = ctx.measureText(boxText).width / SF;

  ctx.font = prevFontStr;

  return width;
};

const BETWEEN_PAGE_RANGES = range(1, 100)?.map(i => [
  i * (TOP_PADDING + PAGE_INNER_HEIGHT + TOP_PADDING) - TOP_PADDING,
  i * (TOP_PADDING + PAGE_INNER_HEIGHT + TOP_PADDING) + TOP_PADDING,
]);

const getBoxesForBlock = ({
  block,
  ctx,
  startY = 0,
  startX,
  blockIndex,
  maxBoxWidth = PAGE_WIDTH_PX,
  pageTopY = 0,
  expandedQueryIds = [],
}) => {
  let blockStartIndex = 0;
  let lineHeight = block?.blockStyles?.fontSize * 1.2 || LINE_HEIGHT;
  const leftIndent = block?.blockStyles?.leftIndent || 0;
  let y = startY;
  let boxes = [];

  if (!block || block?.text === "") {
    if (
      BETWEEN_PAGE_RANGES?.some(
        ([start, end]) => y - pageTopY > start && y - pageTopY < end
      )
    ) {
      y += TOP_PADDING * 2;
    }

    boxes.push({
      ...block,
      text: "",
      blockIndex,
      blockStartIndex,
      y,
      x: startX + leftIndent,
      w: maxBoxWidth,
      h: lineHeight + LINE_SPACING,
      lineHeight,
      styles: block?.styles || [],
    });
    y += lineHeight + LINE_SPACING;

    return [boxes, y];
  }

  const isPromptHidden = !expandedQueryIds?.includes(block?.queryId);
  if (block?.isQuery && block?.queryId && isPromptHidden) {
    boxes?.push({
      ...block,
      x: startX + leftIndent,
      w: 0,
      h: 0,
    });
    return [boxes, y];
  }

  let blockText = block?.text;
  // if (block?.columnIndex > 2) {
  //   blockText = "...";
  // }

  let boxText = getFirstTextSegmentCutAtWord({
    text: blockText,
    ctx,
    fontSize: block?.blockStyles?.fontSize,
    maxBoxWidth: maxBoxWidth - leftIndent,
  });
  // split block text into boxes
  while (boxText) {
    const styles = getBoxStyles(blockStartIndex, block?.styles);

    if (
      BETWEEN_PAGE_RANGES?.some(
        ([start, end]) => y - pageTopY > start && y - pageTopY < end
      )
    ) {
      y += TOP_PADDING * 2;
    }

    boxes.push({
      ...block,
      text: boxText,
      blockIndex,
      blockStartIndex,
      y,
      x: startX + leftIndent,
      w: getBoxWidth(boxText, ctx, block?.blockStyles?.fontSize),
      h: lineHeight + LINE_SPACING,
      lineHeight,
      styles,
    });
    y += lineHeight + LINE_SPACING;

    blockStartIndex += boxText.length;
    boxText = getFirstTextSegmentCutAtWord({
      text: `${blockText}`?.slice(blockStartIndex),
      ctx,
      fontSize: block?.blockStyles?.fontSize,
      maxBoxWidth: maxBoxWidth - leftIndent,
    });
  }

  return [boxes, y];
};

export const deleteSelectionAndMergeBlocks = blocks => {
  const { startBlockIndex, startLetterIndex, endLetterIndex, endBlockIndex } =
    getSelectionFromBlocks(blocks);

  let startBlockText = "";
  let endBlockText = "";

  let newBlocks = blocks
    ?.map((block, blockIndex) => {
      const newBlock = cloneDeep(block);
      if (!block?.styles) {
        newBlock.styles = [];
      }
      const selectionStyles = newBlock?.styles?.filter(s => s?.isSelection);
      if (!range(startBlockIndex, endBlockIndex + 1).includes(blockIndex)) {
        return newBlock;
      }

      const start = selectionStyles?.[0]?.start || 0;
      const end = last(selectionStyles)?.end || 0;

      const segmentToRemove = block?.text?.slice(start, end);
      let newText = newBlock.text.slice(0, start) + newBlock.text.slice(end);

      if (blockIndex === startBlockIndex) {
        startBlockText = newText;
      }
      if (blockIndex === endBlockIndex) {
        endBlockText = newText;
      }

      const newStyles = newBlock?.styles?.map(style => {
        if (style?.end <= start) {
          return style;
        }

        let newStyle = {
          ...style,
          start: style.start - segmentToRemove?.length,
          end: style.end - segmentToRemove?.length,
        };

        if (newStyle?.start < start) {
          newStyle.start = start;
        }

        if (newStyle?.end < start) {
          newStyle.end = start;
        }

        return newStyle;
      });

      newText = newText || "";
      if (!newText && !block?.isTableCell) {
        return null;
      }

      newBlock.text = newText;
      newBlock.styles = newStyles;

      return newBlock;
    })
    ?.filter(isTruthy);

  const areThereTableCellsInSelection = newBlocks
    ?.slice(startBlockIndex, endBlockIndex + 1)
    ?.some(block => block?.isTableCell);

  // merge blocks if needed
  if (
    !areThereTableCellsInSelection &&
    startBlockIndex !== endBlockIndex &&
    startLetterIndex !== 0
  ) {
    newBlocks[startBlockIndex].text = startBlockText + endBlockText;

    const adjustedStylesFromNextBlock = newBlocks[
      startBlockIndex + 1
    ].styles.map(style => {
      return {
        ...style,
        start: style.start + startBlockText?.length,
        end: style.end + startBlockText?.length,
      };
    });
    newBlocks[startBlockIndex].styles.push(...adjustedStylesFromNextBlock);

    newBlocks[startBlockIndex + 1] = null;
  }

  newBlocks = newBlocks.filter(isTruthy);

  const newBlocksNoSelection = removeSelectionStyle(newBlocks);
  const newBlocksUpdatedSelection = addStyleToBlocks({
    startBlockIndex,
    startLetterIndex,
    endBlockIndex: startBlockIndex,
    endLetterIndex: startLetterIndex,
    blocks: newBlocksNoSelection,
    styleFields: {
      isSelection: true,
    },
  });

  return newBlocksUpdatedSelection;
};

const addRowHeightToBoxes = boxes => {
  // build tableId -> rowHeights map
  const tableIdToBoxes = groupBy(
    boxes?.filter(box => !!box.tableId),
    "tableId"
  );
  const tableIdToRowHeights = {};
  Object.entries(tableIdToBoxes).forEach(([tableId, tableBoxes]) => {
    const rowHeights = [];
    const tableBoxesGroupedByRow = groupBy(tableBoxes, "rowIndex");
    let rowIndices = Object.keys(tableBoxesGroupedByRow)?.map(Number);
    rowIndices = range(0, Math.max(...rowIndices) + 1);
    rowIndices.forEach(rowIndex => {
      if (!tableBoxesGroupedByRow[rowIndex]) {
        tableBoxesGroupedByRow[rowIndex] = [];
      }
    });

    Object.entries(tableBoxesGroupedByRow).forEach(([rowIndex, rowBoxes]) => {
      const rowBoxesGroupedByColumn = groupBy(rowBoxes, "columnIndex");
      const columnHeights = [];
      Object.entries(rowBoxesGroupedByColumn).forEach(
        ([columnIndex, columnBoxes]) => {
          const columnHeight = sum(columnBoxes.map(b => b.h));
          columnHeights.push(columnHeight);
        }
      );

      rowHeights.push(Math.max(...columnHeights));
    });

    tableIdToRowHeights[tableId] = rowHeights;
  });

  boxes.forEach((box, boxIndex) => {
    if (box?.isTableCell) {
      box.rowHeight = tableIdToRowHeights[box.tableId][box.rowIndex];
    }
  });

  return boxes;
};

export const drawPageBoundaries = ({ ctx, pageTopY }) => {
  ctx.clearRect(0, 0, 10000, 10000);

  ctx.rect(
    PAGE_LEFT_X * SF,
    (pageTopY + TOP_MARGIN) * SF,
    PAGE_WIDTH_PX * SF,
    10000 * SF
  );
  ctx.fillStyle = "white";
  ctx.strokeStyle = "#323232";

  ctx.stroke();
  ctx.fill();

  range(1, 10)?.forEach(i => {
    ctx.beginPath();
    ctx.moveTo(PAGE_LEFT_X * SF, (pageTopY + i * PAGE_HEIGHT) * SF);
    ctx.lineTo((PAGE_WIDTH_PX + 200) * SF, (pageTopY + i * PAGE_HEIGHT) * SF);

    ctx.stroke();
  });
};

const getRowBoxesAndRowHeight = ({
  cellWidth,
  blocks,
  blockIndex,
  block,
  rowIndex,
  ctx,
  pageTopY,
  y,
}) => {
  let maxRowHeight = 0;
  let rowBoxes = [];
  range(0, block?.numberOfColumns).forEach(columnIndex => {
    // if (columnIndex > 1) {
    //   cellWidth = 0;
    // }

    let startX = START_X + columnIndex * cellWidth;

    const blocksInCell = blocks.filter(
      b =>
        b?.tableId === block?.tableId &&
        b.rowIndex === rowIndex &&
        b.columnIndex === columnIndex
    );

    let cellY = y;
    blocksInCell.forEach(cellBlock => {
      const [cellBlockBoxes, endY] = getBoxesForBlock({
        block: cellBlock,
        ctx,
        startY: cellY,
        startX,
        blockIndex,
        maxBoxWidth: cellWidth,
        pageTopY,
      });
      rowBoxes.push(...cellBlockBoxes);
      cellY = endY;
      blockIndex += 1;
    });

    maxRowHeight = Math.max(maxRowHeight, cellY - y);
  });

  return [rowBoxes, maxRowHeight, blockIndex];
};

const doesBoxCrossBoundary = (box, pageTopY) => {
  const doesBoxCrossRange = ([start, end], rangeIndex) => {
    if (box?.y - pageTopY < start && box?.y + box?.h - pageTopY > start) {
      return true;
    }

    if (box?.y - pageTopY > start && box?.y - pageTopY < end) {
      return true;
    }

    return false;
  };

  const crossedRange = BETWEEN_PAGE_RANGES?.find(doesBoxCrossRange);
  return crossedRange;
};

export const getBoxes = ({
  blocks = [],
  ctx,
  pageTopY = PAGE_TOP_Y,
  imagePathToBase64 = {},
  expandedQueryIds = [],
}) => {
  let boxes = [];

  let y = pageTopY + TOP_MARGIN + TOP_PADDING;
  let blockIndex = 0;

  while (blockIndex < blocks.length) {
    const block = blocks?.[blockIndex];

    if (block?.imagePath) {
      // const imageObj = new Image();
      // imageObj.src = imagePathToBase64?.[block?.imagePath]?.base64Str;

      const imageBox = {
        x: START_X,
        y,
        w: block?.w ?? 100,
        h: block?.h ?? 100,
        image: imagePathToBase64?.[block?.imagePath]?.image,
        blockIndex,
        blockStartIndex: 0,
      };

      if (
        doesBoxCrossBoundary(imageBox, pageTopY)
        // BETWEEN_PAGE_RANGES?.some(
        //   ([start, end]) =>
        //     y + block?.h - pageTopY > start && y + block?.h - pageTopY < end
        // )
      ) {
        const crossedRange = doesBoxCrossBoundary(imageBox, pageTopY);
        imageBox.y = crossedRange[1] + pageTopY;
        y = crossedRange[1] + pageTopY; // block?.h + TOP_PADDING * 2;
      }

      boxes.push(imageBox);
      y += imageBox.h;
      blockIndex += 1;
      continue;
    }

    if (!block?.isTableCell) {
      const [blockBoxes, endY] = getBoxesForBlock({
        block,
        ctx,
        startY: y,
        startX: START_X,
        blockIndex,
        maxBoxWidth: PAGE_WIDTH_PX - RIGHT_PADDING,
        pageTopY,
        expandedQueryIds,
      });
      boxes.push(...blockBoxes);
      y = endY;

      blockIndex += 1;

      continue;
    }

    // if table cell block
    const cellWidth = (PAGE_WIDTH_PX - RIGHT_PADDING) / block?.numberOfColumns;
    range(0, block?.numberOfRows).forEach(rowIndex => {
      let [rowBoxes, maxRowHeight, newBlockIndex] = getRowBoxesAndRowHeight({
        cellWidth,
        blocks,
        blockIndex,
        block,
        rowIndex,
        ctx,
        pageTopY,
        y,
      });

      let pageRangeCellCrosses = BETWEEN_PAGE_RANGES?.findLast(
        ([start, end]) =>
          y + maxRowHeight - pageTopY > start && y - pageTopY < end
      );
      if (pageRangeCellCrosses) {
        [rowBoxes, maxRowHeight, newBlockIndex] = getRowBoxesAndRowHeight({
          cellWidth,
          blocks,
          blockIndex,
          block,
          rowIndex,
          ctx,
          pageTopY,
          y: pageTopY + pageRangeCellCrosses[1],
        });

        boxes.push(...rowBoxes);
        rowBoxes = [];
        y = pageTopY + pageRangeCellCrosses[1] + maxRowHeight;
        blockIndex = newBlockIndex;
        return;
      }

      boxes.push(...rowBoxes);
      rowBoxes = [];
      y += maxRowHeight;
      blockIndex = newBlockIndex;
    });
  }

  // TODO: inefficient function, fix
  boxes = addRowHeightToBoxes(boxes);

  // boxes = moveOverflowingCellsToNextPage(boxes, pageTopY);
  // boxes = moveBoxesOutOfInBetweenPages(boxes, pageTopY);

  return boxes;
};

const moveOverflowingCellsToNextPage = (boxes, pageTopY) => {
  let boxIndex = 0;
  while (boxIndex < boxes.length) {
    const box = boxes?.[boxIndex];
    if (!box?.isTableCell) {
      boxIndex++;
      continue;
    }

    let pageRangeCellCrosses = BETWEEN_PAGE_RANGES?.find(
      ([start, end]) => box?.y + box?.rowHeight - pageTopY > start
    );
    if (pageRangeCellCrosses) {
      let tableStartY = boxes?.find(
        firstBox =>
          firstBox?.rowIndex === 0 &&
          firstBox?.columnIndex === 0 &&
          firstBox?.tableId === box?.tableId
      )?.y;

      const offsetY = pageRangeCellCrosses?.[1] - tableStartY + pageTopY;
      boxes.slice(boxIndex).forEach(boxToMove => {
        boxToMove.y += offsetY;
      });
      return boxes;
    }

    boxIndex++;
  }

  return boxes;
};

const moveBoxesOutOfInBetweenPages = (boxes, pageTopY) => {
  boxes.forEach((box, boxIndex) => {
    if (
      BETWEEN_PAGE_RANGES?.some(
        ([start, end]) => box.y - pageTopY > start && box.y - pageTopY < end
      )
    ) {
      boxes?.slice(boxIndex)?.forEach(boxToMove => {
        boxToMove.y += TOP_PADDING * 2;
      });
    }
  });

  return boxes;
};

const getFontStrForCharIndex = (styles, charIndex, fontSize = 14) => {
  const matchedStyles = styles.filter(
    style => style.start <= charIndex && style.end > charIndex
  );

  const fontWeight =
    matchedStyles?.find(style => style.fontWeight)?.fontWeight || "normal";
  const fontStyle =
    matchedStyles?.find(style => style.fontStyle)?.fontStyle || "normal";

  return `${fontStyle} ${fontWeight} ${fontSize * SF}px Arial`.trim();
};

const getStyleFieldsForCharIndex = (styles = [], charIndex = 0) => {
  const matchedStyles = styles.filter(
    style => style.start <= charIndex && style.end > charIndex
  );

  const matchedFields = {};
  Object.entries(INLINE_STYLE_FIELD_DEFAULTS).forEach(
    ([fieldName, defaultValue]) => {
      matchedFields[fieldName] =
        matchedStyles?.find(style => style?.[fieldName])?.[fieldName] ??
        defaultValue;
    }
  );

  return matchedFields;
};

const doesIncludeFields = (obj = {}, fields = {}) => {
  const objEntries = Object.entries(obj);
  const fieldsEntries = Object.entries(fields);

  const doesEveryFildHaveMatch = fieldsEntries.every(
    ([fieldName, fieldValue]) => {
      const doesObjectContainAMatch = !!objEntries.find(
        ([objFieldName, objFieldValue]) =>
          objFieldName === fieldName &&
          JSON.stringify(objFieldValue) === JSON.stringify(fieldValue)
      );

      return doesObjectContainAMatch;
    }
  );

  return doesEveryFildHaveMatch;
};

// ensures entire text is covered by style decalarations
/**
segment = {
  text, x, w, fontStr, 
}
 */
const getStyleSegments = ({
  fontSize = 14,
  styles = [],
  text = "",
  ctx,
  upToIndex = null,
}) => {
  const segments = [];
  let charIndex = 0;
  let x = 0;

  const upToIndexChecked = upToIndex ?? text?.length;
  let currentSegment = {
    text: "",
    x,
    w: 0,
    fontStr: `normal normal ${(fontSize || 14) * SF}px Arial`,
    fontColor: "#000000",
    url: "",
    meta: null,
  };
  while (charIndex < upToIndexChecked) {
    const fontStr = getFontStrForCharIndex(styles, charIndex, fontSize);
    const charFields = getStyleFieldsForCharIndex(styles, charIndex);

    if (charIndex === 0) {
      currentSegment = { ...currentSegment, ...charFields, fontStr };
      currentSegment.text += text[charIndex] || "";
      charIndex++;
      continue;
    }

    if (
      fontStr === currentSegment.fontStr &&
      doesIncludeFields(currentSegment, charFields)
    ) {
      currentSegment.text += text[charIndex] || "";
      charIndex++;
      continue;
    }

    ctx.font = currentSegment.fontStr;
    currentSegment.w = ctx.measureText(currentSegment.text).width / SF;
    x += currentSegment.w;
    segments.push(currentSegment);

    currentSegment = {
      text: text[charIndex] || "",
      x,
      w: 0,
      fontStr,
      ...charFields,
    };
    charIndex++;
  }
  ctx.font = currentSegment.fontStr;
  currentSegment.w = ctx.measureText(currentSegment.text).width / SF;
  segments.push(currentSegment);

  return segments;
};

export const getSelectionBoxAndLetterIndex = (boxes, keyCode = "") => {
  let boxIndex = null;

  boxes?.forEach((box, i) => {
    const doesBoxHaveSelectionStyle = box?.styles?.some(
      style => style?.isSelection && style?.start <= box?.text?.length
    );
    if (doesBoxHaveSelectionStyle && boxIndex === null) {
      boxIndex = i;
    }
    if (
      doesBoxHaveSelectionStyle &&
      (keyCode === "ArrowRight" || keyCode === "ArrowDown")
    ) {
      boxIndex = i;
    }
  });

  const cursorBox = boxes?.[boxIndex];
  let letterIndex = cursorBox?.styles?.find(s => s.isSelection)?.start;
  if (keyCode === "ArrowRight" || keyCode === "ArrowDown") {
    letterIndex = cursorBox?.styles?.findLast(s => s.isSelection)?.end;
  }

  return { boxIndex, letterIndex };
};

export const drawCursor = ({ ctx, boxes, commandSuggestion }) => {
  const { boxIndex, letterIndex } = getSelectionBoxAndLetterIndex(boxes);
  drawCursorAtLineAndLetterIndex({
    ctx,
    boxIndex,
    letterIndex,
    boxes,
    commandSuggestion,
  });
};

export const drawBoxes = ({
  ctx,
  boxes,
  isResizing,
  commandSuggestion,
  expandedQueryIds = [],
  inProgressBlockIndex,
}) => {
  ctx.strokeStyle = "rgba(0, 0, 0, 1)";
  ctx.fillStyle = "black";
  ctx.beginPath();

  // draw text
  boxes
    ?.filter(box => box.y > -1000 && box.y < 1000)
    ?.forEach((box, i) => {
      if (box?.image) {
        if (!isResizing) {
          ctx.drawImage(
            box?.image,
            box?.x * SF,
            box?.y * SF,
            box?.w * SF,
            box?.h * SF
          );
        }

        const prevStrokeStyle = ctx.strokeStyle;
        ctx.rect(box?.x * SF, box?.y * SF, box?.w * SF, box?.h * SF);
        ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
        ctx.stroke();
        ctx.strokeStyle = prevStrokeStyle;

        return;
      }

      if (box?.blockStartIndex === 0 && box?.blockStyles?.prefix) {
        ctx.font = `normal normal ${
          (box?.blockStyles?.fontSize || 14) * 1.2 * SF
        }px Arial`;
        ctx.fillText(
          box?.blockStyles?.prefix,
          (box?.x - 20) * SF,
          (box?.y + box?.lineHeight) * SF
        );
      }

      const segments = getStyleSegments({
        fontSize: box?.blockStyles?.fontSize,
        styles: box?.styles,
        text: box?.text,
        ctx,
      });

      const isPromptHidden = !expandedQueryIds?.includes(box?.queryId);
      const firstQueryContentBox = boxes?.find(
        filteredBox =>
          filteredBox?.queryId &&
          filteredBox?.queryId === box?.queryId &&
          !filteredBox?.isQuery
      );

      if (
        box?.isQuery ||
        (box?.queryId &&
          isPromptHidden &&
          firstQueryContentBox?.blockIndex === box?.blockIndex)
      ) {
        let prevFontStr = ctx.font;
        const prevFillStyle = ctx.fillStyle;

        const isFirstQueryBoxForBlock =
          boxes?.filter(
            filteredBox => filteredBox?.blockIndex === box?.blockIndex
          )?.[0]?.text === box?.text;

        ctx.font = `normal normal ${14 * SF}px Arial`;
        ctx.fillStyle = "#0191ff";
        if (isFirstQueryBoxForBlock) {
          let prefix = isPromptHidden ? "▷" : "▽";
          if (inProgressBlockIndex === box?.blockIndex) {
            prefix = "✖";
          }

          ctx.fillText(
            prefix,
            (box?.x - 24) * SF,
            (box?.y + box?.lineHeight) * SF
          );
        }
        ctx.font = prevFontStr;
        ctx.fillStyle = prevFillStyle;
      }

      if (box?.blockStyles?.isContext) {
        let prevFontStr = ctx.font;
        const prevFillStyle = ctx.fillStyle;

        const isFirstQueryBoxForBlock =
          boxes?.filter(
            filteredBox => filteredBox?.blockIndex === box?.blockIndex
          )?.[0]?.text === box?.text;

        ctx.font = `normal normal ${14 * SF}px Arial`;
        ctx.fillStyle = "#ff9561";
        if (isFirstQueryBoxForBlock) {
          ctx.fillText("", (box?.x - 24) * SF, (box?.y + box?.lineHeight) * SF);
        }
        ctx.font = prevFontStr;
        ctx.fillStyle = prevFillStyle;
      }

      segments.forEach(segment => {
        const { x, text, fontStr, fontColor } = segment;
        ctx.font = fontStr;
        const prevFillStyle = ctx.fillStyle;
        const prevStrokeStyle = ctx.strokeStyle;

        if (segment?.bgColor) {
          ctx.fillStyle = segment?.bgColor;
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF,
            segment?.w * SF,
            box?.h * SF
          );
          ctx.fillStyle = prevFillStyle;
        }

        if (segment?.isSelection) {
          ctx.fillStyle = "rgba(0, 0, 255, 0.2)";
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF,
            segment?.w * SF,
            box?.h * SF
          );
          ctx.fillStyle = prevFillStyle;
        }

        if (
          segment?.isStrikethrough ||
          box?.blockStyles?.prefix === TICKED_PREFIX
        ) {
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF + 0.6 * box?.h * SF,
            segment?.w * SF,
            6
          );
          ctx.fillStyle = prevFillStyle;
        }

        if (segment?.isUnderlined) {
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF + box?.h * SF - 4,
            segment?.w * SF,
            6
          );
          ctx.fillStyle = prevFillStyle;
        }

        ctx.fillStyle = fontColor;
        if (segment?.url) {
          ctx.fillStyle = "#0000ff";
        }
        ctx.fillText(text, (box?.x + x) * SF, (box?.y + box?.lineHeight) * SF);
        ctx.fillStyle = prevFillStyle;

        if (box?.isQuery) {
          ctx.lineWidth = 4;
          // ctx.strokeStyle = "#0191ff55";
          // ctx.strokeRect(
          //   (box?.x + x) * SF,
          //   box?.y * SF,
          //   segment?.w * SF,
          //   box?.h * SF
          // );
          // ctx.strokeStyle = prevStrokeStyle;

          ctx.fillStyle = "#0191ff11";
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF,
            segment?.w * SF,
            box?.h * SF
          );
          ctx.fillStyle = prevFillStyle;
        }

        if (box?.blockStyles?.isContext) {
          ctx.lineWidth = 4;
          // ctx.strokeStyle = "#ff956155";
          // ctx.strokeRect(
          //   (box?.x + x) * SF,
          //   box?.y * SF,
          //   segment?.w * SF,
          //   box?.h * SF
          // );
          // ctx.strokeStyle = prevStrokeStyle;

          ctx.fillStyle = "#ff956111";
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF,
            segment?.w * SF,
            box?.h * SF
          );
          ctx.fillStyle = prevFillStyle;
        }

        if (segment?.meta) {
          ctx.fillStyle = segment?.meta?.isUserLabel
            ? "#00993320"
            : "#0191ff00";
          ctx.fillRect(
            (box?.x + x) * SF,
            box?.y * SF,
            segment?.w * SF,
            box?.h * SF
          );
          ctx.fillStyle = prevFillStyle;
        }
      });
    });

  // draw table cell borders
  const cellIdToBoxes = groupBy(
    boxes?.filter(box => box?.isTableCell),
    box => `${box?.tableId}-${box?.rowIndex}-${box?.columnIndex}`
  );
  Object.entries(cellIdToBoxes).forEach(([cellId, boxes]) => {
    const x = Math.min(...boxes.map(box => box?.x));
    const y = Math.min(...boxes.map(box => box?.y));
    const w = (PAGE_WIDTH_PX - RIGHT_PADDING) / boxes?.[0]?.numberOfColumns;
    const h = boxes?.[0]?.rowHeight;

    ctx.rect(x * SF, y * SF, w * SF, h * SF);
  });

  ctx.stroke();
};

export const getMouseEventLocation = ({ e, ctx, boxes, contentCanvasRef }) => {
  let { offsetX, offsetY } = e.nativeEvent;
  offsetX = offsetX - contentCanvasRef?.current?.getBoundingClientRect().x;

  // get box
  let clickedBoxIndex = boxes?.length - 1;
  for (let i = 0; i < boxes?.length; i++) {
    const box = boxes?.[i];
    const isXWithinBox = offsetX >= box?.x && offsetX <= box?.x + box?.w;
    const isYWithinBox = offsetY >= box?.y && offsetY <= box?.y + box?.h;
    if (isXWithinBox && isYWithinBox) {
      clickedBoxIndex = i;
      break;
    }

    if (isYWithinBox) {
      clickedBoxIndex = i;
    }
  }

  ctx.stroke();

  // get letter
  const clickedBox = boxes?.[clickedBoxIndex];
  let clickedLetterIndex = 0;
  let letterX = clickedBox?.x;
  const fontSize = clickedBox?.blockStyles?.fontSize || 14;

  // set font for ctx.measureText
  ctx.font = `${fontSize * SF}px Arial`;

  while (
    letterX < offsetX &&
    clickedLetterIndex <= clickedBox?.text?.length + 1
  ) {
    const segments = getStyleSegments({
      fontSize,
      styles: clickedBox?.styles,
      text: clickedBox?.text,
      ctx,
      upToIndex: clickedLetterIndex,
    });
    letterX = clickedBox.x + last(segments)?.x + last(segments)?.w;
    clickedLetterIndex++;
  }

  clickedLetterIndex -= 2;
  if (clickedBoxIndex >= boxes?.length) {
    clickedBoxIndex = boxes?.length - 1;
  }
  clickedLetterIndex = Math.max(0, clickedLetterIndex) || 0;

  const style = clickedBox?.styles?.find(
    s => s?.start <= clickedLetterIndex && s?.end > clickedLetterIndex
  );

  return [clickedBoxIndex, clickedLetterIndex, style];
};

export const getXandYForLineAndLetterIndex = ({
  ctx,
  boxIndex,
  letterIndex,
  boxes,
}) => {
  const box = boxes?.[boxIndex];
  const segments = getStyleSegments({
    fontSize: box?.blockStyles?.fontSize,
    styles: box?.styles,
    text: box?.text,
    ctx,
    upToIndex: letterIndex,
  });
  const x = box?.x + last(segments)?.x + last(segments)?.w;
  const y = box?.y;

  return { x, y };
};

export const drawCursorAtLineAndLetterIndex = ({
  ctx,
  boxIndex,
  letterIndex,
  boxes,
  cursorStrokeStyle = "rgba(0, 0, 0, 1)",
  cursorLineWidth = 8,
  cursorMsg = "",
  commandSuggestion = "",
}) => {
  if (boxIndex === null || letterIndex === null) {
    return;
  }

  ctx.clearRect(0, 0, 10000, 10000);

  const box = boxes?.[boxIndex];
  const boxText = box?.text || "";
  const fontSize = box?.blockStyles?.fontSize || 14;

  ctx.font = `${fontSize * SF}px Arial`;

  const segments = getStyleSegments({
    fontSize,
    styles: box?.styles,
    text: box?.text,
    ctx,
    upToIndex: letterIndex,
  });
  const x = box?.x + last(segments)?.x + last(segments)?.w;
  const y = box?.y;

  if (!boxText && box?.type !== "table-cell" && !box?.isQuery) {
    ctx.fillStyle = "#bbbaba";
    ctx.fillText(
      "Press “/” for AI, right-click for commands",
      x * SF,
      (y + box?.lineHeight) * SF
    );
  }

  if (
    !!boxText &&
    box?.type !== "table-cell" &&
    !box?.isQuery &&
    letterIndex === boxText?.length - 1
  ) {
    ctx.fillStyle = "#bbbaba";
    ctx.fillText(" ⇥ Tab", x * SF, (y + box?.lineHeight) * SF);
  }

  if (!boxText && box?.isQuery) {
    ctx.fillStyle = "#bbbaba";
    ctx.fillText("Enter AI prompt", x * SF, (y + box?.lineHeight) * SF);
  }

  if (boxText && box?.isQuery && letterIndex === boxText?.length - 1) {
    ctx.fillStyle = "#bbbaba";
    ctx.fillText(commandSuggestion, x * SF, (y + box?.lineHeight) * SF);
  }

  if (cursorMsg) {
    ctx.fillStyle = "#0191ff";
    ctx.fillText(cursorMsg, x * SF, (y + box?.lineHeight) * SF);
  }

  ctx.lineWidth = cursorLineWidth;
  ctx.strokeStyle = cursorStrokeStyle;
  ctx.beginPath();
  ctx.moveTo(x * SF, y * SF);
  ctx.lineTo(x * SF, (y + box?.lineHeight + LINE_SPACING) * SF);
  ctx.stroke();
};

export const drawBlueSelectionBoxes = ({
  ctx,
  boxes,
  startBoxIndex,
  startLetterIndex,
  endBoxIndex,
  endLetterIndex,
}) => {
  if (
    isNil(endBoxIndex) ||
    isNil(endLetterIndex) ||
    (startBoxIndex === endBoxIndex && startLetterIndex === endLetterIndex)
  ) {
    return;
  }

  let isEndBeforeStart = false;
  if (endBoxIndex < startBoxIndex) {
    isEndBeforeStart = true;
  }
  if (endBoxIndex === startBoxIndex && endLetterIndex < startLetterIndex) {
    isEndBeforeStart = true;
  }
  if (isEndBeforeStart) {
    const tempEndBoxIndex = endBoxIndex;
    const tempEndLetterIndex = endLetterIndex;
    endBoxIndex = startBoxIndex;
    endLetterIndex = startLetterIndex;
    startBoxIndex = tempEndBoxIndex;
    startLetterIndex = tempEndLetterIndex;
  }

  ctx.clearRect(0, 0, 10000, 10000);
  ctx.fillStyle = "rgba(0, 0, 255, 0.2)";

  const { x: startX, y: startY } = getXandYForLineAndLetterIndex({
    ctx,
    boxIndex: startBoxIndex,
    letterIndex: startLetterIndex,
    boxes,
  });
  const { x: endX } = getXandYForLineAndLetterIndex({
    ctx,
    boxIndex: endBoxIndex,
    letterIndex: endLetterIndex,
    boxes,
  });

  if (startBoxIndex === endBoxIndex) {
    ctx.fillRect(
      startX * SF,
      startY * SF,
      (endX - startX) * SF,
      boxes?.[startBoxIndex]?.h * SF
    );
    return;
  }

  const firstBox = boxes?.[startBoxIndex];
  const firstBoxEndX = firstBox?.x + firstBox?.w;
  ctx.fillRect(
    startX * SF,
    startY * SF,
    (firstBoxEndX - startX) * SF,
    firstBox?.h * SF
  );

  boxes?.slice(startBoxIndex + 1, endBoxIndex).forEach(box => {
    ctx.fillRect(box?.x * SF, box?.y * SF, box?.w * SF, box?.h * SF);
  });

  const lastBox = boxes?.[endBoxIndex];
  ctx.fillRect(
    lastBox?.x * SF,
    lastBox?.y * SF,
    (endX - lastBox?.x) * SF,
    lastBox?.h * SF
  );
};

export const deleteCharAt = ({ blocks, boxes, boxIndex, letterIndex }) => {
  const newBlocks = [...blocks];
  const box = boxes?.[boxIndex];
  const block = newBlocks?.[box?.blockIndex];

  if (
    letterIndex === 0 &&
    box?.blockStartIndex === 0 &&
    box?.type !== "table-cell"
  ) {
    let prevBlock = newBlocks?.[box?.blockIndex - 1];
    if (!prevBlock) {
      prevBlock = { text: "", styles: [] };
    }
    if (prevBlock?.tableContent) {
      return newBlocks;
    }
    if (prevBlock?.imagePath) {
      prevBlock = { text: "", styles: [] };
      newBlocks[box?.blockIndex - 1] = prevBlock;
      return newBlocks;
    }
    const newBlockText = (prevBlock?.text || "") + block?.text;

    const mergedStyles = mergeBlockStyles(
      prevBlock,
      block,
      newBlockText.length
    );
    prevBlock.styles = mergedStyles;
    prevBlock.text = newBlockText;

    newBlocks.splice(box?.blockIndex, 1);

    return newBlocks;
  }

  if (box?.type === "table-cell") {
    const cellText = block.tableContent?.[box?.rowIndex][box?.columnIndex];
    const indexInCellText = box?.cellStartIndex + letterIndex;
    const newCellText =
      cellText.slice(0, Math.max(0, indexInCellText - 1)) +
      cellText.slice(indexInCellText);

    const newBlock = { ...block };
    newBlock.tableContent[box?.rowIndex][box?.columnIndex] = newCellText;
    newBlocks[box?.blockIndex] = newBlock;

    return newBlocks;
  }

  const newBlockText =
    block?.text.slice(0, box?.blockStartIndex + letterIndex - 1) +
    block?.text.slice(box?.blockStartIndex + letterIndex);

  const blockLetterIndex = box?.blockStartIndex + letterIndex;
  const newBlockStyles = block?.styles
    ?.map(style => {
      let newStyle = style;
      if (style.start <= blockLetterIndex && style.end >= blockLetterIndex) {
        newStyle = {
          ...style,
          end: style.end - 1,
        };
      }

      if (style.start >= blockLetterIndex) {
        newStyle = {
          ...style,
          start: style.start - 1,
          end: style.end - 1,
        };
      }

      if (newStyle.start === newStyle.end) {
        newStyle = null;
      }

      return newStyle;
    })
    ?.filter(style => !!style);

  newBlocks[box?.blockIndex] = {
    ...block,
    text: newBlockText,
    styles: newBlockStyles,
  };

  return newBlocks;
};

export const insertCharAt = ({
  blocks,
  boxes,
  boxIndex,
  letterIndex,
  char,
}) => {
  const newBlocks = [...blocks];
  const box = boxes?.[boxIndex];
  const block = newBlocks?.[box?.blockIndex];

  if (box?.type === "table-cell") {
    const cellText = block.tableContent?.[box?.rowIndex][box?.columnIndex];
    const indexInCellText = box?.cellStartIndex + letterIndex;
    const newCellText =
      cellText.slice(0, indexInCellText) +
      char +
      cellText.slice(indexInCellText);

    const newBlock = { ...block };
    newBlock.tableContent[box?.rowIndex][box?.columnIndex] = newCellText;
    newBlocks[box?.blockIndex] = newBlock;
    return newBlocks;
  }

  const newBlockText =
    block?.text.slice(0, box?.blockStartIndex + letterIndex) +
    char +
    block?.text.slice(box?.blockStartIndex + letterIndex);

  const blockLetterIndex = box?.blockStartIndex + letterIndex;
  const newBlockStyles = block?.styles?.map(style => {
    if (style.start <= blockLetterIndex && style.end > blockLetterIndex) {
      return {
        ...style,
        end: style.end + 1,
      };
    }

    if (style.start > blockLetterIndex) {
      return {
        ...style,
        start: style.start + 1,
        end: style.end + 1,
      };
    }

    return style;
  });
  newBlocks[box?.blockIndex] = {
    ...block,
    text: newBlockText,
    styles: newBlockStyles,
  };

  return newBlocks;
};

export const splitBlockAt = ({ blocks, boxes, boxIndex, letterIndex }) => {
  let newBlocks = [...blocks];
  const line = boxes?.[boxIndex];
  const block = newBlocks?.[line?.blockIndex];

  if (line?.cells) {
    return [...blocks, { text: "" }];
  }

  const blockLetterIndex = line?.blockStartIndex + letterIndex;
  const upperBlockText = block?.text?.slice(0, blockLetterIndex);
  const lowerBlockText = block?.text?.slice(blockLetterIndex);

  const upperBlockStyles = (block?.styles || [])
    ?.filter(style => style.start < blockLetterIndex)
    ?.map(style => {
      if (style.end > blockLetterIndex) {
        return {
          ...style,
          end: blockLetterIndex,
        };
      }
      return style;
    });
  const lowerBlockStyles = (block?.styles || [])
    ?.filter(style => style.end >= blockLetterIndex)
    ?.map(style => {
      if (style.start < blockLetterIndex) {
        return {
          ...style,
          start: 0,
          end: style.end - blockLetterIndex,
        };
      }
      return {
        ...style,
        start: style.start - blockLetterIndex,
        end: style.end - blockLetterIndex,
      };
    });

  let newUpperBlock = {
    ...block,
    text: upperBlockText,
    styles: upperBlockStyles,
  };
  if (newUpperBlock.text === "") {
    delete newUpperBlock.queryId;
  }

  let newLowerBlock = {
    ...block,
    text: lowerBlockText,
    styles: lowerBlockStyles,
  };
  if (newLowerBlock.text === "") {
    delete newLowerBlock.queryId;
  }
  // to prevent id duplication
  if (newLowerBlock?.id) {
    delete newLowerBlock.id;
  }

  newBlocks = [
    ...blocks.slice(0, line?.blockIndex),
    newUpperBlock,
    newLowerBlock,
    ...blocks.slice(line?.blockIndex + 1),
  ];

  return newBlocks;
};

const TABLE_ROWS = [
  [
    "Property Address",
    "Purchase Year",
    "Property Purchase Price",
    "Current Value",
    "Rental Income (Annual)",
    "Vacancy Rate",
  ],
  [
    "12 Riggindale Road",
    "2020",
    "£1,683,000.00",
    "£2,288,880.00",
    " £ 200,000.00",
    "9800.00%",
  ],
  [
    "5 Ambleside Avenue",
    "2018",
    "£1,678,713.00",
    "£2,283,049.68",
    " £ 190,870.00 ",
    "9920.00%",
  ],
  [
    "9 Rydal Road",
    "2020",
    "£1,674,426.00",
    "£2,009,311.20",
    " £ 181,928.00 ",
    "9800.00%",
  ],
  [
    "Flat 3, 18 Mitcham Lane",
    "2021",
    "£1,670,139.00",
    "£1,970,764.02",
    " £ 172,986.00",
    "9950.00%",
  ],
];

export const insertTableBlockAt = ({
  blocks,
  boxes,
  boxIndex,
  letterIndex,
}) => {
  let newBlocks = [...blocks];
  const line = boxes?.[boxIndex];
  const block = newBlocks?.[line?.blockIndex];

  const upperBlockText = block?.text?.slice(
    0,
    line?.blockStartIndex + letterIndex
  );
  const lowerBlockText = block?.text?.slice(
    line?.blockStartIndex + letterIndex
  );

  newBlocks = [
    ...blocks.slice(0, line?.blockIndex),
    { ...block, text: upperBlockText },
    {
      tableContent: TABLE_ROWS,
    },
    { ...block, text: lowerBlockText },
    ...blocks.slice(line?.blockIndex + 1),
  ];

  return newBlocks;
};

export const deleteCharsInBlocksInRange = ({
  blocks,
  boxes,
  startBoxIndex,
  startLetterIndex,
  endBoxIndex,
  endLetterIndex,
}) => {
  let isEndBeforeStart = false;
  if (endBoxIndex < startBoxIndex) {
    isEndBeforeStart = true;
  }
  if (endBoxIndex === startBoxIndex && endLetterIndex < startLetterIndex) {
    isEndBeforeStart = true;
  }
  if (isEndBeforeStart) {
    const tempEndBoxIndex = endBoxIndex;
    const tempEndLetterIndex = endLetterIndex;
    endBoxIndex = startBoxIndex;
    endLetterIndex = startLetterIndex;
    startBoxIndex = tempEndBoxIndex;
    startLetterIndex = tempEndLetterIndex;
  }

  const selectedBoxes = boxes?.slice(startBoxIndex, endBoxIndex + 1);
  const blockIndexToBoxes = groupBy(selectedBoxes, "blockIndex");
  const blockIndices = Object.keys(blockIndexToBoxes)?.map(i => parseInt(i));
  let newBlocks = [...blocks];

  const blockIndexToDeletionParams = {};

  blockIndices.forEach(blockIndex => {
    const block = newBlocks[blockIndex];

    let start = 0;
    if (blockIndex === selectedBoxes[0]?.blockIndex) {
      start = selectedBoxes[0]?.blockStartIndex + startLetterIndex;
    }

    let end = block?.text?.length;
    if (blockIndex === selectedBoxes[selectedBoxes.length - 1]?.blockIndex) {
      end =
        selectedBoxes[selectedBoxes.length - 1]?.blockStartIndex +
        endLetterIndex;
    }

    blockIndexToDeletionParams[blockIndex] = {
      start,
      end,
    };
  });

  Object.keys(blockIndexToDeletionParams).forEach(blockIndex => {
    const block = newBlocks[blockIndex];
    const { start, end } = blockIndexToDeletionParams[blockIndex];
    const newBlockText = block?.text.slice(0, start) + block?.text.slice(end);
    newBlocks[blockIndex] = { ...block, text: newBlockText };
  });

  newBlocks = newBlocks.filter((block, blockIndex) => {
    if (blockIndices?.includes(blockIndex)) {
      return block?.text?.length > 0;
    }
    return true;
  });

  return [newBlocks, startBoxIndex, startLetterIndex];
};

const getPriorityStyle = (styles = []) => {
  let style = styles?.[0];

  styles.forEach(s => {
    if (!style?.meta && s?.meta) {
      style = s;
    }
  });

  return style;
};

export const addStyle = (styles = [], newStyle, textLength) => {
  const allStyles = [...(styles || []), newStyle];
  const innerEdges = uniq(
    allStyles
      .map(({ start, end }) => [start, end ?? textLength])
      .flat(Infinity)
      .sort((a, b) => a - b)
  )?.filter(edge => edge !== 0 && edge !== textLength);
  const allEdges = uniq([0, ...innerEdges, textLength]);

  const pairedEdges = [...allEdges, ...innerEdges].sort((a, b) => a - b);
  const edgePairs = chunk(pairedEdges, 2);
  const newStyles = [];

  edgePairs.forEach(([start, end]) => {
    const doesNewStyleApply = start >= newStyle.start && end <= newStyle.end;
    const existingStyles = allStyles?.filter(
      s => start >= s.start && end <= s.end
    );
    const existingStyle = getPriorityStyle(existingStyles);

    if (doesNewStyleApply) {
      newStyles.push({
        ...existingStyle,
        ...newStyle,
        start,
        end,
      });
      return;
    }

    newStyles.push({
      ...existingStyle,
      start,
      end,
    });
  });

  // merge adjacent styles with same properties
  const mergedStyles = newStyles.reduce((acc, curr) => {
    const lastStyle = acc[acc.length - 1];
    if (doesIncludeFields(lastStyle, curr)) {
      return [
        ...acc.slice(0, acc.length - 1),
        {
          ...curr,
          meta: lastStyle.meta,
          start: lastStyle.start,
        },
      ];
    }

    return [...acc, curr];
  }, []);

  if (newStyle?.start === newStyle?.end) {
    mergedStyles.push(newStyle);
  }

  return mergedStyles;
};

const mergeBlockStyles = (prevBlock, block, textLength) => {
  let mergedStyles = [
    ...(prevBlock?.styles || []),
    ...(block?.styles?.map(style => {
      return {
        ...style,
        start: style.start + (prevBlock?.text?.length || 0),
        end: style.end + (prevBlock?.text?.length || 0),
      };
    }) || []),
  ];

  const maxFontSize = Math.max(
    ...(prevBlock?.styles || []).map(style => style.fontSize)
  );

  if (maxFontSize > 0) {
    mergedStyles = addStyle(
      mergedStyles,
      {
        start: 0,
        end: prevBlock?.text.length,
        fontSize: maxFontSize,
      },
      textLength
    );
  }

  return mergedStyles;
};

export const isEndBeforeStart = (
  selection = {
    startBlockIndex: null,
    startLetterIndex: null,
    endBlockIndex: null,
    endLetterIndex: null,
  }
) => {
  const { startBlockIndex, startLetterIndex, endBlockIndex, endLetterIndex } =
    selection;
  if (endBlockIndex === null || endLetterIndex === null) {
    return false;
  }

  if (startBlockIndex < endBlockIndex) {
    return false;
  }
  if (startBlockIndex === endBlockIndex) {
    return startLetterIndex > endLetterIndex;
  }

  return true;
};

export const isRegionSelected = blocks => {
  const { startBlockIndex, endBlockIndex, startLetterIndex, endLetterIndex } =
    getSelectionFromBlocks(blocks);

  if (
    [startBlockIndex, endBlockIndex, startLetterIndex, endLetterIndex].some(
      isNil
    )
  ) {
    return false;
  }

  if (startBlockIndex === endBlockIndex) {
    return startLetterIndex !== endLetterIndex;
  }

  return startBlockIndex !== endBlockIndex;
};

export const getSelectionTopBarState = blocks => {
  const { startBlockIndex, startLetterIndex, endBlockIndex, endLetterIndex } =
    getSelectionFromBlocks(blocks);

  let sel = {
    ...BLOCK_STYLE_FIELD_DEFAULTS,
    ...INLINE_STYLE_FIELD_DEFAULTS,
  };

  if (
    endBlockIndex === null ||
    endLetterIndex === null ||
    isNaN(endLetterIndex) ||
    isNaN(endBlockIndex)
  ) {
    return sel;
  }

  if (
    startBlockIndex === endBlockIndex &&
    startLetterIndex === endLetterIndex
  ) {
    const block = blocks?.[startBlockIndex];
    sel = { ...sel, isQuery: block?.isQuery, ...block?.blockStyles };
    return sel;
  }

  let blockIndex = startBlockIndex;
  let charIndex = startLetterIndex;
  while (blockIndex <= endBlockIndex) {
    if (blockIndex >= endBlockIndex && charIndex >= endLetterIndex) {
      break;
    }
    const block = blocks?.[blockIndex];

    if (block?.text?.length === 0 || !block?.text) {
      blockIndex += 1;
      continue;
    }

    sel = { ...sel, isQuery: block?.isQuery, ...block?.blockStyles };

    const charStyle = block?.styles?.find(
      style => style.start <= charIndex && style.end > charIndex
    );

    sel = { ...sel, ...charStyle };

    charIndex += 1;
    if (charIndex >= block?.text?.length) {
      blockIndex += 1;
      charIndex = 0;
    }
  }

  return sel;
};

export const getBoxAndLetterIndexFromExternalCursor = ({
  blockIndex = null,
  blockLetterIndex = null,
  boxes = [],
}) => {
  let boxIndex = 0;
  while (boxIndex < boxes?.length) {
    const box = boxes?.[boxIndex];
    if (box?.blockIndex !== blockIndex) {
      boxIndex += 1;
      continue;
    }

    if (
      blockLetterIndex >= box?.blockStartIndex &&
      blockLetterIndex <= box?.blockStartIndex + box?.text?.length
    ) {
      return { boxIndex, letterIndex: blockLetterIndex - box?.blockStartIndex };
    }

    boxIndex += 1;
  }

  return { boxIndex: null, letterIndex: null };
};

export const getSelectedText = ({
  cursor = { boxIndex: null, letterIndex: null },
  endCursor = { boxIndex: null, letterIndex: null },
  boxes = [],
}) => {
  if (cursor?.boxIndex === endCursor?.boxIndex) {
    const boxText = boxes?.[cursor?.boxIndex]?.text;
    return boxText.slice(cursor?.letterIndex, endCursor?.letterIndex);
  }

  let text = "";
  range(cursor?.boxIndex, endCursor?.boxIndex + 1).forEach(boxIndex => {
    const box = boxes?.[boxIndex];

    if (boxIndex === cursor?.boxIndex) {
      text += box?.text?.slice(cursor?.letterIndex);
      return;
    }

    if (boxIndex === endCursor?.boxIndex) {
      text += box?.text?.slice(0, endCursor?.letterIndex);
      return;
    }

    text += box?.text;
  });

  return text;
};

export const getFrames = ({ oldCursor, oldBlocks, newCursor, newBlocks }) => {
  let currentCursor = cloneDeep(oldCursor);
  let currentBlocks = cloneDeep(oldBlocks);

  const allFrames = [];
  // animate all after cursor
  while (currentCursor?.blockIndex <= newBlocks?.length) {
    if (
      currentCursor?.blockIndex === newCursor?.blockIndex &&
      currentCursor?.letterIndex === newCursor?.letterIndex
    ) {
      break;
    }
    // target block at this index
    let currentNewBlock = cloneDeep(
      newBlocks?.[currentCursor?.blockIndex] || {}
    );

    // detect if new block is needed, then prepare
    currentCursor.letterIndex += 1;
    if (currentCursor.letterIndex > currentNewBlock?.text?.length) {
      currentCursor.blockIndex += 1;
      currentCursor.letterIndex = 0;
      currentBlocks[currentCursor.blockIndex] = { styles: [], text: "" };
      currentNewBlock = cloneDeep(newBlocks?.[currentCursor?.blockIndex] || {});
    }

    // add the new letter
    currentBlocks[currentCursor.blockIndex] = { ...currentNewBlock };
    currentBlocks[currentCursor.blockIndex].text = currentNewBlock?.text.slice(
      0,
      currentCursor.letterIndex
    );

    allFrames.push({
      blocks: cloneDeep(currentBlocks),
      cursor: cloneDeep(currentCursor),
    });
  }

  allFrames.push({
    blocks: cloneDeep(newBlocks),
    cursor: cloneDeep(newCursor),
  });

  return [
    allFrames[0],
    ...range(1, allFrames?.length - 1, 6)?.map(ind => allFrames?.[ind]),
    last(allFrames),
  ];
};

/*
selection = {
  startBlockIndex, endBlockIndex,
  startLetterIndex, endLetterIndex,
}
*/
export const addStyleToBlocks = ({
  blocks,
  startBlockIndex,
  startLetterIndex,
  endBlockIndex,
  endLetterIndex,
  styleFields,
}) => {
  if (
    [startBlockIndex, startLetterIndex, endBlockIndex, endLetterIndex].some(
      ind => !isNumber(ind) || isNaN(ind) || isNil(ind)
    )
  ) {
    return blocks;
  }

  const newBlocks = cloneDeep(blocks);
  if (startBlockIndex === null) {
    return newBlocks;
  }

  if (startBlockIndex > endBlockIndex) {
    const tempEndBlockIndex = endBlockIndex;
    const tempEndLetterIndex = endLetterIndex;
    endBlockIndex = startBlockIndex;
    endLetterIndex = startLetterIndex;
    startBlockIndex = tempEndBlockIndex;
    startLetterIndex = tempEndLetterIndex;
  }

  range(startBlockIndex, endBlockIndex + 1).forEach(blockIndex => {
    const block = newBlocks?.[blockIndex];
    const newStyle = {
      ...styleFields,
      blockIndex,
      start: blockIndex === startBlockIndex ? startLetterIndex : 0,
      end: blockIndex === endBlockIndex ? endLetterIndex : block?.text?.length,
    };

    if (startBlockIndex !== endBlockIndex && block?.text?.length === 0) {
      return;
    }

    if (newStyle?.start > newStyle?.end) {
      const tempEnd = newStyle?.end;
      newStyle.end = newStyle?.start;
      newStyle.start = tempEnd;
    }

    const newStyles = addStyle(block?.styles, newStyle, block?.text?.length);
    if (newBlocks[blockIndex]) {
      newBlocks[blockIndex].styles = newStyles;
    }
  });

  return newBlocks;
};

export const addToStyleFieldsToSelection = (blocks, newFields) => {
  const newBlocks = cloneDeep(blocks);
  newBlocks.forEach(block => {
    block.styles = block.styles.map(style => {
      if (style.isSelection) {
        return { ...style, ...newFields };
      }
      return style;
    });
  });

  return newBlocks;
};

export const getSelectionFromBlocks = blocks => {
  let startBlockIndex = null;
  let endBlockIndex = null;
  let startLetterIndex = null;
  let endLetterIndex = null;
  let isBackward = false;

  blocks?.forEach((block, blockIndex) => {
    block?.styles?.forEach(style => {
      if (style?.isSelection) {
        if (startBlockIndex === null) {
          startBlockIndex = blockIndex;
          startLetterIndex = style.start;
        }
        endBlockIndex = blockIndex;
        endLetterIndex = style.end;
        isBackward = style.isBackward;
      }
    });
  });

  return {
    startBlockIndex,
    endBlockIndex,
    startLetterIndex,
    endLetterIndex,
    isBackward,
  };
};

export const removeSelectionStyle = blocks => {
  const newBlocks = blocks?.map(block => {
    let newStyles = [];
    if (block?.styles?.length > 0) {
      newStyles = block?.styles
        ?.map(style => {
          if (!style?.isSelection) {
            return style;
          }
          return { ...style, isSelection: false };
        })
        ?.filter(isTruthy);
    }
    return { ...block, styles: newStyles };
  });

  return newBlocks;
};

export const selectWordUnderCursor = blocks => {
  const { startBlockIndex, startLetterIndex } = getSelectionFromBlocks(blocks);
  const block = blocks?.[startBlockIndex];
  const wordStart = block?.text?.lastIndexOf(" ", startLetterIndex) + 1;
  const wordEnd = block?.text?.indexOf(" ", startLetterIndex);
  const newBlocks = addStyleToBlocks({
    blocks,
    startBlockIndex,
    startLetterIndex: wordStart,
    endBlockIndex: startBlockIndex,
    endLetterIndex: wordEnd === -1 ? block?.text?.length : wordEnd,
    styleFields: {
      isSelection: true,
    },
  });

  return newBlocks;
};

export const selectBlockUnderCursor = blocks => {
  const { startBlockIndex } = getSelectionFromBlocks(blocks);

  const newBlocksNoSelection = removeSelectionStyle(blocks);
  const newBlocksUpdatedSelection = addStyleToBlocks({
    startBlockIndex,
    startLetterIndex: 0,
    endBlockIndex: startBlockIndex,
    endLetterIndex: blocks?.[startBlockIndex]?.text?.length,
    blocks: newBlocksNoSelection,
    styleFields: {
      isSelection: true,
    },
  });

  return newBlocksUpdatedSelection;
};

const isStyleDefault = style => {
  return (
    style?.fontWeight === "normal" &&
    style?.bgColor === "transparent" &&
    style?.fontStyle === "normal" &&
    style?.fontColor === "#000000" &&
    !style?.isSelection
  );
};

export const insertBlocksAtCursor = (blocks, blocksToInsert, boxes = []) => {
  const { startBlockIndex, startLetterIndex } = getSelectionFromBlocks(blocks);

  const headBlock = blocksToInsert?.[0];
  let newBlocks = cloneDeep(blocks);
  const startBlock = newBlocks[startBlockIndex];

  if (startBlock?.text?.length === 0) {
    newBlocks.splice(startBlockIndex, 0, ...blocksToInsert);
    return newBlocks;
  }

  // insert head block
  const newText =
    startBlock.text.slice(0, startLetterIndex) +
    headBlock.text +
    startBlock.text.slice(startLetterIndex);
  startBlock.text = newText;
  startBlock.styles = startBlock.styles.map(style => {
    if (style.end < startLetterIndex) {
      return style;
    }

    return {
      ...style,
      start: style.start + headBlock.text.length,
      end: style.end + headBlock.text.length,
    };
  });

  const adjustedHeadBlockStyles = headBlock.styles
    ?.map(style => {
      return {
        ...style,
        start: style.start + startLetterIndex,
        end: style.end + startLetterIndex,
      };
    })
    ?.filter(style => style.start > 0 && style.end <= startBlock.text.length)
    ?.filter(style => !isStyleDefault(style));

  adjustedHeadBlockStyles?.forEach(style => {
    newBlocks = addStyleToBlocks({
      startBlockIndex,
      startLetterIndex: style.start,
      endBlockIndex: startBlockIndex,
      endLetterIndex: style.end,
      blocks: newBlocks,
      styleFields: style,
    });
  });
  newBlocks = removeSelectionStyle(newBlocks);
  newBlocks = addStyleToBlocks({
    startBlockIndex,
    startLetterIndex: startLetterIndex + headBlock.text.length,
    endBlockIndex: startBlockIndex,
    endLetterIndex: startLetterIndex + headBlock.text.length,
    blocks: newBlocks,
    styleFields: {
      isSelection: true,
    },
  });

  if (blocksToInsert?.length === 1) {
    return newBlocks;
  }

  // insert body blocks
  let { boxIndex, letterIndex } = getSelectionBoxAndLetterIndex(boxes);
  newBlocks = splitBlockAt({
    blocks: newBlocks,
    boxes,
    boxIndex,
    letterIndex: letterIndex + headBlock.text.length,
  });

  newBlocks.splice(startBlockIndex + 1, 0, ...blocksToInsert.slice(1, -1));

  // insert tail block
  const tailBlock = last(blocksToInsert);
  const endBlock = newBlocks[startBlockIndex + blocksToInsert.length - 1];
  endBlock.text = tailBlock.text + endBlock.text;

  endBlock.styles = endBlock.styles.map(style => {
    return {
      ...style,
      start: style.start + tailBlock.text.length,
      end: style.end + tailBlock.text.length,
    };
  });
  endBlock.styles = [...(tailBlock?.styles || []), ...(endBlock?.styles || [])];

  newBlocks = removeSelectionStyle(newBlocks);
  newBlocks = addStyleToBlocks({
    startBlockIndex: startBlockIndex + blocksToInsert.length - 1,
    startLetterIndex: tailBlock.text.length,
    endBlockIndex: startBlockIndex + blocksToInsert.length - 1,
    endLetterIndex: tailBlock.text.length,
    blocks: newBlocks,
    styleFields: {
      isSelection: true,
    },
  });

  if (blocksToInsert?.length === 1) {
    return newBlocks;
  }

  return newBlocks;
};

export const insertPlainTextAtCursor = (blocks, textToInsert, boxes) => {
  const blocksToInsert = textToInsert?.split("\n")?.map(text => ({ text }));
  return insertBlocksAtCursor(blocks, blocksToInsert, boxes);
};

export const onCopy = (e, blocks = []) => {
  const { startBlockIndex, startLetterIndex, endBlockIndex, endLetterIndex } =
    getSelectionFromBlocks(blocks);

  const selectedBlocks = cloneDeep(
    blocks.slice(startBlockIndex, endBlockIndex + 1)
  );

  if (!selectedBlocks?.length) {
    return;
  }

  if (!selectedBlocks?.[0]?.styles) {
    selectedBlocks[0].styles = [];
  }
  if (startBlockIndex !== endBlockIndex) {
    selectedBlocks[0].styles = selectedBlocks[0].styles.map(style => {
      return {
        ...style,
        start: style.start - startLetterIndex,
        end: style.end - startLetterIndex,
      };
    });
    selectedBlocks[0].text = selectedBlocks[0].text.slice(startLetterIndex);

    last(selectedBlocks).text = last(selectedBlocks).text.slice(
      0,
      endLetterIndex
    );
  } else {
    const newText = selectedBlocks[0].text.slice(
      startLetterIndex,
      endLetterIndex
    );
    selectedBlocks[0].styles = selectedBlocks[0].styles
      .map(style => {
        return {
          ...style,
          start: style.start - startLetterIndex,
          end: style.end - startLetterIndex,
        };
      })
      ?.filter(
        style =>
          style?.start >= 0 && style?.end >= 0 && style?.end <= newText?.length
      );
    selectedBlocks[0].text = newText;
  }

  e.clipboardData.setData("text", selectedBlocks?.map(b => b.text)?.join("\n"));
  e.clipboardData.setData("json/bz-word-doc", JSON.stringify(selectedBlocks));
  e.preventDefault();
};

export const extendSelection = (
  blocksToExtend,
  boxes,
  boxIndex,
  letterIndex
) => {
  let blocks = cloneDeep(blocksToExtend);
  let {
    startBlockIndex,
    startLetterIndex,
    endBlockIndex: selBInd,
    endLetterIndex: selLetInd,
    isBackward,
  } = getSelectionFromBlocks(blocks);

  if (isBackward) {
    startBlockIndex = selBInd;
    startLetterIndex = selLetInd;
  }

  const box = boxes?.[boxIndex];
  let endBlockIndex = box?.blockIndex;
  let endLetterIndex = box?.blockStartIndex + letterIndex;

  const blocksWithoutSelection = removeSelectionStyle(blocks);
  const blocksWithSelection = addStyleToBlocks({
    startBlockIndex,
    startLetterIndex,
    endBlockIndex,
    endLetterIndex,
    blocks: blocksWithoutSelection,
    styleFields: {
      isBackward: isEndBeforeStart({
        startBlockIndex,
        startLetterIndex,
        endBlockIndex,
        endLetterIndex,
      }),
      isSelection: true,
    },
  });

  return blocksWithSelection;
};

export const getImageHoverLocation = (e, boxUnderMouse, contentCanvasRef) => {
  if (!boxUnderMouse?.image) {
    return [""];
  }
  let { offsetX, offsetY } = e.nativeEvent;
  offsetX = offsetX - contentCanvasRef?.current?.getBoundingClientRect().x;

  const { x, y, w, h } = boxUnderMouse;

  const xWithinImg = offsetX - x;
  const yWithinImg = offsetY - y;

  if (
    inRange(xWithinImg, w - 40, w + 40) &&
    inRange(yWithinImg, h - 40, h + 40)
  ) {
    return ["bottomRightCorner", xWithinImg, yWithinImg];
  }

  return [""];
};

export const changeTableStructure = ({
  blocks,
  contextBlockIndex,
  action = "",
}) => {
  let newBlocks = cloneDeep(blocks);
  const contextBlock = newBlocks?.[contextBlockIndex];

  if (action === "deleteRow") {
    newBlocks = newBlocks
      ?.map(block => {
        if (block?.tableId !== contextBlock?.tableId) {
          return block;
        }

        if (block?.rowIndex === contextBlock?.rowIndex) {
          return null;
        }

        if (block?.rowIndex > contextBlock?.rowIndex) {
          return {
            ...block,
            rowIndex: block?.rowIndex - 1,
            numberOfRows: block?.numberOfRows - 1,
          };
        }

        return { ...block, numberOfRows: block?.numberOfRows - 1 };
      })
      ?.filter(isTruthy);
  }

  if (action === "deleteColumn") {
    newBlocks = newBlocks
      ?.map(block => {
        if (block?.tableId !== contextBlock?.tableId) {
          return block;
        }

        if (block?.columnIndex === contextBlock?.columnIndex) {
          return null;
        }

        if (block?.columnIndex > contextBlock?.columnIndex) {
          return {
            ...block,
            columnIndex: block?.columnIndex - 1,
            numberOfColumns: block?.numberOfColumns - 1,
          };
        }

        return { ...block, numberOfColumns: block?.numberOfColumns - 1 };
      })
      ?.filter(isTruthy);
  }

  if (action === "deleteTable") {
    newBlocks = newBlocks
      ?.map(block => {
        if (block?.tableId !== contextBlock?.tableId) {
          return block;
        }

        return null;
      })
      ?.filter(isTruthy);
  }

  const blocksWithoutSelection = removeSelectionStyle(newBlocks);
  let startBlockIndex = newBlocks?.findIndex(
    block => block?.tableId === contextBlock?.tableId
  );
  if (startBlockIndex === -1) {
    startBlockIndex = 0;
  }
  const blocksWithSelection = addStyleToBlocks({
    startBlockIndex: startBlockIndex,
    startLetterIndex: 0,
    endBlockIndex: startBlockIndex,
    endLetterIndex: 0,
    blocks: blocksWithoutSelection,
    styleFields: {
      isSelection: true,
    },
  });

  return blocksWithSelection;
};
