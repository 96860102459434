import { useState, useEffect } from "react";
import styled from "styled-components";

import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { cloneDeep, uniq } from "lodash";
import { uuidv4 } from "utils/common";
import { putPipelineConfig } from "api/backend/fileSystemEndpoints";
import { PencilIcon } from "components/ui/Icons";

const ModalContent = styled.div`
  width: 800px;
  height: 600px;
  padding: 20px;
  overflow: auto;
`;

const TableGroupName = styled.div`
  padding: 8px 0;
  font-weight: 600;
  margin-top: 20px;
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const Table = styled.table`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 12px;
  border-collapse: separate;
`;

const Tr = styled.tr`
  ${props =>
    props.isIndented &&
    `
  td:first-child {
    padding-left: 40px;
  }
  td {
    background-color: ${ORANGE};
  }
  `}
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const BottomButtons = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: white;
  width: 100%;
  padding: 10px 32px;
  display: grid;
  justify-content: end;
  border-top: 1px solid #eaeaea;
  border-radius: 12px;
  display: flex;
  gap: 10px;
`;

const ButtonWordIcon = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: max-content;
  svg {
    fill: white;
    height: 14px;
  }
`;

const BLUE = "#c0e3ffff";
const GREEN = "#b8f6c5ff";
const PURPLE = "#e2cfffff";
const ORANGE = "#ffe8d5";

const TABLE_COLUMNS = [
  { label: "Name", name: "name" },
  { label: "Action type", name: "actionType" },
  { label: "Description", name: "description" },
  { label: "Data Type", name: "type" },
];

const TableRow = ({
  column = {},
  columns = [],
  setColumns = () => {},
  isIndented = false,
}) => {
  return (
    <Tr isIndented={isIndented}>
      {TABLE_COLUMNS.map(tableColumn => {
        const onChange = e => {
          const newColumns = cloneDeep(columns);
          const columnToEdit = newColumns.find(col => col.id === column.id);
          columnToEdit[tableColumn?.name] = e.target.value;
          setColumns(newColumns);
        };

        if (tableColumn?.name === "actionType") {
          let style = { backgroundColor: BLUE };
          if (column[tableColumn?.name] === "manual") {
            style = { backgroundColor: GREEN };
          }
          if (column[tableColumn?.name] === "reasoning") {
            style = { backgroundColor: PURPLE };
          }

          return (
            <Td key={tableColumn?.name}>
              <StyledSelect
                disabled
                style={style}
                value={column[tableColumn?.name]}
                onChange={onChange}
              >
                <option value="extraction">Extraction</option>
                <option value="manual">Manual</option>
                <option value="reasoning">Reasoning</option>
              </StyledSelect>
            </Td>
          );
        }

        if (tableColumn?.name === "description") {
          return (
            <Td key={tableColumn?.name}>
              <StyledInput
                value={column[tableColumn?.name]}
                onChange={onChange}
              />
            </Td>
          );
        }

        if (tableColumn?.name === "type") {
          let style = { backgroundColor: BLUE };
          if (column[tableColumn?.name] === "NUMBER") {
            style = { backgroundColor: GREEN };
          }
          if (column[tableColumn?.name] === "DATETIME") {
            style = { backgroundColor: PURPLE };
          }
          if (column[tableColumn?.name] === "TABLE") {
            style = { backgroundColor: ORANGE };
          }

          return (
            <Td key={tableColumn?.name}>
              <StyledSelect
                disabled
                style={style}
                value={column[tableColumn?.name]}
                onChange={onChange}
              >
                <option value="TEXT">Text</option>
                <option value="NUMBER">Number</option>
                <option value="DATETIME">Datetime</option>
                <option value="TABLE">Table</option>
                <option value="EMAIL">Email</option>
              </StyledSelect>
            </Td>
          );
        }

        let columnName = column[tableColumn?.name];
        if (columnName?.includes("_")) {
          columnName = columnName?.split("_")?.[1];
        }
        return (
          <Td key={tableColumn?.name}>
            <StyledInput
              disabled
              placeholder={tableColumn?.name}
              value={columnName}
              onChange={onChange}
            />
          </Td>
        );
      })}
    </Tr>
  );
};

const EditPipelineModal = ({ pipelineConfig = {}, onPressSave = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    resetColumns();
  }, [JSON.stringify(pipelineConfig)]);

  const resetColumns = () => {
    const columnsWithAddedIds = pipelineConfig?.sourceTables?.[0]?.columns?.map(
      col => {
        const newCol = { ...col, id: uuidv4() };
        if (newCol?.tableColumns) {
          newCol.tableColumns = newCol.tableColumns.map(subCol => ({
            ...subCol,
            id: uuidv4(),
          }));
        }

        return newCol;
      }
    );
    setColumns(columnsWithAddedIds);
  };

  const groupNamesToUse = uniq(columns?.map(column => column.groupName)) || [];

  const doUpdatePipeline = async () => {
    setIsLoading(true);
    const newPipeline = cloneDeep(pipelineConfig);
    newPipeline.sourceTables[0].columns = columns;
    await putPipelineConfig(pipelineConfig.id, {}, newPipeline);
    setIsLoading(false);
    setIsOpen(false);
    onPressSave();
  };

  return (
    <>
      <ButtonWordIcon onClick={() => setIsOpen(true)}>
        <PencilIcon />
      </ButtonWordIcon>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <div style={{ overflow: "auto", paddingBottom: 60 }}>
            {groupNamesToUse.map(groupName => {
              let groupColumns = columns.filter(
                column => column.groupName === groupName
              );

              return (
                <div>
                  <TableGroupName>{groupName || "Record ID"}</TableGroupName>
                  <Table style={{ width: "100%" }}>
                    <thead>
                      {TABLE_COLUMNS.map(column => (
                        <Th key={column.value}>{column.label}</Th>
                      ))}
                    </thead>
                    <tbody>
                      {groupColumns.map((column, index) => {
                        if (column?.type === "TABLE") {
                          return (
                            <>
                              <TableRow
                                key={column?.id}
                                columns={columns}
                                setColumns={setColumns}
                                column={column}
                              />
                              <tr>
                                <Td
                                  style={{
                                    paddingLeft: "40px",
                                    backgroundColor: ORANGE,
                                    fontWeight: 500,
                                  }}
                                  colSpan={5}
                                >
                                  Set up columns for "{column?.name}"
                                </Td>
                              </tr>
                              {column?.tableColumns?.map(
                                (subColumn, subIndex) => (
                                  <TableRow
                                    isIndented
                                    key={subColumn?.id}
                                    columns={column?.tableColumns}
                                    setColumns={newSubColumns => {
                                      const newColumns = cloneDeep(columns);
                                      const columnToEdit = newColumns.find(
                                        col => col.id === column.id
                                      );
                                      columnToEdit.tableColumns = newSubColumns;
                                      setColumns(newColumns);
                                    }}
                                    column={subColumn}
                                  />
                                )
                              )}
                            </>
                          );
                        }

                        return (
                          <TableRow
                            key={column.id}
                            columns={columns}
                            setColumns={setColumns}
                            column={column}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              );
            })}
          </div>
          <BottomButtons>
            <ButtonWord
              disabled={isLoading}
              onClick={() => {
                resetColumns();
                setIsOpen(false);
              }}
            >
              Cancel
            </ButtonWord>
            <ButtonWord disabled={isLoading} onClick={doUpdatePipeline}>
              Save
            </ButtonWord>
          </BottomButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditPipelineModal;
