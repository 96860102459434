import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { cloneDeep, last } from "lodash";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import {
  postWordDoc,
  postWordDocsIdSaveAsTemplate,
} from "api/services/wordDocsService";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  gap: 15px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 32px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
  z-index: 1;

  position: sticky;
  top: 0px;
`;

const TableContainer = styled.div`
  min-width: 800px;
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 120px;
  padding: 0;
`;

const StyledInput = styled(StyledBoldInput)`
  font-weight: 400;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const PipelineNameModalTrigger = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [pipelineName, setPipelineName] = useState("");
  const [userColumnNames, setUserColumnNames] = useState([]);
  const [columnNameToDescription, setColumnNameToDescription] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setUserColumnNames([]);
  }, [isOpen]);

  const doSaveDocAsTemplate = async () => {
    setIsLoading(true);

    const wordDocBody = {
      fileName: pipelineName,
      content: {
        dashboardConfig: {
          _grid: [["all_records"]],
          all_records: {
            name: "All Records",
            type: "table",
            cellLocationToMeta: {},
            columnNames: ["emailId", "receivedAt", ...userColumnNames],
            columnNameToDescription,
            rows: [],
            columnNameToType: {},
          },
        },
        blocks: userColumnNames.map(columnName => {
          let text = `extract "${columnName}"`;
          if (columnNameToDescription[columnName]) {
            text = `extract "${columnName}", with description "${columnNameToDescription[columnName]}"`;
          }

          return {
            isQuery: true,
            text,
            queryId: uuidv4(),
          };
        }),
      },
    };

    const { data: newWordDoc, error: docCreateErr } = await postWordDoc(
      {},
      wordDocBody
    );
    if (docCreateErr) {
      setError(docCreateErr);
      setIsLoading(false);
      return;
    }
    const { data: newTemplateId, error: templateErr } =
      await postWordDocsIdSaveAsTemplate(newWordDoc?.id);
    if (templateErr) {
      setError(templateErr);
      setIsLoading(false);
      return;
    }

    navigate(`/template-dashboards/${newTemplateId}`);
    setIsLoading(false);
  };

  return (
    <>
      <ButtonCreateNewAsset onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Title>Pipeline Name</Title>
          <StyledTextInputSquare
            placeholder="Deal Extractor"
            value={pipelineName}
            onNewInput={newText => setPipelineName(newText)}
          />
          <Gap />
          Add columns you want extracted:
          <TableContainer>
            <table>
              <tr>
                <Th>Column Name</Th>
                <Th style={{ width: "100%" }}>Prompt</Th>
              </tr>
              <tr>
                <Td>
                  <BoldSpan>Email Subject</BoldSpan>
                </Td>
                <Td>The subject of the email</Td>
              </tr>
              <tr>
                <Td>
                  <BoldSpan>Received Time</BoldSpan>
                </Td>
                <Td>When the email was received</Td>
              </tr>
              {/* <tr>
                <Td>
                  <BoldSpan>Recieved At</BoldSpan>
                </Td>
                <Td>The date the email was recieved</Td>
              </tr> */}
              {userColumnNames?.map((columnName, i) => (
                <tr>
                  <Td key={i}>
                    <StyledBoldInput
                      value={columnName}
                      placeholder="Column Name"
                      onChange={e => {
                        const newColumnNames = [...userColumnNames];
                        newColumnNames[i] = e.target.value;
                        setUserColumnNames(newColumnNames);
                      }}
                    />
                    <StyledTrashIcon
                      onClick={() => {
                        const newColumnNames = [...userColumnNames];
                        newColumnNames.splice(i, 1);
                        setUserColumnNames(newColumnNames);

                        const newColumnNameToDescription = cloneDeep(
                          columnNameToDescription
                        );
                        delete newColumnNameToDescription[columnName];
                        setColumnNameToDescription(newColumnNameToDescription);
                      }}
                    />
                  </Td>
                  <Td>
                    <StyledInput
                      style={{ width: "100%" }}
                      value={columnNameToDescription[columnName]}
                      placeholder="Description"
                      onChange={e => {
                        const newColumnNameToDescription = {
                          ...columnNameToDescription,
                        };
                        newColumnNameToDescription[columnName] = e.target.value;
                        setColumnNameToDescription(newColumnNameToDescription);
                      }}
                    />
                  </Td>
                </tr>
              ))}
              <tr>
                <Td style={{ border: "none" }}>
                  <StyledPlusIcon
                    isDisabled={last(userColumnNames) === ""}
                    onClick={() => {
                      setUserColumnNames([...userColumnNames, ""]);
                    }}
                  />
                </Td>
              </tr>
            </table>
          </TableContainer>
          <Gap />
          <StyledButton
            variant="highlighted"
            isDisabled={!pipelineName}
            value="Create"
            onClick={doSaveDocAsTemplate}
          />
          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default PipelineNameModalTrigger;
