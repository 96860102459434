import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const postWordDocIngestToBoltzhub = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/word-docs/ingest-to-boltzhub",
    params,
    body
  );
  return { data, error };
};

export const postTableHeaderLabel = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/ml/table_header",
    params,
    body
  );
  return { data, error };
};

export const getMetas = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/metas/search",
    params
  );
  return { data, error };
};

export const deleteMetasRemoveKey = async (fileId, params = {}, body = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/chat-service/api/v1/metas/remove-key/${fileId}`,
    params,
    body
  );
  return { data, error };
};

export const patchMetasUpdate = async (fileId, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/chat-service/api/v1/metas/update/${fileId}`,
    params,
    body
  );
  return { data, error };
};

export const getSigmas = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/sigmas",
    params
  );
  return { data, error };
};

export const patchSigma = async (id, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/chat-service/api/v1/sigmas/${id}`,
    params,
    body
  );
  return { data, error };
};

export const postEmailsIngestToBoltzhub = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/emails/ingest-to-boltzhub",
    params,
    body
  );
  return { data, error };
};

export const getTemplateDashboardConfig = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/template-dashboard-config",
    params
  );
  return { data, error };
};

export const patchDashboardConfig = async (id = "", params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/chat-service/api/v1/dashboard-configs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const postGenerateComponent = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/dashboard-configs/generate-component",
    params,
    body
  );
  return { data, error };
};

export const postDashboardConfigCalculateResults = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/chat-service/api/v1/dashboard-configs/${id}/calculate-results`,
    params,
    body
  );
  return { data, error };
};

export const postDashboardConfigExecute = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/chat-service/api/v1/dashboard-config-execute`,
    params,
    body
  );
  return { data, error };
};

export const getKnowledgeConfigs = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/knowledge-configs",
    params
  );
  return { data, error };
};

export const getKnowledgeConfig = (id = "", params = {}) => {
  const { data, error } = zapiGet(
    `/solutions/ocr/chat-service/api/v1/knowledge-configs/${id}`,
    params
  );
  return { data, error };
};

export const postKnowledgeConfig = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/knowledge-configs",
    params,
    body
  );
  return { data, error };
};

export const patchKnowledgeConfig = async (id = "", params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/chat-service/api/v1/knowledge-configs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const deleteKnowledgeConfig = async (id = "", params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/chat-service/api/v1/knowledge-configs/${id}`,
    params
  );
  return { data, error };
};

export const postAiAgentGenerate = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/ai-agent/generate",
    params,
    body
  );
  return { data, error };
};

export const postFlowDatabaseTemplatesBuild = async (
  templatedId = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/chat-service/api/v1/flow-databases-templates/${templatedId}/build-database`,
    params,
    body
  );
  return { data, error };
};

export const getFlowDatabaseTemplates = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/flow-databases-templates",
    params
  );
  return { data, error };
};

export const deleteFlowDatabaseTemplates = async (id = "", params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/chat-service/api/v1/flow-databases-templates/${id}`,
    params
  );
  return { data, error };
};

export const postFlowDatabaseTemplates = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/flow-databases-templates",
    params,
    body
  );
  return { data, error };
};

export const patchFlowDatabaseTemplates = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/chat-service/api/v1/flow-databases-templates/${id}`,
    params,
    body
  );
  return { data, error };
};

export const postChatIngestToBoltzhb = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/word-docs/chat-ingest-to-boltzhub",
    params,
    body
  );
  return { data, error };
};

export const postSigmasUploadCsv = async (
  pipelineId = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/bz-api/v1/pipeline-configs/${pipelineId}/upload-csv`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineBulkIngest = async (
  pipelineId = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/bz-api/v1/pipeline-configs/${pipelineId}/bulk-ingest`,
    params,
    body
  );
  return { data, error };
};
