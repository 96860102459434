import styled from "styled-components";
import { useState, useEffect } from "react";

import { CenteredWithTopNavLayout } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import { deleteTagById, getTags } from "api/services/tagsService";
import { parseJson } from "utils/common";
import { Link } from "react-router-dom";
import CreateTagModal from "components/widgets/CreateTagModal";

const PackageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 20px;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px 100px 1fr auto;
  align-items: center;

  gap: 20px;
  padding: 12px 20px;
  color: white;
  background: linear-gradient(to right, ${props => props.theme.color.feature}, ${props => props.theme.color.primary});

  font-weight: 600;
`;

const PackageRow = styled(HeaderRow)`
  align-items: start;
  font-weight: normal;
  color: ${props => props.theme.color.closest};

  background: none;
  background-color: ${props =>
    props.rowIndex % 2 !== 0 ? props.theme.color.closer0 : `${props.theme.color.closer1}33`};

  :hover {
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.closer1}BB;
  }

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

const BoldSpan = styled.span`
  font-weight: 600;
  line-height: 1.2em;
`;

const TitleAndCreateNew = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TagMetaRow = styled.div`
  display: grid;
  width: 280px;
  grid-template-columns: auto 200px;
  align-items: center;
  margin-bottom: 5px;
`;

const TagsPage = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    doPopulateTags();
  }, []);

  const doPopulateTags = async () => {
    const { data } = await getTags();
    setTags(data);
  };

  return (
    <CenteredWithTopNavLayout>
      <TitleAndCreateNew>
        <BigTitle>My tags</BigTitle>
        <CreateTagModal onTagCreated={newTag => setTags([...tags, newTag])} />
      </TitleAndCreateNew>
      <PackageListContainer>
        <HeaderRow>
          <div>Name</div>
          <div>Created at</div>
          <div>Sub properties</div>
        </HeaderRow>
        {tags?.map(tag => (
          <PackageRow key={tag?.name}>
            <Link to={`/tags/${tag?.id}`}>{tag?.name}</Link>
            <div>{tag?.createdAt}</div>
            <div>
              {Object?.entries(parseJson(tag?.metaJson))?.map(([key, value]) => {
                return (
                  <TagMetaRow key={key}>
                    <BoldSpan>{key}</BoldSpan>
                    <input
                      value={value}
                      onChange={e => {
                        const newValue = e.target.value;
                        const newTags = tags.map(t => {
                          if (t?.id === tag?.id) {
                            const newJsonValue = parseJson(t?.jsonValue);
                            newJsonValue[key] = newValue;
                            t.jsonValue = JSON.stringify(newJsonValue);
                          }
                          return t;
                        });
                        setTags(newTags);
                      }}
                    />
                  </TagMetaRow>
                );
              })}
            </div>
            <button
              onClick={async () => {
                await deleteTagById(tag?.id);
                setTags(tags.filter(t => t?.id !== tag?.id));
              }}
            >
              Delete
            </button>
          </PackageRow>
        ))}
      </PackageListContainer>
    </CenteredWithTopNavLayout>
  );
};

export default TagsPage;
