import { PlusIcon } from "components/ui/Icons";
import styled from "styled-components";
import { useState } from "react";

import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SmallButton from "components/ui/SmallButton";
import { useEffect } from "react";
import ErrorMessageModal from "components/ErrorMessageModal";
import { postUserGroups } from "api/services/authenticationService";

const PlusButton = styled(PlusIcon)`
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  width: 22px;
  height: 22px;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  border-radius: 5px;
  display: grid;
  gap: 10px;
  justify-items: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const NewFolderText = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 4px 20px;
`;

const AddMemberToUserGroupModalTrigger = ({
  userGroup = "",
  onCreationSuccess = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newMemberUserName, setNewMemberUserGroupName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNewMemberUserGroupName("");
    setIsCreating(false);
  }, [isOpen]);

  const doPostNewUserGroup = async () => {
    setIsCreating(true);

    const { error } = await postUserGroups(
      {},
      {
        id: userGroup?.id,
        sharedWith: [
          ...userGroup?.sharedWith,
          {
            userGroupId: newMemberUserName,
            accessLevel: "MEMBER",
          },
        ],
      }
    );
    setError(error);
    setIsCreating(false);

    if (!error) {
      onCreationSuccess();
      setIsOpen(false);
    }
  };

  return (
    <>
      <PlusButton onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isCreating}>
          <NewFolderText>New team member for "{userGroup?.name}"</NewFolderText>
          <TextInputSquare
            placeholder="Username"
            value={newMemberUserName}
            onNewInput={newName => setNewMemberUserGroupName(newName)}
          />
          <StyledSmallButton
            isDisabled={!newMemberUserName}
            onClick={doPostNewUserGroup}
            value="Add"
          />
        </ModalContent>
      </Modal>
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </>
  );
};

export default AddMemberToUserGroupModalTrigger;
