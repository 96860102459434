import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Email, Folder, GridOn } from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { deleteFileById } from "api/services/filesService";
import { deleteDirectory, getDirectoryItems } from "api/services/fileSystem";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  deleteExcelModelById,
  postExcelModelDraft,
} from "api/services/excelModelsService";
import { deleteWordDoc, postWordDoc } from "api/services/wordDocsService";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";

import { CenteredWithTopNavLayout } from "components/Layout";
import Tooltip from "components/ui/Tooltip";
import FileInput from "components/inputs/FileInput";
import useSearchParamsState from "hooks/useSearchParamsState";
import FileActionsDropdown from "components/FileActionsDropdown";
import CreateDirectoryModalTrigger from "components/CreateDirectoryModalTrigger";
import { isTruthy, uuidv4 } from "utils/common";
import MoveItemsToDirectoryModalTrigger from "components/MoveItemsToDirectoryModalTrigger";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";
import useDirectoryPagination from "hooks/useDirectoryPagination";
import {
  ChatIcon,
  CrossIcon,
  ExcelIcon,
  LogoInner,
  PlusIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import { format } from "date-fns";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import { Fragment } from "react";
import {
  deleteQuickStart,
  getQuickStarts,
  patchQuickStart,
} from "api/services/quickStarts";
import { deleteSlideDoc, postSlideDoc } from "api/services/slideDocsService";
import { triggerDownloadOfSlideDoc } from "api/backend/slideDocsEndpoints";
import InputWithStateAdjusting from "components/InputWithStateAdjusting";
import NewDocConfigModalTrigger from "components/NewDocConfigModalTrigger";

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding-bottom: 100px;
`;

const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const CreationBtns = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 0;
`;

const BigTitle = styled(Link)`
  font-size: 24px;
  font-weight: 600;

  text-decoration: none;
  color: ${props => props.theme.color.closest};

  display: flex;
  gap: 12px;
  align-items: center;
`;

const SmallFileInput = styled(FileInput)`
  padding: 8px;
  svg {
    fill: ${props => props.theme.color.closer2};
  }
`;

const DirectoryPath = styled.div`
  font-size: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Td = styled.td`
  max-width: 780px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 22px;
  background-color: ${props => props.theme.color.closer0};
  vertical-align: middle;
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const HeaderTr = styled.tr`
  color: white;
  background: linear-gradient(
    to right,
    ${props => props.theme.color.feature},
    ${props => props.theme.color.primary}
  );
  font-weight: 600;

  ${Td} {
    background-color: transparent;
  }
`;

const IconAndName = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ItemName = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleAndPath = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DirLink = styled(Link)`
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const QuickStarts = styled.div`
  padding: 30px 20px;
  margin: 20px 0;
  background-color: #eaf0f8;
  box-shadow: ${props => props.theme.shadow};

  display: grid;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  justify-content: start;
  row-gap: 20px;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const QuickStartsTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  padding: 4px;

  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
`;

const QuickStartsCard = styled.div`
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: max-content;
  padding: 8px 40px;
  cursor: pointer;
  position: relative;
  :hover {
    background-color: ${props => props.theme.color.closer1}BB;
    ${StyledCrossIcon} {
      visibility: visible;
    }
  }
`;

const QuickStartsThumbnail = styled.div`
  width: 74px;
  height: 96px;
  background-color: ${props => props.theme.color.furthest};
  box-shadow: ${props => props.theme.shadow};
`;

const QuickStartsCardTitleInput = styled(InputWithStateAdjusting)`
  font-weight: 600;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: 120px;

  background-color: transparent;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border: 1px solid ${props => props.theme.color.primary};
  :hover {
    background-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }
  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border: 2px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }
  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const RESERVED_QUICK_START_NAMES = ["Blank"];

const WorkingFilesPage = () => {
  const loggedInUserName = getLoggedInUserName();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [directoryPath] = useSearchParamsState({
    paramName: "directoryPath",
    initialValue: "/working-files",
  });
  const [directoryItems, setDirectoryItems] = useState([]);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
  const [quickStarts, setQuickStarts] = useState([]);
  const [error, setError] = useState(null);

  const [
    pageSize,
    offset,
    prevUrl,
    nextUrl,
    hasMore,
    setNextOffset,
    setHasMore,
  ] = useDirectoryPagination();

  useEffect(() => {
    doPopulateQuickStarts();
  }, []);

  useEffect(() => {
    doPopulateDirectoryItems();
  }, [directoryPath, offset, searchParams.get("type")]);

  const doPopulateDirectoryItems = async () => {
    setDirectoryItems([]);
    const { data } = await getDirectoryItems({
      directoryPath,
      pageSize,
      offset,
      type:
        searchParams.get("type") === "CHAT" ||
        searchParams.get("type") === "SLIDES"
          ? "WORD_DOC"
          : searchParams.get("type"),
    });
    setDirectoryItems(data?.items || []);
    setNextOffset(data?.offset);
    setHasMore(data?.hasMore);
  };

  const doPopulateQuickStarts = async () => {
    const { data } = await getQuickStarts();
    setQuickStarts(data?.items || []);
  };

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?.id);

    let error = null;

    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await deleteExcelModelById(item?.id));
    }

    if (item?.type === "FILE") {
      ({ error } = await deleteFileById(item?.id));
    }

    if (item?.type === "DIRECTORY") {
      ({ error } = await deleteDirectory({ directoryPath: item?.path }));
    }

    if (item?.type === "WORD_DOC") {
      ({ error } = await deleteWordDoc(item?.id));
    }

    if (item?.type === "SLIDE_DOC") {
      ({ error } = await deleteSlideDoc(item?.id));
    }

    setItemIdBeingDeleted(null);
    if (error) {
      setError(error);
      return;
    }
    setDirectoryItems(
      directoryItems.filter(dirItem => dirItem?.id !== item?.id)
    );
  };

  const dirNames = directoryPath?.split("/");
  const parentDirectoryPath = dirNames.slice(0, -1).join("/");

  return (
    <StyledCenteredWithTopNavLayout>
      <TopContent isDisabled={isCreatingNewItem}>
        <TitleAndPath>
          <BigTitle to="/working-files">
            <LogoInner style={{ height: "20px" }} />
            Apps
          </BigTitle>
          <DirectoryPath>
            {dirNames?.length > 6 && "..."}
            {dirNames
              .filter(isTruthy)
              .filter(dirName => dirName !== "working-files")
              .map((dirName, i) => {
                if (dirNames?.length > 5 && i + 3 < dirNames?.length - 3) {
                  return null;
                }

                return (
                  <Fragment key={i}>
                    &nbsp;/&nbsp;
                    <DirLink
                      to={`?directoryPath=${dirNames
                        ?.slice(0, i + 3)
                        .join("/")}`}
                    >
                      {dirName}
                    </DirLink>
                  </Fragment>
                );
              })}
          </DirectoryPath>
        </TitleAndPath>
      </TopContent>

      <QuickStarts>
        <QuickStartsTitle>Create new</QuickStartsTitle>
        {quickStarts?.map((quickStart, i) => (
          <QuickStartsCard
            key={i}
            onClick={async () => {
              setIsCreatingNewItem(true);
              const { data, error } = await postWordDoc(
                { directoryPath },
                {
                  fileName: `Untitled Doc`,
                  content: quickStart?.content,
                }
              );
              if (!error) {
                navigate(`/word-docs/${data?.id}`);
              }
              setIsCreatingNewItem(false);
            }}
          >
            <QuickStartsThumbnail />
            <QuickStartsCardTitleInput
              initialValue={quickStart?.fileName}
              onApplyValue={async fileName => {
                await patchQuickStart(quickStart?.id, {}, { fileName });
              }}
            />
            {!RESERVED_QUICK_START_NAMES?.includes(quickStart?.fileName) && (
              <StyledCrossIcon
                onClick={async e => {
                  e.stopPropagation();
                  await deleteQuickStart(quickStart?.id);
                  await doPopulateQuickStarts();
                }}
              />
            )}
          </QuickStartsCard>
        ))}
      </QuickStarts>

      <CreationBtns>
        <NewDocConfigModalTrigger
          triggerIcon={
            <Tooltip title="New Smart Doc">
              <IconContainer>
                <PlusIcon />
              </IconContainer>
            </Tooltip>
          }
        />
        <Tooltip title="Quick chat">
          <StyledLink to="/quick-chat">
            <ChatIcon />
          </StyledLink>
        </Tooltip>
        <Tooltip title="New folder">
          <CreateDirectoryModalTrigger
            onDirectryCreated={doPopulateDirectoryItems}
            directoryPath={directoryPath}
          />
        </Tooltip>
        <Tooltip title="New Word doc">
          <ButtonCreateNewAsset
            icon={<WordIcon />}
            isDisabled={isCreatingNewItem}
            onClick={async () => {
              setIsCreatingNewItem(true);
              const { data, error } = await postWordDoc(
                { directoryPath },
                {
                  fileName: `Untitled Doc`,
                  content: { blocks: [{ text: "" }] },
                }
              );
              if (!error) {
                navigate(`/word-docs/${data?.id}`);
              }
              setIsCreatingNewItem(false);
            }}
          />
        </Tooltip>
        <Tooltip title="New Excel file">
          <ButtonCreateNewAsset
            icon={<ExcelIcon />}
            isDisabled={isCreatingNewItem}
            onClick={async () => {
              setIsCreatingNewItem(true);
              const { data: newModel, error } = await postExcelModelDraft({
                directoryPath,
              });
              if (!error) {
                navigate(`/excel-views/${newModel?.id}`);
              }
              setIsCreatingNewItem(false);
            }}
          />
        </Tooltip>
        {/* <Tooltip title="Upload Excel file">
          <SmallFileInput
            buttonText=""
            isShowingFileChosenText={false}
            isDisabled={isUploadingFile}
            onFileSelect={async newFile => {
              setIsUploadingFile(true);
              const { data, error: errUpload } = await postExcelModelUploadFile(
                newFile
              );
              if (errUpload) {
                return;
              }

              navigate(`/excel-views/${data?.id}`);
              setIsUploadingFile(false);
            }}
          />
        </Tooltip> */}
        <Tooltip title="New Chat">
          <ButtonCreateNewAsset
            icon={<ChatIcon />}
            isDisabled={isCreatingNewItem}
            onClick={async () => {
              setIsCreatingNewItem(true);
              const { data, error } = await postWordDoc(
                { directoryPath },
                {
                  fileName: `**CHAT**Untitled Chatbot`,
                  content: {
                    hasNotBeenSetup: true,
                    blocks: [],
                  },
                }
              );
              if (!error) {
                navigate(`/word-docs/${data?.id}/chat`);
              }
              setIsCreatingNewItem(false);
            }}
          />
        </Tooltip>
        <Tooltip title="New Presentation">
          <ButtonCreateNewAsset
            icon={<SlidesIcon style={{ fill: "#e5bd61" }} />}
            isDisabled={isCreatingNewItem}
            onClick={async () => {
              setIsCreatingNewItem(true);
              const { data, error } = await postSlideDoc(
                { directoryPath },
                {
                  fileName: `Untitled Presentation`,
                  content: {
                    slides: [
                      {
                        boxes: [
                          {
                            id: uuidv4(),
                            x: 100,
                            y: 100,
                            w: 200,
                            h: 150,
                            text: "Add text here",
                            styles: [],
                            boxStyle: {
                              bgColor: "#ffffff",
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#000000",
                            },
                            isSelected: true,
                          },
                        ],
                      },
                    ],
                  },
                }
              );
              if (!error) {
                navigate(`/slide-docs/${data?.id}`);
              }
              setIsCreatingNewItem(false);
            }}
          />
        </Tooltip>
      </CreationBtns>
      <Table>
        <thead>
          <HeaderTr>
            <Td>Name</Td>
            <Td>Created at</Td>
            <Td>Owner</Td>
            <Td></Td>
          </HeaderTr>
        </thead>
        <tbody>
          {directoryItems
            ?.filter(item => {
              const type = searchParams.get("type");
              if (type === "CHAT") {
                return item?.fileName?.includes("**CHAT**");
              }

              if (type === "SLIDES") {
                return item?.fileName?.includes("**SLIDES**");
              }

              return (
                !item?.fileName?.includes("**CHAT**") &&
                !item?.fileName?.includes("**SLIDES**")
              );
            })
            ?.map((item, i) => {
              let itemIcon = null;
              let itemName = item?.originalName || item?.id;
              let itemUrl = `/working-files?directoryPath=${directoryPath}`;
              if (item?.fileName?.includes(".pdf")) {
                itemIcon = <PictureAsPdfIcon style={{ fill: "#d9534f" }} />;
                itemName = item?.fileName;
                itemUrl = `/file/${item?.id}`;
              }
              if (item?.fileName?.includes(".xlsx")) {
                itemIcon = <GridOn style={{ fill: "#188038" }} />;
                itemName = item?.fileName;
                itemUrl = `/file/${item?.id}`;
              }
              if (item?.type === "DIRECTORY") {
                itemIcon = <Folder style={{ fill: "grey" }} />;
                itemName = item?.path
                  ?.replace(directoryPath, "")
                  ?.replace("/", "");
                itemUrl = `/working-files?directoryPath=${item?.path}`;
              }
              if (item?.type === "EMAIL_FILE") {
                itemIcon = <Email style={{ fill: "grey" }} />;
                itemName = item?.emailSubject;
              }
              if (item?.type === "WORD_DOC") {
                itemIcon = <WordIcon />;
                itemName = item?.fileName;
                itemUrl = `/word-docs/${item?.id}`;
              }
              if (item?.type === "EXCEL_MODEL") {
                itemIcon = <ExcelIcon />;
                itemName = item?.name;
                itemUrl = `/excel-views/${item?.id}`;
              }
              if (searchParams.get("type") === "CHAT") {
                itemIcon = <ChatIcon />;
                itemName = item?.fileName?.replace("**CHAT**", "");
                itemUrl = `/word-docs/${item?.id}/chat`;
              }
              if (item?.type === "SLIDE_DOC") {
                itemIcon = <SlidesIcon style={{ fill: "#a3853f" }} />;
                itemName = item?.fileName;
                itemUrl = `/slide-docs/${item?.id}`;
              }

              const fileActions =
                item?.createdBy === loggedInUserName ? ["Delete"] : [];

              return (
                <Tr
                  isDisabled={itemIdBeingDeleted === item?.id}
                  key={`${item?.id}-${i}`}
                >
                  <Td width="100%">
                    <IconAndName
                      isDisabled={item?.status === "IN_PROGRESS"}
                      href={itemUrl}
                      target="_blank"
                    >
                      {itemIcon}
                      <ItemName>{itemName}</ItemName>
                    </IconAndName>
                  </Td>
                  <Td>
                    {format(
                      new Date(item?.createdAt || item?.createdAtMs),
                      "d MMM yyyy"
                    )}
                  </Td>
                  <Td>
                    {item?.createdBy === loggedInUserName
                      ? "me"
                      : item?.createdBy}
                  </Td>
                  <Td>
                    <ActionsContainer>
                      <FileActionsDropdown
                        visibleActions={fileActions}
                        onClickShare={() => {}}
                        onClickDelete={() => doDeleteItem(item)}
                        onClickDownload={() => {
                          if (item?.type === "SLIDE_DOC") {
                            triggerDownloadOfSlideDoc(item?.id);
                            return;
                          }

                          triggerDownloadOfFile(item?.id, {
                            fileType: item?.type,
                          });
                        }}
                        additionalActions={[
                          <MoveItemsToDirectoryModalTrigger
                            id={item?.id}
                            itemType={item?.type}
                            directoryPath={directoryPath}
                            parentDirectoryPath={parentDirectoryPath}
                            directoryOptionItems={directoryItems?.filter(
                              item => item?.type === "DIRECTORY"
                            )}
                            onItemMoved={doPopulateDirectoryItems}
                          />,
                          <ShareDirectoryItemModalTrigger
                            directoryItem={item}
                          />,
                        ]}
                      />
                    </ActionsContainer>
                  </Td>
                </Tr>
              );
            })}
        </tbody>
      </Table>
      <PrevNextPageButtons
        onClickPrev={() => navigate(prevUrl)}
        isPrevDisabled={!offset}
        onClickNext={() => navigate(nextUrl)}
        isNextDisabled={!hasMore}
      />
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </StyledCenteredWithTopNavLayout>
  );
};

export default WorkingFilesPage;
