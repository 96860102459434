import { zapiDelete, zapiGet, zapiPost } from "api/zapi";

export const getTags = async (params = {}) => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/tags`, params);
  return { data, error };
};

export const postTag = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(`/solutions/ocr/api/v1/tags`, params, body);
  return { data, error };
};

export const deleteTagById = async tagId => {
  const { data, error } = await zapiDelete(`/solutions/ocr/api/v1/tags/${tagId}`);
  return { data, error };
};
