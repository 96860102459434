import styled from "styled-components";

import { CenteredWithTopNavLayout } from "components/Layout";
import NavHeader from "components/NavHeader";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { TrashIcon } from "components/ui/Icons";
import { SIDEBAR_ITEMS, SideBarItem } from "pages/profile-new";
import { useState } from "react";
import { useEffect } from "react";
import {
  getUserGroups,
  postUserGroups,
} from "api/services/authenticationService";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import TooltipClick from "components/ui/TooltipClick";
import Tooltip from "components/ui/Tooltip";
import CreateUserGroupModalTrigger from "components/CreateUserGroupModalTrigger";
import AddMemberToUserGroupModalTrigger from "components/AddMemberToUserGroupModalTrigger";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import FileSideBar from "components/FileSideBar";
import LayoutNew from "components/LayoutNew";
import ButtonWord from "components/ui/ButtonWord";

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  background-color: #ffffff;
  padding: 0;
  height: 100vh;
  grid-template-rows: auto 1fr;
`;

const StyledNavHeader = styled(NavHeader)`
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  box-shadow: none;
`;

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 24px;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const ProfileContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px;
  gap: 50px;
  background-color: #ffffff;
  border-top: 1px solid #00000080;
`;

const SideBar = styled.div`
  padding-top: 16px;
`;

const ProfileFields = styled.div`
  display: grid;
  max-width: 900px;
  margin-top: 10px;
  margin-right: 50px;
  align-content: start;
  gap: 20px;
`;

const Table = styled.table`
  width: max-content;
  font-size: 16px;
  ${props => props.isDisabled && "opacity: 0.5;"}
`;

const Td = styled.td`
  padding: 8px 12px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tr = styled.tr`
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const Th = styled.th`
  padding: 4px;
  font-weight: 500;
  text-align: left;
  padding: 8px 12px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
`;

const COLUMNS = [
  {
    name: "userGroupId",
    label: "Username",
  },
  {
    name: "accessLevel",
    label: "Access Level",
    options: ["OWNER", "MEMBER"],
  },
];

const Trigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 700;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.closer1};
  }
`;

const TooltipOption = styled.div`
  cursor: pointer;
  display: flex;
  gap: 20px;
  padding: 8px 12px;
  align-items: center;
  width: 200px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StyledTooltip = styled(Tooltip)`
  padding: 4px 8px;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.3);
  transform: translateX(0%) translateY(10%);
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const ProfilePageTeams = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroupId, setSelectedUserGroupId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateUserGroups();
  }, []);

  const doPopulateUserGroups = async () => {
    setIsLoading(true);
    const { data } = await getUserGroups();
    setUserGroups(data);
    if (!selectedUserGroupId) {
      setSelectedUserGroupId(data?.[0]?.id);
    }
    setIsLoading(false);
  };

  const doRemoveMember = async userGroupId => {
    setIsLoading(true);
    const { error } = await postUserGroups(
      {},
      {
        id: selectedUserGroup?.id,
        sharedWith: selectedUserGroup?.sharedWith.filter(
          sharedWith => sharedWith?.userGroupId !== userGroupId
        ),
      }
    );
    setError(error);
    doPopulateUserGroups();
  };

  const doUpdateAccess = async (userGroupId, accessLevel) => {
    setIsLoading(true);
    const { error } = await postUserGroups(
      {},
      {
        id: selectedUserGroup?.id,
        sharedWith: selectedUserGroup?.sharedWith.map(sharedWith => {
          if (sharedWith?.userGroupId === userGroupId) {
            return {
              ...sharedWith,
              accessLevel,
            };
          }
          return sharedWith;
        }),
      }
    );
    setError(error);
    doPopulateUserGroups();
  };

  const selectedUserGroup = userGroups.find(
    userGroup => userGroup?.id === selectedUserGroupId
  );

  return (
    <LayoutNew>
      <Container>
        <Title>Teams</Title>
        <BoldSpan>
          Team management is only available in the “Teams” plan
        </BoldSpan>
        <ButtonWord
          isPrimary
          style={{ width: 200, height: 40 }}
          onClick={() => {
            // go to /profile/plan, while keeping search params
            navigate(`/profile/plan?${searchParams.toString()}`);
          }}
        >
          See plans
        </ButtonWord>
      </Container>
    </LayoutNew>
  );

  // return (
  //   <LayoutNew>
  //     <Title>Teams</Title>
  //     <ProfileContent>
  //       <ProfileFields>
  //         <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
  //           <NavWithTabsWithArrows
  //             tabNames={userGroups.map(userGroup => userGroup?.name)}
  //             selectedTabName={
  //               userGroups?.find(
  //                 userGroup => userGroup?.id === selectedUserGroupId
  //               )?.name
  //             }
  //             onTabSelect={tabName => {
  //               const selectedUserGroup = userGroups.find(
  //                 userGroup => userGroup?.name === tabName
  //               );
  //               setSelectedUserGroupId(selectedUserGroup?.id);
  //             }}
  //             tabOptionStyle={{
  //               padding: "8px 14px",
  //             }}
  //           />
  //           <StyledTooltip title="Add team">
  //             <CreateUserGroupModalTrigger
  //               onCreationSuccess={doPopulateUserGroups}
  //             />
  //           </StyledTooltip>
  //         </div>
  //         <ConfirmationModalTrigger
  //           message={`Are you sure you want to delete team "${selectedUserGroup?.name}"?`}
  //           onClickYes={() => {
  //             //
  //           }}
  //         />
  //         <Table isDisabled={isLoading}>
  //           <tr>
  //             {COLUMNS?.map(column => (
  //               <Th>{column.label}</Th>
  //             ))}
  //             <Td style={{ width: "max-content" }}></Td>
  //           </tr>
  //           {selectedUserGroup?.sharedWith?.map(sharedWith => (
  //             <Tr>
  //               {COLUMNS?.map(column => {
  //                 if (column?.options) {
  //                   return (
  //                     <Td>
  //                       <StyledSelect
  //                         value={sharedWith?.accessLevel}
  //                         onChange={e =>
  //                           doUpdateAccess(
  //                             sharedWith?.userGroupId,
  //                             e.target.value
  //                           )
  //                         }
  //                       >
  //                         {column?.options.map(option => (
  //                           <option>{option}</option>
  //                         ))}
  //                       </StyledSelect>
  //                     </Td>
  //                   );
  //                 }

  //                 return <Td>{sharedWith[column.name]}</Td>;
  //               })}
  //               <Td style={{ width: "max-content" }}>
  //                 <TooltipClick
  //                   tipContent={
  //                     <div>
  //                       <TooltipOption
  //                         onClick={() =>
  //                           doRemoveMember(sharedWith?.userGroupId)
  //                         }
  //                       >
  //                         <TrashIcon height="14px" /> Remove
  //                       </TooltipOption>
  //                     </div>
  //                   }
  //                 >
  //                   <Trigger>⋮</Trigger>
  //                 </TooltipClick>
  //               </Td>
  //             </Tr>
  //           ))}
  //         </Table>
  //         <StyledTooltip title="Add member">
  //           <AddMemberToUserGroupModalTrigger
  //             userGroup={selectedUserGroup}
  //             onCreationSuccess={doPopulateUserGroups}
  //           />
  //         </StyledTooltip>
  //       </ProfileFields>
  //     </ProfileContent>
  //   </LayoutNew>
  // );
};

export default ProfilePageTeams;
