import styled from "styled-components";
import { useState, useRef } from "react";
import useClickOutside from "hooks/useClickOutside";
import HistoryIcon from "@material-ui/icons/History";

const OuterContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: max-content;
  z-index: 10;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-weight: bold;
  z-index: 9;
`;

const TooltipContainer = styled.div`
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  position: absolute;
  right: 0;
  overflow: hidden;

  margin-top: 10px;
  margin-right: 20px;
  background-color: ${props => props.theme.color.furthest};
  color: ${props => props.theme.color.closest};
  height: ${props => (props.isExpanded ? "auto" : 0)};
  border: ${props =>
    props.isExpanded && `1px solid ${props.theme.color.closer1}`};
`;

const ItemListPopover = ({
  title,
  children,
  iconComponent,
  className = "",
  isDisabled = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const outerContainerRef = useRef(null);

  useClickOutside(outerContainerRef, () => setIsExpanded(false));

  return (
    <OuterContainer ref={outerContainerRef} isDisabled={isDisabled}>
      <Title onClick={() => setIsExpanded(!isExpanded)}>
        {iconComponent || <HistoryIcon />}
        {title}
      </Title>
      <TooltipContainer className={className} isExpanded={isExpanded}>
        {children}
      </TooltipContainer>
    </OuterContainer>
  );
};

export default ItemListPopover;
