import styled from "styled-components";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteAccountSubscriptionsForceCancel,
  getActiveSubscription,
  patchSubscription,
  postPaymentSession,
} from "api/services/projectService";
import ErrorMessageModal from "components/ErrorMessageModal";
import { CircularProgress } from "@material-ui/core";
import { SIDEBAR_ITEMS, SideBarItem } from "pages/profile-new";
import FileSideBar from "components/FileSideBar";
import { patchSubscriptionCancel } from "api/backend/projectServiceEndpoints";
import LayoutNew from "components/LayoutNew";
import { CrossIcon, TickIcon } from "components/ui/Icons";
import { Cross } from "recharts";
import { COLOR1, COLOR2 } from "pages/login-v2";
import ButtonWord from "components/ui/ButtonWord";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import { getInvoices } from "api/backend/authenticationServiceEndpoints";
import { safeFormat } from "utils/common";

const PLAN_FIELDS = [
  "Access full to AI capabilities",
  "Users",
  "Priority",
  "Support",
  "Report Generation",
  "Pricing",
];

const ALL_TRUE_PLAN = {};
PLAN_FIELDS.forEach(field => {
  ALL_TRUE_PLAN[field] = true;
});

const PLANS = [
  {
    type: "PERSONAL",
    ...ALL_TRUE_PLAN,
    Support: false,
    "Report Generation": false,
    Pricing: false,
  },
  {
    type: "TEAM",
    ...ALL_TRUE_PLAN,
    "Report Generation": false,
    Pricing: false,
  },
  {
    type: "ENTERPRISE",
    ...ALL_TRUE_PLAN,
  },
];

const PlanTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

const Features = styled.div`
  display: grid;
  gap: 8px;
  padding-top: 12px;
  border-top: 1px solid #e8ecef;
`;

const LIP = 16;
const PlanCard = styled.div`
  position: relative;
  background-color: white;
  padding: 28px;
  border-right: 1px solid #f5f5f5;
  display: grid;
  gap: 12px;
  height: 444px;
  * {
    svg {
      fill: grey;
    }
  }

  ${props =>
    props.isBlack &&
    `
    background-color: #232627;
    top: -${LIP}px;
    height: calc(100% + ${2 * LIP}px);
    padding-top: calc(28px + ${LIP}px);
    padding-bottom: calc(28px + ${LIP}px);
    border-radius: 20px;

    * {
      color: white;
      svg {
        fill: grey;
      }
    }

    ${PlanTitle} {
      color: ${COLOR1};
    }
    ${Features} {
      border-top: 1px solid #343839;
    }
  `}

  ${props =>
    props?.isCurrent &&
    !props?.isBlack &&
    `
    background-color: #ffffff88;
  `}
`;

const PlanCards = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  ${PlanCard}:nth-child(1) {
    border-radius: 20px 0 0 20px;
  }
  // last
  ${PlanCard}:nth-child(4) {
    border-radius: 0 20px 20px 0;
    border-right: none;
  }
`;

const PlanDescriptionItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
`;

const PlanPrice = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const PlanPriceDescription = styled.div`
  color: #6c7275;
  line-height: 1.2;
`;

const FeatureItem = styled.div`
  font-size: 14px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 10px;
`;

const GreySpan = styled.span`
  font-size: 14px;
  color: #6c7275;
`;

const Container = styled.div`
  display: grid;
  align-content: start;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: #6c7275;
  text-align: center;
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const ProfileContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px;
  gap: 50px;
  background-color: #ffffff;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const Table = styled.table`
  max-width: 900px;
`;

const Td = styled.td`
  border-top: 1px solid #6c727526;
  border-bottom: 1px solid #6c727526;
  vertical-align: middle;
  padding: 22px 4px;
  font-size: 14px;
  ${props => props.planType === "Free" && "background-color: #D9D9D988;"}
  ${props => props.planType === "Enterprise" && "background-color: #D9D9D9;"}
  width: 300px;
`;

const Th = styled.th`
  vertical-align: middle;
  padding: 22px 4px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
`;

// const Button = styled.button`
//   outline: none;
//   border: none;
//   padding: 4px 12px;
//   cursor: pointer;
//   background-color: ${props =>
//     props.variant === "transparent" ? "transparent" : "#e0e0e0"};
//   font-family: "Montserrat";
//   border-radius: 4px;
//   font-weight: 500;
//   transition: background-color 0.2s;
//   pointer-events: ${props => (props.disabled ? "none" : "auto")};
//   :hover {
//     background-color: #d0d0d0;
//   }
// `;

const Button = styled(ButtonWord)`
  background-color: transparent;
  color: black;
  border: 1px solid #e8ecef;
  height: 42px;
  :hover {
    color: #959595;
  }

  :disabled {
    pointer-events: none;
    background: #efefef;
    color: grey;
  }

  ${props =>
    props?.isPrimary &&
    `
    border: none;
    :hover {
      color: #eaeaea;
    }
  `}
`;

const SUCCESS_URL = `${window.location.origin}/profile/plan`;
const CANCEL_URL = `${window.location.origin}/profile/plan`;

const COLUMNS = [
  { label: "Date of Issue", name: "period_start", type: "date-seconds" },
  { label: "Amount", name: "amount_due", type: "currency" },
  { label: "Status", name: "status" },
  { label: "Invoice PDF", name: "invoice_pdf" },
];

const ProfilePageInvoices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateActiveSubscription();
    doPopulateInvoices();
  }, []);

  const doPopulateActiveSubscription = async () => {
    setIsLoading(true);
    const { data } = await getActiveSubscription();
    setIsLoading(false);
    setActiveSubscription(data);
  };

  const doPopulateInvoices = async () => {
    const { data } = await getInvoices({ status: "PAID" });
    setInvoices(data);
  };

  const rowHeaders = PLAN_FIELDS;
  let userPlanType = activeSubscription?.plan;
  if (!userPlanType) {
    userPlanType = "";
  }

  if (isLoading) {
    return (
      <LayoutNew>
        <Container style={{ display: "grid", justifyContent: "center" }}>
          <CircularProgress
            thickness={10}
            size={40}
            style={{ marginTop: "20px", color: "#0191ff" }}
          />
        </Container>
      </LayoutNew>
    );
  }

  return (
    <LayoutNew>
      <Container>
        <Title>Billing history</Title>
        <Gap height="40px" />
        <Table>
          <thead>
            <tr>
              {COLUMNS?.map((column, index) => (
                <Th key={index}>{column?.label}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {invoices?.map((invoice, index) => (
              <tr key={index}>
                {COLUMNS?.map((column, index) => {
                  if (column?.type === "date-seconds") {
                    return (
                      <Td key={index} planType={userPlanType}>
                        {safeFormat(
                          new Date(invoice?.[column?.name] * 1000),
                          "d MMM yyy"
                        )}
                      </Td>
                    );
                  }

                  if (column?.type === "currency") {
                    return (
                      <Td key={index} planType={userPlanType}>
                        £ {(invoice?.[column?.name] / 100)?.toFixed(2)}
                      </Td>
                    );
                  }

                  return (
                    <Td key={index} planType={userPlanType}>
                      {column?.name === "invoice_pdf" ? (
                        <a href={invoice?.[column?.name]} target="_blank">
                          Download
                        </a>
                      ) : (
                        invoice?.[column?.name]
                      )}
                    </Td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </LayoutNew>
  );
};

export default ProfilePageInvoices;
