import { zapiGet, zapiPost } from "api/zapi";

export const getConversations = async () => {
  const { data, error } = await zapiGet("/solutions/ocr/api/v1/conversations");
  return { data, error };
};

export const postConversation = async body => {
  const { data, error } = await zapiPost("/solutions/ocr/api/v1/conversations", null, body);
  return { data, error };
};

export const postConversationChat = async body => {
  const { data, error } = await zapiPost("/solutions/ocr/api/v1/conversations/chat", null, body);
  return { data, error };
};

export const getChatPromptRecommendation = async params => {
  const { data, error } = await zapiGet("/solutions/ocr/api/v1/conversations/chat-prompt-recommendation", params);
  return { data, error };
};
