import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledButton = styled.label`
  position: relative;
  background-color: #b2daff;
  border: 1px solid ${props => props.theme.color.closest};
  color: #190b70;
  border: none;
  box-shadow: ${props => props.theme.shadow};
  font-family: "Montserrat";
  padding: 8px 12px;
  font-weight: 400;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
  width: max-content;
  ${props => props.isDisabled && "opacity: 0.6; pointer-events: none;"};

  svg {
    height: 16px;
    width: 18px;
    fill: #190b70;
    stroke: #190b70;
  }

  :hover {
    color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.primary};
      stroke: ${props => props.theme.color.primary};
    }
  }
`;

const StyledLink = styled(StyledButton).attrs({ as: Link })`
  text-decoration: none;
`;

const ButtonDark = ({
  style,
  className,
  children,
  onClick,
  isDisabled,
  dataTutorialId = "-",
  id = "",
  to = "",
}) => {
  if (to) {
    return (
      <StyledLink
        to={to}
        id={id}
        data-tutorial-id={dataTutorialId}
        isDisabled={isDisabled}
        style={style}
        className={className}
        onClick={onClick}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <StyledButton
      id={id}
      data-tutorial-id={dataTutorialId}
      isDisabled={isDisabled}
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonDark;
