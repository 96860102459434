import { useEffect, useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import { Gap } from "components/Layout";
import SmallButton from "components/ui/SmallButton";
import { CrossIcon, PlusIcon } from "components/ui/Icons";
import {
  patchKnowledgeConfig,
  postKnowledgeConfig,
} from "api/services/chatService";
import InputWithStateAdjusting from "components/InputWithStateAdjusting";

const ModalContent = styled.div`
  padding: 10px;
  white-space: pre-wrap;
  overflow: auto;
  /* transition: height 0.2s; */
`;

const Trigger = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.color.primary};
`;

const Input = styled.input`
  padding: 0 4px;
  border-radius: 0;
  font-family: "Montserrat";
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  padding: 0 4px;
  border-radius: 0;
  font-family: "Montserrat";
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  width: 100%;
  height: 100%;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const BigNameInput = styled(Input)`
  font-size: 16px;
  font-weight: 600;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"};
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const TermsContainer = styled.div`
  height: 200px;
  overflow: auto;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 10px;
  opacity: 0;
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"};
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Term = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  :hover {
    ${StyledCrossIcon} {
      opacity: 1;
    }
  }
`;

const CalculationContainer = styled.div`
  height: 200px;
`;

const CONFIG_TYPES = ["terminology", "calculation"];

/*
terminology: {
  terms: [
    { name: "term1", definition: "definition1" },
  ]
}

calculation: {
  formula: "AVG_REVENUE = SUM(ALL_REVENUE) / COUNT(NUM_YEARS)"
}
*/

const KnowledgeConfigEditor = ({
  style = {},
  config = { type: "terminology", terms: [] },
  onSave = newConfig => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedConfig, setEditedConfig] = useState(config);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setEditedConfig(config);
    }
  }, [isOpen]);

  const doSaveConfig = async payloadConfig => {
    setIsLoading(true);
    let data = {};
    if (config?._id) {
      ({ data } = await patchKnowledgeConfig(config?._id, {}, payloadConfig));
    } else {
      ({ data } = await postKnowledgeConfig({}, payloadConfig));
    }
    onSave(data);
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <ModalContent
      style={style}
      isDisabled={isLoading}
      onKeyDown={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <BigNameInput
        placeholder="Topic"
        value={editedConfig?.topic || ""}
        onChange={e =>
          setEditedConfig({ ...editedConfig, topic: e.target.value })
        }
        onBlur={() => doSaveConfig(editedConfig)}
      />
      <Gap height="8px" />
      Type{" "}
      <StyledSelect
        value={editedConfig?.type}
        onChange={e =>
          setEditedConfig({ ...editedConfig, type: e.target.value })
        }
      >
        {CONFIG_TYPES.map(type => (
          <option key={type}>{type}</option>
        ))}
      </StyledSelect>
      <Gap height="20px" />
      {editedConfig?.type === "terminology" && (
        <TermsContainer>
          {editedConfig?.terms?.map((term, i) => (
            <Term style={{ marginBottom: "4px" }} key={i}>
              <InputWithStateAdjusting
                initialValue={term?.name}
                onApplyValue={newValue => {
                  const updatedConfig = {
                    ...editedConfig,
                    terms: editedConfig?.terms?.map((t, j) =>
                      j === i ? { ...t, name: newValue } : t
                    ),
                  };
                  setEditedConfig(updatedConfig);
                  doSaveConfig(updatedConfig);
                }}
              />
              :
              <InputWithStateAdjusting
                initialValue={term?.definition}
                onApplyValue={newValue => {
                  const updatedConfig = {
                    ...editedConfig,
                    terms: editedConfig?.terms?.map((t, j) =>
                      j === i ? { ...t, definition: newValue } : t
                    ),
                  };
                  setEditedConfig(updatedConfig);
                  doSaveConfig(updatedConfig);
                }}
              />
              <StyledCrossIcon
                onClick={() => {
                  const updatedConfig = {
                    ...editedConfig,
                    terms: editedConfig?.terms?.filter((t, j) => j !== i),
                  };
                  setEditedConfig(updatedConfig);
                  doSaveConfig(updatedConfig);
                }}
              />
            </Term>
          ))}
          <Gap height="8px" />
          <StyledPlusIcon
            onClick={() => {
              const updatedConfig = {
                ...editedConfig,
                terms: [
                  ...(editedConfig?.terms || []),
                  { name: "new term", definition: "new definition" },
                ],
              };
              setEditedConfig(updatedConfig);
              doSaveConfig(updatedConfig);
            }}
          />
        </TermsContainer>
      )}
      {editedConfig?.type === "calculation" && (
        <CalculationContainer>
          <StyledTextArea
            placeholder="AVG_REVENUE = SUM(ALL_REVENUE) / COUNT(NUM_YEARS)"
            style={{ height: "200px" }}
            value={editedConfig?.formula}
            onChange={e =>
              setEditedConfig({
                ...editedConfig,
                formula: e.target.value,
              })
            }
            onBlur={() => doSaveConfig(editedConfig)}
          />
        </CalculationContainer>
      )}
      {/* <Gap height="20px" />
      <SmallButton value="Save" onClick={doSaveConfig} /> */}
    </ModalContent>
  );
};

export default KnowledgeConfigEditor;
