import { useEffect } from "react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { formatLongNumber } from "utils/common";

const Container = styled.div`
  position: relative;
  height: 300px;
  padding-top: 28px;
`;

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

/*
column = {
  name: ...
}

record = {
  [columnName]: {
    value: ...
  }
}
*/
const RecordsSummaryBarChart = ({ records = [], columns = [] }) => {
  const [selectedColumnName, setSelectedColumnName] = useState(
    columns?.[1]?.name || ""
  );

  useEffect(() => {
    setSelectedColumnName(columns?.[1]?.name || "");
  }, [JSON.stringify(columns)]);

  const categoryName = columns?.[0]?.name || "";
  const barsData = records?.map(record => ({
    [categoryName]: record?.[categoryName]?.value,
    [selectedColumnName]: record?.[selectedColumnName]?.value,
  }));

  return (
    <Container>
      <StyledSelect
        value={selectedColumnName}
        onChange={e => setSelectedColumnName(e.target.value)}
      >
        {columns
          .filter(col => col.name !== categoryName)
          .map(column => (
            <option>{column.name}</option>
          ))}
      </StyledSelect>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={barsData} margin={{}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={categoryName}
            tickFormatter={tick => {
              if (`${tick}`.length > 23) {
                return `${tick}`.slice(0, 16) + "...";
              }
              return tick;
            }}
          />
          <YAxis
            tickFormatter={tick => {
              return formatLongNumber(tick);
            }}
          />
          <Bar
            dataKey={selectedColumnName}
            fill="#0191ff"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default RecordsSummaryBarChart;
