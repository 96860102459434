import {
  getPipelineConfig,
  getSigmasV2,
  putPipelineConfig,
  putSigmasV2,
} from "api/backend/fileSystemEndpoints";
import {
  getSequences,
  getUserProfileByUserName,
} from "api/backend/projectServiceEndpoints";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getDirectoryItems } from "api/services/fileSystem";
import { postBulkUploadFormData } from "api/services/filesService";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import AggregateComponentSmallCard from "components/AggregateComponentSmallCard";
import EditPipelineModal from "components/EditPipelineModal";
import GridDraggable from "components/GridDraggable";
import {
  AnalyticsIcon,
  CodingIcon,
  DocSearchIcon,
  SourcesIcon,
} from "components/IconsNew";
import { Gap } from "components/Layout";
import LayoutApp, { getAppBasePathSource } from "components/LayoutApp";
import LayoutNew from "components/LayoutNew";
import SequenceSmallCard from "components/SequenceSmallCard";
import SequencesModalNew from "components/SequencesModalNew";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, PdfIcon, PencilIcon } from "components/ui/Icons";
import { range, uniq } from "lodash";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  overflow: hidden;
  align-content: start;
  justify-content: center;
  background: linear-gradient(180deg, #f3f5f7 0%, #f2f2f2 100%);
`;

const GroupCard = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 10px;
  height: max-content;
  display: grid;
  align-content: start;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 250px;
  height: 150px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 1px 7px 1px #00000040;
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);
    color: white;
    * {
      color: white;
    }
  }
`;

const AddNewCard = styled.div`
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 10px;
  height: max-content;
  display: grid;
  align-content: start;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 225px;
  height: 90px;
  cursor: pointer;

  background-color: transparent;
  border: 2px dashed #dddddd;
  color: #c0c0c0;
  display: grid;
  justify-content: center;
  align-content: center;
  font-weight: 500;

  :hover {
    background-color: #e3e3e3;
  }
`;

const AddNewCardLabel = styled.label`
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 10px;
  height: max-content;
  display: grid;
  align-content: start;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 225px;
  height: 90px;
  cursor: pointer;

  background-color: transparent;
  border: 2px dashed #dddddd;
  color: #c0c0c0;
  display: grid;
  justify-content: center;
  align-content: center;
  font-weight: 500;

  :hover {
    background-color: #e3e3e3;
  }
`;

const GroupTitle = styled.div`
  grid-column: span 2;
  line-height: 1.5;
  color: ${props => props.theme.color.primary};
  font-weight: 500;
  font-size: 16px;
  svg {
    height: 12px;
    margin-right: 8px;
  }
`;

const RecordDescription = styled.div`
  color: #b9b9b9;
`;

const GradientSpan = styled.span`
  background: linear-gradient(88.57deg, ${COLOR1} 0%, ${COLOR2} 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SubTitle = styled.div`
  color: #424242;
  padding-bottom: 0px;
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 0px;
  transition: opacity 0.5s;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;
  row-gap: 40px;
`;

const RecentItemsGroup = styled.div`
  display: grid;
  gap: 10px;
  align-content: start;
  align-items: start;
`;

const RecentItemsTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  gap: 10px;
  svg {
    fill: black;
  }
`;

const RecentItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const SeeMoreLink = styled.div`
  justify-self: end;
  font-weight: 500;
  color: white;
  text-decoration: none;
  background-color: ${props => props.theme.color.primary};
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledInput = styled.input`
  padding: 14px 20px;
  width: 800px;
  font-weight: 400;
  margin-top: 0px;
  font-family: "Montserrat";
  outline: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #dddddd, #dddddd) border-box;
  border-radius: 12px;
  border: 1px solid transparent;

  :focus {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  }

  /* background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent; */
`;

const AppPageHome = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { pipelineConfigId } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);

  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [dirContent, setDirContent] = useState([]);
  const [sequences, setSequences] = useState([]);

  const [query, setQuery] = useState("");

  const tableId = pipelineConfig?.sourceTables?.[0]?.id;

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
    doPopulateDirectoryItems();
    doPopulateSequences();
  }, [pipelineConfigId]);

  useEffect(() => {
    if (!pipelineConfigId || !tableId) {
      return;
    }
    doPopulateSigmaRecords(pipelineConfigId, tableId);
    const intervalId = setInterval(() => {
      doPopulateSigmaRecords(pipelineConfigId, tableId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [pipelineConfigId, tableId]);

  const doPopulateDirectoryItems = async () => {
    const { data } = await getDirectoryItems({
      directoryPath: getAppBasePathSource(pipelineConfigId),
    });
    setDirContent(data);
  };

  const doPostFiles = async files => {
    const { error } = await postBulkUploadFormData(
      { directoryPath: getAppBasePathSource(pipelineConfigId) },
      { files }
    );
    if (!error) {
      doPopulateDirectoryItems();
    }
  };

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const doPopulateSequences = async () => {
    const { data } = await getSequences({ pipelineId: pipelineConfigId });
    setSequences(data || []);
  };

  const doPopulateSigmaRecords = async (pipelineId, tableName) => {
    const { data } = await getSigmasV2({
      pipelineId,
      tableName,
    });
    setSigmaRecords(data || []);
    if (!selectedRecordId) {
      setSelectedRecordId(data?.[0]?.id);
    }
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${query}`);
    }
  };

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];
  const recordNameColumn = columns?.find(column => !column?.groupName);

  // sort by createdAt
  const sigmaRecordsMostRecentFirst = sigmaRecords.sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  const directoryItemsMostRecentFirst = dirContent?.items?.sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  return (
    <LayoutApp>
      <Container>
        <SubTitle style={{ paddingTop: 100, paddingBottom: 20 }}>
          Welcome to <GradientSpan>{pipelineConfig?.name}</GradientSpan>
        </SubTitle>
        <Gap height="30px" />
        <StyledInput
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Search records, sources, plots, sequences..."
        />
        <Gap height="60px" />
        <TwoColumns>
          <RecentItemsGroup>
            <RecentItemsTitle>
              <DocSearchIcon />
              <div>Recently Added Data</div>
              <SeeMoreLink
                onClick={() => navigate(`/apps/${pipelineConfigId}/records`)}
              >
                See more
              </SeeMoreLink>
            </RecentItemsTitle>
            <RecentItems>
              {range(0, 2)?.map(index => {
                const record = sigmaRecordsMostRecentFirst?.[index];

                if (!record) {
                  return null;
                }

                return (
                  <GroupCard
                    key={record.id}
                    onClick={() =>
                      navigate(
                        `/apps/${pipelineConfigId}/records/${record?.id}`
                      )
                    }
                    isSelected={selectedRecordId === record.id}
                  >
                    <GroupTitle>
                      {record?.fields?.[recordNameColumn?.name]?.value}
                    </GroupTitle>
                    <RecordDescription style={{ gridColumn: "span 2" }}>
                      A record named:&nbsp;
                      {record?.fields?.[recordNameColumn?.name]?.value}
                    </RecordDescription>
                  </GroupCard>
                );
              })}
            </RecentItems>
          </RecentItemsGroup>

          <RecentItemsGroup>
            <RecentItemsTitle>
              <SourcesIcon />
              <div>Recently Ingested Documents</div>
              <SeeMoreLink
                onClick={() => {
                  navigate(
                    `/apps/${pipelineConfigId}/home?sidebar=%7B"type"%3A"sources"%7D`
                  );
                }}
              >
                See more
              </SeeMoreLink>
            </RecentItemsTitle>
            <RecentItems>
              {range(0, 2).map(index => {
                const dirItem = directoryItemsMostRecentFirst?.[index];

                if (!dirItem) {
                  return (
                    <AddNewCardLabel key={index}>
                      Add new
                      <input
                        style={{ display: "none" }}
                        type="file"
                        multiple
                        onChange={e => {
                          const filesArray = [...e.target.files];
                          doPostFiles(filesArray);
                        }}
                      />
                    </AddNewCardLabel>
                  );
                }

                return (
                  <GroupCard
                    key={dirItem.id}
                    onClick={() => {}}
                    isSelected={selectedRecordId === dirItem.id}
                  >
                    <GroupTitle>
                      <PdfIcon />
                      {dirItem?.fileName}
                    </GroupTitle>
                  </GroupCard>
                );
              })}
            </RecentItems>
          </RecentItemsGroup>

          <RecentItemsGroup>
            <RecentItemsTitle>
              <AnalyticsIcon />
              <div>Recently Updated Analytics</div>
              <SeeMoreLink
                onClick={() => navigate(`/apps/${pipelineConfigId}/dashboard`)}
              >
                See more
              </SeeMoreLink>
            </RecentItemsTitle>
            <RecentItems>
              {range(0, 2).map(index => {
                const aggregationComponent =
                  pipelineConfig?.aggregationComponents?.[index];

                if (!aggregationComponent) {
                  return (
                    <AddNewCard
                      onClick={() =>
                        navigate(
                          `/apps/${pipelineConfigId}/dashboard?addNew=true`
                        )
                      }
                    >
                      Add new
                    </AddNewCard>
                  );
                }

                return (
                  <GroupCard
                    key={aggregationComponent.id}
                    onClick={() =>
                      navigate(`/apps/${pipelineConfigId}/dashboard`)
                    }
                  >
                    <AggregateComponentSmallCard
                      key={aggregationComponent.id}
                      aggregationComponent={aggregationComponent}
                    />
                  </GroupCard>
                );
              })}
              {/* <AddNewCard
                onClick={() =>
                  navigate(`/apps/${pipelineConfigId}/dashboard?addNew=true`)
                }
              >
                Add new
              </AddNewCard>
              <AddNewCard
                onClick={() =>
                  navigate(`/apps/${pipelineConfigId}/dashboard?addNew=true`)
                }
              >
                Add new
              </AddNewCard> */}
            </RecentItems>
          </RecentItemsGroup>

          <RecentItemsGroup>
            <RecentItemsTitle>
              <CodingIcon />
              Recently Executed Sequences
              <SeeMoreLink
                onClick={() => navigate(`/apps/${pipelineConfigId}/sequences`)}
              >
                See more
              </SeeMoreLink>
            </RecentItemsTitle>
            <RecentItems>
              {range(0, 2).map(index => {
                const sequence = sequences?.[index];

                if (!sequence) {
                  return (
                    <AddNewCard
                      onClick={() =>
                        navigate(`/apps/${pipelineConfigId}/sequences/new`)
                      }
                    >
                      Add new
                    </AddNewCard>
                  );
                }

                return (
                  <GroupCard
                    key={sequence.id}
                    onClick={() =>
                      navigate(
                        `/apps/${pipelineConfigId}/sequences/${sequence.id}`
                      )
                    }
                  >
                    <SequenceSmallCard
                      pipelineConfigId={pipelineConfigId}
                      sequenceId={sequence.id}
                    />
                  </GroupCard>
                );
              })}
              {/* <AddNewCard
                onClick={() =>
                  navigate(`/apps/${pipelineConfigId}/sequences/new`)
                }
              >
                Add new
              </AddNewCard>
              <AddNewCard
                onClick={() =>
                  navigate(`/apps/${pipelineConfigId}/sequences/new`)
                }
              >
                Add new
              </AddNewCard> */}
            </RecentItems>
          </RecentItemsGroup>
        </TwoColumns>
      </Container>
    </LayoutApp>
  );
};

export default AppPageHome;
