import {
  getPipelineConfigSearch,
  postPipelineConfigSearch,
} from "api/backend/fileSystemEndpoints";
import { CodingIcon, DocSearchIcon, SourcesIcon } from "components/IconsNew";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import { CrossIcon } from "components/ui/Icons";
import useSearchParamsState from "hooks/useSearchParamsState";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  align-content: start;
  justify-content: start;
  padding: 120px;
  padding-bottom: 0;
  padding-top: 80px;
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  width: 800px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
`;

const SearchResults = styled.div`
  white-space: pre-wrap;
  display: grid;
  gap: 20px;
  overflow: auto;
`;

const SearchResultCard = styled.div`
  background-color: #eaeaea;
  padding: 12px;
  width: 800px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  border-radius: 12px;
  height: 80px;
  cursor: pointer;
  svg {
    fill: #000000;
  }
`;

const BoldDiv = styled.div`
  font-weight: 600;
`;

const SearchResultDescription = styled.div`
  line-height: 1.5;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-right: 10px;
  :hover {
    opacity: 1;
  }
`;

const TYPE_TO_ICON = {
  FILE: <SourcesIcon />,
  RECORD: <DocSearchIcon />,
  SEQUENCE: <CodingIcon />,
};

const RESULT_TYPES = [
  { label: "Record", value: "RECORD" },
  { label: "File", value: "FILE" },
  { label: "Sequence", value: "SEQUENCE" },
];

const AppSearchPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pipelineConfigId } = useParams();
  const query = searchParams?.get("query") || "";

  const [editedQuery, setEditedQuery] = useState(
    searchParams.get("query") || ""
  );
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [typeFilter, setTypeFilter] = useSearchParamsState({
    paramName: "type",
    initialValue: "",
  });

  const doPopulateSearchResults = async () => {
    setIsSearching(true);
    const { data } = await postPipelineConfigSearch(
      pipelineConfigId,
      {},
      {
        query,
      }
    );
    setSearchResults(data || []);
    setIsSearching(false);
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${editedQuery}`);
    }
  };

  useEffect(() => {
    doPopulateSearchResults();
  }, [query]);

  const filteredResults =
    searchResults?.filter(
      result => typeFilter === "" || result?.type === typeFilter
    ) || [];

  return (
    <LayoutApp>
      <Container>
        <Title>Search</Title>
        <Gap />
        <StyledInput
          disabled={isSearching}
          value={editedQuery}
          onChange={e => setEditedQuery(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Search records, sources, plots, sequences..."
        />
        <Gap />
        <BoldDiv>{filteredResults?.length} results</BoldDiv>
        <Gap />
        <FilterContainer style={{ justifySelf: "start" }}>
          <StyledSelect
            value={typeFilter}
            onChange={e => setTypeFilter(e.target.value)}
          >
            <option value="">All</option>
            {RESULT_TYPES.map(type => (
              <option value={type.value}>{type.label}</option>
            ))}
          </StyledSelect>
          {typeFilter !== "" && (
            <StyledCrossIcon onClick={() => setTypeFilter("")} />
          )}
        </FilterContainer>
        <Gap />
        <SearchResults>
          {filteredResults?.map(result => {
            let resultUrl = `/apps/${pipelineConfigId}/records/${result?.id}`;
            if (result?.type === "FILE") {
              resultUrl = `/files/${result?.id}`;
            }
            if (result?.type === "SEQUENCE") {
              resultUrl = `/apps/${pipelineConfigId}/sequences/${result?.id}`;
            }

            return (
              <SearchResultCard onClick={() => navigate(resultUrl)}>
                {TYPE_TO_ICON?.[result?.type]}
                <SearchResultDescription>
                  {result?.description}
                </SearchResultDescription>
              </SearchResultCard>
            );
          })}
        </SearchResults>
        <Gap />
      </Container>
    </LayoutApp>
  );
};

export default AppSearchPage;
