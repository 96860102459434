import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { getSigmaTableConfig } from "api/services/sigmaService";
import { getSmartRecord, patchSmartRecord } from "api/services/searchService";
import { parseJson } from "utils/common";
import SmallButton from "components/ui/SmallButton";

const Container = styled.div`
  padding: 20px;
  ${props => props.isLoading && "opacity: 0.5;pointer-events: none;"}
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
`;

const TableNameColumnNameAndInput = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
`;

const TableName = styled.div`
  font-weight: 600;
  grid-column: span 2;
  margin-bottom: 10px;
`;

const Cell = styled.div`
  padding: 10px;
  border: 0.5px solid ${props => props.theme.color.closer1_5};
`;

const BoldCell = styled(Cell)`
  font-weight: 600;
`;

const SolrRecordEditorSimple = ({ solrId }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [solrRecord, setSolrRecord] = useState(null);
  const [sigmaTableConfig, setSigmaTableConfig] = useState(null);
  const [error, setError] = useState(null);

  const [userCorrectionStr, setUserCorrectionStr] = useState("");

  useEffect(() => {
    doPopulateSolrRecordAndSigmaConfig();
  }, [solrId]);

  const doPopulateSolrRecordAndSigmaConfig = async () => {
    setIsLoading(true);
    const { data: smartRecord, error: errFetchSolr } = await getSmartRecord(solrId);
    setSolrRecord(smartRecord);
    setError(errFetchSolr);

    const { data: tableConfig, error: errFetchSigmaConfig } = await getSigmaTableConfig(smartRecord?.sigmaTableId);
    setSigmaTableConfig(tableConfig);
    setError(errFetchSigmaConfig);
    setUserCorrectionStr(parseJson(smartRecord?.value)?.RawValue);
    setIsLoading(false);
  };

  const doPatchSolrRecordAndGoBackToTable = async () => {
    setIsLoading(true);

    const newSolrValueJson = {
      ...parseJson(solrRecord?.value),
      RawValue: userCorrectionStr,
      Value: userCorrectionStr,
    };

    const { error: errPatchSolr } = await patchSmartRecord(solrRecord?.id, { value: JSON.stringify(newSolrValueJson) });
    setError(errPatchSolr);

    if (!errPatchSolr) {
      navigate(`/table-configs/${solrRecord?.sigmaTableId}/results`);
    }

    setIsLoading(false);
  };

  return (
    <Container isLoading={isLoading}>
      {error && <div>{JSON.stringify(error)}</div>}
      <TableNameColumnNameAndInput>
        <TableName>{sigmaTableConfig?.name}</TableName>
        <BoldCell>{sigmaTableConfig?.primarySigmaConfigNames?.[0]}</BoldCell>
        <BoldCell>{parseJson(solrRecord?.jsonKey)?.TOPIC}</BoldCell>
        <Cell>{parseJson(solrRecord?.jsonKey)?.ID}</Cell>
        <Cell>
          <input
            type="text"
            value={userCorrectionStr}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              setUserCorrectionStr(e.target.value);
            }}
          />
        </Cell>
      </TableNameColumnNameAndInput>
      <SmallButton value="Save" onClick={doPatchSolrRecordAndGoBackToTable} />
    </Container>
  );
};

export default SolrRecordEditorSimple;
