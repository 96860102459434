import { postIngestWebpage } from "api/backend/filesEndpoints";
import { postBulkUploadFormData } from "api/services/filesService";
import usePollFiles from "api/services/usePollFiles";
import { InfoMsg } from "components/DocSourcesModalTriggerNew";
import FileViewModal from "components/FileViewModal";
import { Gap } from "components/Layout";
import ButtonIcon from "components/ui/ButtonIcon";
import {
  CrossIcon,
  InformationIcon,
  PdfIcon,
  PlusIcon,
  UploadCloudIcon,
  UploadFileIcon,
  WarningIcon,
} from "components/ui/Icons";
import { uniqBy } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 20px auto auto;
  height: 100%;
`;

const Urls = styled.div`
  display: grid;
  gap: 10px;
  align-content: start;
`;

const Label = styled.label`
  cursor: pointer;
  width: max-content;
  display: flex;

  input {
    display: none;
  }
  :hover {
    opacity: 0.6;
  }
`;

const UrlItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto 200px auto;
  gap: 10px;
  align-items: center;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  padding: 0 22px;
  font-weight: 500;
`;

const StyledProgress = styled.progress`
  // pulsing opacity animation
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  animation: pulse 1s infinite;
  width: 100%;
`;

const DropArea = styled.div`
  width: 100%;
  border: 2px dashed #cdcdcd;
  color: #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  svg {
    height: 32px;
    margin-bottom: 16px;
    fill: #cdcdcd;
  }
  border-radius: 10px;
`;

const DirItem = styled.label`
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  gap: 8px;
  grid-template-columns: 16px 1fr auto auto;
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

const ErrText = styled.div`
  font-weight: 500;
  line-height: 1.2;
  color: ${props => props.theme.color.error};
  padding: 4px 0;
`;

const MIN_PROGRESS = 5;

const BlueButton = styled(ButtonIcon)`
  font-weight: 500;
  justify-self: end;
  align-self: end;
  padding: 4px;
  margin-top: 20px;
  width: max-content;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #d1d1d1;
  padding: 0px 4px;
  width: 100%;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const MsgText = styled.div`
  font-weight: 500;
  line-height: 1.2;
  color: ${props => props.theme.color.primary};
  padding: 4px 0;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const FilesFromUrlsArea = ({
  sources = [],
  setSources = () => {},
  onClickDone = () => {},
  infoMsg = "",
  isTrialUser = false,
}) => {
  const [urls, setUrls] = useState([{ value: "", id: uuidv4() }]);
  // const [fileIds, setFileIds] = useState([]);
  const [uploadErr, setUploadErr] = useState(null);
  const [isShowingMsg, setIsShowingMsg] = useState(false);

  const fileIds = urls.map(url => url.fileId).filter(id => !!id);
  const [files] = usePollFiles(fileIds);

  const doIngestUrls = async () => {
    urls.forEach(async url => {
      if (!url?.value || url?.fileId) {
        return;
      }

      setUrls(prevUrls => {
        const newUrls = [...prevUrls];
        const index = newUrls.findIndex(u => u.id === url.id);
        newUrls[index].isIngesting = true;
        return newUrls;
      });
      const readableUrlName =
        url?.value?.replace(/^https?:\/\/(www\.)?([^\/]+).*/, "$2") ||
        url?.value?.slice(0, 20);
      const { data, error } = await postIngestWebpage(
        {},
        {
          url: url?.value,
          name: `${readableUrlName?.replaceAll(".", "-")}.pdf`,
        }
      );
      setUploadErr(error);
      setUrls(prevUrls => {
        const newUrls = [...prevUrls];
        const index = newUrls.findIndex(u => u.id === url.id);
        newUrls[index].fileId = data?.id;
        newUrls[index].error = error;
        newUrls[index].isIngesting = false;
        return newUrls;
      });
    });
  };

  useEffect(() => {
    const newSourcesFromFiles = files
      .filter(file => file.status === "DONE")
      .map(file => ({
        emailId: file?.emailId,
        fileId: file?.id,
        fileName: file?.fileName,
        type: "FILE",
      }));
    const newSources = uniqBy([...sources, ...newSourcesFromFiles], "fileId");
    setSources(newSources);
  }, [JSON.stringify(files)]);

  const areAllFilesDone =
    files.every(
      file => file.status === "DONE" || file.status?.includes("FAIL")
    ) && files.length > 0;
  const isIngesting =
    urls?.some(url => url.isIngesting) ||
    files?.some(file => file?.status === "RUNNING");

  const urlsCanBeScanned = urls?.some(
    url => !url?.fileId && !url?.isIngesting && url?.value
  );

  let msgContent = <div />;
  if (uploadErr) {
    msgContent = (
      <ErrText>
        {uploadErr?.message || "Upload error. Please try different files."}
      </ErrText>
    );
  }
  if (isIngesting) {
    msgContent = (
      <MsgText>
        <InformationIcon />
        Scanning URLs...
      </MsgText>
    );
  }
  if (areAllFilesDone) {
    msgContent = (
      <MsgText>
        <InformationIcon />
        URLs ready to add to chat.
      </MsgText>
    );
  }

  return (
    <Container>
      <Urls>
        {urls?.map((url, index) => {
          let progressContent = <div />;
          if (url?.fileId || url?.isIngesting) {
            let progressValue = MIN_PROGRESS;
            let matchedFile = files?.find(file => file.id === url?.fileId);
            if (matchedFile) {
              progressValue = matchedFile?.progress;
            }

            progressContent = (
              <StyledProgress
                value={Math.max(progressValue, MIN_PROGRESS)}
                max="100"
              />
            );

            if (progressValue === 100) {
              progressContent = <PdfIcon style={{ fill: "#ed1c24" }} />;
            }
          }

          return (
            <UrlItem key={url?.id}>
              <Input
                disabled={url?.isIngesting || url?.fileId}
                type="text"
                placeholder="Enter URL"
                value={url?.value}
                onChange={e => {
                  const newUrls = [...urls];
                  newUrls[index].value = e.target.value;
                  setUrls(newUrls);
                }}
              />
              {url?.fileId ? <FileViewModal fileId={url?.fileId} /> : <div />}
              {progressContent}
              <CrossIcon
                onClick={() => {
                  setUrls(urls.filter(otherUrl => otherUrl.id !== url.id));
                }}
              />
            </UrlItem>
          );
        })}
        <StyledPlusIcon
          isDisabled={isIngesting}
          onClick={() => {
            if (isTrialUser && urls.length > 0) {
              setIsShowingMsg(true);
              return;
            }

            setUrls([...urls, { value: "", id: uuidv4() }]);
          }}
        />
      </Urls>
      {msgContent}
      {infoMsg && isShowingMsg && (
        <InfoMsg>
          <WarningIcon style={{ fill: "black" }} />
          <div dangerouslySetInnerHTML={{ __html: infoMsg }} />
        </InfoMsg>
      )}
      <div style={{ display: "flex", gap: 10, justifySelf: "end" }}>
        <BlueButton
          isDisabled={isIngesting || !urlsCanBeScanned}
          isActive
          onClick={doIngestUrls}
        >
          Scan
        </BlueButton>
        <BlueButton
          isDisabled={!areAllFilesDone || !files?.length}
          isActive
          onClick={() => {
            const newSourcesFromFiles = files
              .filter(file => file.status === "DONE")
              .map(file => ({
                emailId: file?.emailId,
                fileId: file?.id,
                fileName: file?.fileName,
                type: "FILE",
              }));
            const newSources = uniqBy(
              [...sources, ...newSourcesFromFiles],
              "fileId"
            );
            setSources(newSources);
            onClickDone(newSources);
          }}
        >
          Add to chat
        </BlueButton>
      </div>
    </Container>
  );
};

export default FilesFromUrlsArea;
