import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";

import {
  getFilePageResults,
  getOneFile,
  patchFileById,
  patchPageAnnotations,
} from "api/services/filesService";
import { CenteredWithTopNavLayoutWide } from "components/Layout";
import useSearchParamsState from "hooks/useSearchParamsState";
import DocumentView from "components/DocumentView";
import TextOutputView from "components/TextOutputView";
import TableOutputView from "components/TableOutputView";
import usePollFile from "api/services/usePollFile";
import { getAnnotationsByFileAndPage } from "api/services/filesService";
import FileRerunButtonsAndProgress from "components/RerunButtonsAndProgress";
import Tooltip from "components/ui/Tooltip";
import { Audiotrack, Delete, GroupAdd, Save } from "@material-ui/icons";
import ConversationChat from "components/widgets/ConversationChat";
import InputWithState from "components/InputWithState";
import FileTagFetcher from "components/FileTagFetcher";
import NavHeader from "components/NavHeader";
import { DownloadIcon, PdfIcon } from "components/ui/Icons";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";

const TopBar = styled.div`
  height: 50px;
  width: 100%;
  grid-column: span 3;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};

  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: start;
  grid-template-columns: auto auto 1fr;
  gap: 8px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftColumnSize} auto 1fr;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`;

const FileNameAndTags = styled.div`
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  gap: 20px;
`;

const EditableFileName = styled(InputWithState)`
  font-weight: bold;
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 600;
`;

const OutputContainer = styled.div`
  display: grid;
  /* border-top: 1px solid ${props => props.theme.color.closer1_5}; */
  overflow: auto;
`;

const LayoutSlidingPartition = styled.div`
  width: 4px;
  background-color: lightgrey;
  height: 100%;
  cursor: col-resize;
  :hover {
    background-color: grey;
    width: 6px;
  }
`;

const AreaTypeContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledSelect = styled.select`
  border: 2px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  margin-right: 5px;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const IconButton = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const BottomLeftAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const ToolbarContainer = styled.div`
  padding: 0 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  height: 48px;
  z-index: 90;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
`;

const StyledNavHeader = styled(NavHeader)`
  position: relative;
  box-shadow: none;
  background-color: transparent;
`;

const ModalTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  width: max-content;

  svg {
    width: 20px;
    height: 20px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const FilePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { fileId } = useParams();

  const [leftColumnSize, setLeftColumnSize] = useState("1fr");
  const [file, setFile] = usePollFile(fileId);
  const [pageResultsForEachPage, setPageResultsForEachPage] = useState([]);
  const [pageNumber, setPageNumber] = useSearchParamsState({
    paramName: "pageNumber",
    initialValue: 0,
  });
  const [selectedOutputItemId, setSelectedOutputItemId] = useState(
    parseInt(searchParams?.get("selectedOutputItemId"))
  );

  const [pageAnnotations, setPageAnnotations] = useState([]);
  const [originalPageAnnotations, setOriginalPageAnnotations] = useState([]);
  const [isPatchingAnnotations, setIsPatchingAnnotations] = useState(false);
  const [hasBeenChecked, setHasBeenChecked] = useState(null);

  const isChatVisible = searchParams.get("isChatVisible") === "true";
  const areColumnBoxesVisible =
    searchParams.get("areColumnBoxesVisible") === "true";
  const areRowBoxesVisible = searchParams.get("areRowBoxesVisible") === "true";
  const [isPatchingFile, setIsPatchingFile] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");

  useEffect(() => {
    if (!file?.remoteAudioFile) {
      return;
    }
    doFetchAudioFile();
  }, [file?.remoteAudioFile]);

  const doFetchAudioFile = async () => {
    const { data } = await getOneFile({
      key: `base64/${file?.remoteAudioFile}`,
    });

    setAudioSrc(`data:audio/wav;base64,${data}`);
  };

  useEffect(() => {
    if (file?.originalName?.includes(".xlsx")) {
      navigate(`/excel-files/${fileId}`);
    }
  }, [file?.fileName]);

  useEffect(() => {
    // if (file?.pageResults?.[pageNumber]?.status !== "DONE") {
    //   return;
    // }

    resetSelectedOutputIdIfNeeded();
    doPopulatePageResultsAndAnnotationsForPageNumber();
  }, [pageNumber, file?.pageResults?.[pageNumber]?.status]);

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === "Backspace") {
        deleteSelectedAndPatchPageAnnotations();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedOutputItemId]);

  const resetSelectedOutputIdIfNeeded = () => {
    if (searchParams?.get("selectedOutputItemId")) {
      setSelectedOutputItemId(
        parseInt(searchParams?.get("selectedOutputItemId"))
      );
      searchParams.delete("selectedOutputItemId");
      navigate({ search: searchParams.toString() });
      return;
    }

    setSelectedOutputItemId(null);
  };

  const doPatchFile = async body => {
    setIsPatchingFile(true);
    const { data } = await patchFileById(fileId, body);
    setFile(data);
    setIsPatchingFile(false);
  };

  const doPopulatePageResultsAndAnnotationsForPageNumber = async () => {
    setPageAnnotations([]);
    setHasBeenChecked(null);
    setPageResultsForEachPage(prevResults => {
      if (prevResults[pageNumber]) {
        return prevResults;
      }
      const newResults = [...prevResults];
      newResults[pageNumber] = null;
      return newResults;
    });

    const { data } = await getFilePageResults(fileId, pageNumber);
    const { data: pageAnnos } = await getAnnotationsByFileAndPage(
      fileId,
      pageNumber
    );

    setPageAnnotations(pageAnnos?.annotations);
    setOriginalPageAnnotations(pageAnnos?.annotations);
    setHasBeenChecked(pageAnnos?.checked);
    setPageResultsForEachPage(prevResults => {
      const newResults = [...prevResults];
      newResults[pageNumber] = data;
      return newResults;
    });

    setSelectedOutputItemId(prevId => {
      if (prevId) return prevId;
      return data?.output?.[0]?.id;
    });
  };

  const doPatchPageAnnotations = async pageAnnosToUpdate => {
    // if (isEqual(pageAnnosToUpdate, originalPageAnnotations)) {
    //   return;
    // }
    setIsPatchingAnnotations(true);
    const { data } = await patchPageAnnotations(fileId, pageNumber, {
      annotations: pageAnnosToUpdate,
    });
    setPageAnnotations(data?.annotations);
    setHasBeenChecked(data?.checked);
    setIsPatchingAnnotations(false);
  };

  const deleteSelectedAndPatchPageAnnotations = async () => {
    if (!selectedOutputItemId && selectedOutputItemId !== 0) {
      return;
    }

    const annotationsWithoutDeleted = pageAnnotations?.filter(
      item =>
        item?.x !== selectedPageAnnotation?.x ||
        item?.y !== selectedPageAnnotation?.y
    );
    setPageAnnotations(annotationsWithoutDeleted);
    // await doPatchPageAnnotations(annotationsWithoutDeleted);
  };

  const updatePageAnnotations = (x, y, fieldsToUpdate) => {
    const newAnnotations = [...pageAnnotations];
    const annotationToUpdateIndex = pageAnnotations.findIndex(
      item => item?.x === x && item?.y === y
    );
    newAnnotations[annotationToUpdateIndex] = {
      ...pageAnnotations[annotationToUpdateIndex],
      ...fieldsToUpdate,
    };

    setPageAnnotations(newAnnotations);
  };

  const pageResults = pageResultsForEachPage?.[pageNumber];
  const pageResultsAnnotations = [
    pageResults?.annotations_0,
    pageResults?.annotations_1,
    pageResults?.annotations_2,
    pageResults?.annotations_3,
  ]?.flat();
  const pageAnnotationsWithIds = pageAnnotations?.map(annotation => {
    const matchedPageResultsBox = pageResultsAnnotations?.find(
      item => item?.x === annotation?.x && item?.y === annotation?.y
    );

    const matchedPageResultsId = matchedPageResultsBox?.id;

    if (typeof matchedPageResultsId === "number") {
      return {
        ...annotation,
        id: matchedPageResultsId,
      };
    }

    return {
      ...annotation,
      id: `${annotation?.x}-${annotation?.y}`,
    };
  });

  const selectedOutputItem = pageResults?.output.find(
    item => item.id === selectedOutputItemId
  );
  const selectedPageAnnotation = pageAnnotationsWithIds?.find(
    item => item?.id === selectedOutputItemId
  );
  const areaType = selectedPageAnnotation?.label;

  const haveAnnosBeenEdited =
    JSON.stringify(pageAnnotations) !== JSON.stringify(originalPageAnnotations);

  let filteredPageAnnotations = pageAnnotationsWithIds;
  if (areColumnBoxesVisible) {
    filteredPageAnnotations = pageAnnotationsWithIds?.filter(
      item => item?.label === "col" || item?.label === "-"
    );
  } else if (areRowBoxesVisible) {
    filteredPageAnnotations = pageAnnotationsWithIds?.filter(
      item => item?.label === "row" || item?.label === "-"
    );
  } else {
    filteredPageAnnotations = pageAnnotationsWithIds?.filter(
      item => item?.label !== "col" && item?.label !== "row"
    );
  }

  return (
    <Container leftColumnSize={leftColumnSize}>
      <TopBar>
        <Link to="/">
          {!audioSrc && (
            <PdfIcon
              height="32px"
              style={{
                paddingLeft: "14px",
                paddingRight: "0px",
                fill: "#ed1c24",
              }}
            />
          )}
          {audioSrc && (
            <Audiotrack
              style={{
                heght: "32px",
                marginLeft: "14px",
                marginRight: "0px",
                fill: "black",
              }}
            />
          )}
        </Link>
        <EditableFileName
          initialValue={
            audioSrc ? file?.fileName?.replaceAll(".pdf", "") : file?.fileName
          }
          isDisabled={isPatchingFile}
          onApplyValue={newFileName => doPatchFile({ fileName: newFileName })}
        />
        <FileTagFetcher fileId={fileId} />

        <ModalTrigger
          onClick={() => triggerDownloadOfFile(fileId, { fileType: "FILE" })}
        >
          <DownloadIcon style={{ height: "14px", width: "14px" }} />
        </ModalTrigger>
        {/* <ShareDirectoryItemModalTrigger
          trigger={
            <ModalTrigger>
              <GroupAdd />
            </ModalTrigger>
          }
          directoryItem={{
            id: fileId,
            fileName: file?.fileName,
            type: "FILE",
            sharedWith: file?.sharedWith || [],
          }}
        /> */}
        <StyledNavHeader />
      </TopBar>

      <DocumentView
        audioSrc={audioSrc}
        hasBeenChecked={hasBeenChecked}
        pageNumber={pageNumber}
        pageBase64Img={pageResults?.image}
        pageAnnotations={filteredPageAnnotations}
        onNewPageNumber={newPageNumber => setPageNumber(newPageNumber)}
        numberOfPages={file?.numberOfPages}
        selectedOutputItemId={selectedOutputItemId}
        onClickOutputBox={newSelectedOutputItemId =>
          setSelectedOutputItemId(newSelectedOutputItemId)
        }
        onMouseUpAfterResizingAnnotationAtXAndY={(
          x,
          y,
          newWidth,
          newHeight
        ) => {
          updatePageAnnotations(x, y, {
            w: newWidth,
            h: newHeight,
          });
        }}
        onNewAnnotationBox={({ x, y, w, h }) => {
          const newPageAnnotations = [
            ...pageAnnotations,
            {
              x,
              y,
              w,
              h,
              label: "-",
            },
          ];
          setPageAnnotations(newPageAnnotations);
          setSelectedOutputItemId(`${x}-${y}`);
        }}
      />
      <LayoutSlidingPartition
        draggable
        onDrag={e => e?.pageX !== 0 && setLeftColumnSize(`${e?.pageX}px`)}
      />
      <OutputContainer>
        {selectedOutputItem?.type === "text" && !isChatVisible && (
          <TextOutputView
            areaType={areaType}
            onNewAreaTypeSelected={newAreaType =>
              updatePageAnnotations(
                selectedPageAnnotation?.x,
                selectedPageAnnotation?.y,
                { label: newAreaType }
              )
            }
            onPressSaveAreaType={() => doPatchPageAnnotations(pageAnnotations)}
            isPatchingAnnotations={isPatchingAnnotations}
            fileProgress={file?.progress}
            fileId={fileId}
            textContent={selectedOutputItem?.content}
            pageResultsAnnotations={selectedOutputItem?.annotations}
            onPressDeleteArea={deleteSelectedAndPatchPageAnnotations}
            isSaveButtonDisabled={!haveAnnosBeenEdited}
          />
        )}
        {selectedOutputItem?.type === "table" && !isChatVisible && (
          <TableOutputView
            areaType={areaType}
            onNewAreaTypeSelected={newAreaType =>
              updatePageAnnotations(
                selectedPageAnnotation?.x,
                selectedPageAnnotation?.y,
                { label: newAreaType }
              )
            }
            onPressSaveAreaType={() => doPatchPageAnnotations(pageAnnotations)}
            isPatchingAnnotations={isPatchingAnnotations}
            fileProgress={file?.progress}
            fileId={fileId}
            pageNumber={pageNumber}
            outputItemId={selectedOutputItem?.id}
            tableName={selectedOutputItem?.tableName}
            tableColumns={selectedOutputItem?.tableColumns}
            tablePreview={selectedOutputItem?.tablePreview}
            automaticLineItemNameMap={
              selectedOutputItem?.automaticLineItemNameMap
            }
            onPressDeleteArea={deleteSelectedAndPatchPageAnnotations}
            isSaveButtonDisabled={!haveAnnosBeenEdited}
          />
        )}
        {((selectedOutputItem?.type !== "text" &&
          selectedOutputItem?.type !== "table") ||
          isChatVisible) && (
          <div>
            <ToolbarContainer>
              <FileRerunButtonsAndProgress progress={file?.progress} />
            </ToolbarContainer>
            {areaType && !isChatVisible && (
              <AreaTypeContainer isDisabled={isPatchingAnnotations}>
                Area type:&nbsp;
                <StyledSelect
                  value={areaType}
                  onChange={e =>
                    updatePageAnnotations(
                      selectedPageAnnotation?.x,
                      selectedPageAnnotation?.y,
                      {
                        label: e.target.value,
                      }
                    )
                  }
                >
                  {[
                    "-",
                    "text",
                    "table",
                    "infograph",
                    "image",
                    "title",
                    "sidebar",
                    "note",
                    "list",
                    "row",
                    "col",
                  ]?.map(areaType => (
                    <option key={areaType}>{areaType}</option>
                  ))}
                </StyledSelect>
                <BottomLeftAlignedTooltip title="Save areas">
                  <IconButton
                    onClick={() => doPatchPageAnnotations(pageAnnotations)}
                  >
                    <Save />
                  </IconButton>
                </BottomLeftAlignedTooltip>
                <BottomLeftAlignedTooltip title="Delete area">
                  <IconButton onClick={deleteSelectedAndPatchPageAnnotations}>
                    <Delete />
                  </IconButton>
                </BottomLeftAlignedTooltip>
              </AreaTypeContainer>
            )}
          </div>
        )}
        {isChatVisible && (
          <ConversationChat fileId={fileId} fileName={file?.fileName} />
        )}
      </OutputContainer>
    </Container>
  );
};

export default FilePage;
