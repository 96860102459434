import TooltipNew from "components/ui/TooltipNew";
import PagePreviewTextAndTableModal from "components/widgets/PagePreviewTextAndTableModal";
import { useState } from "react";
import styled from "styled-components";

const Span = styled.span`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const PagePreviewTextAndTableModalSpanTrigger = ({
  style = {},
  tableDocumentLocation = {},
  children,
  pageResultsCache = null,
  setPageResultsCache = () => {},
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TooltipNew style={style} tipText="Click to see reference">
        <Span onClick={() => setOpen(true)}>{children}</Span>
      </TooltipNew>
      <PagePreviewTextAndTableModal
        open={open}
        handleClose={() => setOpen(false)}
        tableDocumentLocation={tableDocumentLocation}
        refText={tableDocumentLocation?.desciprtion || ""}
        pageResultsCache={pageResultsCache}
        setPageResultsCache={setPageResultsCache}
      />
    </>
  );
};

export default PagePreviewTextAndTableModalSpanTrigger;
