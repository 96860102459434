import { Code } from "@material-ui/icons";
import { Editor } from "@monaco-editor/react";
import { useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";

const StyledEditor = styled(Editor)`
  height: 600px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const StyledCodeIcon = styled(Code)`
  color: ${props =>
    props.isBlue ? props.theme.color.primary : props.theme.color.closer2};
  :hover {
    opacity: 0.6;
  }
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  width: 900px;
`;

const Name = styled.div`
  font-weight: 600;
  padding-bottom: 8px;
`;

const CodeModalTrigger = ({ name = "" }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledCodeIcon onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Name>{name}</Name>
          <StyledEditor
            options={{
              readOnly: true,
            }}
            language="python"
            value={`import json
import pandas as pd

# 1. LOAD INPUT
data = json.loads(input())

group_by_key = "State"

# 2. CONVERT TO PANDAS
data = [{k: v for k, v in row.items() if k in row["_columnNameToMeta"]} for row in data]
squashed_data = [{k: v["value"] for k, v in row.items()} for row in data]
df = pd.DataFrame(squashed_data)

# 3. DO TRANSFORMATION
df = df.groupby(group_by_key).sum()

# 4. CONVERT BACK
results = []
for group_by_val, row in df.iterrows():
    result = {group_by_key: {"value": group_by_val}} | {
        k: {"value": row[k]} for k in row.index.values
    }
    results.append(result)

# 5. RETURN OUTPUT
print(json.dumps(results))`}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CodeModalTrigger;
