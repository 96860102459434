import { useNavigate, useSearchParams } from "react-router-dom";
import { parseJson } from "utils/common";

const isEmptyArray = value => {
  return Array.isArray(value) && value.length === 0;
};

const useSearchParamsState = ({
  paramName: searchParamName,
  initialValue = [],
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const parsedValue = parseJson(searchParams.get(searchParamName));
  const value = isEmptyArray(parsedValue) ? initialValue : parsedValue;

  const setValue = newValue => {
    searchParams?.set(searchParamName, JSON.stringify(newValue));
    navigate(`?${searchParams.toString()}`);
  };

  return [value, setValue];
};

export default useSearchParamsState;
