import {
  postAndStreamResponse,
  postAndStreamResponseCompare,
} from "api/api-http-methods";
import { ChatIcon, CodingIcon } from "components/IconsNew";
import LayoutNew from "components/LayoutNew";
import ButtonIcon from "components/ui/ButtonIcon";
import ButtonWord from "components/ui/ButtonWord";
import Tooltip from "components/ui/Tooltip";
import { round } from "lodash";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  justify-items: center;
  align-content: start;
  padding-top: 80px;
  gap: 20px;
  grid-template-rows: auto auto auto 1fr;
  height: 100%;
  width: 100%;
`;

const StyledInput = styled.input`
  height: 36px;
  width: 600px;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Panels = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
  overflow: auto;
  border-top: 1px solid #e0e0e0;
  grid-template-rows: 1fr auto;
`;

const Panel = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 20px;
  line-height: 1.5;
`;

const StyledSelect = styled.select`
  height: 36px;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  padding-top: 20px;
  background-color: white;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWord = styled(ButtonWord)`
  width: 70px;
  background: #515151;
  ${props =>
    props.isHighlighted &&
    `
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);,
    border-radius: 10px;
  `}
`;

const StyledButtonWordIcon = styled(ButtonWord)`
  width: 70px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: white;
  }
`;

const BottomContainer = styled.div``;

const BlocksContainer = styled.div`
  white-space: pre-wrap;
  padding-bottom: 40px;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    fill: grey;
  }
`;

const SUGGESTIONS = [
  "Tell me history of London",
  "How to solve x^2 = 4",
  "Write me a pitch deck for a startup",
];

const Suggestion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f5f7;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  :hover {
    background-color: ${COLOR2};
    color: white;
  }
`;

const LEFT_MODEL_SELECT_OPTIONS = [
  // {
  //   label: "Boltzbit LLM (L4)",
  //   value: "llama3",
  //   description: "Our default Boltzbit LLM",
  //   company: "Boltzbit",
  // },
  {
    label: "Boltzbit LLM Fast (A100)",
    value: "bz_llm",
    description: "Our fast Boltzbit LLM",
    company: "Boltzbit",
  },
];

const RIGHT_MODEL_SELECT_OPTIONS = [
  {
    label: "Gemini",
    value: "gemini",
    description: "Model developed by Google DeepMind",
    company: "Google",
  },
  {
    label: "GPT-4o",
    value: "gpt-4o",
    description: "Open AI's model",
    company: "OpenAI",
  },
  {
    label: "GPT-4o Mini",
    value: "gpt-4o-mini",
    description: "Open AI's model",
    company: "OpenAI",
  },
];

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  BASE_URL = "https://ocr.boltztest.com";
  // BASE_URL = "https://flow.boltzbit.com";
}

const CompareModelsPage = () => {
  const [query, setQuery] = useState("");
  const rightTextRef = useRef(null);
  const leftTextRef = useRef(null);
  const navigate = useNavigate();

  const [leftState, setLeftState] = useState({
    model: "bz_llm",
    blocks: [],
    isGenerating: false,
    tokensPerSecond: null,
    text: "",
  });
  const [rightState, setRightState] = useState({
    model: "gpt-4o",
    blocks: [],
    isGenerating: false,
    tokensPerSecond: null,
    text: "",
  });

  const [abortController, setAbortController] = useState(new AbortController());

  const doSendQuery = async (side = "left") => {
    const sideState = side === "left" ? leftState : rightState;
    const setSideState = side === "left" ? setLeftState : setRightState;
    const textRef = side === "left" ? leftTextRef : rightTextRef;

    setSideState(prev => ({
      ...prev,
      isGenerating: true,
      blocks: [],
      tokensPerSecond: null,
    }));

    textRef.current.innerText = "";

    // const payload = {
    //   cursor: { blockIndex: 0, letterIndex: query?.length },
    //   blocks: [{ text: query, isQuery: true }],
    //   model: sideState?.model,
    //   sources: [],
    // };

    const payload = {
      query,
      model: sideState?.model,
      maxNewTokens: 600,
    };

    const { error } = await postAndStreamResponseCompare({
      url: `${BASE_URL}/bz-api/v1/ai/streamed-plain-queries`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        console.log({ model: sideState?.model, delta: data?.delta });
        // setSideState(prev => {
        //   return {
        //     ...prev,
        //     tokensPerSecond: data?.tokensPerSecond,
        //   };
        // });
        if (data?.tokensPerSecond) {
          setSideState(prev => ({
            ...prev,
            tokensPerSecond: data?.tokensPerSecond,
          }));
        }

        const currentText = textRef.current.innerText;
        textRef.current.innerText = currentText + data?.delta;
      },
    });

    setSideState(prev => ({ ...prev, isGenerating: false }));
  };

  const doStopGeneration = async () => {
    abortController.abort();
    setAbortController(new AbortController());
    setLeftState(prev => ({ ...prev, isGenerating: false }));
    setRightState(prev => ({ ...prev, isGenerating: false }));
  };

  const isGenerating = leftState?.isGenerating || rightState?.isGenerating;

  let rightButton = (
    <StyledButtonWord
      style={{ opacity: query ? 1 : 0.6 }}
      isHighlighted={!!query}
      onClick={() => {
        doSendQuery("left");
        doSendQuery("right");
      }}
    >
      Go
    </StyledButtonWord>
  );
  if (isGenerating) {
    rightButton = (
      <StyledButtonWord onClick={doStopGeneration}>Stop</StyledButtonWord>
    );
  }

  const leftModelOption = LEFT_MODEL_SELECT_OPTIONS.find(
    option => option?.value === leftState?.model
  );
  const rightModelOption = RIGHT_MODEL_SELECT_OPTIONS.find(
    option => option?.value === rightState?.model
  );

  return (
    <LayoutNew isModelDropdownVisible={false}>
      <Container>
        <Title>
          Compare {leftModelOption?.company} vs {rightModelOption?.company}
        </Title>
        <div
          style={{
            display: "flex",
            gap: 10,
            width: "100%",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <StyledInput
            value={query}
            onChange={e => setQuery(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                doSendQuery("left");
                doSendQuery("right");
              }
            }}
            type="text"
            placeholder="Enter your question or select an option below"
            disabled={isGenerating}
          />
          {rightButton}

          <div style={{ position: "absolute", right: 10 }}>
            <StyledButtonWordIcon isPrimary onClick={() => navigate(`/chat`)}>
              <ChatIcon />
            </StyledButtonWordIcon>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 20,
          }}
        >
          {SUGGESTIONS.map(suggestion => (
            <Suggestion onClick={() => setQuery(suggestion)}>
              {suggestion}
            </Suggestion>
          ))}
        </div>
        <Panels>
          {["left", "right"]?.map(side => {
            let sideState = side === "left" ? leftState : rightState;
            let setSideState = side === "left" ? setLeftState : setRightState;
            let modelOptions =
              side === "left"
                ? LEFT_MODEL_SELECT_OPTIONS
                : RIGHT_MODEL_SELECT_OPTIONS;
            let textRef = side === "left" ? leftTextRef : rightTextRef;

            let pythonCodeUrl = "/static/bz_llm_client.py";
            if (side === "right") {
              pythonCodeUrl = "/static/other_llm_client.py";
            }

            return (
              <Panel style={{ borderRight: "1px solid #e0e0e0" }}>
                <StickyContainer>
                  <StyledSelect
                    value={sideState?.model}
                    onChange={e =>
                      setSideState(prev => ({ ...prev, model: e.target.value }))
                    }
                  >
                    {modelOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </StyledSelect>
                  <IconButton>
                    <Tooltip title="Download python code">
                      <a href={pythonCodeUrl} download>
                        <CodingIcon />
                      </a>
                    </Tooltip>
                  </IconButton>
                  <div style={{ color: "#666666", justifySelf: "end" }}>
                    Tokens per second:{" "}
                    {round(sideState?.tokensPerSecond) || "-"}
                  </div>
                </StickyContainer>
                <BlocksContainer ref={textRef}></BlocksContainer>
              </Panel>
            );
          })}
        </Panels>
      </Container>
    </LayoutNew>
  );
};

export default CompareModelsPage;
