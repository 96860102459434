import styled from "styled-components";
import { useState, useEffect } from "react";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { getUserProfile } from "api/services/projectService";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import { getDirectoryItems } from "api/services/fileSystem";
import { format } from "date-fns";
import {
  ExcelIcon,
  InternetIcon,
  PdfIcon,
  SigmaIcon,
  WordIcon,
} from "components/ui/Icons";
import { Link, useNavigate } from "react-router-dom";
import { Email } from "@material-ui/icons";
import FileTagFetcher from "components/FileTagFetcher";

const NUM_ITEMS = 100;

const getDirectoryItemCardFields = item => {
  let itemIcon = null;
  let itemName = item?.originalName || item?.id;

  let itemUrl = `/working-files`;
  if (item?.type === "WORD_DOC") {
    itemIcon = (
      <WordIcon
        style={{ gridRow: "span 2", height: "36px", fill: "#175bbc" }}
      />
    );
    itemName = item?.fileName;
    itemUrl = `/word-docs/${item?.id}`;
  }
  if (item?.type === "EXCEL_MODEL") {
    itemIcon = (
      <ExcelIcon
        style={{ gridRow: "span 2", height: "36px", fill: "#1dd573" }}
      />
    );
    itemName = item?.name;
    itemUrl = `/excel-views/${item?.id}`;
  }
  if (item?.originalName?.includes(".pdf")) {
    itemIcon = (
      <PdfIcon style={{ gridRow: "span 2", height: "36px", fill: "#d9534f" }} />
    );
    itemName = item?.fileName;
    itemUrl = `/file/${item?.id}`;
  }
  if (item?.originalName?.includes(".xlsx")) {
    itemIcon = (
      <ExcelIcon
        style={{ gridRow: "span 2", height: "36px", fill: "#188038" }}
      />
    );
    itemName = item?.fileName;
    itemUrl = `/file/${item?.id}`;
  }
  if (item?.type === "EMAIL_FILE") {
    itemIcon = (
      <Email style={{ gridRow: "span 2", height: "36px", fill: "grey" }} />
    );
    itemName = item?.emailSubject;
    itemUrl = `/email-files/${item?.id}`;
  }
  if (item?.type === "WEB_PAGE") {
    itemIcon = (
      <InternetIcon
        style={{ gridRow: "span 2", height: "36px", fill: "#61E5B7" }}
      />
    );
    itemName = item?.fileName || "Name missing";
    itemUrl = `/web-pages/${item?.id}`;
  }
  if (item?.type === "DIRECTORY") {
    itemIcon = (
      <SigmaIcon
        style={{ gridRow: "span 2", height: "36px", fill: "#d1d0d0" }}
      />
    );
    itemName = item?.path?.split("/")?.slice(-1)[0] || "Name missing";
    itemUrl = `/template-files?directoryPath=${item?.path}`;
  }

  const createdAt =
    item?.createdAt || item?.createdAtMs
      ? format(
          new Date(item?.createdAt || item?.createdAtMs),
          "dd/MM/yyyy HH:mm"
        )
      : "";

  return { itemIcon, itemName, itemUrl, createdAt };
};

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)``;

const Panel = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 14px;
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(5, auto) 1fr;
  align-items: start;
  column-gap: 40px;
  row-gap: 8px;
  width: 50%;

  box-shadow: ${props => props.theme.shadow};
`;

const TitleText = styled.div`
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 8px;
  height: 18px;
`;

const Text = styled.div`
  height: 14px;
`;

const BlueBoldText = styled.div`
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

const UserAvatar = styled.img`
  height: 150px;
  width: 150px;
  background-color: ${props => props.theme.color.closer0};

  border: double 2px transparent;
  border-radius: 300px;
  background-image: linear-gradient(white, white),
    linear-gradient(
      to right,
      ${props => props.theme.color.primary},
      ${props => props.theme.color.feature}
    );
  background-origin: border-box;
  background-clip: content-box, border-box;

  object-fit: cover;

  grid-row: span 8;
`;

const StyledNavWithTabsWithArrows = styled(NavWithTabsWithArrows)`
  width: 100%;
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
`;

const Card = styled(Link)`
  text-decoration: none;
  width: 296px;
  color: ${props => props.theme.color.closest};

  padding: 12px;
  background-color: ${props => props.theme.color.closer0};
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  gap: 4px;

  box-shadow: ${props => props.theme.shadow};
  :hover {
    box-shadow: ${props => props.theme.shadowHover};
    background-color: ${props => props.theme.color.closer0_hover};
  }
`;

const CardTitle = styled.div`
  font-weight: 900;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardSmallText = styled.div`
  font-size: 12px;
  ${props => !props.isVisible && "opacity: 0;"}
`;

const WelcomeText = styled.div`
  grid-column: span 2;
  font-weight: 900;
  font-size: 24px;
  padding-bottom: 20px;
`;

const CardToTemplate = ({ directoryPath, children }) => {
  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    doPopulateTemplateId();
  }, [directoryPath]);

  const doPopulateTemplateId = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setTemplateId(data?.items?.find(item => item?.isTemplate)?.id);
  };

  return <Card to={`/template-dashboards/${templateId}`}>{children}</Card>;
};

const ProfilePipelinesPage = () => {
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState(null);
  const [templateFiles, setTemplateFiles] = useState([]);

  useEffect(() => {
    doPopulateUserProfile();
    doPopulateTemplateFiles();
  }, []);

  const doPopulateUserProfile = async () => {
    const { data } = await getUserProfile();
    setUserProfile(data);
  };

  const doPopulateTemplateFiles = async () => {
    const { data } = await getDirectoryItems({
      directoryPath: "/template-files",
      pageSize: NUM_ITEMS,
    });
    setTemplateFiles(data?.items || []);
  };

  return (
    <StyledCenteredWithTopNavLayout>
      <Panel>
        <WelcomeText>Welcome to Boltzflow</WelcomeText>
        <UserAvatar src={`data:image/png;base64,${userProfile?.image}`} />
        <TitleText>
          {userProfile?.firstName} {userProfile?.lastName}
        </TitleText>
        <BlueBoldText>Position</BlueBoldText>
        <Text>{userProfile?.position}</Text>
        <BlueBoldText>Company</BlueBoldText>
        <Text>{userProfile?.organisation}</Text>
        <BlueBoldText>Teams</BlueBoldText>
        <Text>Equity</Text>
      </Panel>
      <StyledNavWithTabsWithArrows
        tabNames={["Overview", "Source", "Space", "Pipelines"]}
        selectedTabName="Pipelines"
        tabOptionStyle={{ padding: "15px 24px", fontWeight: 600 }}
        onTabSelect={tabName => {
          if (tabName === "Overview") {
            navigate(`/`);
            return;
          }
          navigate(`/profile/${tabName?.toLowerCase()}`);
        }}
      />
      <Gap height="40px" />
      <TitleText>Recent Pipelines</TitleText>
      <Gap height="10px" />
      <CardsContainer>
        {templateFiles?.slice(0, NUM_ITEMS + 1).map(item => {
          const { itemIcon, itemName, itemUrl, createdAt } =
            getDirectoryItemCardFields(item);
          return (
            <CardToTemplate directoryPath={item?.path}>
              <CardTitle>{itemName}</CardTitle>
              {itemIcon}
              <CardSmallText isVisible={createdAt}>
                Created on {createdAt}
              </CardSmallText>
              <FileTagFetcher fileId={item?.id} isReadOnly />
            </CardToTemplate>
          );
        })}
      </CardsContainer>
      <Gap height="80px" />
    </StyledCenteredWithTopNavLayout>
  );
};

export default ProfilePipelinesPage;
