import { clamp } from "lodash";
import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
`;

const Span = styled.span`
  white-space: pre;
  font-family: "Montserrat", sans-serif;
`;

const InputExpanding = ({
  className,
  value = "",
  onChange = e => {},
  onBlur = e => {},
  onKeyDown = e => {},
  onClick = e => {},
  placeholder,
  style = {},
  minWidth = 120,
  maxWidth = 400,
}) => {
  const inputRef = useRef();
  const spanRef = useRef();

  useEffect(() => {
    if (!inputRef?.current || !spanRef?.current) {
      return;
    }

    const spanRect = spanRef.current.getBoundingClientRect();
    const newWidth = clamp(spanRect.width, minWidth, maxWidth) + 4;
    inputRef.current.style.width = `${newWidth}px`;
  }, [value, minWidth, maxWidth, inputRef?.current, spanRef?.current]);

  return (
    <>
      <Input
        className={className}
        style={style}
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onClick={onClick}
        placeholder={placeholder}
      />
      <Span
        className={className}
        style={{
          ...style,
          position: "fixed",
          top: "-100px",
          pointerEvents: "none",
          visibility: "hidden",
          backgroundColor: "salmon",
        }}
        ref={spanRef}
      >
        {value}
      </Span>
    </>
  );
};

export default InputExpanding;
