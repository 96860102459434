import * as backend from "api/backend/dataServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { sleep } from "utils/common";

export const getDatasetsFindByKey = async (params = {}) => {
  const { data, error } = await backend.getDatasetsFindByKey(params);
  return { data, error: getHandlingFromError(error) };
};

export const getDataIngestionJob = async (jobId, params = {}) => {
  const { data, error } = await backend.getDataIngestionJob(jobId, params);
  return { data, error: getHandlingFromError(error) };
};

export const getDataIngestionJobUntilFinish = async (jobId, params = {}) => {
  const { data, error } = await backend.getDataIngestionJob(jobId, params);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  if (data?.status === "IN_PROGRESS") {
    await sleep(1000);
    const res = await getDataIngestionJobUntilFinish(jobId, params);
    return res;
  }

  return { data, error };
};
