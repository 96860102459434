import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import {
  decodeStoredJwt,
  getLoggedInUserName,
} from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import ItemListPopover from "components/ItemListPopover";
import LoginModal from "./LoginModal";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import Tooltip from "components/ui/Tooltip";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import { HumanIcon } from "components/ui/Icons";
import EmailPasswordModal from "components/EmailPasswordModal";
import ModelSelectDropdown from "components/ModelSelectDropdown";
import { PROFILE_URL } from "pages/profile-new/ProfileSidebar";
import ButtonWord from "components/ui/ButtonWord";
import { isEmpty } from "lodash";
import LanguageSelectDropdown from "components/LanguageSelectDropdown";
import { PersonBlankIcon } from "components/IconsNew";

const NavBody = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px 0;
  padding-left: 30px;
  padding-right: 12px;
  height: 41px;
  box-sizing: content-box;
  width: ${props => props.hasStickySearchBar && "100%"};
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  background-color: ${props => props.bgColor || props.theme.color.furthest};
  /* ${props =>
    !props.isBottomBorderHidden &&
    `border-bottom: 1px solid ${props.theme.color.closer1};`} */
  box-shadow: 0px 8px 10px -8px rgba(0, 0, 0, 0.18);
  padding: 0;
  height: 50px;
  padding-right: 12px;
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const UserName = styled.div`
  padding: 10px;
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const PopoverNavLink = styled(Link)`
  padding: 10px 100px 10px 10px;
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PopoverNavItem = styled.div`
  padding: 10px 100px 10px 10px;
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const UserAvatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.color.primary};
  object-fit: cover;
`;

const DefaultUserAvatar = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 1px solid ${props => props.theme.color.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginDiv = styled.div`
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  padding: 80px 100px;
  display: grid;
  ${props => props.isDisabled && "opacity: 0.6; pointer-events: none;"};
  box-shadow: ${props => props.theme.shadow};
  background-color: #cddefd;
  border-radius: 10px;
  line-height: 1.5;
  text-align: center;
  font-size: 20px;
  width: 700px;
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const StyledSelect = styled.select`
  z-index: 100;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const NavHeader = ({
  bgColor,
  isBottomBorderHidden,
  className,
  isProfileOnly,
  dontPopOutNewNotifications = false,
  isModelDropdownVisible = false,
}) => {
  const tokenObj = decodeStoredJwt();
  const userName = getLoggedInUserName();

  const [avatarSrc, setAvatarSrc] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isFetchingProfile, setIsFetchingProfile] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const doFetchCreatorAvatar = async () => {
    const { data, error: apiErr } = await getUserProfileByUserName(userName);
    setError(apiErr);
    if (apiErr?.type === "Unauthorised") {
      navigate("/login");
      return;
    }

    setUserProfile(data);
    setAvatarSrc(data?.image);
    setIsFetchingProfile(false);
  };

  useEffect(() => {
    if (searchParams?.get("isLoginModalVisible") === "true") {
      setShowLoginModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [userName]);

  useEffect(() => {
    if (
      !userProfile?.name &&
      !isFetchingProfile &&
      userName !== "trial-user" &&
      !isEmpty(tokenObj) &&
      !error
    ) {
      navigate("/login-my-account");
    }
  }, [JSON.stringify(userProfile), isFetchingProfile]);

  const profilePageUrl = PROFILE_URL;
  const hasNoProfilePic =
    !userProfile?.image ||
    userProfile?.image === "/images/avatar-placeholder.jpeg";
  const isGeneratedUser = userProfile?.email?.includes("@boltzbit.io");

  const isTrialUser = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");
  const isMobile = window?.screen?.width < 1240;

  if (isTrialUser && isMobile) {
    return (
      <NavBody
        className={className}
        bgColor={bgColor}
        isBottomBorderHidden={isBottomBorderHidden}
      >
        <ButtonWord
          style={{ height: 32, width: 100, fontSize: 14 }}
          isPrimary
          onClick={() => {
            window.open("/signup", "_blank");
          }}
        >
          Sign up
        </ButtonWord>
      </NavBody>
    );
  }

  if (isTrialUser && !isMobile) {
    return (
      <NavBody
        className={className}
        bgColor={bgColor}
        isBottomBorderHidden={isBottomBorderHidden}
      >
        <ModelSelectDropdown />
        <ButtonWord
          style={{ height: 32, width: 100, fontSize: 14 }}
          isPrimary
          onClick={() => window.open("/signup", "_blank")}
        >
          Sign up
        </ButtonWord>
        <ButtonWord
          style={{ height: 32, width: 100, fontSize: 14 }}
          isPrimary
          onClick={() => navigate("/login")}
        >
          Login
        </ButtonWord>
      </NavBody>
    );
  }

  let popupContent = (
    <LoginDiv
      onClick={() => {
        setShowLoginModal(true);
      }}
    >
      Login
    </LoginDiv>
  );

  if (userName) {
    popupContent = (
      <ItemListPopover
        iconComponent={
          hasNoProfilePic ? (
            <DefaultUserAvatar>
              <PersonBlankIcon height="70%" />
            </DefaultUserAvatar>
          ) : (
            <UserAvatar src={avatarSrc} />
          )
        }
      >
        {/* <UserName>Welcome to Boltzflow!</UserName> */}
        <PopoverNavLink to={profilePageUrl}>Profile</PopoverNavLink>
        {/* <PopoverNavLink to="/source-files">Source Files</PopoverNavLink>
        <PopoverNavLink to="/working-files">Working Files</PopoverNavLink> */}
        <PopoverNavLink
          to="/"
          onClick={() => {
            setLoginTokenInClientStorage("PUBLIC");
            window.location = "/login";
          }}
        >
          Logout
        </PopoverNavLink>
      </ItemListPopover>
    );
  }

  if (isGeneratedUser) {
    popupContent = (
      <ItemListPopover
        iconComponent={
          hasNoProfilePic ? (
            <DefaultUserAvatar>
              <HumanIcon height="100%" />
            </DefaultUserAvatar>
          ) : (
            <UserAvatar src={avatarSrc} />
          )
        }
      >
        <EmailPasswordModal
          onClickDone={() => window.location.reload()}
          triggerComponent={<PopoverNavItem>Sign up</PopoverNavItem>}
        />
      </ItemListPopover>
    );
  }

  return (
    <>
      <LoginModal
        open={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
      />
      <NavBody
        className={className}
        bgColor={bgColor}
        isBottomBorderHidden={isBottomBorderHidden}
      >
        {isModelDropdownVisible && <ModelSelectDropdown />}
        {isModelDropdownVisible && <LanguageSelectDropdown />}
        {/* {isModelDropdownVisible && (
          <StyledSelect
            value={language}
            onChange={e => {
              setLanguage(e.target.value);
              localStorage.setItem("language", e.target.value);
            }}
          >
            <option>EN</option>
            <option>FR</option>
            <option>ES</option>
            <option>DE</option>
          </StyledSelect>
        )} */}
        {!isProfileOnly && (
          <Tooltip title="Jobs">
            <JobsTooltipTrigger
              dontPopOutNewNotifications={dontPopOutNewNotifications}
            />
          </Tooltip>
        )}
        <LinksContainer>{popupContent}</LinksContainer>
        {/* <Modal
          open={!!userProfile && !userProfile?.isVerified && !isGeneratedUser}
        >
          <ModalContent>
            <div>
              An email has been sent to{" "}
              <BoldSpan>{userProfile?.email}</BoldSpan>, please verify your
              account.
            </div>
            <Gap height="40px" />
            <div>
              To send documents or emails to your account, you can email{" "}
              <BoldSpan>data@boltzbit.io</BoldSpan>.
            </div>
            <Gap height="40px" />
            <div>We will be in touch soon!</div>
          </ModalContent>
        </Modal> */}
      </NavBody>
    </>
  );
};

export default NavHeader;
