import { useState } from "react";
import styled from "styled-components";

const Container = styled.div``;

const TextParagraph = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 10px;
`;

const TableContainer = styled.div`
  overflow: auto;
  max-height: 600px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const Td = styled.td`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
`;

const TableTitle = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  padding: 8px 0;
  font-weight: 600;
  background-color: ${props => props.theme.color.furthest};
`;

const Table = ({ style, name, rows }) => {
  const columnNames = rows?.[0];

  return (
    <TableContainer style={style}>
      <TableTitle>{name}</TableTitle>
      <StyledTable>
        <tr>
          {columnNames?.map(cell => (
            <Th>{cell}</Th>
          ))}
        </tr>
        {rows?.slice(1)?.map((row, index) => {
          return (
            <tr key={index}>
              {row?.map(cell => (
                <Td>{cell}</Td>
              ))}
            </tr>
          );
        })}
      </StyledTable>
    </TableContainer>
  );
};

const ModalSerchResults = ({ searchResults }) => {
  return (
    <Container onMouseDown={e => e.stopPropagation()}>
      {searchResults?.map(result => {
        if (result?.rows) {
          return <Table rows={result?.rows} />;
        }

        if (result?.text) {
          return <TextParagraph>{result?.text}</TextParagraph>;
        }

        return <div>{JSON.stringify(result)}</div>;
      })}
    </Container>
  );
};

export default ModalSerchResults;
