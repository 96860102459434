import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { uniq } from "lodash";

import { CenteredWithTopNavLayout } from "components/Layout";
import TextInputSquare from "components/inputs/TextInputSquare";
import { getFiles } from "api/services/filesService";
import MultiSelectWithFilter from "components/ui/MultiSelectWithFilter";
import Button from "components/ui/Button";
import { postRunWithTableConfig } from "api/services/sigmaService";
import ProgressBarFakeProgress from "components/widgets/ProgressBarFakeProgress";
import SigmaConfigEditor from "components/SigmaConfigEditor";
import PopulateColumnsFromExcelModal from "components/widgets/PopulateColumnsFromExcelModal";
import SingleSelectWithFilterAndTickBoxes from "components/ui/SingleSelectWithFilterAndTickBoxes";
import usePollFilesStatuses from "api/services/usePollFilesStatuses";

const DOCUMENT_NAME_SIGMA_CONFIG = {
  name: "Document name",
  rule: {
    name: "GPT_AUTO_EXTRACT_RULE",
    type: "GPT",
    description:
      '{"arguments": [{"name": "prompts", "type": "text", "optional": false}, {"name": "block_type", "type": "select", "options": ["title", "list", "table"], "optional": true}, {"name": "value_type", "type": "select", "options": ["string", "float", "integer"], "optional": true}]}',
    id: "63be4c706267d63d91767277",
  },
  description: '{"prompts": ["Source"]}',
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-areas:
    "back-link ."
    "page-title page-title"
    "page-sub-title page-sub-title"
    "section-heading-1 ."
    "table-name-input ."
    "section-heading-columns ."
    "section-sub-heading-columns ."
    "sigma-configs-selector sigma-config-details"
    "section-heading-rows ."
    "id-column-dropdown ."
    "section-heading-2 ."
    "data-files-selector ."
    "keyword-input ."
    "create-button file-status"
    "create-error .";
  gap: 20px;
  padding-bottom: 100px;
  width: 100%;
  align-items: start;
`;

const BackLink = styled(Link)`
  grid-area: back-link;
  margin-bottom: 20px;
`;

const PageTitle = styled.div`
  font-size: 36px;
  font-weight: 600;
  grid-area: page-title;
`;

const SectionSubHeadingColumns = styled.div`
  grid-area: section-sub-heading-columns;
  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */
`;

const TableNameInput = styled(TextInputSquare)`
  grid-area: table-name-input;
`;

const KeywordInput = styled(TextInputSquare)`
  grid-area: keyword-input;
  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */
`;

const DataFilesSelector = styled(MultiSelectWithFilter)`
  grid-area: data-files-selector;
  width: 100%;
  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */
`;

const SigmaConfigsSelector = styled(SingleSelectWithFilterAndTickBoxes)`
  width: 100%;
  grid-area: sigma-configs-selector;
  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */
  height: 400px;
  overflow: auto;
`;

const CreateButton = styled(Button)``;

const SectionHeading1 = styled.div`
  grid-area: section-heading-1;
  font-size: 22px;
  font-weight: 600;

  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */
`;

const SectionHeading2 = styled(SectionHeading1)`
  margin-top: 60px;
  grid-area: section-heading-2;
`;

const SectionHeadingColumns = styled(SectionHeading1)`
  margin-top: 60px;
  margin-bottom: 0;
  grid-area: section-heading-columns;
`;

const CreateBtnAndStatus = styled.div`
  margin-top: 80px;
  grid-area: create-button;
  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */

  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
`;

const CreateError = styled.div`
  margin-top: 80px;
  grid-area: create-error;
  color: ${props => props.theme.color.error};
`;

const CreateTableConfigPage = () => {
  const [name, setName] = useState("");
  const [dataFilesOptions, setDataFilesOptions] = useState([]);
  const [selectedDataFileNames, setSelectedDataFileNames] = useState([]);

  const [sigmaConfigs, setSigmaConfigs] = useState([DOCUMENT_NAME_SIGMA_CONFIG]);
  const [selectedSigmaConfigIndex, setSelectedSigmaConfigIndex] = useState(null);

  const [keywordFilter, setKeywordFilter] = useState("");

  const [idColumnIndices, setIdColumnIndices] = useState([0]);

  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingTable, setIsCreatingTable] = useState(false);
  const [creationErr, setCreationErr] = useState(null);

  const [tableConfigId, setTableConfigId] = useState(null);
  const [fileIdsToPoll, setFileIdsToPoll] = useState([]);
  const fileStatuses = usePollFilesStatuses(fileIdsToPoll);

  const navigate = useNavigate();

  useEffect(() => {
    if (fileStatuses?.length > 0 && fileStatuses?.every(status => status === "DONE")) {
      navigate(`/table-configs/${tableConfigId}/results`);
    }
  }, [JSON.stringify(fileStatuses)]);

  useEffect(() => {
    doPopulateFiles();
  }, []);

  const doPopulateFiles = async () => {
    const { data } = await getFiles({ pageSize: 30 });
    setDataFilesOptions(data);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  let fileStatusContent = null;
  if ((fileStatuses?.length > 0 && fileStatuses?.some(status => status === "IN_PROGRESS")) || isCreatingTable) {
    fileStatusContent = <ProgressBarFakeProgress />;
  }
  if (fileStatuses?.length > 0 && fileStatuses?.every(status => status === "DONE")) {
    fileStatusContent = <ProgressBarFakeProgress isDone />;
  }
  if (fileStatuses?.length > 0 && fileStatuses?.some(status => status === "FAILED")) {
    fileStatusContent = `FAILED, file id: ${fileIdsToPoll?.[fileStatuses?.indexOf("FAILED")]}`;
  }

  return (
    <CenteredWithTopNavLayout>
      <Container>
        <BackLink to="/table-configs">&lt;- Back to all tables</BackLink>
        <PageTitle>Set up your aggregation table</PageTitle>

        <SectionHeading1>Step 1: Choose name</SectionHeading1>
        <TableNameInput
          value={name}
          titleAbove="Table name"
          placeholder="Properties Summary"
          onNewInput={newValue => setName(newValue)}
        />

        <SectionHeadingColumns>Step 2: Set up data extraction rules</SectionHeadingColumns>
        <SectionSubHeadingColumns>
          Add columns and extraction rules or{" "}
          <PopulateColumnsFromExcelModal
            onNewSigmaConfigs={newSigmaConfigs => {
              setSigmaConfigs(newSigmaConfigs);
              setSelectedSigmaConfigIndex(newSigmaConfigs?.length - 1);
            }}
          />
        </SectionSubHeadingColumns>
        <SigmaConfigsSelector
          maxOptionsHeight="100%"
          options={sigmaConfigs?.map((config, i) => ({ value: i, label: config?.name }))}
          tickedValues={idColumnIndices}
          onNewTickedValues={tickedIndices => {
            setIdColumnIndices(tickedIndices);
          }}
          onSelectOption={selectedColumnId => {
            setSelectedSigmaConfigIndex(selectedColumnId);
          }}
          selectedValue={selectedSigmaConfigIndex}
          onDeleteOption={columnIndex => {
            const newSigmaConfigs = [...sigmaConfigs];
            newSigmaConfigs.splice(columnIndex, 1);
            setSigmaConfigs(newSigmaConfigs);
            setIdColumnIndices(idColumnIndices.filter(i => i !== columnIndex));

            if (selectedSigmaConfigIndex === columnIndex) {
              setSelectedSigmaConfigIndex(Math.max(0, columnIndex - 1));
            }
            if (selectedSigmaConfigIndex > columnIndex) {
              setSelectedSigmaConfigIndex(selectedSigmaConfigIndex - 1);
            }
          }}
          title="Columns"
          onClickNewButton={() => {
            const newSigmaConfig = {
              name: "New column",
              rule: {
                name: "GPT_AUTO_EXTRACT_RULE",
                type: "GPT",
                description:
                  '{"arguments": [{"name": "prompts", "type": "text", "optional": false}, {"name": "block_type", "type": "select", "options": ["title", "list", "table"], "optional": true}, {"name": "value_type", "type": "select", "options": ["string", "float", "integer"], "optional": true}]}',
                id: "63be4c706267d63d91767277",
              },
              description: '{"prompts": ["what is new column?"]}',
            };
            const newSigmaConfigs = [...sigmaConfigs, newSigmaConfig];
            setSigmaConfigs(newSigmaConfigs);
          }}
        />
        <SigmaConfigEditor
          sigmaConfig={sigmaConfigs[selectedSigmaConfigIndex]}
          onNewSigmaConfig={newSigmaConfig => {
            setSigmaConfigs(
              sigmaConfigs?.map((config, index) => {
                if (index === selectedSigmaConfigIndex) {
                  return newSigmaConfig;
                }
                return config;
              })
            );
          }}
        />

        <SectionHeading2>Step 3: Choose source documents</SectionHeading2>
        <DataFilesSelector
          options={dataFilesOptions?.items?.map(file => file?.fileName)}
          onSelectOptions={newNames => {
            if (!name) {
              setName(`Summary table for ${newNames?.[0]?.split(".")?.[0]}`);
            }
            setSelectedDataFileNames(newNames);
          }}
          selectedOptions={selectedDataFileNames}
          title="Document"
          shouldShowFakeNewButton
        />
        <KeywordInput
          value={keywordFilter}
          titleAbove="Filter pages by keyword (optional)"
          placeholder="Rent comp"
          onNewInput={newValue => setKeywordFilter(newValue)}
        />

        <CreateBtnAndStatus>
          <CreateButton
            variant="highlighted"
            value="Create table"
            isDisabled={isCreatingTable || !selectedDataFileNames?.length || idColumnIndices?.length === 0}
            onClick={async () => {
              setIsCreatingTable(true);
              const { data, error } = await postRunWithTableConfig({
                name,
                fileIds: dataFilesOptions?.items
                  ?.filter(file => selectedDataFileNames?.includes(file?.fileName))
                  ?.map(file => file?.id),
                sigmaConfigs,
                primarySigmaConfigNames: uniq(idColumnIndices?.map(i => sigmaConfigs?.[i]?.name)),
                keywordFilter,
              });
              setCreationErr(error);
              setFileIdsToPoll(data?.fileIds);
              setTableConfigId(data?.id);
            }}
          />
          {fileStatusContent}
        </CreateBtnAndStatus>
        {creationErr && <CreateError>{JSON.stringify(creationErr)}</CreateError>}
      </Container>
    </CenteredWithTopNavLayout>
  );
};

export default CreateTableConfigPage;
