import styled from "styled-components";
import { Close, ThumbDown, ThumbUp } from "@material-ui/icons";
import { useState } from "react";
import { round } from "lodash";
import { useEffect } from "react";
import SelectInput from "components/ui/SelectInput";
import { Link } from "react-router-dom";

const SpanCloseButtonContainer = styled.div`
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, ${props => props.theme.color.error} 20%, transparent 100%);
  cursor: pointer;
  width: 40px;
  height: calc(1em + 4px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const AdditionalLabel = styled.div`
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  position: absolute;
  left: 0;
  top: 20px;
  background-color: ${props => props.theme.color.furthest};
  border: 1px dashed ${props => props.theme.color.primary};
  color: ${props => props.theme.color.closest};
  border-radius: 2px;
  padding: 0 4px;
  z-index: 15;
  pointer-events: none;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
`;

const AnnoSpan = styled.span`
  overflow: hidden;
  position: relative;
  display: inline;
  background-color: ${props => props.bgColor};
  color: white;
  cursor: pointer;

  :hover {
    background-color: ${props => props.bgColor}44;
  }
`;

const RuleTooltip = styled.div`
  padding: 10px;
  display: grid;
  gap: 5px;
  grid-template-columns: 150px minmax(300px, auto);
  cursor: auto;
`;

const LabelText = styled.div``;

const Label = styled.div`
  font-weight: 600;
  max-height: 150px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelColorGradient = styled.div`
  font-weight: 600;
  color: ${props => `rgb(${(1 - props.score) * 255}, ${props.score * 200}, 0)`};
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 5px;
`;

const ThumbButtonContainer = styled.div`
  color: ${props => (props.isHighlighted ? props.theme.color.primary : props.theme.color.closest)};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const AnnotationSpan = ({
  onClick,
  onClickThumb,
  thumb0or1,
  children,
  bgColor,
  hoverText,
  contentStr,
  fakeRule,
  scoreValue,
  isUserEntitySpan,
  spanEntity,
  onNewSpanEntity,
  additionalLabelUrl = "",
  isHumanLabel,
}) => {
  const [generatedScore] = useState(parseFloat(scoreValue) || Math.random());

  const [isMouseOverSpan, setIsMouseOverSpan] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (isMouseOverSpan) {
      setIsTooltipVisible(true);
    }

    if (!isMouseOverSpan && !isMouseOverTooltip) {
      setIsTooltipVisible(false);
    }
  }, [isMouseOverSpan, isMouseOverTooltip]);

  const isContentANumber = parseInt(contentStr) && `${parseInt(contentStr)}`?.length === contentStr?.length;
  let typeText = isContentANumber ? "NUMERICAL" : "TEXT";
  typeText = fakeRule?.description?.value_type || typeText;

  return (
    <AnnoSpan
      bgColor={bgColor}
      onClick={onClick}
      onMouseEnter={() => setIsMouseOverSpan(true)}
      onMouseLeave={() => setIsMouseOverSpan(false)}
    >
      {children}
      <SpanCloseButtonContainer>
        <Close />
      </SpanCloseButtonContainer>
      <AdditionalLabel
        isVisible={!!hoverText}
        style={{
          opacity: isTooltipVisible ? 1 : 0,
          zIndex: isTooltipVisible ? 15 : 0,
          pointerEvents: isTooltipVisible ? "all" : "none",
        }}
        onMouseEnter={() => setIsMouseOverTooltip(true)}
        onMouseLeave={() => setIsMouseOverTooltip(false)}
      >
        <RuleTooltip>
          <LabelText>Label:</LabelText>
          <Label>{additionalLabelUrl ? <Link to={additionalLabelUrl}>{hoverText}</Link> : hoverText}</Label>
          <LabelText>Type:</LabelText>
          <Label>{typeText}</Label>
          {contentStr?.includes("SF") && (
            <>
              <LabelText>Units:</LabelText>
              <Label>Square Feet</Label>
            </>
          )}
          <LabelText>Extraction Model:</LabelText>
          <Label>{fakeRule?.name || "GPT AUTO EXTRACT"}</Label>
          {!isHumanLabel && (
            <>
              <LabelText>Score:</LabelText>
              <LabelColorGradient score={generatedScore}>
                {round(generatedScore, 2)}
                <ThumbButtonContainer
                  isHighlighted={thumb0or1 === 1}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickThumb(thumb0or1 === 1 ? null : 1);
                  }}
                >
                  <ThumbUp />
                </ThumbButtonContainer>
                <ThumbButtonContainer
                  isHighlighted={thumb0or1 === 0}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickThumb(thumb0or1 === 0 ? null : 0);
                  }}
                >
                  <ThumbDown />
                </ThumbButtonContainer>
              </LabelColorGradient>
            </>
          )}
        </RuleTooltip>
      </AdditionalLabel>

      {isUserEntitySpan && (
        <AdditionalLabel
          isVisible
          style={{
            opacity: isTooltipVisible ? 1 : 0,
            zIndex: isTooltipVisible ? 15 : 0,
            pointerEvents: isTooltipVisible ? "all" : "none",
          }}
          onClick={e => e.stopPropagation()}
          onMouseEnter={() => setIsMouseOverTooltip(true)}
          onMouseLeave={() => setIsMouseOverTooltip(false)}
        >
          <RuleTooltip>
            <Label>Entity type:</Label>
            <SelectInput
              value={spanEntity?.type || "-"}
              onSetNewValue={newType => onNewSpanEntity({ ...spanEntity, type: newType })}
            >
              <option>-</option>
              <option>UNIT</option>
              <option>DATA</option>
            </SelectInput>
          </RuleTooltip>
        </AdditionalLabel>
      )}
    </AnnoSpan>
  );
};

export default AnnotationSpan;
