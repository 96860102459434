import * as emailsBackend from "api/backend/emailServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getEmailContent = async emailId => {
  const { data, error } = await emailsBackend.getEmailContent(emailId);
  return { data, error: getHandlingFromError(error) };
};

export const getEmail = async emailId => {
  const { data, error } = await emailsBackend.getEmail(emailId);
  return { data, error: getHandlingFromError(error) };
};
