import { clamp, cloneDeep, isNil, last, max, range } from "lodash";
import { getColorFromString } from "utils/common";

const IMG_BASE64 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAABHNCSVQICAgIfAhkiAAABCJJREFUeJzt3bFt20AYgNEoyBZBGjcujKyTrJAB3Kpy6wE8Q/bQCC7cuAkyB7OBWFwOR/l7rzVEEibx4Qd4Op22y7Z9ApI+r74AYB0BgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgLAvqy9g1Ncf34Y+//f3n/90Jazg/o8xAUCYAECYAECYAECYAECYAECYAEDY4dcB7L3n/XV+nnr8+nvi1dz/uUwAECYAECYAECYAECYAECYAECYAECYAECYAECYAECYAECYAECYAECYAECYAEHbaLtu28gJmf9971MvT49Lz1x39/t/6fgEmAAgTAAgTAAgTAAgTAAgTAAgTAAg7/O8CrLb3Hvr97XXo+Hf3D0Ofr5+fMSYACBMACBMACBMACBMACBMACBMACLMOAK7YWweyt5/F0fcLMAFAmABAmABAmABAmABAmABAmABA2PR1AEff9x/KTAAQJgAQJgAQJgAQJgAQJgAQJgAQZj+AQav3ta+fnzEmAAgTAAgTAAgTAAgTAAgTAAgTAAg7/DqAl6fHq3+fvZ/A3vn31Pc7OPr/b/T5uvXfDTABQJgAQJgAQJgAQJgAQJgAQJgAQNjwOoDZ+/7vfX71OgGOzfNxnQkAwgQAwgQAwgQAwgQAwgQAwgQAwj78fgDn7z/HLuA89vHZjv59+9Hjz75/9XUCJgAIEwAIEwAIEwAIEwAIEwAIEwAIO22XbRs5wOz9AOpG3/OPcv/m2ru/s383wAQAYQIAYQIAYQIAYQIAYQIAYQIAYYffD6DOe3hmMgFAmABAmABAmABAmABAmABAmABAmABAmABAmABAmABAmABAmABAmABAmABA2PT9AN7fXqce/+7+YerxaZv9/K5mAoAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYCw6fsB+L4+t+yjP78mAAgTAAgTAAgTAAgTAAgTAAgTAAj7B3iEcMr8XLcKAAAAAElFTkSuQmCC";

export const TOP_GAP = 80;
export const VIEW_HEIGHT = window.innerHeight - 50 - 2 - 1 - 40;
export const VIEWPORT_WIDTH = 820;

const FONT_SIZE = 50;
export const LINE_HEIGHT = FONT_SIZE * 1.2;

export const HEIGHT = VIEW_HEIGHT * (1400 / 420);
export const WIDTH = VIEWPORT_WIDTH * (2000 / 600);

export const SF = WIDTH / VIEWPORT_WIDTH;
export const SF_Y = HEIGHT / VIEW_HEIGHT;

const X_MARGIN = 90 * SF;
const START_X = X_MARGIN;
const START_Y = 100 * SF_Y;
const END_X = WIDTH - X_MARGIN;

const T_START = "\u0010";
const R_START = "\u0012";
const C_START = "\u001c";
const T_END = "\u0011";

const TABLE_CHARS = [T_START, R_START, C_START, T_END];

const MAX_PAINT_Y = HEIGHT;

const drawBoundaries = (ctx, topY) => {
  ctx.beginPath();
  // ctx.moveTo(0, -topY);
  // ctx.lineTo(WIDTH, -topY);
  // ctx.moveTo(END_X, 0);
  // ctx.lineTo(END_X, HEIGHT);

  // ctx.moveTo(START_X, 0);
  // ctx.lineTo(START_X, HEIGHT);

  // ctx.moveTo(0, MIN_PAINT_Y);
  // ctx.lineTo(WIDTH, MIN_PAINT_Y);

  // ctx.moveTo(0, MAX_PAINT_Y);
  // ctx.lineTo(WIDTH, MAX_PAINT_Y);

  ctx.stroke();
};

const drawCaret = (ctx, x, y, selectionStyle, textStyle) => {
  if (selectionStyle?.isCaret) {
    ctx.beginPath();
    const prevLineWidth = ctx.lineWidth;
    ctx.lineWidth = 8;
    if (textStyle?.type === "image") {
      ctx.moveTo(x, y);
      ctx.lineTo(x, y - LINE_HEIGHT);
      ctx.stroke();
    } else {
      ctx.moveTo(x, y - LINE_HEIGHT);
      ctx.lineTo(x, y);
      ctx.stroke();
    }
    ctx.lineWidth = prevLineWidth;
  }
};

const drawImage = (ctx, x, y, style) => {
  const img = new Image();
  const coords = { x, y };
  ctx.beginPath();
  ctx.rect(coords?.x, coords?.y, style?.w, style?.h);
  ctx.stroke();
  img.src = IMG_BASE64;
  img.onload = () => {
    ctx.drawImage(img, coords?.x, coords?.y, style?.w, style?.h);
  };
};

const drawTableCellBorders = ({ ctx, content, contentCoords, topY }) => {
  let prevLineWidth = ctx.lineWidth;
  ctx.lineWidth = 2;

  if (contentCoords?.charCoords?.length !== content?.text?.length) {
    return;
  }

  const charCoords = contentCoords?.charCoords || [];

  let i = 0;
  while (i < content?.text?.length) {
    const char = content?.text?.[i];
    const [x, y] = charCoords?.[i] || [0, 0];
    if (y - topY > MAX_PAINT_Y) {
      break;
    }

    if (!TABLE_CHARS?.includes(char)) {
      i++;
      continue;
    }

    if (char === C_START) {
      let [cellX, cellY] = charCoords?.[i + 1] || [0, 0];
      const style = content?.textStyles?.[i];
      const cellWidth = (END_X - START_X) / (style?.numberOfColumns || 2);

      let j = i + 1;
      while (j < content?.text?.length && content?.text?.[j] !== T_END) {
        if (content?.text?.[j] === R_START) {
          break;
        }
        j++;
      }
      let [rStartX, rStartY] = charCoords?.[j + 2] || [0, 0];
      let cellHeight = rStartY - cellY;
      if (cellHeight < 0) {
        cellHeight = 0;
      }

      ctx.beginPath();
      ctx.rect(cellX, cellY - topY - LINE_HEIGHT, cellWidth, cellHeight);
      ctx.stroke();
    }

    if (char === R_START) {
      ctx.beginPath();
      ctx.stroke();
    }

    i++;
  }

  ctx.lineWidth = prevLineWidth;
};

export const drawContent = ({
  content = { text: "", styles: [] },
  ctx,
  topY = 0,
  contentCoords = {},
  mode = "paint",
}) => {
  let charCoords = contentCoords?.charCoords;
  let lines = contentCoords?.lines;
  if (mode === "no-paint") {
    charCoords = [];
    lines = [];
  }

  if (mode === "paint") {
    ctx.clearRect(0, 0, WIDTH, HEIGHT);
    drawBoundaries(ctx, topY);
    drawTableCellBorders({ ctx, content, contentCoords, topY });
  }

  let bgColor = "transparent";
  let fontWeight = "normal";
  let textColor = "#000000";
  let fontStyle = "normal";

  let selectionBgColor = "transparent";

  let printHead = {
    x: START_X,
    y: START_Y + LINE_HEIGHT - topY,
    // for tables
    startX: START_X,
    startY: 0,
    endX: END_X,
    maxRowY: 0,
    colIndex: 0,
  };
  let i = 0;
  // for coords calculation
  let isInTable = false;

  let startLineIndex = lines?.findIndex(line => line?.y > topY);
  let startIndex = lines?.[startLineIndex - 2]?.i || 0;
  let startY = charCoords?.[startIndex]?.[1] ?? 0;

  let { text, selectionStyles, textStyles } = content || {};

  // awlays draw whole table
  if (mode === "paint" && text?.[startIndex] !== T_END) {
    printHead.y = startY - topY;
    i = startIndex + 1;
  }

  while (i < text?.length) {
    let { x, y } = printHead;
    const char = text?.[i];
    if (text?.[i + 1] === T_START) {
      isInTable = true;
    }
    if (text?.[i - 1] === T_END) {
      isInTable = false;
    }

    // caret
    // if (mode === "paint") {
    //   drawCaret(ctx, x, y, selectionStyles?.[i], textStyles?.[i]);
    // }

    printHead = getNextPosition(
      { printHead, i, text, textStyles, ctx },
      content?.isShowingTicks
    );
    ({ x, y } = printHead);

    if (mode === "paint" && y > MAX_PAINT_Y) {
      break;
    }

    const charWidth = ctx.measureText(char).width;

    // image
    if (textStyles?.[i]?.type === "image") {
      drawImage(
        ctx,
        x - textStyles?.[i]?.w,
        y - textStyles?.[i]?.h,
        textStyles?.[i]
      );
    }

    // selection
    if (selectionStyles?.[i]?.isSelection === true) {
      selectionBgColor = "#0000ff22";
    }
    if (selectionStyles?.[i]?.isSelection === false) {
      selectionBgColor = "transparent";
    }

    bgColor = textStyles?.[i]?.bgColor || "transparent";
    fontWeight = textStyles?.[i]?.isBold ? "bold" : "normal";
    fontStyle = textStyles?.[i]?.isItalic ? "italic" : "normal";
    textColor = textStyles?.[i]?.textColor || "#000000";

    ctx.font = `${fontStyle} ${fontWeight} ${FONT_SIZE}px Arial`;

    if (mode === "no-paint") {
      let charWidth = ctx.measureText(char).width;
      if (char === "\n") {
        charWidth = 0;
      }

      charCoords.push([x - charWidth, y, char]);
      if (last(lines)?.y !== y && !isInTable) {
        lines.push({ y, i });
      }
      i++;
      continue;
    }

    if (textStyles?.[i]?.isUnderlined) {
      const prevLineWidth = ctx.lineWidth;
      ctx.lineWidth = 2;
      ctx.strokeRect(x - charWidth, y + 4, charWidth, 1);
      ctx.lineWidth = prevLineWidth;
    }

    ctx.fillStyle = bgColor;
    ctx.fillRect(x - charWidth, y - LINE_HEIGHT, charWidth, LINE_HEIGHT);

    ctx.fillStyle = selectionBgColor;
    ctx.fillRect(x - charWidth, y - LINE_HEIGHT, charWidth, LINE_HEIGHT);

    ctx.fillStyle = textColor;
    ctx.fillText(char, x - charWidth, y);

    if (textStyles?.[i]?.queryId) {
      const prevFillStyle = ctx.fillStyle;
      ctx.fillStyle = getColorFromString(textStyles?.[i]?.prompt);
      ctx.fillRect(0, y - LINE_HEIGHT, 10, LINE_HEIGHT);
      ctx.fillStyle = prevFillStyle;
    }

    if (
      textStyles?.[i]?.metas?.[0] &&
      content?.isShowingTicks &&
      !textStyles?.[i]?.fullLengthStyle
    ) {
      const prevFillStyle = ctx.fillStyle;
      ctx.fillStyle = "#00ff0044";
      ctx.fillRect(x - charWidth, y - LINE_HEIGHT, charWidth, LINE_HEIGHT);
      ctx.fillStyle = prevFillStyle;
    }

    if (textStyles?.[i]?.isEndOfMeta && content?.isShowingTicks) {
      const prevFillStyle = ctx.fillStyle;
      ctx.fillStyle = "green";
      ctx.fillText("✓", x, y);
      ctx.fillStyle = prevFillStyle;
    }

    i++;
  }

  // console.log({
  //   charCoords,
  //   startIndex,
  //   i,
  //   textLength: text?.length,
  //   numPages: Math.round(text?.length / 3000),
  // });

  return { charCoords, lines };
};

const doesWordCrossEnd = ({ printHead, i, text, ctx }) => {
  const char = text?.[i];
  if (char === " ") {
    return false;
  }

  let widthUntilWordEnd = ctx?.measureText(char)?.width;
  let j = i + 1;
  let nextChar = text?.[j];
  while (nextChar !== " " && nextChar !== "\n" && j < text?.length) {
    widthUntilWordEnd += ctx?.measureText(nextChar)?.width;
    j++;
    nextChar = text?.[j];
  }

  return printHead?.x + widthUntilWordEnd > printHead?.endX;
};

const TICK_CHAR = "✓";

const getNextPosition = (
  { printHead, i, text, textStyles, ctx },
  isShowingTicks
) => {
  const char = text?.[i];
  const charTextStyle = textStyles?.[i];

  const prevChar = text?.[i - 1];
  const prevCharTextStyle = textStyles?.[i - 1];

  const charWidth = ctx.measureText(char).width;

  let { x, y, startX, startY, endX, maxRowY, colIndex } = printHead;
  maxRowY = max([maxRowY, y]);

  // table
  if (char === T_START) {
    maxRowY = y + LINE_HEIGHT;
  }

  if (char === R_START) {
    colIndex = 0;
    startY = maxRowY;
  }

  if (prevChar === C_START) {
    const numCols = prevCharTextStyle?.numberOfColumns || 2;
    const cellWidth = (END_X - START_X) / numCols;
    maxRowY = max([maxRowY, y]);
    y = startY + LINE_HEIGHT;
    startX = colIndex * cellWidth + START_X;
    x = startX;
    colIndex++;
    endX = START_X + colIndex * cellWidth;
  }

  if (char === T_END) {
    startX = START_X;
    endX = END_X;
    x = startX;
    y = maxRowY + LINE_HEIGHT;
    maxRowY = 0;
  }

  // normal char
  if (text?.[i - 1] === "\n" || doesWordCrossEnd({ printHead, i, text, ctx })) {
    x = startX;
    y += LINE_HEIGHT;
  }
  if (textStyles?.[i - 1]?.isEndOfMeta && isShowingTicks) {
    x += ctx?.measureText(TICK_CHAR)?.width;
  }

  // image
  if (charTextStyle?.type === "image") {
    x += charTextStyle?.w;
    y += charTextStyle?.h - LINE_HEIGHT;
    return {
      x,
      y,
      startX,
      startY,
      endX,
      maxRowY,
      colIndex,
    };
  }

  if ([T_START, R_START, C_START, T_END, "\n"].includes(char)) {
    return {
      x,
      y,
      startX,
      startY,
      endX,
      maxRowY,
      colIndex,
    };
  }

  x += charWidth;

  return {
    x,
    y,
    startX,
    startY,
    endX,
    maxRowY,
    colIndex,
  };
};

export const insertAtSelection = (str, content) => {
  let newContent = cloneDeep(content);

  let caretIndex = newContent.selectionStyles?.findIndex(
    style => style?.isCaret === true
  );
  const startIndex = newContent.selectionStyles?.findIndex(
    style => style?.isSelection === true
  );
  const endIndex = newContent.selectionStyles?.findIndex(
    (style, index) => style?.isSelection === false
  );
  if (startIndex !== -1 && endIndex !== -1) {
    newContent = deleteAtSelection(newContent);
    caretIndex = newContent.selectionStyles?.findIndex(
      style => style?.isCaret === true
    );
  }

  if (caretIndex === -1) {
    return newContent;
  }

  newContent.text = `${newContent.text.slice(
    0,
    caretIndex
  )}${str}${newContent.text.slice(caretIndex)}`;

  newContent.selectionStyles = new Array(newContent.text?.length)?.fill(null);
  newContent.selectionStyles[caretIndex + str?.length] = {
    isCaret: true,
  };
  const caretTextStyle = newContent.textStyles?.[caretIndex] || null;
  newContent.textStyles = [
    ...newContent.textStyles?.slice(0, caretIndex + 1),
    ...range(str?.length).map(() => caretTextStyle),
    ...newContent.textStyles?.slice(caretIndex + 1),
  ];

  return newContent;
};

export const moveCaret = (content, numChars = 0) => {
  let newContent = cloneDeep(content);
  let caretIndex = newContent.selectionStyles?.findIndex(
    style => style?.isCaret === true
  );
  if (caretIndex === -1) {
    return newContent;
  }

  let newCaretIndex = caretIndex + numChars;
  let char = newContent.text?.[newCaretIndex];

  let isOnSpecialChar = TABLE_CHARS.includes(char);
  while (isOnSpecialChar) {
    newCaretIndex += numChars > 0 ? 1 : -1;
    char = newContent.text?.[newCaretIndex];
    isOnSpecialChar = TABLE_CHARS.includes(char);
  }

  newCaretIndex = clamp(newCaretIndex, 1, newContent.text?.length - 1);
  newContent.selectionStyles = new Array(newContent.text?.length)?.fill(null);
  newContent.selectionStyles[newCaretIndex] = {
    isCaret: true,
  };

  return newContent;
};

export const moveCaretLine = (content, contentCoords, numLines = 0, topY) => {
  let newContent = cloneDeep(content);
  let caretIndex = newContent.selectionStyles?.findIndex(
    style => style?.isCaret === true
  );
  if (caretIndex === -1) {
    return newContent;
  }

  const [x, y] = contentCoords?.charCoords?.[caretIndex] || [0, 0];
  let newY = y - topY + (numLines * LINE_HEIGHT * 2) / SF_Y;
  if (numLines < 0) {
    newY = y - topY + (numLines * LINE_HEIGHT * 4) / SF_Y;
  }
  let newCaretIndex = getIndexFromCoords({
    content: newContent,
    contentCoords,
    x,
    y: newY,
    topY,
  });

  newContent.selectionStyles = new Array(newContent.text?.length)?.fill(null);
  newContent.selectionStyles[newCaretIndex] = {
    isCaret: true,
  };

  return newContent;
};

export const deleteAtSelection = content => {
  const newContent = cloneDeep(content);
  const startIndex = newContent.selectionStyles?.findIndex(
    style => style?.isSelection === true
  );
  const endIndex = newContent.selectionStyles?.findIndex(
    (style, index) => style?.isSelection === false
  );
  const caretIndex = newContent.selectionStyles?.findIndex(
    style => style?.isCaret === true
  );
  if (caretIndex === 1) {
    return newContent;
  }

  // single char
  if (caretIndex !== -1) {
    const charToDelete = newContent.text?.[caretIndex - 1];
    if ([T_START, R_START, C_START, T_END].includes(charToDelete)) {
      return newContent;
    }

    newContent.selectionStyles = new Array(newContent.text?.length)?.fill(null);
    newContent.selectionStyles[caretIndex - 1] = {
      isCaret: true,
    };
    newContent.textStyles = [
      ...newContent.textStyles?.slice(0, caretIndex - 1),
      ...newContent.textStyles?.slice(caretIndex),
    ];
    newContent.text = `${newContent.text.slice(
      0,
      caretIndex - 1
    )}${newContent.text.slice(caretIndex)}`;

    return newContent;
  }

  if (startIndex === -1 || endIndex === -1) {
    return newContent;
  }

  // selection span
  let toDelete = newContent.text?.slice(startIndex + 1, endIndex);
  let toDeleteStyles = newContent.textStyles?.slice(startIndex + 1, endIndex);
  let toKeep = "";
  let toKeepStyles = [];
  let i = 0;
  while (i < toDelete?.length) {
    if ([T_START, R_START, C_START, T_END].includes(toDelete[i])) {
      toKeep += toDelete[i];
      toKeepStyles.push(toDeleteStyles?.[i]);
    }
    if (toDelete?.[i] === C_START) {
      toKeep += "-";
      toKeepStyles.push(null);
    }
    i++;
  }
  if (toKeep?.includes(T_START) && toKeep?.includes(T_END)) {
    toKeep = "";
    toKeepStyles = [];
  }

  newContent.text = `${newContent.text.slice(
    0,
    startIndex
  )}${toKeep}${newContent.text.slice(endIndex)}`;

  newContent.textStyles = [
    ...newContent.textStyles?.slice(0, startIndex),
    ...toKeepStyles,
    ...newContent.textStyles?.slice(endIndex),
  ];

  newContent.selectionStyles = new Array(newContent.text?.length)?.fill(null);

  let newCaretIndex = startIndex;
  let char = newContent?.text?.[newCaretIndex];
  let isOnSpecialChar = TABLE_CHARS.includes(char);
  while (isOnSpecialChar) {
    newCaretIndex -= 1;
    char = content.text?.[caretIndex];
    isOnSpecialChar = TABLE_CHARS.includes(char);
  }

  newContent.selectionStyles[newCaretIndex] = {
    isCaret: true,
  };

  if (!newContent?.text?.endsWith("\n")) {
    newContent.text += "\n";
  }

  return newContent;
};

const expandUntilSeparator = ({ text, i, sep }) => {
  let selectionStart = i;
  let selectionEnd = i;
  while (text?.[selectionStart] !== sep && selectionStart > 0) {
    selectionStart -= 1;
  }
  while (text?.[selectionEnd] !== sep && selectionEnd < text?.length) {
    selectionEnd += 1;
  }

  return [selectionStart + 1, selectionEnd];
};

const getIndexFromCoords = ({ content, contentCoords, x, y, topY }) => {
  let offsetX = x;
  let offsetY = y;

  let i = 1;
  let charCoords = contentCoords?.charCoords?.[i];

  let dx = charCoords?.[0] - offsetX;
  let dy = charCoords?.[1] - topY - offsetY;

  let minDy2 = dy ** 2;
  let minDx2 = dx ** 2;

  let minI = i;

  while (i < content?.text?.length) {
    charCoords = contentCoords?.charCoords?.[i];
    if (charCoords?.[1] - topY > MAX_PAINT_Y) {
      break;
    }

    dy = charCoords?.[1] - topY - offsetY;
    dx = charCoords?.[0] - offsetX;
    let newDy2 = dy ** 2;
    let newDx2 = dx ** 2;

    if (
      newDy2 <= minDy2 &&
      newDx2 <= minDx2 &&
      charCoords?.[1] - topY > offsetY
    ) {
      minDy2 = newDy2;
      minDx2 = newDx2;
      minI = i + 1;
    }

    i++;
  }

  // limit and handle special chars
  let caretIndex = clamp(minI - 1, 1, content?.text?.length - 1);
  let char = content?.text?.[caretIndex];
  let isOnSpecialChar = TABLE_CHARS.includes(char);
  while (isOnSpecialChar) {
    caretIndex += 1;
    char = content.text?.[caretIndex];
    isOnSpecialChar = TABLE_CHARS.includes(char);
  }

  return caretIndex;
};

export const getSelectionStylesFromEvent = (
  e,
  content,
  topY,
  contentCoords
) => {
  let selectionStyles = content?.selectionStyles || [];
  let minI = getIndexFromCoords({
    content,
    contentCoords,
    x: e?.nativeEvent?.offsetX * SF,
    y: e?.nativeEvent?.offsetY * SF_Y,
    topY,
  });

  if (e?.type === "mousedown") {
    selectionStyles = [];
    selectionStyles[minI] = { isCaret: true };
  }

  // double click
  if (e?.type === "mousedown" && e?.detail === 2) {
    let [selectionStart, selectionEnd] = expandUntilSeparator({
      text: content?.text,
      i: minI,
      sep: " ",
    });
    selectionStyles = new Array(content?.text?.length)?.fill(null);
    selectionStyles[selectionStart] = { isSelection: true };
    selectionStyles[selectionEnd] = { isSelection: false };
  }

  // triple click
  if (e?.type === "mousedown" && e?.detail === 3) {
    let [selectionStart, selectionEnd] = expandUntilSeparator({
      text: content?.text,
      i: minI,
      sep: "\n",
    });
    selectionStyles = new Array(content?.text?.length)?.fill(null);
    selectionStyles[selectionStart] = { isSelection: true };
    selectionStyles[selectionEnd] = { isSelection: false };
  }

  if (e?.type === "mousemove") {
    minI = clamp(minI, 1, content?.text?.length - 1);
    const caretIndex = selectionStyles?.findIndex(
      style => style?.isCaret === true
    );
    // start region selection at caret
    if (caretIndex > -1) {
      selectionStyles[caretIndex] = {
        isSelection: true,
        dragStart: true,
      };
    }

    selectionStyles = selectionStyles?.map(style =>
      style?.dragStart ? style : null
    );

    if (selectionStyles[minI]?.dragStart) {
      minI += 1;
    }
    selectionStyles[minI] = {
      isSelection: false,
    };

    // assign first selection style as start, and second as end
    let isSelection = true;
    selectionStyles = selectionStyles.map(style => {
      if (!isNil(style)) {
        let newStyle = { ...style, isSelection };
        isSelection = false;
        return newStyle;
      }
      return null;
    });
  }

  return selectionStyles;
};

export const getSelectionStyle = (content, fieldName = "") => {
  const selectionStart = content?.selectionStyles?.findIndex(
    style => style?.isSelection === true
  );
  const selectionEnd = content?.selectionStyles?.findIndex(
    style => style?.isSelection === false
  );

  if (fieldName === "bgColor" || fieldName === "textColor") {
    return content?.textStyles?.[selectionStart]?.[fieldName];
  }

  return content?.textStyles
    ?.slice(selectionStart, selectionEnd)
    ?.some(style => style?.[fieldName]);
};
