import { useState, useEffect } from "react";
import styled from "styled-components";

import { getKnowledgeConfigs } from "api/services/chatService";
import KnowledgeConfigEditorModal from "components/KnowledgeConfigEditorModal";
import { Gap } from "components/Layout";
import { CrossIcon, FilesIcon, PlusIcon } from "components/ui/Icons";
import SearchableSelectInput from "components/ui/SearchableSelectInput";
import Tooltip from "components/ui/Tooltip";
import KnowledgeConfigEditor from "components/KnowledgeConfigEditor";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

const Container = styled.div`
  padding: 8px;
  height: 100vh;
  overflow: auto;
`;

const ConfigCard = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 8px;
  padding: 4px;
  background-color: ${props => props.theme.color.closer0};
  box-shadow: ${props => props.theme.shadow};
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 12px;
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"};
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  margin-top: 4px;
  padding: 4px;
  height: 24px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const KnowledgeConfigs = ({ configIds = [], onNewIds = newIds => {} }) => {
  const [searchValue, setSearchValue] = useState("");
  const [allConfigs, setAllConfigs] = useState([]);
  const [expandedConfigIds, setExpandedConfigIds] = useState([]);

  useEffect(() => {
    doPopulateConfigs();
  }, []);

  const doPopulateConfigs = async () => {
    const { data } = await getKnowledgeConfigs();
    setAllConfigs(data || []);
  };

  return (
    <Container>
      <Gap height="20px" />
      <Title>Your Domain Knowledge</Title>
      <Gap height="28px" />
      <div
        style={{
          whiteSpace: "pre-wrap",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <SearchableSelectInput
            placeholder="Knowledge topic"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onClickOption={clickedOption => {
              onNewIds([...configIds, clickedOption?.id]);
              // if (clickedOption?.type === "DIRECTORY") {
              //   setSearchValue(`${clickedOption?.path}/`);
              //   return;
              // }
              // const newFileId = clickedOption?.value;
              // const newSource = {
              //   fileId: newFileId,
              //   fileName: allItems.find(item => item.id === newFileId)?.fileName,
              //   type: allItems.find(item => item.id === newFileId)?.type,
              // };
              // onChangeSources([...sources, newSource]);
            }}
            options={allConfigs?.map(config => ({
              id: config?._id,
              label: config?.topic,
              icon: <FilesIcon style={{ fill: "#731dff" }} />,
            }))}
          />
          <Tooltip
            placement="left"
            style={{ alignSelf: "end" }}
            title="Create knowledge config"
          >
            <KnowledgeConfigEditorModal
              triggerIcon={<StyledPlusIcon />}
              onSave={newConfig => {
                setAllConfigs([...allConfigs, newConfig]);
                onNewIds([...configIds, newConfig?._id]);
              }}
            />
          </Tooltip>
        </div>
        {configIds.map(configId => {
          const config = allConfigs.find(item => item?._id === configId);
          const isExpanded = expandedConfigIds.includes(configId);
          return (
            <ConfigCard>
              <FilesIcon style={{ fill: "#731dff" }} />
              <div>{config?.topic}</div>
              <IconContainer
                onClick={() => {
                  if (isExpanded) {
                    setExpandedConfigIds(
                      expandedConfigIds.filter(id => id !== configId)
                    );
                    return;
                  }
                  setExpandedConfigIds([...expandedConfigIds, configId]);
                }}
              >
                {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconContainer>
              <StyledCrossIcon
                onClick={() => {
                  onNewIds(configIds.filter(id => id !== configId));
                }}
                style={{ justifySelf: "end" }}
              />

              <KnowledgeConfigEditor
                style={{
                  gridColumn: "1 / -1",
                  height: isExpanded ? "auto" : 0,
                  padding: isExpanded ? "10px" : 0,
                }}
                config={config}
                onSave={newConfig => {
                  setAllConfigs(
                    allConfigs.map(item => {
                      if (item?._id === config?._id) {
                        return newConfig;
                      }
                      return item;
                    })
                  );
                }}
              />
              {/* <KnowledgeConfigEditorModal
                config={config}
                onSave={newConfig => {
                  setAllConfigs(
                    allConfigs.map(item => {
                      if (item?._id === config?._id) {
                        return newConfig;
                      }
                      return item;
                    })
                  );
                }}
              /> */}
            </ConfigCard>
          );
        })}
        {/* <StyledPlusIcon style={{ alignSelf: "start" }} onClick={() => {}} /> */}
      </div>
    </Container>
  );
};

export default KnowledgeConfigs;
