const ApiDocsPage = () => {
  return (
    <>
      <rapi-doc spec-url="openapi.yaml" theme="dark" show-header="false">
        <a
          href="/"
          slot="nav-logo"
          style={{ width: "max-content", margin: "0 auto" }}
        >
          <img
            style={{ height: "60px", padding: "8px" }}
            src="/images/logo-white.png"
          />
        </a>
      </rapi-doc>
    </>
  );
};

export default ApiDocsPage;
