import AnnotationsConfig from "components/AnnotationConfig";
import { CenteredWithTopNavLayout } from "components/Layout";

const AnnotationsConfigPage = () => {
  return (
    <CenteredWithTopNavLayout>
      <AnnotationsConfig />
    </CenteredWithTopNavLayout>
  );
};

export default AnnotationsConfigPage;
