import * as backend from "api/backend/fileSystemEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { groupBy } from "lodash";

export const getDirectory = async params => {
  const { data, error } = await backend.getDirectory(params);
  return { data, error: getHandlingFromError(error) };
};

export const getDirectoryItems = async params => {
  const reqParams = { ...params };
  if (!reqParams?.offset) {
    delete reqParams?.offset;
  }
  const { data: directory, error } = await backend.getDirectory(reqParams);
  if (error) {
    return { data: [], error };
  }
  const itemToInfo = groupBy(directory?.items, "id");
  const mergedItems = directory?.detailedItems?.items?.map(detailedItem => {
    const itemInfo = itemToInfo?.[detailedItem?.id]?.[0];
    return { ...detailedItem, ...itemInfo };
  });

  // sort by createdAt
  let sortedItems = mergedItems?.sort((a, b) => {
    return (
      new Date(b?.createdAt || b?.createdAtMs) -
      new Date(a?.createdAt || a?.createdAtMs)
    );
  });

  // add chat type
  sortedItems = sortedItems?.map(item => {
    const newItem = { ...item };
    if (
      item?.fileName?.startsWith("Chat on") ||
      item?.isChat ||
      item?.fileName?.startsWith("[chat]")
    ) {
      newItem.type = "CHAT_DOC";
    }
    return newItem;
  });

  return {
    data: {
      items: sortedItems,
      offset: directory?.detailedItems?.offset,
      hasMore: directory?.detailedItems?.hasMore,
    },
    error: getHandlingFromError(error),
  };
};

export const postDirectories = async body => {
  const { data, error } = await backend.postDirectories(body);
  return { data, error: getHandlingFromError(error) };
};

export const postMoveItem = async body => {
  const { data, error } = await backend.postMoveItem(body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteDirectory = async params => {
  const { data, error } = await backend.deleteDirectory(params);
  return { data, error: getHandlingFromError(error) };
};

export const patchFileSystemUpdateAccess = async body => {
  const { data, error } = await backend.patchFileSystemUpdateAccess({}, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteItem = async (params = {}, body = {}) => {
  const { data, error } = await backend.deleteItem(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postRenameDirectory = async (params = {}, body = {}) => {
  const { data, error } = await backend.postRenameDirectory(params, body);
  return { data, error: getHandlingFromError(error) };
};
