import { apiPatch, apiPost } from "api/api-http-methods";

export const postPageMetas = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/training-data-audit/page-metas",
    params,
    body
  );
  return { data, error };
};

export const postPages = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/training-data-audit/pages",
    params,
    body
  );
  return { data, error };
};

export const patchUpdatePage = async (params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    "/training-data-audit/update-page",
    params,
    body
  );
  return { data, error };
};
