import { useState } from "react";
import styled from "styled-components";

import { CrossIcon, GearIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import ButtonWord from "components/ui/ButtonWord";
import { useEffect } from "react";
import { Gap } from "components/Layout";

const ModalTrigger = styled.div`
  cursor: pointer;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  width: 600px;
  height: 400px;

  display: grid;
  grid-template-rows: 1fr auto;
`;

const BottomBar = styled.div`
  display: grid;
  justify-content: end;
  padding-top: 10px;
  border-top: 1px solid ${props => props.theme.color.closer1};
`;

const Container = styled.div`
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 600;
`;

const StyledCrossIcon = styled(CrossIcon)`
  cursor: pointer;
`;

const Item = styled.div`
  display: flex;
  align-content: center;
  gap: 10px;
  font-weight: 500;
  :hover {
    background-color: #f3f5f7;
  }
  border-radius: 12px;
  padding: 4px 8px;
  width: max-content;
  margin-bottom: 4px;
  svg {
    height: 12px;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const ColumnsListModal = ({
  value = [],
  options = [],
  onPressSave = newValue => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedValue, setEditedValue] = useState(value || []);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setEditedValue(value || []);
  }, [JSON.stringify(value)]);

  const onClickAdd = () => {
    if (selectedValue) {
      setEditedValue([...editedValue, selectedValue]);
      setSelectedValue("");
    }
  };

  return (
    <>
      <ModalTrigger onClick={() => setIsOpen(true)}>
        <GearIcon />
      </ModalTrigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Container>
            <Title>Choose columns</Title>
            <Gap />
            <div style={{ display: "flex", gap: 10 }}>
              <StyledSelect
                value={selectedValue}
                onChange={e => setSelectedValue(e.target.value)}
              >
                <option value="">Select a column</option>
                {options.map((option, i) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </StyledSelect>
              <ButtonWord disabled={!selectedValue} onClick={onClickAdd}>
                Add
              </ButtonWord>
            </div>
            <Gap />
            <div style={{ overflow: "auto" }}>
              {editedValue.map((item, i) => (
                <Item key={i}>
                  <div>{item}</div>
                  <StyledCrossIcon
                    onClick={() =>
                      setEditedValue(editedValue.filter(v => v !== item))
                    }
                  />
                </Item>
              ))}
            </div>
          </Container>
          <BottomBar>
            <ButtonWord
              onClick={() => {
                onPressSave(editedValue);
                setIsOpen(false);
              }}
            >
              Save
            </ButtonWord>
          </BottomBar>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ColumnsListModal;
