import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import WordDoc from "components/WordDoc";
import { addStyleToBlocks, removeSelectionStyle } from "utils/word-utils";
import { postChatflowGenerate } from "api/services/searchService";
import {
  ChatIcon,
  DownloadIcon,
  FilesIcon,
  WordIcon,
} from "components/ui/Icons";
import { sleep } from "utils/common";
import NavHeader from "components/NavHeader";
import { CircularProgress } from "@material-ui/core";
import ModelSelectDropdown from "components/ModelSelectDropdown";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import {
  ChevronLeft,
  ChevronRight,
  FileCopy,
  FileCopySharp,
  GroupAdd,
} from "@material-ui/icons";
import InputExpanding from "components/InputExpanding";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import TooltipNew from "components/ui/TooltipNew";
import { transform } from "lodash";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import useSearchParamsState from "hooks/useSearchParamsState";
import ChatView from "components/ChatView";

const OuterContainer = styled.div`
  display: grid;
  height: 100vh;
  overflow: hidden;
  user-select: none;
`;

const Container = styled.div`
  display: grid;
  align-items: start;
  background-color: #ffffff;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const EditableDocNameInput = styled(InputExpanding)`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;

  display: block;
  align-self: center;
  border: none;
  outline: none;
  background-color: #ffffff;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  align-items: center;
  background-color: #ffffff;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 50px;
  border-bottom: 1px solid #e0e0e0;
`;

const InputAndMenuBar = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledNavHeader = styled(NavHeader)`
  /* padding: 0;
  height: 100%;
  position: relative;
  background-color: #ffffff;
  width: max-content;
  box-shadow: none; */
  box-shadow: none;
  background-color: transparent;
  position: relative;
`;

const ModalTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000088;

  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const ModalTriggerWide = styled.div`
  cursor: pointer;
  border-radius: 0px;
  padding: 0 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000088;

  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  /* width: 30px; */
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const WordDocPageNew = () => {
  const { wordDocId } = useParams();
  const navigate = useNavigate();

  const [wordDoc, setWordDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingApproval, setIsSavingApproval] = useState(false);
  const [shouldCancelGeneration, setShouldCancelGeneration] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const [blocks, setBlocks] = useState([{ text: "" }]);
  const [blockIdsToRun, setBlockIdsToRun] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useSearchParamsState({
    paramName: "isSidebarOpen",
    initialValue: false,
  });

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  useEffect(() => {
    if (isLoading || isGenerating) {
      return;
    }
    doSaveDoc();
  }, [JSON.stringify(blocks), isGenerating]);

  const doPopulateBlocksFromChatflow = async (cursor, blocksToSend) => {
    setIsLoading(true);
    const sourceFileIds = wordDoc?.content?.sources?.map(s => s?.fileId);
    const { data, error } = await postChatflowGenerate(
      { max_new_tokens: 20, fileIds: sourceFileIds },
      {
        cursor,
        genContext: "word_tab",
        blocks: blocksToSend,
        sources: wordDoc?.content?.sources || [],
      }
    );

    if (!error && data?.code !== "FAILED") {
      const blocksWithoutSelection = removeSelectionStyle(data?.blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: data?.cursor?.blockIndex || 0,
        startLetterIndex: data?.cursor?.letterIndex || 0,
        endBlockIndex: data?.cursor?.blockIndex || 0,
        endLetterIndex: data?.cursor?.letterIndex || 0,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      setBlocks(blocksWithSelection);

      if (data?.sources?.length > 0) {
        setWordDoc({
          ...wordDoc,
          content: {
            ...wordDoc?.content,
            sources: data?.sources,
          },
        });
      }
    }

    setIsLoading(false);
  };

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doSaveDoc = async () => {
    setIsLoading(true);
    await sleep(2000);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...wordDoc, content: { ...wordDoc?.content, blocks } }
    );
    // setWordDoc(data);
    // setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doChangeApprovalBlocksAndSaveDoc = async newBlocks => {
    setIsSavingApproval(true);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...wordDoc, content: { ...wordDoc?.content, blocks: newBlocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsSavingApproval(false);
  };

  const doPatchDocSources = async newSources => {
    setIsLoading(true);

    const { data: newWordDoc } = await patchWordDoc(
      wordDocId,
      {},
      { content: { ...wordDoc?.content, sources: newSources } }
    );
    setWordDoc(newWordDoc);
    setIsLoading(false);
  };

  return (
    <OuterContainer
      style={{
        gridTemplateColumns: isSidebarOpen ? "auto 300px" : "auto 0px",
      }}
    >
      <Container>
        <DocNameAndSourcesModal>
          <Link to="/">
            <WordIcon
              height="32px"
              style={{
                paddingLeft: "14px",
                paddingRight: "10px",
                fill: "#2b579a",
              }}
            />
          </Link>
          <InputAndMenuBar>
            <EditableDocNameInput
              maxWidth={600}
              value={wordDoc?.fileName}
              onChange={e => {
                setWordDoc({
                  ...wordDoc,
                  fileName: e.target.value,
                });
              }}
              onBlur={doSaveDoc}
            />
            <CircularProgress
              size={14}
              thickness={10}
              style={{
                opacity: isLoading ? 1 : 0,
                color: "#0191ff",
              }}
            />
          </InputAndMenuBar>

          <ModalTrigger
            style={{ marginRight: "10px" }}
            onClick={() =>
              triggerDownloadOfFile(wordDocId, { fileType: "WORD_DOC" })
            }
          >
            <DownloadIcon style={{ height: "14px", width: "14px" }} />
          </ModalTrigger>
          <ShareDirectoryItemModalTrigger
            trigger={
              <ModalTrigger style={{ marginRight: "10px" }}>
                <GroupAdd />
              </ModalTrigger>
            }
            directoryItem={{
              id: wordDocId,
              fileName: wordDoc?.fileName,
              type: "WORD_DOC",
              sharedWith: wordDoc?.sharedWith || [],
            }}
          />

          {/* <button onClick={() => setArePromptsVisible(!arePromptsVisible)}>
            {arePromptsVisible ? "Hide Prompts" : "Show Prompts"}
          </button> */}
          <ModelSelectDropdown />
          <DocSourcesModalTrigger
            trigger={
              <ModalTriggerWide style={{ margin: "0 10px" }}>
                <TooltipNew
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  tipStyle={{ transform: "translate(-3.5px, 6px)" }}
                  tipText="Add PDF, Word, Excel"
                >
                  <span style={{ fontWeight: 600 }}>
                    {wordDoc?.content?.sources?.length || 0} source
                    {wordDoc?.content?.sources?.length === 1 ? "" : "s"}
                  </span>
                  <FileCopy />
                </TooltipNew>
              </ModalTriggerWide>
            }
            sources={wordDoc?.content?.sources}
            onChangeSources={newSources => doPatchDocSources(newSources)}
          />
          <StyledNavHeader />
        </DocNameAndSourcesModal>

        <WordDoc
          isSidebarOpen={isSidebarOpen}
          maxNewTokens={20}
          isGenerating={isGenerating}
          onNewIsGenerating={newFlag => setIsGenerating(newFlag)}
          shouldCancelGeneration={shouldCancelGeneration}
          onNewShouldCancelGeneration={newFlag =>
            setShouldCancelGeneration(newFlag)
          }
          sourceFileIds={
            wordDoc?.content?.sources?.length > 0
              ? wordDoc?.content?.sources?.map(s => s?.fileId)
              : []
          }
          blocks={blocks}
          onChangeSources={newSources => {
            setWordDoc({
              ...wordDoc,
              content: {
                ...wordDoc?.content,
                sources: newSources,
              },
            });
          }}
          onChangeBlocks={newBlocks => setBlocks(newBlocks)}
          onPressTab={extCursor =>
            doPopulateBlocksFromChatflow(extCursor, blocks)
          }
          onPressApproveOrReject={newBlocks =>
            doChangeApprovalBlocksAndSaveDoc(newBlocks)
          }
          isSavingApproval={isSavingApproval}
          sources={wordDoc?.content?.sources || []}
          blockIdsToRun={blockIdsToRun}
          onNewBlockIdsToRun={newBlockIdsToRun =>
            setBlockIdsToRun(newBlockIdsToRun)
          }
        />

        <div
          style={{
            position: "fixed",
            top: 60,
            right: isSidebarOpen ? 310 : 10,
          }}
        >
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            {isSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
            <ChatIcon />
          </IconButton>
        </div>
      </Container>

      <div
        style={{
          zIndex: 2,
          backgroundColor: "white",

          width: "300px",
          borderLeft: "1px solid lightgrey",
        }}
      >
        <ChatView
          workingDocs={[{ id: wordDocId }]}
          onChangeBlocks={newBlocks => setBlocks(newBlocks)}
        />
      </div>
    </OuterContainer>
  );
};

export default WordDocPageNew;
