import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import { getDirectoryItems } from "api/services/fileSystem";
import {
  CrossIcon,
  ExcelIcon,
  FolderIcon,
  InternetIcon,
  PdfIcon,
  SigmaIcon,
  TickIcon,
  WordIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import SearchableSelectInput from "components/ui/SearchableSelectInput";
import { last, sum } from "lodash";
import ProgressBar from "components/ui/ProgressBar";
import { Gap } from "components/Layout";
import FileInputWithProgress from "components/FileInputWithProgress";
import TooltipNew from "components/ui/TooltipNew";
import { Audiotrack } from "@material-ui/icons";
import { formatItemName } from "components/DirectoryItemsList";
import ButtonDark from "components/ui/ButtonDark";
import ButtonIcon from "components/ui/ButtonIcon";
import FileFromUrlInput from "components/FileFromUrlInput";
import { decodeStoredJwt } from "api/services/authenticationService";

const formatLongNumberUpToK = (num, digits = 3) => {
  try {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  } catch {
    return num;
  }
};

const MAX_TOKENS = 5 * 10 ** 6;

const TriggerText = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 0 4px;
`;

const HighlightSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
`;

const ModalTrigger = styled.div`
  display: grid;
  /* grid-template-columns: auto auto; */
  column-gap: 16px;
  row-gap: 0px;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;  
  `}/* :hover {
    background-color: ${props => props.theme.color.closer0};
  } */
`;

const SourcesTextAndIcons = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  justify-content: end;
  gap: 5px;
  border-radius: 0px;
  padding: 1px 8px;
  font-weight: 600;
  background-color: ${props => props.theme.color.closer0};
  border: 2px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const ModalContent = styled.div`
  border-radius: 4px;
  padding: 20px 30px;
  padding-bottom: 20px;
  min-height: 300px;
  min-width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
  background-color: #dddddd;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const SourceContainer = styled.div`
  padding: 12px;
  border-radius: 4px;
  background-color: ${props => props.theme.color.closer0};
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  /* border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  ); */
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  :hover {
    background-color: ${props => props.theme.color.furthest};
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  :hover {
    opacity: 0.5;
  }
`;

const getItemIconFromItem = (item = {}, isItemInSources = false) => {
  const { fileName, type, path } = item;
  if (item?.isAudioFile) {
    return (
      <Audiotrack
        height="12px"
        style={{ height: "12px", width: "12px", opacity: 0.8 }}
      />
    );
  }
  if (path?.startsWith("/template-files/")) {
    return <SigmaIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (isItemInSources) {
    return <TickIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".pdf")) {
    return <PdfIcon height="12px" style={{ opacity: 0.8, fill: "#ed1c24" }} />;
  }
  if (type === "WEB_PAGE") {
    return <InternetIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (type === "SIGMA_TEMPLATE") {
    return <SigmaIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".docx")) {
    return <WordIcon height="12px" style={{ opacity: 0.8, fill: "#175bbc" }} />;
  }
  if (fileName?.endsWith(".xlsx")) {
    return (
      <ExcelIcon height="12px" style={{ opacity: 0.8, fill: "#127e45" }} />
    );
  }
  if (type === "DIRECTORY") {
    return <FolderIcon height="10px" />;
  }
  return null;
};

const getUrlFromSourceItem = sourceItem => {
  if (sourceItem?.type === "SIGMA_TEMPLATE") {
    return `/trial-dashboards/${sourceItem?.pipelineId}/source`;
  }

  return `/files/${sourceItem?.fileId}`;
};

const DocSourcesModalTrigger = ({
  sources = [],
  onChangeSources = () => {},
  trigger = null,
  onClose = () => {},
  onOpen = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  let directoryPath = searchValue?.split("/")?.slice(0, -1)?.join("/");
  directoryPath = "/source-files" + directoryPath;

  const tokenObj = decodeStoredJwt();
  const isDisabled = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");

  useEffect(() => {
    if (isOpen) {
      onOpen();
    }

    if (!isOpen) {
      onClose();
      setIsContinueDisabled(true);
    }
  }, [isOpen]);

  useEffect(() => {
    doPopulateFileOptions();
  }, [directoryPath]);

  const doPopulateFileOptions = async () => {
    setAllItems([]);
    const { data } = await getDirectoryItems({
      directoryPath,
    });

    let newItems = data?.items;

    if (directoryPath === "/source-files") {
      const { data: pipelineItems } = await getDirectoryItems({
        directoryPath: "/template-files",
      });
      newItems = [...data?.items, ...pipelineItems?.items];
    }

    setAllItems(newItems);
    return { data: newItems };
  };

  const selectOptions = allItems?.map(item => {
    const isItemInSources = sources.find(s => s.fileId === item.id);

    return {
      value: item?.id,
      label:
        formatItemName(item, item?.fileName) || last(item?.path?.split("/")),
      icon: getItemIconFromItem(item, isItemInSources),
      type: item?.type,
      path: item?.path?.replace("/source-files", ""),
      isAudioFile: item?.isAudioFile,
      isDisabled:
        isItemInSources ||
        (item?.type !== "DIRECTORY" && item?.status !== "DONE"),
      isFailed: item?.status === "FAILED",
    };
  });

  const numbersOfPages = sources?.map(source => {
    const sourceItem = allItems?.find(item => item?.id === source?.fileId);
    return sourceItem?.numberOfPages;
  });
  const totalNumberOfPages = sum(numbersOfPages);
  const totalNumberOfTokens = totalNumberOfPages * 999;

  return (
    <>
      <ModalTrigger
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => setIsOpen(true)}
        isDisabled={isDisabled}
      >
        {trigger || (
          <>
            {/* <TriggerText
              style={{
                gridColumn: 1,
              }}
            >
              <HighlightSpan>
                {formatLongNumberUpToK(totalNumberOfTokens)}
              </HighlightSpan>
              &nbsp;tokens
            </TriggerText> */}
            {/* <div
              style={{
                gridColumn: 1,
                justifySelf: "end",
                fontSize: "12px",
                paddingBottom: "4px",
              }}
            >
              out of {formatLongNumberUpToK(MAX_TOKENS)}
            </div> */}
            <SourcesTextAndIcons style={{ gridRow: "1 / 3", gridColumn: 2 }}>
              {/* Sources <WordIcon /> */}
              {/* <ExcelIcon /> */}
              <PdfIcon />
              {/* <InternetIcon /> */}
            </SourcesTextAndIcons>

            {/* <ProgressBar
              style={{
                gridColumn: "span 2",
              }}
              maxValue={MAX_TOKENS}
              currentValue={totalNumberOfTokens}
            /> */}
          </>
        )}
      </ModalTrigger>

      <Modal
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalContent>
          {/* <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "grid",
                gap: "2px",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  gridColumn: 1,
                }}
              >
                <HighlightSpan>
                  {formatLongNumberUpToK(totalNumberOfTokens)}
                </HighlightSpan>
                &nbsp;tokens
              </div>
              <div
                style={{
                  gridColumn: 1,
                  justifySelf: "start",
                  fontSize: "12px",
                  paddingBottom: "4px",
                }}
              >
                out of {formatLongNumberUpToK(MAX_TOKENS)}
              </div>
              <ProgressBar
                style={{
                  gridColumn: "span 2",
                }}
                maxValue={MAX_TOKENS}
                currentValue={totalNumberOfTokens}
              />
            </div>

            <div
              style={{
                fontSize: "18px",
              }}
            >
              <HighlightSpan>{sources?.length}</HighlightSpan> documents
            </div>

            <div
              style={{
                fontSize: "18px",
              }}
            >
              <HighlightSpan>{totalNumberOfPages}</HighlightSpan> pages
            </div>
          </div>
          <Gap height="0px" /> */}

          <Title>Attach file</Title>
          <Gap height="10px" />

          <div>Upload PDF, Word, Excel</div>
          <FileInputWithProgress
            onFileUploaded={async newFile => {
              const { data: items } = await doPopulateFileOptions();
              const newSource = {
                emailId: items.find(item => item.id === newFile?.id)?.emailId,
                fileId: newFile?.id,
                fileName: items.find(item => item.id === newFile?.id)?.fileName,
                type: items.find(item => item.id === newFile?.id)?.type,
              };
              setIsContinueDisabled(false);
              onChangeSources([...sources, newSource]);
            }}
          />
          <div>Webpage</div>
          <FileFromUrlInput
            onFileUploaded={async newFile => {
              const { data: items } = await doPopulateFileOptions();
              const newSource = {
                emailId: items.find(item => item.id === newFile?.id)?.emailId,
                fileId: newFile?.id,
                fileName: items.find(item => item.id === newFile?.id)?.fileName,
                type: items.find(item => item.id === newFile?.id)?.type,
              };
              setIsContinueDisabled(false);
              onChangeSources([...sources, newSource]);
            }}
          />
          <Gap height="4px" />
          <div>Select existing</div>
          <SearchableSelectInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onClickOption={clickedOption => {
              if (!clickedOption) {
                return;
              }

              if (clickedOption?.isTemplate) {
                const newSource = {
                  fileId: clickedOption?.id,
                  fileName: clickedOption?.fileName,
                  type: "SIGMA_TEMPLATE",
                  pipelineId: clickedOption?.id,
                  pipelineName: clickedOption?.fileName,
                  isAudioFile: clickedOption?.isAudioFile,
                };

                setIsContinueDisabled(false);
                onChangeSources([...sources, newSource]);
                return;
              }

              if (clickedOption?.type === "DIRECTORY") {
                setSearchValue(`${clickedOption?.path}/`);
                return;
              }

              const newFileId = clickedOption?.value;
              const newSource = {
                fileId: newFileId,
                emailId: allItems.find(item => item.id === newFileId)?.emailId,
                fileName: allItems.find(item => item.id === newFileId)
                  ?.fileName,
                type: allItems.find(item => item.id === newFileId)?.type,
                isAudioFile: allItems.find(item => item.id === newFileId)
                  ?.isAudioFile,
                numberOfPages: allItems.find(item => item.id === newFileId)
                  ?.numberOfPages,
              };

              setIsContinueDisabled(false);
              onChangeSources([...sources, newSource]);
            }}
            options={selectOptions}
            placeholder="File or folder name"
          />
          {sources
            .filter(source => source?.fileId)
            .map(source => {
              const sourceItem = allItems?.find(
                item => item?.id === source?.fileId
              );
              const numberOfPages = sourceItem?.numberOfPages;

              return (
                <SourceContainer>
                  {getItemIconFromItem(sourceItem)}
                  <StyledA href={getUrlFromSourceItem(source)} target="_blank">
                    {formatItemName(sourceItem, source?.fileName)}{" "}
                    {numberOfPages && (
                      <span>
                        (<HighlightSpan>{numberOfPages}</HighlightSpan> pages)
                      </span>
                    )}
                  </StyledA>{" "}
                  <StyledCrossIcon
                    onClick={() => {
                      const newSources = sources.filter(
                        s => s.fileId !== source.fileId
                      );
                      setIsContinueDisabled(false);
                      onChangeSources(newSources);
                    }}
                  />
                </SourceContainer>
              );
            })}
          <ButtonIcon
            style={{
              fontWeight: "500",
              justifySelf: "end",
              padding: "4px 20px",
              marginTop: "20px",
            }}
            isActive
            isDisabled={isContinueDisabled}
            onClick={() => setIsOpen(false)}
          >
            Continue
          </ButtonIcon>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocSourcesModalTrigger;
