import styled from "styled-components";

import AnnotationsConfig from "components/AnnotationConfig";
import Modal from "components/ui/Modal";
import { getActivatedLabelSetId, getPreDefinedLabelSets } from "api/services/filesService";

const ModalContent = styled.div`
  padding: 35px;
  width: 900px;
  height: 700px;
  overflow-y: auto;
`;

const AnnotationConfigModal = ({ open, handleClose, onSetPreDefinedLabels }) => {
  const doFetchLabels = async () => {
    const { data: labelSets, error: labelsetsError } = await getPreDefinedLabelSets();
    const { data: activeLabelSetId, activeLabelSetIdError } = await getActivatedLabelSetId();
    if (!labelsetsError && !activeLabelSetIdError) {
      const filteredLabelSet = labelSets.find(e => e.id === activeLabelSetId.activatedLabelSetId);
      onSetPreDefinedLabels(filteredLabelSet.labels);
    }
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <AnnotationsConfig showShareIcon={false} onUpdate={doFetchLabels} />
      </ModalContent>
    </Modal>
  );
};

export default AnnotationConfigModal;
