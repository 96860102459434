import { ANIMATION_MS, ParamsEditor } from "components/BarChartParams";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColorFromString } from "utils/common";

const BandedChartParams = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
}) => {
  const { xColumnName, yColumnNames } = params;

  const data = records?.map(record => {
    let dataPoint = {
      [xColumnName]: record?.[xColumnName]?.value,
    };
    yColumnNames?.forEach(yColumnName => {
      dataPoint = {
        ...dataPoint,
        [`${yColumnName}_error_band`]: [
          record?.[yColumnName]?.value - 2,
          parseFloat(record?.[yColumnName]?.value) + 2,
        ],
        [yColumnName]: record?.[yColumnName]?.value,
      };
    });

    return dataPoint;
  });

  return (
    <div>
      <ParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      />
      <ResponsiveContainer width="99%" height={180}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            interval={0}
            style={{ fontSize: "9px" }}
            dataKey={xColumnName}
          />
          <YAxis />

          {yColumnNames?.map(yColumnName => (
            <>
              <Line
                key={yColumnName}
                dataKey={yColumnName}
                stroke={getColorFromString(`c${yColumnName}`)}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
              <Area
                isAnimationActive
                animationDuration={ANIMATION_MS}
                type="monotone"
                dataKey={`${yColumnName}_error_band`}
                stroke="none"
                fill="#cccccc"
                connectNulls
                dot={false}
                activeDot={false}
              />
            </>
          ))}
          <Tooltip contentStyle={{ backgroundColor: "white" }} cursor={false} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BandedChartParams;
