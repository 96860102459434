import styled from "styled-components";

import {
  CenteredWithTopNavLayout,
  CenteredWithTopNavLayoutNoSideBar,
} from "components/Layout";
import SearchInput from "components/widgets/SearchInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ExcelIcon,
  InternetIcon,
  PdfIcon,
  WordIcon,
} from "components/ui/Icons";
import { Public } from "@material-ui/icons";

const Container = styled.div`
  display: grid;
  grid-template-rows: 120px auto auto;
  align-items: center;
  justify-items: center;
  align-content: center;
  gap: 35px;

  @media (min-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -195px);
  }
`;

const IconsLinks = styled.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  padding: 4px;
  font-weight: 600;
`;

const LogoImg = styled.img`
  height: 100%;
`;

const StyledWordIcon = styled(WordIcon)`
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StyledExcelIcon = styled(ExcelIcon)`
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StyledPdfIcon = styled(PdfIcon)`
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StyledInternetIcon = styled(InternetIcon)`
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StartSearchPage = () => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <CenteredWithTopNavLayoutNoSideBar isProfileAndThemeOnly>
      <Container>
        <LogoImg
          src={
            isDarkTheme ? "/images/logo-white.png" : "/images/logo-black.png"
          }
          alt="logo"
        />
        <SearchInput
          style={{
            width: "580px",
            height: "42px",
          }}
          onPressEnter={selectedValue => {
            searchParams.set("query", selectedValue);
            navigate(`/search?${searchParams}`);
          }}
          recommendationType="search_bar"
        />
        {/* <Button
          value="Go to files"
          variant="highlighted"
          onClick={() => navigate("/source-files")}
        /> */}
        <IconsLinks>
          <StyledWordIcon
            onClick={() => navigate("/source-files")}
            height="32px"
          />
          <StyledExcelIcon
            onClick={() => navigate("/source-files")}
            height="32px"
          />
          <StyledPdfIcon
            onClick={() => navigate("/source-files")}
            height="32px"
          />
          <StyledInternetIcon
            height="32px"
            onClick={() => navigate("/source-files")}
          />
        </IconsLinks>
      </Container>
    </CenteredWithTopNavLayoutNoSideBar>
  );
};

export default StartSearchPage;
