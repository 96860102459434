import { useState } from "react";
import styled from "styled-components";

import { PencilIcon, PlusIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import SmallButton from "components/ui/SmallButton";
import { useEffect } from "react";
import { Gap } from "components/Layout";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import { Editor } from "@monaco-editor/react";
import { sleep } from "utils/common";

const CODE = `import logging
import json
import re
import numpy as np
from sklearn.linear_model import LinearRegression


LOGGER = logging.getLogger(__name__)
number_pattern = re.compile(r"([+-]?(?:[0-9]*[.])?[0-9]+)")

def prepare_data(records, x_labels, y_label=None):
    trainable_idx = []
    xs = []
    ys = []
    y_formats = {
        "type": "",
        "unit": "",
        "meta": dict(),
    }
    for i, record in enumerate(records):
        xs_i = []
        for x_label in x_labels:
            x_value = record.get(x_label, dict()).get("value")
            LOGGER.info("x_dict = %s", record.get(x_label, dict()))
            try:
                if isinstance(x_value, str):
                    x_value = number_pattern.search(x_value).group(1)
                x_value = float(x_value)
            except Exception:
                x_value = 0
            xs_i.append(x_value)
        xs.append(xs_i)

        if y_label is None:
            continue
        y_dict = record.get(y_label, dict())
        y_value = y_dict.get("value")

        if y_dict.get("type", ""):
            y_formats["type"] = y_dict.get("type", "")
        if y_dict.get("unit", ""):
            y_formats["unit"] = y_dict.get("unit", "")
        try:
            if isinstance(y_value, str):
                y_value = number_pattern.search(y_value).group(1)
            y_value = float(y_value)
            trainable_idx.append(i)
        except Exception:
            y_value = 0
        ys.append(y_value)

    if y_label is None:
        return xs

    trainable_xs = []
    trainable_ys = []
    for i in trainable_idx:
        trainable_xs.append(xs[i])
        trainable_ys.append(ys[i])
    return trainable_xs, trainable_ys, y_formats


def fit_and_predict(xs, ys, xs_pred, min_training_datapoints=3):
    if len(xs) < min_training_datapoints or len(ys) < min_training_datapoints:
        return None
    ys = np.asarray(ys)
    if np.allclose(ys, ys.astype(int)):
        y_dtype = int
    else:
        y_dtype = float

    model = LinearRegression()
    model.fit(xs, ys)
    ys_pred = model.predict(xs_pred).astype(y_dtype)
    return ys_pred.item()


query_params, current_record, existing_records = json.loads(input())
x_labels = query_params["x_labels"]
y_label = query_params["y_label"]

xs, ys, y_formats = prepare_data(existing_records, x_labels, y_label)
xs_pred = prepare_data([current_record], x_labels)
LOGGER.info("prepared data: xs = %s; ys = %s; xs_pred = %s", xs, ys, xs_pred)
y_value = fit_and_predict(xs, ys, xs_pred)`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};

  display: grid;
  grid-auto-flow: row;
  gap: 10px;

  min-width: 800px;

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const StyledTextArea = styled.textarea`
  height: 150px;
  width: 100%;
  resize: none;
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  padding: 8px;
  font-family: "Montserrat", sans-serif;
`;

const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  padding: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 50%;
`;

const BottomButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const InputAndSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TriggerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledLabel = styled.label`
  width: max-content;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

export const COLUMN_TYPES = [
  "",
  "TEXT",
  "CURRENCY",
  "PERCENTAGE",
  "NUMBER",
  "DATETIME",
];

const TAB_NAMES = ["Extraction", "Action", "Prediction"];

const DashboardColumnModalTrigger = ({
  column = {},
  onClickConfirm = newColumn => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [columnDescription, setColumnDescription] = useState("");
  const [columnType, setColumnType] = useState("TEXT");
  const [columnAction, setColumnAction] = useState("");
  const [columnPrediction, setColumnPrediction] = useState("");
  const [columnCode, setColumnCode] = useState("");
  const [isExtractionDisabled, setIsExtractionDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [selectedTab, setSelectedTab] = useState(TAB_NAMES[0]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(false);
  }, [isOpen]);

  useEffect(() => {
    setColumnName(column?.name);
    setColumnType(column?.type || "TEXT");

    setColumnDescription(column?.description);
    setColumnAction(column?.action);
    setColumnPrediction(column?.prediction);
    setSelectedTab(column?.selectedTab || TAB_NAMES[0]);

    setIsExtractionDisabled(column?.isExtractionDisabled || false);
    setColumnCode(column?.code || "");
  }, [JSON.stringify(column), isOpen]);

  const doGenerateCode = async () => {
    setIsFetching(true);
    await sleep(500);
    setColumnCode(CODE);
    setIsFetching(false);
  };

  return (
    <>
      <TriggerContainer onClick={() => setIsOpen(true)}>
        {column?.name ? <StyledPencilIcon /> : <StyledPlusIcon />}
      </TriggerContainer>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isDisabled}>
          <ModalTitle>
            {column?.name ? "Edit column" : "Add new column"}
          </ModalTitle>
          <InputAndSelect>
            <StyledInput
              value={columnName}
              onChange={e => setColumnName(e.target.value)}
              placeholder="Column name"
            />
            <div>
              Display Format&nbsp;
              <StyledSelect
                value={columnType}
                onChange={e => setColumnType(e.target.value)}
              >
                {COLUMN_TYPES?.map(type => (
                  <option>{type}</option>
                ))}
              </StyledSelect>
            </div>
          </InputAndSelect>
          <Label style={{ justifySelf: "end" }}>
            Disable ML
            <input
              type="checkbox"
              checked={isExtractionDisabled}
              onChange={e => setIsExtractionDisabled(e.target.checked)}
            />
          </Label>
          <div
            style={{
              opacity: isExtractionDisabled ? 0.5 : 1,
              pointerEvents: isExtractionDisabled ? "none" : "all",
            }}
          >
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {TAB_NAMES?.map(tabName => (
                <StyledLabel
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ margin: 0 }}
                    type="radio"
                    name="tabName"
                    checked={tabName === selectedTab}
                    onChange={() => setSelectedTab(tabName)}
                  />
                  {tabName}
                </StyledLabel>
              ))}
            </div>
            {/* <NavWithTabsWithArrows
              tabNames={TAB_NAMES}
              selectedTabName={selectedTab}
              onTabSelect={tabName => setSelectedTab(tabName)}
            /> */}
            <Gap />
            {selectedTab === TAB_NAMES[0] && (
              <StyledTextArea
                value={columnDescription}
                onChange={e => setColumnDescription(e.target.value)}
                placeholder="What to extract"
              />
            )}
            {selectedTab === TAB_NAMES[1] && (
              <StyledTextArea
                value={columnAction}
                onChange={e => setColumnAction(e.target.value)}
                placeholder="Action to take after extracion/prediction"
              />
            )}
            {selectedTab === TAB_NAMES[2] && (
              <>
                <StyledTextArea
                  style={{ height: "35px" }}
                  value={columnPrediction}
                  onChange={e => setColumnPrediction(e.target.value)}
                  placeholder="What to predict"
                />
                <Gap height="4px" />
                <SmallButton value="Generate" onClick={doGenerateCode} />
                <Gap height="4px" />
                <div
                  style={{
                    opacity: isFetching ? 0.5 : 1,
                    border: "1px solid #eaeaea",
                  }}
                >
                  <Editor
                    value={columnCode}
                    onChange={e => setColumnCode(e.target.value)}
                    height="200px"
                    defaultLanguage="python"
                  />
                </div>
              </>
            )}
          </div>
          <Gap height="40px" />
          <BottomButtons>
            <SmallButton
              value={column?.name ? "Update" : "Add to table"}
              onClick={() => {
                onClickConfirm({
                  name: columnName,
                  selectedTab,
                  description: columnDescription,
                  action: columnAction,
                  prediction: columnPrediction,
                  type: columnType,
                  code: columnCode,
                  isExtractionDisabled,
                });
                setIsOpen(false);
              }}
            />
          </BottomButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DashboardColumnModalTrigger;
