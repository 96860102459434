import { cloneDeep, range, uniq } from "lodash";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import PagePreviewTextAndTableModal from "components/widgets/PagePreviewTextAndTableModal";
import { CrossIcon, DownloadIcon, TickIcon } from "components/ui/Icons";
import NewDashboardColumnModalTrigger from "components/NewDashboardColumnModalTrigger";
import { postDownloadExcelFileOfTable } from "api/services/projectService";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import { CircularProgress } from "@material-ui/core";
import { safeFormat } from "utils/common";
import { ArrowDownward, Code, Refresh } from "@material-ui/icons";
import GeoMap from "components/GeoMap";
import { CodeBlock } from "react-code-blocks";
import { Editor } from "@monaco-editor/react";
import InputWithState from "components/InputWithState";
import CodeModalTrigger from "components/CodeModalTrigger";

const getStateNameToFields = rows => {
  const stateNameToFields = {};

  const fieldNames = rows?.[0]?.slice(1);
  rows?.slice(1)?.forEach(row => {
    const stateName = row?.[0];

    const fieldNameToValue = {};
    fieldNames.forEach(fieldName => {
      fieldNameToValue[fieldName] = parseFloat(
        row?.[rows?.[0]?.indexOf(fieldName)]
      );
    });

    stateNameToFields[stateName] = fieldNameToValue;
  });

  return stateNameToFields;
};

const deleteColumnFromSchemaConfig = (schemaConfig, blocks, columnName) => {
  const newConfig = cloneDeep(schemaConfig);
  let newBlocks = cloneDeep(blocks);

  newConfig.all_records.columnNames = newConfig.all_records.columnNames.filter(
    colName => {
      if (colName === columnName) {
        return false;
      }
      return true;
    }
  );

  delete newConfig.all_records.columnNameToDescription[columnName];

  newBlocks = newBlocks.filter(block => {
    if (!block?.isQuery) {
      return true;
    }

    if (block?.text?.includes(`extract "${columnName}"`)) {
      return false;
    }

    return true;
  });

  return [newConfig, newBlocks];
};

const Container = styled.div`
  display: grid;
  align-items: start;
  gap: 20px;
  grid-auto-columns: 1fr;
`;

const TableContainer = styled.div`
  /* cursor: move; */
`;

const StyledTable = styled.table`
  width: 100%;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 14px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;

  position: sticky;
  top: 0;

  z-index: 100;
`;

const TopContent = styled.div``;

const TableScrollContainer = styled.div`
  overflow: auto;
  max-height: 600px;
`;

const TableTitle = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  padding: 8px 0;
  font-weight: 600;
  background-color: ${props => props.theme.color.furthest};

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const StyledInputWithState = styled(InputWithState)`
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  width: 100%;
`;

const FilterInput = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 4px 8px;
  margin-bottom: 8px;

  position: sticky;
  top: 34px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 12px;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;

const StyledCodeIcon = styled(Code)`
  color: ${props =>
    props.isBlue ? props.theme.color.primary : props.theme.color.closer2};
  :hover {
    opacity: 0.6;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const getMatchedIndices = (commaSepStr, arr) => {
  if (!commaSepStr) {
    return range(0, arr?.length);
  }

  const filterStrings = commaSepStr
    .split(",")
    .map(val => val.trim())
    .filter(val => val);
  const matchedIndices = [];

  arr?.forEach((cell, index) => {
    const valueIndex = filterStrings.findIndex(filterString =>
      cell.toLowerCase()?.includes(filterString.toLowerCase())
    );

    if (valueIndex !== -1) {
      matchedIndices.push(index);
    }
  });

  return matchedIndices;
};

const columnNameToDisplayName = {
  emailId: "Email Subject",
  receivedAt: "Received Time",
};

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  trailingZeroDisplay: "stripIfInteger",
});

const VerificationDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props =>
    props?.isVerified
      ? props.theme.color.success
      : props.theme.color.in_progress};
  opacity: 0.5;

  position: absolute;
  top: 2px;
  right: 2px;
`;

const BlueTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.primary};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const GreyTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.closer2};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const TickTd = styled(Td)`
  border: none;
`;

const CrossTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 4px;
`;

const RefreshTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 0;

  cursor: pointer;

  svg {
    fill: ${props => props.theme.color.closest};
    height: 16px;
    :hover {
      opacity: 0.6;
    }
  }
`;

const Tr = styled.tr`
  :hover {
    background-color: ${props => props.theme.color.closer0}AA;
    ${CrossTd} {
      opacity: 1;
    }
    ${RefreshTd} {
      opacity: 1;
    }
  }
`;

const TableText = styled.div`
  display: inline-block;
  position: relative;
  max-width: 250px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ColumnActions = styled.div`
  display: inline-flex;
  gap: 4px;
  margin-left: 4px;
  align-items: center;
`;

const ColNameAndActions = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArrowDown = styled(ArrowDownward)`
  opacity: 0;
  pointer-events: none;

  ${props => props.isHighlighted && `color: ${props.theme.color.primary};`}
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const LoadingRowContainer = styled.div`
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const StyledEditor = styled(Editor)`
  height: 200px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const RESERVED_COLUMNS = ["emailId", "receivedAt"];

const Table = ({
  isMapView = true,
  style,
  name,
  tableId,
  rows = [],
  cellLocationToMeta = {},
  rowIndexToStatus = {},
  onChangeCell = (rowIndex, columnIndex, newValue, newAnnotationId) => {},
  blocks = [],
  schemaConfig = {},
  onChangeSchemaConfig = (newSchemaConfig = {}, newBlocks = []) => {},
  onClickCross = (rowIndex, columnIndex) => {},
  onPressVerifyCell = (rowIndex, columnIndex, checked) => {},
  onPressVerifyRow = (rowIndex, checked) => {},
  onClickRefresh = rowIndex => {},
  onSetIsDragging = isDragging => {},
}) => {
  const [filterValue, setFilterValue] = useState("");
  const [modalCellLocation, setModalCellLocation] = useState(null);
  const [sortOptions, setSortOptions] = useState({
    columnName: "emailId",
    order: "DESC",
  });

  const columnNames = rows?.[0];
  const matchedIndices = getMatchedIndices(filterValue, columnNames);

  const filteredColumnNames = matchedIndices?.map(ind => columnNames?.[ind]);

  const [modalRowIndex, setModalRowIndex] = useState(null);
  const [modalColumnIndex, setModalColumnIndex] = useState(null);

  const sortedRows = rows?.slice(1);
  // const sortedRows = rows?.slice(1)?.sort((row1, row2) => {
  //   const sortColumnIndex = columnNames?.indexOf(sortOptions?.columnName);

  //   const row1Value = row1?.[sortColumnIndex];
  //   const row2Value = row2?.[sortColumnIndex];

  //   if (sortOptions?.order === "ASC") {
  //     if (row1Value < row2Value) {
  //       return -1;
  //     }
  //     if (row1Value > row2Value) {
  //       return 1;
  //     }
  //     return 0;
  //   }

  //   if (sortOptions?.order === "DESC") {
  //     if (row1Value > row2Value) {
  //       return -1;
  //     }
  //     if (row1Value < row2Value) {
  //       return 1;
  //     }
  //     return 0;
  //   }

  //   return 0;
  // });

  const doDownloadTable = async () => {
    const tableColumns = rows?.[0];
    const tablePreview = rows?.slice(1)?.map(row => {
      const newRow = {};
      tableColumns?.forEach((column, index) => {
        if (column === "emailId") {
          newRow["Email Subject"] = {
            Value: row[index],
            RawValue: row[index],
          };
          return;
        }
        newRow[column] = {
          Value: row[index],
          RawValue: row[index],
        };
      });

      return newRow;
    });

    const body = {
      tableColumns: tableColumns?.map(column => {
        if (column === "emailId") {
          return "Email Subject";
        }
        return column;
      }),
      tablePreview,
    };

    await postDownloadExcelFileOfTable(body);
  };

  const sourceModalMeta = cellLocationToMeta?.[modalCellLocation];

  if (isMapView && rows?.[0]?.[0]?.toLowerCase() === "state") {
    const stateNameToFields = getStateNameToFields(rows);
    return (
      <GeoMap
        id={tableId}
        onSetIsDragging={onSetIsDragging}
        // style={{ gridRow: "span 2" }}
        stateNameToFields={stateNameToFields}
        fieldNames={rows?.[0]?.slice(1)}
        onClickCross={() => {
          const newSchemaConfig = cloneDeep(schemaConfig);
          delete newSchemaConfig[tableId];

          const newGrid = newSchemaConfig?._grid?.map(row => {
            return row?.map(cell => {
              if (cell === tableId) {
                return ".";
              }
              return cell;
            });
          });

          newSchemaConfig._grid = newGrid;
          onChangeSchemaConfig(newSchemaConfig, blocks);
        }}
      />
    );
  }

  return (
    <TableContainer
      style={style}
      draggable="true"
      onDragStart={e => {
        onSetIsDragging(true);
        e.dataTransfer.setData("text/plain", tableId);
      }}
      onDragEnd={() => {
        onSetIsDragging(false);
      }}
    >
      <TopContent>
        <TableTitle
          style={{
            justifyContent:
              tableId === "all_records" ? "start" : "space-between",
          }}
        >
          {tableId === "all_records" ? (
            "Download"
          ) : (
            <StyledInputWithState
              initialValue={name}
              onApplyValue={newName => {
                const newSchemaConfig = cloneDeep(schemaConfig);
                newSchemaConfig[tableId].name = newName;
                onChangeSchemaConfig(newSchemaConfig, blocks);
              }}
            />
          )}
          <StyledDownloadIcon onClick={doDownloadTable} />
          {tableId !== "all_records" && (
            <>
              {/* <StyledCodeIcon
                isBlue={isViewingCode}
                onClick={() => setIsViewingCode(!isViewingCode)}
              /> */}
              <CodeModalTrigger name={name} />
              <StyledCrossIcon
                onClick={() => {
                  const newSchemaConfig = cloneDeep(schemaConfig);
                  delete newSchemaConfig[tableId];

                  const newGrid = newSchemaConfig?._grid?.map(row => {
                    return row?.map(cell => {
                      if (cell === tableId) {
                        return ".";
                      }
                      return cell;
                    });
                  });

                  newSchemaConfig._grid = newGrid;
                  onChangeSchemaConfig(newSchemaConfig, blocks);
                }}
              />
            </>
          )}
        </TableTitle>
        {tableId === "all_records" && (
          <FilterInput
            value={filterValue}
            onChange={e => setFilterValue(e.target.value)}
            placeholder="Filter columns"
          />
        )}
      </TopContent>
      <TableScrollContainer>
        <StyledTable>
          <tr>
            {filteredColumnNames?.map(cell => {
              let sortIcon = (
                <StyledArrowDown
                  onClick={() =>
                    setSortOptions({ columnName: cell, order: "DESC" })
                  }
                  style={{ height: "16px", width: "16px" }}
                />
              );

              if (
                sortOptions?.columnName === cell &&
                sortOptions?.order === "ASC"
              ) {
                sortIcon = (
                  <StyledArrowDown
                    isHighlighted
                    onClick={() =>
                      setSortOptions({ columnName: cell, order: "DESC" })
                    }
                    style={{
                      height: "16px",
                      width: "16px",
                      transform: "rotate(180deg)",
                    }}
                  />
                );
              }

              if (
                sortOptions?.columnName === cell &&
                sortOptions?.order === "DESC"
              ) {
                sortIcon = (
                  <StyledArrowDown
                    isHighlighted
                    onClick={() =>
                      setSortOptions({ columnName: cell, order: "ASC" })
                    }
                    style={{
                      height: "16px",
                      width: "16px",
                    }}
                  />
                );
              }

              return (
                <Th>
                  <ColNameAndActions>
                    {columnNameToDisplayName?.[cell] || cell}
                    {RESERVED_COLUMNS?.includes(cell) && (
                      <ColumnActions>{sortIcon}</ColumnActions>
                    )}
                    {!RESERVED_COLUMNS?.includes(cell) &&
                      tableId === "all_records" && (
                        <ColumnActions>
                          {sortIcon}
                          <NewDashboardColumnModalTrigger
                            schemaConfig={schemaConfig}
                            blocks={blocks}
                            onChangeSchemaConfig={(
                              newSchemaConfig,
                              newBlocks
                            ) =>
                              onChangeSchemaConfig(newSchemaConfig, newBlocks)
                            }
                            editColumnName={cell}
                          />
                          <ConfirmationModalTrigger
                            message={`Are you sure you want to delete column "${cell}"?`}
                            onClickYes={() => {
                              const columnName = cell;
                              const [newSchemaConfig, newBlocks] =
                                deleteColumnFromSchemaConfig(
                                  schemaConfig,
                                  blocks,
                                  columnName
                                );

                              onChangeSchemaConfig(newSchemaConfig, newBlocks);
                            }}
                          />
                        </ColumnActions>
                      )}
                  </ColNameAndActions>
                </Th>
              );
            })}
            {tableId === "all_records" && (
              <Th>
                <NewDashboardColumnModalTrigger
                  schemaConfig={schemaConfig}
                  blocks={blocks}
                  onChangeSchemaConfig={(newSchemaConfig, newBlocks) =>
                    onChangeSchemaConfig(newSchemaConfig, newBlocks)
                  }
                />
              </Th>
            )}
          </tr>
          {sortedRows?.map((row, rowIndex) => {
            const rowValues = matchedIndices?.map(ind => row?.[ind]);
            const areAllValuesVerified = rowValues?.every((_, columnIndex) => {
              const cellLocation = `${rowIndex + 1},${columnIndex}`;
              if (!cellLocationToMeta?.[cellLocation]?.fileId) {
                return true;
              }

              const cellMeta = cellLocationToMeta?.[cellLocation];
              return cellMeta?.checked;
            });

            const isRowInProgress =
              rowIndexToStatus?.[rowIndex + 1] === "IN_PROGRESS";

            return (
              <Tr key={rowIndex}>
                {rowValues?.map((cell, columnIndex) => {
                  const columnName =
                    columnNames?.[matchedIndices?.[columnIndex]];
                  const columnType =
                    schemaConfig.all_records.columnNameToType?.[columnName] ||
                    "TEXT";

                  let cellValue = cell;
                  if (columnType === "CURRENCY") {
                    cellValue = CURRENCY_FORMATTER.format(cell);
                  }

                  const cellLocation = `${rowIndex + 1},${
                    matchedIndices?.[columnIndex]
                  }`;
                  if (cellLocationToMeta?.[cellLocation]?.fileId) {
                    return (
                      <Td>
                        <span
                          onClick={() => {
                            setModalCellLocation(cellLocation);
                            setModalRowIndex(rowIndex + 1);
                            setModalColumnIndex(columnIndex);
                          }}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#0191ff22",
                          }}
                        >
                          {typeof cellValue === "object"
                            ? JSON.stringify(cellValue)
                            : cellValue}
                          <VerificationDot
                            isVerified={
                              cellLocationToMeta?.[cellLocation]?.checked
                            }
                          />
                        </span>
                      </Td>
                    );
                  }

                  if (columnName === "receivedAt") {
                    return (
                      <Td>
                        <TableText>
                          {safeFormat(cellValue, "d MMM yyyy, HH:mm")}
                        </TableText>
                      </Td>
                    );
                  }

                  return (
                    <Td>
                      <TableText>{cellValue}</TableText>
                    </Td>
                  );
                })}

                {tableId === "all_records" && (
                  <TickTd>
                    {isRowInProgress && (
                      <LoadingRowContainer>
                        <CircularProgress size={14} thickness={8} />
                      </LoadingRowContainer>
                    )}
                    {!isRowInProgress &&
                      (!areAllValuesVerified ? (
                        <GreyTickIcon
                          onClick={() => onPressVerifyRow(rowIndex + 1, true)}
                        />
                      ) : (
                        <BlueTickIcon
                          onClick={() => onPressVerifyRow(rowIndex + 1, false)}
                        />
                      ))}
                  </TickTd>
                )}

                {tableId === "all_records" && (
                  <CrossTd>
                    <ConfirmationModalTrigger
                      icon={<StyledCrossIcon />}
                      message={`Are you sure you want to delete email "${row[0]}"?`}
                      onClickYes={() => {
                        onClickCross(rowIndex + 1);
                      }}
                    />
                    {/* <StyledCrossIcon
                  onClick={() => {
                    onClickCross(rowIndex + 1);
                  }}
                /> */}
                  </CrossTd>
                )}

                {tableId === "all_records" && (
                  <RefreshTd
                    onClick={() => {
                      onClickRefresh(rowIndex + 1);
                    }}
                  >
                    <Refresh />
                  </RefreshTd>
                )}
              </Tr>
            );
          })}
        </StyledTable>
      </TableScrollContainer>

      <PagePreviewTextAndTableModal
        open={sourceModalMeta?.fileId}
        handleClose={() => setModalCellLocation(null)}
        checked={sourceModalMeta?.checked}
        refText={sourceModalMeta?.refText}
        tableDocumentLocation={{
          fileId: sourceModalMeta?.fileId,
          pageNumber: sourceModalMeta?.pageNumber,
          fileName: sourceModalMeta?.fileName,
          annotationId: sourceModalMeta?.annotationId,
          emailId: sourceModalMeta?.emailId,
        }}
        isReferenceEditable
        columnName={rows?.[0]?.[modalColumnIndex]}
        columnValue={rows?.[modalRowIndex]?.[modalColumnIndex]}
        columnDescription={
          schemaConfig?.all_records?.columnNameToDescription?.[
            rows?.[0]?.[modalColumnIndex]
          ]
        }
        blocks={blocks}
        onPressSaveValue={async (newValue, newAnnotationId) => {
          await onChangeCell(
            modalRowIndex,
            modalColumnIndex,
            newValue,
            newAnnotationId
          );
          setModalCellLocation(null);
        }}
        onPressVerify={checked => {
          onPressVerifyCell(modalRowIndex, modalColumnIndex, checked);
        }}
      />
    </TableContainer>
  );
};

const PlotContainer = styled.div`
  height: 200px;
`;

const PlotTitle = styled.div`
  padding: 8px 0;
  font-weight: 600;
`;

const Plot = ({ style, name, points, xLabel = "", yLabel = "" }) => {
  return (
    <PlotContainer style={style}>
      <PlotTitle>{name}</PlotTitle>
      <ResponsiveContainer>
        <LineChart data={points}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            label={{
              value: xLabel,
              angle: 0,
              position: "insideBottom",
              offset: -5,
            }}
          />
          <YAxis
            label={{ value: yLabel, angle: -90, position: "insideLeft" }}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="y"
            stroke="#0191ff"
          />
        </LineChart>
      </ResponsiveContainer>
    </PlotContainer>
  );
};

const getGridString = gridList => {
  const grid = gridList
    .map(row => {
      return `"${row.join(" ")}"`;
    })
    .join("\n");

  return grid;
};

/*
pie chart
single number
geo map
*/

const DashboardConfigVisualiser = ({
  config = {},
  onPatchSigmaRecord = (id, body, newColumnNameToMeta) => {},
  schemaConfig = {},
  blocks = [],
  onChangeSchemaConfig = (newSchemaConfig = {}, newBlocks = []) => {},
  sigmaRecords = [],
  doRefreshSigmaRecord = id => {},
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setIsVisible(false);
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    return () => clearTimeout(timeoutId);
  }, [JSON.stringify(config?._grid)]);

  if (!config?._grid) {
    return null;
  }

  const seenComponentIds = [];
  const componentIds = config?._grid?.flat()?.map((componentId, index) => {
    if (componentId === ".") {
      return `.${index}`;
    }

    if (seenComponentIds?.includes(componentId)) {
      return `-${index}`;
    }

    seenComponentIds.push(componentId);
    return componentId;
  });

  return (
    <Container
      style={{
        display: isVisible ? "grid" : "none",
        gridTemplateAreas: getGridString(config?._grid),
      }}
    >
      {componentIds?.map((componentId, index) => {
        const component = config?.[componentId];
        const componentName = component?.name || componentId;

        if (component?.type === "plot") {
          return (
            <Plot
              style={{ gridArea: componentName }}
              key={index}
              gridArea={componentName}
              name={component?.name}
              points={component?.points}
              xLabel={component?.xLabel}
              yLabel={component?.yLabel}
            />
          );
        }

        if (componentId?.includes(".")) {
          return (
            <div
              key={componentId}
              style={{
                width: "100%",
                height: "100%",
                border: "4px dashed",
                borderColor: "#dad9d9",
                opacity: isDragging ? 1 : 0,
                transition: "opacity 0.2s",
              }}
              onDragOver={e => {
                e.preventDefault();
                e.target.style.opacity = 1;
                e.target.style.borderColor = "#a7a7a7";
              }}
              onDragLeave={e => {
                e.target.style.borderColor = "#dad9d9";
                // e.target.style.opacity = 0;
              }}
              onDrop={e => {
                setIsDragging(false);
                e.target.style.opacity = 0;
                e.preventDefault();

                const droppedTableId = e.dataTransfer.getData("text/plain");
                const newRowIndex = Math.floor(index / 3);
                const newColIndex = index % 3;

                const newSchemaConfig = cloneDeep(schemaConfig);
                const newGrid = newSchemaConfig?._grid?.map((row, rowIndex) => {
                  const newRow = row?.map((cell, colIndex) => {
                    if (rowIndex === newRowIndex && colIndex === newColIndex) {
                      return droppedTableId;
                    }

                    if (cell === droppedTableId) {
                      return ".";
                    }

                    return cell;
                  });

                  return newRow;
                });

                newSchemaConfig._grid = newGrid;

                onChangeSchemaConfig(newSchemaConfig, blocks);
              }}
            >
              {/* empty, row {Math.floor(index / 3)}, col {index % 3} */}
            </div>
          );
        }

        if (component?.type === "table") {
          return (
            <Table
              key={componentId || componentName}
              isMapView={component?.isMapView}
              tableId={componentId || "all_records"}
              style={{ gridArea: componentId || componentName }}
              // key={index}
              name={component?.name}
              rows={component?.rows}
              rowIndexToStatus={component?.rowIndexToStatus}
              cellLocationToMeta={component?.cellLocationToMeta}
              onChangeCell={async (
                rowIndex,
                columnIndex,
                newValue,
                newAnnotationId
              ) => {
                const columnName = component?.rows?.[0]?.[columnIndex];
                const id =
                  component?.cellLocationToSigmaId?.[
                    `${rowIndex},${columnIndex}`
                  ];
                const sigmaRecord =
                  sigmaRecords?.find(sigma => sigma?._id === id) || {};

                const body = {
                  [columnName]: {
                    ...sigmaRecord?.[columnName],
                    value:
                      sigmaRecord?.[columnName]?.type === "NUMERIC"
                        ? parseFloat(newValue)
                        : newValue,
                    checked: true,
                  },
                };
                await onPatchSigmaRecord(id, body, {
                  [columnName]: {
                    annotationId: newAnnotationId,
                  },
                });
              }}
              onPressVerifyCell={async (rowIndex, columnIndex, checked) => {
                const columnName = component?.rows?.[0]?.[columnIndex];
                const id =
                  component?.cellLocationToSigmaId?.[
                    `${rowIndex},${columnIndex}`
                  ];
                const sigmaRecord =
                  sigmaRecords?.find(sigma => sigma?._id === id) || {};

                const body = {
                  [columnName]: {
                    ...sigmaRecord?.[columnName],
                    checked,
                  },
                };

                await onPatchSigmaRecord(id, body);
              }}
              onPressVerifyRow={async (rowIndex, checked) => {
                const id = component?.cellLocationToSigmaId?.[`${rowIndex},0`];
                const sigmaRecord =
                  sigmaRecords?.find(sigma => sigma?._id === id) || {};
                const columnNames = component?.rows?.[0]?.filter(
                  colName => colName !== "emailId"
                );
                const body = {};
                columnNames?.forEach(columnName => {
                  body[columnName] = {
                    ...sigmaRecord?.[columnName],
                    checked,
                  };
                });

                await onPatchSigmaRecord(id, body);
              }}
              blocks={blocks}
              schemaConfig={schemaConfig}
              onChangeSchemaConfig={onChangeSchemaConfig}
              onClickCross={async rowIndex => {
                const id = component?.cellLocationToSigmaId?.[`${rowIndex},0`];
                const body = { isDeleted: true };
                await onPatchSigmaRecord(id, body);
              }}
              onClickRefresh={async rowIndex => {
                const id = component?.cellLocationToSigmaId?.[`${rowIndex},0`];
                await doRefreshSigmaRecord(id);
              }}
              onSetIsDragging={setIsDragging}
            />
          );
        }

        return null;
      })}
    </Container>
  );
};

export default DashboardConfigVisualiser;
