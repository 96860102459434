import {
  getActiveSubscription,
  postPaymentSession,
} from "api/services/projectService";
import ErrorMessageModal from "components/ErrorMessageModal";
import { Gap } from "components/Layout";
import TableFlat from "components/TableFlat";
import SmallButton from "components/ui/SmallButton";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import styled from "styled-components";

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  grid-column: span 2;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const UpgradeButton = styled(SmallButton)`
  display: inline;
  padding: 8px 20px;
`;

const Panel = styled.div`
  min-width: 300px;
  padding: 10px;
  width: max-content;
  background-color: ${props => props.theme.color.closer0};
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const TitleAndEditLink = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
`;

const TdBlueText = styled.td`
  background-color: ${props => props.theme.color.primary};
  color: white;
  font-weight: bold;
  padding: 8px 0;
  text-align: center;
  vertical-align: middle;
`;

const TdButton = styled.td`
  text-align: center;
  vertical-align: middle;
  /* padding: 8px 0; */
`;

const EditLink = styled.div`
  color: ${props => props.theme.color.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const CancelButton = styled(SmallButton)`
  padding: 4px 20px;
  border: 2px solid ${props => props.theme.color.error};

  :hover {
    background-color: ${props => props.theme.color.error};
    border: 2px solid ${props => props.theme.color.error};
  }
`;

const SUCCESS_URL = `${window.location.origin}/profile?selectedView="Account"`;
const CANCEL_URL = `${window.location.origin}/profile?selectedView="Account"`;

const AccountView = ({ userName }) => {
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateUserProfile();
  }, [userName]);

  const doPopulateUserProfile = async () => {
    const { data } = await getActiveSubscription();
    setActiveSubscription(data);
  };

  const doPostPaymentSession = async plan => {
    const { data, error } = await postPaymentSession(
      {},
      {
        plan,
        successUrl: SUCCESS_URL,
        cancelUrl: CANCEL_URL,
      }
    );
    if (error) {
      setError(error);
      return;
    }

    window.location = data.paymentUrl;
  };

  return (
    <>
      <Panel>
        <Title>Plan Details</Title>
        type <BoldSpan>{activeSubscription?.plan}</BoldSpan>
        status <BoldSpan>{activeSubscription?.status}</BoldSpan>
        created at{" "}
        <BoldSpan>
          {activeSubscription?.createdAt
            ? format(new Date(activeSubscription?.createdAt), "d MMM yyyy")
            : ""}
        </BoldSpan>
      </Panel>
      <Gap height="20px" />
      <Panel>
        <TitleAndEditLink>
          <Title>Billing Address</Title>
          <EditLink>Edit</EditLink>
        </TitleAndEditLink>
        next billing date{" "}
        <BoldSpan>
          {activeSubscription?.createdAt
            ? format(new Date(activeSubscription?.createdAt), "d MMM yyyy")
            : ""}
        </BoldSpan>
        next billing amount <BoldSpan>100 $</BoldSpan>
        billing address <BoldSpan>123 Main St, New York, NY 10030</BoldSpan>
      </Panel>
      <Gap />
      <Panel>
        <TitleAndEditLink>
          <Title>Payment Methods</Title>
          <EditLink>Edit</EditLink>
        </TitleAndEditLink>
        <div>card ending in 3657</div>
        <div />
        <div>expiring 1/2025</div>
      </Panel>
      <Gap />
      <TableFlat
        headerRow={["", "Individual", "Premium", "Business", "Enterprise"]}
        bodyRows={[
          ["Private AI Model", "tick", "tick", "tick", "tick"],
          ["Data Source: PDF, Excel, Website", "tick", "tick", "tick", "tick"],
          ["Data Source: Email", "", "tick", "tick", "tick"],
          [
            "Report Generation in Search and Word",
            "tick",
            "tick",
            "tick",
            "tick",
          ],
          [
            "Report Generation in Excel, PowerPoint and Dashboard",
            "",
            "tick",
            "tick",
            "tick",
          ],
          ["Source Verification", "", "", "tick", "tick"],
          ["Page Limit", "", "", "Unlimited", "Unlimited"],
          ["Customised GPT", "", "CPU", "GPU", "GPU"],
          ["Maximum Users", "1", "5", "ASK SALES", "ASK SALES"],
          ["Price", "ASK SALES", "ASK SALES", "ASK SALES", "ASK SALES"],
        ]}
        footerRows={[
          [
            <td />,
            <TdBlueText>Your plan</TdBlueText>,
            <TdButton>
              <UpgradeButton value="Upgrade" />
            </TdButton>,
            <TdButton>
              <UpgradeButton value="Upgrade" />
            </TdButton>,
            <td />,
          ],
        ]}
      />
      <CancelButton variant="err" value="Cancel plan" />
      <Gap height="50px" />
      <ErrorMessageModal message={error?.message} />
    </>
  );
};

export default AccountView;
