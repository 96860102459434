import * as backend from "api/backend/dataTrainingAuditEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const postPageMetas = async (params = {}, body = {}) => {
  const { data, error } = await backend.postPageMetas(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postPages = async (params = {}, body = {}) => {
  const { data, error } = await backend.postPages(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const patchUpdatePage = async (params = {}, body = {}) => {
  const { data, error } = await backend.patchUpdatePage(params, body);
  return { data, error: getHandlingFromError(error) };
};
