import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  deleteWordDoc,
  getWordDocs,
  postWordDoc,
} from "api/services/wordDocsService";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import TableActionsDropdown from "components/TableActionsDropdown";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr auto;
  align-items: center;

  gap: 20px;
  padding: 12px 20px;
  color: white;
  background: linear-gradient(
    to right,
    ${props => props.theme.color.feature},
    ${props => props.theme.color.primary}
  );

  font-weight: 600;
`;

const PackageListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 668px;
  padding-bottom: 50px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const TitleAndButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 10px;
`;

const PackageRow = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr auto;
  align-items: start;

  gap: 20px;
  padding: 12px 20px;
  background-color: ${props =>
    props.rowIndex % 2 !== 0
      ? props.theme.color.closer0
      : `${props.theme.color.closer1}33`};

  :hover {
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.closer1}BB;
  }

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const WordDocsPage = () => {
  const navigate = useNavigate();
  const [wordDocs, setWordDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [wordDocIdBeingDeleted, setWordDocIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateWordDocs();
  }, []);

  const doPopulateWordDocs = async () => {
    const { data } = await getWordDocs();
    setWordDocs(data);
    setIsLoading(false);
  };

  return (
    <CenteredWithTopNavLayout>
      <TitleAndButton>
        <BigTitle>Word Docs</BigTitle>
        <ButtonCreateNewAsset
          isDisabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const { data, error } = await postWordDoc(
              {},
              {
                fileName: `Untitled Doc`,
                content: { blocks: [{ text: "" }] },
              }
            );
            setError(error);
            if (!error) {
              navigate(`/word-docs/${data?.id}`);
            }
            setIsLoading(false);
          }}
        />
      </TitleAndButton>
      <Gap />
      <PackageListContainer isDisabled={isLoading}>
        <HeaderRow>
          <div>Name</div>
          <div>Created at</div>
          <div>Actions</div>
        </HeaderRow>
        {wordDocs?.map(wordDoc => (
          <PackageRow
            key={wordDoc?.id}
            isDisabled={wordDocIdBeingDeleted === wordDoc?.id}
          >
            <Link to={`/word-docs/${wordDoc.id}`}>{wordDoc.fileName}</Link>
            <div>{wordDoc.createdAt}</div>
            <div>
              <TableActionsDropdown
                onClickDelete={async () => {
                  setWordDocIdBeingDeleted(wordDoc?.id);
                  const { error } = await deleteWordDoc(wordDoc?.id);
                  setError(error);
                  if (!error) {
                    setWordDocs(wordDocs.filter(t => t.id !== wordDoc.id));
                  }
                  setWordDocIdBeingDeleted(null);
                }}
              />
            </div>
          </PackageRow>
        ))}
        {error && <div>{JSON.stringify(error)}</div>}
      </PackageListContainer>
    </CenteredWithTopNavLayout>
  );
};

export default WordDocsPage;
