import { round } from "lodash";
import styled from "styled-components";

const Td = styled.td`
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.color.closest}55;
  font-weight: 300;
  transition: opacity 0.2s, border 0.2s;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid ${props => props.theme.color.closest}55;
  font-weight: 500;
  text-align: left;
  transition: opacity 0.2s, border 0.2s;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const formatStatValue = (label, value) => {
  if (typeof value !== "number") {
    return value;
  }

  if (label === "Coverage") {
    return `${round(value * 100, 2)}%`;
  }

  if (
    ["Total Issue Size", "Total Book Size"].includes(label) ||
    value > 10 ** 6
  ) {
    return `${round(value * 10 ** -6, 2)}mm`;
  }

  return round(value, 2);
};

const RecordsSummaryTable = ({ columns = [], records = [] }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((col, colIndex) => (
            <Th key={colIndex}>{col.name}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {records?.map((record, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <Td key={colIndex}>
                {formatStatValue(col?.name, record[col.name]?.value)}
              </Td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default RecordsSummaryTable;
