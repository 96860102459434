import jwt_decode from "jwt-decode";
import * as backend from "api/backend/authenticationServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

export const getLoggedInUserName = () => {
  try {
    const accessToken = getLoginTokenFromClientStorage();
    const { sub } = jwt_decode(accessToken);
    return sub;
  } catch {
    return "";
  }
};

const INVESTOR_USER_NAME = "46f3593f-2bfa-4575-81cd-25886c5406dc";

export const isInvestorUser = () => {
  try {
    const accessToken = getLoginTokenFromClientStorage();
    const { sub } = jwt_decode(accessToken);
    if (sub === INVESTOR_USER_NAME) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const decodeStoredJwt = () => {
  try {
    const accessToken = getLoginTokenFromClientStorage();
    return jwt_decode(accessToken);
  } catch {
    return {};
  }
};

export const resetPassword = async user => {
  const { data, error } = await backend.resetPassword(user);
  return { data, error: getHandlingFromError(error) };
};

export const updateUser = async (user, userObj) => {
  const { data, error } = await backend.updateUser(user, userObj);
  return { data, error: getHandlingFromError(error) };
};

export const getPlatformLoginToken = async (username, password) => {
  const { data, error } = await backend.getLoginTokens(username, password);

  if (error) {
    const errorHandlingDetails = getHandlingFromError(error);
    return { data, error: errorHandlingDetails };
  }

  return { data, error };
};

export const postLogin = async (params = {}, body = {}) => {
  const { data, error } = await backend.postLogin(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getJwtForUserFromOneTimeCode = async (userId, code) => {
  const { data, error } = await backend.getVerifyOneTimeCode(userId, { code });
  return { data, error: getHandlingFromError(error) };
};

export const getUserGroups = async () => {
  const { data, error } = await backend.getUserGroups();
  return { data: data || [], error: getHandlingFromError(error) };
};

export const getUsers = async () => {
  const { data, error } = await backend.getUsers();
  return { data: data || [], error: getHandlingFromError(error) };
};

export const postUserGroups = async (params = {}, body = {}) => {
  const { data, error } = await backend.postUserGroups(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const registerTrialUser = async (params = {}, body = {}) => {
  const { data, error } = await backend.registerTrialUser(params, body);
  return { data, error };
};

export const getUserProfile = async (params = {}) => {
  const { data, error } = await backend.getUserProfile(params);
  return { data, error: getHandlingFromError(error) };
};

export const postUpgradeTrialUser = async (params = {}, body = {}) => {
  const { data, error } = await backend.postUpgradeTrialUser(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postUsersVerify = async (params = {}, body = {}) => {
  const { data, error } = await backend.postUsersVerify(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postUsers = async (params = {}, body = {}) => {
  const { data, error } = await backend.postUsers(params, body);
  return { data, error: getHandlingFromError(error) };
};
