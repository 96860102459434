import TableViewerScanned from "./TableViewerScanned";
import ImageWithOCRAnnotations from "components/ImageWithOCRAnnotations";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getActivatedLabelSetId,
  getAnnotationsByFileAndPage,
  getPreDefinedLabelSets,
  patchTextAnnotationsByFileAndPage,
  saveAnnotationsByFileAndPage,
} from "api/services/filesService";
import useEventListener from "hooks/useEventListener";
import { round } from "lodash";
import AnnotationConfigModal from "components/AnnotationConfigModal";
import ProgressBar from "components/ui/ProgressBar";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import ImageWithOCRAnnotationsAndHumanAnnottions from "components/ImageWithOCRAnnotationsAndHumanAnnotations";
import { CircularProgress } from "@material-ui/core";
import TextLabellingArea from "components/widgets/TextLabellingArea";
import usePollPageResults from "api/services/usePollPageResults";
import ZoomButton from "components/ui/ZoomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConversationChat from "components/widgets/ConversationChat";
import SolrRecordEditor from "components/widgets/SolrRecordEditor";
import SmallButton from "components/ui/SmallButton";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 50px;
  gap: 10px;
  justify-content: center;
`;

const TextContainer = styled.div`
  padding: 10px;
  transition: opacity 0.2s;
  opacity: ${props => (props.isHidden ? 0 : 1)};

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  background-color: ${props => props.theme.color.closer0};
  height: 100%;
`;

const OutputsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;

  max-height: calc(100vh - 200px);
  background-color: ${props => props.theme.color.closer0};
  overflow: auto;
`;

const StyledImageWithOCRAnnotations = styled(ImageWithOCRAnnotations)`
  border-top-right-radius: ${props => (props.noTopRightBorderRadius ? 0 : "5px")};
`;

const TopBar = styled.div`
  background-color: ${props => props.theme.color.closer0};
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 10px;
  padding: 10px 0;
  padding-right: 20px;
  position: relative;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
`;

const StagesContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const TopBarAndCanvas = styled.div`
  overflow: hidden;
  border: 4px solid;
  height: max-content;
  max-height: calc(100vh - 200px);
  overflow: hidden;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  background-color: ${props => props.theme.color.closer0};
`;

const Legend = styled.div`
  opacity: 0.7;
  margin-left: 20px;
  position: absolute;
  padding: 4px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};
  z-index: 12;
  font-size: 14px;

  border: 2px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const Square = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${props => props.backgroundColor};
`;

const PatchingIndicator = styled.div`
  transition: opacity 0.2s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  svg {
    transform: scale(0.5);
  }
`;

const CheckedIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${props => (props.isChecked ? props.theme.color.success : props.theme.color.in_progress)};
`;

const sizeIncremental = 250;

const FileView = ({
  fileId,
  fileName,
  noTopRightBorderRadius,
  pageNumber = 0,
  pageStatus,
  entities,
  onNewEntities,
  pageProgress,
  isChatEnabled = true,
  pageAnnotation,
  setPageAnnotation,
  isPatchingAnnotations,
  setIsPatchingAnnotations,
  doPatchHumanAnnotations,
  setOriginalPageAnnotations,
  areOriginalAnnotationsDifferent,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [size, setSize] = useState(600);
  const [zoomScale, setZoomScale] = useState(1);
  const [isAnnotationConfigModalOpen, setIsAnnotationConfigModalOpen] = useState(false);
  const [isViewMode, setIsViewMode] = useState(true);
  const [selectedOutputItem, setSelectedOutputItem] = useState(null);
  const [preDefinedLabels, setPreDefinedLabels] = useState([]);

  const [pageResults, , setPageResults] = usePollPageResults(fileId, pageNumber, pageStatus);
  const [userReferenceCandidates, setUserReferenceCandidates] = useState([]);

  const solrId = searchParams.get("solrId");
  const tableName = searchParams.get("tableName");
  const tableId = searchParams.get("tableId");
  const columnName = searchParams.get("columnName");
  const solrValue = searchParams.get("solrValue");

  const keyDownHandler = ({ key }) => {
    if (key === "ArrowUp") {
      if (zoomScale < 3) {
        setZoomScale(zoomScale + 0.5);
        setSize(size + sizeIncremental);
      }
      return;
    }
    if (key === "ArrowDown") {
      if (zoomScale > 0.5) {
        setZoomScale(zoomScale - 0.5);
        setSize(size - sizeIncremental);
      }
      return;
    }
  };

  useEventListener("keydown", keyDownHandler);

  useEffect(() => {
    setSelectedOutputItem(null);

    doFetchHumanAnnotations();
    doFetchLabels();
  }, [fileId, pageNumber, pageStatus]);

  const doFetchLabels = async () => {
    const { data: labelSets, error: labelsetsError } = await getPreDefinedLabelSets();
    const { data: activeLabelSetId, activeLabelSetIdError } = await getActivatedLabelSetId();
    if (!labelsetsError && !activeLabelSetIdError) {
      const filteredLabelSet = labelSets.find(e => e.id === activeLabelSetId.activatedLabelSetId);
      setPreDefinedLabels(filteredLabelSet.labels);
    }
  };

  const doFetchHumanAnnotations = async () => {
    const { data, error } = await getAnnotationsByFileAndPage(fileId, pageNumber);
    if (!error) {
      setPageAnnotation(data);
      setOriginalPageAnnotations(data);
    }
  };

  const onAddReferenceCandidate = newCandidate => {
    const doesReferenceExistAlready = userReferenceCandidates?.find(
      candidate =>
        candidate?.annotationId === newCandidate?.annotationId && candidate?.pageNumber === newCandidate?.pageNumber
    );
    if (doesReferenceExistAlready) {
      return;
    }

    const newCandidates = [...userReferenceCandidates, newCandidate];
    setUserReferenceCandidates(newCandidates);
  };

  return (
    <Container>
      <TopBarAndCanvas>
        <TopBar>
          <Buttons>
            <ZoomButton
              onClick={() => {
                if (zoomScale < 3) {
                  setZoomScale(zoomScale + 0.5);
                  setSize(size + sizeIncremental);
                }
              }}
            >
              +
            </ZoomButton>
            <ZoomButton
              isMinusIcon
              onClick={() => {
                if (zoomScale > 0.5) {
                  setZoomScale(zoomScale - 0.5);
                  setSize(size - sizeIncremental);
                }
              }}
            >
              -
            </ZoomButton>
            <p>{round(zoomScale * 100)}%</p>
            <SmallButton
              value={`Save annotations${areOriginalAnnotationsDifferent ? " *" : ""}`}
              onClick={() => doPatchHumanAnnotations(pageAnnotation?.annotations)}
            />
          </Buttons>
          <StagesContainer>
            {pageProgress < 100 && <ProgressBar maxValue={100} currentValue={pageProgress} />}
          </StagesContainer>
          <PatchingIndicator isVisible={isPatchingAnnotations}>
            <CircularProgress fontSize="small" />
          </PatchingIndicator>
          <TwoNameSwitch
            leftName="View"
            rightName="Annotate"
            isOnLeft={isViewMode}
            onSwitch={() => setIsViewMode(!isViewMode)}
          />
          <CheckedIndicator isChecked={pageAnnotation?.checked} />
        </TopBar>
        <Legend>
          <Square backgroundColor="#00C85365" />
          Tables
          <Square backgroundColor="#04788965" />
          Texts
        </Legend>
        {isViewMode && (
          <StyledImageWithOCRAnnotations
            zoomScale={zoomScale}
            width={size}
            height={size * 1.2}
            base64Data={pageResults?.image}
            annotations_0={pageResults?.annotations_0}
            annotations_1={pageResults?.annotations_1}
            annotations_2={pageResults?.annotations_2}
            annotations_3={pageResults?.annotations_3}
            noTopRightBorderRadius={noTopRightBorderRadius}
            selectedOutputItem={selectedOutputItem}
            onClickAnnotation={clickedAnno => {
              const newSelectedOutputItem = pageResults?.output?.find(out => out?.id === clickedAnno?.id);
              if (clickedAnno?.id || clickedAnno?.id === 0) {
                searchParams.set("annotationIdToHighlight", clickedAnno?.id);
                navigate({ search: searchParams.toString() });
              }
              setSelectedOutputItem(newSelectedOutputItem);
            }}
          />
        )}
        {!isViewMode && (
          <ImageWithOCRAnnotationsAndHumanAnnottions
            isDisabled={isPatchingAnnotations}
            width={size}
            height={size * 1.2}
            base64Data={pageResults?.image}
            annotations_0={pageResults?.annotations_0}
            annotations_1={pageResults?.annotations_1}
            annotations_2={pageResults?.annotations_2}
            annotations_3={pageResults?.annotations_3}
            humanAnnotations={pageAnnotation?.annotations}
            onNewHumanAnnotations={annotations => setPageAnnotation({ ...pageAnnotation, annotations })}
            doPatchHumanAnnotations={doPatchHumanAnnotations}
            preDefinedLabels={preDefinedLabels}
          />
        )}
      </TopBarAndCanvas>

      <OutputsContainer>
        {solrId && (
          <SolrRecordEditor
            solrId={solrId}
            tableName={tableName}
            columnName={columnName}
            solrValue={solrValue}
            tableId={tableId}
            userReferenceCandidates={userReferenceCandidates}
          />
        )}
        {isChatEnabled && <ConversationChat fileId={fileId} fileName={fileName} />}
        {!isChatEnabled && !selectedOutputItem && isViewMode && (
          <TextContainer>Click on a box on the left</TextContainer>
        )}
        {!isChatEnabled && selectedOutputItem?.type === "table" && (
          <TableViewerScanned
            entities={entities}
            onNewEntities={onNewEntities}
            key={selectedOutputItem?.tableName}
            packageName={pageResults?.packageName}
            table={selectedOutputItem}
            outputItemId={selectedOutputItem?.id}
            annotations={pageResults?.annotations}
            textOnBoldedValueHover={searchParams?.get("textOnBoldedValueHover")}
            boldedCellValue={searchParams?.get("boldedCellValue")}
            onAddReferenceCandidate={onAddReferenceCandidate}
            isScanComplete
          />
        )}
        {!isChatEnabled && selectedOutputItem?.type === "text" && (
          <TextLabellingArea
            onNewTextAnnotations={async newTextAnnotations => {
              setIsPatchingAnnotations(true);
              await patchTextAnnotationsByFileAndPage(fileId, pageNumber, {
                outputId: selectedOutputItem?.id,
                content: selectedOutputItem?.content,
                annotations: newTextAnnotations,
              });

              const newOutputItem = { ...selectedOutputItem, annotations: newTextAnnotations };
              const newPageResultsOutput = pageResults?.output?.map(outputItem => {
                if (outputItem?.id === selectedOutputItem?.id) {
                  return newOutputItem;
                }
                return outputItem;
              });
              const newPageResults = { ...pageResults, output: newPageResultsOutput };

              setPageResults(newPageResults);
              setIsPatchingAnnotations(false);
            }}
            outputItem={selectedOutputItem}
            fileId={fileId}
            entities={entities}
            onNewEntities={onNewEntities}
            onAddReferenceCandidate={onAddReferenceCandidate}
          />
        )}
      </OutputsContainer>
      <AnnotationConfigModal
        open={isAnnotationConfigModalOpen}
        handleClose={() => setIsAnnotationConfigModalOpen(false)}
      />
    </Container>
  );
};

export default FileView;
