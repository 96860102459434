import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { cloneDeep, get, isEmpty, range, set } from "lodash";

import { Gap } from "components/Layout";
import {
  getSigmas,
  getTemplateDashboardConfig,
  patchDashboardConfig,
  patchSigma,
  postDashboardConfigCalculateResults,
  postGenerateComponent,
} from "api/services/chatService";
import {
  CrossIcon,
  FilesIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  TrashIcon,
} from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import RecordsTable from "components/RecordsTable";
import RecordsSummaryChart from "components/RecordsSummaryChart";
import styled from "styled-components";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import SmallButton from "components/ui/SmallButton";
import { postReRunJobs } from "api/services/jobService";
import NavHeader from "components/NavHeader";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import AutocompleteTextInputDropdown from "components/ui/AutocompleteTextInputDropdown";
import {
  COMPONENT_TYPES,
  NL_TO_DSL,
} from "components/ComponentCodeModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ViewColumn,
} from "@material-ui/icons";
import GridDraggable from "components/GridDraggable";

const TOP_LAYOUT = [".", ".", "."];

const ComponentGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20px 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  gap: 20px;

  padding-left: 8px;
  padding-right: 48px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: lightgrey;
  :hover {
    fill: ${props => props.theme.color.closest};
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledFilesIcon = styled(FilesIcon)`
  cursor: pointer;
  fill: ${props => props.theme.color.closer2};

  :hover {
    opacity: 0.8;
  }
`;

const PulsingDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};

  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      background-color: ${props => props.theme.color.primary};
    }

    50% {
      background-color: ${props => props.theme.color.primary}55;
    }

    100% {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;

const TopMessageAndButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 8px;
  grid-column: span 4;
  align-items: center;
  padding: 0px 48px;
  padding-top: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  background-color: ${props => props.theme.color.closer0};
`;

const BlueSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
  svg {
    height: 10px;
    width: 16px;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }
`;

const EmptySlot = styled.div`
  border: 2px dashed lightgrey;
  width: 100%;
  height: 200px;
  opacity: 0.5;
`;

const Container = styled.div``;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  position: relative;
  width: max-content;
  box-shadow: none;
`;

const TopBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 201;

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  background-color: ${props => props.theme.color.furthest};
  padding: 12px;
`;

const RoundPlusIcon = styled(PlusIcon)`
  background-color: ${props => props.theme.color.closer0};
  padding: 2px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

const RoundTrashIcon = styled(TrashIcon)`
  background-color: ${props => props.theme.color.closer0};
  padding: 2px;
  cursor: pointer;
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}

  :hover {
    opacity: 0.6;
  }
`;

const Select = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ChevronContainer = styled.div`
  cursor: pointer;
  ${props => props.isHidden && "opacity: 0; pointer-events: none;"}

  :hover {
    opacity: 0.6;
  }
`;

const TabLinks = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
`;

const TabLinkItem = styled(Link)`
  text-decoration: none;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  font-weight: 600;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props.isSelected ? props.theme.color.primary : props.theme.color.closer1};
`;

const getGridString = (gridList = []) => {
  const grid = gridList
    .map(row => {
      return `". ${row.join(" ")}"`;
    })
    .join("\n");

  return grid;
};

const RESERVED_COLUMNS = ["emailSubject", "receivedAt"];

const getDsl = naturalLanguageCommand => {
  return NL_TO_DSL[naturalLanguageCommand] || naturalLanguageCommand;
};

const PencilContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const DashboardPage = () => {
  const { wordDocId } = useParams();

  const [wordDoc, setWordDoc] = useState({});
  const [dashboardConfig, setDashboardConfig] = useState(null);
  const [sigmas, setSigmas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initiallyOpenComponentId, setInitiallyOpenComponentId] =
    useState(null);

  const [naturalLanguageCommand, setNaturalLanguageCommand] = useState("");
  const [message, setMessage] = useState("");

  const [isEditingDashboard, setIsEditingDashboard] = useState(false);

  useEffect(() => {
    doPopulateTemplateConfig();
    doPopulateSigmas();
    doPopulateWordDoc();

    const intervalId = setInterval(() => {
      doPopulateSigmas();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [wordDocId]);

  useEffect(() => {
    const doCalculateResultsInBackground = async () => {
      await postDashboardConfigCalculateResults(dashboardConfig?._id);
      await doPopulateTemplateConfig();
    };

    doCalculateResultsInBackground();
  }, [sigmas?.length]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
  };

  const sourceRecordsColumns = dashboardConfig?.config?.components?.find(
    component => component?.id === "source_records"
  )?.columns;

  useEffect(() => {
    if (!sourceRecordsColumns?.length) {
      return;
    }

    const blocks = [];
    sourceRecordsColumns
      ?.filter(
        column =>
          !RESERVED_COLUMNS.includes(column?.name) &&
          !column?.isExtractionDisabled
      )
      ?.forEach(column => {
        let newBlock = {
          isQuery: true,
          queryId: uuidv4(),
          text: `extract "${column?.name}"`,
        };
        if (column?.description) {
          newBlock.text = `${newBlock.text}, with description "${column?.description}"`;
        }
        if (column?.action) {
          newBlock.text = `${newBlock.text}, with action "${column?.action}"`;
        }
        if (column?.prediction) {
          newBlock.text = `${newBlock.text}, with prediction "${column?.prediction}"`;
        }
        blocks.push(newBlock);
      });

    const newWordDocBody = {
      content: {
        blocks,
      },
    };
    patchWordDoc(wordDocId, {}, newWordDocBody);
  }, [JSON.stringify(sourceRecordsColumns)]);

  const doPopulateSigmas = async () => {
    const { data } = await getSigmas({ templateId: wordDocId });

    const records = data || [];
    const recordBeingRefreshed = records?.find(
      sigma => sigma?.isBeingRefreshed
    );
    const beingRefreshedEmailId = recordBeingRefreshed?.emailId;
    const freshRecord = records?.find(
      sigma =>
        sigma?.emailId === beingRefreshedEmailId && !sigma?.isBeingRefreshed
    );

    if (freshRecord) {
      const { data } = await patchSigma(
        recordBeingRefreshed?._id,
        {},
        { isDeleted: true }
      );
      setSigmas(records?.filter(sigma => sigma?._id !== data?._id));
      return;
    }

    setSigmas(records);
  };

  const doPatchSigmaRecord = async (id, newFields) => {
    await patchSigma(id, {}, newFields);
    await doPopulateSigmas();
    await doCalculateResults();
  };

  const doPopulateTemplateConfig = async () => {
    const { data: dashConfig } = await getTemplateDashboardConfig({
      wordDocId,
    });

    if (!dashConfig?.config?.layout) {
      const { data: initialWordDoc } = await getWordDoc(wordDocId);
      const sourceRecordsColumns =
        initialWordDoc?.content?.dashboardConfig?.columns;
      const newConfig = {
        topLayout: TOP_LAYOUT,
        layout: [
          [".", "."],
          [".", "."],
          ["source_records", "source_records"],
        ],
        components: [
          {
            id: "source_records",
            name: "Your Records",
            columns: [
              // {
              //   name: "emailSubject",
              // },
              // {
              //   name: "receivedAt",
              // },
              ...sourceRecordsColumns,
            ],
            pythonCode: "print(input())",
            records: [],
          },
        ],
      };
      const { data: newDashboardConfig } = await patchDashboardConfig(
        dashConfig?._id,
        {},
        {
          ...dashConfig,
          config: newConfig,
        }
      );
      setDashboardConfig(newDashboardConfig);
      setIsLoading(false);
      return;
    }

    setDashboardConfig(dashConfig);
    setIsLoading(false);
  };

  const doAddBlankComponent = async (x, y) => {
    setIsLoading(true);
    const id = uuidv4();
    const newComponent = {
      id,
      name: "",
      results: {},
    };

    const newLayoutDraggable = cloneDeep(
      dashboardConfig?.config?.layoutDraggable || {}
    );
    newLayoutDraggable[id] = { x, y, w: 20, h: 16 };
    // const newDashboardConfig = await patchDashboardConfig(
    //   dashboardConfig?._id,
    //   {},
    //   {
    //     ...dashboardConfig,
    //     config: {
    //       ...dashboardConfig?.config,
    //       components: [
    //         ...(dashboardConfig?.config?.components || []),
    //         newComponent,
    //       ],
    //       layoutDraggable: newLayoutDraggable,
    //     },
    //   }
    // );
    setDashboardConfig({
      ...dashboardConfig,
      config: {
        ...dashboardConfig?.config,
        components: [
          ...(dashboardConfig?.config?.components || []),
          newComponent,
        ],
        layoutDraggable: newLayoutDraggable,
      },
    });

    setInitiallyOpenComponentId(id);
    setIsLoading(false);
  };

  const doDeleteComponent = async id => {
    const payloadConfig = cloneDeep(dashboardConfig);
    payloadConfig.config.components = payloadConfig.config.components.filter(
      component => component?.id !== id
    );

    const newLayoutDraggable = cloneDeep(
      dashboardConfig?.config?.layoutDraggable || {}
    );
    delete newLayoutDraggable[id];
    payloadConfig.config.layoutDraggable = newLayoutDraggable;

    // payloadConfig.config.layout = payloadConfig.config.layout.map(row =>
    //   row.map(column => (column === id ? "." : column))
    // );
    // payloadConfig.config.topLayout = payloadConfig.config.topLayout.map(
    //   column => (column === id ? "." : column)
    // );

    const newDashboardConfig = await patchDashboardConfig(
      dashboardConfig?._id,
      {},
      payloadConfig
    );
    setDashboardConfig(newDashboardConfig?.data);
  };

  const updateComponent = (id, payload) => {
    const newDashboardConfig = cloneDeep(dashboardConfig);
    newDashboardConfig.config.components =
      newDashboardConfig.config.components.map(component => {
        if (component?.id === id) {
          return {
            ...component,
            ...payload,
          };
        }
        return component;
      });
    setDashboardConfig(newDashboardConfig);

    return newDashboardConfig;
  };

  const doPatchConfig = async payloadConfig => {
    setIsLoading(true);
    const newDashboardConfig = await patchDashboardConfig(
      dashboardConfig?._id,
      {},
      payloadConfig
    );
    setDashboardConfig(newDashboardConfig?.data);
    setIsLoading(false);
  };

  const doCalculateResults = async () => {
    setIsLoading(true);
    await postDashboardConfigCalculateResults(dashboardConfig?._id);
    await doPopulateTemplateConfig();
    setIsLoading(false);
  };

  const doRefreshSigmaRecord = async id => {
    const emailId = sigmas?.find(sigma => sigma?._id === id)?.emailId;

    await postReRunJobs(
      {},
      {
        ids: [emailId],
      }
    );
    await doPatchSigmaRecord(id, { isBeingRefreshed: true });
    await doPopulateSigmas();
  };

  const doInsertComponentInBlankSpace = async () => {
    if (!naturalLanguageCommand) {
      return;
    }
    let type = "Table";
    COMPONENT_TYPES?.forEach(componentType => {
      if (
        naturalLanguageCommand
          ?.toLowerCase()
          .includes(componentType?.toLowerCase())
      ) {
        type = componentType;
      }
    });

    const id = uuidv4();
    const cleanNlCommand = naturalLanguageCommand?.replaceAll(
      /, show as .*/g,
      ""
    );
    const newComponent = {
      id,
      name: "New component",
      naturalLanguageCommand: cleanNlCommand,
      type,
      pythonCode: "",
      results: {},
    };

    const { data } = await postGenerateComponent(
      {},
      {
        naturalLanguageCommand: getDsl(cleanNlCommand)?.replaceAll(
          "Received Time",
          "Received At"
        ),
      }
    );

    newComponent.pythonCode = data?.pythonCode;

    const newLayout = cloneDeep(dashboardConfig?.config?.layout);
    let hasBeenPlaced = false;
    newLayout.forEach(newRow => {
      newRow.forEach((newColumn, newColumnIndex) => {
        if (newColumn === "." && !hasBeenPlaced) {
          newRow[newColumnIndex] = id;
          hasBeenPlaced = true;
        }
      });
    });

    if (!hasBeenPlaced) {
      setMessage("No blank space to place component");
      return;
    }

    const newDashboardConfig = cloneDeep(dashboardConfig);
    newDashboardConfig.config.components.push(newComponent);
    newDashboardConfig.config.layout = newLayout;

    setNaturalLanguageCommand("");
    await doPatchConfig(newDashboardConfig);
    await doCalculateResults();
  };

  return (
    <Container>
      <TopBar>
        <Title>
          <Link to="/template-files">
            <SigmaIcon
              height="32px"
              style={{
                paddingLeft: "4px",
                paddingRight: "10px",
                fill: "grey",
              }}
            />
          </Link>
          <PulsingDot />
          {wordDoc?.fileName?.replace("template_", "")}
          <StyledFilesIcon
            height="18px"
            onClick={() => {
              navigator.clipboard.writeText(
                `Pipeline: ${wordDoc?.fileName?.replace("template_", "")}`
              );
            }}
          />
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            gap: "10px",
            width: "100%",
          }}
        >
          <AutocompleteTextInputDropdown
            value={naturalLanguageCommand}
            onChangeValue={newVal => setNaturalLanguageCommand(newVal)}
            placeholder="sum Loan Amount grouping by State"
            options={Object.keys(NL_TO_DSL)}
            onKeyDownAfterSelection={e => {
              if (e.key !== "Enter") {
                return;
              }
              doInsertComponentInBlankSpace();
            }}
            // onBlur={() => doGenerateCode(naturalLanguageCommand)}
            // isOptionClickDisabled
            // onKeyDown={e => {
            //   if (e.key === "Tab" && !naturalLanguageCommand) {
            //     e.preventDefault();
            //     setNaturalLanguageCommand(
            //       "sum loan amount grouping by state"
            //     );
            //   }
            // }}
          />
          <SmallButton
            style={{
              padding: "4px",
              opacity: naturalLanguageCommand ? 1 : 0,
              transition: "opacity 0.2s",
            }}
            onClick={doInsertComponentInBlankSpace}
            // value="Add"
            icon={<PlusIcon height="16px" />}
          />
        </div>
        <StyledNavHeader />
      </TopBar>
      <Gap height="0px" />
      <TopMessageAndButton>
        <div>
          Send emails to&nbsp;
          <BlueSpan>
            data@boltzbit.io
            <StyledFilesIcon
              height="8px"
              style={{
                fill: "#0191ff",
              }}
              onClick={() => {
                navigator.clipboard.writeText("data@boltzbit.io");
              }}
            />
          </BlueSpan>
          &nbsp;to ingest records
        </div>
        <TabLinks>
          <TabLinkItem isSelected to={`/dashboards/${wordDocId}`}>
            Dashboard
          </TabLinkItem>
          <TabLinkItem to={`/dashboards/${wordDocId}/source`}>
            Source
          </TabLinkItem>
        </TabLinks>
      </TopMessageAndButton>

      <Gap height="0px" />

      <div
        style={{
          position: "relative",
          padding: "0 32px",
          paddingRight: "48px",
        }}
      >
        <PencilContainer
          onClick={() => setIsEditingDashboard(!isEditingDashboard)}
        >
          {isEditingDashboard ? (
            <CrossIcon height="14px" />
          ) : (
            <PencilIcon height="16px" />
          )}
        </PencilContainer>
        <GridDraggable
          isEditingDisabled={!isEditingDashboard}
          style={{
            height: "calc(100vh - 140px)",
          }}
          initialLayout={dashboardConfig?.config?.layoutDraggable}
          onDragEnd={async newLayout => {
            const newDashboardConfig = await patchDashboardConfig(
              dashboardConfig?._id,
              {},
              {
                ...dashboardConfig,
                config: {
                  ...dashboardConfig?.config,
                  layoutDraggable: newLayout,
                },
              }
            );
            setDashboardConfig(newDashboardConfig?.data);
          }}
          onClickEmptyCell={({ x, y }) => doAddBlankComponent(x, y)}
        >
          {dashboardConfig?.config?.components
            ?.filter(comp => comp?.id !== "source_records")
            ?.map((component, index) => {
              // if (component?.id === "source_records") {
              //   return (
              //     <div key={component?.id}>
              //       <RecordsTable
              //         columns={component?.columns}
              //         onNewColumns={newColumns => {
              //           const newDashboardConfig = updateComponent(
              //             component?.id,
              //             {
              //               columns: newColumns,
              //             }
              //           );
              //           doPatchConfig(newDashboardConfig);
              //         }}
              //         records={sigmas?.filter(sig => !sig?.isDeleted)}
              //         onConfirmChangeRecord={async (id, newFields) => {
              //           await doPatchSigmaRecord(id, newFields);
              //         }}
              //         onClickRefreshRecord={id => doRefreshSigmaRecord(id)}
              //       />
              //     </div>
              //   );
              // }

              return (
                <div key={component?.id}>
                  <RecordsSummaryChart
                    isEditingDisabled={!isEditingDashboard}
                    isInitiallyOpen={component?.id === initiallyOpenComponentId}
                    onClose={async () => {
                      if (
                        component?.id === initiallyOpenComponentId &&
                        !isEmpty(component?.results)
                      ) {
                        const newDashboardConfig = await patchDashboardConfig(
                          dashboardConfig?._id,
                          {},
                          dashboardConfig
                        );
                        setDashboardConfig(newDashboardConfig?.data);
                      }

                      if (
                        component?.id === initiallyOpenComponentId &&
                        isEmpty(component?.results)
                      ) {
                        const newDashboardConfig = cloneDeep(dashboardConfig);
                        newDashboardConfig.config.components =
                          newDashboardConfig.config.components.filter(
                            comp => comp?.id !== component?.id
                          );
                        const newLayoutDraggable = cloneDeep(
                          dashboardConfig?.config?.layoutDraggable || {}
                        );
                        delete newLayoutDraggable[component?.id];
                        newDashboardConfig.config.layoutDraggable =
                          newLayoutDraggable;

                        setDashboardConfig(newDashboardConfig);
                      }

                      setInitiallyOpenComponentId(null);
                    }}
                    type={component?.type}
                    records={component?.results?.records}
                    columns={component?.results?.columns}
                    backgroundColor={component?.backgroundColor}
                    name={component?.name}
                    onNewName={async newName => {
                      const newDashboardConfig = updateComponent(
                        component?.id,
                        {
                          name: newName,
                        }
                      );
                      await doPatchConfig(newDashboardConfig);
                    }}
                    naturalLanguageCommand={component?.naturalLanguageCommand}
                    pythonCode={component?.pythonCode}
                    onConfirmEdit={async newComponent => {
                      const newDashboardConfig = updateComponent(
                        component?.id,
                        newComponent
                      );
                      await doPatchConfig(newDashboardConfig);
                      await doCalculateResults();
                    }}
                    onClickCross={() => doDeleteComponent(component?.id)}
                  />
                </div>
              );
            })}
        </GridDraggable>
      </div>

      {/* <Gap height="100px" /> */}
      <ErrorMessageModal
        color="#d4a600"
        message={message}
        onDismiss={() => setMessage("")}
      />
    </Container>
  );
};

export default DashboardPage;
