import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { parseJson } from "utils/common";
import DirectoryItemsList from "components/DirectoryItemsList";
import ButtonIcon from "components/ui/ButtonIcon";
import TooltipClick from "components/ui/TooltipClick";
import { PlusIcon } from "components/ui/Icons";
import DirectoryItemsListPipelines from "components/DirectoryItemsListPipelines";

const SideBar = styled.div`
  width: 300px;
  position: fixed;
  top: 0;
  background-color: #f7f8fc;
  height: 100vh;
  overflow: auto;
  z-index: 200;
  border-right: 1px solid ${props => props.theme.color.closer1}77;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DirectoryHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 200;
  background-color: #f7f8fc;
  padding: 10px 8px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  height: auto;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
`;

const BtnText = styled.span``;

const FileSideBar = () => {
  const [searchParams] = useSearchParams();

  const openSidebarType = parseJson(searchParams.get("openSidebarType"));

  return (
    <>
      {openSidebarType === "docs" && (
        <SideBar>
          <DirectoryItemsList
            renderHeaderComponent={({ tipContent }) => (
              <DirectoryHeaderContainer>
                <TooltipClick
                  tipStyle={{ marginLeft: "-282px" }}
                  tipContent={tipContent}
                >
                  <StyledButtonIcon isActive>
                    <PlusIcon height="10px" />
                    <BtnText>New workspace</BtnText>
                  </StyledButtonIcon>
                </TooltipClick>
              </DirectoryHeaderContainer>
            )}
            isHeaderSticky
            isInitiallyOpen
            isDeleteActionHidden
            directoryPath="/working-files"
            isIconDisabled
            folderActions={["new_word_doc", "upload_word_doc"]}
          />
        </SideBar>
      )}

      {openSidebarType === "sources" && (
        <SideBar>
          <DirectoryItemsList
            renderHeaderComponent={({ tipContent }) => (
              <DirectoryHeaderContainer>
                <TooltipClick
                  tipStyle={{ marginLeft: "-282px" }}
                  tipContent={tipContent}
                >
                  <StyledButtonIcon isActive>
                    <PlusIcon height="10px" />
                    <BtnText>New source</BtnText>
                  </StyledButtonIcon>
                </TooltipClick>
              </DirectoryHeaderContainer>
            )}
            isHeaderSticky
            isInitiallyOpen
            isDeleteActionHidden
            directoryPath="/source-files"
            isIconDisabled
            folderActions={["upload_files", "send_email", "upload_audio"]}
          />
        </SideBar>
      )}

      {openSidebarType === "pipelines" && (
        <SideBar>
          <DirectoryItemsListPipelines
            renderHeaderComponent={({ tipContent }) => (
              <DirectoryHeaderContainer>
                <TooltipClick
                  tipStyle={{ marginLeft: "-282px" }}
                  tipContent={tipContent}
                >
                  <StyledButtonIcon isActive>
                    <PlusIcon height="10px" />
                    <BtnText>New pipeline</BtnText>
                  </StyledButtonIcon>
                </TooltipClick>
              </DirectoryHeaderContainer>
            )}
            isHeaderSticky
            isInitiallyOpen
            isDeleteActionHidden
            directoryPath="/template-files"
            isIconDisabled
          />
        </SideBar>
      )}
    </>
  );
};

export default FileSideBar;
