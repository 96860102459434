import { useEffect, useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import TooltipNew from "components/ui/TooltipNew";
import { getFilePageResults } from "api/services/filesService";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import ButtonWord from "components/ui/ButtonWord";

export const useIsDebug = (initialValue = false) => {
  const [isDebug, setIsDebug] = useState(initialValue);

  useEffect(() => {
    const toggleDebug = e => {
      if (e.key === "D" && e.shiftKey) {
        setIsDebug(prev => !prev);
      }
    };

    document.addEventListener("keydown", toggleDebug);
    return () => document.removeEventListener("keydown", toggleDebug);
  }, []);

  return isDebug;
};

const Span = styled.span`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const ModalContent = styled.div`
  background-color: white;
  width: 800px;
  height: 600px;
  display: grid;
  align-content: start;
  white-space: pre-wrap;
  overflow: auto;
  background-color: #eaeaea;
`;

const StickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: 8px;
  border-bottom: 1px solid #eaeaea;
`;

const FileLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  background-color: white;
  padding: 8px;
`;

const ImgWithRectangleFlexibleStyled = styled(ImgWithRectangleFlexible)``;

const ReferenceModalSpan = ({
  style = {},
  meta = {},
  onClickRegenerate = null,
  children,
  isTooltipDisabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const isDebug = useIsDebug();
  const [pageResults, setPageResults] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    doPopulatePageResults();
  }, [isOpen, meta?.fileId, meta?.pageNumber]);

  const doPopulatePageResults = async () => {
    const { data, error } = await getFilePageResults(
      meta?.fileId,
      meta?.pageNumber
    );
    setPageResults(data);
    setError(error);
    console.log("pageResults", { data });
  };

  return (
    <>
      {isTooltipDisabled ? (
        <Span
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {children}
        </Span>
      ) : (
        <TooltipNew style={style} tipText="Click to see reference">
          <Span onClick={() => setOpen(true)}>{children}</Span>
        </TooltipNew>
      )}
      <Modal open={isOpen} handleClose={() => setOpen(false)}>
        <ModalContent>
          {isDebug && JSON.stringify({ meta, error }, null, 2)}
          <StickyTop>
            <FileLink
              href={`/files/${meta?.fileId}?pageNumber=${meta?.pageNumber}`}
              target="_blank"
            >
              {meta?.fileName} (page {meta?.pageNumber + 1})
            </FileLink>
            {typeof onClickRegenerate === "function" && (
              <ButtonWord onClick={onClickRegenerate}>Regenerate</ButtonWord>
            )}
          </StickyTop>

          <div style={{ margin: "12px 40px" }}>
            <ImgWithRectangleFlexibleStyled
              isLoading={!pageResults?.image}
              rectangles={meta?.references}
              src={`data:image/png;base64,${pageResults?.image || ""}`}
              alt="page preview"
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferenceModalSpan;
