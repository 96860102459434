import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Edit, Email, Functions, GridOn, Title } from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { deleteFileById } from "api/services/filesService";
import { deleteDirectory, getDirectoryItems } from "api/services/fileSystem";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import { getLoggedInUserName } from "api/services/authenticationService";

import { CenteredWithTopNavLayout } from "components/Layout";
import useSearchParamsState from "hooks/useSearchParamsState";
import FileActionsDropdown from "components/FileActionsDropdown";
import { isTruthy } from "utils/common";
import MoveItemsToDirectoryModalTrigger from "components/MoveItemsToDirectoryModalTrigger";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";
import useDirectoryPagination from "hooks/useDirectoryPagination";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import { format } from "date-fns";
import { deleteExcelModelById } from "api/services/excelModelsService";
import { deleteWordDoc } from "api/services/wordDocsService";
import { ExcelIcon, FilesIcon, WordIcon } from "components/ui/Icons";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Tooltip from "components/ui/Tooltip";
import PipelineNameModalTrigger from "components/PipelineNameModalTrigger";
import PipelineNameModalTriggerNew from "components/PipelineNameModalTriggerNew";
import {
  deleteKnowledgeConfig,
  getKnowledgeConfigs,
} from "api/services/chatService";
import KnowledgeConfigEditorModal from "components/KnowledgeConfigEditorModal";

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding-bottom: 100px;
`;

const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const BigTitle = styled(Link)`
  font-size: 24px;
  font-weight: 600;

  text-decoration: none;
  color: ${props => props.theme.color.closest};
`;

const DirectoryPath = styled.div`
  font-size: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Td = styled.td`
  max-width: 780px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 22px;
  background-color: ${props => props.theme.color.closer0};
  vertical-align: middle;
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const HeaderTr = styled.tr`
  color: white;
  background: linear-gradient(
    to right,
    ${props => props.theme.color.feature},
    ${props => props.theme.color.primary}
  );
  font-weight: 600;

  ${Td} {
    background-color: transparent;
  }
`;

const IconAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ItemName = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleAndPath = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DirLink = styled(Link)`
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CreationBtns = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 0;
`;

const LinkToTemplate = ({ directoryPath, children }) => {
  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    doPopulateTemplateId();
  }, [directoryPath]);

  const doPopulateTemplateId = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setTemplateId(data?.items?.find(item => item?.isTemplate)?.id);
  };

  return (
    <IconAndName target="_blank" href={`/dashboards/${templateId}`}>
      {children}
    </IconAndName>
  );
};

const KnowledgeConfigsPage = () => {
  const loggedInUserName = getLoggedInUserName();
  const navigate = useNavigate();

  const [directoryPath] = useSearchParamsState({
    paramName: "directoryPath",
    initialValue: "/template-files",
  });
  const [directoryItems, setDirectoryItems] = useState([]);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);

  const [
    pageSize,
    offset,
    prevUrl,
    nextUrl,
    hasMore,
    setNextOffset,
    setHasMore,
  ] = useDirectoryPagination();

  useEffect(() => {
    doPopulateDirectoryItems();
  }, [directoryPath]);

  const doPopulateDirectoryItems = async () => {
    setDirectoryItems([]);
    const { data } = await getKnowledgeConfigs();
    setDirectoryItems(data || []);
  };

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?._id);

    const { error } = await deleteKnowledgeConfig(item?._id);

    setItemIdBeingDeleted(null);
    if (error) {
      setError(error);
      return;
    }
    setDirectoryItems(
      directoryItems.filter(dirItem => dirItem?._id !== item?._id)
    );
  };

  const dirNames = directoryPath?.split("/");
  const parentDirectoryPath = dirNames.slice(0, -1).join("/");

  return (
    <StyledCenteredWithTopNavLayout>
      <TopContent>
        <TitleAndPath>
          <BigTitle to="/knowledge-configs">Knowledge Configs</BigTitle>
          <DirectoryPath>
            {dirNames?.length > 6 && "..."}
            {dirNames
              .filter(isTruthy)
              .filter(dirName => dirName !== "template-files")
              .map((dirName, i) => {
                if (dirNames?.length > 5 && i + 3 < dirNames?.length - 3) {
                  return null;
                }

                return (
                  <Fragment key={i}>
                    &nbsp;/&nbsp;
                    <DirLink
                      to={`?directoryPath=${dirNames
                        ?.slice(0, i + 3)
                        .join("/")}`}
                    >
                      {dirName}
                    </DirLink>
                  </Fragment>
                );
              })}
          </DirectoryPath>
        </TitleAndPath>
      </TopContent>

      <CreationBtns>
        <Tooltip title="New knowledge config">
          <KnowledgeConfigEditorModal
            triggerIcon={<ButtonCreateNewAsset />}
            onSave={newConfig => {
              setDirectoryItems([...directoryItems, newConfig]);
            }}
          />
        </Tooltip>
      </CreationBtns>

      <Table>
        <thead>
          <HeaderTr>
            <Td>Name</Td>
            <Td>Created at</Td>
            <Td>Owner</Td>
            <Td></Td>
          </HeaderTr>
        </thead>
        <tbody>
          {directoryItems?.map((item, i) => {
            let itemIcon = <FilesIcon style={{ fill: "#731dff" }} />;
            let itemName = item?.topic;
            let itemUrl = `/template-files?directoryPath=${directoryPath}`;

            const fileActions =
              item?.createdBy === loggedInUserName ? ["Delete"] : [];

            return (
              <Tr
                isDisabled={itemIdBeingDeleted === item?.id}
                key={`${item?.id}-${i}`}
              >
                <Td width="100%">
                  <IconAndName target="_blank" href={itemUrl}>
                    {itemIcon}
                    <KnowledgeConfigEditorModal
                      config={item}
                      onSave={newConfig => {
                        setDirectoryItems(
                          directoryItems.map(dirItem => {
                            if (dirItem?._id === item?._id) {
                              return newConfig;
                            }
                            return dirItem;
                          })
                        );
                      }}
                    />{" "}
                  </IconAndName>
                </Td>
                <Td>
                  {item?.createdAt
                    ? format(new Date(item?.createdAt), "d MMM yyyy")
                    : ""}
                </Td>
                <Td>
                  {item?.createdBy === loggedInUserName
                    ? "me"
                    : item?.createdBy}
                </Td>
                <Td>
                  <ActionsContainer>
                    <FileActionsDropdown
                      visibleActions={["Delete"]}
                      onClickDelete={() => doDeleteItem(item)}
                    />
                  </ActionsContainer>
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <PrevNextPageButtons
        onClickPrev={() => navigate(prevUrl)}
        isPrevDisabled={!offset}
        onClickNext={() => navigate(nextUrl)}
        isNextDisabled={!hasMore}
      />
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </StyledCenteredWithTopNavLayout>
  );
};

export default KnowledgeConfigsPage;
