import { zapiGet, zapiPatch } from "api/zapi";

export const getWebPage = async id => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/web-pages/${id}`
  );
  return { data, error };
};

export const patchWebPage = async (id, params, body) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/web-pages/${id}`,
    params,
    body
  );
  return { data, error };
};
