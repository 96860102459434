import { zapiDelete, zapiGet, zapiPost } from "api/zapi";

export const getSizerConfigs = async () => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/cc/configs/sizer`);
  return { data, error };
};

export const postSizerFillUp = async params => {
  const { data, error } = await zapiPost(`/solutions/ocr/api/v1/cc/excel-models/sizer-fill-up`, params);
  return { data, error };
};

export const getExcelModelSizerFillUpProgress = async excelModelId => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/cc/excel-models/sizer-fill-up-progress/${excelModelId}`);
  return { data, error };
};

export const getExcelAnnotationsByFileId = async fileId => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/cc/excel/annotations/${fileId}`);
  return { data, error };
};

export const postExcelAnnotationForFile = async (fileId, annotation) => {
  const { data, error } = await zapiPost(`/solutions/ocr/api/v1/cc/excel/annotations/${fileId}/add`, null, annotation);
  return { data, error };
};

export const deleteExcelAnnotation = async annotationId => {
  const { error } = await zapiDelete(`/solutions/ocr/api/v1/cc/excel/annotations/${annotationId}`);
  return { error };
};
