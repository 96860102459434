import { useState, useEffect } from "react";
import styled from "styled-components";

import TextInput from "components/inputs/TextInput";
import {
  getPlatformLoginToken,
  resetPassword,
} from "api/services/authenticationService";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { Error } from "@material-ui/icons";
import { setLoginTokenInClientStorage } from "utils/auth-utils";

const ModalContent = styled.div`
  width: 500px;
`;

const Gap = styled.div`
  height: 10px;
`;

const Logo = styled.img`
  margin: 0;
  width: 50px;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5px;
`;

const LoginLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 15px;
`;

const LoginLinkDiv = styled.div`
  text-decoration: underline;
  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const ForgotPasswordLinkDiv = styled.div`
  text-decoration: underline;
  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  padding: 15px;
  color: ${props => props.theme.color.error};
  width: 100%;
  justify-content: center;
`;

const EmailMesssage = styled.div`
  display: flex;
  padding: 15px;
  color: ${props => props.theme.color.primary};
  width: max-content;
  width: 100%;
  justify-content: center;
`;

const Form = styled.form`
  width: 400;
  padding: 35px 50px 35px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const CheckSpamMsg = styled.div`
  max-width: 400px;
  margin-top: 10px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  ${props => !props.isVisible && "pointer-events: none;"};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${props => props.theme.color.feature};
  padding: 5px;
  border-radius: 5px;
  width: max-content;
`;

const LoginModal = ({ open, handleClose }) => {
  const [inputUserName, setInputUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [emailMesssage, setEmailMessage] = useState("");
  const [notificationMsg, setNotificationMsg] = useState(
    "Your login may be in your spam."
  );

  useEffect(() => {
    setError(null);
    setEmailMessage("");
    setIsLoading(false);
    setPassword("");
  }, [forgotPasswordMode]);

  const logInAndRedirect = async e => {
    setIsLoading(true);
    e.preventDefault();
    const { data, error } = await getPlatformLoginToken(
      inputUserName,
      password
    );

    setIsLoading(false);
    if (error) {
      setError(error);
      return;
    }

    setLoginTokenInClientStorage(data.accessToken);
    window.location = "/";
  };

  const doResetPassword = async e => {
    setIsLoading(true);
    e.preventDefault();
    const { error } = await resetPassword(inputUserName);
    if (error) {
      setError(error);
      return;
    }
    setNotificationMsg(
      "A new password has been sent to your email. Check your spam."
    );
    setForgotPasswordMode(false);
    setIsLoading(false);
  };

  const switchMode = () => {
    setForgotPasswordMode(!forgotPasswordMode);
  };

  return (
    <Modal
      open={open}
      handleClose={() => {
        setForgotPasswordMode(false);
        setInputUserName("");
        handleClose();
      }}
    >
      <ModalContent>
        {forgotPasswordMode ? (
          <Form onSubmit={doResetPassword}>
            <Logo src={`/images/logo-black.png`} />
            <Gap height="18px" />
            <TextInput
              title="Username"
              value={inputUserName}
              onNewInput={newUsername => setInputUserName(newUsername)}
              isRequired
            />
            <Gap />
            <ErrorMessage>{error?.message}</ErrorMessage>
            <EmailMesssage>{emailMesssage}</EmailMesssage>
            <ButtonContainer>
              <Button
                type="submit"
                value={isLoading ? "Loading..." : "Reset Password"}
              />
            </ButtonContainer>
          </Form>
        ) : (
          <Form onSubmit={logInAndRedirect}>
            <Logo src={`/images/logo-black.png`} />
            <Gap height="20px" />
            <TextInput
              title="Username"
              value={inputUserName}
              onNewInput={newUsername => setInputUserName(newUsername)}
              isRequired
            />
            <ForgotPasswordContainer>
              <ForgotPasswordLinkDiv
                onClick={() => {
                  switchMode();
                }}
              >
                forgot password{" "}
              </ForgotPasswordLinkDiv>
            </ForgotPasswordContainer>

            <Gap />
            <TextInput
              title="Password"
              value={password}
              type="password"
              onNewInput={newPassword => setPassword(newPassword)}
              isRequired
            />
            <ErrorMessage>{error?.message}</ErrorMessage>
            <ButtonContainer>
              <Button
                type="submit"
                value={isLoading ? "Loading..." : "Login"}
              />
            </ButtonContainer>
            <CheckSpamMsg isVisible>
              <Error />
              {notificationMsg}
            </CheckSpamMsg>
          </Form>
        )}
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
