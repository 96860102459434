import { postLogin } from "api/services/authenticationService";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { setLoginTokenInClientStorage } from "utils/auth-utils";

const useLoginFromUrlParams = () => {
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [searchParams] = useSearchParams();

  const userEmail = searchParams.get("userEmail");
  const password = searchParams.get("password");

  useEffect(() => {
    if (userEmail && password) {
      doLoginWithEmailAndPassword(userEmail, password);
      return;
    }
  }, [userEmail, password]);

  const doLoginWithEmailAndPassword = async (email, password) => {
    const { data, error } = await postLogin({}, { userName: email, password });
    if (!error) {
      setLoginTokenInClientStorage(data.accessToken);
      setHasLoggedIn(true);
    }
  };

  return hasLoggedIn;
};

export default useLoginFromUrlParams;
