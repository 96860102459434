import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { cloneDeep, last } from "lodash";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import {
  postWordDoc,
  postWordDocsIdSaveAsTemplate,
} from "api/services/wordDocsService";
import { COLUMN_TYPES } from "components/DashboardColumnModalTrigger";
import { postPipelineConfigs } from "api/backend/fileSystemEndpoints";
import { ChevronLeft } from "@material-ui/icons";
import ButtonWord from "components/ui/ButtonWord";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  gap: 15px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 32px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
  z-index: 1;

  position: sticky;
  top: 0px;
`;

const TableContainer = styled.div`
  min-width: 800px;
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 180px;
  padding: 0;
`;

const StyledInput = styled(StyledBoldInput)`
  font-weight: 400;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const INITIAL_COLUMNS = [
  {
    name: "Introduction",
    description: "Summarise the attached document",
  },
];

const INITIAL_COLUMN_NAMES = INITIAL_COLUMNS.map(column => column.name);

const TemplateEditorModal = ({
  trigger = null,
  template = {},
  onChangeTemplate = newTemplate => {},
}) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [columns, setColumns] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setColumns(template?.sections || []);
  }, [isOpen]);

  useEffect(() => {
    onChangeTemplate({ ...template, sections: columns });
  }, [JSON.stringify(columns)]);

  const doSaveDocAsTemplate = async () => {};

  return (
    <>
      <div onClick={() => setIsOpen(true)}>
        {trigger || <ButtonCreateNewAsset />}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Title>Title</Title>
          {/* <input
            value={template?.title}
            onChange={e =>
              onChangeTemplate({ ...template, title: e.target.value })
            }
          /> */}
          <StyledTextInputSquare
            isDisabled
            placeholder="Deal Extractor"
            value={template?.title}
            onNewInput={newText => {
              onChangeTemplate({ ...template, title: newText });
            }}
          />
          <Gap />
          Add sections and prompts
          <TableContainer>
            <table>
              <tr>
                <Th>Section Title</Th>
                <Th style={{ width: "100%" }}>Section Prompt</Th>
              </tr>
              {columns?.map((column, i) => (
                <tr>
                  <Td key={i}>
                    <StyledBoldInput
                      value={column?.name}
                      placeholder="Section Title"
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].name = e.target.value;
                        setColumns(newColumns);
                      }}
                    />
                    <StyledTrashIcon
                      onClick={() => {
                        const newColumns = cloneDeep(columns);
                        newColumns.splice(i, 1);
                        setColumns(newColumns);
                      }}
                    />
                  </Td>
                  <Td>
                    <StyledInput
                      style={{
                        width: "100%",
                      }}
                      value={column?.description}
                      placeholder="Section Description"
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].description = e.target.value;
                        setColumns(newColumns);
                      }}
                    />
                  </Td>
                </tr>
              ))}
              <tr>
                <Td style={{ border: "none" }}>
                  <StyledPlusIcon
                    onClick={() => {
                      setColumns([
                        ...columns,
                        {
                          name: "",
                          description: "",
                          type: "TEXT",
                        },
                      ]);
                    }}
                  />
                </Td>
              </tr>
            </table>
          </TableContainer>
          {/* <Gap />
          <StyledButton
            variant="highlighted"
            value="Create"
            onClick={doSaveDocAsTemplate}
          /> */}
          <ButtonWord
            onClick={() => setIsOpen(false)}
            style={{ justifySelf: "start" }}
          >
            Back
          </ButtonWord>
          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default TemplateEditorModal;
