import styled from "styled-components";

const SelectorOption = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  border: 0.5px solid ${props => props.theme.color.closer1_5};

  ${props =>
    props.isSelected &&
    `background-color: ${props.theme.color.closer1};
    color: ${props.theme.color.primary};`};

  ${props => props.isDisabled && "opacity: 0.3; pointer-events: none;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
    color: ${props => props.theme.color.primary};
  }
`;

const SelectorContainer = styled.div`
  width: max-content;
  background-color: ${props => props.theme.color.closer0};
  border: 0.5px solid ${props => props.theme.color.closer1_5};
  display: flex;
  align-items: center;
  height: fit-content;
  border-radius: 5px;

  margin-right: 10px;

  ${SelectorOption}:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  ${SelectorOption}:last-of-type {
    border-radius: 0 5px 5px 0;
  }
`;

const MultiOptionSelector = ({
  options = [],
  disabledOptions = [],
  selectedOption,
  onOptionSelect,
  optionStyle,
  className,
}) => {
  if (options.every(option => typeof option === "string")) {
    return (
      <SelectorContainer className={className}>
        {options.map(option => (
          <SelectorOption
            key={option}
            isDisabled={disabledOptions.includes(option)}
            isSelected={selectedOption === option}
            onClick={() => onOptionSelect(option)}
            style={optionStyle}
          >
            {option}
          </SelectorOption>
        ))}
      </SelectorContainer>
    );
  }

  return (
    <SelectorContainer className={className}>
      {options.map(option => (
        <SelectorOption
          key={option.value}
          isDisabled={disabledOptions.includes(option)}
          isSelected={selectedOption?.value === option.value}
          onClick={() => onOptionSelect(option.value)}
          style={optionStyle}
        >
          {option.icon}
        </SelectorOption>
      ))}
    </SelectorContainer>
  );
};

export default MultiOptionSelector;
