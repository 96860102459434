import styled from "styled-components";
import { useState, useEffect } from "react";
import TextInputSquare from "components/inputs/TextInputSquare";
import { updateUser } from "api/services/authenticationService";
import Button from "components/ui/Button";

const Container = styled.div``;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const ErrorMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.color.error};
`;

const Message = styled.div`
  color: ${props => props.theme.color.primary};
`;

const ButtonContainer = styled.div`
  position: relative;
  padding-top: 40px;
`;

const PasswordView = ({ user }) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassowrd] = useState("");

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const doUpdateUser = async () => {
    if (password === repeatPassword) {
      const userObj = {
        id: user,
        password,
      };
      const { error } = await updateUser(user, userObj);
      if (error) {
        setError(error);
      } else {
        setMessage("Password successfully updated. ");
      }
    } else {
      setError({ message: "Passwords must match." });
    }
  };

  useEffect(() => {
    setRepeatPassowrd("");
    setMessage("");
    setError(null);
  }, [password]);

  return (
    <Container>
      <Form>
        <TextInputSquare
          titleAbove="New Password"
          value={password}
          onNewInput={newPassword => setPassword(newPassword)}
          type="password"
          isRequired
        />
        <TextInputSquare
          titleAbove="Repeat New Password"
          value={repeatPassword}
          onNewInput={newPassword => setRepeatPassowrd(newPassword)}
          type="password"
          isRequired
        />

        <ButtonContainer>
          <Button
            onClick={() => {
              doUpdateUser();
            }}
            type="button"
            value={isLoading ? "Loading..." : "Update"}
          />
        </ButtonContainer>
        <ErrorMessage>{error?.message}</ErrorMessage>
        <Message>{message} </Message>
      </Form>
    </Container>
  );
};

export default PasswordView;
