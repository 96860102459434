import { apiPostFormDataGetBlob } from "api/api-http-methods";
import {
  zapiDelete,
  zapiGet,
  zapiPatch,
  zapiPost,
  zapiPostFile,
} from "api/zapi";

export const getSigmaConfigs = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/sigma/configs`,
    params
  );
  return { data, error };
};

export const patchSigmaConfig = async (id, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/sigma/configs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const getSigmaTableConfigs = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/sigma/table-configs`,
    params
  );
  return { data, error };
};

export const getSigmaTableConfig = async (id, params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/sigma/table-configs/${id}`,
    params
  );
  return { data, error };
};

export const deleteSigmaTableConfig = async id => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/sigma/table-configs/${id}`
  );
  return { data, error };
};

export const postSigmaTableConfig = async (params = {}, body) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/table-configs`,
    params,
    body
  );
  return { data, error };
};

export const patchSigmaTableConfig = async (id, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/sigma/table-configs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const postExtractSigmaConfigsFromFile = async (params = {}, file) => {
  const { data, error } = await zapiPostFile(
    "/solutions/ocr/api/v1/sigma/upload-excel",
    params,
    file
  );
  return { data, error };
};

export const postRunWithTableConfig = async (params = {}, body) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/table-configs/run-with-table-config`,
    params,
    body
  );
  return { data, error };
};

export const postRunSigmaTableConfig = async (id, params = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/table-configs/${id}/run`,
    params
  );
  return { data, error };
};

export const postSigmaRule = async body => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/rules`,
    {},
    body
  );
  return { data, error };
};

export const postSigmaResultsDownload = async (params = {}, formDataBody) => {
  const { data: excelFileBlob, error } = await apiPostFormDataGetBlob(
    "/solutions/ocr/api/v1/sigma/results/download",
    params,
    formDataBody
  );

  const href = URL.createObjectURL(excelFileBlob);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", "filled_table.xlsx");
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  return { data: null, error };
};

export const postTableConfigFromConversation = async (
  conversationId,
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/table-configs/conversations/${conversationId}`,
    params,
    body
  );
  return { data, error };
};

export const postSigmaRerunEmailJobs = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/rerun-email-jobs`,
    params,
    body
  );
  return { data, error };
};

export const postSigmaRerunExtractionJobs = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/sigma/rerun-extraction-jobs`,
    params,
    body
  );
  return { data, error };
};
