import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "title filter"
    "selection-buttons selection-buttons"
    "options options";
  gap: 2.5px;
  ${props => props.isDisabled && `opacity: 0.5;pointer-events: none;`}
`;

const CrossButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: max-content;
  opacity: 0;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const Option = styled.div`
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
  ${props => props.isSelected && `background-color: ${props.theme.color.primary}33;`}
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  :hover {
    background-color: ${props => props.theme.color.closer0};
    ${CrossButtonContainer} {
      opacity: 1;
    }
  }
`;

const OptionsContainer = styled.div`
  grid-area: options;
  max-height: ${props => props.maxOptionsHeight};
  overflow: auto;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Title = styled.div`
  grid-area: title;
  font-weight: 900;
  align-self: end;
  max-width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: flex;
  gap: 10px;
  align-items: center;
`;

const FilterInput = styled.input`
  grid-area: filter;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
`;

const SingleSelect = ({
  isDisabled = false,
  className,
  options = [{ label: "", value: "" }],
  title,
  maxOptionsHeight = "300px",
  selectedValue,
  onSelectOption = newSelectedOptionValue => {},
}) => {
  const [filterText, setFilterText] = useState("");

  const filteredOptions = options?.filter(option => option?.label?.toLowerCase()?.includes(filterText?.toLowerCase()));

  return (
    <Container className={className} isDisabled={isDisabled}>
      {title && <Title>{title}</Title>}
      <FilterInput value={filterText} onChange={e => setFilterText(e.target.value)} />
      <OptionsContainer maxOptionsHeight={maxOptionsHeight}>
        {filteredOptions?.map((option, i) => (
          <Option
            isSelected={option?.value === selectedValue}
            onClick={() => {
              onSelectOption(option?.value);
            }}
            key={`${option?.value}-${i}`}
          >
            {option.label}
          </Option>
        ))}
      </OptionsContainer>
    </Container>
  );
};

export default SingleSelect;
