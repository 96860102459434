import { chartColours } from "App";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { formatLongNumber } from "utils/common";

const Container = styled.div`
  position: relative;
  height: 300px;
  padding-top: 28px;
`;

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

/*
column = {
  name: ...
}

record = {
  [columnName]: {
    value: ...
  }
}
*/
const RecordsSummaryMultiBarChart = ({ records = [], columns = [] }) => {
  let categoryName = columns?.[0]?.name || "";
  const barsData = records?.map(record => {
    const barData = {
      [categoryName]: record?.[categoryName]?.value,
    };
    columns.forEach(column => {
      barData[column?.name] = record?.[column?.name]?.value;
    });

    return barData;
  });

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={barsData} margin={{}}>
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={categoryName}
            tickFormatter={tick => {
              if (`${tick}`.length > 23) {
                return `${tick}`.slice(0, 16) + "...";
              }
              return tick;
            }}
            // tick={{ width: 200 }}
            // tick={({ payload }) => <b>{payload?.value}</b>}
          />
          <YAxis
            tickFormatter={tick => {
              return formatLongNumber(tick);
            }}
          />
          {columns?.slice(1)?.map((column, i) => (
            <Bar
              dataKey={column?.name}
              fill={chartColours[i] || "#0191ff"}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default RecordsSummaryMultiBarChart;
