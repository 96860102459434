import styled from "styled-components";

const ButtonIcon = styled.div`
  background-color: #dedede;
  border-radius: 4px;

  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 6px;

  color: #464646;
  cursor: pointer;
  svg {
    fill: #464646;
  }

  :hover {
    opacity: 0.7;
  }

  ${props =>
    props.isActive &&
    `
    background-color: #497FF3;
    color: white;
    box-shadow: 0px 0px 2px #759CFF;
    svg {
      fill: white;
    }  
  `}

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.3;
    pointer-events: none;
    box-shadow: none;
    background-color: #b9b9b9;
  `}
`;

export const ButtonIconTransparent = styled.div`
  background-color: transparent;
  border-radius: 4px;

  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 6px;

  color: #464646;
  cursor: pointer;
  svg {
    fill: #464646;
  }

  :hover {
    background-color: #eaeaea;
    color: white;
    /* box-shadow: 0px 0px 2px #759cff; */
    /* svg {
      fill: white;
    } */
  }

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}
`;

export default ButtonIcon;
