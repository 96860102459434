import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  getLoggedInUserName,
  registerTrialUser,
} from "api/services/authenticationService";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import { postFlowDatabaseTemplatesBuild } from "api/services/chatService";

const ResolvePage = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();

  const doResolveTemplate = async () => {
    const userName = getLoggedInUserName();

    if (!userName) {
      const { data } = await registerTrialUser();
      setLoginTokenInClientStorage(data.accessToken);
    }

    const { data } = await postFlowDatabaseTemplatesBuild(templateId);

    navigate(`/intro?wordDocId=${data?.wordDocId}`);
  };

  useEffect(() => {
    doResolveTemplate();
  }, [templateId]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {/* <LogoInner
        style={{
          height: "50px",
          opacity: 0.4,
        }}
      /> */}
    </div>
  );
};

export default ResolvePage;
