import { useState } from "react";
import styled from "styled-components";

import { CrossIcon } from "components/ui/Icons";
import ErrorMessageModal from "components/ErrorMessageModal";
import BlackTooltip from "components/ui/BlackTooltip";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const COLORS = [
  "#7aecec",
  "#bfeeb7",
  "#feca74",
  "#ff9561",
  "#aa9cfc",
  "#c887fb",
  "#9cc9cc",
  "#ffeb80",
  "#ff8197",
  "#ff8197",
  "#f0d0ff",
  "#8bd2c1",
  "#bfe1d9",
  "#c8d387",
];

const getColorFromString = string => {
  const digits = 1;
  var m = Math.pow(10, digits + 1) - 1;
  var phi = Math.pow(10, digits) / 2 - 1;
  var n = 0;
  for (var i = 0; i < string.length; i++) {
    n = (n + phi * string.charCodeAt(i)) % m;
  }
  const zeroTo100 = n.toString();

  const colorIndex = Math.floor((zeroTo100 / 100) * COLORS.length);

  return COLORS?.[colorIndex] || "#e1e1e1";
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}

  ${props =>
    props.isExpandable &&
    !props.isExpanded &&
    `
      overflow: hidden;
      flex-wrap: nowrap;
      width: 100%;
  `};
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 8px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  cursor: pointer;
`;

const Tag = styled.div`
  width: max-content;
  padding: 4px;
  padding-left: 13px;
  background-color: ${props => props.bgColor}44;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  border: 2px solid;
  border-image: linear-gradient(
    ${props => props.bgColor},
    ${props => props.bgColor}
  );

  ${props =>
    props.isHighlighted &&
    `
    font-weight: 600;
    color: ${props.theme.color.primary};
    background-color: ${props.theme.color.primary}22;
    border-image: linear-gradient(
      ${props.theme.color.primary},
      ${props.theme.color.primary}
    );
  `}

  :hover {
    ${StyledCrossIcon} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

const StyledBlackTooltip = styled(BlackTooltip)`
  transform: translateX(0) translateY(0);
  left: 0;
  background-color: ${props => props.theme.color.furthest};
  color: ${props => props.theme.color.closer2};
  border-radius: 0;
  padding: 2px;
`;

const IconButtonContainer = styled.div`
  position: absolute;
  right: 0;
  background-color: ${props => props.theme.color.furthest};
  z-index: 11;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  ${props => props.isExpanded && "bottom: 0;"}
`;

const MetaTagsViewer = ({
  metaTags = [],
  onTagClick = () => {},
  highlightedTagValues = [],
  style = {},
  isExpandable = false,
}) => {
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container
      style={style}
      isExpandable={isExpandable}
      isExpanded={isExpanded}
    >
      {metaTags.map((meta, i) => (
        <StyledBlackTooltip
          title={
            meta?.key?.includes(":") ? meta?.key?.split(":")?.[1] : meta?.key
          }
        >
          <Tag
            key={i}
            bgColor={getColorFromString(meta?.key)}
            isHighlighted={highlightedTagValues?.includes(meta?.value)}
            onClick={e => {
              onTagClick(meta);
            }}
          >
            {meta?.value}
            <StyledCrossIcon
              height="10px"
              style={{ opacity: 0, pointerEvents: "none" }}
            />
          </Tag>
        </StyledBlackTooltip>
      ))}

      {isExpandable && (
        <IconButtonContainer
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButtonContainer>
      )}

      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </Container>
  );
};

export default MetaTagsViewer;
