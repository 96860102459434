import styled from "styled-components";
import { useState, useEffect } from "react";

import { getLinks, getSeedLinks } from "api/services/datacrawlerService";
import { CenteredWithTopNavLayout } from "components/Layout";
import AddSeedLinkModalTrigger from "components/AddSeedLinkModalTrigger";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";
import { useNavigate, useSearchParams } from "react-router-dom";

const getColorFromStatus = status => {
  if (status === "DONE") {
    return "#00C853";
  }

  if (status === "IN_PROGRESS") {
    return "#d4a600";
  }

  if (status?.includes("FAIL")) {
    return "#d9534f";
  }

  return "#24292F";
};

const PlusAndPagination = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding: 20px;
  padding-top: 100px;
  display: grid;
  align-content: start;
`;

const Table = styled.table``;

const Td = styled.td`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TdBold = styled(Td)`
  font-weight: bold;
`;

const LinksContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
`;

const SeedLinkPdfs = ({ seedLinkId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allPdfLinks, setAllPdfLinks] = useState([]);

  useEffect(() => {
    doPopulateAllLinks();
  }, [seedLinkId]);

  const doPopulateAllLinks = async () => {
    if (!seedLinkId) return;
    setIsLoading(true);
    const { data } = await getLinks({ seedLinkId });
    setAllPdfLinks(
      data?.items?.filter(link => link?.url?.includes(".pdf")) || []
    );
    setIsLoading(false);
  };

  if (isLoading) {
    return "...";
  }

  if (allPdfLinks.length === 0) {
    return null;
  }

  return (
    <LinksContainer>
      {allPdfLinks.map((link, index) => (
        <a href={link?.url} key={index}>
          {link?.url}
        </a>
      ))}
    </LinksContainer>
  );
};

const DEFAULT_PAGE_SIZE = 10;

const CrawlerPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [seedLinks, setSeedLinks] = useState([]);

  const [nextOffset, setNextOffset] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const prevOffsets =
    searchParams
      .get("prevOffsetsStr")
      ?.split(",")
      ?.filter(s => !!s) || [];
  const offset = searchParams.get("offset");
  const pageSize = searchParams.get("pageSize") || DEFAULT_PAGE_SIZE;

  const prevUrl = `?pageSize=${pageSize}&offset=${prevOffsets?.slice(
    -1
  )}&prevOffsetsStr=${[prevOffsets.slice(0, -1)]}`;
  const nextUrl = `?pageSize=${pageSize}&offset=${nextOffset}&prevOffsetsStr=${[
    ...prevOffsets,
    offset,
  ]}`;

  useEffect(() => {
    doPopulateSeedLinks();
  }, [offset]);

  const doPopulateSeedLinks = async () => {
    const { data } = await getSeedLinks({ pageSize, offset });
    setNextOffset(data?.offset);
    setHasMore(data?.hasMore);
    setSeedLinks(data?.items || []);
  };

  return (
    <StyledCenteredWithTopNavLayout centerColumnMaxWidth="100%">
      <PlusAndPagination>
        <AddSeedLinkModalTrigger onSuccess={() => window.location.reload()} />
        <PrevNextPageButtons
          onClickPrev={() => navigate(prevUrl)}
          isPrevDisabled={!offset}
          onClickNext={() => navigate(nextUrl)}
          isNextDisabled={!hasMore}
        />
      </PlusAndPagination>

      <Table>
        <tbody>
          <tr>
            <TdBold>Seed Link</TdBold>
            <TdBold>Created At</TdBold>
            <TdBold>PDFs</TdBold>
            <TdBold>Status</TdBold>
          </tr>
          {seedLinks.map((seedLink, index) => (
            <tr key={index}>
              <Td style={{ width: "200px", maxWidth: "200px" }}>
                <a href={seedLink?.url}>{seedLink.url}</a>
              </Td>
              <Td>{seedLink.createdAt}</Td>
              <Td style={{ width: "780px", maxWidth: "780px" }}>
                <SeedLinkPdfs seedLinkId={seedLink.id} />
              </Td>
              <Td style={{ color: getColorFromStatus(seedLink.status) }}>
                {seedLink.status}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PrevNextPageButtons
        onClickPrev={() => navigate(prevUrl)}
        isPrevDisabled={!offset}
        onClickNext={() => navigate(nextUrl)}
        isNextDisabled={!hasMore}
      />
    </StyledCenteredWithTopNavLayout>
  );
};

export default CrawlerPage;
