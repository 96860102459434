import styled from "styled-components";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import { Link } from "react-router-dom";
import { round } from "lodash";
import Progress from "components/Progress";
import TooltipNew from "components/ui/TooltipNew";
import { InformationIcon } from "components/ui/Icons";
import { SIDEBAR_ITEMS, SideBarItem } from "pages/profile-new";
import FileSideBar from "components/FileSideBar";
import { PLAN_URL } from "pages/profile-new/ProfileSidebar";
import LayoutNew from "components/LayoutNew";

const USAGE_FIELDS = [
  {
    label: "Tokens",
    name: "tokens",
    used: 4500,
    limit: 6000,
    info: "Information about tokens",
  },
  {
    label: "Documents",
    name: "documents",
    used: 25,
    limit: 55,
    info: "Information about documents",
  },
  {
    label: "Emails",
    name: "emails",
    used: 93,
    limit: 100,
    info: "Information about emails",
  },
];

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  background-color: #ffffff;
  padding: 0;
  height: 100vh;
  grid-template-rows: auto 1fr;
`;

const StyledNavHeader = styled(NavHeader)`
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  box-shadow: none;
`;

const Title = styled.div`
  background-color: #f7f8fc;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
`;

const ProfileContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px;
  gap: 50px;
  background-color: #ffffff;
  border-top: 1px solid #00000080;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const SideBar = styled.div`
  padding-top: 16px;
`;

const ProfileFields = styled.div`
  display: grid;
  max-width: 900px;
  margin-top: 10px;
  margin-right: 50px;
`;

const ContentTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 20px;
`;

const FieldRow = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr auto;
  padding: 24px 8px;
  border-bottom: 1px solid #00000080;
  align-items: start;
  :hover {
    background-color: #cddefd;
  }
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const ProgressContainer = styled.div`
  position: relative;
  background-color: #d9d9d9;
  height: 28px;
`;

const ProgressFiller = styled.div`
  position: absolute;
  background-color: #070707;
  height: 100%;
`;

const UsageStatContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
`;

const Tip = styled.div`
  padding: 4px 8px;
  color: #fff;
  background-color: #000;
  margin-top: 2px;
`;

const TitleAndIcon = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledLink = styled(Link)`
  justify-self: end;
`;

const ProgressBar = ({ style = {}, value = 0, max = 0 }) => (
  <ProgressContainer style={style}>
    <ProgressFiller
      style={{
        width: `${(value / max) * 100}%`,
      }}
    />
  </ProgressContainer>
);

const ProfilePageUsage = () => {
  return (
    <LayoutNew>
      <Title>Usage</Title>
      <ProfileContent>
        <ProfileFields>
          <ContentTitle style={{ marginTop: "20px" }}>
            Usage this cycle (23/02/2024 - 22/03/2024)
          </ContentTitle>
          {USAGE_FIELDS?.map((field, index) => (
            <FieldRow
              key={`${index}-${field.name}`}
              style={{
                borderTop: index === 0 ? "1px solid #00000080" : "none",
              }}
            >
              <TooltipNew tipContent={<Tip>{field?.info}</Tip>}>
                <TitleAndIcon>
                  <BoldSpan>{field.label}</BoldSpan>
                  <InformationIcon style={{ fill: "black" }} />
                </TitleAndIcon>
              </TooltipNew>

              <UsageStatContainer>
                <div>
                  Used {field?.used} out of {field?.limit}
                </div>
                <div style={{ justifySelf: "end" }}>
                  {round((1 - field?.used / field?.limit) * 100)}% left
                </div>
                <ProgressBar
                  style={{ gridColumn: "span 2" }}
                  value={field?.used}
                  max={field?.limit}
                />
              </UsageStatContainer>
            </FieldRow>
          ))}
          <Gap height="20px" />
          <StyledLink to={PLAN_URL}>
            <button>Get more</button>
          </StyledLink>
        </ProfileFields>
      </ProfileContent>
    </LayoutNew>
  );
};

export default ProfilePageUsage;
