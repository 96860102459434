import { Audiotrack, HelpOutline, Visibility } from "@material-ui/icons";
import { postPromptRecommendation } from "api/backend/searchServiceEndpoints";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import DocSourcesModalTriggerNew from "components/DocSourcesModalTriggerNew";
import FileViewModal from "components/FileViewModal";
import { PlusInCircleIcon } from "components/IconsNew";
import { ButtonIconTransparent } from "components/ui/ButtonIcon";
import {
  CrossIcon,
  ExcelIcon,
  FolderIcon,
  PaperClipIcon,
  PdfIcon,
  PlusIcon,
  SigmaIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import useSearchParamsState from "hooks/useSearchParamsState";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { formatLongNumber } from "utils/common";

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
`;

const SuggestionsContainer = styled.div`
  position: fixed;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 3;

  /* border: 2px solid #e8ecef; */
  display: grid;
  grid-template-columns: 1fr 300px;
  border-top: none;
  border-radius: 0px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  max-height: 400px;
  overflow: auto;
  /* box-shadow: 0px 0px 5px #b7b7b7; */
  padding-bottom: 0px;
  background-color: white;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;

  /* border-top: none; */
  /* border-radius: 0px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px; */

  ::-webkit-scrollbar {
    display: none;
  }
  background-color: white;

  @media (max-width: 1240px) {
    grid-template-columns: 1fr 0px;
  }
`;

const DropShadow = styled.div`
  position: fixed;
  z-index: -1;
  /* box-shadow: 0px 0px 5px #b7b7b7; */
  pointer-events: none;
  border-radius: 12px;
`;

const SuggestedQueries = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-content: start;

  min-height: 100px;
  max-height: 210px;
  overflow-y: scroll;
`;

const Suggestions = styled.div``;

const Suggestion = styled.div`
  padding: 14px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.2;
  align-items: center;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;

  :hover {
    background-color: #eaeaea;
  }
`;

const EmptySuggestion = styled.div`
  background-color: transparent;
  pointer-events: none;
`;

const DividerLineHorizontal = styled.div`
  height: 1px;
  width: calc(100% - 20px);
  background-color: #00000022;
  margin: 0 auto;
`;

const SourcesContainer = styled.div`
  border-left: 1px solid #00000022;
  background-color: white;
  height: 214px;
  overflow-y: auto;
`;

const SmallTitle = styled.div`
  padding: 8px 10px;
  font-weight: 500;
  font-size: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr auto;
  gap: 10px;
  padding-top: 16px;
  color: #00000055;

  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const SmallSuggestionsTitle = styled.div`
  padding: 8px 12px;
  font-weight: 500;
  font-size: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 10px;
  background-color: white;
  color: #00000055;
  padding-top: 16px;
  /* font-style: italic; */

  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const SUGGESTIONS = [];

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0;
  height: 12px;
  padding-right: 4px;
  fill: #666666;
  cursor: pointer;
  :hover {
    fill: #000;
  }
`;

const DirectoryItem = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  padding: 8px 10px;
  gap: 8px;
  align-items: center;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;

  .trigger {
    opacity: 0;
    margin-right: 20px;
  }

  :hover {
    .trigger {
      opacity: 1;
    }
    background-color: #ededed;
    ${StyledCrossIcon} {
      background-color: #ededed;
      padding-left: 4px;
      opacity: 1;
    }
  }
`;

const DirItemName = styled.a`
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000bf;
  font-weight: 500;
`;

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 10px;
  margin-right: 2px;
`;

const StyledAudioIcon = styled(Audiotrack)``;

const StyledPlusIcon = styled(PlusIcon)`
  width: 12px;
`;

const SmallNumber = styled.div`
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HelpText = styled.div`
  font-size: 22px;
  font-weight: 600;
  transition: opacity 0.2s;
  width: 200px;
  line-height: 1.5;
  color: #00000088;
  svg {
    fill: #00000088;
  }
`;

const IconButton = styled.div`
  justify-self: end;
  color: ${props => (props.isActive ? props.theme.color.primary : "#00000066")};
  svg {
    height: 18px;
    width: 18px;
  }
  :hover {
    color: ${props =>
      props.isActive ? `${props.theme.color.primary}88` : "#00000033"};
  }
  cursor: pointer;
`;

const TokensText = styled.div`
  font-size: 12px;
`;

export const getItemUrl = item => {
  if (item?.type === "SIGMA_TEMPLATE") {
    return `/trial-dashboards/${item?.fileId}/source`;
  }

  if (item?.type === "FILE") {
    return `/files/${item?.fileId}`;
  }

  if (item?.type === "WORD_DOC") {
    return `/word-docs/${item?.fileId}`;
  }

  if (item?.type === "EXCEL_MODEL") {
    return `/excel-views/${item?.fileId}`;
  }

  if (item?.type === "SLIDE_DOC") {
    return `/slide-docs/${item?.fileId}`;
  }

  return "";
};

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon />,
  EXCEL_MODEL: <StyledExcelIcon />,
  SLIDE_DOC: <StyledSlidesIcon />,
  DIRECTORY: <StyledFolderIcon />,
  FILE: <StyledPdfIcon />,
  SIGMA_TEMPLATE: <StyledSigmaIcon />,
};

export const getItemIcon = item => {
  if (item?.isAudioFile) {
    return (
      <StyledAudioIcon
        style={{ width: "12px", height: "12px", marginTop: "2px" }}
      />
    );
  }

  return TYPE_TO_ICON[item?.type];
};

const ButtonsBackground = styled.div`
  background: linear-gradient(to right, #ffffff00, #ffffff 24%);
  height: 100%;
`;

const BarPlusIconContainer = styled.div`
  display: flex;
  /* background-color: #eaeaea; */
  :hover {
    background-color: #b6b6b6;
  }

  @media (max-width: 1240px) {
    display: none;
  }
`;

const InputWithBottomSuggestionsAndSources = ({
  className,
  value,
  onChange = e => {},
  placeholder,
  disabled,
  onKeyDown = e => {},
  onClickSuggestion = text => {},
  id = "",
  sources = [],
  setSources = () => {},
  userSources = [],
  setUserSources = () => {},
  externalSuggestions = [],
  areTickboxesHidden = false,
}) => {
  const [searchParams] = useSearchParams();
  // const [sources, setSources] = useSearchParamsState({
  //   paramName: "sources",
  //   initialValue: [],
  // });

  const [isShowingSourcesHelp, setIsShowingSourcesHelp] = useState(false);
  const [isShowingSuggestionsHelp, setIsShowingSuggestionsHelp] =
    useState(false);

  const [suggestions, setSuggestions] = useState(SUGGESTIONS);
  const [isFocussed, setIsFocussed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sourcesContainerRef = useRef(null);
  const suggestionsContainerRef = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    // close suggestions when resizing, to prevent them getting stuck
    const onResize = e => {
      ref.current.blur();
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    setIsShowingSourcesHelp(false);
    setIsShowingSuggestionsHelp(false);
  }, [value]);

  useEffect(() => {
    if (disabled) {
      setIsFocussed(false);
    }
  }, [disabled]);

  useEffect(() => {
    doPopulateSuggestions();
  }, [JSON.stringify(sources)]);

  useEffect(() => {
    if (searchParams.get("isShowingSourcesHelp") === "true") {
      setIsShowingSourcesHelp(true);
    }
    if (searchParams.get("isShowingSuggestionsHelp") === "true") {
      setIsShowingSuggestionsHelp(true);
    }
  }, [
    searchParams.get("isShowingSourcesHelp"),
    searchParams.get("isShowingSuggestionsHelp"),
  ]);

  const doPopulateSuggestions = async () => {
    const { data } = await postPromptRecommendation(
      {},
      {
        sources,
      }
    );
    setSuggestions(data || []);
  };

  const rect = ref.current?.getBoundingClientRect();
  const sourcesContainerRect =
    sourcesContainerRef.current?.getBoundingClientRect();
  const suggestionsContainerRect =
    suggestionsContainerRef.current?.getBoundingClientRect();

  let totalNumberOfPages = 0;
  sources?.forEach(source => {
    totalNumberOfPages += source?.numberOfPages;
  });
  const numTokens = totalNumberOfPages * 999;

  let suggestionsToUse = suggestions || [];
  if (externalSuggestions?.length > 0) {
    suggestionsToUse = externalSuggestions;
  }

  // let suggestionsToUse = [
  //   "What is the purpose of Boltzbit Platform Access?",
  //   "Is there any discount or promotion available for this invoice?",
  //   "How can I pay the invoice online?",
  //   "What is the purpose of Boltzbit Platform Access?",
  //   "Is there any discount or promotion available for this invoice?",
  //   "How can I pay the invoice online?",
  //   "What is the purpose of Boltzbit Platform Access?",
  //   "Is there any discount or promotion available for this invoice?",
  //   "How can I pay the invoice online?",
  // ];

  return (
    <>
      <input
        id={id}
        className={className}
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={e => {
          if (e.key === "Enter") {
            ref.current.blur();
          }
          onKeyDown(e);
        }}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => {
          doPopulateSuggestions();
          setIsFocussed(true);
        }}
        onBlur={() => setIsFocussed(false)}
      />
      {/* <ButtonsBackground
        style={{
          position: "absolute",
          top: 22,
          right: 0,
          height: 44,
          zIndex: 1,
          padding: 0,
          width: 100,
        }}
      /> */}
      <BarPlusIconContainer
        id="attach-button"
        className="blue-button"
        onClick={() => {
          ref.current.focus();
        }}
        isActive={sources?.length > 0}
        style={{
          position: "absolute",
          top: 27,
          transform: "translateY(-50%)",
          left: 16,
          zIndex: 2,
          width: 22,
          height: 22,
          padding: 0,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
      >
        {/* <PlusIcon height="8px" /> */}
        {/* <PaperClipIcon
          style={{
            transform: "rotate(-45deg)",
          }}
          height="16px"
        /> */}
        <DocSourcesModalTriggerNew
          alreadyAddedSources={sources}
          onAddSources={newSources => {
            setSources([...sources, ...newSources]);
            setUserSources([...userSources, ...newSources]);
          }}
          onClose={() => setIsModalOpen(false)}
          trigger={
            <div
              onClick={e => setIsModalOpen(true)}
              isActive={sources.length > 0}
              style={{
                // position: "absolute",
                height: 18,
                width: 18,
                top: 0,
                right: 4,
                zIndex: 2,
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridColumn: "span 2",
              }}
            >
              <PlusInCircleIcon />
            </div>
          }
        />
        {/* <SmallNumber
          style={{
            display: sources?.length > 0 ? "flex" : "none",
            position: "absolute",
            top: -4,
            right: -4,
          }}
        >
          {sources?.length}
        </SmallNumber> */}
      </BarPlusIconContainer>
      <SuggestionsContainer
        id="suggestions"
        style={{
          visibility: isFocussed || isModalOpen ? "visible" : "hidden",
          display: "grid",
          top: rect?.top - 218,
          left: rect?.left,
          width: rect?.width,
        }}
      >
        {/* <EmptySuggestion
          style={{
            height: rect?.height,
            // width: rect?.width,
            gridColumn: "span 2",
          }}
        /> */}
        <DropShadow
          style={{
            width: rect?.width,
            height: rect?.height + suggestionsContainerRect?.height,
            top: rect?.top,
            left: rect?.left,
          }}
        />
        <DividerLineHorizontal style={{ gridColumn: "span 2" }} />
        <SuggestedQueries ref={suggestionsContainerRef}>
          <SmallSuggestionsTitle>Related to your files</SmallSuggestionsTitle>
          <Suggestions id="suggestions-list">
            {suggestionsToUse?.map((suggestion, i) => {
              return (
                <Suggestion
                  className="suggestion"
                  data-index={i}
                  key={suggestion}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickSuggestion(suggestion);
                  }}
                >
                  {suggestion}
                </Suggestion>
              );
            })}
          </Suggestions>
        </SuggestedQueries>
        <SourcesContainer ref={sourcesContainerRef}>
          <SmallTitle>
            Attached files
            <DocSourcesModalTriggerNew
              alreadyAddedSources={sources}
              onAddSources={newSources => {
                setSources([...sources, ...newSources]);
              }}
              onClose={() => setIsModalOpen(false)}
              trigger={
                <ButtonIconTransparent
                  onClick={e => setIsModalOpen(true)}
                  isActive={sources.length > 0}
                  style={{
                    // position: "absolute",
                    height: 16,
                    width: 16,
                    top: 0,
                    right: 4,
                    zIndex: 2,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gridColumn: "span 2",
                  }}
                >
                  <StyledPlusIcon height="12px" />
                </ButtonIconTransparent>
              }
            />
          </SmallTitle>
          {sources.map((source, i) => (
            <DirectoryItem key={source.id} className="source" data-index={i}>
              <input
                checked={
                  !!userSources?.find(item => item.fileId === source.fileId)
                }
                onChange={e => {
                  if (e.target.checked) {
                    setUserSources([...userSources, source]);
                    return;
                  }
                  setUserSources(
                    userSources.filter(item => item.fileId !== source.fileId)
                  );
                }}
                onMouseDown={e => e.preventDefault()}
                type="checkbox"
              />
              {getItemIcon(source)}
              <DirItemName
                onMouseDown={e => e.preventDefault()}
                href={getItemUrl(source)}
                target="_blank"
              >
                {source.name || source?.fileName}
              </DirItemName>
              <FileViewModal fileId={source?.fileId} />
              <StyledCrossIcon
                style={{ position: "absolute", right: 8 }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSources(
                    sources.filter(item => item.fileId !== source.fileId)
                  );
                }}
                onMouseDown={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </DirectoryItem>
          ))}
        </SourcesContainer>
      </SuggestionsContainer>
    </>
  );
};

export default InputWithBottomSuggestionsAndSources;
