import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { isEmpty, round } from "lodash";
import { useRef } from "react";

const CloseButtonContainer = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const TopBar = styled.div`
  position: absolute;
  top: -20px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transition: opacity 0.2s;
`;

const LabelInputContainer = styled.div`
  position: absolute;
  top: ${props => props.pixelY}px;
  left: ${props => props.pixelX}px;
  width: ${props => props.pixelW}px;
  height: ${props => props.pixelH}px;
`;

const InputAndCloseButtonContainer = styled.div`
  position: absolute;
  display: flex;
  top: -20px;
`;

const MaskRectangle = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor || props.theme.color.primary};
  border: 1px solid ${props => (props.isBorderHighlighted ? props.theme.color.feature : props.theme.color.closest)};
  opacity: 0.5;

  resize: both;
  overflow: auto;
`;

const LabelInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer2};
  border-bottom: none;
  height: 20px;
  padding: 0;
`;

const SelectInput = styled.select`
  width: 60px;
  border: none;
  height: 20px;
  padding: 0;
`;

const SuggestionCloseButtonContainer = styled(CloseButtonContainer)`
  left: 80px;
  opacity: 1;
`;

const getColorFromString = s => {
  const cyrb53 = function (str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  return `#${cyrb53(s)?.toString(16)?.substring(0, 6)}`;
};

export const AnnotationLabelInput = ({ value, onChange, onBlur, pixelX, pixelY, pixelW, pixelH, onClickClose }) => {
  const [backgroundColor, setBackgroundColor] = useState(getColorFromString(value));

  return (
    <LabelInputContainer pixelX={pixelX} pixelY={pixelY} pixelW={pixelW} pixelH={pixelH}>
      <InputAndCloseButtonContainer>
        <LabelInput
          value={value}
          size={value?.length + 2}
          onChange={onChange}
          onBlur={() => {
            setBackgroundColor(getColorFromString(value));
            onBlur();
          }}
        />
        <CloseButtonContainer onClick={onClickClose} backgroundColor={backgroundColor}>
          <Close fontSize="small" />
        </CloseButtonContainer>{" "}
      </InputAndCloseButtonContainer>

      <MaskRectangle backgroundColor={backgroundColor} />
    </LabelInputContainer>
  );
};

const MoveHandle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  cursor: pointer;
  background-color: ${props => props.theme.color.feature};
  opacity: 0.4;
  z-index: 2;

  :hover {
    opacity: 0.6;
  }
`;

export const SuggestedAnnotation = ({
  x,
  y,
  w,
  h,
  scaleFactor,
  onChange,
  annotatedLabel,
  labels,
  onClickClose,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onEditAnnotationBox,
  isSelected,
  onClick,
}) => {
  const [selectedLabel, setSelectedLabel] = useState(annotatedLabel);
  const [backgroundColor, setBackgroundColor] = useState(null);

  const maskRef = useRef();

  const [isMovingBox, setIsMovingBox] = useState(false);

  const [annoX, setAnnoX] = useState(x);
  const [annoY, setAnnoY] = useState(y);
  const [clickOffset, setClickOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isEmpty(selectedLabel)) {
      alert("No pre-defined label is available, please add at least 1 label");
      return;
    }
    setBackgroundColor(getColorFromString(selectedLabel));
  }, [selectedLabel]);

  const isEventInResizeCorner = e => {
    const maskRect = maskRef?.current?.getBoundingClientRect();
    const isTowardsRight = e.nativeEvent.offsetX >= maskRect?.width - 15;
    const isTowardsBottom = e.nativeEvent.offsetY >= maskRect?.height - 15;
    return isTowardsRight && isTowardsBottom;
  };

  return (
    <LabelInputContainer
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      pixelX={annoX * scaleFactor}
      pixelY={annoY * scaleFactor}
      pixelW={w * scaleFactor}
      pixelH={h * scaleFactor}
    >
      {isSelected && (
        <TopBar onClick={e => e.stopPropagation()}>
          <SelectInput
            value={selectedLabel}
            onChange={e => {
              setSelectedLabel(e.target.value);
              onChange(e);
            }}
          >
            {["-", ...labels].map(label => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </SelectInput>
          <SuggestionCloseButtonContainer onClick={onClickClose} backgroundColor={backgroundColor}>
            <Close fontSize="small" />
          </SuggestionCloseButtonContainer>
        </TopBar>
      )}
      <MoveHandle
        onMouseDown={e => {
          e.preventDefault();
          setIsMovingBox(true);
          setClickOffset({ x: e.nativeEvent?.offsetX, y: e.nativeEvent?.offsetY });
        }}
        onMouseMove={e => {
          if (!isMovingBox) {
            return;
          }
          const containerRect = document.querySelector("#image-container").getBoundingClientRect();
          let newX = (e.clientX - containerRect?.left - clickOffset.x) / scaleFactor;
          let newY = (e.clientY - containerRect?.top - clickOffset.y) / scaleFactor;

          setAnnoX(round(newX));
          setAnnoY(round(newY));
        }}
        onMouseUp={() => {
          onEditAnnotationBox(annoX, annoY, w, h);
          setIsMovingBox(false);
        }}
        onMouseLeave={() => {
          onEditAnnotationBox(annoX, annoY, w, h);
          setIsMovingBox(false);
        }}
      />
      <MaskRectangle
        ref={maskRef}
        backgroundColor={backgroundColor}
        isBorderHighlighted={isSelected}
        onMouseDown={e => {
          if (isEventInResizeCorner(e)) {
            return;
          }
          onMouseDown(e);
        }}
        onMouseMove={onMouseMove}
        onMouseUp={e => {
          if (isEventInResizeCorner(e)) {
            const newRect = maskRef?.current?.getBoundingClientRect();
            onEditAnnotationBox(x, y, round(newRect.width / scaleFactor), round(newRect.height / scaleFactor));
            return;
          }
          onMouseUp(e);
        }}
      />
    </LabelInputContainer>
  );
};
