import styled from "styled-components";
import { isFalsey } from "utils/common";

const Container = styled.div`
  overflow: auto;
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  width: max-content;
`;

const TabOption = styled.div`
  width: max-content;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  line-height: 20px;
  font-weight: ${props => (props.isSelected ? "bold" : "normal")};
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closest};
  border-bottom: ${props =>
    props.isSelected ? "2px solid " + props.theme.color.primary : "none"};

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 15px;

  svg {
    width: 14px;
    height: 14px;
    ${props => props.isSelected && "fill: " + props.theme.color.primary};
  }
`;

const NavWithTabsWithArrows = ({
  tabNames,
  tabIcons = [],
  selectedTabName,
  onTabSelect,
  className,
  tabOptionStyle = {},
}) => {
  if (!tabNames.length || tabNames.every(isFalsey)) {
    return null;
  }

  return (
    <Container>
      <TabsContainer className={className}>
        {tabNames.map((tabName, i) => (
          <TabOption
            onClick={() => onTabSelect(tabName, i)}
            key={`${tabName}-${i}`}
            isSelected={selectedTabName === tabName}
            style={tabOptionStyle}
          >
            {tabIcons?.[i] || ""}
            {tabName}
          </TabOption>
        ))}
      </TabsContainer>
    </Container>
  );
};

export default NavWithTabsWithArrows;
