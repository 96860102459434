import { postUsers } from "api/services/authenticationService";
import ButtonIcon from "components/ui/ButtonIcon";
import { BoltzbitLogo } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  padding-top: 200px;

  /* seed */
  /* #e4edf9; */

  /* light bg */
  /* #f7f9fd; */

  /* strong bg */
  /* #cdd4e0; */

  background: linear-gradient(315deg, #f4f8fd 0%, #cdd4e0 60%, #cdd4e0 100%);
`;

const Frame = styled.div`
  padding: 40px;
  background-color: white;
  border-radius: 4px;

  display: grid;
  grid-template-columns: auto 1fr;

  column-gap: 0px;
  row-gap: 20px;
  height: max-content;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 500;
  align-self: center;
  width: 400px;
  line-height: 1.2;
`;

const Input = styled.input`
  outline: none;
  font-family: "Montserrat";
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 12px 16px;
  font-size: 18px;
  width: 458px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ButtonPrimary = styled(ButtonIcon)`
  font-weight: 500;
  padding: 8px 20px;
`;

const ButtonSecondary = styled(ButtonIcon)`
  font-weight: 500;
  background-color: transparent;
`;

const LeftContent = styled.div`
  display: grid;
  gap: 20px;
  align-content: start;
  align-items: start;
  width: 420px;
`;

const RightContent = styled.div`
  margin-top: 62px;
  display: grid;
  justify-items: end;
`;

const ErrorMessage = styled.div`
  height: 120px;
  padding: 10px;
  justify-self: start;
  color: ${props => props.theme.color.error};
  width: 450px;
`;

const Message = styled.div`
  width: 250px;
  line-height: 1.2;
`;

const Username = styled.div`
  width: max-content;
  font-size: 16px;
`;

const SignupPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [text, setText] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const userName = searchParams.get("userName");

  useEffect(() => {
    setText("");
    setError("");
  }, [userName]);

  const doCheckPasswordAndSignUp = async () => {
    setIsLoading(true);
    if (text !== repeatedPassword) {
      setError({ message: "Passwords do not match" });
      setIsLoading(false);
    }

    let payloadUserName = userName;
    let payloadEmail = "";
    if (userName.includes("@")) {
      payloadEmail = userName;
      payloadUserName = userName?.split("@")?.[0];
      payloadUserName = payloadUserName?.replaceAll(".", "-");
      // todo: tell user to do manually
    }

    const { data, error } = await postUsers(
      {},
      {
        userName: payloadUserName,
        email: payloadEmail,
        password: text,
        repeatedPassword,
      }
    );
    setError(error);
    setIsLoading(false);

    if (!error) {
      navigate(
        "/login?message=Your account must be verified. Please check your email."
      );
    }
  };

  let leftMessage = searchParams?.get("message") || "";
  let title = "Create account";
  let placeholder = "Email";
  let onClickNext = () => navigate(`/signup-old?userName=${text}`);
  let secondaryButton = (
    <ButtonSecondary onClick={() => navigate("/login")}>
      Sign in
    </ButtonSecondary>
  );
  let inputType = "text";

  if (userName) {
    inputType = "password";
    placeholder = "Password";
    secondaryButton = (
      // eslint-disable-next-line no-restricted-globals
      <ButtonSecondary onClick={() => history.back()}>Back</ButtonSecondary>
    );
    onClickNext = doCheckPasswordAndSignUp;
  }

  let greetngPrefix = "Hello, ";
  if (userName?.includes("@")) {
    greetngPrefix = "";
  }

  return (
    <Container>
      <Frame>
        <LeftContent>
          <Link
            to="/login"
            style={{
              width: "max-content",
            }}
          >
            <BoltzbitLogo
              style={{
                height: "42px",
                //  fill: "url(#SvgGradientPurpleToBlue)"
              }}
            />
          </Link>
          <Title>{title}</Title>
          {userName && (
            <Username>
              {greetngPrefix}
              {userName}
            </Username>
          )}
          <Message>{leftMessage}</Message>
        </LeftContent>

        <RightContent>
          <Input
            type={inputType}
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder={placeholder}
          />

          <Input
            value={repeatedPassword}
            onChange={e => setRepeatedPassword(e.target.value)}
            style={{
              marginTop: "10px",
              visibility: userName ? "visible" : "hidden",
            }}
            type="password"
            placeholder="Confirm"
          />

          <ErrorMessage>{error && error?.message}</ErrorMessage>
          <div style={{ display: "flex", justifyItems: "end", gap: "10px" }}>
            {secondaryButton}
            <ButtonPrimary
              onClick={onClickNext}
              isActive
              isDisabled={!text || isLoading}
            >
              Next
            </ButtonPrimary>
          </div>
        </RightContent>
      </Frame>
    </Container>
  );
};

export default SignupPage;
