import { getFile } from "api/services/filesService";
import { useState, useEffect } from "react";

const usePollFile = fileId => {
  const [file, setFile] = useState(null);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fileId) {
      setFile(null);
      return;
    }

    doPopulateFile();
    const intervalId = setInterval(doPopulateFile, 2000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [fileId]);

  useEffect(() => {
    if (file?.progress === 100 || file?.status === "FAILED" || !!error) {
      clearInterval(pollIntervalId);
    }
  }, [JSON.stringify(file), JSON.stringify(error)]);

  const doPopulateFile = async () => {
    const { data, error } = await getFile(fileId);
    setFile(data);
    setError(error);
  };

  return [file, setFile];
};

export default usePollFile;
