import { Add, Close } from "@material-ui/icons";
import TextInputSquare from "components/inputs/TextInputSquare";
import styled from "styled-components";

const KeywordsValues = styled.div`
  grid-area: keywords-values;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RuleKeywordInput = styled(TextInputSquare)`
  label {
    padding: 5px 15px;
  }
`;

const KeywordInputAndDelete = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 30px;
  gap: 5px;
  align-items: center;
  align-self: start;
`;

const AddButton = styled.div`
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.closer0};
  }
  border-radius: 50%;
  padding: 0px;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(AddButton)``;

const KeywordsContainer = styled.div``;

const KeywordsArrayEditor = ({ keywords = [], onNewKeywords }) => {
  return (
    <KeywordsContainer>
      <KeywordsValues>
        {keywords.map((keyword, index) => (
          <KeywordInputAndDelete key={index}>
            <RuleKeywordInput
              key={index}
              value={keyword}
              onNewInput={newKeyword =>
                onNewKeywords([...keywords.slice(0, index), newKeyword, ...keywords.slice(index + 1)])
              }
            />
            <DeleteButton onClick={() => onNewKeywords([...keywords.slice(0, index), ...keywords.slice(index + 1)])}>
              <Close />
            </DeleteButton>
          </KeywordInputAndDelete>
        ))}
        <AddButton onClick={() => onNewKeywords([...keywords, ""])}>
          <Add />
        </AddButton>
      </KeywordsValues>
    </KeywordsContainer>
  );
};

export default KeywordsArrayEditor;
