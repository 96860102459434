import Button from "components/ui/Button";
import styled from "styled-components";

const SmallButton = styled(Button)`
  padding: 0;
  gap: 0;
  grid-template-columns: auto;
`;

export default SmallButton;
