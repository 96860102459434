import { Visibility } from "@material-ui/icons";
import ReferenceModalSpan from "components/ReferenceModalSpan";
import ButtonWord from "components/ui/ButtonWord";
import { ChevronDownIcon, PdfIcon } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow: auto;
  display: grid;
  padding-bottom: 8px;
`;

const ActionsAndBtn = styled.div`
  height: ${props => (props.isExpanded ? "170px" : "0px")};
  display: grid;
  transition: height 0.2s;
  overflow: hidden;
`;

const ActionsContainer = styled.div`
  display: flex;

  gap: 10px;
  padding: 8px 0;
  margin-left: 30px;
  padding-right: 4px;
  width: 100%;
  overflow-x: auto;
`;

const ActionCard = styled.div`
  position: relative;
  width: calc(100% / 4);
  flex-shrink: 0;
  height: 120px;
  background-color: white;
  border-radius: 18px;
  padding: 12px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid
    ${props => (props?.isSelected ? props.theme.color.primary : "transparent")};
  transition: background-color 0.2s;
  :hover {
    background-color: #f3f3f3;
    box-shadow: ${props => props.theme.shadowWeak};
  }
  font-weight: 500;
  padding-bottom: 0;

  display: grid;
  grid-template-rows: auto auto 1fr;
`;

const EyeContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 10px;
  opacity: 0.5;
  svg {
    height: 18px;
  }
  :hover {
    opacity: 1;
  }
`;

const CardLabel = styled.div`
  background-color: #e1e1e1;
  padding: 4px 8px;
  border-radius: 8px;
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TEXT = `This document contains information about the financial status of all subsidiary companies under Morgan Stanley`;

const Description = styled.div`
  padding-top: 8px;
  height: 100%;
  overflow: hidden;
  font-size: 10px;
  font-weight: 400;
  opacity: 0.8;
`;

const TwoColumns = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 30px;
  justify-self: start;
  padding: 4px;
  cursor: pointer;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 18px;
  fill: none;
  stroke: black;
  transition: transform 0.2s;
`;

const RegenActions = ({
  actions = [],
  doRerunBlock = () => {},
  rerunIndex = 0,
  chevronText = "",
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const initialSelectedIds = actions
    ?.filter(action => action?.isHighlighted)
    ?.map(action => action?.id);

  useEffect(() => {
    setSelectedIds(initialSelectedIds);
  }, [JSON.stringify(initialSelectedIds)]);

  let expandText = chevronText || "Show options";
  if (isExpanded) {
    expandText = chevronText || "Hide options";
  }

  const noSourcesActionId = actions?.find(
    action => action?.needSearch === false
  );

  return (
    <Container>
      <TwoColumns onClick={() => setIsExpanded(prev => !prev)}>
        <div>{expandText}</div>
        <StyledChevronDownIcon
          style={{
            transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </TwoColumns>
      <ActionsAndBtn isExpanded={isExpanded}>
        <ActionsContainer>
          {actions?.map(action => {
            return (
              <ActionCard
                isSelected={selectedIds.includes(action?.id)}
                onClick={() => {
                  setSelectedIds(prev => {
                    let newIds = [];

                    if (prev.includes(action?.id)) {
                      newIds = prev?.filter(id => id !== action?.id);
                    } else {
                      newIds = [...prev, action?.id];
                    }

                    if (
                      newIds?.includes(noSourcesActionId?.id) &&
                      !action?.needSearch
                    ) {
                      newIds = [noSourcesActionId?.id];
                    }

                    if (action?.needSearch) {
                      newIds = newIds.filter(
                        id => id !== noSourcesActionId?.id
                      );
                    }

                    return newIds;
                  });
                }}
                key={action?.id}
              >
                <input
                  style={{
                    position: "absolute",
                    top: 12,
                    right: 12,
                    zIndex: 1,
                  }}
                  type="checkbox"
                  checked={selectedIds.includes(action?.id)}
                />
                {action?.meta && (
                  <ReferenceModalSpan meta={action?.meta} isTooltipDisabled>
                    <EyeContainer>
                      <Visibility />
                    </EyeContainer>
                  </ReferenceModalSpan>
                )}
                {!action?.meta && <div />}
                <CardLabel>{action?.name}</CardLabel>
                <Description>{action?.description}</Description>
              </ActionCard>
            );
          })}
        </ActionsContainer>
        <ButtonWord
          style={{ justifySelf: "end", borderRadius: "12px" }}
          disabled={selectedIds.length === 0}
          onClick={() => {
            doRerunBlock(rerunIndex, selectedIds);
          }}
        >
          Regenerate
        </ButtonWord>
      </ActionsAndBtn>
    </Container>
  );
};

export default RegenActions;
