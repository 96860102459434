import {
  getBlog,
  patchBlog,
  postBlog,
} from "api/backend/projectServiceEndpoints";
import Modal from "components/ui/Modal";
import { useState, useEffect } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  background-color: white;
  padding: 10px;
  white-space: pre-wrap;
  /* height: 600px; */
  width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
`;

const FIELDS = [
  {
    name: "name",
  },
  {
    name: "subtitle",
  },
  {
    name: "content",
    type: "textarea",
  },
];

const FieldName = styled.div`
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  font-family: "Montserrat", sans-serif;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 400px;
  resize: none;
  font-family: "Montserrat", sans-serif;
`;

const EditBlogModal = ({ trigger = null, id = "" }) => {
  const [blog, setBlog] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      setBlog({});
      return;
    }
    doPopulateBlog();
  }, [id]);

  const doPopulateBlog = async () => {
    const { data } = await getBlog(id);
    setBlog(data);
  };

  const onPressSave = async () => {
    setIsLoading(true);
    if (id) {
      await patchBlog(id, {}, blog);
    } else {
      await postBlog({}, blog);
    }
    window.location.reload();
  };

  return (
    <>
      <span onClick={() => setIsOpen(true)}>{trigger}</span>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <FieldName>Thumbnail</FieldName>
          <img
            alt="card thumbnail"
            style={{ height: "100px" }}
            src={`https://drive.google.com/thumbnail?id=${blog?.meta?.imgGoogleDriveId}`}
          />
          <input
            placeholder="Google drive ID"
            value={blog?.meta?.imgGoogleDriveId || ""}
            onChange={e =>
              setBlog({
                ...blog,
                meta: {
                  ...(blog.meta || {}),
                  imgGoogleDriveId: e.target.value,
                },
              })
            }
          />
          <input
            placeholder="Date"
            type="date"
            value={blog?.meta?.dateStr || ""}
            onChange={e =>
              setBlog({
                ...blog,
                meta: {
                  ...(blog.meta || {}),
                  dateStr: e.target.value,
                },
              })
            }
          />
          {FIELDS.map(field => {
            const value = blog?.[field.name] || "";
            const onChange = e =>
              setBlog({ ...blog, [field.name]: e.target.value });

            let inputElement = <Input value={value} onChange={onChange} />;
            if (field.type === "textarea") {
              inputElement = <TextArea value={value} onChange={onChange} />;
            }

            return (
              <div>
                <FieldName>{field.name}</FieldName>
                {inputElement}
              </div>
            );
          })}

          <button
            disabled={isLoading}
            onClick={onPressSave}
            style={{ justifySelf: "end", alignSelf: "end" }}
          >
            Save
          </button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditBlogModal;
