import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { cloneDeep, fill, isEmpty, isEqual, range } from "lodash";
import { Link, useSearchParams } from "react-router-dom";
import { Done, FileCopy, KeyboardArrowDown, Replay } from "@material-ui/icons";

import {
  BoltzbitLogo,
  BoltzhubLogoInner,
  CrossIcon,
  DatasetIcon,
  FilesIcon,
  LogoInner,
  PaperClipIcon,
  PlusIcon,
  RoundTickIcon,
  SaveTickIcon,
  SendIcon,
  TickIcon,
} from "components/ui/Icons";
import NavHeader from "components/NavHeader";
import { Gap } from "components/Layout";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  getCubesFindByKey,
  getUserProfileByUserName,
} from "api/services/projectService";
import { postAndStreamResponse } from "api/api-http-methods";
import InputWithSuggestions from "components/InputWithSuggestions";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import useSearchParamsState from "hooks/useSearchParamsState";
import { WORD_DOC_WITH_REFERENCE } from "api/services/fixtures";
import RecordsPlot from "components/chat-plots/RecordsPlot";
import TooltipNew from "components/ui/TooltipNew";
import ButtonIcon, { ButtonIconTransparent } from "components/ui/ButtonIcon";
import SourceFilesList from "components/SourceFilesList";
import TextAreaExpanding from "components/TextAreaExpanding";
import { parseJson, sleep } from "utils/common";
import { postChatIngestToBoltzhb } from "api/services/chatService";
import { getDataIngestionJobUntilFinish } from "api/services/dataService";
import ModelSelectDropdown from "components/ModelSelectDropdown";
import LayoutNew from "components/LayoutNew";
import ReferenceModalSpan from "components/ReferenceModalSpan";

const getBlockTextSegments = block => {
  // const block = cloneDeep(inputBlock);
  // if (block?.text?.startsWith(" ")) {
  //   block.text = block.text.slice(1);
  // }
  const blockTextSegments = [];
  let currentSegment = {
    text: "",
    meta: null,
    url: "",
    bgColor: "transparent",
  };
  let i = 0;
  while (i < block?.text?.length) {
    const styleAtIndex = block?.styles?.find(
      style => i >= style?.start && i < style?.end
    );
    const metaAtIndex = styleAtIndex?.meta || null;
    const urlAtIndex = styleAtIndex?.url || null;

    if (
      !isEqual(metaAtIndex, currentSegment?.meta) ||
      !isEqual(urlAtIndex, currentSegment?.url)
    ) {
      blockTextSegments.push(currentSegment);
      currentSegment = {
        text: "",
        url: urlAtIndex,
        meta: metaAtIndex,
        bgColor: styleAtIndex?.bgColor || "transparent",
        fontWeight: styleAtIndex?.fontWeight || "normal",
      };
    }

    currentSegment.text += block?.text?.[i];
    i++;
  }
  blockTextSegments.push(currentSegment);

  return blockTextSegments;
};

const Container = styled.div`
  position: relative;
  width: ${props => (props?.isSideBarOpen ? "570px" : "800px")};
  margin: 0 auto;
  display: grid;
  padding-top: 50px;
  grid-template-rows: calc(100vh - 60px - 50px - 40px) 60px;
  transition: width 0.2s;
`;

const TopBar = styled.div`
  width: 100vw;
  background-color: white;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;

  display: grid;
  align-items: center;
  padding: 0 20px;
  padding-right: 12px;
  grid-template-columns: 1fr auto auto;
  justify-content: space-between;
  z-index: 3;

  border-bottom: 1px solid #eaeaea;
`;

const StyledNavHeader = styled(NavHeader)`
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  position: relative;
`;

const TextBlocks = styled.div`
  position: relative;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const InputArea = styled.div`
  position: relative;
  padding-bottom: 20px;
  background-color: ${props => props.theme.color.furthest};
  width: 100%;
`;

const StyledInputWithSuggestions = styled(InputWithSuggestions)`
  padding: 20px;
  border: none;
  /* width: 100%; */
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  padding: 12px 18px;
  border-radius: 4px;
  resize: none;

  position: fixed;
  bottom: 40px;
  transition: width 0.2s;
  width: ${props => (props.isSideBarOpen ? "570px" : "800px")};

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;

const BlockText = styled.div`
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 14px;
  padding: 0 10px;

  ${props => props.isIngested && `background-color: #0191ff11;`}
  ${props => props.isHighlighted && `background-color: #f1f1f1;`}

  ${props => props.isDisabled && `pointer-events: none;`}
`;

const StyledReplay = styled(Replay)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const StyledFileCopy = styled(FileCopy)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const Actions = styled.div`
  padding: 8px 0;
  padding-left: 35px;
  display: flex;
  gap: 10px;
`;

const StyledBubbleSendIcon = styled(SendIcon)`
  opacity: 0;
  transition: opacity 0.2s;
  fill: #848484;
  height: 14px;
  margin-left: 4px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledTickIcon = styled(Done)`
  transition: opacity 0.2s;
  fill: #848484;
  height: 14px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }

  ${props => props.disabled && `opacity: 0.5; pointer-events: none;`}
`;

const InputAndSendButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 4px;
  justify-items: start;
  padding-bottom: 4px;

  :hover {
    ${StyledBubbleSendIcon} {
      opacity: 1;
    }
  }
`;

const StyledInput = styled(TextAreaExpanding)`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  height: max-content;
  resize: none;
  overflow: hidden;
  line-height: 1.4;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const ProfilePicContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.primary};
  svg {
    margin-top: 3px;
    height: 12px;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 14px;
  cursor: pointer;
  z-index: 3;

  :hover {
    opacity: 0.5;
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const TableConainer = styled.div`
  padding-left: 45px;
  max-width: 100%;
  overflow: auto;
`;

const PulsingDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const StyledArrowDown = styled(KeyboardArrowDown)`
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  :hover {
    background-color: lightgrey;
  }
`;

const AttachBtn = styled(ButtonIconTransparent)`
  /* position: absolute; */
  z-index: 3;
  height: 24px;
  /* right: 0;
  bottom: 0; */
`;

const BlueDot = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
`;

const StyledBoltzbitLogo = styled(BoltzbitLogo)`
  fill: ${props => props.theme.color.closest};
  height: 28px;
`;

const CustomLogo = styled.div`
  fill: ${props => props.theme.color.closest};
  height: 28px;
  width: 28px;

  background-image: url(${props => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const IndentedBlock = styled.div`
  position: relative;
  ${props =>
    props.hasListStyle &&
    `
  padding-left: 16px;
  ::before {
    position: absolute;
    left: 0px;
    top: -15px;
    font-size: 32px;
    content: "•";
  }
  `};
`;

const RightButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 24px;
  z-index: 3;
  right: 12px;
  top: 7px;
`;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com/";
}

const isListBlock = block => {
  return block?.text?.startsWith("* ");
};

const lightenColor = colorStr => {
  if (colorStr?.startsWith("#") && colorStr?.length === 9) {
    const coreColor = colorStr?.slice(0, 7);
    return `${coreColor}00`;
  }

  return colorStr;
};

const HUB_BASE_URL = "https://hub.boltzbit.com";

const QuickChatStreamPage = () => {
  const [searchParams] = useSearchParams();

  const [userInput, setUserInput] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [avatarSrc, setAvatarSrc] = useState("");
  const [logoSrc, setLogoSrc] = useState("");
  const [canSeeBottom, setCanSeeBottom] = useState(true);
  const [sources, setSources] = useSearchParamsState({
    paramName: "sources",
    initialValue: [],
  });
  const [bottomInputHeight, setBottomInputHeight] = useState("auto");

  const scrollerRef = useRef(null);

  const [pageResultsCache, setPageResultsCache] = useState({});

  const [highlightedQueryId, setHighlightedQueryId] = useState("");
  const [ingestingQueryId, setIngestingQueryId] = useState("");
  const [ingestedQueryIds, setIngestedQueryIds] = useState([]);

  const [flowCube, setFlowCube] = useState(null);

  useEffect(() => {
    doPopulateFlowDatasetId();
  }, []);

  const doPopulateFlowDatasetId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCube(data);
  };

  const doIngestDataPoint = async () => {
    setIngestingQueryId(highlightedQueryId);

    const ingestBody = {
      content: {
        blocks,
        sources,
      },
    };
    const { data: job } = await postChatIngestToBoltzhb(
      { queryId: highlightedQueryId },
      ingestBody
    );
    // await getDataIngestionJobUntilFinish(job?.dataIngestionJobId?.id);

    setIngestingQueryId("");
    setIngestedQueryIds([...ingestedQueryIds, highlightedQueryId]);
  };

  useEffect(() => {
    if (canSeeBottom) {
      scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
    }

    const newCanSeeBottom =
      scrollerRef?.current?.scrollTop + scrollerRef?.current?.clientHeight >=
      scrollerRef?.current?.scrollHeight - 20;
    setCanSeeBottom(newCanSeeBottom);
  }, [canSeeBottom, scrollerRef.current]);

  useEffect(() => {
    const initialQuery = searchParams?.get("initialQuery");
    if (!initialQuery) {
      return;
    }

    const newBlocks = [
      {
        isQuery: true,
        text: initialQuery,
        styles: [
          {
            isSelection: true,
            start: initialQuery?.length,
            end: initialQuery?.length,
          },
        ],
      },
    ];
    setBlocks([
      ...newBlocks,
      { text: "...", isLoading: true, isThrowAway: true },
    ]);
    onPressEnterInCommandInput(initialQuery, newBlocks);
    // setBlocks(WORD_DOC_WITH_REFERENCE?.content?.blocks);
  }, [searchParams?.get("initialQuery")]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, []);

  const doFetchCreatorAvatar = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    const src = data?.image?.includes("/images/")
      ? data?.image
      : `data:image/png;base64,${data?.image}`;
    setAvatarSrc(src || "");
    setLogoSrc(data?.organisation || "");
  };

  const onPressEnterInCommandInput = async (query, payloadBlocks) => {
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    const payload = {
      searchBarQuery: null,
      slashQuery: query,
      cursor: { blockIndex, letterIndex },
      genContext: "word_query",
      blocks: payloadBlocks?.filter(block => !!block),
      sources,
    };

    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        // console.log({ res: data });
        setBlocks(data?.blocks || []);
      },
    });

    setIsGenerating(false);
  };

  const doRerunBlock = async index => {
    const block = blocks?.[index];
    const blocksWithoutSelection = removeSelectionStyle(blocks);
    const blocksWithSelection = addStyleToBlocks({
      blocks: blocksWithoutSelection,
      startBlockIndex: index,
      startLetterIndex: block?.text?.length,
      endBlockIndex: index,
      endLetterIndex: block?.text?.length,
      styleFields: {
        isSelection: true,
      },
    });

    onPressEnterInCommandInput(block?.text, blocksWithSelection);
    const blocksWithLoading = cloneDeep(blocks);
    blocksWithLoading[index + 1].isLoading = true;
    setBlocks(blocksWithLoading);
  };

  const openSidebarType = parseJson(searchParams?.get("openSidebarType"));
  const isSideBarOpen = !!openSidebarType && !isEmpty(openSidebarType);

  return (
    <LayoutNew>
      <Container isSideBarOpen={isSideBarOpen}>
        <TextBlocks
          ref={scrollerRef}
          onScroll={e => {
            const newCanSeeBottom =
              e.target.scrollTop + e.target.clientHeight >=
              e.target.scrollHeight - 20;
            setCanSeeBottom(newCanSeeBottom);
          }}
        >
          {blocks?.map((block, index) => {
            const prevBlock = blocks?.[index - 1];
            const nextBlock = blocks?.[index + 1];

            if (block?.records && block?.columns) {
              return (
                <BlockText
                  isHighlighted={highlightedQueryId === block?.queryId}
                  isIngested={ingestedQueryIds.includes(block?.queryId)}
                  isDisabled={ingestingQueryId === block?.queryId}
                >
                  {(block?.isQuery || prevBlock?.isQuery) && (
                    <div
                      style={{
                        marginTop: "4px",
                        padding: "4px 0",
                        paddingTop: "10px",
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <ProfilePicContainer>
                        <BoltzhubLogoInner
                          style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                          height="14px"
                        />
                      </ProfilePicContainer>
                      <BoldSpan>Assistant</BoldSpan>
                    </div>
                  )}
                  <div style={{ paddingLeft: "35px" }}>
                    <div>{block?.text}</div>
                    <RecordsPlot
                      genCode={block?.genCode}
                      records={block?.records}
                      columns={block?.columns}
                    />
                  </div>
                  {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                    <Actions>
                      <StyledReplay
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                        onClick={() => {
                          const queryBlockIndex = blocks
                            ?.slice(0, index)
                            ?.findLastIndex(qBlock => qBlock?.isQuery);
                          doRerunBlock(queryBlockIndex);
                        }}
                      />
                      <TooltipNew
                        tipContent={
                          <div
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              marginTop: "8px",
                              padding: "2px",
                            }}
                          >
                            {ingestedQueryIds.includes(prevBlock?.queryId)
                              ? "Verified"
                              : "Verify"}
                          </div>
                        }
                      >
                        {ingestedQueryIds.includes(prevBlock?.queryId) ? (
                          <StyledTickIcon
                            style={{
                              fill: "#0191ff",
                              height: "16px",
                              width: "16px",
                            }}
                            onClick={() => {
                              const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                              window.open(url, "_blank");
                            }}
                            onMouseOver={() =>
                              setHighlightedQueryId(prevBlock?.queryId)
                            }
                            onMouseOut={() => setHighlightedQueryId("")}
                          />
                        ) : (
                          <StyledTickIcon
                            style={{ height: "16px", width: "16px" }}
                            onClick={doIngestDataPoint}
                            onMouseOver={() =>
                              setHighlightedQueryId(prevBlock?.queryId)
                            }
                            onMouseOut={() => setHighlightedQueryId("")}
                          />
                        )}
                      </TooltipNew>
                    </Actions>
                  )}
                </BlockText>
              );
            }

            if (block?.isTableCell) {
              if (block?.rowIndex === 0 && block?.columnIndex === 0) {
                const numRows = block?.numberOfRows;
                const numCols = block?.numberOfColumns;
                const tableId = block?.tableId;

                return (
                  <TableConainer>
                    <table>
                      <tbody>
                        {range(numRows).map(rowIndex => (
                          <tr key={`${tableId}-row-${rowIndex}`}>
                            {range(numCols).map(colIndex => {
                              const cellBlock = blocks?.find(
                                b =>
                                  b?.tableId === tableId &&
                                  b?.rowIndex === rowIndex &&
                                  b?.columnIndex === colIndex
                              );

                              const blockTextSegments =
                                getBlockTextSegments(cellBlock);

                              return (
                                <Td
                                  key={`${tableId}-row-${rowIndex}-col-${colIndex}`}
                                >
                                  {blockTextSegments?.map(segment => {
                                    if (segment?.meta) {
                                      return (
                                        <ReferenceModalSpan
                                          meta={segment?.meta}
                                          style={{
                                            backgroundColor: lightenColor(
                                              segment?.bgColor
                                            ),
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {segment?.text}
                                        </ReferenceModalSpan>
                                      );
                                    }
                                    return segment?.text;
                                  })}
                                </Td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableConainer>
                );
              }
              return null;
            }

            if (block?.imageBase64) {
              return (
                <BlockText
                  isDisabled={ingestingQueryId === block?.queryId}
                  style={{
                    backgroundColor: block?.isQuery ? "transparent" : "#f1f1f1",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      border: "1px solid #f1f1f1",
                    }}
                    src={`data:image/png;base64,${block?.imageBase64}`}
                    alt="plot"
                  />
                </BlockText>
              );
            }

            if (block?.isLoading) {
              return (
                <BlockText>
                  {(block?.isQuery || prevBlock?.isQuery) && (
                    <div
                      style={{
                        marginTop: "4px",
                        padding: "4px 0",
                        paddingTop: "10px",
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <ProfilePicContainer>
                        <BoltzhubLogoInner
                          style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                          height="14px"
                        />
                      </ProfilePicContainer>
                      <BoldSpan>Assistant</BoldSpan>
                    </div>
                  )}
                  <div style={{ paddingLeft: "35px" }}>
                    <PulsingDot />
                  </div>
                </BlockText>
              );
            }

            const blockTextSegments = getBlockTextSegments(block);

            return (
              <BlockText
                isHighlighted={
                  highlightedQueryId === block?.queryId && !block?.isQuery
                }
                isIngested={
                  ingestedQueryIds.includes(block?.queryId) && !block?.isQuery
                }
                isDisabled={ingestingQueryId === block?.queryId}
              >
                {(block?.isQuery || prevBlock?.isQuery) && (
                  <div
                    style={{
                      marginTop: "4px",
                      padding: "4px 0",
                      paddingTop: "10px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicContainer>
                      {block?.isQuery && <Img src={avatarSrc} />}
                      {!block?.isQuery && (
                        <BoltzhubLogoInner
                          style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                          height="14px"
                        />
                      )}
                    </ProfilePicContainer>
                    {block?.isQuery ? (
                      <BoldSpan>You</BoldSpan>
                    ) : (
                      <BoldSpan>Assistant</BoldSpan>
                    )}
                  </div>
                )}
                <div style={{ paddingLeft: "35px" }}>
                  {block?.isQuery && (
                    <InputAndSendButton>
                      <StyledInput
                        value={block?.text}
                        onChange={e => {
                          e.target.style.height = e.target.scrollHeight + "px";

                          const newBlocks = cloneDeep(blocks);
                          newBlocks[index].text = e.target.value;
                          setBlocks(newBlocks);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "2px",
                        }}
                      >
                        <StyledBubbleSendIcon
                          onClick={() => doRerunBlock(index)}
                        />
                      </div>
                    </InputAndSendButton>
                  )}
                  <IndentedBlock hasListStyle={isListBlock(block)}>
                    {!block?.isQuery &&
                      blockTextSegments?.map(segment => {
                        let segmentText = segment?.text;
                        if (isListBlock(block)) {
                          segmentText = segment?.text?.replaceAll("* ", "");
                        }

                        if (segment?.url) {
                          return (
                            <a
                              href={segment?.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {segmentText}
                            </a>
                          );
                        }

                        if (segment?.meta) {
                          return (
                            <ReferenceModalSpan
                              meta={segment?.meta}
                              style={{
                                backgroundColor: lightenColor(segment?.bgColor),
                                textDecoration: "underline",
                              }}
                            >
                              {segmentText}
                            </ReferenceModalSpan>
                          );
                        }
                        return segmentText;
                      })}
                  </IndentedBlock>
                </div>
                {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                  <Actions>
                    <StyledReplay
                      style={{
                        height: "16px",
                        width: "16px",
                      }}
                      onClick={() => {
                        const queryBlockIndex = blocks
                          ?.slice(0, index)
                          ?.findLastIndex(qBlock => qBlock?.isQuery);
                        doRerunBlock(queryBlockIndex);
                      }}
                    />
                    <TooltipNew
                      tipContent={
                        <div
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            marginTop: "8px",
                            padding: "2px",
                          }}
                        >
                          {ingestedQueryIds.includes(prevBlock?.queryId)
                            ? "Verified"
                            : "Verify"}
                        </div>
                      }
                    >
                      {ingestedQueryIds.includes(prevBlock?.queryId) ? (
                        <StyledTickIcon
                          style={{
                            fill: "#0191ff",
                            height: "16px",
                            width: "16px",
                          }}
                          onClick={() => {
                            const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                            window.open(url, "_blank");
                          }}
                          onMouseOver={() =>
                            setHighlightedQueryId(prevBlock?.queryId)
                          }
                          onMouseOut={() => setHighlightedQueryId("")}
                        />
                      ) : (
                        <StyledTickIcon
                          style={{ height: "16px", width: "16px" }}
                          onClick={doIngestDataPoint}
                          onMouseOver={() =>
                            setHighlightedQueryId(prevBlock?.queryId)
                          }
                          onMouseOut={() => setHighlightedQueryId("")}
                        />
                      )}
                    </TooltipNew>
                    <TooltipNew tipText="Copy">
                      <StyledFileCopy
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                        onClick={() => {
                          // TODO
                          // console.log("copying", { blocks });
                          // navigator.clipboard.writeText(prevBlock?.text);
                        }}
                      />
                    </TooltipNew>
                  </Actions>
                )}
              </BlockText>
            );
          })}
          <Gap height="10px" />
          {!canSeeBottom && (
            <StyledArrowDown
              onClick={() => {
                scrollerRef.current.scrollTo({
                  top: scrollerRef.current.scrollHeight,
                  behavior: "smooth",
                });
              }}
            />
          )}
        </TextBlocks>

        <InputArea>
          <StyledInputWithSuggestions
            isSideBarOpen={isSideBarOpen}
            style={{ height: bottomInputHeight }}
            disabled={isGenerating}
            value={userInput}
            onChange={e => {
              if (e.target.scrollHeight < 260) {
                setBottomInputHeight(e.target.scrollHeight + "px");
              } else {
                setBottomInputHeight("260px");
              }

              setUserInput(e.target.value);
            }}
            onClickSuggestion={text => setUserInput(text)}
            placeholder="Ask a question"
            onKeyDown={e => {
              if (e.key === "Enter" && e.shiftKey) {
                return;
              }

              if (e.key === "Enter") {
                e.preventDefault();
                setBottomInputHeight("auto");

                const blocksWithoutSelection = removeSelectionStyle(blocks);
                const payloadBlocks = [
                  ...blocksWithoutSelection,
                  {
                    isQuery: true,
                    text: userInput,
                    styles: [
                      {
                        isSelection: true,
                        start: userInput.length,
                        end: userInput.length,
                      },
                    ],
                  },
                ];
                setBlocks([
                  ...payloadBlocks,
                  { text: "...", isLoading: true, isThrowAway: true },
                ]);
                setUserInput("");
                onPressEnterInCommandInput(userInput, payloadBlocks);

                return;
              }
            }}
          />
          <RightButtonsContainer>
            {isGenerating && (
              <StyledCrossIcon
                onClick={() => {
                  abortController.abort();
                  setAbortController(new AbortController());
                  setIsGenerating(false);
                  const newBlocks = cloneDeep(blocks);
                  const updatedBlocks = newBlocks
                    ?.filter(block => !block?.isThrowAway)
                    .map(block => {
                      block.isLoading = false;
                      return block;
                    });
                  setBlocks(updatedBlocks);
                }}
              />
            )}
            {!isGenerating && (
              <>
                <DocSourcesModalTrigger
                  sources={sources}
                  onChangeSources={newSources => setSources(newSources)}
                  trigger={
                    <AttachBtn isActive={sources.length > 0}>
                      <PaperClipIcon height="14px" />
                    </AttachBtn>
                  }
                />
                <AttachBtn
                  isActive
                  onClick={() => {
                    const blocksWithoutSelection = removeSelectionStyle(blocks);
                    const payloadBlocks = [
                      ...blocksWithoutSelection,
                      {
                        isQuery: true,
                        text: userInput,
                        styles: [
                          {
                            isSelection: true,
                            start: userInput.length,
                            end: userInput.length,
                          },
                        ],
                      },
                    ];
                    setBlocks([
                      ...payloadBlocks,
                      { text: "...", isLoading: true, isThrowAway: true },
                    ]);
                    setUserInput("");
                    onPressEnterInCommandInput(userInput, payloadBlocks);
                    setBottomInputHeight("auto");
                  }}
                >
                  <SendIcon height="12px" />
                </AttachBtn>
              </>
            )}
          </RightButtonsContainer>
        </InputArea>
        <SourceFilesList />
      </Container>
    </LayoutNew>
  );
};

export default QuickChatStreamPage;
