import { useState, useEffect } from "react";
import { getFile } from "api/services/filesService";

const usePollFilesStatuses = (fileIds = []) => {
  const [statuses, setStatuses] = useState([]);
  const [pollIntervalId, setPollIntervalId] = useState(null);

  useEffect(() => {
    if (!fileIds?.[0]) {
      setStatuses([]);
      return;
    }

    const intervalId = setInterval(doPopulateFilesStatuses, 2000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [JSON.stringify(fileIds)]);

  useEffect(() => {
    if (
      (statuses?.length > 0 && statuses?.every(status => status === "DONE")) ||
      statuses?.some(status => status === "FAILED")
    ) {
      clearInterval(pollIntervalId);
    }

    return () => clearInterval(pollIntervalId);
  }, [JSON.stringify(statuses)]);

  const doPopulateFilesStatuses = async () => {
    const filesResponses = await Promise.all(fileIds?.map(fileId => getFile(fileId, { strip: true })));
    setStatuses(filesResponses?.map(fileResponse => fileResponse?.data?.status));
  };

  return statuses;
};

export default usePollFilesStatuses;
