import { apiGetRawResponse } from "api/api-http-methods";
import { zapiGet } from "api/zapi";

export const getEmailContent = async emailId => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/emails/syndicate/files/${emailId}/source`);
  return { data, error };
};

export const getEmail = async emailId => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/emails/syndicate/files/${emailId}`);
  return { data, error };
};

export const triggerDownloadOfEmailAttachment = async (fileId, attachmentNumber) => {
  const res = await apiGetRawResponse(
    `/solutions/ocr/api/v1/emails/syndicate/files/${fileId}/attachment/${attachmentNumber}`
  );
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const contentDispositionHeader = res?.headers.get("Content-Disposition");
  const fileName = contentDispositionHeader?.split("filename=")?.[1];

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = fileName || "file.xlsx";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};
