import styled from "styled-components";
import { useState } from "react";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SelectInput from "components/ui/SelectInput";

const TriggerButton = styled(Button)`
  padding: 0;
`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
  overflow: auto;

  display: grid;
  align-content: start;
  justify-content: start;
  align-items: center;
  gap: 10px;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(10, auto) 1fr;
  grid-template-areas:
    "title title"
    "sub2 sub2"
    "choose-table choose-column"
    "sub1 sub1"
    "example selected-value"
    "name-text name-input"
    "type-text type-input"
    "value-type-text value-type-input"
    "keywords-text keywords-values"
    "units-text units-input"
    "create-btn error-msg";
`;

const SelectedValue = styled.div`
  grid-area: selected-value;
  font-size: 20px;
  color: ${props => props.theme.color.primary};
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  grid-area: title;
  margin-bottom: 15px;
`;

const ExampleText = styled.div`
  grid-area: example;
`;

const NameText = styled.div`
  grid-area: name-text;
`;

const NameInput = styled(TextInputSquare)`
  grid-area: name-input;
  label {
    padding: 5px 15px;
  }
`;

const CreateButton = styled(Button)`
  grid-area: create-btn;
  justify-self: start;
  align-self: end;
  grid-template-columns: auto;
  gap: 0;
`;

const ValueTypeText = styled(NameText)`
  grid-area: value-type-text;
`;

const ValueTypeInput = styled(SelectInput)`
  grid-area: value-type-input;
`;

const AddLabelModal = ({ selectedStr, onLabelAdded = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [labelStr, setLabelStr] = useState("");
  const [valueType, setValueType] = useState("TEXT");

  return (
    <>
      <TriggerButton value="Add label" onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Add label</Title>
          <ExampleText>Selected value</ExampleText>
          <SelectedValue>{selectedStr}</SelectedValue>
          <ValueTypeText>Value Type</ValueTypeText>
          <ValueTypeInput value={valueType} onSetNewValue={newValueType => setValueType(newValueType)}>
            <option>TEXT</option>
            <option value="NUMERICAL">NUMBER</option>
            <option>DATE</option>
            <option>CURRENCY</option>
          </ValueTypeInput>

          <NameText>Label</NameText>
          <NameInput value={labelStr} onNewInput={newLabel => setLabelStr(newLabel)} />

          <CreateButton
            isDisabled={!labelStr}
            value="Add label"
            variant="highlighted"
            onClick={() => {
              onLabelAdded(labelStr, { name: "USER DEFINED", description: { value_type: valueType } });
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddLabelModal;
