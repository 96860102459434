import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { range } from "lodash";

import { ALPHABET_EXTENDED } from "utils/excel-utils";
import { CalculateIcon } from "components/ui/Icons";

/*

DRAWING CONVENTIONS

0,0 ---> x
|
|  canvas
↓
y

topY    * ------ *
        |        |
        | A CELL |
        |        |
bottomY * ------ *
      leftX     rightX



topY, bottomY, leftX, rightX are all in pixels

a row has an index 0, 1, 2, 3 ...    and topY and bottomY
a column has an index 0, 1, 2, 3 ... and leftX and rightX

*/

// scale factor, without it the canvas is blurry
const SF = 2;

const X_OFFSET = 42;
const Y_OFFSET = 26;

const SCROLL_Y_SENSITIVITY = 18;
const SCROLL_X_SENSITIVITY = 50;

const CELL_PADDING = 4;
const FONT_SIZE_MULTIPLIER = 0.06;

const CONTROL_KEYS = ["Tab", "Enter"];

const drawRowHeader = ({ ctx, rowIndToTopY, rowInd, selectedCellLocation }) => {
  const rectY = Y_OFFSET * SF + rowIndToTopY[rowInd] * SF;
  const rectWidth = X_OFFSET * SF;
  const rectHeight = (rowIndToTopY[rowInd + 1] - rowIndToTopY[rowInd]) * SF;
  const selectedRow = selectedCellLocation?.match(/\d+/)?.[0] - 1;

  ctx.fillStyle = `#fff`;
  if (selectedRow === rowInd) {
    ctx.fillStyle = "#0191ff22";
  }
  ctx.fillRect(0, rectY, rectWidth, rectHeight);
  ctx.strokeStyle = "#5d5d5d";
  ctx.strokeRect(0, rectY, rectWidth, rectHeight);

  ctx.font = "28px Montserrat";
  ctx.fillStyle = "rgb(40,40,40)";
  ctx.fillText(rowInd + 1, X_OFFSET / 2, Y_OFFSET * SF + rowIndToTopY[rowInd] * SF + 32);
};

const drawColHeader = ({ ctx, colIndToLeftX, colInd, selectedCellLocation }) => {
  const rectX = X_OFFSET * SF + colIndToLeftX[colInd] * SF;
  const rectWidth = (colIndToLeftX[colInd + 1] - colIndToLeftX[colInd]) * SF;
  const rectHeight = Y_OFFSET * SF;
  const selectedCol = ALPHABET_EXTENDED.indexOf(selectedCellLocation?.match(/[A-Z]+/)?.[0]);

  ctx.fillStyle = `#fff`;
  if (selectedCol === colInd) {
    ctx.fillStyle = "#0191ff22";
  }
  ctx.fillRect(rectX, 0, rectWidth, rectHeight);
  ctx.strokeStyle = "#5d5d5d";
  ctx.strokeRect(rectX, 0, rectWidth, rectHeight);

  ctx.font = "28px Montserrat";
  ctx.fillStyle = "rgb(40,40,40)";
  ctx.fillText(ALPHABET_EXTENDED[colInd], X_OFFSET * SF + colIndToLeftX[colInd] * SF + rectWidth / 2 - 14, 32);
};

const drawText = ({ ctx, cell, topY, leftX, cellWidth }) => {
  if (!cell) {
    return;
  }

  const fontSize = (cell?.fontSize || 200) * SF * FONT_SIZE_MULTIPLIER;

  ctx.font = `${cell?.fontItalic ? "italic" : ""} ${cell?.fontBold ? "600" : ""} ${fontSize}px Montserrat`;
  ctx.fillStyle = `#${cell?.fontColor?.slice(2) || "000000"}`;

  let [excelHorizAlign] = cell?.align?.split(";") || [];

  let ctxTextAlign = "left";
  if (excelHorizAlign === "RIGHT") {
    ctxTextAlign = "right";
  }
  if (excelHorizAlign === "GENERAL") {
    if (cell?.dataType === "NUMERIC" || cell?.dataType === "FORMULA") {
      ctxTextAlign = "right";
    }
  }
  if (excelHorizAlign === "CENTER") {
    ctxTextAlign = "center";
  }

  const cellValue = cell?.["f-value"] || cell?.value || "";
  const textMeasurement = ctx.measureText(cellValue);

  if (ctxTextAlign === "right" && textMeasurement.width <= cellWidth * SF) {
    ctx.textAlign = "right";
    ctx.fillText(cellValue, -CELL_PADDING * 1.5 + leftX * SF + cellWidth * SF, topY * SF + fontSize);
    ctx.textAlign = "left";
    return;
  }

  if (ctxTextAlign === "center" && textMeasurement.width <= cellWidth * SF) {
    ctx.textAlign = "center";
    ctx.fillText(cellValue, leftX * SF + (cellWidth * SF) / 2, topY * SF + fontSize);
    ctx.textAlign = "left";
    return;
  }

  ctx.textAlign = "left";
  ctx.fillText(cellValue, CELL_PADDING + leftX * SF + 4, CELL_PADDING + topY * SF + fontSize);
};

const drawCellBorder = ({ ctx, cell, topY, leftX, cellWidth, cellHeight }) => {
  let [top, right, bottom, left] = cell?.boarder?.split(";") || [];
  if (top === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF, topY * SF + 1);
    ctx.lineTo(leftX * SF + cellWidth * SF, topY * SF + 1);
  }
  if (bottom === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF, topY * SF + cellHeight * SF - 1);
    ctx.lineTo(leftX * SF + cellWidth * SF, topY * SF + cellHeight * SF - 1);
    ctx.stroke();
  }
  if (left === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF + 1, topY * SF);
    ctx.lineTo(leftX * SF + 1, topY * SF + cellHeight * SF);
    ctx.stroke();
  }
  if (right === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF + cellWidth * SF - 1, topY * SF);
    ctx.lineTo(leftX * SF + cellWidth * SF - 1, topY * SF + cellHeight * SF);
    ctx.stroke();
  }
};

const drawBlueRectAroundSelectedCell = ({ ctx, selectedCellLocation, rowIndToTopY, colIndToLeftX, grid }) => {
  if (!selectedCellLocation) {
    return;
  }

  const selectedRow = selectedCellLocation?.match(/\d+/)?.[0] - 1;
  const selectedCol = ALPHABET_EXTENDED.indexOf(selectedCellLocation?.match(/[A-Z]+/)?.[0]);

  const topY = Y_OFFSET + rowIndToTopY?.[selectedRow];
  const cellWidth = grid?.columnIndexToWidth?.[selectedCol] ?? 100;
  const leftX = X_OFFSET + colIndToLeftX?.[selectedCol];
  const cellHeight = grid?.rowIndexToHeight?.[selectedRow] ?? 26;

  ctx.strokeStyle = "#0191ff";
  ctx.lineWidth = 4;
  ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
  ctx.lineWidth = 1;
};

const drawCells = ({ ctx, window, grid, cells, selectedCellLocation }) => {
  ctx.clearRect(0, 0, 100000, 100000);

  const { startRow, endRow, startCol, endCol } = window || {};

  const rowIndToTopY = getRowIndToTopY(startRow, endRow, grid);
  const colIndToLeftX = getColIndToLeftX(startCol, endCol, grid);

  range(startRow, endRow).forEach(rowInd => {
    drawRowHeader({ ctx, rowIndToTopY, rowInd, selectedCellLocation });
    range(startCol, endCol).forEach(colInd => {
      if (rowInd === startRow) {
        drawColHeader({ ctx, colIndToLeftX, colInd, selectedCellLocation });
      }

      const topY = Y_OFFSET + rowIndToTopY[rowInd];
      const cellWidth = grid?.columnIndexToWidth?.[colInd] ?? 100;
      const leftX = X_OFFSET + colIndToLeftX[colInd];
      const cellHeight = grid?.rowIndexToHeight?.[rowInd] ?? 26;

      const cellId = `${ALPHABET_EXTENDED[colInd]}${rowInd + 1}`;
      const cell = cells?.[cellId];

      ctx.fillStyle = `#${cell?.bgColor?.slice(2) || "fff"}`;

      ctx.fillRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
      ctx.strokeStyle = "#ccc";
      ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);

      drawCellBorder({ ctx, cell, topY, leftX, cellWidth, cellHeight });
      drawText({ ctx, cell, topY, leftX, cellWidth });
    });
  });

  drawBlueRectAroundSelectedCell({ ctx, selectedCellLocation, rowIndToTopY, colIndToLeftX, grid });
};

const getRowIndToTopY = (startRow, endRow, grid) => {
  const rowIndToTopY = {};
  let y = 0;
  range(startRow, endRow).forEach(i => {
    rowIndToTopY[i] = y;
    y += grid?.rowIndexToHeight?.[i] ?? 26;
  });
  return rowIndToTopY;
};

const getColIndToLeftX = (startCol, endCol, grid) => {
  const colIndToLeftX = {};
  let x = 0;
  range(startCol, endCol).forEach(i => {
    colIndToLeftX[i] = x;
    x += grid?.columnIndexToWidth?.[i] ?? 100;
  });
  return colIndToLeftX;
};

const getWindow = (scrollTop, scrollLeft, grid = {}, canvasSize = {}) => {
  const startRow = Math.floor(scrollTop / SCROLL_Y_SENSITIVITY);
  let endRow = startRow;
  let y = 0;
  while (y < canvasSize.height) {
    endRow++;
    y += grid?.rowIndexToHeight?.[endRow] ?? 26;
  }

  const startCol = Math.floor(scrollLeft / SCROLL_X_SENSITIVITY);
  let endCol = startCol;
  let x = 0;
  while (x < canvasSize.width) {
    endCol++;
    x += grid?.columnIndexToWidth?.[endCol] ?? 100;
  }

  return { startRow, endRow, startCol, endCol: endCol + 2 };
};

const getCellLocationFromOffset = (offsetX, offsetY, grid, viewWindow) => {
  const rowIndex = getRowIndexFromOffset(offsetY, grid, viewWindow?.startRow);
  const colIndex = getColIndexFromOffset(offsetX, grid, viewWindow?.startCol);

  return `${ALPHABET_EXTENDED[colIndex]}${rowIndex + 1}`;
};

const getRowIndexFromOffset = (offsetY, grid, startRow) => {
  let bottomY = Y_OFFSET;
  let rowIndex = startRow;

  while (bottomY < offsetY) {
    bottomY += grid?.rowIndexToHeight?.[rowIndex] ?? 26;
    rowIndex++;
  }

  return rowIndex - 1;
};

const getColIndexFromOffset = (offsetX, grid, startCol) => {
  let rightX = X_OFFSET;
  let colIndex = startCol;

  while (rightX < offsetX) {
    rightX += grid?.columnIndexToWidth?.[colIndex] ?? 100;
    colIndex++;
  }

  return colIndex - 1;
};

const getSelectedCellStyle = (cellLocation, grid, viewWindow) => {
  if (!cellLocation) {
    return { display: "none" };
  }

  const rowIndex = cellLocation?.match(/\d+/)?.[0] - 1;
  const columnIndex = ALPHABET_EXTENDED.indexOf(cellLocation?.match(/[A-Z]+/)?.[0]);

  if (
    rowIndex < viewWindow?.startRow ||
    rowIndex > viewWindow?.endRow ||
    columnIndex < viewWindow?.startCol ||
    columnIndex > viewWindow?.endCol
  ) {
    return { display: "none" };
  }

  const topY = Y_OFFSET + getRowIndToTopY(viewWindow?.startRow, rowIndex + 1, grid)[rowIndex];
  const leftX = X_OFFSET + getColIndToLeftX(viewWindow?.startCol, columnIndex + 1, grid)[columnIndex];

  const width = grid?.columnIndexToWidth?.[columnIndex] ?? 100;
  const height = grid?.rowIndexToHeight?.[rowIndex] ?? 26;

  return { top: `${188 + topY}px`, left: `${leftX}px`, width: `${width}px`, height: `${height}px` };
};

const CellsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  border-top: 1px solid ${props => props.theme.color.closer2};
  scroll-behavior: auto;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 24px 1fr;
  width: 100%;
  height: 100%;
`;

const GridCanvas = styled.canvas`
  width: ${props => props.viewportWidth}px;
  height: ${props => props.viewportHeight}px;

  position: fixed;
  top: calc(124px + 24px + 40px);
  left: 0;
  pointer-events: none;
`;

const LongEmptyDiv = styled.div`
  width: 1000000px;
`;

const TallEmptyDiv = styled.div`
  position: absolute;
  left: 0;
  height: 1000000px;
  width: 1px;
  visibility: none;
`;

const TopLeftCorner = styled.div`
  padding: 4px;
  overflow: hidden;
  position: fixed;
  top: calc(124px + 24px + 40px);
  left: 0;
  width: ${X_OFFSET}px;
  height: ${Y_OFFSET - 1}px;
  background-color: ${props => props.theme.color.furthest};
  z-index: 100;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const FormulaBar = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  border-top: 1px solid ${props => props.theme.color.closer1_5};

  svg {
    padding: 0 5px;
    align-self: center;
    opacity: 0.4;
    height: 20px;
  }
`;

const FormulaTextInput = styled.input`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-top: none;
  border-bottom: none;
  border-right: none;
  outline: none;
  border-radius: 0;
  font-family: "Montserrat", sans-serif;
`;

const SelectedCellDiv = styled.div`
  position: fixed;
  z-index: 1;
`;

const ExcelViewSheetViewOnly = ({
  viewWindow = { startRow: 0, startCol: 0, endRow: 30, endCol: 30 },
  onScrollViewWindow = ({ startRow, startCol, endRow, endCol }) => {},
  cells = {},
  grid = {},
  sheetName,
  renderSelectedCellContent = () => "",
  selectedCellLocation,
  onNewSelectedCellLocation = () => {},
}) => {
  const gridCanvasRef = useRef(null);
  const containerRef = useRef(null);

  const [canvasSize, setCanvasSize] = useState({ width: 200, height: 200 });

  const [isEditing, setIsEditing] = useState(false);
  const [valueToEdit, setValueToEdit] = useState("");

  useEffect(() => {
    containerRef.current.scrollTo(0, 0);
  }, [sheetName]);

  useEffect(() => {
    const { width, height } = containerRef.current.getBoundingClientRect();
    setCanvasSize({ width, height });

    const initialWindow = getWindow(0, 0, grid, { width, height });
    onScrollViewWindow(initialWindow);
  }, [JSON.stringify(grid)]);

  useEffect(() => {
    drawCells({
      ctx: gridCanvasRef.current.getContext("2d"),
      window: viewWindow,
      grid,
      cells,
      selectedCellLocation,
    });

    setIsEditing(false);
    setValueToEdit(selectedCell?.formula || selectedCell?.value || "");
  }, [JSON.stringify(cells), JSON.stringify(grid), JSON.stringify(viewWindow), selectedCellLocation]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [selectedCellLocation, isEditing, valueToEdit]);

  const onScrollDrawGridAndSetLocationsInView = e => {
    const { scrollTop, scrollLeft } = e.target;
    const newWindow = getWindow(scrollTop, scrollLeft, grid, canvasSize);
    onScrollViewWindow(newWindow);
  };

  const onCanvasClick = e => {
    const clickLocation = getCellLocationFromOffset(e.nativeEvent.offsetX, e.nativeEvent.offsetY, grid, viewWindow);
    onNewSelectedCellLocation(clickLocation);
  };

  const onKeyDown = e => {
    if (!CONTROL_KEYS.includes(e.key) || !selectedCellLocation) {
      return;
    }

    if (e.key === "Enter") {
      if (isEditing) {
        setIsEditing(false);
        return;
      }

      setIsEditing(true);
      return;
    }

    e.preventDefault();

    let rowIndex = selectedCellLocation?.match(/\d+/)?.[0] - 1;
    let columnIndex = ALPHABET_EXTENDED.indexOf(selectedCellLocation?.match(/[A-Z]+/)?.[0]);

    if (e.key === "ArrowUp") {
      rowIndex--;
    }
    if (e.key === "ArrowDown") {
      rowIndex++;
    }
    if (e.key === "ArrowLeft") {
      columnIndex--;
    }
    if (e.key === "ArrowRight" || e.key === "Tab") {
      columnIndex++;
    }

    const newSelectedCellLocation = `${ALPHABET_EXTENDED[columnIndex]}${rowIndex + 1}`;
    onNewSelectedCellLocation(newSelectedCellLocation);
  };

  const selectedCell = cells?.[selectedCellLocation];
  let rowIndex = selectedCellLocation?.match(/\d+/)?.[0] - 1;
  let columnIndex = ALPHABET_EXTENDED.indexOf(selectedCellLocation?.match(/[A-Z]+/)?.[0]);

  return (
    <Container>
      <FormulaBar>
        <CalculateIcon />
        <FormulaTextInput
          value={valueToEdit}
          disabled={!selectedCellLocation}
          onFocus={() => setIsEditing(true)}
          onBlur={() => setIsEditing(false)}
          onChange={e => setValueToEdit(e.target.value)}
        />
      </FormulaBar>

      <CellsContainer ref={containerRef} onScroll={onScrollDrawGridAndSetLocationsInView} onClick={onCanvasClick}>
        <TopLeftCorner>{selectedCellLocation || ""}</TopLeftCorner>
        <LongEmptyDiv />
        <TallEmptyDiv />

        {selectedCellLocation && (
          <SelectedCellDiv
            // key and id needed for onWheel fix, see :fileId.js
            key={`${rowIndex}-${columnIndex}`}
            id={`selected-cell-div-${rowIndex}-${columnIndex}`}
            style={getSelectedCellStyle(selectedCellLocation, grid, viewWindow)}
          >
            {renderSelectedCellContent({ rowIndex, columnIndex })}
          </SelectedCellDiv>
        )}

        <GridCanvas
          viewportWidth={canvasSize?.width}
          viewportHeight={canvasSize?.height}
          width={canvasSize?.width * SF}
          height={canvasSize?.height * SF}
          ref={gridCanvasRef}
        />
      </CellsContainer>
    </Container>
  );
};

export default ExcelViewSheetViewOnly;
