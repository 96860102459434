import styled from "styled-components";
import { useState, useEffect } from "react";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import {
  deleteFlowDatabaseTemplates,
  getFlowDatabaseTemplates,
} from "api/services/chatService";
import CreateFlowDatabaseModal from "components/CreateFlowDatabaseModal";
import EditFlowDatabaseModal from "components/EditFlowDatabaseModal";
import EditFlowDatabaseChatModal from "components/EditFlowDatabaseChatModal";

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding-bottom: 100px;
`;

const Th = styled.th`
  font-weight: 600;
  padding: 8px 12px;
  background-color: ${props => props.theme.color.closer0};
  border: 1px solid ${props => props.theme.color.closer1};
  text-align: left;
`;

const Td = styled.td`
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 12px;

  vertical-align: middle;
  border: 1px solid ${props => props.theme.color.closer1};
  text-align: left;
`;

const FlowTemplatesPage = () => {
  const [templates, setTemplates] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);

  useEffect(() => {
    doPopulateDirectoryItems();
  }, []);

  const doPopulateDirectoryItems = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  return (
    <StyledCenteredWithTopNavLayout>
      <CreateFlowDatabaseModal />
      <Gap />
      <table>
        <thead>
          <tr>
            <Th>Name</Th>
            <Th>Prompt</Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {templates
            .filter(template => !deletedIds?.includes(template?._id))
            .map(template => (
              <tr key={template._id}>
                <Td>
                  {template.wordDocTemplate?.fileName?.replaceAll(
                    "template_",
                    ""
                  )}
                </Td>
                <Td>{template.prompt}</Td>
                <Td>
                  <EditFlowDatabaseModal template={template} />
                  <EditFlowDatabaseChatModal template={template} />
                  <button
                    onClick={async () => {
                      await deleteFlowDatabaseTemplates(template._id);
                      setDeletedIds([...deletedIds, template._id]);
                    }}
                  >
                    Delete
                  </button>
                </Td>
              </tr>
            ))}
        </tbody>
      </table>
    </StyledCenteredWithTopNavLayout>
  );
};

export default FlowTemplatesPage;
