import { getDirectoryItems } from "api/services/fileSystem";
import { WarningIcon } from "components/ui/Icons";
import { last } from "lodash";
import { useState, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OptionsContainer = styled.div`
  position: absolute;
  z-index: 200;
  background-color: ${props => props.theme.color.furthest};
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 40px 0 ${props => props.theme.color.closer1_5};
  white-space: nowrap;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const MarginRect = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${props => props.theme.color.closer0};
  position: sticky;
`;

const Option = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 8px 8px;
  min-width: 200px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${props => props.theme.color.closer0};
  ${props =>
    props.isHighlighted && `background-color: ${props.theme.color.closer1};`}
  ${props => props.isDisabled && `pointer-events: none; opacity: 0.5;`}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 12px;
  font-family: "Montserrat";
  border: none;
  outline: none;
  background-color: ${props => props.theme.color.closer0};
  border: none;
  border-radius: 4px;
  /* border-image: linear-gradient(
    ${props => props.theme.color.closer2}22,
    ${props => props.theme.color.closer2}22
  ); */
  :focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const CONTROL_KEYS = ["ArrowDown", "ArrowUp", "Enter"];

const SearchableSelectInput = ({
  placeholder,
  searchValue,
  setSearchValue,
  onClickOption = newText => {},
  options = [],
  style = {},
  className = "",
}) => {
  const [isFocussed, setIsFocussed] = useState(false);

  const inputRef = useRef(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const filteredOptions = options?.filter(option => {
    const searchedValue = last(searchValue?.split("/"))?.toLowerCase();
    return option?.label?.toLowerCase()?.includes(searchedValue);
  });

  const handleArrowKeysAndEnter = e => {
    if (!filteredOptions?.length) {
      return;
    }

    if (CONTROL_KEYS.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "ArrowDown") {
      setSelectedOptionIndex(ind =>
        Math.min(ind + 1, filteredOptions?.length - 1)
      );
    }

    if (e.key === "ArrowUp") {
      setSelectedOptionIndex(ind => Math.max(ind - 1, -1));
    }

    if (e.key === "Enter" && selectedOptionIndex !== -1) {
      onClickOption(filteredOptions[selectedOptionIndex]);
      // setSearchValue("");
      inputRef?.current?.focus();
      setSelectedOptionIndex(-1);
    }
  };

  return (
    <Container style={style} className={className}>
      <StyledInput
        placeholder={placeholder}
        ref={inputRef}
        onBlur={() => setIsFocussed(false)}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onKeyDown={handleArrowKeysAndEnter}
        onFocus={() => setIsFocussed(true)}
      />
      {isFocussed && (
        <OptionsContainer>
          <MarginRect style={{ top: "0" }} />
          {filteredOptions?.map((option, i) => (
            <Option
              isDisabled={option?.isDisabled}
              key={option?.id}
              isHighlighted={selectedOptionIndex === i}
              onMouseDown={async e => {
                e.stopPropagation();
                if (e.shiftKey) {
                  e.preventDefault();
                }

                if (option?.path?.startsWith("/template-files/")) {
                  const { data } = await getDirectoryItems({
                    directoryPath: option?.path,
                  });
                  const templateFile = data?.items?.find(
                    file => file?.isTemplate
                  );
                  onClickOption(templateFile);
                  return;
                }
                onClickOption(option);

                if (option?.type === "DIRECTORY") {
                  setTimeout(() => inputRef?.current?.focus(), 10);
                  setSelectedOptionIndex(-1);
                  return;
                }

                setSearchValue(
                  `${searchValue?.split("/")?.slice(0, -1)?.join("/")}/`
                );
                setSelectedOptionIndex(-1);
              }}
            >
              {option?.isFailed && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <WarningIcon height="12px" style={{ fill: "#d4a600" }} />
                  <span
                    style={{
                      color: "#d4a600",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                  >
                    FAILED
                  </span>
                </div>
              )}
              {option?.icon}
              {option?.label}
            </Option>
          ))}
          <MarginRect style={{ bottom: "0" }} />
        </OptionsContainer>
      )}
    </Container>
  );
};

export default SearchableSelectInput;
