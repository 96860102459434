import { useState, useEffect } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import { getFile, getFilePageResults } from "api/services/filesService";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import ModalSerchResults from "components/ModalSearchResults";
import SmallButton from "components/ui/SmallButton";
import { BoltzhubLogoInner, CubeIcon, TickIcon } from "components/ui/Icons";
import { postEmailsIngestToBoltzhub } from "api/services/chatService";
import { getCubesFindByKey } from "api/services/projectService";
import { Gap } from "components/Layout";

const ModalContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  min-height: 620px;
  min-width: 727px;
  max-width: 1000px;
  overflow: auto;
  border-radius: 0;

  display: grid;
  gap: 10px;

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"};
`;

const CornerSquare = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const FileTextAndLink = styled.div`
  font-size: 22px;
`;

const FileLink = styled.div`
  display: inline;
  text-decoration: none;
  color: ${props => props.theme.color.primary};
  cursor: pointer;
`;

const ErrMsg = styled.div`
  margin-top: 64px;
`;

const OutputContainer = styled.div`
  grid-area: output;
  overflow: auto;
`;

const PageImage = styled(ImgWithRectangleFlexible)`
  height: 600px;
`;

const ImageScrollingContainer = styled.div`
  height: 600px;
  overflow: auto;
`;

const OutputItemText = styled.div`
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
  height: max-content;
  width: max-content;
  white-space: pre;
`;

const ReferenceEditingContainer = styled.div`
  display: grid;
  grid-template-columns: auto 100px auto auto 1fr auto auto;
  justify-content: start;
  gap: 8px;
  align-items: center;
`;

const StyledInput = styled.input`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 4px;
`;

const RefText = styled.div`
  max-width: 800px;
  line-height: 1.5;
  padding: 0px 0;
  font-size: 18px;
  font-weight: 600;
  white-space: pre-wrap;
`;

const VerifiedBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0px;
  font-size: 12px;
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.primary}88;
  justify-self: end;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  svg {
    color: ${props => props.theme.color.primary};
  }

  :hover {
    background-color: ${props => props.theme.color.closer1_5}66;
  }
`;

const BlueTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.primary};
`;

const UnverifiedBtn = styled(VerifiedBtn)`
  border: 1px solid ${props => props.theme.color.closer2}88;
  color: ${props => props.theme.color.closer2};
  svg {
    color: ${props => props.theme.color.closer2};
  }
`;

const GreyTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.closer2};
`;

const DatasetA = styled.a`
  justify-self: end;
  position: relative;
  display: flex;
  padding: 3px 4px;
  align-items: center;
  gap: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};

  :hover {
    background-color: ${props => props.theme.color.closer1_5}66;
  }
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const IconButtonNoIconHeight = styled(IconButton)`
  justify-self: end;
  svg {
    height: unset;
  }
`;

const PagePreviewModalContent = ({
  tableDocumentLocation = {},
  solrId = "",
  isReferenceEditable = false,
  columnName = "",
  columnValue = "",
  columnDescription = "",
  onPressSaveValue = (newValue, newAnnotationId) => {},
  onPressVerify = checked => {},
  checked = false,
  refText = "",
}) => {
  const [file, setFile] = useState(null);
  const [pageResults, setPageResults] = useState(null);
  const [pageResultsErr, setPageResultsErr] = useState(null);
  const [referenceValue, setReferenceValue] = useState(columnValue);
  const [isDisabled, setIsDisabled] = useState(false);
  const [newAnnotationId, setNewAnnotationId] = useState(null);
  const [flowCubeId, setFlowCubeId] = useState(null);
  const [isIngesting, setIsIngesting] = useState(false);

  useEffect(() => {
    doPopulateFlowDatasetId();
  }, [isIngesting]);

  const doPopulateFlowDatasetId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCubeId(data?.id);
  };

  useEffect(() => {
    setReferenceValue(columnValue);
    setNewAnnotationId(
      tableDocumentLocation?.annotationId || tableDocumentLocation?.blockId
    );
  }, [columnValue, JSON.stringify(tableDocumentLocation)]);

  let {
    fileId,
    pageNumber,
    annotationId,
    fileName,
    description,
    reference,
    references,
  } = tableDocumentLocation || {};

  reference = reference || {};
  annotationId = annotationId || tableDocumentLocation?.blockId;

  const referencesWithIds =
    references?.map((ref, i) => ({
      ...ref,
      id: `reference_rect_${i}`,
    })) || [];

  useEffect(() => {
    doPopulatePageResults(fileId, pageNumber);
    doPopulateFile(fileId);
  }, [fileId, pageNumber]);

  const doPopulatePageResults = async (previewFileId, previewPageNumber) => {
    setPageResultsErr(null);
    setPageResults(null);
    if (!fileId || pageNumber === undefined || pageNumber === null) {
      return;
    }
    const { data, error } = await getFilePageResults(
      previewFileId,
      previewPageNumber
    );
    setPageResults(data);
    setPageResultsErr(error);
  };

  const doPopulateFile = async fileId => {
    const { data } = await getFile(fileId);
    setFile(data);
  };

  const doIngestFlowDataPoint = async () => {
    setIsIngesting(true);
    const ingestBody = {
      emailFileId: tableDocumentLocation?.emailId,
      prompt: `Extract "${columnName}", with description "${columnDescription}"`,
      generatedText: `${columnValue}`,
    };
    await postEmailsIngestToBoltzhub({}, ingestBody);
    setIsIngesting(false);
  };

  const { annotations_0, annotations_1, annotations_2, annotations_3 } =
    pageResults || {};

  const allAnnotations = [
    annotations_0,
    annotations_1,
    annotations_2,
    annotations_3,
  ]?.flat();

  const hubBaseUrl = "https://hub.boltzbit.com";

  return (
    <div
      isDisabled={isDisabled}
      onMouseDown={e => {
        e.stopPropagation();
        // e.preventDefault();
      }}
      onMouseUp={e => {
        e.stopPropagation();
        // e.preventDefault();
      }}
    >
      <FileTextAndLink>
        <FileLink
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            // let url = `/file/${fileId}?pageNumber=${pageNumber}&annotationIdToHighlight=${annotationId}`;
            // let extraParams = `&solrId=${solrId}&tableName=${tableName}&columnName=${cellLineItem}&solrValue=${solrValue}&tableId=${tableId}&rowIdName=${rowIdName}&rowIdValue=${cellRowIdValue}`;

            let url = `/file/${fileId}?pageNumber=${pageNumber}&selectedOutputItemId=${annotationId}`;
            if (fileName?.includes(".xlsx")) {
              url = `/excel-files/${fileId}?pageNumber=${pageNumber}&selectedOutputItemId=${annotationId}`;
            }
            let extraParams = `&solrId=${solrId}`;

            window.open(`${url}${extraParams}`, "_blank").focus();
          }}
        >
          {fileName || file?.fileName} (page {pageNumber + 1})
        </FileLink>
      </FileTextAndLink>
      <Gap />
      <RefText>{refText || description}</RefText>
      <Gap />
      {isReferenceEditable && (
        <ReferenceEditingContainer>
          <div>{columnName}</div>
          <StyledInput
            value={referenceValue}
            onChange={e => setReferenceValue(e.target.value)}
          />
          <SmallButton
            onClick={async () => {
              setIsDisabled(true);
              await onPressSaveValue(referenceValue, newAnnotationId);
              setIsDisabled(false);
            }}
            isDisabled={
              referenceValue === columnValue && annotationId === newAnnotationId
            }
            value="Save"
          />
          <SmallButton
            isDisabled={
              referenceValue === columnValue && annotationId === newAnnotationId
            }
            onClick={() => {
              setReferenceValue(columnValue);
              setNewAnnotationId(annotationId);
            }}
            value="Cancel"
          />
          {flowCubeId ? (
            <DatasetA
              href={`${hubBaseUrl}/cube/${flowCubeId}?tab=Dataset`}
              target="_blank"
            >
              <BoltzhubLogoInner height="14px" />
              <CubeIcon height="14px" />
            </DatasetA>
          ) : (
            <div />
          )}
          {checked ? (
            <VerifiedBtn
              onClick={() => {
                onPressVerify(false);
              }}
            >
              Verified
              <BlueTickIcon />
            </VerifiedBtn>
          ) : (
            <UnverifiedBtn
              isDisabled={isIngesting}
              onClick={async () => {
                await doIngestFlowDataPoint();
                onPressVerify(true);
              }}
            >
              Unverified
              <GreyTickIcon />
            </UnverifiedBtn>
          )}
        </ReferenceEditingContainer>
      )}
      {/* <OutputContainer>
          {outputItem?.type === "text" && (
            <OutputItemText
              dangerouslySetInnerHTML={{
                __html: outputItem?.content?.replaceAll(
                  cellValue,
                  `<span style="color: #0191ff;">${cellValue}</span>`
                ),
              }}
            />
          )}
          {outputItem?.type === "table" && (
            <TableViewerScanned
              table={outputItem}
              isSaveButtonDisabled
              boldedCellValue={cellValue}
            />
          )}
        </OutputContainer> */}
      {pageResultsErr?.message?.includes("File not found") && (
        <ErrMsg>You have no access to this file</ErrMsg>
      )}
      <ImageScrollingContainer>
        {!pageResultsErr && (
          <PageImage
            isLoading={!pageResults?.image}
            newAnnotationId={newAnnotationId ?? annotationId}
            onClickBox={id => setNewAnnotationId(id)}
            selectedAnnotationId={annotationId}
            rectangles={[
              ...allAnnotations,
              { id: "reference_rect", ...reference },
              ...referencesWithIds,
            ]}
            src={`data:image/png;base64,${pageResults?.image || ""}`}
            alt="page preview"
          />
        )}
      </ImageScrollingContainer>
      <CornerSquare />
      <CornerSquare style={{ top: 0, right: 0 }} />
      <CornerSquare style={{ bottom: 0, left: 0 }} />
      <CornerSquare style={{ bottom: 0, right: 0 }} />
    </div>
  );
};

export default PagePreviewModalContent;
