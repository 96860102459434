import {
  cloneDeep,
  findLastIndex,
  groupBy,
  range,
  round,
  sum,
  uniq,
} from "lodash";
import { parseJson } from "utils/common";

export const X_OFFSET = 42;
export const Y_OFFSET = 26;

export const DEFAULT_CELL_HEIGHT = 22;

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const ALPHABET_EXTENDED = [
  ...ALPHABET,
  ...ALPHABET?.flatMap(letter1 => ALPHABET?.map(letter2 => letter1 + letter2)),
];

const isLetterWithinRange = (letter, startLetter, endLetter) => {
  const startLetterIndex = ALPHABET_EXTENDED.indexOf(startLetter);
  const endLetterIndex = ALPHABET_EXTENDED.indexOf(endLetter);
  const containedLetters = ALPHABET_EXTENDED.slice(
    startLetterIndex,
    endLetterIndex + 1
  );

  return containedLetters?.includes(letter);
};

export const isCellIdWithinSelection = (cellId, selectionStr) => {
  if (selectionStr === cellId) {
    return true;
  }

  if (!selectionStr || selectionStr?.split(":")?.length === 1) {
    return false;
  }

  const [startCellId, endCellId] = selectionStr?.split(":") || [];

  const startLetter = startCellId.match(/[A-Z]+/)?.[0];
  const startNumber = parseInt(startCellId.match(/[0-9]+/)?.[0]);

  const endLetter = endCellId?.match(/[A-Z]+/)?.[0];
  const endNumber = parseInt(endCellId.match(/[0-9]+/)?.[0]);

  const cellLetter = cellId?.match(/[A-Z]+/)?.[0];
  const cellNumber = parseInt(cellId?.match(/[0-9]+/)?.[0]);

  if (
    isLetterWithinRange(cellLetter, startLetter, endLetter) &&
    cellNumber >= startNumber &&
    cellNumber <= endNumber
  ) {
    return true;
  }

  return false;
};

export const getAllValuesOfKey = (records, fieldName) => {
  const names = records?.map(rec => rec[fieldName]);
  return uniq(names).sort();
};

export const getOffsetCellLocation = (
  startingLocation = "A1",
  rowOffset,
  colOffset
) => {
  const startingCol = startingLocation.match(/[A-Z]*/)[0];
  const startingRow = parseInt(startingLocation?.match(/[0-9].*/)[0]);

  const newColIndex = ALPHABET_EXTENDED.indexOf(startingCol) + colOffset;
  const newColLetter = ALPHABET_EXTENDED[newColIndex];
  const newRow = startingRow + rowOffset;

  return `${newColLetter}${newRow}`;
};

export const getSheetWithSmartRecordsFilledIn = ({
  sheet,
  upperLeftCornerLocation = "A1",
  smartRecords,
  columnNamesInSelectedRow,
}) => {
  const newCells = { ...sheet?.cells };
  const newCellMetadata = { ...sheet?.cellMetadata };

  if (smartRecords?.length === 0) {
    return { ...sheet };
  }

  let listOfRows = [];
  let listOfTableDocumentLocations = [];
  let cellLocationsToUpdate = [];

  const idToRecords = groupBy(smartRecords, record => record?.ID);
  Object.entries(idToRecords).forEach(([id, records]) => {
    const row = columnNamesInSelectedRow?.map(colName => {
      const smartRecord = records.find(
        rec => rec.TOPIC?.trim() === colName?.trim()
      );
      return smartRecord?.Value ? parseJson(smartRecord?.Value)?.Value : null;
    });

    const tableDocumentLocations = columnNamesInSelectedRow?.map(colName => {
      const smartRecord = records.find(
        rec => rec.TOPIC?.trim() === colName?.trim()
      );
      return smartRecord?.Value
        ? parseJson(smartRecord?.Value)?.tableDocumentLocation
        : null;
    });

    listOfRows.push(row);
    listOfTableDocumentLocations.push(tableDocumentLocations);
  });

  let locationToFill = upperLeftCornerLocation;
  listOfRows?.forEach((row, rowIndex) => {
    row?.forEach((cellValue, colIndex) => {
      if (!cellValue) {
        return;
      }
      locationToFill = getOffsetCellLocation(
        upperLeftCornerLocation,
        rowIndex,
        colIndex
      );
      cellLocationsToUpdate?.push(locationToFill);

      newCells[locationToFill] = {
        value: cellValue,
        formula: "",
        dataType: typeof cellValue === "number" ? "NUMERIC" : "STRING",
      };

      if (listOfTableDocumentLocations[rowIndex][colIndex]) {
        newCellMetadata[locationToFill] = JSON.stringify({
          tableDocumentLocation:
            listOfTableDocumentLocations[rowIndex][colIndex],
        });
      }
    });
  });

  return [
    { ...sheet, cellMetadata: newCellMetadata, cells: newCells },
    cellLocationsToUpdate,
  ];
};

export const getSheetWithTableWiped = sheet => {
  if (!sheet?.tableLocation) {
    return sheet;
  }

  const newCells = { ...sheet?.cells };
  Object.keys(newCells).forEach(cellLocation => {
    if (isCellIdWithinSelection(cellLocation, sheet?.tableLocation)) {
      newCells[cellLocation] = {
        value: "",
        formula: "",
        dataType: "STRING",
      };
    }
  });

  return { ...sheet, cells: newCells };
};

export const getArrayOfCellLocationsFromSelection = selectionStr => {
  if (!selectionStr || selectionStr?.split(":")?.length === 1) {
    return [];
  }

  const [startCellId, endCellId] = selectionStr?.split(":") || [];

  const startLetter = startCellId?.[0];
  const startNumber = parseInt(startCellId?.slice(1));

  const endLetter = endCellId?.[0];
  const endNumber = parseInt(endCellId?.slice(1));

  const listOfCellLocations = [];

  range(startNumber, endNumber + 1).forEach(number => {
    range(
      ALPHABET_EXTENDED.indexOf(startLetter),
      ALPHABET_EXTENDED.indexOf(endLetter) + 1
    ).forEach(letterIndex => {
      const letter = ALPHABET[letterIndex];
      listOfCellLocations.push(`${letter}${number}`);
    });
  });

  return listOfCellLocations;
};

export const getArrayOfAllValuesInSelection = (sheet, selectionStr) => {
  const values = [];

  const cellLocations = getArrayOfCellLocationsFromSelection(selectionStr);

  cellLocations.sort().forEach(cellLocation => {
    values.push(sheet?.cells[cellLocation]?.value || "");
  });

  const valuesWithoutTrailingEmptyStrings = values.slice(
    0,
    findLastIndex(values, val => val !== "") + 1
  );

  return valuesWithoutTrailingEmptyStrings;
};

export const getColumnX = (
  columnIndex,
  grid = { columnIndexToWidth: {} },
  viewWindow = { startCol: 0, endCol: 0 }
) => {
  const x = sum(
    range(viewWindow.startCol, columnIndex).map(colIndex => {
      return grid?.columnIndexToWidth?.[colIndex] ?? 100;
    })
  );

  return X_OFFSET + x;
};

export const getRowY = (
  rowIndex,
  grid = { rowIndexToHeight: {} },
  viewWindow = { startRow: 0, endRow: 0 }
) => {
  const y = sum(
    range(viewWindow.startRow, rowIndex).map(rowIndex => {
      return grid?.rowIndexToHeight?.[rowIndex] ?? DEFAULT_CELL_HEIGHT;
    })
  );

  return y;
};

export const drawVerticalLine = ({ x, ctx }) => {
  ctx.clearRect(0, 0, 100000, 100000);

  ctx.lineWidth = 1;
  ctx.strokeStyle = "black";
  ctx.beginPath();
  ctx.moveTo(x, 0);
  ctx.lineTo(x, 100000);
  ctx.stroke();
};

export const drawHorizontalLine = ({ y, ctx }) => {
  ctx.clearRect(0, 0, 100000, 100000);

  ctx.lineWidth = 1;
  ctx.strokeStyle = "black";
  ctx.beginPath();
  ctx.moveTo(0, y);
  ctx.lineTo(100000, y);
  ctx.stroke();
};

const getRowIndexFromOffset = (offsetY, grid, startRow) => {
  let bottomY = Y_OFFSET;
  let rowIndex = startRow;

  while (bottomY < offsetY) {
    bottomY += grid?.rowIndexToHeight?.[rowIndex] ?? DEFAULT_CELL_HEIGHT;
    rowIndex++;
  }

  return rowIndex - 1;
};

const getColIndexFromOffset = (offsetX, grid, startCol) => {
  let rightX = X_OFFSET;
  let colIndex = startCol;

  while (rightX < offsetX) {
    rightX += grid?.columnIndexToWidth?.[colIndex] ?? 100;
    colIndex++;
  }

  return colIndex - 1;
};

export const getCellLocationFromOffset = (
  offsetX,
  offsetY,
  grid,
  viewWindow
) => {
  const rowIndex = getRowIndexFromOffset(offsetY, grid, viewWindow?.startRow);
  const colIndex = getColIndexFromOffset(offsetX, grid, viewWindow?.startCol);

  return `${ALPHABET_EXTENDED[colIndex] || ""}${rowIndex + 1}`;
};

export const getNearestBoundaryColumnIndex = ({ e, grid, viewWindow }) => {
  const mouseLocation = getCellLocationFromOffset(
    e.nativeEvent.offsetX,
    e.nativeEvent.offsetY,
    grid,
    viewWindow
  );
  const rowIndex = mouseLocation?.match(/\d+/)?.[0] - 1;
  if (rowIndex !== -1) {
    return null;
  }

  let nearestColumnIndex = null;
  range(viewWindow?.startCol, viewWindow?.endCol + 1).forEach(colIndex => {
    const x = getColumnX(colIndex, grid, viewWindow);
    if (Math.abs(x - e.nativeEvent.offsetX) < 10) {
      nearestColumnIndex = colIndex - 1;
    }
  });

  return nearestColumnIndex;
};

export const getResizedColumnGrid = ({
  grid,
  columnResizeIndex,
  columnResizeAmount,
}) => {
  const newGrid = cloneDeep(grid);
  if (!newGrid.columnIndexToWidth) {
    newGrid.columnIndexToWidth = [];
  }

  let newWidth =
    (newGrid.columnIndexToWidth[columnResizeIndex] || 100) + columnResizeAmount;
  newWidth = Math.max(newWidth, 10);

  newGrid.columnIndexToWidth[columnResizeIndex] = round(newWidth);

  return newGrid;
};

export const getNearestBoundaryRowIndex = ({ e, grid, viewWindow }) => {
  const mouseLocation = getCellLocationFromOffset(
    e.nativeEvent.offsetX,
    e.nativeEvent.offsetY,
    grid,
    viewWindow
  );
  const colIndex = ALPHABET_EXTENDED.indexOf(
    mouseLocation?.match(/[A-Z]+/)?.[0]
  );
  if (colIndex !== -1) {
    return null;
  }

  let nearestRowIndex = null;
  range(viewWindow?.startRow, viewWindow?.endRow + 1).forEach(rowIndex => {
    const y = getRowY(rowIndex, grid, viewWindow);
    if (Math.abs(y - e.nativeEvent.offsetY) < 10) {
      nearestRowIndex = rowIndex;
    }
  });

  return nearestRowIndex - 2;
};

export const getResizedRowGrid = ({
  grid,
  rowResizeIndex,
  rowResizeAmount,
}) => {
  const newGrid = cloneDeep(grid);
  if (!newGrid.rowIndexToHeight) {
    newGrid.rowIndexToHeight = [];
  }

  let newHeight =
    (newGrid.rowIndexToHeight[rowResizeIndex] || 26) + rowResizeAmount;
  newHeight = Math.max(newHeight, 10);

  newGrid.rowIndexToHeight[rowResizeIndex] = round(newHeight);

  return newGrid;
};
