import * as wordsBackend from "api/backend/wordDocsEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { uuidv4 } from "utils/common";

export const getWordDocs = async (params = {}) => {
  const { data, error } = await wordsBackend.getWordDocs(params);
  return { data, error: getHandlingFromError(error) };
};

export const getTableBlocks = tableContent => {
  const blocks = [];

  const tableId = uuidv4();
  tableContent.forEach((row, rowIndex) => {
    row.forEach((cellText, columnIndex) => {
      const cellBlock = {
        text: cellText,
        rowIndex,
        columnIndex,
        numberOfColumns: row.length,
        numberOfRows: tableContent.length,
        isTableCell: true,
        tableId,
      };
      blocks.push(cellBlock);
    });
  });

  return blocks;
};

export const getWordDoc = async (id, params) => {
  const { data, error } = await wordsBackend.getWordDoc(id, params);
  // insert a list of text blocks for every cell in block.tableContent
  // const newWordDoc = { ...data };
  // newWordDoc.content.blocks = newWordDoc.content.blocks
  //   .map(block => {
  //     if (block?.tableContent) {
  //       return getTableBlocks(block.tableContent);
  //     }
  //     return block;
  //   })
  //   .flat();

  return { data, error: getHandlingFromError(error) };
};

export const patchWordDoc = async (id, params = {}, body = {}) => {
  const { data, error } = await wordsBackend.patchWordDoc(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postWordDoc = async (params = {}, body = {}) => {
  const { data, error } = await wordsBackend.postWordDoc(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postWordDocsUpload = async (params = {}, body = {}) => {
  const { data, error } = await wordsBackend.postWordDocsUpload(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteWordDoc = async (id, params = {}) => {
  const { data, error } = await wordsBackend.deleteWordDoc(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const postWordDocsIdSaveAsTemplate = async (
  id,
  params = {},
  body = {}
) => {
  const { data, error } = await wordsBackend.postWordDocsIdSaveAsTemplate(
    id,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};
