import { getFilesBz } from "api/backend/filesEndpoints";
import { useState, useEffect } from "react";

const usePollFiles = (fileIds = []) => {
  const [files, setFiles] = useState([]);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fileIds?.length) {
      setFiles([]);
      return;
    }
    // if some fileIds removed
    const newFiles = files?.filter(file => fileIds.includes(file.id)) || [];
    setFiles(newFiles);

    doPopulateFiles();
    const intervalId = setInterval(doPopulateFiles, 2000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [JSON.stringify(fileIds)]);

  useEffect(() => {
    const isEveryFileDone = files?.every(
      file => file.status === "DONE" || file.status?.includes("FAIL")
    );

    if (error || isEveryFileDone) {
      clearInterval(pollIntervalId);
    }
  }, [JSON.stringify(files), JSON.stringify(error)]);

  const doPopulateFiles = async () => {
    if (!fileIds?.length) {
      return;
    }
    setError(null);
    const { data, error } = await getFilesBz({ ids: fileIds });
    setFiles(data?.filter(file => fileIds.includes(file.id) || []));
    setError(error);
  };

  return [files, setFiles];
};

export default usePollFiles;
