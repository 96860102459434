import {
  getLoginOAuthCallback,
  getProviderLogin,
} from "api/backend/projectServiceEndpoints";
import {
  getJwtForUserFromOneTimeCode,
  postLogin,
  resetPassword,
} from "api/services/authenticationService";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { BoltzhubLogoInner } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { setLoginTokenInClientStorage } from "utils/auth-utils";

export const COLOR1 = "#38D0DA";
export const COLOR2 = "#497FF3";

const Container = styled.div`
  display: grid;
  align-items: center;
  height: 100vh;
  background-color: black;
  padding: 50px;

  @media (min-width: 1240px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 1240px) {
    padding: 0;
  }
`;

const FormContainer = styled.div`
  color: white;
  padding: 10px;
  font-size: 16px;
  display: grid;
  gap: 0px;
  padding: 28px;
  border-radius: 20px;
  background-color: #232627;
  height: 100%;

  display: grid;
  align-content: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  gap: 14px;
  display: flex;
  align-items: center;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 40px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
  position: absolute;
  transform: translateX(-115%);
`;

const Input = styled.input`
  width: 100%;

  @media (min-width: 1240px) {
    width: 500px;
  }

  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid transparent;
  background-color: #141718;
  margin-bottom: 16px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 14px;
  font-weight: 500;

  height: 52px;
  padding: 14px 16px 14px 16px;
  gap: 12px;
  border-radius: 12px;

  ::placeholder {
    color: #80878baa;
  }
`;

const LeftContainer = styled.div`
  display: none;
  padding-right: 50px;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;

  background: linear-gradient(88.86deg, #ffffff 60.62%, #6f6f6f 105.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const LeftSubTitle = styled.div`
  font-size: 20px;
  color: #e8ecef80;
  line-height: 1.4;
  margin-bottom: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const ErrorMsg = styled.div`
  color: #ff000094;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: start;
  font-weight: 500;
`;

const Message = styled.div`
  height: 32px;
  font-weight: 500;
  opacity: 0.5;
  font-size: 14px;
  padding-top: 8px;
`;

const ClickableText = styled.div`
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  cursor: pointer;

  height: 24px;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: start;
  width: max-content;
`;

const Msg = styled.div`
  font-size: 12px;
  text-align: center;
  opacity: 0.6;
  a {
    color: ${props => props.theme.color.primary};
  }
`;

const STATES = {
  LOGIN: {
    btnText: "Login",
    clickableText: "Forgot password?",
    clickDestination: "FORGOT",
    fields: [
      { name: "userName", placeholder: "Email or username" },
      { name: "password", placeholder: "Password", type: "password" },
    ],
  },
  FORGOT: {
    btnText: "Reset password",
    clickableText: "Back to login",
    clickDestination: "LOGIN",
    fields: [{ name: "userName", placeholder: "Email or username" }],
  },
  TWO_FACTOR: {
    btnText: "Login",
    fields: [{ name: "code", placeholder: "Code" }],
    clickableText: "Back to login",
    clickDestination: "LOGIN",
  },
  SIGNUP: {},
};

const PROVIDERS = ["gmail", "microsoft"];

const SwitchLinks = styled.div`
  background-color: #141718;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 6px;
  border-radius: 14px;
`;

const SwitchLink = styled(Link)`
  color: white;
  background-color: ${props => (props.isActive ? "#232627" : "#141718")};
  color: ${props => (props.isActive ? "white" : "#6C7275")};
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    color: white;
  }
`;

const TermsLink = styled.a`
  color: #6e6e6e;
  font-size: 14px;
`;

export const SwitchLinksBar = () => (
  <SwitchLinks>
    <SwitchLink isActive={window.location.pathname === "/login"} to="/login">
      Login
    </SwitchLink>
    <SwitchLink isActive={window.location.pathname === "/signup"} to="/signup">
      Create account
    </SwitchLink>
  </SwitchLinks>
);

const LoginPageV2 = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { providerInUrl } = useParams();

  const [state, setState] = useState("LOGIN");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [payload, setPayload] = useState({
    userName: "",
    password: "",
    code: "",
  });
  const [message, setMessage] = useState("");
  const code = searchParams.get("code");

  useEffect(() => {
    localStorage.removeItem("language");
    localStorage.removeItem("model");
    setLoginTokenInClientStorage("PUBLIC");
  }, []);

  useEffect(() => {
    const sendOnEnter = e => {
      if (e.key === "Enter") {
        doSend();
      }
    };
    document.addEventListener("keydown", sendOnEnter);
    return () => document.removeEventListener("keydown", sendOnEnter);
  }, [JSON.stringify(payload)]);

  useEffect(() => {
    if (!code || !providerInUrl) {
      return;
    }
    doGetAccessTokenFromCode();
  }, [code, providerInUrl]);

  const doGetAccessTokenFromCode = async () => {
    const { data, error } = await getLoginOAuthCallback(providerInUrl, {
      code,
    });

    if (!error) {
      setLoginTokenInClientStorage(data.accessToken);
      navigate("/");
    }
  };

  const doExternalLogin = async provider => {
    const { data, error } = await getProviderLogin(provider, {
      callbackUrl: `${window.location.origin}/login/${provider}`,
    });
    if (error) {
      setErrorMsg(error?.message);
      return;
    }

    window.location = data;
  };

  const doSend = async () => {
    setIsSending(true);
    setErrorMsg("");

    const { userName, password, code } = payload;
    if (state === "LOGIN") {
      const { data, error } = await postLogin({}, { userName, password });
      setIsSending(false);
      setErrorMsg(error?.message);

      if (data?.isTwoFactorRequired) {
        setState("TWO_FACTOR");
        setMessage("Please enter the code sent to your email");
        return;
      }

      if (!error) {
        setLoginTokenInClientStorage(data.accessToken);
        navigate("/");
      }
    }

    if (state === "TWO_FACTOR") {
      const { data, error } = await getJwtForUserFromOneTimeCode(
        userName,
        code?.trim()
      );
      setIsSending(false);
      setErrorMsg(error?.message);

      if (!error) {
        setLoginTokenInClientStorage(data.accessToken);
        navigate("/");
      }
    }

    if (state === "FORGOT") {
      const { error } = await resetPassword(userName);
      setIsSending(false);
      setErrorMsg(error?.message);
      if (!error) {
        setMessage("We sent you an email with your new password");
        setState("LOGIN");
      }
    }
  };

  return (
    <Container>
      <LeftContainer>
        <LeftTitle>The AI you can trust.</LeftTitle>
        <LeftSubTitle>
          Search, chat, write, analyse, code, and plan for your everyday work
          and life.
        </LeftSubTitle>
        <Img src="/images/signup-img.png" />
      </LeftContainer>
      <FormContainer>
        <Title style={{ justifySelf: "center", marginBottom: "20px" }}>
          <StyledBoltzbitLogo />
          Boltzbit
        </Title>
        {searchParams?.get("showExternalLogins") === "true" &&
          PROVIDERS?.map(provider => (
            <ButtonWord
              onClick={() => doExternalLogin(provider)}
              style={{ padding: "8px", marginBottom: 10 }}
            >
              Continue with {provider}
            </ButtonWord>
          ))}
        <Gap />
        <SwitchLinksBar />
        <Message>{message}</Message>
        {STATES?.[state]?.fields?.map(field => (
          <Input
            key={field?.name}
            value={payload[field.name]}
            type={field.type || "text"}
            onChange={e =>
              setPayload({ ...payload, [field.name]: e.target.value })
            }
            placeholder={field.placeholder}
          />
        ))}

        <ErrorMsg>{errorMsg}</ErrorMsg>
        <ClickableText
          onClick={() => {
            setState(STATES?.[state]?.clickDestination);
            setMessage("");
            setErrorMsg("");
          }}
        >
          {STATES?.[state]?.clickableText}
        </ClickableText>
        <ButtonWord
          isPrimary
          disabled={isSending}
          onClick={doSend}
          style={{
            width: "100%",
            maxWidth: "none",
            height: "40px",
            fontSize: "16px",
            justifySelf: "center",
            marginTop: "0px",
          }}
        >
          {STATES?.[state]?.btnText}
        </ButtonWord>
        {/* <Msg style={{ paddingTop: 20 }}>
          Need an account? <a href="/signup">Signup</a>
        </Msg> */}
        {/* <TermsLink
          style={{ justifySelf: "center", marginTop: "20px" }}
          href="/terms"
          target="_blank"
        >
          Terms of Services
        </TermsLink> */}
      </FormContainer>
    </Container>
  );
};

export default LoginPageV2;
