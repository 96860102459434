import { Button } from "@material-ui/core";
import {
  deleteSequence,
  getSequences,
  patchSequenceUpdate,
  postSequenceTrigger,
  postSequencesCreate,
} from "api/backend/projectServiceEndpoints";
import ErrorMessageModal from "components/ErrorMessageModal";
import ButtonWord from "components/ui/ButtonWord";
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { Cross } from "recharts";
import styled from "styled-components";

const ModalContent = styled.div`
  background-color: white;
  padding: 0px 20px;
  width: 800px;
  height: 520px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: start;
  border-radius: 0px;
  position: relative;
  gap: 20px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  overflow: auto;
`;

const TopBar = styled.div`
  border-bottom: 1px solid #ccc;
  width: 800px;
  padding: 8px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto auto;
`;

const StyledPlusIcon = styled(PlusIcon)`
  height: 24px;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
  ${props => (props.disabled ? "opacity: 0.5; pointer-events: none;" : "")}
  :hover {
    background-color: #eaeaea;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 18px;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
  ${props => (props.disabled ? "opacity: 0.5; pointer-events: none;" : "")}
  :hover {
    background-color: #eaeaea;
  }
`;

const BorderDiv = styled.div`
  border: 1px solid #eaeaea;
  padding: 8px;
  margin: 0 8px;
  border-radius: 8px;
  position: relative;
`;

const Textarea = styled.textarea`
  resize: none;
  width: 300px;
  height: 200px;
  border-radius: 0;
  border: 1px solid #ccc;
  outline: none;
  font-family: "Montserrat", sans-serif;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Input = styled.input`
  border-radius: 0;
  border: 1px solid #ccc;
  outline: none;
  font-family: "Montserrat", sans-serif;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const INITIAL_SEQUENCE = {
  name: "",
  steps: [],
};

const FUNCTIONS = [
  { name: "manual", field: "template" },
  { name: "gpt", field: "prompt" },
];

const ACTION_TYPES = ["email", "extraction"];

const INITIAL_STEP = {
  function: {
    type: "manual",
    subject: "",
    template: "",
  },
  action: {
    type: "email",
    condition: "",
  },
  output: [
    {
      columnName: "Step 1 email status",
      description: "Describe the status of the email",
    },
    {
      columnName: "Link to body word doc",
      description:
        "body will be generated as word doc and save the link in this column",
    },
  ],
  next: {
    action: {
      condition: "after first email status is opened",
      type: "email",
    },
  },
};

const SequenceEditModal = ({
  columns = [],
  initialSequence = null,
  doPopulateSequences = () => {},
  pipelineId = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [sequence, setSequence] = useState(initialSequence || INITIAL_SEQUENCE);

  const doSaveSequence = async () => {
    setIsSaving(true);
    if (sequence?.id) {
      const { data } = await patchSequenceUpdate(
        sequence?.id,
        {},
        { ...sequence, pipelineId }
      );
      setSequence(data);
      setIsSaving(false);
      doPopulateSequences();
      return;
    }

    await postSequencesCreate({}, { ...sequence, pipelineId });
    setIsSaving(false);
    doPopulateSequences();
    setIsOpen(false);
  };

  const doDeleteSequence = async () => {
    await deleteSequence(sequence.id);
    doPopulateSequences();
    setIsOpen(false);
  };

  const addStep = () => {
    setSequence({
      ...sequence,
      steps: [...(sequence?.steps || []), { ...INITIAL_STEP }],
    });
  };

  const steps = sequence?.steps || [];

  let btnText = <PlusIcon style={{ fill: "white", height: "10px" }} />;
  if (sequence?.id) {
    btnText = <PencilIcon style={{ fill: "white", height: "10px" }} />;
  }

  return (
    <>
      <ButtonWord onClick={() => setIsOpen(true)}>{btnText}</ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isSaving}>
          <TopBar>
            <input
              placeholder="Sequence name"
              value={sequence?.name}
              onChange={e => setSequence({ ...sequence, name: e.target.value })}
            />
            {sequence?.id ? (
              <ButtonWord onClick={doDeleteSequence}>
                <TrashIcon style={{ fill: "white", height: "10px" }} />
              </ButtonWord>
            ) : (
              <div />
            )}
            <ButtonWord onClick={doSaveSequence}>Save</ButtonWord>
          </TopBar>
          <BorderDiv>
            Record: {(columns || [])?.map(column => column.name).join(", ")}
          </BorderDiv>
          {steps?.map((step, i) => (
            <BorderDiv style={{ display: "grid", gap: "8px" }}>
              <StyledCrossIcon
                style={{ position: "absolute", top: 4, right: 4 }}
                onClick={() => {
                  const newSequence = cloneDeep(sequence);
                  newSequence.steps.splice(i, 1);
                  setSequence(newSequence);
                }}
              />
              <div>
                type:&nbsp;
                <select disabled value="manual">
                  <option>manual</option>
                </select>
              </div>
              <Input
                placeholder="subject"
                value={step?.function?.subject}
                onChange={e => {
                  const newSequence = cloneDeep(sequence);
                  newSequence.steps[i].function.subject = e.target.value;
                  setSequence(newSequence);
                }}
              />
              <Textarea
                value={step?.function?.template}
                onChange={e => {
                  const newSequence = cloneDeep(sequence);
                  newSequence.steps[i].function.template = e.target.value;
                  setSequence(newSequence);
                }}
                placeholder="template"
              />
              <div>
                action:&nbsp;
                <select
                  value={step?.action?.type}
                  onChange={e => {
                    const newSequence = cloneDeep(sequence);
                    newSequence.steps[i].action.type = e.target.value;
                    setSequence(newSequence);
                  }}
                >
                  {ACTION_TYPES.map(actionType => (
                    <option key={actionType}>{actionType}</option>
                  ))}
                </select>
              </div>
            </BorderDiv>
          ))}
          <StyledPlusIcon onClick={addStep} />
        </ModalContent>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 600px;
  height: 400px;
  padding: 20px;
  display: grid;
  gap: 10px;
  align-content: start;
  /* justify-content: start; */
  justify-items: start;
`;

const Sequences = ({ columns = [], selectedSigmaIds = [], pipelineId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sequences, setSequences] = useState([]);
  const [error, setError] = useState(null);
  const [loadingSequenceId, setLoadingSequenceId] = useState(null);

  useEffect(() => {
    doPopulateSequences();
  }, []);

  const doPopulateSequences = async () => {
    const { data } = await getSequences({ pipelineId });
    setSequences(data || []);
  };

  const doRunSequence = async sequenceId => {
    setLoadingSequenceId(sequenceId);
    const { error } = await postSequenceTrigger(
      sequenceId,
      {},
      {
        input: {
          sigmaIds: selectedSigmaIds,
          pipelineId,
        },
      }
    );
    setLoadingSequenceId(null);
    setError(error);
  };

  const doDeleteSequence = async sequenceId => {
    await deleteSequence(sequenceId);
    doPopulateSequences();
  };

  let runBtnText = "Run";
  if (selectedSigmaIds?.length > 0) {
    runBtnText = `Run on ${selectedSigmaIds?.length} records`;
  }

  return (
    <>
      <ButtonWord onClick={() => setIsOpen(true)}>Sequences</ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <Container>
          <SequenceEditModal
            columns={columns}
            doPopulateSequences={doPopulateSequences}
            pipelineId={pipelineId}
          />
          {sequences.map(sequence => (
            <div
              style={{
                padding: "4px 8px",
                border: "1px solid #ccc",
                fontWeight: 500,
                display: "grid",
                width: "100%",
                alignItems: "center",
                gridTemplateColumns: "1fr auto auto auto",
                whiteSpace: "nowrap",
                fontSize: "12px",
                gap: "10px",
                opacity: loadingSequenceId === sequence.id ? 0.5 : 1,
              }}
            >
              {sequence.name}
              <ButtonWord
                disabled={!selectedSigmaIds?.length}
                onClick={() => doRunSequence(sequence?.id)}
              >
                {runBtnText}
              </ButtonWord>
              <SequenceEditModal
                columns={columns}
                initialSequence={sequence}
                key={sequence.id}
                doPopulateSequences={doPopulateSequences}
                pipelineId={pipelineId}
              />
              <ButtonWord onClick={() => doDeleteSequence(sequence?.id)}>
                <TrashIcon style={{ fill: "white", height: "10px" }} />
              </ButtonWord>
            </div>
          ))}
          <ErrorMessageModal
            message={error?.message}
            onDismiss={() => setError(null)}
          />
        </Container>
      </Modal>
    </>
  );
};

export default Sequences;
