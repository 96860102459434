import styled from "styled-components";
import { Close } from "@material-ui/icons";

import { sleep } from "utils/common";
import ButtonDark from "components/ui/ButtonDark";
import { BoltzhubLogoInner } from "components/ui/Icons";

const ZINDEX = 1203;

export const showTutorialOverlay = () => {
  document.querySelector("[data-tutorial-overlay]").style = "display: block";
};

export const blurTutorialOverlay = () => {
  document
    .querySelector("[data-tutorial-overlay]")
    ?.style?.setProperty("backdrop-filter", "blur(1px)");
};

const hideTutorialOverlay = () => {
  document.querySelectorAll("[data-tutorial-id]").forEach(element => {
    element.style = "";
    // const newElement = element.cloneNode(true);
    // element.parentNode.replaceChild(newElement, element);
  });
  document.querySelector("[data-tutorial-overlay]").style = "display: none";
  document.querySelector("[id=tutorial-message]").style = "display: none";

  localStorage.setItem("isTutorialDone", true);
};

const getElementByTutorialId = async tutorialId => {
  let element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  if (!element) {
    await sleep(10);
    element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  }
  if (!element) {
    await sleep(100);
    element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  }
  if (!element) {
    await sleep(1000);
    element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  }
  return element;
};

export const highlightElementByTutorialId = async tutorialId => {
  const element = await getElementByTutorialId(tutorialId);
  element.style = `position: relative; z-index: ${ZINDEX + 1};`;
};

const hideStepElements = stepElements => {
  stepElements.forEach(prevStepElement => {
    const prevStepDomElement = document.querySelector(
      `[data-tutorial-id=${prevStepElement.elementTutorialId}]`
    );
    prevStepDomElement.style = "";

    const messageElement = document.getElementById("tutorial-message");
    messageElement.style.display = "none";

    const messageBtnElement = document.getElementById("tutorial-message-btn");
    messageBtnElement.style.display = "none";

    const modalMessageElement = document.getElementById(
      "modal-tutorial-message"
    );
    if (modalMessageElement) {
      modalMessageElement.style.display = "none";
    }
  });
};

const getTriggerFuncForStep = (config, stepId) => {
  const stepIdToUse = stepId === null ? config.firstStepId : stepId;
  let stepElements = config?.steps?.[stepIdToUse];

  if (!stepElements) {
    localStorage.setItem("isTutorialDone", true);
    return hideTutorialOverlay;
  }

  if (localStorage?.getItem("isTutorialDone") === "true") {
    return hideTutorialOverlay;
  }

  const attachListenerToStepElement = async stepElement => {
    const stepDomElement = await getElementByTutorialId(
      stepElement.elementTutorialId
    );
    stepDomElement.style = `position: relative; z-index: ${
      ZINDEX + 1
    };background-color:${stepElement?.bgHighlightColor};`;

    if (stepElement.message) {
      const rect = stepDomElement.getBoundingClientRect();
      const messageElement = document.getElementById(
        stepElement?.messageId || "tutorial-message"
      );

      const messageTextElement = document.getElementById(
        "tutorial-message-text"
      );
      const messageCornerElement = document.getElementById(
        "tutorial-message-corner"
      );

      if (!messageElement) {
        return;
      }

      messageTextElement.innerText = stepElement.message;
      messageElement.style.display = "block";
      // messageElement.style.opacity = 0;

      const messageRect = messageElement.getBoundingClientRect();

      messageElement.style = `top: ${rect.top + rect.height + 10}px; left: ${
        rect.left
      }px;`;
      messageCornerElement.style = `top: -4px; left: 20px;`;

      if (stepElement.messagePosition === "left") {
        messageElement.style = `top: ${rect.top}px; left: ${
          rect.left - messageRect?.width - 20
        }px;`;
      }

      if (stepElement.messagePosition === "top") {
        messageElement.style = `top: ${
          rect.top - rect.height - messageRect.height - 45 - 300
        }px; left: ${rect.left + 10}px;`;
        messageCornerElement.style = `bottom: -4px; left: 20px;`;
      }

      if (stepElement.messagePosition === "bottom-left") {
        messageElement.style = `top: ${
          rect.top + rect.height + 10
        }px; right: 10px;`;
        messageCornerElement.style = `top: -4px; right: 20px;`;
      }
    }

    if (stepElement.nextStepTriggerEvent === "click-next") {
      const messageButton = document.getElementById("tutorial-message-btn");
      messageButton.style.display = "block";
      const newMessageBtn = messageButton.cloneNode(true);
      messageButton.parentNode.replaceChild(newMessageBtn, messageButton);
      newMessageBtn.addEventListener("click", () => {
        hideStepElements(stepElements);
        getTriggerFuncForStep(config, stepElement.nextStepId)();
      });
      return;
    }

    if (stepElement.nextStepTriggerEvent) {
      stepDomElement.addEventListener(stepElement.nextStepTriggerEvent, () => {
        hideStepElements(stepElements);
        getTriggerFuncForStep(config, stepElement.nextStepId)();
      });
    }
  };

  return async () => {
    stepIdToUse === config.firstStepId && showTutorialOverlay();
    await Promise.all(stepElements.map(attachListenerToStepElement));
  };
};

export const startTutorial = tutorialJson => {
  localStorage.setItem("isTutorialDone", false);
  const start = getTriggerFuncForStep(tutorialJson, null);
  start();
};

const TutorialOverlayContainer = styled.div`
  ${props => !props.isEnabled && "display: none;"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZINDEX};
`;

const Overlay = styled.div`
  background-color: black;
  opacity: ${props => (props.theme.name === "light" ? 0.5 : 0.2)};
  width: 100%;
  height: 100%;
`;

const CloseContainer = styled.div`
  background-color: ${props => props.theme.color.furthest};
  border-radius: 50px;
  width: max-content;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZINDEX + 1};
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

export const TutorialMessage = styled.div`
  position: absolute;
  z-index: ${ZINDEX + 1};
  background-color: #135fd4;
  padding: 6px 16px;
  font-size: 14px;
  color: ${props => props.theme.color.closer2};
  box-shadow: ${props => props.theme.shadow};
  max-width: 800px;
  line-height: 1.5;
  /* border: 1px solid ${props => props.theme.color.primary}; */
  transition: opacity 0.2s;
  color: #f9f9f6;
  border-radius: 10px;

  display: flex;
  gap: 60px;
  align-items: center;
`;

const TutorialBoltzhubLogoInner = styled(BoltzhubLogoInner)`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 3;
  fill: white;
`;

const StyledButton = styled(ButtonDark)`
  padding: 2px 16px;
  color: #f9f9f6;
  background-color: #135fd4;
  box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.3),
    0 0 2px rgba(255, 255, 255, 0.3);
`;

const MsgText = styled.div`
  background-color: #135fd4;
  z-index: 2;
  margin-left: 26px;
`;

const MessageCorner = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: #135fd4;
  z-index: 1;
`;

const TutorialOverlay = () => (
  <>
    <TutorialOverlayContainer data-tutorial-overlay>
      {/* <CloseContainer onClick={hideTutorialOverlay}>
      <Close fontSize="large" />
    </CloseContainer> */}

      <Overlay onClick={hideTutorialOverlay} />
    </TutorialOverlayContainer>
    <TutorialMessage style={{ display: "none" }} id="tutorial-message">
      <TutorialBoltzhubLogoInner />
      <MessageCorner id="tutorial-message-corner" />
      <MsgText id="tutorial-message-text"></MsgText>
      <StyledButton style={{ display: "none" }} id="tutorial-message-btn">
        Next
      </StyledButton>
    </TutorialMessage>
  </>
);

export default TutorialOverlay;
