import { getAggregateProgress } from "api/backend/jobServiceEndpoints";
import { useState, useEffect } from "react";

const usePollAggregateProgress = (jobIds = []) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!jobIds?.length) {
      setResult(null);
      return;
    }

    if (result?.progress === 100) {
      return;
    }

    const intervalId = setInterval(doPollAggregateProgress, 2000);
    return () => clearInterval(intervalId);
  }, [JSON.stringify(jobIds), JSON.stringify(result)]);

  const doPollAggregateProgress = async () => {
    if (!jobIds?.length) {
      return;
    }

    const { data } = await getAggregateProgress({}, jobIds);
    setResult(data);
  };

  return result;
};

export default usePollAggregateProgress;
