import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

const EditableDocNameInput = styled.input`
  font-family: "Montserrat", sans-serif;
  width: 100%;
  display: block;
  outline: none;
  border: none;
  z-index: 2;
  position: absolute;
  padding: 0;
  top: 0;
  min-width: 10px;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const InputWithStateAdjusting = ({
  isDisabled = false,
  initialValue,
  onApplyValue = newVal => {},
  onChange = newVal => {},
  style = {},
  className = "",
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div
      onClick={e => e.stopPropagation()}
      style={{
        position: "relative",
      }}
    >
      <span
        style={{ ...style, opacity: 0, display: "block", height: "1em" }}
        className={className}
      >
        {value}
      </span>
      <EditableDocNameInput
        ref={inputRef}
        className={className}
        isDisabled={isDisabled}
        style={style}
        value={value}
        onChange={e => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
        onBlur={e => {
          if (e.target.value === initialValue) {
            return;
          }
          onApplyValue(e.target.value);
        }}
        onKeyDown={e => {
          e.stopPropagation();
          if (e.key === "Escape") {
            setValue(initialValue);
          }
          if (e.key === "Enter") {
            // onApplyValue(e.target.value);
            inputRef.current.blur();
          }
        }}
      />
    </div>
  );
};

export default InputWithStateAdjusting;
