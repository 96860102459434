import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import { getDirectoryItems } from "api/services/fileSystem";
import {
  CrossIcon,
  ExcelIcon,
  FolderIcon,
  InternetIcon,
  PdfIcon,
  PencilIcon,
  SigmaIcon,
  TickIcon,
  WordIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import SearchableSelectInput from "components/ui/SearchableSelectInput";
import { last, sum } from "lodash";
import { Gap } from "components/Layout";
import SmallButton from "components/ui/SmallButton";
import ProgressBar from "components/ui/ProgressBar";
import { formatLongNumber } from "utils/common";

const MAX_TOKENS = 5 * 10 ** 6;

const HighlightSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
`;

const ModalTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  min-height: 600px;
  min-width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
`;

const SourceContainer = styled.div`
  padding: 4px;
  background-color: ${props => props.theme.color.closer0};
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  :hover {
    background-color: ${props => props.theme.color.furthest};
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  :hover {
    opacity: 0.5;
  }
`;

const getItemIconFromItem = (fileName, type = "", isItemInSources) => {
  if (isItemInSources) {
    return <TickIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".pdf")) {
    return <PdfIcon height="12px" style={{ opacity: 0.8, fill: "#ed1c24" }} />;
  }
  if (type === "WEB_PAGE") {
    return <InternetIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (type === "SIGMA_TEMPLATE") {
    return <SigmaIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".docx")) {
    return <WordIcon height="12px" style={{ opacity: 0.8, fill: "#175bbc" }} />;
  }
  if (fileName?.endsWith(".xlsx")) {
    return (
      <ExcelIcon height="12px" style={{ opacity: 0.8, fill: "#127e45" }} />
    );
  }
  if (type === "DIRECTORY") {
    return <FolderIcon height="10px" />;
  }
  return null;
};

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const TextInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  outline: none;
  padding: 10px;
  font-family: "Montserrat";
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const TextArea = styled.textarea`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  outline: none;
  padding: 10px;
  font-family: "Montserrat";
  resize: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledSmallButton = styled(SmallButton)`
  justify-self: end;
  padding: 4px 20px;
`;

const AddSmallButton = styled(SmallButton)`
  padding: 6px 8px;
`;

const QuestionCrossIcon = styled(CrossIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const ChatConfigModalTrigger = ({
  onPressDone = () => {},
  sources = [],
  onChangeSources = () => {},
  isInitiallyOpen = false,
  wordDoc = {},
  onChangeWordDoc = () => {},
  isGreyedOut = false,
  onClickPublish = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [allItems, setAllItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [questionText, setQuestionText] = useState("");

  let directoryPath = searchValue?.split("/")?.slice(0, -1)?.join("/");
  directoryPath = "/source-files" + directoryPath;

  const numbersOfPages = sources?.map(source => {
    const sourceItem = allItems?.find(item => item?.id === source?.fileId);
    return sourceItem?.numberOfPages;
  });
  const totalNumberOfPages = sum(numbersOfPages);
  const totalNumberOfTokens = totalNumberOfPages * 999;

  useEffect(() => {
    if (isInitiallyOpen) {
      setIsOpen(true);
    }
  }, [isInitiallyOpen]);

  useEffect(() => {
    doPopulateFileOptions();
  }, [directoryPath]);

  const doPopulateFileOptions = async () => {
    setAllItems([]);
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setAllItems(data?.items);
  };

  const selectOptions = allItems?.map(item => {
    const isItemInSources = sources.find(s => s.fileId === item.id);

    return {
      value: item?.id,
      label: item?.fileName || last(item?.path?.split("/")),
      icon: getItemIconFromItem(item?.fileName, item?.type, isItemInSources),
      type: item?.type,
      path: item?.path?.replace("/source-files", ""),
      isDisabled: isItemInSources,
    };
  });

  const addQuestion = () => {
    setQuestionText("");
    const exampleQuestions = wordDoc?.content?.exampleQuestions || [];
    onChangeWordDoc({
      ...wordDoc,
      content: {
        ...wordDoc?.content,
        exampleQuestions: [...exampleQuestions, questionText],
      },
    });
  };

  return (
    <>
      <ModalTrigger onClick={() => setIsOpen(true)}>
        <StyledPencilIcon height="16px" />
      </ModalTrigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isGreyedOut}>
          <ModalTitle
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto auto",
              gap: "8px",
            }}
          >
            <div>Configure Bot</div>
            <div>
              {wordDoc?.content?.isPublished && (
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  href={`/word-docs/${wordDoc?.id}/chat-published`}
                >
                  Try it
                </a>
              )}
            </div>
            <SmallButton value="Publish" onClick={onClickPublish} />
          </ModalTitle>
          <Gap height="5px" />
          <Title>Chatbot name</Title>
          <TextInput
            value={wordDoc?.fileName?.replace("**CHAT**", "")}
            onChange={e => {
              onChangeWordDoc({
                ...wordDoc,
                fileName: `**CHAT**${e.target.value}`,
              });
            }}
            placeholder="Assistant"
          />
          <Gap height="5px" />
          <Title>Description</Title>
          <TextArea
            value={wordDoc?.content?.description}
            onChange={e => {
              onChangeWordDoc({
                ...wordDoc,
                content: {
                  ...wordDoc?.content,
                  description: e.target.value,
                },
              });
            }}
            placeholder="I help people with their tasks"
          />
          <Gap height="5px" />
          <Title>Example questions</Title>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <TextInput
              value={questionText}
              onChange={e => {
                setQuestionText(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  addQuestion();
                }
              }}
              placeholder="What's the most profitable company?"
            />
            <AddSmallButton value="Add" onClick={addQuestion} />
          </div>
          <div
            style={{
              height: "100px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {wordDoc?.content?.exampleQuestions?.map(question => (
              <div
                style={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "8px",
                  border: "1px solid #ccc",
                }}
              >
                {question}{" "}
                <QuestionCrossIcon
                  onClick={() => {
                    const exampleQuestions =
                      wordDoc?.content?.exampleQuestions || [];
                    onChangeWordDoc({
                      ...wordDoc,
                      content: {
                        ...wordDoc?.content,
                        exampleQuestions: exampleQuestions.filter(
                          q => q !== question
                        ),
                      },
                    });
                  }}
                />
              </div>
            ))}
          </div>

          <Gap height="5px" />
          <Title>Knowledge</Title>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "grid",
                gap: "2px",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  gridColumn: 1,
                }}
              >
                <HighlightSpan>
                  {formatLongNumber(totalNumberOfTokens)}
                </HighlightSpan>
                &nbsp;tokens
              </div>
              <div
                style={{
                  gridColumn: 1,
                  justifySelf: "start",
                  fontSize: "12px",
                  paddingBottom: "4px",
                }}
              >
                out of {formatLongNumber(MAX_TOKENS)}
              </div>
              <ProgressBar
                style={{
                  gridColumn: "span 2",
                }}
                maxValue={MAX_TOKENS}
                currentValue={totalNumberOfTokens}
              />
            </div>

            <div
              style={{
                fontSize: "18px",
              }}
            >
              <HighlightSpan>{sources?.length}</HighlightSpan> documents
            </div>

            <div
              style={{
                fontSize: "18px",
              }}
            >
              <HighlightSpan>{totalNumberOfPages}</HighlightSpan> pages
            </div>
          </div>
          <SearchableSelectInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onClickOption={clickedOption => {
              if (clickedOption?.type === "DIRECTORY") {
                console.log({ clickedOption });
                setSearchValue(`${clickedOption?.path}/`);
                return;
              }

              const newFileId = clickedOption?.value;
              const newSource = {
                fileId: newFileId,
                fileName: allItems.find(item => item.id === newFileId)
                  ?.fileName,
                type: allItems.find(item => item.id === newFileId)?.type,
              };
              onChangeSources([...sources, newSource]);
            }}
            options={selectOptions}
            placeholder="File or folder name"
          />
          <div
            style={{
              height: "200px",
              overflow: "auto",
              display: "grid",
              alignContent: "start",
              gap: "8px",
            }}
          >
            {sources.map(source => {
              const sourceItem = allItems?.find(
                item => item?.id === source?.fileId
              );
              const numberOfPages = sourceItem?.numberOfPages;

              return (
                <SourceContainer>
                  {getItemIconFromItem(source?.fileName, source?.type)}
                  <StyledA href={`/files/${source?.fileId}`} target="_blank">
                    {source?.fileName}{" "}
                    {numberOfPages && `(${numberOfPages} pages)`}
                  </StyledA>{" "}
                  <StyledCrossIcon
                    onClick={() => {
                      const newSources = sources.filter(
                        s => s.fileId !== source.fileId
                      );
                      onChangeSources(newSources);
                    }}
                  />
                </SourceContainer>
              );
            })}
          </div>
          <StyledSmallButton
            value="Done"
            onClick={() => {
              onPressDone();
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChatConfigModalTrigger;
