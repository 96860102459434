import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  deleteDirectory,
  getDirectoryItems,
  postDirectories,
  postRenameDirectory,
} from "api/services/fileSystem";
import {
  ChevronDownIcon,
  DownloadIcon,
  EmailIcon,
  ExcelIcon,
  FilesIcon,
  FolderIcon,
  PdfIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  SlidesIcon,
  TrashIcon,
  WordIcon,
} from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import {
  deleteExcelModelById,
  patchExcelModelById,
} from "api/services/excelModelsService";
import {
  deleteWordDoc,
  patchWordDoc,
  postWordDoc,
  postWordDocsUpload,
} from "api/services/wordDocsService";
import {
  deleteSlideDoc,
  patchSlideDoc,
  postSlideDocsUpload,
} from "api/services/slideDocsService";
import ErrorMessageModal from "components/ErrorMessageModal";
import { CircularProgress } from "@material-ui/core";
import {
  deleteFileById,
  patchFileById,
  postAudioFilesFormData,
  postBulkUploadFormData,
} from "api/services/filesService";
import { last } from "lodash";
import {
  Audiotrack,
  FileCopy,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons";
import Modal from "components/ui/Modal";
import InputWithStateNew from "components/InputWithStateNew";
import { getLoggedInUserName } from "api/services/authenticationService";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import { triggerDownloadOfSlideDoc } from "api/backend/slideDocsEndpoints";

const Container = styled.div`
  position: relative;
  background-color: #232627;
  margin-top: 20px;
  margin-right: 20px;
  border-top-right-radius: 20px;
`;

const ItemsContainer = styled.div`
  display: grid;
  gap: 4px;
  height: calc(100vh - 20px);
  overflow: auto;
`;

const StyledTooltipClick = styled(TooltipClick)`
  position: absolute;
  right: 0;
  top: 9px;
  right: 4px;
`;

const ActionsTrigger = styled.div`
  opacity: 0;
  z-index: 1;
  height: 16px;
  border-radius: 10px;
  display: grid;
  align-items: center;
  padding: 0 4px;
  background-color: #d8d8d8;
  cursor: pointer;
  transition: opacity 0.1s;

  :hover {
    background-color: #a0a0a0;
  }
`;

const DirectoryItem = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 8px 0;
  padding-right: 20px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;

  :hover {
    background-color: #454545;
    ${ActionsTrigger} {
      opacity: 1;
    }
  }
`;

const FolderItem = styled.div``;

const ItemName = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eaeaea;
  font-weight: 500;
  text-decoration: none;
`;

const StyledInputWithStateNew = styled(InputWithStateNew)`
  color: #000000bf;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
`;

const DirItemName = styled.div`
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-weight: 500;
`;

const StyledDirInputWithStateNew = styled(InputWithStateNew)`
  color: #000000bf;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;

  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000bf;
  font-weight: 500;
`;

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
  margin-left: 10px;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
  fill: white;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledAudioIcon = styled(Audiotrack)`
  width: 12px;
  margin-left: 10px;
  fill: white;
  color: white;
`;

const StyledActionWordIcon = styled(WordIcon)`
  height: 14px;
`;

const StyledActionTrashIcon = styled(TrashIcon)`
  height: 14px;
`;

const StyledActionFolderIcon = styled(FolderIcon)`
  width: 12px;
`;

const StyledActionPencilIcon = styled(PencilIcon)`
  height: 14px;
`;

const StyledActionPdfIcon = styled(PdfIcon)`
  height: 14px;
`;

const StyledActionAudioIcon = styled(Audiotrack)`
  height: 14px;
`;

const StyledActionEmailIcon = styled(EmailIcon)`
  height: 14px;
`;

const NewItemFileInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  ::file-selector-button {
    display: none;
  }
`;

const Actions = styled.div``;

const ActionItem = styled.div`
  height: 30px;
  padding: 0 8px;
  padding-right: 50px;
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  gap: 8px;

  :hover {
    background-color: #d9d9d9;
  }
`;

const DirNameHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 5px 0;
  gap: 8px;
  align-items: center;
  /* background-color: #f7f8fc; */
  ${props => props.isSticky && "position: sticky; top: 0;"}
  justify-content: space-between;
  z-index: 1;

  cursor: pointer;
  :hover {
    background-color: #d9d9d9;
  }
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: #696969;
  border-radius: 50%;
`;

const DotsContainer = styled.div`
  display: flex;
  gap: 3px;
`;

const StyledFilesIcon = styled(FilesIcon)`
  height: 14px;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const ModalContent = styled.div`
  padding: 20px 40px;
`;

const SendEmailModal = ({ trigger = "open" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{trigger}</div>
      <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <div>
            Send email to <BoldSpan>data@boltzbit.io</BoldSpan>{" "}
            <StyledFilesIcon
              onClick={() => navigator.clipboard.writeText("data@boltzbit.io")}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const ThreeDots = () => (
  <DotsContainer>
    <Dot />
    <Dot />
    <Dot />
  </DotsContainer>
);

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon style={{ fill: "#5e85c0" }} />,
  EXCEL_MODEL: <StyledExcelIcon style={{ fill: "white" }} />,
  SLIDE_DOC: <StyledSlidesIcon style={{ fill: "white" }} />,
  DIRECTORY: <StyledFolderIcon style={{ fill: "white" }} />,
  FILE: <StyledPdfIcon />,
};

const getItemIcon = item => {
  if (item?.isTemplate) {
    return <StyledSigmaIcon />;
  }

  if (item?.isAudioFile) {
    return (
      <StyledAudioIcon
        style={{ width: "12px", height: "12px", marginTop: "2px" }}
      />
    );
  }

  return TYPE_TO_ICON[item?.type];
};

const getItemUrl = item => {
  if (item?.isTemplate) {
    return `/trial-dashboards/${item?.id}/source`;
  }

  if (item?.type === "FILE") {
    return `/files/${item?.id}`;
  }

  if (item?.type === "WORD_DOC") {
    return `/word-docs/${item?.id}`;
  }

  if (item?.type === "EXCEL_MODEL") {
    return `/excel-views/${item?.id}`;
  }

  if (item?.type === "SLIDE_DOC") {
    return `/slide-docs/${item?.id}`;
  }

  return "";
};

const getNewFolderName = directoryItems => {
  const existingFolderNames = directoryItems
    .filter(item => item?.type === "DIRECTORY")
    .filter(item => item?.path.includes("New Folder"))
    .map(item => last(item?.path?.split("/")));

  let newFolderName = "New Folder";
  let i = 1;
  while (existingFolderNames.includes(newFolderName)) {
    newFolderName = `New Folder ${i}`;
    i++;
  }
  return newFolderName;
};

const StyledPlusIcon = styled(PlusIcon)`
  height: 12px;
  :hover {
    opacity: 0.5;
  }
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 8px;
`;

const ITEM_TYPE_TO_NAME_KEY = {
  WORD_DOC: "fileName",
  EXCEL_MODEL: "name",
  SLIDE_DOC: "fileName",
  FILE: "fileName",
  DIRECTORY: "path",
};

export const formatItemName = (item, itemName) => {
  if (item?.isAudioFile) {
    return itemName?.replaceAll(".pdf", "");
  }

  return itemName;
};

const downloadItem = item => {
  if (item?.type === "SLIDE_DOC") {
    triggerDownloadOfSlideDoc(item?.id);
    return;
  }
  triggerDownloadOfFile(item?.id, { fileType: item?.type });
};

const INDENT_PX = 16;

const DirectoryItemsListDark = ({
  directoryPath = "/working-files",
  isInitiallyOpen = false,
  isHeaderSticky = false,
  parentNewItemIds = [],
  isDeleteActionHidden = false,
  onRemoveSelf = () => {},
  indentPx = 0,
  isIconDisabled = false,
  folderActions = [],
  renderHeaderComponent = null,
  onApplyNewPath = newPath => {},
}) => {
  const [directoryItems, setDirectoryItems] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [newItemIds, setNewItemIds] = useState([]);
  const [isShowingFolderActions, setIsShowingFolderActions] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [isEditingDirectoryName, setIsEditingDirectoryName] = useState(false);

  useEffect(() => {
    if (isOpen || parentNewItemIds?.length > 0) {
      doPopulateDirectoryItems();
    }
  }, [isOpen, parentNewItemIds?.length]);

  useEffect(() => {
    const doFetchDirectoryItems = async () => {
      const { data } = await getDirectoryItems({
        directoryPath,
      });
      setDirectoryItems(data?.items || []);
    };

    const intervalId = setInterval(() => {
      if (isOpen) {
        doFetchDirectoryItems();
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [directoryPath]);

  const doPopulateDirectoryItems = async () => {
    setIsLoadingItems(true);
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setDirectoryItems(data?.items || []);
    setIsLoadingItems(false);
  };

  const doCreateWordDoc = async dirPath => {
    const { data, error } = await postWordDoc(
      {},
      {
        fileName: `Untitled Doc`,
        directoryPath: dirPath,
        content: { blocks: [{ text: "" }] },
      }
    );
    if (!error) {
      setNewItemIds(prev => [...prev, data?.id]);
      window.open(`/word-docs/${data?.id}`, "_blank");
      doPopulateDirectoryItems();
    }
  };

  const doUploadWordDoc = async (dirPath, file) => {
    const body = new FormData();
    body.append("wordDocFile", file);
    const { error } = await postWordDocsUpload(
      {
        directoryPath: dirPath,
      },
      body
    );
    setError(error);
  };

  const doCreateNewFolder = async dirPath => {
    const { data, error } = await postDirectories({
      path: `${dirPath}/${getNewFolderName(directoryItems)}`,
    });
    if (!error) {
      setNewItemIds(prev => [...prev, data?.id]);
      doPopulateDirectoryItems();
    }
  };

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?.id);

    let error = null;

    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await deleteExcelModelById(item?.id));
    }

    if (item?.type === "DIRECTORY") {
      ({ error } = await deleteDirectory({ directoryPath: item?.path }));
    }

    if (item?.type === "WORD_DOC") {
      ({ error } = await deleteWordDoc(item?.id));
    }

    if (item?.type === "SLIDE_DOC") {
      ({ error } = await deleteSlideDoc(item?.id));
    }

    if (item?.type === "FILE") {
      ({ error } = await deleteFileById(item?.id));
    }

    setItemIdBeingDeleted(null);
    if (error) {
      setError(error);
      return;
    }
    if (item?.type === "DIRECTORY" && item?.path === directoryPath) {
      onRemoveSelf();
    }
    setDirectoryItems(
      directoryItems.filter(dirItem => dirItem?.id !== item?.id)
    );
  };

  const doPostFiles = async files => {
    const { error } = await postBulkUploadFormData(
      { directoryPath },
      { files }
    );
    if (!error) {
      doPopulateDirectoryItems();
    }
  };

  const doPostAudioFiles = async files => {
    const { error } = await postAudioFilesFormData(
      { directoryPath },
      { files }
    );
    if (!error) {
      doPopulateDirectoryItems();
    }
  };

  const doPatchItem = async (id, updatedFields = {}) => {
    const item = directoryItems.find(item => item?.id === id);
    let error = null;

    if (item?.type === "WORD_DOC") {
      ({ error } = await patchWordDoc(item?.id, {}, updatedFields));
    }
    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await patchExcelModelById(
        item?.id,
        {},
        // {
        //   startRow: 0,
        //   endRow: 10,
        //   startCol: 0,
        //   endCol: 10,
        // },
        updatedFields
      ));
    }
    if (item?.type === "SLIDE_DOC") {
      ({ error } = await patchSlideDoc(item?.id, {}, updatedFields));
    }
    if (item?.type === "FILE") {
      ({ error } = await patchFileById(item?.id, updatedFields));
    }
    if (item?.type === "DIRECTORY") {
      ({ error } = await postRenameDirectory(
        {},
        {
          newDirectoryPath: updatedFields?.path,
          oldDirectoryPath: item?.path,
          userId: getLoggedInUserName(),
        }
      ));
    }

    if (!error) {
      updateItem(id, updatedFields);
    }
    setError(error);
  };

  const updateItem = (id, updatedFields) => {
    setDirectoryItems(prev =>
      prev.map(item => {
        if (item?.id === id) {
          return {
            ...item,
            ...updatedFields,
          };
        }
        return item;
      })
    );
  };

  const actionNameToComponent = {
    new_word_doc: (
      <ActionItem onClick={() => doCreateWordDoc(directoryPath)}>
        <StyledActionWordIcon />
        New word doc
      </ActionItem>
    ),
    upload_word_doc: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          accept=".docx"
          onChange={e => {
            doUploadWordDoc(directoryPath, e.target.files?.[0]);
          }}
        />
        <StyledActionWordIcon />
        Upload
      </ActionItem>
    ),
    upload_files: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          multiple
          accept=".pdf"
          onChange={e => {
            const filesArray = [...e.target.files];
            doPostFiles(filesArray);
          }}
        />
        <StyledActionPdfIcon />
        Upload files
      </ActionItem>
    ),
    upload_audio: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          multiple
          accept=".wav"
          onChange={e => {
            const filesArray = [...e.target.files];
            doPostAudioFiles(filesArray);
          }}
        />
        <StyledActionAudioIcon style={{ height: "14px", width: "14px" }} />
        Upload audio
      </ActionItem>
    ),
    send_email: (
      <SendEmailModal
        trigger={
          <ActionItem>
            <StyledActionEmailIcon />
            Send email
          </ActionItem>
        }
      />
    ),
  };

  const headerTipContent = (
    <Actions>
      {folderActions?.map(action => actionNameToComponent[action])}
      <ActionItem onClick={() => doCreateNewFolder(directoryPath)}>
        <StyledActionFolderIcon />
        New folder
      </ActionItem>
      {!isDeleteActionHidden && (
        <>
          <ActionItem onClick={() => setIsEditingDirectoryName(true)}>
            <StyledActionPencilIcon />
            Rename
          </ActionItem>
          <ActionItem
            onClick={() =>
              doDeleteItem({
                type: "DIRECTORY",
                path: directoryPath,
              })
            }
          >
            <StyledActionTrashIcon />
            Delete
          </ActionItem>
        </>
      )}
    </Actions>
  );

  const dirDisplayName = last(directoryPath.split("/"));
  let dirNameHeader = (
    <DirNameHeader
      isSticky={isHeaderSticky}
      onMouseEnter={() => setIsShowingFolderActions(true)}
      onMouseLeave={() => setIsShowingFolderActions(false)}
    >
      {!isIconDisabled && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            marginLeft: 8 + indentPx - INDENT_PX,
          }}
        >
          <StyledChevronDownIcon
            style={{
              transform: `rotate(${isOpen ? "0deg" : "-90deg"})`,
              transition: "transform 0.1s",
            }}
          />
          <StyledFolderIcon />
        </div>
      )}
      {isEditingDirectoryName ? (
        <StyledDirInputWithStateNew
          autoFocus
          initialValue={dirDisplayName}
          onBlur={e => {
            setIsEditingDirectoryName(false);
            const newPath = [
              ...directoryPath.split("/").slice(0, -1),
              e.target.value,
            ].join("/");
            onApplyNewPath(newPath);
          }}
        />
      ) : (
        <DirItemName onClick={e => setIsOpen(prev => !prev)}>
          {dirDisplayName}
        </DirItemName>
      )}
      <StyledTooltipClick tipContent={headerTipContent}>
        <ActionsTrigger
          style={{
            opacity: isShowingFolderActions || isHeaderSticky ? 1 : 0,
          }}
        >
          {isHeaderSticky ? <StyledPlusIcon /> : <ThreeDots />}
        </ActionsTrigger>
      </StyledTooltipClick>
    </DirNameHeader>
  );

  if (renderHeaderComponent && typeof renderHeaderComponent === "function") {
    dirNameHeader = renderHeaderComponent({ tipContent: headerTipContent });
  }

  if (isLoadingItems && directoryItems?.length === 0) {
    return (
      <Container>
        {dirNameHeader}
        <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
          <DirectoryItem>
            <div />
            <CircularProgress
              size={16}
              thickness={6}
              style={{
                color: "#0191ff",
              }}
            />
          </DirectoryItem>
        </ItemsContainer>
      </Container>
    );
  }

  if (!isLoadingItems && directoryItems?.length === 0) {
    return (
      <Container>
        {dirNameHeader}
        <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
          <DirectoryItem style={{ marginLeft: indentPx }}>
            <div style={{ width: "0px" }} />
            <div style={{ opacity: 0.2 }}>No items</div>
          </DirectoryItem>
        </ItemsContainer>
      </Container>
    );
  }

  return (
    <Container>
      {dirNameHeader}
      <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
        {directoryItems?.map(item => {
          if (item?.type === "DIRECTORY") {
            return (
              <FolderItem
                key={item?.id}
                // style={{ marginLeft: indentPx }}
              >
                <DirectoryItemsListDark
                  parentNewItemIds={newItemIds}
                  directoryPath={item?.path}
                  onRemoveSelf={() => doPopulateDirectoryItems()}
                  indentPx={indentPx + INDENT_PX}
                  folderActions={folderActions}
                  onApplyNewPath={newPath => {
                    doPatchItem(item?.id, { path: newPath });
                  }}
                />
              </FolderItem>
            );
          }

          const nameKey = ITEM_TYPE_TO_NAME_KEY[item?.type];
          return (
            <DirectoryItem
              isDisabled={
                itemIdBeingDeleted === item?.id || item?.status === "Processing"
              }
              key={item?.id}
              target="_blank"
              // style={{ marginLeft: indentPx }}
            >
              <div style={{ marginLeft: 11 + indentPx }}>
                {getItemIcon(item) || <div />}
              </div>
              {editingItemId === item?.id ? (
                <StyledInputWithStateNew
                  autoFocus
                  initialValue={item?.[nameKey]}
                  onBlur={e => {
                    setEditingItemId(null);
                    doPatchItem(item?.id, { [nameKey]: e.target.value });
                  }}
                  onKeyDown={e => {
                    if (e.key === "Escape") {
                      setEditingItemId(null);
                    }
                    if (e.key === "Enter") {
                      setEditingItemId(null);
                      doPatchItem(item?.id, { [nameKey]: e.target.value });
                    }
                  }}
                />
              ) : (
                <ItemName href={getItemUrl(item)} target="_blank">
                  {formatItemName(item, item?.[nameKey])}
                </ItemName>
              )}
              <StyledTooltipClick
                tipContent={
                  <Actions>
                    <ActionItem onClick={() => setEditingItemId(item?.id)}>
                      <StyledActionPencilIcon />
                      Rename
                    </ActionItem>
                    <ActionItem onClick={() => doDeleteItem(item)}>
                      <StyledActionTrashIcon />
                      Delete
                    </ActionItem>
                    <ActionItem>
                      <ShareDirectoryItemModalTrigger directoryItem={item} />
                    </ActionItem>
                    <ActionItem onClick={() => downloadItem(item)}>
                      <DownloadIcon style={{ height: "12px" }} /> Download
                    </ActionItem>
                  </Actions>
                }
              >
                <ActionsTrigger>
                  <ThreeDots />
                </ActionsTrigger>
              </StyledTooltipClick>
            </DirectoryItem>
          );
        })}
        <ErrorMessageModal
          message={error?.message}
          onDismiss={() => setError(null)}
        />
      </ItemsContainer>
    </Container>
  );
};

export default DirectoryItemsListDark;
