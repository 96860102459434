import * as webPagesBackend from "api/backend/webPagesEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getWebPage = async id => {
  const { data, error } = await webPagesBackend.getWebPage(id);
  return { data, error: getHandlingFromError(error) };
};

export const patchWebPage = async (id, params, body) => {
  const { data, error } = await webPagesBackend.patchWebPage(id, params, body);
  return { data, error: getHandlingFromError(error) };
};
