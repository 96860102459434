import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { cloneDeep, uniq } from "lodash";

import { getSigmaTableConfig, postSigmaResultsDownload } from "api/services/sigmaService";
import { CenteredWithTopNavLayout } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import MultiSelectWithFilter from "components/ui/MultiSelectWithFilter";
import { getSmartRecords } from "api/services/searchService";
import ResultsTable from "components/ResultsTable";
import { makeElementFirstInArray, sleep, sortArrayByAnotherArray } from "utils/common";
import useSearchParamsState from "hooks/useSearchParamsState";
import PagePreviewTextAndTableModal from "components/widgets/PagePreviewTextAndTableModal";
import Button from "components/ui/Button";
import FileNameSpan from "components/widgets/FileNameSpan";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { postDownloadExcelFileOfCells } from "api/services/projectService";
import FileInput from "components/inputs/FileInput";
import TagsEditor from "components/widgets/TagsEditor";

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto auto 1fr;
  gap: 20px;
  align-items: start;
  padding-top: 20px;
  grid-template-areas:
    "edit-link title download-btn fill-up-excel-input"
    "columns-selector file-and-keyword file-and-keyword file-and-keyword "
    "columns-selector results results results"
    "columns-selector results results results";
  grid-template-rows: repeat(3, auto) 1fr;
  height: 100%;
`;

const EditLinkButton = styled(Button)`
  grid-area: edit-link;
  justify-self: start;
  padding: 0;
`;

const TableTitle = styled(BigTitle)`
  grid-area: title;
`;

const ColumnsSelector = styled(MultiSelectWithFilter)`
  grid-area: columns-selector;
`;

const ResultsContainer = styled.div`
  grid-area: results;
  height: 100%;
  overflow: auto;
`;

const FileAndKeywordContainer = styled.div`
  align-items: start;
  grid-area: file-and-keyword;
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const FillUpExcelInputContainer = styled.div`
  grid-area: fill-up-excel-input;
`;

const FillUpExcelInput = styled(FileInput)`
  padding: 0;
`;

const ClearFiltersButton = styled(Button)`
  padding: 0;
`;

const TagAllButton = styled(Button)`
  padding: 0;
`;

const DownloadBtnIconContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  grid-area: download-btn;
  align-self: center;
  width: max-content;

  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const TableConfigResultsPage = () => {
  const navigate = useNavigate();
  const { tableConfigId } = useParams();

  const [tableConfig, setTableConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedRowNames, setSelectedRowNames] = useState([]);
  const [selectedColumnNames, setSelectedColumnNames] = useState([]);

  // const [cells, setCells] = useState([]);
  const [resultsError, setResultsError] = useState(null);

  const [isDownloadingTable, setIsDownloadingTable] = useState(false);

  const [tableDocumentLocationAndSearchValueForModal, setTableDocumentLocationAndSearchValueForModal] =
    useSearchParamsState({
      paramName: "tableDocumentLocationAndSearchValueForModal",
    });

  const [searchParams] = useSearchParams();

  const primarySigmaConfigs = tableConfig?.sigmaConfigs?.filter(config =>
    tableConfig?.primarySigmaConfigNames.includes(config.name)
  );
  const primarySigmaConfigNames = primarySigmaConfigs?.map(config => config.name);

  const onCellClick = (cellValue, cellValueJson, cellLineItem, cellSolrId, cellRowIdValue) => {
    if (!cellValue && cellValue !== 0) {
      return;
    }

    const newCellValueJson = cloneDeep(cellValueJson);
    if (Array.isArray(newCellValueJson?.candidates)) {
      newCellValueJson.candidates = {};
    }

    setTableDocumentLocationAndSearchValueForModal({
      ...cellValueJson?.tableDocumentLocation,
      cellValue: cellValueJson?.RawValue || cellValue,
      cellLineItem,
      solrValue: JSON.stringify(newCellValueJson),
      solrId: cellSolrId,
      cellRowIdValue,
    });
  };

  useEffect(() => {
    doPopulateTableConfig();
    //doPopulateCells();
  }, [tableConfigId]);

  const doPopulateTableConfig = async () => {
    const { data } = await getSigmaTableConfig(tableConfigId);
    const columnNamesWithoutIdColumn = data?.sigmaConfigs
      ?.map(config => config.name)
      ?.filter(columnName => !data?.primarySigmaConfigNames.includes(columnName));

    setSelectedColumnNames(columnNamesWithoutIdColumn);
    setTableConfig(data);
    setIsLoading(false);
  };

  // const doPopulateCells = async () => {
  //   const { data, error } = await getSmartRecords({ sigmaTableConfigId: tableConfigId, query: "" });
  //   setSelectedRowNames(uniq(data?.map(cell => cell?.ID)));
  //   setCells(data);
  //   setResultsError(error);
  // };

  if (isLoading) {
    return (
      <CenteredWithTopNavLayout centerColumnMaxWidth="calc(100% - 200px)">
        <Link to="/table-configs">&lt;- To table configs</Link>
        <div>Loading...</div>
      </CenteredWithTopNavLayout>
    );
  }

  const allColumnNames = [...tableConfig?.sigmaConfigs?.map(config => config.name)];

  // const filteredCells = cells?.filter(cell => selectedRowNames.includes(cell?.ID));

  // const orderedResultsColumnNames = [
  //   ...primarySigmaConfigs?.map(config => config.name),
  //   ...sortArrayByAnotherArray(selectedColumnNames, allColumnNames),
  // ];

  return (
    <CenteredWithTopNavLayout centerColumnMaxWidth="calc(100% - 200px)">
      <Container>
        <EditLinkButton
          onClick={() => navigate(`/table-configs/${tableConfigId}/edit`)}
          value="Edit table configuration"
        />
        <TableTitle>{tableConfig?.name}</TableTitle>
        {/* <DownloadBtnIconContainer
          isDisabled={isDownloadingTable}
          onClick={async () => {
            setIsDownloadingTable(true);
            const { error } = await postDownloadExcelFileOfCells({
              cells: filteredCells,
              tableName: tableConfig?.name,
              tableColumns: [filterSigmaConfig?.name, ...selectedColumnNames],
              idColumnName: filterSigmaConfig?.name,
            });
            setIsDownloadingTable(false);
            setResultsError(error);
          }}
        >
          <CloudDownloadOutlined />
        </DownloadBtnIconContainer> */}
        <FillUpExcelInputContainer>
          {/* <FillUpExcelInput
            variant="default"
            isShowingFileChosenText={false}
            buttonText="Upload excel to fill up"
            onFileSelect={async file => {
              await postSigmaResultsDownload(file, cells, filterSigmaConfig?.name, [
                filterSigmaConfig?.name,
                ...selectedColumnNames,
              ]);
            }}
          /> */}
        </FillUpExcelInputContainer>
        <FileAndKeywordContainer>
          <div />
          {/* <ClearFiltersButton
            value="Clear filters"
            onClick={() => {
              searchParams?.delete("columnFilters");
              navigate(`?${searchParams.toString()}`);
            }}
          /> */}
          {tableConfig?.keywordFilter && (
            <div>
              filtered pages by keyword: <BoldSpan>{tableConfig?.keywordFilter}</BoldSpan>
            </div>
          )}
        </FileAndKeywordContainer>
        <ColumnsSelector
          title="Columns"
          options={allColumnNames?.filter(columnName => !primarySigmaConfigNames?.includes(columnName))}
          selectedOptions={selectedColumnNames}
          onSelectOptions={newSelectedColumnNames => setSelectedColumnNames(newSelectedColumnNames)}
          shouldShowSelectAllAndClearButtons
        />
        <ResultsContainer>
          <ResultsTable
            columnNames={allColumnNames?.filter(columnName => {
              return primarySigmaConfigNames?.includes(columnName) || selectedColumnNames?.includes(columnName);
            })}
            rowKey="ID"
            colKey="TOPIC"
            sigmaTableConfigId={tableConfigId}
            primarySigmaConfigNames={primarySigmaConfigNames}
            // records={filteredCells}
            onCellClick={onCellClick}
          />
          {resultsError && <div>{JSON.stringify(resultsError)}</div>}
        </ResultsContainer>
      </Container>

      <PagePreviewTextAndTableModal
        open={!!tableDocumentLocationAndSearchValueForModal?.fileId}
        handleClose={() => setTableDocumentLocationAndSearchValueForModal(null)}
        tableDocumentLocation={tableDocumentLocationAndSearchValueForModal}
        cellValue={tableDocumentLocationAndSearchValueForModal?.cellValue}
        cellLineItem={tableDocumentLocationAndSearchValueForModal?.cellLineItem}
        solrId={tableDocumentLocationAndSearchValueForModal?.solrId}
        solrValue={tableDocumentLocationAndSearchValueForModal?.solrValue}
        tableId={tableConfigId}
        cellRowIdValue={tableDocumentLocationAndSearchValueForModal?.cellRowIdValue}
        rowIdName={tableConfig?.primarySigmaConfigNames?.[0]}
        tableName={tableConfig?.name}
      />
    </CenteredWithTopNavLayout>
  );
};

export default TableConfigResultsPage;
