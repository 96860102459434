import styled from "styled-components";

import { Gap } from "components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Modal from "components/ui/Modal";
import { PencilIcon } from "components/ui/Icons";
import {
  deleteCard,
  getCards,
  postAddCard,
  postMakeCardDefault,
} from "api/backend/projectServiceEndpoints";
import { useEffect } from "react";
import { getActiveSubscription } from "api/services/projectService";
import ErrorMessageModal from "components/ErrorMessageModal";
import LayoutNew from "components/LayoutNew";
import { PLAN_URL } from "pages/profile-new/ProfileSidebar";
import ButtonWord from "components/ui/ButtonWord";
import { PencilIconDetailed } from "components/IconsNew";
import {
  getStripeCustomer,
  patchStripeCustomer,
  patchStripeCustomerBillingAddress,
} from "api/backend/authenticationServiceEndpoints";

const Container = styled.div`
  display: grid;
  align-content: start;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const ContentTitle = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 20px;
`;

const StyledPencilIcon = styled(PencilIconDetailed)`
  position: absolute;
  top: 12px;
  right: 12px;
  opacity: 0;
  cursor: pointer;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const FieldRow = styled.div`
  position: relative;
  display: grid;
  gap: 12px;
  align-items: center;
  width: max-content;
  :hover {
    ${StyledPencilIcon} {
      opacity: 1;
    }
  }
`;

const WhiteRect = styled.div`
  width: 400px;
  height: 50px;
  line-height: 50px;
  background-color: white;
  border-radius: 12px;
  gap: 12px;
  padding: 0 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  width: 500px;
  padding: 40px;
  border-radius: 24px;
  display: grid;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0em;
  padding-bottom: 20px;
`;

const StyledInput = styled.input`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const StyledButtonWord = styled(ButtonWord)`
  margin-top: 30px;
  height: 52px;
  font-size: 16px;
`;

const StyledLink = styled(Link)``;

const Cards = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  height: 100px;
  width: 236px;
  overflow: hidden;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const GreyRect = styled.div`
  height: 20px;
  align-self: center;
  border-radius: 4px;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    #eaeaea;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -20 0;
  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const Button = styled(ButtonWord)`
  background-color: transparent;
  color: black;
  border: 1px solid #d9dde1;
  height: 50px;
  :hover {
    color: #959595;
  }

  :disabled {
    pointer-events: none;
    background: #efefef;
    color: grey;
  }

  ${props =>
    props?.isPrimary &&
    `
    border: none;
    :hover {
      color: #eaeaea;
    }
  `}
`;

const Img = styled.img`
  height: 20px;
`;

const BRAND_TO_ICON = {
  visa: <Img src="/images/visa-logo.png" />,
  mastercard: <Img src="/images/master-card.png" />,
};

const BigText = styled.div`
  font-weight: 500;
`;

const GreyText = styled.div`
  font-size: 12px;
  color: #00000080;
`;

const WarningMsg = styled.div`
  color: ${props => props.theme.color.in_progress};
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 1.2;
`;

export const EditPaymentDetailsModalTrigger = ({
  field = {},
  value,
  onSuccess = () => {},
}) => {
  const [modalValue, setModalValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setModalValue(value);
  }, [isOpen]);

  const doPatchStripeCustomer = async () => {
    setIsUpdating(true);
    const { error } = await patchStripeCustomerBillingAddress(
      {},
      { address: modalValue }
    );
    setError(error);
    setIsUpdating(false);
    if (!error) {
      setIsOpen(false);
      onSuccess();
    }
  };

  return (
    <>
      <StyledPencilIcon onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>{field?.label}</ModalTitle>

          {field?.subFields?.map(subField => (
            <StyledInput
              value={modalValue?.[subField?.name] || ""}
              placeholder={subField?.placeholder || ""}
              onChange={e => {
                setModalValue({
                  ...modalValue,
                  [subField?.name]: e.target.value,
                });
              }}
            />
          ))}

          <StyledButtonWord
            isPrimary
            disabled={isUpdating}
            onClick={doPatchStripeCustomer}
          >
            Update
          </StyledButtonWord>
          <div>{error?.message || ""}</div>
        </ModalContent>
      </Modal>
    </>
  );
};

const SUCCESS_URL = `${window.location.origin}/profile/billing`;
const CANCEL_URL = `${window.location.origin}/profile/billing`;

// states: EMPTY, LOADING

const BILLING_ADDRESS_FIELD = {
  label: "Billing Address",
  name: "address",
  subFields: [
    { name: "line1", placeholder: "Address Line 1" },
    { name: "line2", placeholder: "Address Line 2" },
    { name: "postal_code", placeholder: "Post Code" },
    { name: "city", placeholder: "City" },
    { name: "country", placeholder: "Country" },
  ],
};

const ProfilePageBilling = () => {
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [cards, setCards] = useState([]);

  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(true);

  const [stripeCustomer, setStripeCustomer] = useState(null);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    doPopulateStripeCustomer();
  }, []);

  useEffect(() => {
    doPopulateActiveSubscription();
    doPopulateCards();
  }, []);

  const doPopulateStripeCustomer = async () => {
    const { data } = await getStripeCustomer();
    setStripeCustomer(data);
  };

  const doPopulateCards = async () => {
    setIsLoadingCards(true);
    const { data } = await getCards();
    setCards(data || []);
    setIsLoadingCards(false);
  };

  const doPopulateActiveSubscription = async () => {
    setIsFetchingSubscription(true);
    const { data } = await getActiveSubscription();
    setActiveSubscription(data);
    setIsFetchingSubscription(false);
  };

  const doAddPaymentMethod = async () => {
    setIsAddingCard(true);
    const { data, error } = await postAddCard(
      {},
      {
        successUrl: SUCCESS_URL,
        cancelUrl: CANCEL_URL,
      }
    );
    setError(error);
    if (!error) {
      window.location = data.url;
      return;
    }
    setIsAddingCard(false);
    // console.log("postAddCard", { data, error });
  };

  const doDeleteCard = async paymentMethodId => {
    setIsLoadingCards(true);
    const { error } = await deleteCard({}, { paymentMethodId });
    // console.log("deletePaymentSessionCard", { data, error });
    setIsLoadingCards(false);
    setError(error);
    if (!error) {
      doPopulateCards();
      return;
    }
  };

  const doMakeCardDefault = async paymentMethodId => {
    setIsLoadingCards(true);
    const { error } = await postMakeCardDefault({}, { paymentMethodId });
    setIsLoadingCards(false);
    setError(error);
    if (!error) {
      doPopulateCards();
    }
  };

  const { line1, line2, postal_code, city, country } =
    stripeCustomer?.address || {};
  const addressString = [line1, line2, postal_code, city, country]
    .filter(Boolean)
    .join(", ");

  return (
    <LayoutNew>
      <Container>
        <Title>Payment</Title>

        <BoldSpan style={{ marginTop: "40px", marginBottom: 12 }}>
          Payment methods
        </BoldSpan>
        {["past_due", "incomplete"]?.includes(activeSubscription?.status) && (
          <WarningMsg>
            There was an issue taking payment from your default card. Please use
            a different card, to avoid plan cancellation. We will try to charge
            your default card again in the next 24 hours.
          </WarningMsg>
        )}
        <Cards>
          {isLoadingCards && (
            <Card>
              <GreyRect />
              <GreyRect />
              <GreyRect />
              <GreyRect />
            </Card>
          )}
          {!isLoadingCards && !cards?.length && (
            <Card>
              <div style={{ color: "#b1b1b1", lineHeight: 1.5 }}>
                No payment methods
              </div>
            </Card>
          )}
          {!isLoadingCards &&
            cards.map((card, index) => (
              <Card key={index}>
                <div style={{ alignSelf: "center" }}>
                  {BRAND_TO_ICON?.[card?.card?.brand] || card?.card?.brand}
                </div>

                <div style={{ display: "grid", gap: "4px" }}>
                  <BigText>••••{card?.card?.last4}</BigText>
                  <GreyText>
                    Expires {card?.card?.exp_month}/{card?.card?.exp_year}
                  </GreyText>
                </div>

                {card?.isDefault && (
                  <div
                    style={{
                      padding: "2px",
                      justifySelf: "start",
                      marginLeft: "-2px",
                      marginTop: "2px",
                      fontWeight: 500,
                    }}
                  >
                    Default
                  </div>
                )}
                {!card?.isDefault && (
                  <Button
                    variant="transparent"
                    onClick={() => doMakeCardDefault(card?.id)}
                    style={{
                      height: "auto",
                      border: "none",
                      padding: "2px",
                      justifySelf: "start",
                      marginLeft: "2px",
                      marginTop: "-3px",
                    }}
                  >
                    Set default
                  </Button>
                )}

                <Button
                  variant="transparent"
                  style={{
                    height: "auto",
                    border: "none",
                    color: "#ff4646",
                    padding: "2px",
                    justifySelf: "start",
                    marginLeft: "-2px",
                    marginTop: "-3px",
                  }}
                  onClick={() => doDeleteCard(card?.id)}
                >
                  Delete
                </Button>
              </Card>
            ))}
        </Cards>
        {isFetchingSubscription && (
          <GreyRect style={{ width: "170px", height: "24px" }} />
        )}
        {activeSubscription?.id && !isFetchingSubscription && (
          <Button
            disabled={isLoadingCards || isAddingCard}
            onClick={doAddPaymentMethod}
            style={{ justifySelf: "start" }}
          >
            Add payment method
          </Button>
        )}
        {!activeSubscription?.id && !isFetchingSubscription && (
          <Button
            disabled={isLoadingCards || isAddingCard}
            onClick={() => navigate(PLAN_URL)}
            style={{ justifySelf: "start" }}
          >
            Choose plan
          </Button>
        )}
        <Gap height="40px" />
        <BoldSpan>Billing Address</BoldSpan>
        <Gap height="12px" />
        {BILLING_ADDRESS_FIELD?.subFields?.map(subField => (
          <FieldRow style={{ marginBottom: 12 }}>
            <WhiteRect>
              {stripeCustomer?.address?.[subField?.name] || (
                <span style={{ opacity: 0.4 }}>{subField?.placeholder}</span>
              )}
            </WhiteRect>
            <EditPaymentDetailsModalTrigger
              field={BILLING_ADDRESS_FIELD}
              value={stripeCustomer?.address}
              onSuccess={doPopulateStripeCustomer}
            />
          </FieldRow>
        ))}

        <ErrorMessageModal
          message={error?.message}
          onDismiss={() => setError(null)}
        />
      </Container>
    </LayoutNew>
  );
};

export default ProfilePageBilling;
