import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PageNumInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.closer0};
  font-family: "Montserrat", sans-serif;
  outline: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
  width: 30px;
  padding: 0 2px;
  text-align: center;
`;

const ArrowButton = styled.div`
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageNumberInputCountingFrom1 = ({ pageNumber, numberOfPages = "?", onNewPageNumber = newPageNum => {} }) => {
  const [inputValue, setInputValue] = useState(pageNumber + 1);

  const updatePageNumberIfValid = newPageNum => {
    if (newPageNum < 0 || isNaN(newPageNum) || newPageNum >= numberOfPages) {
      return;
    }

    onNewPageNumber(newPageNum);
    setInputValue(newPageNum + 1);
  };

  useEffect(() => {
    updatePageNumberIfValid(pageNumber);
  }, [pageNumber]);

  return (
    <Container>
      <ArrowButton onClick={() => updatePageNumberIfValid(pageNumber - 1)}>
        <ArrowLeft />
      </ArrowButton>
      <PageNumInput
        type="text"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={e => {
          if (e.key !== "Enter") {
            return;
          }
          updatePageNumberIfValid(parseInt(inputValue) - 1);
        }}
      />
      / &nbsp;{numberOfPages}
      <ArrowButton onClick={() => updatePageNumberIfValid(pageNumber + 1)}>
        <ArrowRight />
      </ArrowButton>
    </Container>
  );
};

export default PageNumberInputCountingFrom1;
