import { MoreVert } from "@material-ui/icons";
import useClickOutside from "hooks/useClickOutside";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

const Tip = styled.div`
  background-color: ${props => props.theme.color.closer0};
  box-shadow: ${props => props.theme.shadow};
  z-index: 1;
`;

const TriggerContainer = styled.div`
  cursor: pointer;
  border-radius: 50px;
  height: max-content;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
`;

const TooltipPopup = ({ children, offsetX = 0, offsetY = 20 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const triggerRef = useRef(null);

  useClickOutside(triggerRef, () => {
    setIsOpen(false);
  });

  return (
    <>
      <TriggerContainer
        ref={triggerRef}
        onClick={e => {
          const rect = e.target.getBoundingClientRect();
          setPosition({ x: rect.x + offsetX, y: rect.y + offsetY });
          setIsOpen(!isOpen);
        }}
      >
        <MoreVert />
      </TriggerContainer>
      <Tip
        style={{
          position: "fixed",
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "auto" : "none",
          top: position.y,
          left: position.x,
        }}
      >
        {children}
      </Tip>
    </>
  );
};

export default TooltipPopup;
