import { isNil, last } from "lodash";
import { formatLongNumber } from "utils/common";

export const getDisplayValue = ({ record, columnName, columnType = "-" }) => {
  let recordValue = last(`${record?.fields?.[columnName]?.value}`?.split("="));

  if (
    record?.fields?.[columnName]?.value === "" ||
    isNil(record?.fields?.[columnName]?.value)
  ) {
    return "-";
  }

  const isYearColumn = columnName?.toLowerCase()?.includes("year");

  if (typeof recordValue === "number" && !isYearColumn) {
    recordValue = formatLongNumber(recordValue);
  }

  if (columnName === "Loan Amount" && typeof recordValue === "string") {
    recordValue = recordValue?.replaceAll(",", "");
    recordValue = formatLongNumber(parseFloat(recordValue));
  }

  if (columnType === "TEXT") {
    return recordValue;
  }

  if (columnType === "PERCENTAGE") {
    return `${recordValue}%`;
  }

  if (columnType === "CURRENCY") {
    return `$${recordValue}`;
  }

  if (record?.fields?.[columnName]?.unit === "USD") {
    recordValue = `$${recordValue}`;
  }

  return recordValue;
};
