import { getDirectoryItems } from "api/services/fileSystem";
import {
  ExcelIcon,
  FolderIcon,
  PdfIcon,
  SigmaIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import { cloneDeep, uniqBy } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
  margin-left: 10px;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 12px;
  margin-left: 10px;
`;

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon />,
  EXCEL_MODEL: <StyledExcelIcon />,
  SLIDE_DOC: <StyledSlidesIcon />,
  DIRECTORY: <StyledFolderIcon />,
  FILE: <StyledPdfIcon />,
};

const getItemIcon = item => {
  if (item?.isTemplate) {
    return <StyledSigmaIcon />;
  }

  return TYPE_TO_ICON[item?.type];
};

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  height: 24px;
  align-items: center;
  :hover {
    background-color: #d8d8d8;
  }
`;

const ItemName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const INDENT = 16;

const getItemIndent = (item = {}, directoryPath = "") => {
  const paddingLeft = item?.directoryPath?.split("/")?.length * INDENT;
  return paddingLeft;
};

const DirectoryItems = ({ directoryPath }) => {
  const [directoryItems, setDirectoryItems] = useState([]);
  const [openedDirPaths, setOpenedDirPaths] = useState([directoryPath]);

  useEffect(() => {
    setOpenedDirPaths([directoryPath]);
    doPopulateDirectoryItems();
  }, [directoryPath]);

  const doPopulateDirectoryItems = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setDirectoryItems(data?.items || []);
  };

  const doInsertItemsBelow = async item => {
    const { data } = await getDirectoryItems({
      directoryPath: item?.path,
    });

    let newDirectoryItems = cloneDeep(directoryItems).filter(
      newItem =>
        newItem?.path === item?.path ||
        !newItem?.directoryPath?.startsWith(item?.path)
    );
    const itemIndex = newDirectoryItems.findIndex(i => i.id === item.id);

    newDirectoryItems.splice(itemIndex + 1, 0, ...data?.items);
    setDirectoryItems(uniqBy(newDirectoryItems, "id"));
  };

  const onClickItem = item => {
    if (item.type === "DIRECTORY") {
      doInsertItemsBelow(item);

      if (!openedDirPaths.includes(item.path)) {
        setOpenedDirPaths([...openedDirPaths, item.path]);
        return;
      }
      setOpenedDirPaths(openedDirPaths.filter(path => path !== item.path));
    }
  };

  console.log({ openedDirPaths, directoryItems });

  return (
    <>
      {directoryItems
        .filter(item => openedDirPaths.includes(item.directoryPath))
        .map(item => {
          return (
            <Item
              key={item.id}
              onClick={() => onClickItem(item)}
              style={{ paddingLeft: getItemIndent(item, directoryPath) }}
            >
              {getItemIcon(item)}
              <ItemName>{item?.name || item?.fileName || item?.path}</ItemName>
            </Item>
          );
        })}
    </>
  );
};

export default DirectoryItems;
