import { COLOR1, COLOR2 } from "pages/login-v2";
import styled from "styled-components";

const ButtonWord = styled.button`
  padding: 0 12px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #141718;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  :hover {
    color: #eaeaea;
  }

  ${props =>
    props.isPrimary &&
    `
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);,
    border-radius: 10px;
  `}

  :disabled {
    pointer-events: none;
    background: #e0e0e0;
  }
`;

export default ButtonWord;
