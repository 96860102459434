import { useState, useEffect } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import { getFilePageResults } from "api/services/filesService";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import ModalSerchResults from "components/ModalSearchResults";
import SmallButton from "components/ui/SmallButton";
import {
  BoltzhubLogoInner,
  CubeIcon,
  TickIcon,
  WarningIcon,
} from "components/ui/Icons";
import { postEmailsIngestToBoltzhub } from "api/services/chatService";
import { getCubesFindByKey } from "api/services/projectService";
import { getDataIngestionJobUntilFinish } from "api/services/dataService";
import { isEmpty, isNil } from "lodash";

const ModalContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  min-height: 620px;
  min-width: 727px;
  max-width: 1000px;
  overflow: auto;
  border-radius: 0;

  display: grid;
  gap: 10px;

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"};
`;

const CornerSquare = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const FileTextAndLink = styled.div`
  /* font-size: 22px; */
`;

const FileLink = styled.div`
  display: inline;
  text-decoration: underline;
  color: ${props => props.theme.color.primary};
  cursor: pointer;
`;

const ErrMsg = styled.div`
  margin-top: 64px;
`;

const OutputContainer = styled.div`
  grid-area: output;
  overflow: auto;
`;

const PageImage = styled(ImgWithRectangleFlexible)`
  height: 600px;
`;

const ImageScrollingContainer = styled.div`
  height: 600px;
  overflow: auto;
`;

const OutputItemText = styled.div`
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
  height: max-content;
  width: max-content;
  white-space: pre;
`;

const ReferenceEditingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto 1fr auto auto;
  justify-content: start;
  gap: 8px;
  align-items: end;
`;

const StyledTextarea = styled.textarea`
  display: block;
  height: 64px;
  width: 700px;
  font-family: "Montserrat", sans-serif;
  resize: none;
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 4px;
`;

const StyledInput = styled.input`
  display: block;
  width: 500px;
  font-family: "Montserrat", sans-serif;
  resize: none;
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 4px;
`;

const RefText = styled.div`
  max-width: 800px;
  line-height: 1.5;
  padding: 0px 0;
  font-size: 18px;
  font-weight: 600;
  white-space: pre-wrap;
  max-height: 80px;
  overflow: auto;
`;

const VerifiedBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0px;
  font-size: 12px;
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.primary}88;
  justify-self: end;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  svg {
    color: ${props => props.theme.color.primary};
  }

  :hover {
    background-color: ${props => props.theme.color.closer1_5}66;
  }
`;

const BlueTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.primary};
`;

const UnverifiedBtn = styled(VerifiedBtn)`
  border: 1px solid ${props => props.theme.color.closer2}88;
  color: ${props => props.theme.color.closer2};
  svg {
    color: ${props => props.theme.color.closer2};
  }
`;

const GreyTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.closer2};
`;

const DatasetA = styled.a`
  justify-self: end;
  position: relative;
  display: flex;
  padding: 3px 4px;
  align-items: center;
  gap: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};

  :hover {
    background-color: ${props => props.theme.color.closer1_5}66;
  }
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const IconButtonNoIconHeight = styled(IconButton)`
  justify-self: end;
  svg {
    height: unset;
  }
`;

const NoExtractionMessage = styled.div`
  padding: 8px;
  padding-top: 60px;
  align-content: start;
  align-items: center;
  color: ${props => props.theme.color.closer2};
  display: grid;
  grid-template-columns: auto 1fr;
  width: 400px;
  line-height: 1.4;
  gap: 20px;
  margin: 0 auto;
  svg {
    fill: ${props => props.theme.color.closer2};
  }
`;

const PagePreviewTextAndTableModal = ({
  isInputDisabled = false,
  open,
  handleClose,
  tableDocumentLocation = {},
  emailId = "",
  solrId = "",
  searchResults,
  isReferenceEditable = false,
  columnName = "",
  columnValue = "",
  columnDescription = "",
  onPressSaveValue = (newValue, newAnnotationId) => {},
  onPressVerify = checked => {},
  checked = false,
  refText = "",
  areHubBtnsHidden = false,
  //
  pageResultsCache = null,
  setPageResultsCache = () => {},
}) => {
  const [pageResults, setPageResults] = useState(null);
  const [pageResultsErr, setPageResultsErr] = useState(null);
  const [referenceValue, setReferenceValue] = useState(columnValue);
  const [isDisabled, setIsDisabled] = useState(false);
  const [newAnnotationId, setNewAnnotationId] = useState(null);
  const [flowCubeId, setFlowCubeId] = useState(null);
  const [isIngesting, setIsIngesting] = useState(false);

  useEffect(() => {
    if (areHubBtnsHidden || !isReferenceEditable) {
      return;
    }
    doPopulateFlowDatasetId();
  }, [isIngesting]);

  const doPopulateFlowDatasetId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCubeId(data?.id);
  };

  let {
    fileId,
    pageNumber,
    annotationId,
    fileName,
    description,
    references,
    calculation,
  } = tableDocumentLocation || {};

  useEffect(() => {
    let calculationStr = "";
    // if (calculation) {
    //   calculationStr = `${calculation} = `;
    // }

    setReferenceValue(`${calculationStr}${columnValue}`);

    setNewAnnotationId(
      tableDocumentLocation?.annotationId || tableDocumentLocation?.blockId
    );
  }, [columnValue, open, JSON.stringify(tableDocumentLocation)]);

  annotationId = annotationId || tableDocumentLocation?.blockId;

  useEffect(() => {
    if (isNil(pageResultsCache)) {
      doPopulatePageResults(fileId, pageNumber);
      return;
    }
    doPopulatePageResultsCache(fileId, pageNumber);
  }, [fileId, pageNumber]);

  const doPopulatePageResultsCache = async (
    previewFileId,
    previewPageNumber
  ) => {
    setPageResultsErr(null);
    setPageResults(null);
    if (!fileId || pageNumber === undefined || pageNumber === null) {
      return;
    }

    const key = `${previewFileId}_${previewPageNumber}`;
    if (pageResultsCache?.[key]) {
      if (isEmpty(pageResultsCache[key])) {
        return;
      }

      setPageResults(pageResultsCache[key]);
      return;
    }

    setPageResultsCache(prev => ({
      ...prev,
      [key]: {},
    }));
    const { data, error } = await getFilePageResults(
      previewFileId,
      previewPageNumber
    );
    setPageResults(data);
    setPageResultsCache(prev => ({
      ...prev,
      [key]: data,
    }));
    setPageResultsErr(error);
  };

  const doPopulatePageResults = async (previewFileId, previewPageNumber) => {
    setPageResultsErr(null);
    setPageResults(null);
    if (!fileId || pageNumber === undefined || pageNumber === null) {
      return;
    }
    const { data, error } = await getFilePageResults(
      previewFileId,
      previewPageNumber
    );
    setPageResults(data);
    setPageResultsErr(error);
  };

  const doIngestFlowDataPoint = async () => {
    setIsIngesting(true);
    const ingestBody = {
      emailFileId: emailId,
      prompt: columnDescription,
      generatedText: `Answer: ${columnValue}\nReference: ${refText}`,
    };

    const { data: job } = await postEmailsIngestToBoltzhub({}, ingestBody);
    await getDataIngestionJobUntilFinish(job?.dataIngestionJobId?.id);

    setIsIngesting(false);
  };

  const { annotations_0, annotations_1, annotations_2, annotations_3 } =
    pageResults || {};

  const allAnnotations = [
    annotations_0,
    annotations_1,
    annotations_2,
    annotations_3,
  ]?.flat();

  // const outputItem = pageResults?.output?.find(
  //   item => item?.id === annotationId
  // );

  if (searchResults?.length > 0) {
    return (
      <Modal open={open} handleClose={handleClose}>
        <ModalContent>
          <ModalSerchResults searchResults={searchResults} />
        </ModalContent>
      </Modal>
    );
  }

  // if (!tableDocumentLocation?.fileId) {
  //   return null;
  // }

  const hubBaseUrl = "https://hub.boltzbit.com";
  const referencesWithIds =
    references?.map((ref, i) => ({
      ...ref,
      id: `reference_rect_${i}`,
    })) || [];

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent
        isDisabled={isDisabled}
        onMouseDown={e => {
          e.stopPropagation();
          // e.preventDefault();
        }}
        onMouseUp={e => {
          e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <FileTextAndLink>
          <FileLink
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              // let url = `/file/${fileId}?pageNumber=${pageNumber}&annotationIdToHighlight=${annotationId}`;
              // let extraParams = `&solrId=${solrId}&tableName=${tableName}&columnName=${cellLineItem}&solrValue=${solrValue}&tableId=${tableId}&rowIdName=${rowIdName}&rowIdValue=${cellRowIdValue}`;

              let url = `/file/${fileId}?pageNumber=${pageNumber}&selectedOutputItemId=${annotationId}`;
              if (fileName?.includes(".xlsx")) {
                url = `/excel-files/${fileId}?pageNumber=${pageNumber}&selectedOutputItemId=${annotationId}`;
              }
              let extraParams = `&solrId=${solrId}`;

              window.open(`${url}${extraParams}`, "_blank").focus();
            }}
          >
            {fileName} (page {pageNumber + 1})
          </FileLink>
        </FileTextAndLink>
        {/* <RefText>{refText || description}</RefText> */}
        {isReferenceEditable && (
          <ReferenceEditingContainer>
            <div>
              <div
                style={{
                  fontWeight: "700",
                  marginBottom: "8px",
                }}
              >
                {columnName}
              </div>
              {referenceValue?.length > 100 ? (
                <StyledTextarea
                  value={
                    isNil(referenceValue) || referenceValue === "null"
                      ? ""
                      : referenceValue
                  }
                  onChange={e => setReferenceValue(e.target.value)}
                  disabled={isInputDisabled}
                />
              ) : (
                <StyledInput
                  value={
                    isNil(referenceValue) || referenceValue === "null"
                      ? ""
                      : referenceValue
                  }
                  onChange={e => setReferenceValue(e.target.value)}
                  disabled={isInputDisabled}
                />
              )}
            </div>
            {!isInputDisabled && (
              <SmallButton
                onClick={async () => {
                  setIsDisabled(true);
                  await onPressSaveValue(referenceValue, newAnnotationId);
                  setIsDisabled(false);
                }}
                isDisabled={
                  referenceValue === columnValue &&
                  annotationId === newAnnotationId
                }
                value="Save"
              />
            )}
            <div />
            {!areHubBtnsHidden && (
              <>
                {flowCubeId ? (
                  <DatasetA
                    href={`${hubBaseUrl}/cube/${flowCubeId}?tab=Dataset`}
                    target="_blank"
                  >
                    {/* <BoltzhubLogoInner height="14px" /> */}
                    <CubeIcon height="14px" />
                  </DatasetA>
                ) : (
                  <div />
                )}
                {checked ? (
                  <VerifiedBtn
                    onClick={() => {
                      onPressVerify(false);
                    }}
                  >
                    Verified
                    <BlueTickIcon />
                  </VerifiedBtn>
                ) : (
                  <UnverifiedBtn
                    isDisabled={isIngesting}
                    onClick={async () => {
                      await doIngestFlowDataPoint();
                      onPressVerify(true);
                    }}
                  >
                    Unverified
                    <GreyTickIcon />
                  </UnverifiedBtn>
                )}
              </>
            )}
          </ReferenceEditingContainer>
        )}
        {pageResultsErr?.message?.includes("File not found") && (
          <ErrMsg>You have no access to this file</ErrMsg>
        )}
        {!isNil(pageNumber) && (
          <ImageScrollingContainer>
            {!pageResultsErr && (
              <PageImage
                isLoading={!pageResults?.image}
                newAnnotationId={newAnnotationId ?? annotationId}
                onClickBox={id => setNewAnnotationId(id)}
                selectedAnnotationId={annotationId}
                rectangles={[...referencesWithIds]}
                src={`data:image/png;base64,${pageResults?.image || ""}`}
                alt="page preview"
              />
            )}
          </ImageScrollingContainer>
        )}
        {(isNil(pageNumber) || isNil(fileId)) && (
          <NoExtractionMessage style={{ height: "600px" }}>
            <WarningIcon />
            <div>
              Value could not be extracted. Please try changing column name or
              prompt and re-run.
            </div>
          </NoExtractionMessage>
        )}
        <CornerSquare />
        <CornerSquare style={{ top: 0, right: 0 }} />
        <CornerSquare style={{ bottom: 0, left: 0 }} />
        <CornerSquare style={{ bottom: 0, right: 0 }} />
      </ModalContent>
    </Modal>
  );
};

export default PagePreviewTextAndTableModal;
