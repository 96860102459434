import styled from "styled-components";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CloudDownload } from "@material-ui/icons";

import { getEmail } from "api/services/emailService";
import { CenteredWithTopNavLayoutNoSideBar } from "components/Layout";
import WordDoc from "components/WordDoc";
import {
  PAGE_WIDTH_PX,
  START_X,
  addToStyleFieldsToSelection,
} from "utils/word-utils";
import AddDocLabelModalTrigger from "components/AddDocLabelModalTrigger";
import { uuidv4 } from "utils/common";

const StyledCenteredWithTopNavLayout = styled(
  CenteredWithTopNavLayoutNoSideBar
)`
  display: grid;
  align-items: start;

  background-color: ${props => props.theme.color.furthest};
`;

const DocName = styled.div`
  margin: 10px 0;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  white-space: nowrap;

  display: block;
  align-self: center;
  border: none;
  outline: none;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0 10px;
  background-color: ${props => props.theme.color.furthest};
  align-self: center;
  position: sticky;
  top: 71px;
  z-index: 3;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const ToolbarContainer = styled.div`
  border-top: 1px solid ${props => props.theme.color.closer1_5};
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: end;
  padding: 2px 20px;
  height: 100%;
  background-color: ${props => props.theme.color.furthest};

  position: sticky;
  top: 123px;
  z-index: 3;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const PageBoundaryLine = styled.div`
  width: ${PAGE_WIDTH_PX}px;
  height: 2px;
  background-color: ${props => props.theme.color.primary};

  position: sticky;
  top: calc(125px + 30px + 4px);
  left: ${START_X}px;
  z-index: 0;
`;

const EmailFilePage = () => {
  const { emailFileId } = useParams();
  const [emailFile, setEmailFile] = useState(null);
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    doPopulateEmailFile();
  }, [emailFileId]);

  const doPopulateEmailFile = async () => {
    const { data } = await getEmail(emailFileId);
    setEmailFile(data);
    setBlocks(data?.content?.blocks);
  };

  const addToSelectionStyle = newStyleFields => {
    const newBlocks = addToStyleFieldsToSelection(blocks, newStyleFields);
    setBlocks(newBlocks);
  };

  return (
    <StyledCenteredWithTopNavLayout
      centerColumnMaxWidth="100%"
      contentTopPadding={0}
    >
      <DocNameAndSourcesModal>
        <DocName>{emailFile?.fileName}</DocName>
      </DocNameAndSourcesModal>
      <ToolbarContainer>
        <IconButton>
          <AddDocLabelModalTrigger
            onPressSave={newLabel => {
              addToSelectionStyle({
                meta: {
                  createdAt: new Date().toISOString(),
                  id: uuidv4(),
                  isUserLabel: true,
                  label: newLabel,
                },
              });
            }}
          />
        </IconButton>
        <IconButton>
          <CloudDownload />
        </IconButton>
      </ToolbarContainer>
      <PageBoundaryLine />
      <WordDoc
        sourceFileIds={[]}
        blocks={blocks}
        onChangeBlocks={newBlocks => setBlocks(newBlocks)}
        isReadOnly={true}
        topOffset={71 + 52 + 36}
      />
    </StyledCenteredWithTopNavLayout>
  );
};

export default EmailFilePage;
