import { ArrowForward } from "@material-ui/icons";
import { postMoveItem } from "api/services/fileSystem";
import Modal from "components/ui/Modal";
import SmallButton from "components/ui/SmallButton";
import { useState } from "react";
import styled from "styled-components";

const ActionOption = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  svg {
    color: ${props => props.theme.color.closer2};
  }

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  border-radius: 5px;
  display: grid;
  gap: 10px;
  justify-items: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  min-width: 400px;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const MoveButton = styled(SmallButton)`
  opacity: 0;
`;

const DirItemContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1}99;
    ${MoveButton} {
      opacity: 1;
    }
  }
`;

const MoveItemsToDirectoryModalTrigger = ({
  directoryOptionItems,
  parentDirectoryPath,
  id,
  itemType,
  onItemMoved = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const doMoveItemTo = async newDirectoryPath => {
    await postMoveItem({
      newDirectoryPath,
      id,
      itemType,
    });
    setIsOpen(false);
    onItemMoved();
  };

  return (
    <>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>Move to</ModalTitle>
          {parentDirectoryPath && (
            <DirItemContainer onClick={() => doMoveItemTo(parentDirectoryPath)}>
              {parentDirectoryPath}
            </DirItemContainer>
          )}
          {directoryOptionItems.map(item => (
            <DirItemContainer
              key={item?.id}
              onClick={() => doMoveItemTo(item?.path)}
            >
              {item?.path}
            </DirItemContainer>
          ))}
        </ModalContent>
      </Modal>
      <ActionOption
        isDisabled={!directoryOptionItems?.length}
        onClick={() => setIsOpen(true)}
      >
        <ArrowForward /> Move
      </ActionOption>
    </>
  );
};

export default MoveItemsToDirectoryModalTrigger;
