import { first, last } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import styled from "styled-components";

const COLORS = [
  "#0191ff",
  "#9650ff",
  "#009933",
  "#d9534f",
  "#a4b9a8",
  "#ffcc00",
  "#ff6600",
  "#BB0000",
  "#cc0066",
  "#660066",
  "#4d4da5",
  "#ff00ff",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    ></text>
  );
};

const Container = styled.div`
  position: relative;
  height: 380px;
  padding-top: 28px;
`;

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const RecordsSummaryPieChart = ({ records = [], columns = [] }) => {
  const [selectedColumnName, setSelectedColumnName] = useState(
    last(columns)?.name || ""
  );

  useEffect(() => {
    setSelectedColumnName(last(columns)?.name || "");
  }, [JSON.stringify(columns)]);

  // TODO: the record will tell which column is a groupby column
  const categoryName = first(columns)?.name || "";
  const pieData = records?.map(record => ({
    name: record?.[categoryName]?.value,
    value: record?.[selectedColumnName]?.value,
  }));

  return (
    <Container>
      <StyledSelect
        value={selectedColumnName}
        onChange={e => setSelectedColumnName(e.target.value)}
      >
        {columns
          .filter(col => col.name !== categoryName)
          .map(column => (
            <option>{column.name}</option>
          ))}
      </StyledSelect>
      <ResponsiveContainer width="99%" height={300}>
        <PieChart margin={{ right: 20 }}>
          <Legend />
          <Pie
            data={pieData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={100}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default RecordsSummaryPieChart;
