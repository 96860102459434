import { NotificationsNone } from "@material-ui/icons";
import { getProgressManagement } from "api/services/progressManagementService";
import useClickOutside from "hooks/useClickOutside";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import ProgressBar from "components/ui/ProgressBar";
import { getCookieByName, safeFormat } from "utils/common";
import SmallButton from "components/ui/SmallButton";

const IconButtonContainer = styled.div`
  position: relative;
  display: block;
  text-decoration: none;
  color: ${props =>
    props.$isCurrentRoute
      ? props.theme.color.closest
      : props.theme.color.closer2};
  border-radius: 20px;
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;

const NumberOfJobs = styled.div`
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f7f9fd;
`;

const Container = styled.div`
  position: relative;
`;

const Tip = styled.div`
  position: absolute;
  right: 0;
  width: max-content;
  max-height: 300px;
  max-width: 380px;
  line-height: 1.2;
  overflow: auto;

  background-color: ${props => props.theme.color.furthest};
  z-index: 100;
  box-shadow: 0 8px 24px
    ${props =>
      props.theme.name === "light"
        ? "rgba(140, 149, 159, 0.2)"
        : "rgba(0, 0, 0, 0.4)"};
  font-weight: 400;

  display: ${props => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const JobContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 15px 20px;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const JobContainerBlue = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 15px 20px;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  background-color: ${props => props.theme.color.primary}22;
  min-width: 380px;
`;

const JobTitle = styled.div`
  font-weight: 600;
  grid-column: span 2;
  max-width: 400px;
  line-height: 1.2;
`;

const usePollProgressManagement = () => {
  const [jobs, setJobs] = useState([]);
  const [isDoingFirstFetch, setIsDoingFirstFetch] = useState(true);

  const doPopulateJobs = async () => {
    const { data } = await getProgressManagement();
    setJobs(data || []);
    setIsDoingFirstFetch(false);
  };

  useEffect(() => {
    doPopulateJobs();
    const intervalId = setInterval(doPopulateJobs, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return [jobs, isDoingFirstFetch];
};

const StyledProgressBar = styled(ProgressBar)`
  width: 100%;
  height: 9px;

  div {
    animation: pulseBgColor 1s infinite;

    @keyframes pulseBgColor {
      0% {
        background-color: #000000;
      }

      50% {
        background-color: #00000055;
      }

      100% {
        background-color: #000000;
      }
    }
  }
`;

const JobStatus = styled.div`
  justify-self: end;
  align-self: start;
  padding: 2px;

  border-radius: 50px;
  padding: 2px 20px;
  color: ${props => props.theme.color.in_progress};
  /* background-color: ${props => props.theme.color.in_progress}22; */
  border: 1px solid ${props => props.theme.color.in_progress};
  height: max-content;

  ${props =>
    props.status === "Done" &&
    `
    color: ${props.theme.color.success};
    border-color: ${props.theme.color.success};
  `}

  ${props =>
    props.status === "Failed" &&
    `
    color: ${props.theme.color.error};
    border-color: ${props.theme.color.error};
  `}
`;

const getJobTitle = job => {
  if (job?.wordDocId) {
    return (
      <a href={`/dashboards/${job?.wordDocId}/source`} target="_blank">
        {job?.jobName}
      </a>
    );
  }

  if (job?.fileId) {
    return (
      <a href={`/files/${job?.fileId}`} target="_blank">
        {job?.jobName}
      </a>
    );
  }

  return job?.jobName;
};

const getJobMessage = job => {
  if (job?.wordDocId) {
    return (
      <a href={`/dashboards/${job?.wordDocId}/source`} target="_blank">
        {job?.message}
      </a>
    );
  }

  if (job?.fileId) {
    return (
      <a href={`/files/${job?.fileId}`} target="_blank">
        {job?.message}
      </a>
    );
  }

  return job?.message;
};

const JobsTooltipTrigger = ({ dontPopOutNewNotifications = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const tipRef = useRef(null);
  const [jobs] = usePollProgressManagement();
  const [haveFirstJobsBeenLoaded, setHaveFirstJobsBeenLoaded] = useState(false);
  const [isShowingLatestOnly, setIsShowingLatestOnly] = useState(false);
  const [notificationsClearedDate, setNotificationsClearedDate] =
    useState(null);

  useEffect(() => {
    const notificationsClearedDateStr = getCookieByName(
      "notificationsClearedDate"
    );
    if (notificationsClearedDateStr) {
      setNotificationsClearedDate(new Date(notificationsClearedDateStr));
    }
  }, []);

  useClickOutside(tipRef, () => {
    if (!isOpen) return;
    setIsOpen(false);
    setIsShowingLatestOnly(false);
  });

  useEffect(() => {
    if (!isShowingLatestOnly) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsShowingLatestOnly(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [isShowingLatestOnly]);

  const jobsSortedByMostRecentFirst = jobs
    ?.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    ?.filter(job => {
      if (notificationsClearedDate) {
        return new Date(job?.createdAt) > notificationsClearedDate;
      }
      return true;
    });

  const latestJob = jobsSortedByMostRecentFirst?.[0];

  useEffect(() => {
    if (!latestJob?.message) {
      return;
    }

    if (!haveFirstJobsBeenLoaded) {
      setHaveFirstJobsBeenLoaded(true);
      return;
    }

    if (!isOpen) {
      setIsShowingLatestOnly(true);
    }
  }, [JSON.stringify(latestJob?.status)]);

  let formattedJobCount = jobsSortedByMostRecentFirst?.length;
  if (jobsSortedByMostRecentFirst?.length > 99) {
    formattedJobCount = "99+";
  }

  if (isShowingLatestOnly && !dontPopOutNewNotifications) {
    return (
      <Container>
        <IconButtonContainer
          onClick={() => {
            setIsOpen(true);
            setIsShowingLatestOnly(false);
          }}
        >
          <NotificationsNone />
          <NumberOfJobs>{formattedJobCount}</NumberOfJobs>
        </IconButtonContainer>
        <Tip
          isVisible={isShowingLatestOnly}
          ref={tipRef}
          onClick={() => {
            setIsOpen(false);
            setIsShowingLatestOnly(false);
          }}
        >
          <JobContainerBlue>
            <JobTitle>{latestJob?.jobName}</JobTitle>
            {latestJob?.status !== "Done" && latestJob?.status !== "Failed" && (
              <div style={{ gridColumn: "span 2" }}>
                <StyledProgressBar
                  maxValue={100}
                  currentValue={Math.max(latestJob?.progress || 0, 2)}
                />
              </div>
            )}
            {latestJob?.message && (
              <div
                style={{
                  paddingTop: "0px",
                  gridColumn: "span 2",
                  lineHeight: 1.4,
                }}
                dangerouslySetInnerHTML={{ __html: latestJob?.message }}
              />
            )}

            <div style={{ color: "#808080" }}>
              {safeFormat(latestJob?.createdAt, "d MMM yyyy, HH:mm")}
            </div>
            <JobStatus status={latestJob?.status}>
              {latestJob?.status}
            </JobStatus>
          </JobContainerBlue>
        </Tip>
      </Container>
    );
  }

  return (
    <Container>
      <IconButtonContainer onClick={() => setIsOpen(true)}>
        <NotificationsNone />
        <NumberOfJobs>{formattedJobCount}</NumberOfJobs>
      </IconButtonContainer>
      <Tip isVisible={isOpen} ref={tipRef}>
        {jobsSortedByMostRecentFirst?.map(job => (
          <JobContainer key={job?.id}>
            <JobTitle>{job?.jobName}</JobTitle>

            {job?.status !== "Done" && job?.status !== "Failed" && (
              <div style={{ gridColumn: "span 2" }}>
                <StyledProgressBar
                  maxValue={100}
                  currentValue={Math.max(job?.progress || 0, 2)}
                />
              </div>
            )}
            {job?.message && (
              <div
                style={{
                  paddingTop: "0px",
                  gridColumn: "span 2",
                  lineHeight: 1.4,
                }}
                dangerouslySetInnerHTML={{ __html: job?.message }}
              />
            )}

            <div style={{ color: "#808080" }}>
              {safeFormat(job?.createdAt, "d MMM yyyy, HH:mm")}
            </div>
            <JobStatus status={job?.status}>{job?.status}</JobStatus>
          </JobContainer>
        ))}
        {jobsSortedByMostRecentFirst?.length === 0 && (
          <div
            style={{
              padding: "8px",
              textAlign: "center",
              color: "#808080",
            }}
          >
            No new notifications
          </div>
        )}
        <div
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            padding: "8px",
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "end",
            minWidth: "380px",
          }}
        >
          <SmallButton
            onClick={() => {
              const now = new Date();
              document.cookie = `notificationsClearedDate=${now}`;
              setNotificationsClearedDate(now);
            }}
            value="Clear"
            style={{
              padding: "2px 4px",
            }}
          />
        </div>
      </Tip>
    </Container>
  );
};

export default JobsTooltipTrigger;
