import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Image as ImageIcon,
  Redo,
  Undo,
  Link as LinkIcon,
  Remove,
} from "@material-ui/icons";

import {
  getSlideDoc,
  getSlideDocSlide,
  patchSlideDoc,
} from "api/services/slideDocsService";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import {
  CrossIcon,
  FilesIcon,
  PlusIcon,
  SlidesIcon,
} from "components/ui/Icons";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import { getBase64FromImageFile, uuidv4 } from "utils/common";
import ItemListPopover from "components/ItemListPopover";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import NavHeader from "components/NavHeader";
import Slide from "components/Slide";
import { clamp, clone, cloneDeep, merge, range } from "lodash";
import WordDocSearchResultsModal from "components/WordDocSearchResultsModal";

const getSlideDocWithImages = slideDoc => {
  const newSlideDoc = cloneDeep(slideDoc);
  if (!newSlideDoc?.content?.slides) {
    return newSlideDoc;
  }
  newSlideDoc.content.slides.forEach(slide => {
    slide.boxes.forEach(box => {
      if (box?.image) {
        const image = new Image();
        image.src = box.image;
        box.image = image;
      }
    });
  });

  return newSlideDoc;
};

const getSlideDocWithBase64 = slideDoc => {
  const newSlideDoc = cloneDeep(slideDoc);
  if (!newSlideDoc?.content?.slides) {
    return newSlideDoc;
  }

  newSlideDoc.content.slides.forEach(slide => {
    slide.boxes.forEach(async box => {
      if (box?.image) {
        box.image = box.image.src;
      }
    });
  });

  return newSlideDoc;
};

const Container = styled.div`
  height: 100vh;
  display: grid;
  align-items: start;
  align-content: start;
  background-color: #f9fbfd;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  grid-template-rows: auto auto 1fr;
`;

const EditableDocNameInput = styled.input`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;

  display: block;
  align-self: center;
  border: none;
  width: 50%;
  outline: none;
  background-color: #f9fbfd;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  background-color: #f9fbfd;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 67px;
  padding-right: 14px;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};

  padding: 1px 8px;
  margin: 0px 16px;
  margin-bottom: 0px;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.closer0};
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 5px;
`;

const ColoredIconButton = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }

    path:nth-child(2) {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ColoredIconButtonBucket = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path:nth-child(2) {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }
  }
`;

const TopMenuBar = styled.div`
  display: flex;
`;

const TopMenuBarItem = styled.div`
  font-weight: normal;
  padding: 2px 8px;
  font-size: 14px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  cursor: pointer;
  z-index: 4;
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
  border: none;
  box-shadow: ${props => props.theme.shadow};
  left: 0;
  width: 200px;
  transform: translate(0, 4px);
`;

const PopoverItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 8px 14px;
  color: black;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PopoverItemBtn = styled(PopoverItemLink).attrs({ as: "div" })``;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${props => props.theme.color.closer1_5};
  height: 20px;
  padding: 10px 0;
  margin: 0 5px;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  height: 100%;
  position: relative;
  background-color: #f9fbfd;
  width: max-content;
  box-shadow: none;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
`;

const Sidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-right: 1px solid ${props => props.theme.color.closer1_5};
`;

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0;
  transition: opacity 0.2s;
  :hover {
    fill: ${props => props.theme.color.error};
  }
`;

const StyledFilesIcon = styled(FilesIcon)`
  position: absolute;
  bottom: 4px;
  right: 4px;
  opacity: 0;
  height: 16px;
  transition: opacity 0.2s;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const Thumbnail = styled.div`
  position: relative;
  width: 160px;
  height: 95px;
  border: 1px solid
    ${props =>
      props.isHighlighted
        ? props.theme.color.primary
        : props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.furthest};

  :hover {
    opacity: 0.6;
    ${StyledCrossIcon} {
      opacity: 1;
    }
    ${StyledFilesIcon} {
      opacity: 1;
    }
  }
  cursor: pointer;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const isEditingText = config => {
  if (!config?.boxes) {
    return false;
  }
  return config.boxes
    ?.find(box => box.isSelected)
    ?.styles?.find(s => s.isSelection);
};

const SlideDocSlidePage = () => {
  const navigate = useNavigate();
  const { slideDocId, slideIndex } = useParams();

  const [slideDoc, setSlideDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onKeyDown = e => {
    const selectedSlide = slideDoc?.content?.slides?.[slideIndex];
    if (isEditingText(selectedSlide)) {
      return;
    }

    const lastSlideIndex = slideDoc?.content?.slides?.length - 1 || 0;
    if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
      // setSlideIndex(prev => clamp(prev - 1, 0, lastSlideIndex));
    }

    if (e.key === "ArrowRight" || e.key === "ArrowDown") {
      // setSlideIndex(prev => clamp(prev + 1, 0, lastSlideIndex));
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [slideDoc?.content]);

  useEffect(() => {
    doPopulateSlideDoc();
  }, [slideDocId, slideIndex]);

  const doPopulateSlideDoc = async () => {
    const { data } = await getSlideDocSlide(slideDocId, slideIndex);
    const slideDocWithImages = getSlideDocWithImages(data);
    setSlideDoc(slideDocWithImages);
    setIsLoading(false);
  };

  const doSaveDoc = async () => {
    setIsLoading(true);
    const payloadDoc = getSlideDocWithBase64(slideDoc);
    const { data } = await patchSlideDoc(slideDocId, {}, payloadDoc);
    const slideDocWithImages = getSlideDocWithImages(data);
    setSlideDoc(slideDocWithImages);
    setIsLoading(false);
  };

  const addStyleAtSelection = (styleFields = {}) => {
    const newSlideDoc = cloneDeep(slideDoc);
    const selectedSlide = newSlideDoc?.content?.slides?.[slideIndex];
    const selectedBox = selectedSlide?.boxes?.find(box => box.isSelected);
    const selectedStyle = selectedBox?.styles?.find(style => style.isSelection);
    if (!selectedStyle) {
      return;
    }

    const newStyle = {
      start: selectedStyle?.start,
      end: selectedStyle?.end,
      ...styleFields,
    };

    selectedBox.styles.push(newStyle);
    setSlideDoc(newSlideDoc);
  };

  const addBoxStyle = (styleFields = {}) => {
    const newSlideDoc = cloneDeep(slideDoc);
    const selectedSlide = newSlideDoc?.content?.slides?.[slideIndex];
    const selectedBox = selectedSlide?.boxes?.find(box => box.isSelected);

    selectedBox.boxStyle = {
      ...selectedBox.boxStyle,
      ...styleFields,
    };
    setSlideDoc(newSlideDoc);
  };

  const addSlideStyle = (styleFields = {}) => {
    const newSlideDoc = cloneDeep(slideDoc);
    const selectedSlide = newSlideDoc?.content?.slides?.[slideIndex];
    selectedSlide.bgColor = styleFields.bgColor;
    setSlideDoc(newSlideDoc);
  };

  const selectedSlide = slideDoc?.content?.slides?.[0];
  const selectedBox = selectedSlide?.boxes?.find(box => box.isSelected);
  const selectionStyle = selectedBox?.styles?.find(style => style.isSelection);
  const stylesInSelection = selectedBox?.styles?.filter(
    style =>
      style.start <= selectionStyle?.end && style.end >= selectionStyle?.start
  );
  const topBarStyle = merge(...cloneDeep(stylesInSelection || []));

  return (
    <Container isDisabled={isLoading}>
      <DocNameAndSourcesModal>
        <Link to="/working-files?type=SLIDE_DOC">
          <SlidesIcon
            height="40px"
            style={{
              paddingLeft: "14px",
              paddingRight: "10px",
              fill: "#a27c26",
            }}
          />
        </Link>
        <InputAndMenuBar>
          <EditableDocNameInput
            value={slideDoc?.fileName}
            onChange={e => {
              setSlideDoc({
                ...slideDoc,
                fileName: e.target.value,
              });
            }}
            onBlur={doSaveDoc}
            onKeyDown={e => e.stopPropagation()}
          />
          <TopMenuBar>
            <StyledItemListPopover
              iconComponent={<TopMenuBarItem>File</TopMenuBarItem>}
            >
              <PopoverItemLink to="/working-files">Open</PopoverItemLink>
              <PopoverItemBtn onClick={doSaveDoc}>Save</PopoverItemBtn>
              <PopoverItemLink to="/working-files">New</PopoverItemLink>
              <PopoverItemLink to="/working-files">Make a copy</PopoverItemLink>
              <PopoverItemBtn
                onClick={() =>
                  triggerDownloadOfFile(slideDocId, {
                    fileType: "WORD_DOC",
                  })
                }
              >
                Download
              </PopoverItemBtn>
            </StyledItemListPopover>
            <StyledItemListPopover
              iconComponent={<TopMenuBarItem>Edit</TopMenuBarItem>}
            >
              <PopoverItemLink to="/working-files">Undo</PopoverItemLink>
              <PopoverItemLink to="/working-files">Redo</PopoverItemLink>
              <PopoverItemLink to="/working-files">Cut</PopoverItemLink>
              <PopoverItemLink to="/working-files">Copy</PopoverItemLink>
              <PopoverItemLink to="/working-files">Paste</PopoverItemLink>
            </StyledItemListPopover>
            <StyledItemListPopover
              iconComponent={<TopMenuBarItem>View</TopMenuBarItem>}
            >
              <PopoverItemLink to="/working-files">Full Screen</PopoverItemLink>
              <PopoverItemLink to="/working-files">Web View</PopoverItemLink>
            </StyledItemListPopover>
            <StyledItemListPopover
              iconComponent={<TopMenuBarItem>Insert</TopMenuBarItem>}
            >
              <PopoverItemLink to="/working-files">Image</PopoverItemLink>
              <PopoverItemLink to="/working-files">Table</PopoverItemLink>
              <PopoverItemLink to="/working-files">Chart</PopoverItemLink>
            </StyledItemListPopover>
            <StyledItemListPopover
              iconComponent={<TopMenuBarItem>Help</TopMenuBarItem>}
            >
              <PopoverItemLink to="/working-files">
                Getting started
              </PopoverItemLink>
              <PopoverItemLink to="/working-files">
                Start tutorial
              </PopoverItemLink>
              <PopoverItemLink to="/working-files">
                Keyboard shortcuts
              </PopoverItemLink>
            </StyledItemListPopover>
          </TopMenuBar>
        </InputAndMenuBar>
        <StyledNavHeader isProfileOnly />
        <DocSourcesModalTrigger
          sources={slideDoc?.content?.sources}
          onChangeSources={newSources => {}}
        />
      </DocNameAndSourcesModal>
      <ToolbarContainer>
        <WordDocSearchResultsModal />
        <IconButton>
          <Undo />
        </IconButton>
        <IconButton>
          <Redo />
        </IconButton>
        <VerticalLine />
        <StyledSelect
          value={selectedBox?.boxStyle?.fontSize}
          onChange={e => addBoxStyle({ fontSize: e.target.value })}
        >
          <option>14</option>
          <option>16</option>
          <option>18</option>
          <option>20</option>
          <option>24</option>
          <option>28</option>
          <option>32</option>
          <option>36</option>
          <option>48</option>
        </StyledSelect>
        <VerticalLine />
        <IconButton
          isActive={topBarStyle?.fontWeight === "bold"}
          onClick={() =>
            addStyleAtSelection({
              fontWeight:
                topBarStyle?.fontWeight === "bold" ? "normal" : "bold",
            })
          }
        >
          <FormatBold />
        </IconButton>
        <IconButton isActive={false} onClick={() => {}}>
          <FormatItalic />
        </IconButton>
        <IconButton>
          <FormatUnderlined />
        </IconButton>
        <ColorPickerTooltip
          selectedColor={
            isEditingText(selectedSlide)
              ? topBarStyle?.color
              : selectedBox?.boxStyle?.color
          }
          onNewColor={color => {
            if (!isEditingText(selectedSlide)) {
              addBoxStyle({ color });
              return;
            }
            addStyleAtSelection({
              color,
            });
          }}
          triggerIcon={
            <ColoredIconButton color={topBarStyle?.color}>
              <FormatColorText />
            </ColoredIconButton>
          }
        />
        <ColorPickerTooltip
          selectedColor={
            selectedBox?.boxStyle?.bgColor || selectedSlide?.bgColor
          }
          onNewColor={bgColor => {
            if (!selectedBox) {
              addSlideStyle({ bgColor });
              return;
            }
            addBoxStyle({ bgColor });
          }}
          triggerIcon={
            <ColoredIconButtonBucket color={selectedBox?.boxStyle?.bgColor}>
              <FormatColorFill />
            </ColoredIconButtonBucket>
          }
        />
        <VerticalLine />
        <IconButton>
          <LinkIcon />
        </IconButton>
        <IconButton>
          <HiddenInput
            type="file"
            onChange={async e => {
              const file = e.target.files[0];
              const base64Str = await getBase64FromImageFile(file);

              const imageToInsert = new Image();
              imageToInsert.src = base64Str;
              imageToInsert.onload = () => {
                const newSlideDoc = cloneDeep(slideDoc);
                const newSlide = newSlideDoc.content.slides[slideIndex];
                const newBox = {
                  id: uuidv4(),
                  x: 100,
                  y: 100,
                  w: imageToInsert.width * (400 / imageToInsert.height),
                  h: 400,
                  styles: [],
                  boxStyle: {
                    bgColor: "transparent",
                  },
                  isSelected: true,
                  image: imageToInsert,
                };
                newSlide.boxes = [newBox, ...newSlide.boxes];
                setSlideDoc(newSlideDoc);
              };
            }}
          />
          <ImageIcon />
        </IconButton>
        <VerticalLine />
        <IconButton onClick={() => {}}>
          <FormatIndentIncrease />
        </IconButton>
        <IconButton onClick={() => {}}>
          <FormatIndentDecrease />
        </IconButton>
        <IconButton isActive={false} onClick={() => {}}>
          <FormatListBulleted />
        </IconButton>
        <VerticalLine />
        border width
        <StyledSelect
          value={selectedBox?.boxStyle?.lineWidth}
          onChange={e => addBoxStyle({ lineWidth: e.target.value })}
        >
          <option>0</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
        </StyledSelect>
        <VerticalLine />
        <IconButton
          isActive={selectedBox?.boxStyle?.type === "line"}
          onClick={() => {
            addBoxStyle({ type: "line", lineWidth: 1 });
          }}
        >
          <Remove />
        </IconButton>
        {selectedBox?.boxStyle?.type === "line" && (
          <>
            <VerticalLine />
            end shape
            <StyledSelect
              value={selectedBox?.boxStyle?.lineEnd}
              onChange={e => addBoxStyle({ lineEnd: e.target.value })}
            >
              <option></option>
              <option>arrow</option>
            </StyledSelect>
          </>
        )}
      </ToolbarContainer>

      <Content>
        <Sidebar>
          {range(0, slideDoc?.numberOfSlides || 5)?.map(i => {
            const slide = slideDoc?.content?.slides?.[0];

            return (
              <Thumbnail
                key={i}
                isHighlighted={parseInt(slideIndex) === i}
                onClick={() =>
                  navigate(`/slide-docs/${slideDocId}/slides/${i}`)
                }
                style={{ background: slide?.bgColor || "#ffffff" }}
              >
                <img
                  style={{
                    opacity: slide?.thumbnailBase64Src ? 1 : 0,
                    width: "100%",
                  }}
                  src={slide?.thumbnailBase64Src}
                  alt="thumbnail"
                />
                <StyledCrossIcon
                  onClick={() => {
                    const newSlideDoc = cloneDeep(slideDoc);
                    newSlideDoc.content.slides.splice(i, 1);
                    setSlideDoc(newSlideDoc);
                  }}
                />
                <StyledFilesIcon
                  onClick={() => {
                    const newSlideDoc = cloneDeep(slideDoc);
                    const newSlide = cloneDeep(newSlideDoc.content.slides[i]);
                    newSlide.id = uuidv4();
                    newSlideDoc.content.slides.splice(i + 1, 0, newSlide);
                    setSlideDoc(newSlideDoc);
                  }}
                />
              </Thumbnail>
            );
          })}
          <StyledPlusIcon
            onClick={() => {
              const newSlide = {
                bgColor: "#ffffff",
                boxes: [],
              };
              const newSlideDoc = cloneDeep(slideDoc);

              if (!newSlideDoc?.content) {
                newSlideDoc.content = {};
              }
              if (!newSlideDoc?.content?.slides) {
                newSlideDoc.content.slides = [];
              }
              newSlideDoc.content.slides.push(newSlide);
              setSlideDoc(newSlideDoc);
            }}
          />
        </Sidebar>
        <Slide
          config={slideDoc?.content?.slides?.[0]}
          setConfig={newConfig => {
            if (!slideDoc) {
              return;
            }
            const newSlideDoc = cloneDeep(slideDoc);
            if (!newSlideDoc.content.slides) {
              newSlideDoc.content.slides = [];
            }
            newSlideDoc.content.slides[0] = newConfig;
            setSlideDoc(newSlideDoc);
          }}
        />
      </Content>
    </Container>
  );
};

export default SlideDocSlidePage;
