import styled from "styled-components";
import { useState, useEffect } from "react";
import { cloneDeep, isEqual, range } from "lodash";

import { LogoInner } from "components/ui/Icons";
import NavHeader from "components/NavHeader";
import { Gap } from "components/Layout";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import { sleep } from "utils/common";
import { postChatflowGenerate } from "api/services/searchService";
import { Replay } from "@material-ui/icons";
import { useRef } from "react";
import PagePreviewTextAndTableModalSpanTrigger from "components/PagePreviewTextAndTableModalTrigger";
import SmallButton from "components/ui/SmallButton";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";

const getBlockTextSegments = block => {
  const blockTextSegments = [];
  let currentSegment = {
    text: "",
    meta: null,
    bgColor: "transparent",
  };
  let i = 0;
  while (i < block?.text?.length) {
    const styleAtIndex = block?.styles?.find(
      style => i >= style?.start && i < style?.end
    );
    const metaAtIndex = styleAtIndex?.meta || null;

    if (!isEqual(metaAtIndex, currentSegment?.meta)) {
      blockTextSegments.push(currentSegment);
      currentSegment = {
        text: "",
        meta: metaAtIndex,
        bgColor: styleAtIndex?.bgColor || "transparent",
        fontWeight: styleAtIndex?.fontWeight || "normal",
      };
    }

    currentSegment.text += block?.text?.[i];
    i++;
  }
  blockTextSegments.push(currentSegment);

  return blockTextSegments;
};

const Container = styled.div``;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 67px;
  padding-right: 14px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  position: relative;
  background-color: #f9fbfd;
  width: max-content;
  box-shadow: none;
`;

const TextBlocks = styled.div`
  height: 10000px;
`;

const Panel = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;

const UserTextInput = styled.input`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border: none;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  font-size: 14px;
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }

  font-size: 12px;
  padding: 4px 18px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
`;

const BlockText = styled.div`
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 14px;
  padding: 0 10px;
`;

const Questions = styled.div`
  display: flex;
  gap: 10px;
  position: sticky;
  bottom: 70px;
`;

const StyledReplay = styled(Replay)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const Question = styled.div`
  background-color: ${props => props.theme.color.furthest};
  color: ${props => props.theme.color.closer2};
  cursor: pointer;
  padding: 8px;
  border: 1px solid ${props => props.theme.color.closer1};
  width: max-content;
  margin-left: 8px;
  line-height: 1.2;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const TextClamp = styled.div`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const Actions = styled.div`
  padding: 8px 0;
  padding-left: 35px;
`;

const StyledSendButton = styled(SmallButton)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const InputAndSendButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 4px;
  justify-items: start;
  padding-bottom: 4px;

  :hover {
    ${StyledSendButton} {
      opacity: 1;
    }
  }
`;

const StyledInput = styled.input`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const ProfilePicContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.primary};
`;

const StopSmallButton = styled(SmallButton)`
  margin-left: 100%;
  transform: translateX(-100%);

  background-color: ${props => props.theme.color.furthest};
  :hover {
    background-color: ${props => props.theme.color.primary};
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const TableConainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding-left: 45px;
  max-width: 100%;
  overflow: auto;
`;

const ChatAssistant = ({
  initialPrompts = [],
  wordDocBlocks = [],
  onChangeWordDocBlocks = () => {},
  sources = [],
}) => {
  const [userInput, setUserInput] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [shouldCancelGeneration, setShouldCancelGeneration] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [avatarSrc, setAvatarSrc] = useState("");

  const scrollerRef = useRef(null);

  useEffect(() => {
    if (wordDocBlocks?.length > 0) {
      setBlocks(wordDocBlocks);
    }
  }, [wordDocBlocks]);

  useEffect(() => {
    onChangeWordDocBlocks(blocks);
  }, [blocks]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, []);

  useEffect(() => {
    if (shouldCancelGeneration) {
      abortController.abort();
      setAbortController(new AbortController());
      setShouldCancelGeneration(false);
      setIsGenerating(false);
    }
  }, [shouldCancelGeneration]);

  const doFetchCreatorAvatar = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    const src = data?.image?.includes("/images/")
      ? data?.image
      : `data:image/png;base64,${data?.image}`;
    setAvatarSrc(src || "");
  };

  const onPressEnterInCommandInput = async (
    query,
    payloadBlocks,
    continueWithQuery = {},
    isContinue = false
  ) => {
    if (shouldCancelGeneration || isGenerating) {
      return;
    }
    setIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    console.log({
      payload: {
        slashQuery: query,
        cursor: { blockIndex, letterIndex },
        genContext: "word_query",
        blocks: payloadBlocks?.filter(block => !!block),
        continueWithQuery,
      },
    });

    const { data, error } = await postChatflowGenerate(
      { max_new_tokens: 10, fileIds: sources?.map(s => s?.fileId) },
      isContinue
        ? {
            slashQuery: query,
            cursor: { blockIndex, letterIndex },
            genContext: "word_query",
            blocks: payloadBlocks?.filter(block => !!block),
            continueWithQuery,
            isContinue,
            sources,
          }
        : {
            slashQuery: query,
            genContext: "word_query",
            cursor: { blockIndex, letterIndex },
            blocks: payloadBlocks?.filter(block => !!block),
            sources,
          },
      abortController
    );

    console.log({
      resData: data,
      resError: error,
    });

    if (!error && data?.blocks) {
      const blocksWithoutSelection = removeSelectionStyle(data?.blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: data?.cursor?.blockIndex || 0,
        startLetterIndex: data?.cursor?.letterIndex || 0,
        endBlockIndex: data?.cursor?.blockIndex || 0,
        endLetterIndex: data?.cursor?.letterIndex || 0,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      setBlocks(blocksWithSelection);

      if (data?.isContinue) {
        await sleep(10);
        onPressEnterInCommandInput(
          query,
          blocksWithSelection,
          data?.continueWithQuery,
          data?.isContinue
        );
      } else {
        setIsGenerating(false);
      }
    }
  };

  const doRerunBlock = async index => {
    const block = blocks?.[index];
    const blocksWithoutSelection = removeSelectionStyle(blocks);
    const blocksWithSelection = addStyleToBlocks({
      blocks: blocksWithoutSelection,
      startBlockIndex: index,
      startLetterIndex: block?.text?.length,
      endBlockIndex: index,
      endLetterIndex: block?.text?.length,
      styleFields: {
        isSelection: true,
      },
    });

    onPressEnterInCommandInput(block?.text, blocksWithSelection);
  };

  return (
    <Container
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <TextBlocks ref={scrollerRef}>
        {blocks?.map((block, index) => {
          const prevBlock = blocks?.[index - 1];
          const nextBlock = blocks?.[index + 1];

          if (block?.isTableCell) {
            if (block?.rowIndex === 0 && block?.columnIndex === 0) {
              const numRows = block?.numberOfRows;
              const numCols = block?.numberOfColumns;
              const tableId = block?.tableId;

              return (
                <TableConainer>
                  <table>
                    <tbody>
                      {range(numRows).map(rowIndex => (
                        <tr key={`${tableId}-row-${rowIndex}`}>
                          {range(numCols).map(colIndex => {
                            const cellBlock = blocks?.find(
                              b =>
                                b?.tableId === tableId &&
                                b?.rowIndex === rowIndex &&
                                b?.columnIndex === colIndex
                            );

                            const blockTextSegments =
                              getBlockTextSegments(cellBlock);

                            return (
                              <Td
                                key={`${tableId}-row-${rowIndex}-col-${colIndex}`}
                              >
                                {blockTextSegments?.map(segment => {
                                  if (segment?.meta) {
                                    return (
                                      <PagePreviewTextAndTableModalSpanTrigger
                                        tableDocumentLocation={segment?.meta}
                                        style={{
                                          backgroundColor: segment?.bgColor,
                                        }}
                                      >
                                        {segment?.text}
                                      </PagePreviewTextAndTableModalSpanTrigger>
                                    );
                                  }
                                  return segment?.text;
                                })}
                              </Td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableConainer>
              );
            }
            return null;
          }

          if (block?.imageBase64) {
            return (
              <BlockText
                style={{
                  backgroundColor: block?.isQuery ? "transparent" : "#f1f1f1",
                }}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    border: "1px solid #f1f1f1",
                  }}
                  src={`data:image/png;base64,${block?.imageBase64}`}
                  alt="plot"
                />
              </BlockText>
            );
          }

          const blockTextSegments = getBlockTextSegments(block);

          return (
            <BlockText
              style={{
                backgroundColor: block?.isQuery ? "transparent" : "#f1f1f1",
              }}
            >
              {(block?.isQuery || prevBlock?.isQuery) && (
                <div
                  style={{
                    marginTop: "4px",
                    padding: "4px 0",
                    paddingTop: "10px",
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicContainer>
                    {block?.isQuery && <Img src={avatarSrc} />}
                    {!block?.isQuery && (
                      <LogoInner
                        style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                        height="14px"
                      />
                    )}
                  </ProfilePicContainer>
                  {block?.isQuery ? (
                    <BoldSpan>You</BoldSpan>
                  ) : (
                    <BoldSpan>Assistant</BoldSpan>
                  )}
                </div>
              )}
              <div style={{ paddingLeft: "35px" }}>
                {block?.isQuery && (
                  <InputAndSendButton>
                    <StyledInput
                      value={block?.text}
                      onChange={e => {
                        const newBlocks = cloneDeep(blocks);
                        newBlocks[index].text = e.target.value;
                        setBlocks(newBlocks);
                      }}
                    />
                    <StyledSendButton
                      value="Send"
                      onClick={() => doRerunBlock(index)}
                    />
                  </InputAndSendButton>
                )}
                {!block?.isQuery &&
                  blockTextSegments?.map(segment => {
                    if (segment?.meta) {
                      return (
                        <PagePreviewTextAndTableModalSpanTrigger
                          tableDocumentLocation={segment?.meta}
                          style={{ backgroundColor: segment?.bgColor }}
                        >
                          {segment?.text}
                        </PagePreviewTextAndTableModalSpanTrigger>
                      );
                    }
                    return segment?.text;
                  })}
              </div>
              {/* {block?.isQuery && (
                    <Actions>
                      <PencilIcon height="12px" style={{ opacity: 0 }} />
                    </Actions>
                  )} */}
              {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                <Actions>
                  <StyledReplay
                    style={{
                      height: "16px",
                      width: "16px",
                    }}
                    onClick={() => {
                      const queryBlockIndex = blocks?.findIndex(
                        qBlock =>
                          qBlock?.isQuery &&
                          qBlock?.queryId === prevBlock?.queryId
                      );
                      doRerunBlock(queryBlockIndex);
                    }}
                  />
                </Actions>
              )}
            </BlockText>
          );
        })}
        <Gap height="10px" />
      </TextBlocks>

      <StopSmallButton
        onClick={() => setShouldCancelGeneration(true)}
        style={{
          opacity: isGenerating ? 1 : 0,
          position: "sticky",
          bottom: "60px",
          padding: "4px",
          display: "block",
        }}
        value="Stop"
      />
      {blocks?.length === 0 && (
        <Questions>
          {initialPrompts?.map(question => (
            <Question
              onClick={() => {
                const blocksWithoutSelection = removeSelectionStyle(blocks);
                const payloadBlocks = [
                  ...blocksWithoutSelection,
                  {
                    isQuery: true,
                    text: question,
                    styles: [
                      {
                        isSelection: true,
                        start: question.length,
                        end: question.length,
                      },
                    ],
                  },
                ];
                setBlocks(payloadBlocks);
                setUserInput("");
                onPressEnterInCommandInput(question, payloadBlocks);
              }}
            >
              <TextClamp>{question}</TextClamp>
            </Question>
          ))}
        </Questions>
      )}
      <UserTextInput
        disabled={isGenerating}
        value={userInput}
        onChange={e => {
          setUserInput(e.target.value);
        }}
        placeholder="Ask a question"
        onKeyDown={e => {
          if (e.key !== "Enter") {
            return;
          }

          const blocksWithoutSelection = removeSelectionStyle(blocks);
          const payloadBlocks = [
            ...blocksWithoutSelection,
            {
              isQuery: true,
              text: userInput,
              styles: [
                {
                  isSelection: true,
                  start: userInput.length,
                  end: userInput.length,
                },
              ],
            },
          ];
          setBlocks(payloadBlocks);
          setUserInput("");
          onPressEnterInCommandInput(userInput, payloadBlocks);
        }}
      />
    </Container>
  );
};

export default ChatAssistant;
