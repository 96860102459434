import { deleteBlog, getBlogs } from "api/backend/projectServiceEndpoints";
import EditBlogModal from "components/EditBlogModal";
import { Gap } from "components/Layout";
import { BoltzhubLogoInner } from "components/ui/Icons";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 100px;
  margin: 0 40px;
`;

const TopBar = styled.div`
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid #eaeaea;
  gap: 20px;
`;

const TopBarLink = styled(Link)`
  display: block;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 36px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
`;

const StyledLink = styled(Link)`
  justify-self: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const Table = styled.table``;

const Tr = styled.tr``;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #eaeaea;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid #eaeaea;
  font-weight: 600;
  text-align: left;
`;

const COLUMNS = ["id", "createdAt", "name", "subtitle", "content"];

const AdminBlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    doPopulateBlogs();
  }, []);

  const doPopulateBlogs = async () => {
    const { data } = await getBlogs();
    setBlogs(data);
  };

  const doDeleteBlog = async id => {
    setIsLoading(true);
    await deleteBlog(id);
    doPopulateBlogs();
    setIsLoading(false);
  };

  return (
    <Container>
      <TopBar>
        <StyledLink to="/">
          <StyledBoltzbitLogo />
        </StyledLink>
        <TopBarLink to="/admin/blogs">Blogs</TopBarLink>
        <TopBarLink to="/admin/page-visits">Page Visits</TopBarLink>
      </TopBar>
      <Title>Blogs</Title>
      <Gap />
      <EditBlogModal trigger={<button>New</button>} />
      <Gap />
      <Table>
        <thead>
          <Tr>
            {COLUMNS.map(column => (
              <Th>{column}</Th>
            ))}
            <Th />
          </Tr>
        </thead>
        <tbody>
          {blogs?.map(blog => (
            <Tr>
              {COLUMNS.map(column => (
                <Td>{blog[column]?.slice(0, 60)}</Td>
              ))}
              <Td>
                <div style={{ display: "flex", gap: 10 }}>
                  <EditBlogModal id={blog.id} trigger={<button>Edit</button>} />
                  <button
                    disabled={isLoading}
                    onClick={() => doDeleteBlog(blog.id)}
                  >
                    Delete
                  </button>
                </div>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AdminBlogsPage;
