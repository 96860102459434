import { Close } from "@material-ui/icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { parseJson } from "utils/common";

const LABEL_TO_COLOR = {
  ORG: "#7aecec",
  PRODUCT: "#bfeeb7",
  GPE: "#feca74",
  LOC: "#ff9561",
  PERSON: "#aa9cfc",
  NORP: "#c887fb",
  FAC: "#9cc9cc",
  EVENT: "#ffeb80",
  LAW: "#ff8197",
  LANGUAGE: "#ff8197",
  WORK_OF_ART: "#f0d0ff",
  DATE: "#bfe1d9",
  TIME: "#bfe1d9",
  MONEY: "#e4e7d2",
  QUANTITY: "#e4e7d2",
  ORDINAL: "#e4e7d2",
  CARDINAL: "#e4e7d2",
  PERCENT: "#e4e7d2",
};

const TooltipToShowOnHover = styled.div`
  position: absolute;
  left: -1px;
  top: 20px;
  background-color: ${props => props.theme.color.furthest};
  border: 1px solid
    ${props =>
      props.isGreen ? props.theme.color.data : props.theme.color.primary}4D;

  ${props =>
    props.borderColor &&
    `
    border: 1px solid ${props.borderColor};
  `}

  color: ${props => props.theme.color.closest};
  border-radius: 2px;
  z-index: 15;
  pointer-events: none;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

  display: grid;
  padding: 0px 5px;
  grid-template-columns: 1fr 1fr auto;
  gap: 5px;

  font-size: 14px;
  user-select: none;
`;

const Span = styled.span`
  position: relative;
  border-radius: 2px;
  background-color: ${props =>
    props?.isHighlighted
      ? `${
          props.isGreen ? props.theme.color.data : props.theme.color.primary
        }22`
      : props.theme.color.furthest};

  ${props =>
    props.bgColor &&
    `
    background-color: ${props.bgColor}88;
  `}

  :hover {
    background-color: ${props =>
      props.isGreen ? props.theme.color.data : props.theme.color.primary}44;

    ${props =>
      props.bgColor &&
      `
    background-color: ${props.bgColor};
  `}
  }
`;

const PropertyName = styled.div`
  grid-column: 1;
  font-weight: 600;
  white-space: nowrap;
`;

const PropertyValue = styled.div`
  grid-column: 2;
`;

const CloseButtonContainer = styled.div`
  padding: ${props => (props.isVisible ? "2px" : "0px")};
  height: 20px;
  width: ${props => (props.isVisible ? "20px" : "0px")};
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  transform: translate(5px, 0);

  svg {
    height: 18px;
  }
`;

const getReshapedAnnotation = annotation => {
  if (annotation?.label) {
    annotation = {
      ...annotation,
      labelProperties: `[{ "name": "Label name", "value": "${annotation.label}" }]`,
    };
  }
  if (annotation?.additionalLabel) {
    annotation = {
      ...annotation,
      labelProperties: `[{ "name": "Label name", "value": "${annotation.additionalLabel}" }]`,
    };
  }
  return annotation;
};

const AnnoSpan = ({
  children,
  annotation = {
    labelProperties: `[{ "name": "Property Name", "value": "Value" }]`,
    entityId: "",
  },
  onClickDelete = () => {},
}) => {
  const [searchParams] = useSearchParams();

  const [isMouseOverSpan, setIsMouseOverSpan] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const isHighlighted = searchParams?.get("areAnnotationsVisible") === "true";

  useEffect(() => {
    if (isMouseOverSpan) {
      setIsTooltipVisible(true);
    }

    if (!isMouseOverSpan && !isMouseOverTooltip) {
      setIsTooltipVisible(false);
    }
  }, [isMouseOverSpan, isMouseOverTooltip]);

  annotation = getReshapedAnnotation(annotation);
  const labelPropertiesJson = parseJson(annotation?.labelProperties);

  return (
    <Span
      isHighlighted={isHighlighted}
      isGreen={!!annotation?.entityId}
      bgColor={LABEL_TO_COLOR[annotation?.additionalLabel]}
      onMouseEnter={() => setIsMouseOverSpan(true)}
      onMouseLeave={() => setIsMouseOverSpan(false)}
    >
      {children}
      <TooltipToShowOnHover
        isGreen={!!annotation?.entityId}
        borderColor={LABEL_TO_COLOR[annotation?.additionalLabel]}
        style={{
          zIndex: isTooltipVisible ? 15 : 0,
          pointerEvents: isTooltipVisible ? "all" : "none",
          display: isTooltipVisible ? "grid" : "none",
        }}
        onMouseEnter={() => setIsMouseOverTooltip(true)}
        onMouseLeave={() => setIsMouseOverTooltip(false)}
      >
        <CloseButtonContainer
          isVisible={!!annotation?.entityId}
          onClick={onClickDelete}
        >
          <Close />
        </CloseButtonContainer>
        {labelPropertiesJson?.map((propertyNameAndValue, i) => (
          <Fragment key={i}>
            <PropertyName>{propertyNameAndValue?.name}:</PropertyName>
            <PropertyValue>{propertyNameAndValue?.value}</PropertyValue>
          </Fragment>
        ))}
      </TooltipToShowOnHover>
    </Span>
  );
};

export default AnnoSpan;
