import styled from "styled-components";
import { TERMS_HTML_STR } from "components/Terms";
import { useEffect } from "react";

const Container = styled.div`
  padding: 100px;
  max-width: 1200px;
  line-height: 1.8;
`;

const TermsContent = styled.div`
  font-family: "Montserrat", sans-serif;
`;

const LogoImg = styled.img`
  height: 24px;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 80px;
  background-color: black;
  display: grid;
  align-items: center;
`;

const Title = styled.div`
  font-size: 48px;
  font-weight: 600;
`;

const SmallText = styled.div`
  font-size: 14px;
  margin-top: 0px;
`;

const LinkHome = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: max-content;
`;

const TermsPage = () => {
  // const onPaste = e => {
  //   // print html content
  //   console.log(e.clipboardData.getData("text/html"));
  // };

  // useEffect(() => {
  //   document.addEventListener("paste", onPaste);
  //   return () => {
  //     document.removeEventListener("paste", onPaste);
  //   };
  // }, []);

  return (
    <Container>
      <TopBar>
        <LinkHome href="/chat">
          <LogoImg src="/images/boltzbit_logo_h_white.png" />
        </LinkHome>
      </TopBar>
      <Title>Terms of services</Title>
      <SmallText>Updated: 5th July 2024</SmallText>
      <TermsContent dangerouslySetInnerHTML={{ __html: TERMS_HTML_STR }} />
    </Container>
  );
};

export default TermsPage;
