import { useState, useRef } from "react";
import { round } from "lodash";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import PageNumberInputCountingFrom1 from "components/widgets/PageNumberInputCountingFrom1";
import SmallButton from "components/ui/SmallButton";

const Container = styled.div`
  max-height: calc(100vh - 50px);
  display: grid;
  background-color: ${props => props.theme.color.closer0};
  /* border-top: 1px solid ${props => props.theme.color.closer1_5}; */
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ImageCenteringContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 20px;
  overflow: auto;
  background-color: ${props => props.theme.color.closer0};
`;

const PageImage = styled.img`
  width: 100%;
`;

const ToolbarContainer = styled.div`
  padding: 8px;
  height: 48px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.furthest};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  z-index: 90;

  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 5px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ImageAndBoxesContainer = styled.div`
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  transition: width 0.1s;
`;

const AREA_TYPE_TO_COLOR = {
  "-": "#aec7e8",
  text: "#0191ff",
  table: "#9650ff",
  infograph: "#ff7f0e",
  image: "#df5d00",
  title: "#009933",
  sidebar: "#00eeff",
  note: "#bcbd22",
  list: "#5c3129",
};

const getThemeColorFromProps = props => {
  return AREA_TYPE_TO_COLOR?.[props?.label] || props.theme.color.primary;
};

const CheckedDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.closer1};
  ${props =>
    props.hasBeenChecked &&
    `background-color: ${props.theme.color.status_done};`}
  ${props =>
    props.hasBeenChecked === false &&
    `background-color: ${props.theme.color.in_progress};`}
`;

const AnnotationBox = styled.div`
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  border-radius: 2px;
  border: 1px solid blue;

  background-color: ${getThemeColorFromProps}4B;
  border: 1px solid ${props => props.theme.color.primary}3D;
  cursor: pointer;
  transition: opacity 0.1s, background-color 0.1s;
  resize: both;
  overflow: auto;

  opacity: ${props => (props.isSelected ? 1 : 0)};
  ${props => props.isHighlighted && !props.isSelected && "opacity: 0.5;"}
  :hover {
    opacity: 1;
    background-color: ${getThemeColorFromProps}4B;
  }
`;

const ZoomButtons = styled.div`
  display: grid;
  grid-template-columns: 20px 20px;
  gap: 5px;
`;

const ResizeableAnnotationBox = ({
  label,
  top,
  left,
  style,
  onClick,
  isSelected,
  onMouseUpAfterResizing,
}) => {
  const [searchParams] = useSearchParams();

  const boxRef = useRef(null);
  const isHighlighted = searchParams?.get("areAnnotationsVisible") === "true";

  return (
    <AnnotationBox
      label={label}
      ref={boxRef}
      isHighlighted={isHighlighted}
      isSelected={isSelected}
      onClick={onClick}
      top={top}
      left={left}
      style={style}
      onMouseUp={() =>
        onMouseUpAfterResizing(
          boxRef.current.offsetWidth,
          boxRef.current.offsetHeight
        )
      }
    />
  );
};

const DocumentView = ({
  pageBase64Img,
  pageNumber,
  onNewPageNumber,
  numberOfPages,
  pageAnnotations,
  selectedOutputItemId,
  onClickOutputBox,
  onMouseUpAfterResizingAnnotationAtXAndY = (x, y, newWidth, newHeight) => {},
  onNewAnnotationBox = (x, y) => {},
  hasBeenChecked,
  audioSrc = "",
}) => {
  const [naturalWidth, setNaturalWidth] = useState(1);
  const [imageWidth, setImageWidth] = useState(600);
  const pageAnnotationsNoNulls = pageAnnotations?.filter(anno => !!anno);

  const scaleFactor = imageWidth / naturalWidth;

  return (
    <Container>
      <ToolbarContainer>
        {audioSrc ? (
          <audio src={audioSrc} style={{ height: "32px" }} controls />
        ) : (
          <>
            <PageNumberInputCountingFrom1
              pageNumber={pageNumber}
              onNewPageNumber={onNewPageNumber}
              numberOfPages={numberOfPages}
            />

            <ZoomButtons>
              <SmallButton
                value="-"
                onClick={() => setImageWidth(prev => prev - 200)}
              />
              <SmallButton
                value="+"
                onClick={() => setImageWidth(prev => prev + 200)}
              />
            </ZoomButtons>
            <CheckedDot hasBeenChecked={hasBeenChecked} />
          </>
        )}
      </ToolbarContainer>
      <ImageCenteringContainer>
        {pageBase64Img && (
          <ImageAndBoxesContainer style={{ width: `${imageWidth}px` }}>
            <PageImage
              src={`data:image/png;base64,${pageBase64Img}`}
              onLoad={e => setNaturalWidth(e.target.naturalWidth)}
              onClick={e => {
                const x = round(e.nativeEvent.offsetX / scaleFactor);
                const y = round(e.nativeEvent.offsetY / scaleFactor);
                const w = round(50 / scaleFactor);
                const h = round(50 / scaleFactor);
                onNewAnnotationBox({ x, y, w, h });
              }}
            />
            {pageAnnotationsNoNulls?.map(({ x, y, w, h, id, label }, i) => {
              return (
                <ResizeableAnnotationBox
                  label={label}
                  isSelected={selectedOutputItemId === id}
                  onClick={() => {
                    onClickOutputBox(id);
                  }}
                  key={i}
                  top={y * scaleFactor}
                  left={x * scaleFactor}
                  style={{ width: w * scaleFactor, height: h * scaleFactor }}
                  onMouseUpAfterResizing={(newWidth, newHeight) => {
                    onMouseUpAfterResizingAnnotationAtXAndY(
                      x,
                      y,
                      round(newWidth / scaleFactor),
                      round(newHeight / scaleFactor)
                    );
                  }}
                />
              );
            })}
          </ImageAndBoxesContainer>
        )}
      </ImageCenteringContainer>
    </Container>
  );
};

export default DocumentView;
