import { useState, useEffect } from "react";
import styled from "styled-components";
import { updatePreDefinedLabelSet, addPreDefinedLabelSet, shareLabelset } from "api/services/filesService";
import { Delete, Add } from "@material-ui/icons";
import { isEmpty } from "lodash";
import TextInput from "components/inputs/TextInput";
import Button from "components/ui/Button";
import LoadingSpinner from "./ui/LoadingSpinner";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Modal from "./ui/Modal";
import { sleep } from "utils/common";

const LabelRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const CircleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};
  border-radius: 100%;
  border: 2px solid ${props => props.theme.color.primary};
  height: 35px;
  width: 35px;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  svg {
    font-size: 20px;
  }

  :hover {
    color: ${props => props.theme.color.closer2};
    border: 2px solid ${props => props.theme.color.closer2};
  }
`;

const LabelInputContainer = styled.div`
  width: 180px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 350px;
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
  padding: 35px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 8px;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  width: 100%;
  margin-bottom: 15px;
`;

const InvisiblePanelContainer = styled(PanelContainer)`
  justify-content: center;
  align-items: center;
`;

const ActivateIcon = styled.div`
  color: ${props => (props.isActivated ? props.theme.color.primary : props.theme.color.closer1_5)};
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.closest};
  }
`;

const ShareIcon = styled.div`
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

const ModelContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 35px;
  width: 300px;
  heigth: 300px;
  align-items: center;
  justify-content: center;
`;

const UserLabelsPanel = ({ userLabels, isActivated, onActivate = () => {}, onClickBtn = () => {}, showShareIcon }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [labels, setLabels] = useState([]);
  const [group, setGroup] = useState("untitled");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSharedUserModal, setShowSharedUserModal] = useState(false);
  const [sharedUser, setSharedUser] = useState("");
  const [shareMsg, setShareMsg] = useState("");

  useEffect(() => {
    if (userLabels) {
      setIsVisible(true);
      setLabels(userLabels.labels);
      setGroup(userLabels.group);
    }
  }, [userLabels]);

  useEffect(() => {
    setMessage("");
  }, [group, labels]);

  const doUpdatePreDefinedLabels = async () => {
    const newUserLabels = {
      ...userLabels,
      group,
      labels,
    };
    const { error } = await updatePreDefinedLabelSet(newUserLabels);
    if (error) {
      setMessage("Fail while saving labels !");
    } else {
      setMessage("Succeed while saving labels !");
    }
  };

  const doAddPreDefinedLabels = async () => {
    const newUserLabels = {
      group,
      labels,
    };
    const { error } = await addPreDefinedLabelSet(newUserLabels);
    if (error) {
      setMessage("Fail while saving labels !");
    } else {
      setMessage("Succeed while saving labels !");
    }
  };

  const doShareLabelSet = async () => {
    const { error } = await shareLabelset(userLabels.id, sharedUser);
    if (error) {
      setShareMsg("Failed when sharing the label set.");
    } else {
      setShareMsg("Succeed when sharing the label set. ");
    }
  };

  if (!isVisible) {
    return (
      <InvisiblePanelContainer>
        <Button
          value="Add a label set"
          onClick={() => {
            setIsVisible(true);
          }}
        />
      </InvisiblePanelContainer>
    );
  }

  return (
    <PanelContainer>
      <Modal
        open={showSharedUserModal}
        handleClose={() => {
          setShowSharedUserModal(false);
          setSharedUser("");
          setShareMsg("");
        }}
      >
        <ModelContent>
          <TextInput title={"User Name"} value={sharedUser} onNewInput={newVal => setSharedUser(newVal)} />
          {shareMsg}
          <Button value="share" onClick={doShareLabelSet} />
        </ModelContent>
      </Modal>
      <TopBar>
        <div>by {userLabels?.createdBy}</div>
        <ActivateIcon
          isActivated={isActivated}
          onClick={() => {
            onActivate(userLabels.id);
          }}
        >
          <CheckCircleOutlineIcon />
        </ActivateIcon>
        {showShareIcon && (
          <ShareIcon onClick={() => setShowSharedUserModal(true)}>
            <PersonAddIcon />
          </ShareIcon>
        )}
      </TopBar>
      <TextInput
        title={"Group name:"}
        value={group}
        onNewInput={newVal => {
          setGroup(newVal);
        }}
      ></TextInput>
      {!isEmpty(labels) &&
        labels.map((label, idx) => (
          <LabelRow key={`label-${idx}`}>
            <div>label</div>
            <LabelInputContainer>
              <TextInput
                placeholder=""
                value={label}
                onNewInput={newVal => {
                  let updatedLabels = [...labels];
                  updatedLabels.splice(idx, 1, newVal.trim().toLowerCase());
                  setLabels(updatedLabels);
                }}
              />
            </LabelInputContainer>

            <CircleButton
              onClick={() => {
                let updatedLabels = [...labels];
                updatedLabels.splice(idx, 1);
                setLabels(updatedLabels);
              }}
            >
              <Delete />
            </CircleButton>
          </LabelRow>
        ))}
      <CircleButton
        onClick={() => {
          let updatedEmails = [...labels, ""];
          setLabels(updatedEmails);
        }}
      >
        <Add />
      </CircleButton>
      <p>{message}</p>
      <Buttons>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Button
            isDisabled={false}
            value={"Save"}
            onClick={async () => {
              setIsLoading(true);
              if (userLabels) {
                doUpdatePreDefinedLabels();
              } else {
                doAddPreDefinedLabels();
                setIsVisible(false);
                setGroup("");
                setLabels([]);
              }
              await sleep(1000);
              setIsLoading(false);
              onClickBtn();
            }}
          />
        )}
      </Buttons>
    </PanelContainer>
  );
};

export default UserLabelsPanel;
