import { Close } from "@material-ui/icons";
import { patchSmartRecord } from "api/services/searchService";
import Button from "components/ui/Button";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { parseJson } from "utils/common";

const Container = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  display: grid;
  grid-template-rows: auto 1fr;
`;

const InputAndSaveButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const TableNameColumnNameAndInput = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const SmallButton = styled(Button)`
  padding: 2px;
  grid-template-columns: auto;
  gap: 0;
  margin-left: 40px;
  justify-self: end;
  align-self: start;
`;

const CandidatesContainer = styled.div`
  padding-top: 15px;
  line-height: 1.5;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
`;

const TableDocLocationsContainer = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  max-height: 200px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  border-radius: 50%;
  svg {
    width: 18px;
    height: 18px;
  }
  width: max-content;
  height: max-content;
  display: none;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const AnnotationBox = styled.div`
  position: relative;
  padding: 5px 15px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
  min-height: 80px;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
    ${CloseIconContainer} {
      display: flex;
    }
  }
  white-space: pre-wrap;

  border: 1px solid ${props => (props.isHighlighted ? props.theme.color.feature : "transparent")};
`;

const Cell = styled.div`
  padding: 10px;
  border: 0.5px solid ${props => props.theme.color.closer1_5};
  background-color: ${props => (props.isWhite ? props.theme.color.closer0 : `${props.theme.color.closer1}66`)};
  ${props => props.isCollapsed && "width: 0; padding: 0; overflow: hidden;"}
`;

const BoldCell = styled(Cell)`
  font-weight: 600;
`;

const UnitSpan = styled.span`
  padding: 2px;
  font-size: 10px;
  font-weight: 600;
`;

const unitToSymbol = {
  PERCENTAGE: "%",
  GBP: "£",
  USD: "$",
  EUR: "€",
};

const SolrRecordEditor = ({ solrId, tableName, tableId, columnName, solrValue, userReferenceCandidates = [] }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const annotationIdToHighlight = parseInt(searchParams.get("annotationIdToHighlight"));
  const pageNumber = parseInt(searchParams.get("pageNumber"));
  const rowIdName = searchParams.get("rowIdName");
  const rowIdValue = searchParams.get("rowIdValue");

  const [userCorrectionStr, setUserCorrectionStr] = useState("");
  const [isPatchingSmartRecord, setIsPatchingSmartRecord] = useState(false);
  const [patchErr, setPatchErr] = useState(null);

  const solrValueJson = parseJson(solrValue);
  // solrValueJson.candidates = {
  //   answer: "10 Downing Street",
  //   tableDocumentLocations: [
  //     {
  //       pageNumber: 15,
  //       annotationId: 2,
  //       annotationContent: "whatever text",
  //     },
  //     {
  //       pageNumber: 10,
  //       annotationId: 3,
  //       annotationContent: "another whatever text",
  //     },
  //   ],
  // };

  const [tableDocumentLocationsToRemove, setTableDocumentLocationsToRemove] = useState([]);

  useEffect(() => {
    setUserCorrectionStr(
      solrValueJson?.RawValue?.[0] === "?" ? solrValueJson?.RawValue?.slice(1) : solrValueJson?.RawValue
    );
  }, [solrValueJson?.RawValue]);

  return (
    <Container>
      <InputAndSaveButton>
        <BoldSpan>{tableName}</BoldSpan>
        <SmallButton
          isDisabled={isPatchingSmartRecord}
          value="Save"
          onClick={async () => {
            setIsPatchingSmartRecord(true);

            let newCandidates = {
              answer: solrValueJson?.candidates?.answer,
              tableDocumentLocations: solrValueJson?.candidates?.tableDocumentLocations || [],
            };
            newCandidates.tableDocumentLocations = [
              ...(solrValueJson?.candidates?.tableDocumentLocations || []),
              ...userReferenceCandidates,
            ]?.filter(
              tableDocLocation =>
                !tableDocumentLocationsToRemove.find(
                  tableDocLocationToRemove =>
                    tableDocLocationToRemove?.annotationId === tableDocLocation?.annotationId &&
                    tableDocLocationToRemove?.pageNumber === tableDocLocation?.pageNumber
                )
            );

            const newSolrValueJson = {
              ...solrValueJson,
              RawValue: userCorrectionStr,
              Value: userCorrectionStr,
              candidates: newCandidates,
            };

            const { error: patchErr } = await patchSmartRecord(solrId, { value: JSON.stringify(newSolrValueJson) });
            setPatchErr(patchErr);
            setIsPatchingSmartRecord(false);

            if (!patchErr && tableId) {
              navigate(`/table-configs/${tableId}/results`);
            }
          }}
        />
        <TableNameColumnNameAndInput>
          <BoldCell isWhite isCollapsed={rowIdName === columnName}>
            {rowIdName}
          </BoldCell>
          <BoldCell>{columnName}</BoldCell>
          <Cell isWhite isCollapsed={rowIdName === columnName}>
            {rowIdValue}
          </Cell>
          <Cell>
            <input
              disabled={rowIdName === columnName}
              type="text"
              value={userCorrectionStr}
              onChange={e => {
                e.preventDefault();
                e.stopPropagation();
                setUserCorrectionStr(e.target.value);
              }}
            />
            {/* <UnitSpan>{unitToSymbol?.[solrValueJson?.Unit]}</UnitSpan> */}
          </Cell>
        </TableNameColumnNameAndInput>
      </InputAndSaveButton>
      {patchErr && <div>{JSON.stringify(patchErr)}</div>}
      <CandidatesContainer isVisible>
        {solrValueJson?.candidates?.answer && (
          <div>
            Suggested value: <BoldSpan>{solrValueJson?.candidates?.answer}</BoldSpan>
          </div>
        )}
        <div>Reference</div>
        <TableDocLocationsContainer>
          {[...(solrValueJson?.candidates?.tableDocumentLocations || []), ...userReferenceCandidates]
            ?.filter(
              tableDocLocation =>
                !tableDocumentLocationsToRemove.find(
                  tableDocLocationToRemove =>
                    tableDocLocationToRemove?.annotationId === tableDocLocation?.annotationId &&
                    tableDocLocationToRemove?.pageNumber === tableDocLocation?.pageNumber
                )
            )
            ?.reverse()
            ?.map((tableDocLocation, index) => (
              <AnnotationBox
                isHighlighted={
                  annotationIdToHighlight === tableDocLocation?.annotationId &&
                  pageNumber === tableDocLocation?.pageNumber
                }
                onClick={() => {
                  searchParams.set("annotationIdToHighlight", tableDocLocation?.annotationId);
                  searchParams.set("pageNumber", tableDocLocation?.pageNumber);
                  navigate({ search: searchParams.toString() });
                }}
                key={index}
              >
                {tableDocLocation?.annotationContent}
                <CloseIconContainer
                  onClick={() => {
                    setTableDocumentLocationsToRemove([...tableDocumentLocationsToRemove, tableDocLocation]);
                  }}
                >
                  <Close />
                </CloseIconContainer>
              </AnnotationBox>
            ))}
        </TableDocLocationsContainer>
      </CandidatesContainer>
    </Container>
  );
};

export default SolrRecordEditor;
