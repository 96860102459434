import styled from "styled-components";
import { useState, useEffect } from "react";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import SelectInput from "components/ui/SelectInput";
import TextInputSquare from "components/inputs/TextInputSquare";
import SmallButton from "components/ui/SmallButton";
import { getSizerConfigs, postSizerFillUp } from "api/services/ccService";
import { Fragment } from "react";
import { getSigmaTableConfig } from "api/services/sigmaService";
import { Edit } from "@material-ui/icons";
import { getDealNames } from "api/services/filesService";

const ModalContent = styled.div`
  padding: 15px;
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 15px;
  column-gap: 5px;
  align-items: center;
  justify-content: start;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  label {
    padding: 5px;
  }
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 5px 40px;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  grid-column: span 2;
`;

const FieldTitle = styled.div``;

const ConfigContainer = styled.div`
  width: 100%;
  overflow: auto;
  background-color: ${props => props.theme.color.closer2}20;
  padding: 5px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  visibility: ${props => (props.isExpanded ? "visible" : "hidden")};
  height: ${props => (props.isExpanded ? "auto" : "0px")};
  transition: height 0.2s;
  align-content: start;
  max-height: 300px;
`;

const InnerConfigContainer = styled.div`
  width: 100%;
  overflow: auto;
  background-color: ${props => props.theme.color.closer2}20;
  padding: 5px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 5px;
  column-gap: 10px;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const ConfigList = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ShowConfigButton = styled(SmallButton)`
  justify-self: start;
`;

const SigmaTableName = ({ sigmaTableId }) => {
  const [sigmaTableName, setSigmaTableName] = useState("");

  useEffect(() => {
    doPopulateSigmaTableName();
  }, [sigmaTableId]);

  const doPopulateSigmaTableName = async () => {
    const { data } = await getSigmaTableConfig(sigmaTableId);
    setSigmaTableName(data?.name);
  };

  return <span>{sigmaTableName}</span>;
};

const IconContainer = styled.div`
  svg {
    height: 14px;
    fill: ${props => props.theme.color.closer2};
  }
  cursor: pointer;

  :hover {
    svg {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const userFriendlyName = {
  sheetName: "Sheet name",
  tableHeader: "Table top left cell",
  startingRow: "Start row",
  sigmaTableFilterColumn: "Filter column",
  sigmaTableFilterColumnValue: "Filter value",
};

const DealSelectAndInput = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DealNameValue = styled.div`
  width: 150px;
  color: ${props => props.theme.color.primary};
  font-weight: 600;
  margin-right: 50px;
  word-break: break-all;
`;

const CreateSizerModalTrigger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dealName, setDealName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sizerConfigs, setSizerConfigs] = useState([]);
  const [selectedSizerConfigId, setSelectedSizerConfigId] = useState(null);
  const [isConfigVisible, setIsConfigVisible] = useState(false);

  const [dealNames, setDealNames] = useState([]);

  useEffect(() => {
    doPopulateSizerConfigs();
    doPopulateDealNames();
  }, []);

  const doPopulateSizerConfigs = async () => {
    const { data } = await getSizerConfigs();
    setSizerConfigs(data || []);
    setSelectedSizerConfigId(data[0]?.id);
  };

  const doPopulateDealNames = async () => {
    const { data } = await getDealNames();
    setDealNames(data || []);
    setDealName(data?.[0] || "");
  };

  const doTriggerExcelFillUp = async () => {
    setIsLoading(true);
    const { error } = await postSizerFillUp({ dealName, configId: selectedSizerConfigId });
    if (error) {
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsLoading(false);
    setIsOpen(false);
    window.location.reload();
  };

  const selectedConfig = sizerConfigs.find(config => config.id === selectedSizerConfigId);

  return (
    <>
      <ButtonCreateNewAsset onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Title>Create sizer</Title>

          {/* <FieldTitle>Template excel</FieldTitle>
          <Link to="/excel-views/642d54fe47bf171f29893ea0">Template SIZER_Multifamily_v15.xlsx</Link> */}

          <FieldTitle>Deal name</FieldTitle>
          <DealSelectAndInput>
            <DealNameValue>{dealName}</DealNameValue>
            <SelectInput value={dealName} onSetNewValue={newDealName => setDealName(newDealName)}>
              <option value="">-</option>
              {dealNames.map((dealName, i) => (
                <option key={i} value={dealName}>
                  {dealName}
                </option>
              ))}
            </SelectInput>
            <StyledTextInputSquare
              placeholder="Custom name"
              value={dealName}
              onNewInput={newDealName => setDealName(newDealName)}
            />
          </DealSelectAndInput>
          <FieldTitle>Config</FieldTitle>
          <SelectInput value={selectedSizerConfigId} onSetNewValue={newId => setSelectedSizerConfigId(newId)}>
            {sizerConfigs.map(config => (
              <option key={config.id} value={config.id}>
                {config?.name}
              </option>
            ))}
          </SelectInput>

          <div />
          <ShowConfigButton
            value={isConfigVisible ? "Hide config" : "Show config"}
            onClick={() => setIsConfigVisible(!isConfigVisible)}
          />

          <div />
          <ConfigContainer isExpanded={isConfigVisible}>
            <BoldSpan>Config name: </BoldSpan>
            {selectedConfig?.name}
            <BoldSpan></BoldSpan>
            <ConfigList>
              {selectedConfig?.values.map((value, valueInd) => (
                <InnerConfigContainer key={valueInd}>
                  {Object.entries(value)?.map(([key, value], i) => {
                    if (key === "sigmaTableFilterColumnValue") {
                      return null;
                    }

                    if (key === "sigmaTableId") {
                      return (
                        <Fragment key={i}>
                          <BoldSpan>Σ table name:</BoldSpan>
                          <SigmaTableName key={i} sigmaTableId={value} />
                          <IconContainer>
                            <Edit />
                          </IconContainer>
                        </Fragment>
                      );
                    }

                    return (
                      <Fragment key={i}>
                        <BoldSpan>{userFriendlyName[key] || key}:</BoldSpan>
                        {value}
                        <IconContainer>
                          <Edit />
                        </IconContainer>
                      </Fragment>
                    );
                  })}
                </InnerConfigContainer>
              ))}
            </ConfigList>
          </ConfigContainer>

          <StyledSmallButton isDisabled={!dealName} value="Create" onClick={doTriggerExcelFillUp} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateSizerModalTrigger;
