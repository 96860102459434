import styled from "styled-components";
import { useState } from "react";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SelectInput from "components/ui/SelectInput";
import { Add, Close } from "@material-ui/icons";
import { useEffect } from "react";
import { getSigmaTableConfig, getSigmaTableConfigs, patchSigmaConfig, postSigmaRule } from "api/services/sigmaService";
import TextArea from "components/ui/TextArea";

const TriggerButton = styled(Button)`
  padding: 0;
`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
  height: 750px;
  overflow: auto;

  display: grid;
  align-content: start;
  justify-content: start;
  align-items: center;
  gap: 10px;
  grid-template-columns: 200px 300px;
  grid-template-rows: repeat(10, auto) 1fr;
  grid-template-areas:
    "title title"
    "sub2 sub2"
    "choose-table choose-column"
    "sub1 sub1"
    "example selected-value"
    "name-text name-input"
    "type-text type-input"
    "value-type-text value-type-input"
    "keywords-text keywords-values"
    "units-text units-input"
    "create-btn error-msg";
`;

const SelectedValue = styled.div`
  grid-area: selected-value;
  font-size: 20px;
  color: ${props => props.theme.color.primary};
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  grid-area: title;
  margin-bottom: 15px;
`;

const SubTitle1 = styled.div`
  grid-area: sub1;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const ExampleText = styled.div`
  grid-area: example;
`;

const NameText = styled.div`
  grid-area: name-text;
`;

const NameInput = styled(TextInputSquare)`
  grid-area: name-input;
  label {
    padding: 5px 15px;
  }
`;

const TypeText = styled(NameText)`
  grid-area: type-text;
`;

const TypeSelectInput = styled(SelectInput)`
  grid-area: type-input;
`;

const ValueTypeText = styled(NameText)`
  grid-area: value-type-text;
`;

const ValueTypeInput = styled(SelectInput)`
  grid-area: value-type-input;
`;

const KeywordsText = styled(NameText)`
  grid-area: keywords-text;
  align-self: start;
`;

const KeywordsValues = styled.div`
  grid-area: keywords-values;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const KeywordInput = styled(TextInputSquare)`
  label {
    padding: 5px 15px;
  }
`;

const KeywordInputAndDelete = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 30px;
  gap: 5px;
  align-items: center;
  align-self: start;
`;

const AddButton = styled.div`
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.closer0};
  }
  border-radius: 50%;
  padding: 0px;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(AddButton)``;

const UnitsText = styled(NameText)`
  grid-area: units-text;
`;

const UnitsInput = styled(NameInput)`
  grid-area: units-input;
`;

const SubTitle2 = styled(SubTitle1)`
  margin-top: 0;
  grid-area: sub2;
`;

const CreateButton = styled(Button)`
  grid-area: create-btn;
  justify-self: start;
  align-self: end;
  grid-template-columns: auto;
  gap: 0;
`;

const PropmptTextArea = styled(TextArea)`
  grid-area: keywords-values;
`;

const ErrorMsg = styled.div`
  grid-area: error-msg;
  color: ${props => props.theme.color.error};
  align-self: end;
`;

const ChooseTableSelect = styled(SelectInput)`
  grid-area: choose-table;
`;

const ChooseColumnSelect = styled(SelectInput)`
  grid-area: choose-column;
`;

const CreateRuleModal = ({ selectedStr, onRuleCreated = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [ruleType, setRuleType] = useState("MANUAL");
  const [valueType, setValueType] = useState("TEXT");
  const [keywords, setKeywords] = useState([]);
  const [unitsStr, setUnitsStr] = useState("");

  const [tableConfigs, setTableConfigs] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState("");

  const [columnNames, setColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState("");

  const [sigmaConfigs, setSigmaConfigs] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    doPopulateTableConfigs();
  }, []);

  useEffect(() => {
    const selectedTableId = tableConfigs.find(tableConfig => tableConfig.name === selectedTableName)?.id;
    selectedTableId && doPopulateColumnNames(selectedTableId);
  }, [selectedTableName]);

  const doPopulateTableConfigs = async () => {
    const { data } = await getSigmaTableConfigs();
    setTableConfigs(data);
  };

  const doPopulateColumnNames = async tableId => {
    const { data } = await getSigmaTableConfig(tableId);
    setSigmaConfigs(data?.sigmaConfigs);
    setColumnNames(data?.sigmaConfigs?.map(config => config?.name) || []);
  };

  return (
    <>
      <TriggerButton value="Create rule" onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Create new rule</Title>
          <SubTitle1>Specify rule details</SubTitle1>
          <ExampleText>Selected value</ExampleText>
          <SelectedValue>{selectedStr}</SelectedValue>
          <NameText>Name</NameText>
          <NameInput value={name} onNewInput={newName => setName(newName)} placeholder="Address extractor" />
          <TypeText>Rule Type</TypeText>
          <TypeSelectInput value={ruleType} onSetNewValue={newRuleType => setRuleType(newRuleType)}>
            <option value="MANUAL">KEYWORD</option>
            <option value="GPT">GPT</option>
          </TypeSelectInput>
          <ValueTypeText>Value Type</ValueTypeText>
          <ValueTypeInput value={valueType} onSetNewValue={newValueType => setValueType(newValueType)}>
            <option>TEXT</option>
            <option value="NUMERICAL">NUMBER</option>
            <option>DATE</option>
            <option>CURRENCY</option>
          </ValueTypeInput>
          {ruleType === "MANUAL" && (
            <>
              <KeywordsText>Keywords (matched to the left of value)</KeywordsText>
              <KeywordsValues>
                {keywords.map((keyword, index) => (
                  <KeywordInputAndDelete key={index}>
                    <KeywordInput
                      key={index}
                      value={keyword}
                      onNewInput={newKeyword =>
                        setKeywords([...keywords.slice(0, index), newKeyword, ...keywords.slice(index + 1)])
                      }
                    />
                    <DeleteButton
                      onClick={() => setKeywords([...keywords.slice(0, index), ...keywords.slice(index + 1)])}
                    >
                      <Close />
                    </DeleteButton>
                  </KeywordInputAndDelete>
                ))}
                <AddButton onClick={() => setKeywords([...keywords, ""])}>
                  <Add />
                </AddButton>
              </KeywordsValues>
              <UnitsText>Units</UnitsText>
              <UnitsInput value={unitsStr} onNewInput={newUnitsStr => setUnitsStr(newUnitsStr)} placeholder="SF" />
            </>
          )}
          {ruleType === "GPT" && (
            <>
              <KeywordsText>Prompt</KeywordsText>
              <PropmptTextArea placeholder="Extract year without the month" />
            </>
          )}
          <SubTitle2>Assign rule</SubTitle2>
          <ChooseTableSelect
            title="Choose table"
            value={selectedTableName}
            onSetNewValue={newTableName => setSelectedTableName(newTableName)}
          >
            <option value="">-</option>
            {tableConfigs?.map(table => (
              <option key={table?.id}>{table?.name}</option>
            ))}
          </ChooseTableSelect>
          {columnNames?.length > 0 && (
            <ChooseColumnSelect
              title="Choose column"
              value={selectedColumnName}
              onSetNewValue={newColumnName => setSelectedColumnName(newColumnName)}
            >
              <option value="">-</option>
              {columnNames?.map(columnName => (
                <option key={columnName}>{columnName}</option>
              ))}
            </ChooseColumnSelect>
          )}
          <CreateButton
            isDisabled={isCreating || !name || !selectedColumnName || !selectedTableName}
            value="Create rule"
            variant="highlighted"
            onClick={async () => {
              setIsCreating(true);
              setErrorMsg("");
              const newRuleDescription =
                ruleType === "MANUAL" ? { value_type: valueType, keywords, units: unitsStr } : {};
              const newRule = {
                name,
                type: ruleType,
                description: "",
              };

              const { data: rule, error: ruleCreationErr } = await postSigmaRule(newRule);
              if (ruleCreationErr) {
                setErrorMsg("Please try different rule name");
                setIsCreating(false);
                return;
              }

              const selectedSigmaConfigId = sigmaConfigs?.find(config => config?.name === selectedColumnName)?.id;
              const { error: patchSigmaConfigErr } = await patchSigmaConfig(selectedSigmaConfigId, {
                rule: {
                  id: rule?.id,
                },
                description: JSON.stringify(newRuleDescription),
              });
              if (patchSigmaConfigErr) {
                setErrorMsg("Could not patch sigma config");
                setIsCreating(false);
                return;
              }

              setIsCreating(false);
              setIsOpen(false);
              onRuleCreated({ ...newRule, description: newRuleDescription });
            }}
          />
          <ErrorMsg>{errorMsg}</ErrorMsg>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateRuleModal;
