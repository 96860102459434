import { useNavigate, useSearchParams } from "react-router-dom";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import styled from "styled-components";
import SmallButton from "components/ui/SmallButton";
import {
  DownloadIcon,
  InternetIcon,
  PdfIcon,
  PlusIcon,
  SaveTickIcon,
  WordIcon,
} from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";
import { useState, useEffect } from "react";
import { postWordDoc } from "api/services/wordDocsService";
import { cloneDeep, isEqual, last, uniqWith } from "lodash";
import MetaTagsViewer from "components/MetaTagsViewer";
import { postChatflowSearch } from "api/services/searchService";
import LoadingSpinner from "components/ui/LoadingSpinner";
import useSearchParamsState from "hooks/useSearchParamsState";
import { postIngestPublicContent } from "api/services/filesService";
import ErrorMessageModal from "components/ErrorMessageModal";
import ItemListPopover from "components/ItemListPopover";
import { getQuickStarts } from "api/services/quickStarts";

const LinksContainer = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 100px;
  margin-top: 20px;
`;

const LinkContainer = styled.div`
  max-width: 800px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: start;
  gap: 10px;
  padding: 8px;
  background-color: ${props =>
    props.isHighlighted ? `${props.theme.color.closer0}88` : "transparent"};
  border-left: 2px solid
    ${props =>
      props.isHighlighted ? `${props.theme.color.primary}88` : "transparent"};

  :hover {
    background-color: ${props =>
      props.isHighlighted
        ? `${props.theme.color.closer1}DD`
        : props.theme.color.closer0};
  }
`;

const LinkTitle = styled.a`
  font-size: 20px;
  width: max-content;
  text-decoration: none;
  color: ${props => props.theme.color.primary};
`;

const LinkDescription = styled.div`
  line-height: 1.2;
  color: #616161;
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 4px;
`;

const StyledTooltip = styled(Tooltip)`
  transform: translateX(0);
`;

const RightAlignedTooltip = styled(Tooltip)`
  transform: translateY(8px) translateX(-18px);
`;

const ButtonContainer = styled.div``;

const Url = styled.div`
  font-size: 12px;
  color: #8d8d8d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
`;

const IconButtonDownload = styled(DownloadIcon)`
  padding: 2px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  height: 24px;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const IconButtonSave = styled(SaveTickIcon)`
  padding: 2px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  height: 24px;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const IconButtonRemove = styled(IconButtonSave)`
  fill: ${props => props.theme.color.primary};
`;

const StickyTopContainer = styled.div`
  margin-right: 100px;
  position: sticky;
  background-color: ${props => props.theme.color.furthest};
  z-index: 1;
  top: 61px;
  padding-top: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  display: grid;
  gap: 8px;
  width: 100%;
  margin-top: -10px;
`;

const FixedSideContainer = styled.div`
  position: fixed;
  right: 0;
  border-left: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 30px;
  padding-top: 21px;
  width: 400px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const TitleAndNewBtn = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const SideTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const CompactSourceItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 22px auto;
  align-items: center;
  max-width: 300px;
  white-space: nowrap;
`;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;

  transform: translate(100%, -40px);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.2);
`;

const NewDocBtn = styled.div`
  display: flex;
  gap: 4px;
  border: 2px solid ${props => props.theme.color.closer1};
  align-items: center;
  font-weight: normal;
  padding: 4px;

  border: 2px solid;
  border-image: linear-gradient(
      to right,
      ${props => props.theme.color.feature},
      ${props => props.theme.color.primary}
    )
    1;
  :hover {
    background: linear-gradient(
      to right,
      ${props => props.theme.color.feature},
      ${props => props.theme.color.primary}
    );
    svg {
      fill: white;
    }
  }
`;

const QuickStartBtns = styled.div`
  background-color: ${props => props.theme.color.furthest};
  white-space: nowrap;
`;

const NewItemBtn = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: 22px auto;

  padding: 8px;
  width: 150px;
  overflow: hidden;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0_hover};
  }
`;

const TITLE_GREY = "#a0a0a0";
const NewItemsTitle = styled.div`
  padding: 8px;
  color: ${TITLE_GREY};
  font-size: 10px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Search = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = searchParams?.get("query");

  const [searchResuls, setSearchResults] = useState(null);
  const [isFetchingSearchResults, setIsFetchingSearchResults] = useState(false);
  const [sources, setSources] = useState([]);
  const [isCreatingWordDoc, setIsCreatingWordDoc] = useState(false);
  const [linkIdBeingDownloaded, setLinkIdBeingDownloaded] = useState("");
  const [filterTagValues, setFilterTagValues] = useSearchParamsState({
    initialValue: [],
    paramName: "filterTagValues",
  });
  const [error, setError] = useState(null);
  const [quickStarts, setQuickStarts] = useState([]);

  useEffect(() => {
    doPopulateQuickStarts();
  }, []);

  const doPopulateQuickStarts = async () => {
    const { data } = await getQuickStarts();
    setQuickStarts(data?.items || []);
  };

  useEffect(() => {
    doPopulateSearchResults();
  }, [query]);

  const doPopulateSearchResults = async () => {
    setSearchResults(null);
    setIsFetchingSearchResults(true);
    const { data } = await postChatflowSearch(
      {
        query,
      },
      {}
    );
    setSearchResults(data || []);
    setIsFetchingSearchResults(false);
  };

  const doPostNewDocAndRedirect = async (
    initialContent = { blocks: [{ text: "" }] }
  ) => {
    setIsCreatingWordDoc(true);
    const { data, error } = await postWordDoc(
      {},
      {
        fileName: "Untitled",
        content: { ...initialContent, sources },
      }
    );
    if (!error) {
      window.open(`/word-docs/${data?.id}`, "_blank").focus();
      // navigate(`/word-docs/${data?.id}`);
    }
    setIsCreatingWordDoc(false);
  };

  const doDownloadLink = async link => {
    setLinkIdBeingDownloaded(link?.id);
    const { data: ingestedFile, error } = await postIngestPublicContent(
      {},
      {
        id: link?.id,
        type: link?.type,
        fileName: link?.fileName,
        url: link?.url,
      }
    );
    if (error) {
      setLinkIdBeingDownloaded("");
      return;
    }

    const newSearchResults = cloneDeep(searchResuls);
    const linkIndex = newSearchResults?.links?.findIndex(
      searchLink => searchLink?.id === link?.id
    );
    newSearchResults.links[linkIndex].ingestedFileId = ingestedFile?.id;
    setSearchResults(newSearchResults);

    setLinkIdBeingDownloaded("");
  };

  if (isFetchingSearchResults) {
    return (
      <CenteredWithTopNavLayout
        centerColumnMaxWidth="100%"
        contentTopPadding={0}
      >
        <Gap height="100px" />
        <LoadingSpinner />
      </CenteredWithTopNavLayout>
    );
  }

  if (searchResuls?.links?.length === 0) {
    return (
      <CenteredWithTopNavLayout
        centerColumnMaxWidth="100%"
        contentTopPadding={0}
      >
        <LinksContainer>No results found</LinksContainer>
      </CenteredWithTopNavLayout>
    );
  }

  const allMetaTags = searchResuls?.links?.map(link => link?.metaTags)?.flat();
  const uniqueMetaTags = uniqWith(allMetaTags, isEqual);

  return (
    <CenteredWithTopNavLayout
      centerColumnMaxWidth="100%"
      contentTopPadding={71}
    >
      <StickyTopContainer>
        <MetaTagsViewer
          isExpandable
          metaTags={uniqueMetaTags}
          onTagClick={tag => {
            if (filterTagValues?.includes(tag?.value)) {
              setFilterTagValues(
                filterTagValues.filter(value => value !== tag?.value)
              );
            } else {
              setFilterTagValues([...filterTagValues, tag?.value]);
            }
          }}
          highlightedTagValues={filterTagValues}
        />
      </StickyTopContainer>

      <FixedSideContainer>
        <TitleAndNewBtn style={{ paddingBottom: "10px" }}>
          <SideTitle>Selected Sources</SideTitle>
          <StyledItemListPopover
            isDisabled={isCreatingWordDoc || sources?.length === 0}
            iconComponent={
              <NewDocBtn>
                <PlusIcon height="8px" />
                <WordIcon />
              </NewDocBtn>
            }
          >
            <QuickStartBtns>
              <NewItemsTitle>CHOOSE TEMPLATE</NewItemsTitle>
              {quickStarts?.map(quickStart => (
                <NewItemBtn
                  onClick={() => doPostNewDocAndRedirect(quickStart?.content)}
                >
                  <WordIcon />
                  <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {quickStart?.fileName}
                  </div>
                </NewItemBtn>
              ))}
            </QuickStartBtns>
          </StyledItemListPopover>
        </TitleAndNewBtn>

        {sources?.map((source, i) => (
          <CompactSourceItem>
            {source?.type === "FILE" && (
              <PdfIcon style={{ marginTop: "2px", fill: "#ed1c24" }} />
            )}
            {source?.type === "WEB_PAGE" && (
              <InternetIcon style={{ marginTop: "2px" }} />
            )}
            <span
              style={{
                overflowX: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {source?.fileName}
            </span>
          </CompactSourceItem>
        ))}
      </FixedSideContainer>

      <LinksContainer>
        {searchResuls?.links
          ?.filter(link => {
            if (filterTagValues?.length === 0) {
              return true;
            }
            const linkMetaTags = link?.metaTags?.map(meta => meta?.value);
            return filterTagValues?.every(value =>
              linkMetaTags?.includes(value)
            );
          })
          .map((link, i) => {
            const isLinkInSources = sources?.find(
              source => source?.fileId === link?.ingestedFileId
            );

            let linkActionTooltipMsg = "";
            let linkActionBtn = null;
            let linkIcon = null;

            if (isLinkInSources) {
              linkActionTooltipMsg = "Remove from sources";
              linkActionBtn = (
                <IconButtonRemove
                  onClick={() =>
                    setSources(
                      sources.filter(
                        source => source?.fileId !== link?.ingestedFileId
                      )
                    )
                  }
                />
              );
            }
            if (!isLinkInSources) {
              linkActionTooltipMsg = "Add to sources";
              linkActionBtn = (
                <IconButtonSave
                  onClick={() =>
                    setSources([
                      ...sources,
                      {
                        fileId: link?.ingestedFileId,
                        fileName: link?.url?.endsWith(".pdf")
                          ? last(link?.url?.split("/"))
                          : link?.fileName,
                        type: link?.type,
                      },
                    ])
                  }
                />
              );
            }
            if (!link?.ingestedFileId) {
              linkActionTooltipMsg = "Download to my source files";
              linkActionBtn = (
                <IconButtonDownload
                  isDisabled={linkIdBeingDownloaded === link?.id}
                  onClick={() => doDownloadLink(link)}
                />
              );
            }

            if (link?.type === "FILE") {
              linkIcon = (
                <PdfIcon style={{ marginTop: "2px", fill: "#ed1c24" }} />
              );
            }
            if (link?.type === "WEB_PAGE") {
              linkIcon = <InternetIcon style={{ marginTop: "2px" }} />;
            }
            if (link?.type === "WEB_PAGE" && link?.url?.endsWith(".pdf")) {
              linkIcon = (
                <PdfIcon style={{ marginTop: "2px", fill: "#ed1c24" }} />
              );
            }

            return (
              <LinkContainer key={i} isHighlighted={isLinkInSources}>
                <StyledTooltip title={linkActionTooltipMsg}>
                  {linkActionBtn}
                </StyledTooltip>
                {linkIcon}
                <div>
                  <LinkTitle
                    href={link?.url || `/files/${link?.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link?.fileName}
                  </LinkTitle>
                  <Gap height="10px" />
                  <LinkDescription>{link?.description}</LinkDescription>
                  <Gap height="10px" />
                  <Url>{link?.url?.replace("https://", "")}</Url>
                  <Gap height="10px" />
                  <MetaTagsViewer metaTags={link?.metaTags} isReadOnly />
                </div>
              </LinkContainer>
            );
          })}
      </LinksContainer>

      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </CenteredWithTopNavLayout>
  );
};

export default Search;
