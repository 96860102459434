import styled from "styled-components";
import { useState } from "react";

import { getColorFromString } from "utils/common";
import { Close } from "@material-ui/icons";

const Label = styled.div`
  display: flex;
  color: white;
  padding: 2px;
  background-color: ${props => props.bgColor};
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: none;
  padding: 0;
  color: white;
  width: calc(359px / 5);
  font-size: 12px;
`;

const CloseButtonContainer = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
  border-radius: 20px;
  :hover {
    background: ${props => props.theme.color.furthest};
    color: ${props => props.theme.color.closest};
  }

  display: flex;
  align-items: center;
  height: 100%;
  svg {
    width: 14px;
    height: 12px;
  }
`;

const LegendLabelInput = ({ label, annotations, onNewAnnotations }) => {
  const [newLabelName, setNewLabelName] = useState(label);

  return (
    <Label bgColor={getColorFromString(label)}>
      <Input
        value={newLabelName}
        onChange={e => setNewLabelName(e.target.value)}
        onBlur={e => {
          const newLabel = e.target.value;
          const newAnnotations = annotations.map(annotation => {
            return annotation?.label === label ? { ...annotation, label: newLabel } : annotation;
          });
          onNewAnnotations(newAnnotations);
        }}
      />
      <CloseButtonContainer
        onClick={() => {
          const newAnnotations = annotations.filter(annotation => annotation?.label !== label);
          onNewAnnotations(newAnnotations);
        }}
      >
        <Close />
      </CloseButtonContainer>
    </Label>
  );
};

export default LegendLabelInput;
