import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Typewriter from "typewriter-effect";

import { CenteredWithTopNavLayout } from "components/Layout";
import { getTemplateDashboardConfig } from "api/services/chatService";
import { useState } from "react";
import {
  AnimatedLogo,
  BoltzhubLogoInner,
  HumanIcon,
  SendIcon,
} from "components/ui/Icons";
import { useRef } from "react";
import ButtonDark from "components/ui/ButtonDark";
import Modal from "components/ui/Modal";
import { Fragment } from "react";
import { MODEL_SELECT_OPTIONS } from "pages/trial-dashboards/:wordDocId/source";
import { KeyboardArrowDown } from "@material-ui/icons";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import { getWordDoc } from "api/services/wordDocsService";
import { parseJson } from "utils/common";

const BUBBLES = [
  {
    author: "user",
    items: [{ text: "I am a real estate asset manager" }],
  },
  {
    author: "user",
    items: [
      {
        text: "Sort out my emails. I want to know deal price, loan amount, location and contact details",
      },
    ],
  },
  {
    author: "bot",
    items: [{ text: "Certainly! Building a table from your emails..." }],
  },
  {
    author: "bot",
    items: [
      {
        text: "Here is the table:",
      },
      {
        columns: [
          { name: "name", label: "Column name" },
          { name: "description", label: "Extraction request" },
        ],
        records: [],
      },
    ],
  },
  {
    author: "action",
    items: [{ type: "button", text: "See it in action" }],
  },
];

const SelectCustom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  width: 150px;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding: 0;
  display: grid;
  justify-content: center;
  grid-template-rows: calc(100vh - 70px - 50px) 70px;
  background-color: #ffffff;
  margin-top: 50px;
`;

const BubblesContainer = styled.div`
  display: grid;
  gap: 8px;
  padding-top: 4px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 800px;
  overflow: auto;
  padding-bottom: 20px;
  background-color: #ffffff;

  ::-webkit-scrollbar {
    display: none;
  }

  align-items: start;
  align-content: start;

  justify-content: start;
  justify-items: start;
`;

const Bubble = styled.div`
  font-weight: ${props => (props.isUser ? 300 : 500)};
  padding: 4px 12px;
  color: ${props => (props.isUser ? props.theme.color.closest : "#0A1E54")};
  background-color: ${props =>
    props.isUser ? props.theme.color.closer0 : "#D3E2FD"};
  border-radius: 10px;
  box-shadow: ${props => props.theme.shadow};
  line-height: 1.5;
  width: max-content;
  max-width: 540px;
`;

const BubbleContainer = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 20px;
  opacity: 0;
  transition: opacity 0.2s;
  width: 800px;
  justify-content: start;
`;

const Td = styled.td`
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.color.closest}55;
  font-weight: 300;
  transition: opacity 0.2s, border 0.2s;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid ${props => props.theme.color.closest}55;
  font-weight: 500;
  text-align: left;
  transition: opacity 0.2s, border 0.2s;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const LogoAndIntroText = styled.div`
  display: grid;
  gap: 32px;
  justify-self: center;
  justify-items: center;
  width: 600px;
  align-self: center;
  transition: opacity 0.2s;
  position: absolute;
  pointer-events: none;
`;

// pulse animation
const BoltzhubLogoInnerAnimated = styled(BoltzhubLogoInner)`
  animation: pulse-opacity 1.5s infinite;
  @keyframes pulse-opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.05;
    }
    100% {
      opacity: 1;
    }
  }
`;

const IntroText = styled.div`
  font-size: 20px;
`;

const BotPic = styled(BoltzhubLogoInner)`
  height: 18px;
`;

const BotProfilePicContainer = styled.div`
  height: 32px;
  width: 32px;
  border: 2px solid ${props => props.theme.color.primary};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
`;

const BotProfilePic = () => (
  <BotProfilePicContainer>
    <BotPic />
  </BotProfilePicContainer>
);

const HumanProfilePic = styled(HumanIcon)`
  height: 32px;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 50%;
`;

const InputArea = styled.div`
  position: relative;
  background-color: white;
  width: 800px;
  justify-self: center;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Montesserat", sans-serif;
  padding: 12px;
  resize: none;
  font-weight: 300;
  border-radius: 10px;
  height: 40px;
`;

const ActionContainer = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  opacity: 0;
  transition: opacity 0.4s;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 12px 40px;
  font-size: 16px;
  background-color: #0957d0;
  color: white;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 40px 62px;
  display: grid;
  gap: 20px;
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  justify-items: center;
  svg {
    height: 140px;
  }
`;

const PulsingDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const TopBar = styled.div`
  height: 50px;
  width: calc(100vw - 62px);
  background-color: #ffffff;
  position: absolute;
  top: 0;

  display: grid;
  align-items: center;
  justify-content: end;
  padding: 0 48px;
  grid-auto-flow: column;
  gap: 4px;
  pointer-events: none;
`;

const CELL_VISIBLE = {
  opacity: 1,
  border: "1px solid #00000055",
};

const CELL_INVISIBLE = {
  opacity: 0,
  border: "1px solid transparent",
};

const ROWS_LIMIT = 6;

const Table = ({
  columns = [],
  records = [],
  typeoutTime = 500,
  delay = 0,
  isDisabled = true,
}) => {
  const [rowColIndex, setRowColIndex] = useState(0);
  const [hasDelayPassed, setHasDelayPassed] = useState(false);

  const maxRowColIndex = records?.length * columns?.length;
  const msPerCell = typeoutTime / maxRowColIndex;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHasDelayPassed(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  useEffect(() => {
    if (rowColIndex >= maxRowColIndex || isDisabled || !hasDelayPassed) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setRowColIndex(prev => prev + 1);
    }, msPerCell);

    return () => clearTimeout(timeoutId);
  }, [rowColIndex, isDisabled, msPerCell, maxRowColIndex, hasDelayPassed]);

  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((col, colIndex) => {
            if (col?.name === "description") {
              return (
                <Th
                  style={rowColIndex > colIndex ? CELL_VISIBLE : CELL_INVISIBLE}
                >
                  Extraction request
                </Th>
              );
            }

            return (
              <Th
                style={rowColIndex > colIndex ? CELL_VISIBLE : CELL_INVISIBLE}
              >
                {col.label}
              </Th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {records?.slice(0, ROWS_LIMIT).map((record, rowIndex) => (
          <tr>
            {columns.map((col, colIndex) => (
              <Td
                style={
                  rowColIndex > colIndex + rowIndex * columns.length
                    ? CELL_VISIBLE
                    : CELL_INVISIBLE
                }
              >
                {record[col.name]}
              </Td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const AiBubbleContent = ({ children }) => {
  const [msSinceLoad, setMsSinceLoad] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMsSinceLoad(prev => prev + 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  });

  if (msSinceLoad < 2000) {
    return (
      <div>
        <PulsingDot />
      </div>
    );
  }

  return children;
};

const DelayedContent = ({ children, delay = 0 }) => {
  const [hasDelayPassed, setHasDelayPassed] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHasDelayPassed(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  if (!hasDelayPassed) {
    return null;
  }

  return children;
};

const TypingOutText = ({ text = "", typeoutTime = 500, isDisabled = true }) => {
  const [wordIndex, setWordIndex] = useState(0);

  const words = text.split(" ");
  const msPerWord = typeoutTime / words.length;

  useEffect(() => {
    if (wordIndex >= words.length || isDisabled) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setWordIndex(prev => prev + 1);
    }, msPerWord);

    return () => clearTimeout(timeoutId);
  }, [wordIndex, words?.length, isDisabled]);

  return words?.slice(0, wordIndex).join(" ");
};

const IntroPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const wordDocId = searchParams.get("wordDocId");

  const [dashConfig, setDashConfig] = useState(null);
  const [wordDoc, setWordDoc] = useState(null);
  const [bubbleIndex, setBubbleIndex] = useState(0);

  const [hasClickedGetStarted, setHasClickedGetStarted] = useState(false);

  const bubblesContainerRef = useRef(null);

  useEffect(() => {
    if (!wordDocId) {
      return;
    }
    doPopulateDashboardConfig();
    doPopulateWordDoc();
  }, [wordDocId]);

  const exampleInput = parseJson(wordDoc?.content?.exampleInput);
  const bubbles = (exampleInput?.chatBubbles || [])?.slice(0, bubbleIndex + 1);

  useEffect(() => {
    if (bubbleIndex >= bubbles.length) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setBubbleIndex(prev => prev + 1);
      bubblesContainerRef.current.scrollTo(0, 1000000);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [bubbleIndex, bubbles?.length]);

  useEffect(() => {
    if (hasClickedGetStarted) {
      const timeoutId = setTimeout(() => {
        navigate(
          `/trial-dashboards/${wordDocId}/source?shouldStartTutorial=true`
        );
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [hasClickedGetStarted]);

  const doPopulateDashboardConfig = async () => {
    const { data: dashConfig } = await getTemplateDashboardConfig({
      wordDocId,
    });
    setDashConfig(dashConfig);
  };

  const doPopulateWordDoc = async () => {
    const { data: newWordDoc } = await getWordDoc(wordDocId);
    setWordDoc(newWordDoc);
  };

  const records = dashConfig?.config?.components?.find(
    cmp => cmp?.id === "source_records"
  )?.columns;

  if (hasClickedGetStarted) {
    return (
      <StyledCenteredWithTopNavLayout
        style={{ marginTop: 0, paddingTop: 0, height: "100vh" }}
        isTopBarHidden
        isHeaderEnabled={false}
        isSidebarDisabled
      >
        <LogoAndIntroText style={{ opacity: 1 }}>
          <BoltzhubLogoInnerAnimated height="75px" />
          <IntroText>
            <Typewriter
              options={{
                delay: 20,
                pauseFor: 100000,
                strings: ["Building your solution..."],
                autoStart: true,
              }}
            />
          </IntroText>
        </LogoAndIntroText>
      </StyledCenteredWithTopNavLayout>
    );
  }

  return (
    <StyledCenteredWithTopNavLayout
      isTopBarHidden
      isHeaderEnabled={false}
      isSidebarDisabled
    >
      <TopBar>
        <SelectCustom>
          {MODEL_SELECT_OPTIONS?.[0]?.label} <KeyboardArrowDown />
        </SelectCustom>
        <div style={{ paddingRight: "8px" }}>
          <JobsTooltipTrigger />
        </div>
        <HumanProfilePic />
      </TopBar>
      <BubblesContainer
        ref={bubblesContainerRef}
        style={{
          alignContent: bubbleIndex === 0 ? "center" : "start",
        }}
      >
        <LogoAndIntroText style={{ opacity: bubbleIndex === 0 ? 1 : 0 }}>
          <BoltzhubLogoInner height="75px" />
          <IntroText>How can I help today?</IntroText>
        </LogoAndIntroText>

        {bubbles?.map((bubble, i) => {
          const prevBubble = bubbles?.[i - 1];
          const shouldShowProfileIcon = prevBubble?.author !== bubble.author;

          if (bubble.author === "action") {
            const { text } = bubble.items?.[0];
            return (
              <ActionContainer
                style={{
                  opacity: i < bubbleIndex ? 1 : 0,
                  height: i < bubbleIndex ? "auto" : 0,
                }}
              >
                <DelayedContent delay={2000}>
                  <StyledButtonDark
                    onClick={() => setHasClickedGetStarted(true)}
                  >
                    See it in action
                  </StyledButtonDark>
                </DelayedContent>
              </ActionContainer>
            );
          }

          return (
            <BubbleContainer
              style={{
                opacity: i < bubbleIndex ? 1 : 0,
                height: i < bubbleIndex ? "auto" : 0,
              }}
            >
              {bubble.author === "user" && (
                <Fragment key={i}>
                  {shouldShowProfileIcon ? <HumanProfilePic /> : <div />}
                  <Bubble isUser={bubble.author === "user"} key={i}>
                    {bubble?.items?.map((item, j) => {
                      if (item.columns) {
                        return null;
                      }

                      return (
                        <div key={j}>
                          <TypingOutText
                            key={j}
                            isDisabled={i >= bubbleIndex}
                            text={item.text}
                          />
                        </div>
                      );
                    })}
                  </Bubble>
                </Fragment>
              )}
              {bubble.author === "bot" && (
                <Fragment key={i}>
                  {shouldShowProfileIcon ? <BotProfilePic /> : <div />}
                  <AiBubbleContent setBubbleIndex={setBubbleIndex}>
                    <Bubble isUser={bubble.author === "user"} key={i}>
                      {bubble?.items?.map((item, j) => {
                        if (item.columns) {
                          return (
                            <Table
                              delay={j * 1000}
                              key={j}
                              columns={item.columns}
                              records={records}
                              isDisabled={i >= bubbleIndex}
                            />
                          );
                        }

                        return (
                          <div key={j}>
                            <TypingOutText
                              text={item.text}
                              isDisabled={i >= bubbleIndex}
                            />
                          </div>
                        );
                      })}
                    </Bubble>
                  </AiBubbleContent>
                </Fragment>
              )}
            </BubbleContainer>
          );
        })}
      </BubblesContainer>

      <InputArea>
        <StyledTextArea disabled placeholder="Send a message" />
        <SendIcon
          height="20px"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            opacity: 0.2,
          }}
        />
      </InputArea>
    </StyledCenteredWithTopNavLayout>
  );
};

export default IntroPage;
