import styled from "styled-components";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { CloudDownload } from "@material-ui/icons";

import { CenteredWithTopNavLayoutNoSideBar } from "components/Layout";
import {
  getExcelFilePageResults,
  patchFileById,
} from "api/services/filesService";
import { BigTitle } from "components/ui/Text";
import ExcelViewSheetViewOnly from "components/widgets/ExcelViewSheetViewOnly";
import FileRerunButtonsAndProgress from "components/RerunButtonsAndProgress";
import usePollFile from "api/services/usePollFile";
import { ALPHABET_EXTENDED } from "utils/excel-utils";
import AnnoCellExpandable from "components/AnnoCellExpandable";
import {
  deleteExcelAnnotation,
  getExcelAnnotationsByFileId,
  postExcelAnnotationForFile,
} from "api/services/ccService";
import AddCellLabelExpandableModalTrigger from "components/widgets/AddCellLabelExapndableModalTrigger";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import Tooltip from "components/ui/Tooltip";
import { CircularProgress } from "@material-ui/core";
import InputWithState from "components/InputWithState";
import FileTagFetcher from "components/FileTagFetcher";

const getHumanLineItemMapFromExcelAnnotations = (
  excelAnnotations = [],
  sheetIndex = 0
) => {
  const humanLineItemMap = {};
  excelAnnotations
    .filter(
      annotation => parseInt(annotation?.context?.sheetIndex) === sheetIndex
    )
    .forEach(annotation => {
      const rowColStr = `${annotation?.context?.rowIndex},${annotation?.context?.colIndex}`;
      humanLineItemMap[rowColStr] = annotation;
    });

  return humanLineItemMap;
};

const pythonNameToAnnotationName = {
  "Table Name": "tableName",
  "Row Name": "rowName",
  "Column Name": "colName",
  "Cell Type": "cellType",
};

const getInitalPartialAnnotationFromAutomaticLineItemMapItem =
  annotationString => {
    const annotation = {};

    const lines = annotationString?.split("\n") || [];
    lines.forEach(line => {
      const [name, value] = line.split(":");
      annotation[pythonNameToAnnotationName[name]] = value;

      if (name === "Cell Type") {
        annotation.cellType = value.trim() === "Header" ? "HEADER" : "DATA";
      }
    });

    return annotation;
  };

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: calc(100vh - 81px);
  align-items: start;
`;

const EditableFileName = styled(InputWithState)`
  font-size: 26px;
  font-weight: bold;
  line-height: 1.25;
`;

const SheetNamesContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.closer0};
  padding-left: 41px;
  border: 1px solid ${props => props.theme.color.closer1};
  align-items: center;
  z-index: 12;

  width: 100vw;
  overflow: auto;
`;

const SheetName = styled.div`
  padding: 15px;
  background-color: ${props =>
    props.isSelected ? props.theme.color.furthest : "transparent"};
  font-weight: 600;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  border-right: 0.5px solid ${props => props.theme.color.closer1};
  border-left: 0.5px solid ${props => props.theme.color.closer1};
  cursor: pointer;
  :hover {
    background-color: ${props =>
      !props.isSelected && props.theme.color.closer1};
  }
  white-space: nowrap;
`;

const ModelNameAndWebLink = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const Toolbar = styled.div`
  height: 40px;
  border-top: 1px solid ${props => props.theme.color.closer1_5};
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: end;
`;

const StyledAnnoCellExpandable = styled(AnnoCellExpandable)`
  padding: 0;
  width: 100%;
  height: 100%;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const BottomRightAlignedTooltip = styled(Tooltip)`
  right: 0;
  transform: translateX(calc(-100% + 15px)) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const ExcelFileOcrPage = () => {
  const { fileId } = useParams();

  const [file, setFile] = usePollFile(fileId);

  const [excelFilePageResults, setExcelFilePageResults] = useState(null);
  const [selectedSheetName, setSelectedSheetName] = useState(null);
  const [viewWindow, setViewWindow] = useState({
    startRow: 0,
    startCol: 0,
    endRow: 30,
    endCol: 30,
  });

  const [selectedCellLocation, setSelectedCellLocation] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [excelAnnotations, setExcelAnnotations] = useState([]);
  const [isPatchingFile, setIsPatchingFile] = useState(false);

  useEffect(() => {
    doPopulateExcelFile();
  }, [fileId]);

  useEffect(() => {
    doPopulateEntities();
  }, [selectedSheetName]);

  const doPopulateExcelFile = async () => {
    setIsLoading(true);
    const { data } = await getExcelFilePageResults(fileId);
    setExcelFilePageResults(data);
    setSelectedSheetName(data?.sheets[0]?.sheetName);
    setIsLoading(false);
  };

  const doPopulateEntities = async () => {
    const { data } = await getExcelAnnotationsByFileId(fileId);
    setExcelAnnotations(data || []);
  };

  const doPostExcelAnnotation = async annotation => {
    setIsLoading(true);
    const { data } = await postExcelAnnotationForFile(fileId, annotation);
    setExcelAnnotations(previousAnnos => [...previousAnnos, data]);
    setIsLoading(false);
  };

  const doDeleteExcelAnnotation = async annotationId => {
    setIsLoading(true);
    const { error } = await deleteExcelAnnotation(annotationId);
    if (error) {
      return;
    }
    setExcelAnnotations(previousAnnos =>
      previousAnnos.filter(anno => anno.id !== annotationId)
    );
    setIsLoading(false);
  };

  const doPatchFile = async body => {
    setIsPatchingFile(true);
    const { data } = await patchFileById(fileId, body);
    setFile(data);
    setIsPatchingFile(false);
  };

  const sheetNames = excelFilePageResults?.sheets?.map(
    sheet => sheet?.sheetName
  );
  const selectedSheet = excelFilePageResults?.sheets?.find(
    sheet => sheet?.sheetName === selectedSheetName
  );

  const selectedCellValue =
    selectedSheet?.cells?.[selectedCellLocation]?.["f-value"] ||
    selectedSheet?.cells?.[selectedCellLocation]?.value;

  const selectedRowIndex = selectedCellLocation?.match(/\d+/)?.[0] - 1;
  const selectedColumnIndex = ALPHABET_EXTENDED.indexOf(
    selectedCellLocation?.match(/[A-Z]+/)?.[0]
  );

  const humanLineItemMap = getHumanLineItemMapFromExcelAnnotations(
    excelAnnotations,
    selectedSheet?.index
  );
  const initialPartialAnnotation =
    getInitalPartialAnnotationFromAutomaticLineItemMapItem(
      selectedSheet?.automaticLineItemNameMap?.[
        `${selectedRowIndex},${selectedColumnIndex}`
      ]
    );

  return (
    <CenteredWithTopNavLayoutNoSideBar
      centerColumnMaxWidth="100%"
      contentTopPadding={0}
    >
      <Container>
        <ModelNameAndWebLink>
          {/* <ExcelModelName>{file?.fileName || "Loading..."}</ExcelModelName> */}
          <EditableFileName
            initialValue={file?.fileName}
            isDisabled={isPatchingFile}
            onApplyValue={newFileName => doPatchFile({ fileName: newFileName })}
          />
          <FileTagFetcher fileId={fileId} />
          {isLoading && <CircularProgress size={18} thickness={8} />}
        </ModelNameAndWebLink>
        <Toolbar>
          <BottomRightAlignedTooltip title="Download file">
            <IconButton
              onClick={() =>
                triggerDownloadOfFile(fileId, { fileType: "EXCEL_MODEL" })
              }
            >
              <CloudDownload />
            </IconButton>
          </BottomRightAlignedTooltip>
          <AddCellLabelExpandableModalTrigger
            isTooltipRightAligned
            isDisabled={!selectedCellLocation || isLoading}
            selectedValue={selectedCellValue}
            initialPartialAnnotation={initialPartialAnnotation}
            onPartialAnnotationAdded={partialAnnotation => {
              const newAnnotation = {
                fileId,
                context: {
                  rowIndex: selectedRowIndex,
                  colIndex: selectedColumnIndex,
                  sheetIndex: selectedSheet?.index,
                },
                cellText: selectedCellValue,
                ...partialAnnotation,
              };
              doPostExcelAnnotation(newAnnotation);
            }}
          />
          <FileRerunButtonsAndProgress
            progress={file?.progress}
            visibleButtons={["rerunfile"]}
          />
        </Toolbar>
        <ExcelViewSheetViewOnly
          cells={selectedSheet?.cells}
          grid={selectedSheet?.grid}
          viewWindow={viewWindow}
          onScrollViewWindow={newWindow => setViewWindow(newWindow)}
          renderSelectedCellContent={({ rowIndex, columnIndex }) => {
            return (
              <StyledAnnoCellExpandable
                onWheel={() => {
                  // necessary to allow scrolling while mouse is over selected cell
                  // tried deselecting cell on mouse wheel, but that caused unpleaant flash
                  document.querySelector(
                    `#selected-cell-div-${rowIndex}-${columnIndex}`
                  ).style.display = "none";
                }}
                annotations={[
                  selectedSheet?.automaticLineItemNameMap?.[
                    `${rowIndex},${columnIndex}`
                  ],
                  humanLineItemMap?.[`${rowIndex},${columnIndex}`],
                ]}
                onClickDelete={annotationId =>
                  doDeleteExcelAnnotation(annotationId)
                }
              />
            );
          }}
          selectedCellLocation={selectedCellLocation}
          onNewSelectedCellLocation={newLocation =>
            setSelectedCellLocation(newLocation)
          }
        />
        <SheetNamesContainer>
          {sheetNames?.map((sheetName, index) => (
            <SheetName
              key={index}
              onClick={() => setSelectedSheetName(sheetName)}
              isSelected={sheetName === selectedSheetName}
            >
              {sheetName}
            </SheetName>
          ))}
        </SheetNamesContainer>
      </Container>
    </CenteredWithTopNavLayoutNoSideBar>
  );
};

export default ExcelFileOcrPage;
