import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { Close } from "@material-ui/icons";
import { parseJson } from "utils/common";

const Cell = styled.div`
  position: relative;
  padding: 5px 10px;
  border: 0.5px solid ${props => props.theme.color.closer2};
  cursor: pointer;
  :hover {
    border-color: ${props => props.theme.color.primary};
  }
`;

const TooltipToShowOnHover = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 2px;
  z-index: 15;
  pointer-events: none;
  transform: translateY(100%);
`;

const AnnotationContainer = styled.div`
  background-color: ${props => props.theme.color.furthest};
  border: 1px solid ${props => (props.isGreen ? props.theme.color.data : props.theme.color.primary)}4D;
  color: ${props => props.theme.color.closest};
  display: grid;
  padding: 5px;
  grid-template-columns: 1fr auto auto;
  column-gap: 10px;
  row-gap: 5px;
  width: max-content;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

  font-size: 14px;
  user-select: none;
  font-weight: normal;
`;

const PropertyName = styled.div`
  grid-column: 1;
  font-weight: 600;
  white-space: nowrap;
`;

const PropertyValue = styled.div`
  grid-column: 2;
`;

const SummaryAndSeeMoreContainer = styled.div`
  display: grid;
  gap: 5px;
  padding-bottom: 5px;
  grid-column: span 2;
`;

const SeeMoreLink = styled.div`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  color: ${props => props.theme.color.primary};
`;

const CloseButtonContainer = styled.div`
  padding: ${props => (props.isVisible ? "2px" : "0px")};
  height: 20px;
  width: 20px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  transform: translate(5px, -5px);

  svg {
    height: 18px;
  }
`;

const getReshapedAnnotation = annotation => {
  if (typeof annotation === "string") {
    const labelProperties = [];

    const lines = annotation?.split("\n");
    lines.forEach(line => {
      const [name, value] = line.split(":");
      labelProperties.push({ name, value });
    });

    annotation = { labelProperties: JSON.stringify(labelProperties) };
  }

  return annotation;
};

const AnnotationBoxAutomatic = ({ annotation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  annotation = getReshapedAnnotation(annotation);
  const labelPropertiesJson = parseJson(annotation?.labelProperties);

  const rowNameProperty = labelPropertiesJson?.find(p => p?.name === "Row Name");
  const colNameProperty = labelPropertiesJson?.find(p => p?.name === "Column Name");

  const summary = `${rowNameProperty?.value || ""} - ${colNameProperty?.value || ""}`;

  return (
    <AnnotationContainer>
      <SummaryAndSeeMoreContainer>
        {summary}
        <SeeMoreLink
          onClick={e => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? "See Less" : "See More"}
        </SeeMoreLink>
      </SummaryAndSeeMoreContainer>
      {isExpanded &&
        labelPropertiesJson?.map((propertyNameAndValue, j) => (
          <Fragment key={j}>
            <PropertyName>{propertyNameAndValue?.name}:</PropertyName>
            <PropertyValue>{propertyNameAndValue?.value}</PropertyValue>
          </Fragment>
        ))}
    </AnnotationContainer>
  );
};

const SUMMARY_FIELDS = ["label", "cellType"];
const EXPANDED_FIELDS = ["tableName", "rowName", "colName"];

const FIELD_NAME_TO_DISPLAY_NAME = {
  label: "Label",
  cellType: "Cell Type",
  tableName: "Table Name",
  rowName: "Row Name",
  colName: "Column Name",
};

const AnnotationBoxHuman = ({ annotation = {}, onClickDelete = id => {} }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <AnnotationContainer isGreen>
      <CloseButtonContainer onClick={() => onClickDelete(annotation?.id)}>
        <Close />
      </CloseButtonContainer>
      <SummaryAndSeeMoreContainer>
        {SUMMARY_FIELDS?.map(fieldName => (
          <Fragment key={fieldName}>
            <PropertyName>{FIELD_NAME_TO_DISPLAY_NAME[fieldName]}:</PropertyName>
            <PropertyValue>{annotation?.[fieldName]}</PropertyValue>
          </Fragment>
        ))}
        <SeeMoreLink
          onClick={e => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? "See Less" : "See More"}
        </SeeMoreLink>
      </SummaryAndSeeMoreContainer>
      {isExpanded &&
        EXPANDED_FIELDS?.map(fieldName => (
          <Fragment key={fieldName}>
            <PropertyName>{FIELD_NAME_TO_DISPLAY_NAME[fieldName]}:</PropertyName>
            <PropertyValue>{annotation?.[fieldName]}</PropertyValue>
          </Fragment>
        ))}
    </AnnotationContainer>
  );
};

const AnnoCellExpandable = ({
  annotations = [{ labelProperties: `[{ "name": "Property Name", "value": "Value" }]`, entityId: "" }],
  children,
  className,
  onClick = () => {},
  onClickDelete = () => {},
  onWheel = () => {},
}) => {
  const [isMouseOverSpan, setIsMouseOverSpan] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (isMouseOverSpan) {
      setIsTooltipVisible(true);
    }

    if (!isMouseOverSpan && !isMouseOverTooltip) {
      setIsTooltipVisible(false);
    }
  }, [isMouseOverSpan, isMouseOverTooltip]);

  return (
    <Cell
      onWheel={onWheel}
      onClick={onClick}
      className={className}
      onMouseEnter={() => setIsMouseOverSpan(true)}
      onMouseLeave={() => setIsMouseOverSpan(false)}
    >
      {children}
      <TooltipToShowOnHover
        style={{
          zIndex: isTooltipVisible ? 15 : 0,
          pointerEvents: isTooltipVisible ? "all" : "none",
          display: isTooltipVisible ? "grid" : "none",
        }}
        onMouseEnter={() => setIsMouseOverTooltip(true)}
        onMouseLeave={() => setIsMouseOverTooltip(false)}
      >
        {annotations
          ?.filter(a => !!a)
          ?.map((annotation, i) => {
            if (annotation?.id) {
              return <AnnotationBoxHuman key={i} annotation={annotation} onClickDelete={onClickDelete} />;
            }
            return <AnnotationBoxAutomatic key={i} annotation={annotation} />;
          })}
      </TooltipToShowOnHover>
    </Cell>
  );
};

export default AnnoCellExpandable;
