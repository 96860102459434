import { useState, useEffect } from "react";
import styled from "styled-components";
import { getPreDefinedLabelSets, getActivatedLabelSetId, setAcitvatedLabelSetId } from "api/services/filesService";
import UserLabelsPanel from "./UserLabelsPanel";
import { isEmpty } from "lodash";


const PanelGrid = styled.div`
  width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px 35px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
`;


const AnnotationsConfig = ({ showShareIcon = true, onUpdate = () => {} }) => {
  const [userLabelsList, setUserLabelsList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [localAcitvatedLabelSetId, setLocalAcitvatedLabelSetId] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchPredefinedLabels();
    fetchActivatedLabelSetId();
  }, []);

  
  
  const fetchPredefinedLabels = async () => {
    setIsLoading(true);
    const { data, error } = await getPreDefinedLabelSets();
    setIsLoading(false);
    if (!error) {
      setUserLabelsList(data);
    }
  };

  const fetchActivatedLabelSetId = async () => {
    const { data, error } = await getActivatedLabelSetId();
    if (!error) {
      setLocalAcitvatedLabelSetId(data.activatedLabelSetId);
    }
  };


  const doHandelActivate = async (setId) => {
    const { data, error } = await setAcitvatedLabelSetId(setId);
    await fetchActivatedLabelSetId();
  };


  return (
    <Container>
    <h1>Annotation Label Sets</h1>
    {message}
    <PanelGrid>
      <UserLabelsPanel
        userLabels={null} 
        onClickBtn={() => {fetchPredefinedLabels(); onUpdate();}}
        isActivated={false}
      />
      {!isEmpty(userLabelsList) && 
        userLabelsList.map(userLabels => 
          <UserLabelsPanel 
            userLabels={userLabels} 
            onClickBtn={() => {fetchPredefinedLabels(); onUpdate();} } 
            showShareIcon={showShareIcon}
            isActivated={localAcitvatedLabelSetId === userLabels.id} 
            onActivate={setId => {doHandelActivate(setId); onUpdate(); setMessage(`You activated the "${userLabels.group}" labelset.`)}} 
          /> 
      )}
    </PanelGrid>

  </Container>);
}

export default AnnotationsConfig;