import styled from "styled-components";
import { useState } from "react";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import SmallButton from "components/ui/SmallButton";
import TextArea from "components/ui/TextArea";
import { postSeedLink } from "api/services/datacrawlerService";

const ModalContent = styled.div`
  padding: 15px;
  display: grid;
  gap: 10px;
  flex-direction: column;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  width: 800px;
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 5px 40px;
  margin-top: 20px;
  justify-self: start;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const BoldDiv = styled.div`
  font-weight: bold;
`;

const AddSeedLinkModalTrigger = ({ onSuccess = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [maxLinks, setMaxLinks] = useState(1000);

  const doPostSeedLink = async () => {
    setIsLoading(true);
    const { error } = await postSeedLink({ url, maxLinks });
    setError(error);
    setIsLoading(false);

    if (!error) {
      onSuccess();
    }
  };

  return (
    <>
      <ButtonCreateNewAsset onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Title>Add Seed Link</Title>
          <BoldDiv>URL</BoldDiv>
          <TextArea value={url} onNewInput={newUrl => setUrl(newUrl)} />
          <BoldDiv>Max links</BoldDiv>
          <input
            type="number"
            value={maxLinks}
            onChange={e => setMaxLinks(e.target.value)}
          />
          <StyledSmallButton
            isDisabled={!url}
            onClick={doPostSeedLink}
            value="Add and start crawl"
          />
          {error && <div>{JSON.stringify(error)}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddSeedLinkModalTrigger;
