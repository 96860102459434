import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { chunk, cloneDeep, flatMap, groupBy, last, set, sum } from "lodash";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { getSigmas, patchSigma } from "api/services/chatService";
import DashboardConfigVisualiser from "components/DashboardConfigVisualiser";
import styled from "styled-components";
import SmallButton from "components/ui/SmallButton";
import {
  getWordDoc,
  patchWordDoc,
  postWordDoc,
} from "api/services/wordDocsService";
import { DownloadIcon, PencilIcon, WordIcon } from "components/ui/Icons";
import DummyTable from "components/DummyTable";
import { postReRunJobs } from "api/services/jobService";
import { FileCopy, Refresh } from "@material-ui/icons";
import Tooltip from "components/ui/Tooltip";
import { uuidv4 } from "utils/common";
import AutocompleteTextInputDropdown from "components/ui/AutocompleteTextInputDropdown";

const INITIAL_CONFIG = {
  _grid: [["all_records"], ["loan_amounts"]],
  all_records: {
    name: "Download Records",
    type: "table",
    cellLocationToMeta: {},
    columnNames: ["emailId", "receivedAt", "Loan Amount", "City"],
    columnNameToDescription: {},
    rows: [],
  },

  // loan_amounts: {
  //   command: "sum Loan Amount, group by City",
  //   name: "Loan Amounts",
  //   type: "table",

  //   columnNames: [],
  //   rows: [],
  // },

  // year_built_vs_number_of_units: {
  //   name: "Year Built vs # Unit",
  //   type: "plot",
  //   xLabel: "year",
  //   yLabel: "units",
  //   points: [],
  // },
  // total_predictions: {
  //   name: "Total Predictions",
  //   type: "single_value",
  //   value: 0,
  // },
};

const NON_DATA_COLUMNS = [
  "_id",
  "pipelineName",
  "templateId",
  "sharedWith",
  "createdBy",
  "_columnNameToMeta",
  "status",
];

/*
    "Loan Amount": {
      "rawValue": "5300000",
      "value": 53000000,
      "type": "NUMERIC",
      "unit": "USD",
      "meta": "",
      "checked": false
    }
*/

const placeNewComponentInGrid = (grid, newComponentId) => {
  let allSummaryComponents = [];
  if (grid?.length > 1) {
    allSummaryComponents = grid
      .filter(row => !row?.every(cName => cName === "all_records"))
      .flat()
      .filter(cName => cName !== ".");
  }

  allSummaryComponents = [newComponentId, ...allSummaryComponents];
  let allSummaryRows = chunk(allSummaryComponents, 3);
  allSummaryRows = allSummaryRows.map(row => {
    if (row?.length === 1) {
      return [row[0], ".", "."];
    }

    if (row?.length === 2) {
      return [row[0], row[1], "."];
    }

    return row;
  });

  // allSummaryRows = flatMap(allSummaryRows, (row, rowIndex) => {
  //   const mapCellIndex = row.findIndex(cName => cName?.includes("map_"));
  //   const nextRow = allSummaryRows?.[rowIndex + 1];
  //   const doesMapHaveTwoRows = nextRow?.[mapCellIndex]?.includes("map_");
  //   if (mapCellIndex === -1 || doesMapHaveTwoRows) {
  //     return [row];
  //   }

  //   let additionalRow = row?.map(cName =>
  //     cName?.includes("map_") ? cName : "."
  //   );

  //   return [row, additionalRow];
  // });

  return [...allSummaryRows, ["all_records", "all_records", "all_records"]];
};

const getStringFromValueJson = valueJson => {
  return valueJson?.value || valueJson?.rawValue || "";
};

const getFilledConfigFromSigmas = (config, sigmas = []) => {
  if (sigmas?.length === 0) {
    return config;
  }

  // all records
  const columnNames = config?.all_records?.columnNames;
  const newConfig = cloneDeep(config);
  newConfig.all_records.rows = [
    columnNames,
    ...sigmas.map(sigma =>
      columnNames.map(columnName => {
        if (columnName === "emailId") {
          return sigma?.emailSubject || sigma?.emailId;
        }

        if (columnName === "receivedAt") {
          return sigma?.receivedAt;
        }

        return getStringFromValueJson(sigma?.[columnName]);
      })
    ),
  ];

  const cellLocationToMeta = {};
  const cellLocationToSigmaId = {};
  const rowIndexToStatus = {};
  sigmas?.forEach((sigma, sigmaIndex) => {
    const rowIndex = sigmaIndex + 1;
    columnNames?.forEach((columnName, columnIndex) => {
      cellLocationToMeta[`${rowIndex},${columnIndex}`] = {
        refText: sigma?.[columnName]?.refText,
        checked: sigma?.[columnName]?.checked,
        ...sigma?._columnNameToMeta?.[columnName],
        emailId: sigma?.emailId,
        receivedAt: sigma?.receivedAt,
      };

      cellLocationToSigmaId[`${rowIndex},${columnIndex}`] = sigma?._id;
    });

    if (sigma?.status === "IN_PROGRESS") {
      rowIndexToStatus[rowIndex] = "IN_PROGRESS";
    }
  });
  newConfig.all_records.cellLocationToMeta = cellLocationToMeta;
  newConfig.all_records.cellLocationToSigmaId = cellLocationToSigmaId;
  newConfig.all_records.rowIndexToStatus = rowIndexToStatus;

  // summary tables
  Object.keys(newConfig).forEach(componentId => {
    if (["all_records", "_grid"]?.includes(componentId)) {
      return;
    }

    if (newConfig[componentId]?.type !== "table") {
      return;
    }

    const simpleSigmas = sigmas.map(sigma => {
      const simpleSigma = {};
      Object.keys(sigma).forEach(columnName => {
        if (sigma[columnName]?.rawValue) {
          simpleSigma[columnName] =
            parseFloat(sigma[columnName]?.rawValue) ||
            sigma?.[columnName]?.value ||
            sigma?.[columnName]?.rawValue;
          return;
        }

        simpleSigma[columnName] = sigma?.[columnName];
      });

      return simpleSigma;
    });

    const subCommands = newConfig[componentId]?.command
      ?.split(",")
      ?.map(s => s?.trim());

    const groupByCommand = subCommands?.find(s => s?.includes("group by"));
    const groupByColumnName = groupByCommand?.replace("group by ", "");
    const groupedSigmas = groupBy(simpleSigmas, groupByColumnName);

    const aggCommands = subCommands?.filter(
      s => s?.includes("sum") || s?.includes("count")
    );

    // let aggFunction = "count";
    // if (aggCommand?.includes("sum")) {
    //   aggFunction = "sum";
    // }

    // const aggColumnName = subCommands?.[0]?.replace(`${aggFunction} `, "");

    const aggFunctions = [];
    const aggColumnNames = aggCommands?.map(aggCommand => {
      let aggFunction = "count";
      if (aggCommand?.includes("sum")) {
        aggFunction = "sum";
      }
      aggFunctions.push(aggFunction);

      return aggCommand?.replace(`${aggFunction} `, "");
    });

    const rows = [[groupByColumnName, ...aggColumnNames]];
    Object.keys(groupedSigmas).forEach(groupName => {
      if (groupName === "undefined" || groupName === "[object Object]") {
        return;
      }

      const row = [groupName];
      aggColumnNames.forEach((aggColumnName, i) => {
        const valuesToAggregate = groupedSigmas[groupName].map(
          sigma => parseFloat(sigma[aggColumnName]) || 0
        );
        const aggFunction = aggFunctions[i];

        let aggValue = 0;
        if (aggFunction === "sum") {
          aggValue = sum(valuesToAggregate);
        }

        if (aggFunction === "count") {
          aggValue = valuesToAggregate?.length;
        }

        row.push([aggValue]);
      });

      rows.push(row);
    });
    newConfig[componentId].rows = rows;

    newConfig[componentId].isMapView =
      newConfig[componentId]?.command?.includes("show on map");
  });

  return newConfig;
};

const TopContent = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  justify-items: start;
  align-items: center;
  gap: 14px;
`;

const BlueSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  grid-column: span 2;

  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    cursor: pointer;
    height: 14px;
    fill: ${props => props.theme.color.closer2};

    :hover {
      opacity: 0.8;
    }
  }
`;

const StyledSmallButton = styled(SmallButton)`
  justify-self: end;
`;

const IconContainer = styled.div`
  padding: 2px;
  border-radius: 0;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  justify-self: end;
  cursor: pointer;
`;

const ScanningContainer = styled.div`
  /* margin-top: 20px;
  display: flex;
  align-items: center; */
  /* gap: 16px; */
  font-weight: 600;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const TopMessageAndButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  gap: 8px;
  justify-content: start;
  grid-column: span 4;
  align-items: center;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  justify-self: end;
`;

const CommandInput = styled.input`
  padding: 4px 8px;
  border: 2px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
  font-family: "Montserrat";
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const AddPlotBtn = styled(SmallButton)`
  display: inline-block;
  border-radius: 0;
  border: 2px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  padding: 4px 8px;

  height: 26px;
  margin-left: 8px;
`;

const PulsingDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};

  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      background-color: ${props => props.theme.color.primary};
    }

    50% {
      background-color: ${props => props.theme.color.primary}55;
    }

    100% {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;

const TemplateDahsboardPage = () => {
  const { templateFileId } = useParams();
  const navigate = useNavigate();

  const [filledConfig, setFilledConfig] = useState(INITIAL_CONFIG);
  const [wordDocTemplate, setWordDocTemplate] = useState(null);
  const [isPatching, setIsPatching] = useState(false);

  const [isFetchingRecords, setIsFetchingRecords] = useState(true);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [isReRunningAll, setIsReRunningAll] = useState(false);

  const [commandStr, setCommandStr] = useState("");

  useEffect(() => {
    doPopulateWordDocTemplate();
  }, [templateFileId]);

  useEffect(() => {
    doPopulateSigmas(
      wordDocTemplate?.content?.dashboardConfig || INITIAL_CONFIG
    );

    const intervalId = setInterval(() => {
      doPopulateSigmas(
        wordDocTemplate?.content?.dashboardConfig || INITIAL_CONFIG
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [wordDocTemplate?.content?.dashboardConfig]);

  const doPopulateSigmas = async schemaConfig => {
    const { data } = await getSigmas({ templateId: templateFileId });
    setSigmaRecords(data);
    const newConfig = getFilledConfigFromSigmas(schemaConfig, data);
    setFilledConfig(newConfig);
    setIsFetchingRecords(false);
  };

  const doPopulateWordDocTemplate = async () => {
    const { data } = await getWordDoc(templateFileId);
    setWordDocTemplate(data);
  };

  const doPatchWordDocTemplate = async (body = {}) => {
    setIsPatching(true);
    const newWordDoc = {
      ...wordDocTemplate,
      ...body,
      content: {
        ...wordDocTemplate?.content,
        ...body?.content,
      },
    };
    const { data } = await patchWordDoc(templateFileId, {}, newWordDoc);
    setWordDocTemplate(data);
    setIsPatching(false);
  };

  const doPatchSigmaRecord = async (id, body, newColumnNameToMeta = null) => {
    let patchBody = body;
    if (newColumnNameToMeta) {
      const sigmaRecord = sigmaRecords?.find(
        sigmaRecord => sigmaRecord?._id === id
      );

      const patchColumnNameToMeta = cloneDeep(sigmaRecord?._columnNameToMeta);
      const columnName = Object.keys(newColumnNameToMeta)?.[0];
      patchColumnNameToMeta[columnName] = {
        ...sigmaRecord?._columnNameToMeta?.[columnName],
        ...newColumnNameToMeta?.[columnName],
      };

      patchBody = {
        ...body,
        _columnNameToMeta: patchColumnNameToMeta,
      };
    }

    await patchSigma(id, {}, patchBody);
    await doPopulateSigmas(wordDocTemplate?.content?.dashboardConfig);
  };

  const doPostWordDocWithTemplateSource = async () => {
    const { data, error } = await postWordDoc(
      { directoryPath: "/working-files" },
      {
        fileName: `Flow doc of ${wordDocTemplate?.fileName?.replace(
          "template_",
          ""
        )}`,
        content: {
          blocks: [{ text: "" }],
          sources: [
            {
              fileId: templateFileId,
              type: "SIGMA_TEMPLATE",
              fileName: wordDocTemplate?.fileName?.replace("template_", ""),
            },
          ],
        },
      }
    );
    if (!error) {
      window.open(`/word-docs/${data?.id}`, "_blank").focus();
    }
  };

  const doReRunAllFiles = async () => {
    setIsReRunningAll(true);

    await postReRunJobs(
      {},
      {
        ids: sigmaRecords?.map(sigma => sigma?.emailId),
      }
    );
    await doPopulateSigmas(wordDocTemplate?.content?.dashboardConfig);

    setIsReRunningAll(false);
  };

  const doRefreshSigmaRecord = async id => {
    const emailId = sigmaRecords?.find(sigma => sigma?._id === id)?.emailId;

    await postReRunJobs(
      {},
      {
        ids: [emailId],
      }
    );
    await doPatchSigmaRecord(id, { isDeleted: true });
    await doPopulateSigmas(wordDocTemplate?.content?.dashboardConfig);
  };

  const areThereAnyRecords = filledConfig?.all_records?.rows?.[0]?.length > 0;

  if (isFetchingRecords) {
    return (
      <CenteredWithTopNavLayout isDisabled={isPatching}>
        <TopContent>
          <Title>{wordDocTemplate?.fileName?.replace("template_", "")}</Title>
          <IconContainer onClick={doPostWordDocWithTemplateSource}>
            <WordIcon />
          </IconContainer>
        </TopContent>
        <Gap />
        Loading...
      </CenteredWithTopNavLayout>
    );
  }

  return (
    <CenteredWithTopNavLayout isDisabled={isPatching}>
      <TopContent>
        <Title>
          <PulsingDot />
          {wordDocTemplate?.fileName?.replace("template_", "")}
          <FileCopy
            onClick={() => {
              navigator.clipboard.writeText(
                `Pipeline: ${wordDocTemplate?.fileName?.replace(
                  "template_",
                  ""
                )}`
              );
            }}
          />
        </Title>
        <div />
        <IconContainer onClick={doPostWordDocWithTemplateSource}>
          <WordIcon />
        </IconContainer>
        <IconContainer
          onClick={() => navigate(`/template-files/${templateFileId}`)}
        >
          <PencilIcon />
        </IconContainer>
        {areThereAnyRecords && (
          <TopMessageAndButton>
            <div>
              Send emails to&nbsp;
              <BlueSpan>
                {window?.location?.host?.includes("boltztest")
                  ? "data@boltztest.com"
                  : "data@boltzbit.io"}
              </BlueSpan>
              &nbsp;to ingest records
            </div>
            <IconButton onClick={doReRunAllFiles}>
              <Tooltip title="Re-run all files">
                <Refresh />
              </Tooltip>
            </IconButton>
          </TopMessageAndButton>
        )}
      </TopContent>
      <Gap />
      {/* <CommandInput
        disabled={isPatching}
        value={commandStr}
        onChange={e => setCommandStr(e.target.value)}
        style={{ width: "600px" }}
        placeholder="sum Loan Amount, group by State, show on map"
      /> */}
      <AutocompleteTextInputDropdown
        isDisabled={isPatching}
        style={{
          height: "27px",
          width: "600px",
          display: "inline-block",
        }}
        placeholder="sum Loan Amount, group by State, show on map"
        options={[
          "sum Loan Amount, group by State, show on map",
          "sum # Units, group by State",
          "count, group by State",
        ]}
        value={commandStr}
        onChangeValue={newVal => setCommandStr(newVal)}
        onKeyDownAfterSelection={async e => {
          if (e.key !== "Enter") {
            return;
          }

          const componentId = `${
            commandStr?.includes("show on map") ? "map_" : ""
          }${uuidv4()}`;

          const numberOfComponents =
            wordDocTemplate?.content?.dashboardConfig?._grid
              ?.flat()
              ?.filter(c => c !== ".").length || 0;

          const newSchemaConfig = {
            ...wordDocTemplate?.content?.dashboardConfig,
            _grid: placeNewComponentInGrid(
              wordDocTemplate?.content?.dashboardConfig?._grid,
              componentId
            ),
            [componentId]: {
              id: componentId,
              name: `Summary Table ${numberOfComponents + 1}`,
              type: "table",
              rows: [],
              command: commandStr,
            },
          };

          await doPatchWordDocTemplate({
            ...wordDocTemplate,
            content: {
              ...wordDocTemplate?.content,
              dashboardConfig: newSchemaConfig,
            },
          });

          setCommandStr("");
        }}
      />
      <Gap />
      {areThereAnyRecords && (
        <DashboardConfigVisualiser
          config={filledConfig}
          blocks={wordDocTemplate?.content?.blocks}
          schemaConfig={
            wordDocTemplate?.content?.dashboardConfig || INITIAL_CONFIG
          }
          onChangeSchemaConfig={async (newConfig, newBlocks) => {
            await doPatchWordDocTemplate({
              ...wordDocTemplate,
              content: {
                ...wordDocTemplate?.content,
                blocks: newBlocks,
                dashboardConfig: newConfig,
              },
            });
          }}
          sigmaRecords={sigmaRecords}
          onPatchSigmaRecord={(id, body, newColumnNameToMeta) =>
            doPatchSigmaRecord(id, body, newColumnNameToMeta)
          }
          doRefreshSigmaRecord={id => doRefreshSigmaRecord(id)}
        />
      )}
      {!areThereAnyRecords && <DummyTable />}
      <Gap height="40px" />
    </CenteredWithTopNavLayout>
  );
};

export default TemplateDahsboardPage;
