import styled from "styled-components";

import Modal from "components/ui/Modal";
import SmallButton from "components/ui/SmallButton";
import { useState } from "react";
import { Notes } from "@material-ui/icons";
import TutorialOverlayModal, {
  useIsOverlayVisible,
} from "components/widgets/TutorialOverlayModal";
import { TutorialMessage } from "components/widgets/TutorialOverlay";

const ModalContent = styled.div`
  padding: 10px;
  ${props => props.isDisabled && "opacity: 0.6; pointer-events: none;"}
`;

const INITIAL_TEXT = `City is London

And Loan Request is £7,899,848`;

const StyledTextArea = styled.textarea`
  resize: none;
  padding: 4px;
  width: 400px;
  height: 200px;
  background-color: ${props => props.theme.color.furthest};
  color: ${props => props.theme.color.closest};
  border: 2px solid ${props => props.theme.color.closer1_5};
  font-family: "Montserrat";
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const StyledInput = styled.input`
  resize: none;
  padding: 4px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  border: 2px solid ${props => props.theme.color.closer1_5};
  font-family: "Montserrat";
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const StyledSmallButton = styled(SmallButton)`
  background-color: #f2f1f1;
`;

const BottomSmallButton = styled(SmallButton)`
  margin-top: 8px;
  padding: 4px;
  background-color: ${props => props.theme.color.furthest};
`;

const StyledTutorialMessage = styled(TutorialMessage)`
  position: fixed;
`;

const AddTextFileModal = ({ onClickDone = textFile => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState(INITIAL_TEXT);
  const [isDisabled, setIsDisabled] = useState(false);
  const [sourceName, setSourceName] = useState("Loan Request 1");

  return (
    <>
      <StyledSmallButton onClick={() => setIsOpen(true)} icon={<Notes />} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <StyledTutorialMessage
          style={{ display: "none" }}
          id="modal-tutorial-message"
        />

        <ModalContent isDisabled={isDisabled}>
          <div
            style={{
              fontWeight: 600,
              paddingBottom: "8px",
            }}
          >
            Source name
          </div>
          <StyledInput
            placeholder="my letter"
            value={sourceName}
            onChange={e => setSourceName(e.target.value)}
          />
          <div
            style={{
              fontWeight: 600,
              padding: "8px 0",
            }}
          >
            Content
          </div>
          <StyledTextArea
            data-tutorial-id="input-modal"
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <BottomSmallButton
            dataTutorialId="btn-parse-and-close-modal"
            value="Parse & add record"
            onClick={async () => {
              setIsDisabled(true);

              const file = new File([text], `${sourceName || "text"}.txt`, {
                type: "text/plain",
              });

              await onClickDone(file);

              setIsDisabled(false);
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddTextFileModal;
