import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import { getDirectoryItems } from "api/services/fileSystem";
import {
  ChatIcon,
  CrossIcon,
  ExcelIcon,
  FolderIcon,
  InternetIcon,
  PdfIcon,
  PencilIcon,
  SigmaIcon,
  SlidesIcon,
  TickIcon,
  WordIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import SearchableSelectInput from "components/ui/SearchableSelectInput";
import { last, sum } from "lodash";
import { Gap } from "components/Layout";
import SmallButton from "components/ui/SmallButton";
import ProgressBar from "components/ui/ProgressBar";
import { formatLongNumber, sleep } from "utils/common";
import { postAiAgentGenerate } from "api/services/chatService";

const MAX_TOKENS = 5 * 10 ** 6;

const HighlightSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
`;

const ModalTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  min-height: 600px;
  min-width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
`;

const SourceContainer = styled.div`
  padding: 4px;
  background-color: ${props => props.theme.color.closer0};
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  :hover {
    background-color: ${props => props.theme.color.furthest};
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  :hover {
    opacity: 0.5;
  }
`;

const getItemIconFromItem = (fileName, type = "", isItemInSources) => {
  if (isItemInSources) {
    return <TickIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".pdf")) {
    return <PdfIcon height="12px" style={{ opacity: 0.8, fill: "#ed1c24" }} />;
  }
  if (type === "WEB_PAGE") {
    return <InternetIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (type === "SIGMA_TEMPLATE") {
    return <SigmaIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".docx")) {
    return <WordIcon height="12px" style={{ opacity: 0.8, fill: "#175bbc" }} />;
  }
  if (fileName?.endsWith(".xlsx")) {
    return (
      <ExcelIcon height="12px" style={{ opacity: 0.8, fill: "#127e45" }} />
    );
  }
  if (type === "DIRECTORY") {
    return <FolderIcon height="10px" />;
  }
  return null;
};

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const TextInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  outline: none;
  padding: 10px;
  font-family: "Montserrat";
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const TextArea = styled.textarea`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  outline: none;
  padding: 10px;
  font-family: "Montserrat";
  resize: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledSmallButton = styled(SmallButton)`
  justify-self: end;
  padding: 4px 20px;
`;

const IconContainer = styled.div`
  border: 2px solid
    ${props =>
      props.isSelected ? props.theme.color.primary : props.theme.color.closer1};
  padding: 4px 8px;
  display: grid;
  align-content: center;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const DOC_TYPES = [
  "WORD_DOC",
  "SLIDE_DOC",
  "EXCEL_MODEL",
  "DATABASE",
  "CHAT_BOT",
];

const SelectDocType = ({ value, onChange = newValue => {} }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <IconContainer
        isSelected={value === "WORD_DOC"}
        onClick={() => onChange("WORD_DOC")}
      >
        <WordIcon height="22px" />
      </IconContainer>
      <IconContainer
        isSelected={value === "SLIDE_DOC"}
        onClick={() => onChange("SLIDE_DOC")}
      >
        <SlidesIcon style={{ fill: "#d5c91d", height: "22px" }} />
      </IconContainer>
      <IconContainer
        isSelected={value === "EXCEL_MODEL"}
        onClick={() => onChange("EXCEL_MODEL")}
      >
        <ExcelIcon />
      </IconContainer>
      <IconContainer
        isSelected={value === "DATABASE"}
        onClick={() => onChange("DATABASE")}
      >
        <SigmaIcon />
      </IconContainer>
      <IconContainer
        isSelected={value === "CHAT_BOT"}
        onClick={() => onChange("CHAT_BOT")}
      >
        <ChatIcon />
      </IconContainer>
    </div>
  );
};

const PROMPT_PLACEHOLDER = "create a power point of AI in medicine";

const NewDocConfigModalTrigger = ({
  isInitiallyOpen = false,
  isGreyedOut = false,
  triggerIcon = <button>open</button>,
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [allItems, setAllItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [prompt, setPrompt] = useState("");
  const [sources, setSources] = useState([]);
  const [docType, setDocType] = useState(DOC_TYPES[0]);
  const [isCreating, setIsCreating] = useState(false);

  let directoryPath = searchValue?.split("/")?.slice(0, -1)?.join("/");
  directoryPath = "/source-files" + directoryPath;

  const numbersOfPages = sources?.map(source => {
    const sourceItem = allItems?.find(item => item?.id === source?.fileId);
    return sourceItem?.numberOfPages;
  });
  const totalNumberOfPages = sum(numbersOfPages);
  const totalNumberOfTokens = totalNumberOfPages * 999;

  useEffect(() => {
    if (isOpen) {
      setPrompt("");
      setSources([]);
      setDocType(DOC_TYPES[0]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isInitiallyOpen) {
      setIsOpen(true);
    }
  }, [isInitiallyOpen]);

  useEffect(() => {
    doPopulateFileOptions();
  }, [directoryPath]);

  const doCreateDoc = async () => {
    setIsCreating(true);

    const { data, error } = await postAiAgentGenerate({ docType }, { prompt });

    setIsOpen(false);
    setIsCreating(false);
    window.location.href = "/working-files";
  };

  const doPopulateFileOptions = async () => {
    setAllItems([]);
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setAllItems(data?.items);
  };

  const selectOptions = allItems?.map(item => {
    const isItemInSources = sources.find(s => s.fileId === item.id);

    return {
      value: item?.id,
      label: item?.fileName || last(item?.path?.split("/")),
      icon: getItemIconFromItem(item?.fileName, item?.type, isItemInSources),
      type: item?.type,
      path: item?.path?.replace("/source-files", ""),
      isDisabled: isItemInSources,
    };
  });

  return (
    <>
      <ModalTrigger onClick={() => setIsOpen(true)}>{triggerIcon}</ModalTrigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isGreyedOut || isCreating}>
          <ModalTitle>
            <div>Create document</div>
          </ModalTitle>
          <Gap height="5px" />
          <Title>Prompt</Title>
          <TextArea
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Tab") {
                e.preventDefault();
                setPrompt(PROMPT_PLACEHOLDER);
              }
            }}
            placeholder={PROMPT_PLACEHOLDER}
          />
          <Gap height="5px" />
          <Title>Type</Title>
          <SelectDocType
            value={docType}
            onChange={newType => setDocType(newType)}
          />
          <Gap height="5px" />
          <Title>Knowledge</Title>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "grid",
                gap: "2px",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  gridColumn: 1,
                }}
              >
                <HighlightSpan>
                  {formatLongNumber(totalNumberOfTokens)}
                </HighlightSpan>
                &nbsp;tokens
              </div>
              <div
                style={{
                  gridColumn: 1,
                  justifySelf: "start",
                  fontSize: "12px",
                  paddingBottom: "4px",
                }}
              >
                out of {formatLongNumber(MAX_TOKENS)}
              </div>
              <ProgressBar
                style={{
                  gridColumn: "span 2",
                }}
                maxValue={MAX_TOKENS}
                currentValue={totalNumberOfTokens}
              />
            </div>

            <div
              style={{
                fontSize: "18px",
              }}
            >
              <HighlightSpan>{sources?.length}</HighlightSpan> documents
            </div>

            <div
              style={{
                fontSize: "18px",
              }}
            >
              <HighlightSpan>{totalNumberOfPages}</HighlightSpan> pages
            </div>
          </div>
          <SearchableSelectInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onClickOption={clickedOption => {
              if (clickedOption?.type === "DIRECTORY") {
                console.log({ clickedOption });
                setSearchValue(`${clickedOption?.path}/`);
                return;
              }

              const newFileId = clickedOption?.value;
              const newSource = {
                fileId: newFileId,
                fileName: allItems.find(item => item.id === newFileId)
                  ?.fileName,
                type: allItems.find(item => item.id === newFileId)?.type,
              };
              setSources([...sources, newSource]);
            }}
            options={selectOptions}
            placeholder="File or folder name"
          />
          <div
            style={{
              height: "200px",
              overflow: "auto",
              display: "grid",
              alignContent: "start",
              gap: "8px",
            }}
          >
            {sources.map(source => {
              const sourceItem = allItems?.find(
                item => item?.id === source?.fileId
              );
              const numberOfPages = sourceItem?.numberOfPages;

              return (
                <SourceContainer>
                  {getItemIconFromItem(source?.fileName, source?.type)}
                  <StyledA href={`/files/${source?.fileId}`} target="_blank">
                    {source?.fileName}{" "}
                    {numberOfPages && `(${numberOfPages} pages)`}
                  </StyledA>{" "}
                  <StyledCrossIcon
                    onClick={() => {
                      const newSources = sources.filter(
                        s => s.fileId !== source.fileId
                      );
                      setSources(newSources);
                    }}
                  />
                </SourceContainer>
              );
            })}
          </div>
          <StyledSmallButton
            value="Done"
            onClick={() => {
              doCreateDoc();
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewDocConfigModalTrigger;
