import { useState, useRef } from "react";
import styled from "styled-components";

import {
  FUNCTION_TO_DESCRIPTION,
  SUPPORTED_FUNCTIONS,
} from "api/services/excelModelsService";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Arial";
`;

const DropdownFunctionDescriptionOption = styled.div`
  width: 100%;
  padding: 4px;
  min-width: 200px;
  font-size: 12px;
`;

const OptionsContainer = styled.div`
  position: absolute;
  z-index: 200;
  background-color: ${props => props.theme.color.furthest};
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 4px 0 ${props => props.theme.color.closer1_5};
  white-space: nowrap;
`;

const Option = styled.div`
  width: 100%;
  padding: 4px;
  min-width: 200px;
  font-size: 12px;
  cursor: pointer;
  ${props =>
    props.isHighlighted && `background-color: ${props.theme.color.closer1};`}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  font-family: "Arial";
  border: none;
  outline: 2px solid ${props => props.theme.color.primary};
  color: blue;
  font-size: 14px;
  border: 1px solid transparent;
  color: transparent;
  caret-color: ${props => props.theme.color.closest};
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  display: flex;
  align-items: center;
  padding-left: 2px;
  white-space: nowrap;
  overflow: hidden;
`;

const getWidthFromValue = value => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = "14px Arial";
  const width = context.measureText(value).width + 8;
  return width;
};

const splitByCommaAndBrackets = str => {
  if (typeof str !== "string") {
    return [`${str}`];
  }

  const separators = [
    ",",
    "\\(",
    "\\)",
    "\\+",
    "-",
    "\\*",
    "/",
    ":",
    "=",
    ">",
    "<",
    ">=",
    "<=",
  ];

  const regexPattern = `(${separators.join("|")})`;
  const regex = new RegExp(regexPattern);
  const resultList = str.split(regex);

  return resultList;
};

const COLORS = [
  "cyan",
  "magenta",
  "brown",
  "red",
  "purple",
  "green",
  "blue",
  "orange",
];

export const getCellLocationToColorMap = str => {
  if (typeof str !== "string") {
    return {};
  }

  let usedColors = [...COLORS];
  let cellLocationToColorMap = {};

  const cellAddressPattern = /^[A-Z]+[1-9]\d*$/;
  const cellRangePattern = /^[A-Z]+[1-9]\d*:[A-Z]+[1-9]\d*$/;

  splitByCommaAndBrackets(str)?.forEach(textChunk => {
    if (cellAddressPattern.test(textChunk.trim())) {
      cellLocationToColorMap[textChunk.trim()] = usedColors.pop() || "black";
    }

    if (cellRangePattern.test(textChunk.trim())) {
      cellLocationToColorMap[textChunk.trim()] = usedColors.pop() || "black";
    }
  });

  return cellLocationToColorMap;
};

const CONTROL_KEYS = ["ArrowDown", "ArrowUp", "Enter"];

const ColorSpan = styled.span`
  white-space: pre;
`;

const ExcelTextInputWithFormulaDropdown = ({
  value,
  onChangeValue = newText => {},
  isDisabled = false,
  onFocus = e => {},
  onBlur = e => {},
  style = {},
  autoFocus = false,
  onClick,
  className = "",
  onMouseDown = e => {},
  placeholder = "",
}) => {
  const inputRef = useRef(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const handleArrowKeysAndEnter = e => {
    if (
      isUserAboutToTypeArguments ||
      !filteredFunctionNames?.length ||
      hasUserClosedBracket
    ) {
      return;
    }

    if (CONTROL_KEYS.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "ArrowDown") {
      setSelectedOptionIndex(ind =>
        Math.min(ind + 1, filteredFunctionNames?.length - 1)
      );
    }

    if (e.key === "ArrowUp") {
      setSelectedOptionIndex(ind => Math.max(ind - 1, -1));
    }

    if (e.key === "Enter" && selectedOptionIndex !== -1) {
      onChangeValue(filteredFunctionNames[selectedOptionIndex]);
      setSelectedOptionIndex(-1);
    }
  };

  let filteredFunctionNames = [];
  if (value?.[0] === "=") {
    filteredFunctionNames = SUPPORTED_FUNCTIONS?.map(
      func => `=${func}(`
    )?.filter(
      option =>
        !!value && option?.toLowerCase()?.startsWith(value?.toLowerCase())
    );
  }

  let dropdownContent = filteredFunctionNames?.map((option, i) => (
    <Option
      key={i}
      isHighlighted={selectedOptionIndex === i}
      onClick={() => onChangeValue(option)}
    >
      {option}
    </Option>
  ));

  const isUserAboutToTypeArguments =
    typeof value === "string" && value?.match(/^=.*\(/);
  if (isUserAboutToTypeArguments) {
    const funcName = value?.match(/^=.*\(/)?.[0]?.slice(1, -1);

    dropdownContent = (
      <DropdownFunctionDescriptionOption>
        ={FUNCTION_TO_DESCRIPTION?.[funcName] || funcName}
      </DropdownFunctionDescriptionOption>
    );
  }

  const hasUserClosedBracket =
    typeof value === "string" && value?.match(/^=.*\)/);
  if (hasUserClosedBracket) {
    dropdownContent = null;
  }

  const cellLocationToColor = getCellLocationToColorMap(value);

  return (
    <Container style={style}>
      <StyledInput
        placeholder={placeholder}
        className={className}
        ref={inputRef}
        disabled={isDisabled}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={e => onChangeValue(e.target.value)}
        onKeyDown={handleArrowKeysAndEnter}
        autoFocus={autoFocus}
        onClick={onClick}
        onMouseDown={onMouseDown}
        style={{
          width: `max(${getWidthFromValue(value)}px, 100%)`,
        }}
      />
      <TextContainer
        style={{
          width: `max(${getWidthFromValue(value)}px, 100%)`,
        }}
      >
        {splitByCommaAndBrackets(value)?.map((textChunk, i) => (
          <ColorSpan
            style={{ color: cellLocationToColor[textChunk?.trim()] || "black" }}
            key={i}
          >
            {textChunk}
          </ColorSpan>
        ))}
      </TextContainer>

      {document.activeElement === inputRef.current && (
        <OptionsContainer>{dropdownContent}</OptionsContainer>
      )}
    </Container>
  );
};

export default ExcelTextInputWithFormulaDropdown;
