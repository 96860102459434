import useClickOutside from "hooks/useClickOutside";
import { useState, useRef } from "react";
import styled from "styled-components";

const Trigger = styled.div``;

const Container = styled.div`
  position: fixed;
  background-color: ${props => props.theme.color.furthest};
`;

const PopoverFlexible = ({ children, triggerIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  useClickOutside(triggerRef, () => setIsOpen(false));

  const rect = triggerRef.current?.getBoundingClientRect();
  const { top, left, width, height } = rect || {};

  return (
    <>
      <Trigger onClick={() => setIsOpen(!isOpen)} ref={triggerRef}>
        {triggerIcon || <button>+</button>}
      </Trigger>
      <Container
        style={{
          display: isOpen ? "block" : "none",
          top: `calc(${top}px - ${height}px - 20px)`,
          left,
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default PopoverFlexible;
