import { isEqual } from "lodash";

import * as backend from "api/backend/sigmaEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getTablePreviewFromCells } from "api/services/projectService";

export const getSigmaTableConfig = async id => {
  const { data, error } = await backend.getSigmaTableConfig(id);
  return { data, error: getHandlingFromError(error) };
};

export const deleteSigmaTableConfig = async id => {
  const { data, error } = await backend.deleteSigmaTableConfig(id);
  return { data, error: getHandlingFromError(error) };
};

export const getSigmaTableConfigs = async () => {
  const { data, error } = await backend.getSigmaTableConfigs();
  return { data, error: getHandlingFromError(error) };
};

export const getSigmaConfigs = async ({ ids = [] }) => {
  const { data, error } = await backend.getSigmaConfigs();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  return { data: data.filter(config => ids.includes(config.id)), error: null };
};

export const postExtractSigmaConfigsFromFile = async (params = {}, file) => {
  const { data, error } = await backend.postExtractSigmaConfigsFromFile(
    params,
    file
  );
  return { data, error: getHandlingFromError(error) };
};

export const postSigmaTableConfig = async body => {
  const { data, error } = await backend.postSigmaTableConfig({}, body);
  return { data, error: getHandlingFromError(error) };
};

export const postRunWithTableConfig = async body => {
  const { data, error } = await backend.postRunWithTableConfig({}, body);
  return { data, error: getHandlingFromError(error) };
};

export const patchTableConfig = async (
  id,
  newTableConfig,
  originalTableConfig = {}
) => {
  const haveSigmaConfigsBeenReplaced = newTableConfig.sigmaConfigs.some(
    config => !config?.id
  );
  if (haveSigmaConfigsBeenReplaced) {
    return {
      data: null,
      error: getHandlingFromError({
        message: "Sigma configs have been replaced",
      }),
    };
  }

  const editedSigmaConfigs = newTableConfig.sigmaConfigs.filter(config => {
    const originalConfig = originalTableConfig.sigmaConfigs.find(
      c => c.id === config.id
    );
    return !isEqual(config, originalConfig);
  });

  const sigmaConfigsPatchResults = await Promise.all(
    editedSigmaConfigs.map(editedConfig =>
      backend.patchSigmaConfig(editedConfig.id, {}, editedConfig)
    )
  );
  const isTherePatchingError = sigmaConfigsPatchResults.some(
    result => result.error
  );
  if (isTherePatchingError) {
    const firstPatchErr = sigmaConfigsPatchResults?.find(
      result => result.error
    )?.error;
    return { data: null, error: getHandlingFromError(firstPatchErr) };
  }

  const { name, primarySigmaConfigNames, fileIds, keywordFilter } =
    newTableConfig;
  const { data, error } = await backend.patchSigmaTableConfig(
    id,
    {},
    {
      name,
      primarySigmaConfigNames,
      fileIds,
      keywordFilter,
    }
  );

  return { data, error: getHandlingFromError(error) };
};

export const postRunSigmaTableConfig = async (id, params = {}) => {
  const { data, error } = await backend.postRunSigmaTableConfig(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const postSigmaRule = async body => {
  const { data, error } = await backend.postSigmaRule(body);
  return { data, error: getHandlingFromError(error) };
};

export const patchSigmaConfig = async (id, body) => {
  const { data, error } = await backend.patchSigmaConfig(id, {}, body);
  return { data, error: getHandlingFromError(error) };
};

export const postSigmaResultsDownload = async (
  file,
  cells,
  idColumnName,
  tableColumns
) => {
  const tablePreview = getTablePreviewFromCells(cells, idColumnName);
  const results = { tablePreview, tableColumns };

  const { error } = await backend.postSigmaResultsDownload(
    {},
    { results: JSON.stringify(results), file }
  );

  return { data: null, error: getHandlingFromError(error) };
};

export const postTableConfigFromConversation = async conversationId => {
  const { data, error } = await backend.postTableConfigFromConversation(
    conversationId
  );
  return { data, error: getHandlingFromError(error) };
};

export const postSigmaRerunEmailJobs = async (params = {}, body = {}) => {
  const { data, error } = await backend.postSigmaRerunEmailJobs(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postSigmaRerunExtractionJobs = async (params = {}, body = {}) => {
  const { data, error } = await backend.postSigmaRerunExtractionJobs(
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};
