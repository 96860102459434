import { CircularProgress } from "@material-ui/core";
import { ArrowDownward, Refresh } from "@material-ui/icons";
import { postDownloadExcelFileOfTable } from "api/services/projectService";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import DashboardColumnModalTrigger from "components/DashboardColumnModalTrigger";
import DownloadSourceTableModal from "components/DownloadSourceTableModal";
import TooltipPopup from "components/TooltipPopup";
import { CrossIcon, DownloadIcon, TickIcon } from "components/ui/Icons";
import PagePreviewTextAndTableModal from "components/widgets/PagePreviewTextAndTableModal";
import { isNil, range } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { safeFormat } from "utils/common";
import { getDisplayValue } from "utils/dashboard-utils";

const doDownloadTable = async (columns, records) => {
  const tableColumns = columns?.map(column => column?.name);
  const tablePreview = records?.map(record => {
    const newRow = {};
    tableColumns?.forEach(columnName => {
      const val =
        typeof record?.[columnName] === "string"
          ? record?.[columnName]
          : record?.[columnName]?.value || "";
      newRow[columnName] = {
        Value: val,
        RawValue: val,
      };
    });

    return newRow;
  });

  const body = {
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const Container = styled.div`
  display: grid;
  align-items: start;
  gap: 20px;
  grid-auto-columns: 1fr;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: auto;
  max-width: 420px;
  transition: opacity 0.2s;

  ::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.isSticky &&
    `
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${props.theme.color.furthest};
    z-index: 101;
    border-right: 1px solid ${props.theme.color.closer1};
  `}
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 14px;
  transition: opacity 0.8s;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;

  ${props =>
    props.isSticky &&
    `
    position: sticky;
    top: 0;
    left: 0;
    z-index: 101;
  `}

  ${props => props.isPredictionStyle && `color: ${props.theme.color.primary};`}

  z-index: 100;
`;

const TopContent = styled.div``;

const TableScrollContainer = styled.div`
  overflow: auto;
  max-height: 600px;
`;

const TableTitle = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  padding: 8px 0;
  font-weight: 600;
  background-color: ${props => props.theme.color.furthest};

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const FilterInput = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 4px 8px;
  margin-bottom: 8px;

  position: sticky;
  top: 34px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 12px;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const columnNameToDisplayName = {
  emailId: "Email Subject",
  receivedAt: "Received Time",
};

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  trailingZeroDisplay: "stripIfInteger",
});

const VerificationDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props =>
    props?.isVerified
      ? props.theme.color.success
      : props.theme.color.in_progress};
  opacity: 0.5;

  position: absolute;
  top: 2px;
  right: 2px;
`;

const BlueTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.primary};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const GreyTickIcon = styled(TickIcon)`
  fill: ${props => props.theme.color.closer2};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const TickTd = styled(Td)`
  border: none;
`;

const CrossTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 4px;
`;

const RefreshTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 0;

  cursor: pointer;

  svg {
    fill: ${props => props.theme.color.closest};
    height: 16px;
    :hover {
      opacity: 0.6;
    }
  }
`;

const NewColumnTh = styled(Th)`
  opacity: 0;
  transition: opacity 0.2s;
  border: 1px solid transparent;
`;

const DotsTd = styled(Td)`
  border: none;
  opacity: 0;
  transition: opacity 0.2s;
`;

const Tr = styled.tr`
  :hover {
    background-color: ${props => props.theme.color.closer0}AA;
    ${CrossTd} {
      opacity: 1;
    }
    ${RefreshTd} {
      opacity: 1;
    }
    ${NewColumnTh} {
      opacity: 1;
      border: 1px solid ${props => props.theme.color.closer1};
    }
    ${DotsTd} {
      opacity: 1;
    }
  }
`;

const TableText = styled.div`
  display: inline-block;
  position: relative;
  max-width: 250px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ColumnActions = styled.div`
  display: inline-flex;
  gap: 4px;
  margin-left: 4px;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;

  ${props => props.isHidden && "visibility: hidden;"}
`;

const ColNameAndActions = styled.div`
  display: flex;
  align-items: center;

  :hover {
    ${ColumnActions} {
      opacity: 1;
    }
  }
`;

const StyledArrowDown = styled(ArrowDownward)`
  opacity: 0;
  pointer-events: none;

  ${props => props.isHighlighted && `color: ${props.theme.color.primary};`}
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const LoadingRowContainer = styled.div`
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 14px;
  cursor: pointer;
  color: ${props => props.theme.color.closest};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const BlueDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 0.5;
  background-color: ${props => props.theme.color.primary};
  display: inline-block;
  margin-bottom: 1px;
  margin-right: 4px;
  /* margin-left: -10px; */
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-bottom: 0px;
  margin-right: 4px;
  width: 6px;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const ColumnPrompt = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.primary};
`;

const UnderlineSpan = styled.span`
  :hover {
    text-decoration: underline;
  }
`;

const disp = columnName => {
  if (columnName === "emailSubject") {
    return "Email Subject";
  }
  if (columnName === "receivedAt") {
    return "Received Time";
  }
  return columnName;
};

const ID_COLUMN_NAMES = ["Source Name", "Email Subject", "emailSubject"];
const TIME_COLUMN_NAMES = ["Received Time", "Inserted Time", "receivedAt"];

const RecordsTable = ({
  name = "",
  columns = [],
  arePromptsVisible = false,
  records = [{ id: "", State: { value: "Alaska" } }],
  onNewColumns = newColumns => {},
  onConfirmChangeRecord = (id, newFields) => {},
  onClickRefreshRecord = id => {},
}) => {
  const [msAfterLoad, setMsAfterLoad] = useState(100000);

  const [modalColumnName, setModalColumnName] = useState(null);
  const [modalRecordId, setModalRecordId] = useState(null);
  const [dismissedRecordIds, setDismissedRecordIds] = useState([]);

  // useEffect(() => {
  //   if (!columns?.length) {
  //     return;
  //   }

  //   const intervalId = setInterval(
  //     () => setMsAfterLoad(prev => prev + 100),
  //     100
  //   );
  //   return () => clearInterval(intervalId);
  // }, [columns?.length]);

  useEffect(() => {
    if (dismissedRecordIds?.length === 0) {
      setDismissedRecordIds(records?.map(record => record?._id));
    }
  }, [records?.length, dismissedRecordIds?.length]);

  const modalRecord = records?.find(record => record?._id === modalRecordId);
  const modalMeta = modalRecord?.[modalColumnName]?.meta || {
    fileId: modalRecord?.emailPdfFileId,
    fileName: "",
    pageNumber: 0,
    annotationId: "",
    blockId: "",
  };

  const sortedRecords = records?.sort((a, b) => {
    const aReceivedAt = new Date(a?.receivedAt);
    const bReceivedAt = new Date(b?.receivedAt);
    if (aReceivedAt < bReceivedAt) {
      return 1;
    }
    if (aReceivedAt > bReceivedAt) {
      return -1;
    }
    return 0;
  });

  const modalColumn = columns?.find(column => column?.name === modalColumnName);

  return (
    <div style={{ overflow: "auto" }}>
      <TableTitle>
        <span>{name}</span>
        <DownloadSourceTableModal
          onClickDownloadFile={() => doDownloadTable(columns, records)}
        />
        {/* <StyledDownloadIcon onClick={() => doDownloadTable(columns, records)} /> */}
      </TableTitle>
      <StyledTable>
        <thead>
          <Tr>
            {columns?.map((column, i) => (
              <Th
                style={{
                  opacity:
                    msAfterLoad >= (i + 1) * 400 || records?.length ? 1 : 0,
                }}
                isSticky={i === 0}
                isPredictionStyle={column?.prediction}
              >
                <div
                  style={{
                    display: "grid",
                    gap: "4px",
                    justifyItems: "start",
                  }}
                >
                  <ColNameAndActions>
                    {disp(column?.name)}
                    <ColumnActions>
                      <DashboardColumnModalTrigger
                        column={column}
                        onClickConfirm={newColumn => {
                          const newColumns = columns?.map(col => {
                            if (col?.name === column?.name) {
                              return newColumn;
                            }
                            return col;
                          });
                          onNewColumns(newColumns);
                        }}
                      />
                      <ConfirmationModalTrigger
                        message={`Are you sure you want to delete column "${column?.name}"?`}
                        onClickYes={() => {
                          const newColumns = columns?.filter(
                            col => col?.name !== column?.name
                          );
                          onNewColumns(newColumns);
                        }}
                      />
                    </ColumnActions>
                  </ColNameAndActions>
                  {arePromptsVisible && (
                    <ColumnPrompt>
                      prompt: {column?.description?.toLowerCase()}
                    </ColumnPrompt>
                  )}
                </div>
              </Th>
            ))}
            <NewColumnTh style={{ padding: 0, verticalAlign: "middle" }}>
              <DashboardColumnModalTrigger
                onClickConfirm={newColumn => {
                  onNewColumns([...columns, newColumn]);
                }}
              />
            </NewColumnTh>
          </Tr>
        </thead>
        <tbody>
          {!sortedRecords?.length && (
            <>
              {range(0, 5).map(i => (
                <Tr style={{ opacity: 0.2 }}>
                  {columns?.map((column, j) => {
                    const rowDelay = 100 * i;
                    const style = {
                      opacity: msAfterLoad >= rowDelay + (j + 1) * 400 ? 1 : 0,
                    };

                    if (column?.name === "Inserted Time") {
                      return (
                        <Td style={style}>
                          {safeFormat(
                            new Date(`0${i + 1} February 2023 14:48 UTC`),
                            "d MMM yyyy, HH:mm"
                          )}
                        </Td>
                      );
                    }

                    return (
                      <Td style={style}>
                        Example {column?.name} {i}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </>
          )}
          {sortedRecords?.map(record => {
            const isDismissed = dismissedRecordIds?.includes(record?._id);
            const dismissedStyle = isDismissed
              ? {}
              : {
                  backgroundColor: "#f2f9ff",
                };
            const isInProgress = record?.status === "IN_PROGRESS";

            return (
              <Tr
                onClick={() =>
                  setDismissedRecordIds(prev => [...prev, record?._id])
                }
                style={{
                  color: isInProgress ? "#00000055" : "auto",
                }}
                key={`${record?._id}-${record?.status}`}
              >
                {columns?.map((column, i) => {
                  if (
                    ID_COLUMN_NAMES?.includes(column?.name) &&
                    typeof record?.emailSubject === "string"
                  ) {
                    return (
                      <Td
                        style={{
                          maxWidth: "300px",
                          // textOverflow: "ellipsis",
                          ...dismissedStyle,
                        }}
                        isSticky
                      >
                        {isInProgress && (
                          <StyledCircularProgress size={8} thickness={8} />
                        )}
                        {!isDismissed && i === 0 && <BlueDot />}
                        {record?.emailSubject || "(no name)"}
                      </Td>
                    );
                  }

                  if (record?.[column?.name]?.format === "timestamp") {
                    return (
                      <Td isSticky={i === 0} style={dismissedStyle}>
                        {safeFormat(
                          record?.[column?.name]?.value,
                          "d MMM yyyy, HH:mm"
                        )}
                      </Td>
                    );
                  }

                  if (
                    TIME_COLUMN_NAMES?.includes(column?.name) &&
                    record?.receivedAt
                  ) {
                    return (
                      <Td style={dismissedStyle}>
                        {safeFormat(record?.receivedAt, "d MMM yyyy, HH:mm")}
                      </Td>
                    );
                  }

                  if (
                    record?.[column?.name]?.meta ||
                    record?.[column?.name]?.value === undefined
                  ) {
                    const recordValue = getDisplayValue({
                      record,
                      columnName: column?.name,
                      columnType: column?.type,
                    });

                    return (
                      <Td isSticky={i === 0} style={dismissedStyle}>
                        {!dismissedRecordIds?.includes(record?._id) &&
                          i === 0 && <BlueDot />}
                        <UnderlineSpan
                          onClick={() => {
                            setModalRecordId(record?._id);
                            setModalColumnName(column?.name);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {recordValue}
                        </UnderlineSpan>
                        <VerificationDot
                          isVerified={record?.[column?.name]?.meta?.checked}
                        />
                      </Td>
                    );
                  }

                  return (
                    <Td isSticky={i === 0}>{record?.[column?.name]?.value}</Td>
                  );
                })}
                <DotsTd
                  style={{
                    border: "none",
                    padding: "4px",
                    verticalAlign: "middle",
                  }}
                >
                  <TooltipPopup offsetX={-80} offsetY={24}>
                    {!isInProgress && (
                      <TooltipItem
                        onClick={() => onClickRefreshRecord(record?._id)}
                      >
                        <Refresh style={{ height: 14, width: 14 }} />
                        Re-run
                      </TooltipItem>
                    )}
                    <TooltipItem
                      onClick={() =>
                        onConfirmChangeRecord(record?._id, { isDeleted: true })
                      }
                    >
                      <CrossIcon height="12px" />
                      Delete
                    </TooltipItem>
                  </TooltipPopup>
                </DotsTd>
              </Tr>
            );
          })}
        </tbody>
      </StyledTable>

      <PagePreviewTextAndTableModal
        open={!isNil(modalRecordId)}
        handleClose={() => {
          setModalColumnName(null);
          setModalRecordId(null);
        }}
        checked={modalMeta?.checked}
        refText={modalRecord?.[modalColumnName]?.refText}
        tableDocumentLocation={modalMeta}
        emailId={modalRecord?.emailId}
        // tableDocumentLocation={{
        //   fileId: modalMeta?.fileId,
        //   pageNumber: modalMeta?.pageNumber,
        //   fileName: modalMeta?.fileName,
        //   annotationId: modalMeta?.annotationId,
        //   blockId: modalMeta?.blockId,
        //   emailId: modalMeta?.emailId,
        // }}
        isReferenceEditable
        columnName={modalColumnName}
        columnValue={modalRecord?.[modalColumnName]?.value}
        columnDescription={modalColumn?.description}
        onPressSaveValue={async (newValue, newAnnotationId) => {
          let parsedValue = newValue;
          const isNewColumnAndNumberParsed =
            modalRecord?.[modalColumnName]?.value === undefined ||
            !isNaN(newValue);
          if (
            modalRecord?.[modalColumnName]?.type === "NUMERIC" ||
            isNewColumnAndNumberParsed
          ) {
            if (newValue?.includes("=")) {
              parsedValue = newValue;
            } else if (newValue === "") {
              parsedValue = null;
            } else {
              parsedValue = parseFloat(newValue) || 0;
            }
          }

          onConfirmChangeRecord(modalRecord?._id, {
            [modalColumnName]: {
              ...modalRecord?.[modalColumnName],
              value: parsedValue,
              meta: {
                ...modalMeta,
                annotationId: newAnnotationId ?? modalMeta?.annotationId,
                blockId: newAnnotationId ?? modalMeta?.blockId,
              },
            },
          });
        }}
        onPressVerify={checked => {
          onConfirmChangeRecord(modalRecord?._id, {
            [modalColumnName]: {
              ...modalRecord?.[modalColumnName],
              value: modalRecord?.[modalColumnName]?.value,
              meta: {
                ...modalMeta,
                checked,
              },
            },
          });
        }}
      />
    </div>
  );
};

export default RecordsTable;
