import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Email, Folder, GridOn } from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { isNil } from "lodash";

import usePollDirectoryItems from "api/services/usePollDirectoryItems";
import {
  deleteFileById,
  postBulkUploadFormData,
} from "api/services/filesService";
import { deleteDirectory } from "api/services/fileSystem";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import { getLoggedInUserName } from "api/services/authenticationService";

import { CenteredWithTopNavLayout } from "components/Layout";
import Tooltip from "components/ui/Tooltip";
import useSearchParamsState from "hooks/useSearchParamsState";
import FileActionsDropdown from "components/FileActionsDropdown";
import CreateDirectoryModalTrigger from "components/CreateDirectoryModalTrigger";
import { isTruthy } from "utils/common";
import MoveItemsToDirectoryModalTrigger from "components/MoveItemsToDirectoryModalTrigger";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";
import useDirectoryPagination from "hooks/useDirectoryPagination";
import FileInputMulti from "components/inputs/FileInputMulti";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import { format } from "date-fns";
import { deleteExcelModelById } from "api/services/excelModelsService";
import FileTagFetcher from "components/FileTagFetcher";
import {
  DatasetIcon,
  EmailIcon,
  InternetIcon,
  ProgressCircleIcon,
} from "components/ui/Icons";
import ProgressBar from "components/ui/ProgressBar";

const ProgressAndPages = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 50px;
  height: 4px;

  div {
    animation: pulse 1s infinite;

    @keyframes pulse {
      0% {
        background-color: ${props => props.theme.color.primary};
      }

      50% {
        background-color: ${props => props.theme.color.primary}55;
      }

      100% {
        background-color: ${props => props.theme.color.primary};
      }
    }
  }
`;

const getPagesScannedValue = (numberOfPages, progress) => {
  if (isNil(numberOfPages)) {
    return "";
  }

  if (progress === 100) {
    return numberOfPages;
  }

  const scannedPages = Math.floor((numberOfPages * progress) / 100);

  return (
    <ProgressAndPages>
      {/* <ProgressCircleIcon progress="20" /> */}
      {/* <StyledProgressBar maxValue={100} currentValue={Math.max(progress, 20)} /> */}
      <div>
        {scannedPages} / {numberOfPages}
      </div>
    </ProgressAndPages>
  );
};

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding-bottom: 100px;
`;

const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
`;

const BigTitle = styled(Link)`
  font-size: 24px;
  font-weight: 600;

  text-decoration: none;
  color: ${props => props.theme.color.closest};

  display: flex;
  gap: 12px;
  align-items: center;
`;

const SmallFilesInput = styled(FileInputMulti)`
  padding: 8px;
  svg {
    fill: ${props => props.theme.color.closer2};
  }
`;

const DirectoryPath = styled.div`
  font-size: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Td = styled.td`
  max-width: 780px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 14px;
  background-color: ${props => props.theme.color.closer0};
  vertical-align: top;
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const HeaderTr = styled.tr`
  color: white;
  background: linear-gradient(
    to right,
    ${props => props.theme.color.feature},
    ${props => props.theme.color.primary}
  );
  font-weight: 600;

  ${Td} {
    background-color: transparent;
  }
`;

const IconAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Name = styled.div`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleAndPath = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DirLink = styled(Link)`
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NameTd = styled(Td)`
  max-width: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CreationBtns = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 0;
`;

const SourceFilesPage = () => {
  const loggedInUserName = getLoggedInUserName();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [directoryPath] = useSearchParamsState({
    paramName: "directoryPath",
    initialValue: "/source-files",
  });
  const [directoryItems, setDirectoryItems] = useState([]);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);

  const [
    pageSize,
    offset,
    prevUrl,
    nextUrl,
    hasMore,
    setNextOffset,
    setHasMore,
  ] = useDirectoryPagination();

  const [itemsData, doPopulateDirectoryItems] = usePollDirectoryItems({
    directoryPath,
    pageSize,
    offset,
    type: searchParams?.get("type"),
  });

  useEffect(() => {
    setDirectoryItems(itemsData?.items || []);
    setHasMore(itemsData?.hasMore);
    setNextOffset(itemsData?.offset);
  }, [JSON.stringify(itemsData)]);

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?.id);

    let error = null;

    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await deleteExcelModelById(item?.id));
    }

    if (item?.type === "FILE") {
      ({ error } = await deleteFileById(item?.id));
    }

    if (item?.type === "DIRECTORY") {
      ({ error } = await deleteDirectory({ directoryPath: item?.path }));
    }

    setItemIdBeingDeleted(null);
    if (error) {
      setError(error);
      return;
    }
    doPopulateDirectoryItems();
  };

  const doPostFiles = async files => {
    setIsUploadingFiles(true);
    const { error } = await postBulkUploadFormData(
      { directoryPath },
      { files }
    );
    if (error) {
      alert(JSON.stringify(error));
      setIsUploadingFiles(false);
      return;
    }
    setIsUploadingFiles(false);
    doPopulateDirectoryItems();
  };

  const dirNames = directoryPath?.split("/");
  const parentDirectoryPath = dirNames.slice(0, -1).join("/");

  return (
    <StyledCenteredWithTopNavLayout>
      <TopContent>
        <TitleAndPath>
          <BigTitle to="/source-files">
            <DatasetIcon style={{ height: "20px" }} />
            SOURCE
          </BigTitle>
          <DirectoryPath>
            {dirNames?.length > 6 && "..."}
            {dirNames
              .filter(isTruthy)
              .filter(dirName => dirName !== "source-files")
              .map((dirName, i) => {
                if (dirNames?.length > 5 && i + 3 < dirNames?.length - 3) {
                  return null;
                }

                return (
                  <Fragment key={i}>
                    &nbsp;/&nbsp;
                    <DirLink
                      to={`?directoryPath=${dirNames
                        ?.slice(0, i + 3)
                        .join("/")}`}
                    >
                      {dirName}
                    </DirLink>
                  </Fragment>
                );
              })}
          </DirectoryPath>
        </TitleAndPath>
      </TopContent>

      <CreationBtns>
        <Tooltip title="New folder">
          <CreateDirectoryModalTrigger
            onDirectryCreated={doPopulateDirectoryItems}
            directoryPath={directoryPath}
          />
        </Tooltip>
        <Tooltip title="Upload new files">
          <SmallFilesInput
            buttonText="Upload"
            isShowingFileChosenText={false}
            isDisabled={isUploadingFiles}
            onFilesSelect={doPostFiles}
            accept=".pdf, .xlsx"
          />
        </Tooltip>
      </CreationBtns>

      <Table>
        <thead>
          <HeaderTr>
            <Td>Name</Td>
            <Td>Created at</Td>
            <Td>Owner</Td>
            <Td>Pages</Td>
            <Td></Td>
          </HeaderTr>
        </thead>
        <tbody>
          {directoryItems?.map((item, i) => {
            let itemIcon = null;
            let itemName = item?.originalName || item?.id;
            let itemUrl = `/source-files?directoryPath=${directoryPath}`;

            if (item?.originalName?.includes(".pdf")) {
              itemIcon = <PictureAsPdfIcon style={{ fill: "#d9534f" }} />;
              itemName = item?.fileName;
              itemUrl = `/file/${item?.id}`;
            }
            if (item?.originalName?.includes(".xlsx")) {
              itemIcon = <GridOn style={{ fill: "#188038" }} />;
              itemName = item?.fileName;
              itemUrl = `/file/${item?.id}`;
            }
            if (item?.type === "DIRECTORY") {
              itemIcon = <Folder style={{ fill: "grey" }} />;
              itemName = item?.path
                ?.replace(directoryPath, "")
                ?.replace("/", "");
              itemUrl = `/source-files?directoryPath=${item?.path}`;
            }
            if (item?.emailId) {
              itemIcon = <EmailIcon style={{ fill: "grey" }} />;
              itemName = item?.fileName;
              itemUrl = `/files/${item?.id}`;
            }
            if (item?.type === "WEB_PAGE") {
              itemIcon = <InternetIcon style={{ fill: "grey" }} />;
              itemName = item?.fileName || "Name missing";
              itemUrl = `/web-pages/${item?.id}`;
            }

            const fileActions =
              item?.createdBy === loggedInUserName ? ["Delete"] : [];

            return (
              <Tr
                isDisabled={itemIdBeingDeleted === item?.id}
                key={`${item?.id}-${i}`}
              >
                <NameTd width="100%">
                  <IconAndName>
                    {itemIcon}
                    <Link to={itemUrl}>
                      <Name>{itemName}</Name>
                    </Link>
                  </IconAndName>
                  {item?.type !== "DIRECTORY" && (
                    <FileTagFetcher fileId={item?.id} />
                  )}
                </NameTd>
                <Td>
                  {format(
                    new Date(item?.createdAt || item?.createdAtMs),
                    "d MMM yyyy"
                  )}
                </Td>
                <Td>
                  {item?.createdBy === loggedInUserName
                    ? "me"
                    : item?.createdBy}
                </Td>
                <Td>
                  {getPagesScannedValue(item?.numberOfPages, item?.progress)}
                </Td>
                <Td>
                  <ActionsContainer>
                    <FileActionsDropdown
                      visibleActions={fileActions}
                      onClickShare={() => {}}
                      onClickDelete={() => doDeleteItem(item)}
                      onClickDownload={() =>
                        triggerDownloadOfFile(item?.id, {
                          fileType: item?.type,
                        })
                      }
                      additionalActions={[
                        <MoveItemsToDirectoryModalTrigger
                          id={item?.id}
                          itemType={item?.type}
                          directoryPath={directoryPath}
                          parentDirectoryPath={parentDirectoryPath}
                          directoryOptionItems={directoryItems?.filter(
                            dirOptionItem =>
                              dirOptionItem?.type === "DIRECTORY" &&
                              dirOptionItem?.id !== item?.id
                          )}
                          onItemMoved={doPopulateDirectoryItems}
                        />,
                        <ShareDirectoryItemModalTrigger directoryItem={item} />,
                      ]}
                    />
                  </ActionsContainer>
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <PrevNextPageButtons
        onClickPrev={() => navigate(prevUrl)}
        isPrevDisabled={!offset}
        onClickNext={() => navigate(nextUrl)}
        isNextDisabled={!hasMore}
      />
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </StyledCenteredWithTopNavLayout>
  );
};

export default SourceFilesPage;
