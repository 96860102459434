import { getPageVisits } from "api/backend/projectServiceEndpoints";
import { Gap } from "components/Layout";
import { BoltzhubLogoInner } from "components/ui/Icons";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 100px;
  margin: 0 40px;
  padding-bottom: 100px;
`;

const TopBar = styled.div`
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid #eaeaea;
  gap: 20px;
  background-color: white;
`;

const TopBarLink = styled(Link)`
  display: block;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 36px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
`;

const StyledLink = styled(Link)`
  justify-self: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const Table = styled.table``;

const Tr = styled.tr``;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #eaeaea;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid #eaeaea;
  font-weight: 600;
  text-align: left;
`;

const COLUMNS = ["createdAt", "url", "tempUserId", "meta"];

const AdminPageVisitsPage = () => {
  const [pageVisits, setPageVisits] = useState([]);

  useEffect(() => {
    doPopulatePageVisits();
  }, []);

  const doPopulatePageVisits = async () => {
    const { data } = await getPageVisits();
    setPageVisits(data);
  };

  return (
    <Container>
      <TopBar>
        <StyledLink to="/">
          <StyledBoltzbitLogo />
        </StyledLink>
        <TopBarLink to="/admin/blogs">Blogs</TopBarLink>
        <TopBarLink to="/admin/page-visits">Page Visits</TopBarLink>
      </TopBar>
      <Title>Page Visits</Title>
      <Gap />
      <Table>
        <thead>
          <Tr>
            {COLUMNS.map(column => (
              <Th>{column}</Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {pageVisits?.map(pageVisit => (
            <Tr>
              {COLUMNS.map(column => {
                let columnValue = JSON.stringify(pageVisit?.[column]);
                if (typeof pageVisit?.[column] === "string") {
                  columnValue = pageVisit?.[column]?.slice(0, 60);
                }
                // if (columnValue?.startsWith("http")) {
                //   columnValue = (
                //     <a href={columnValue} target="_blank" rel="noreferrer">
                //       {columnValue}
                //     </a>
                //   );
                // }

                return <Td>{columnValue}</Td>;
              })}
            </Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AdminPageVisitsPage;
