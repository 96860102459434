import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cloneDeep, get, isEmpty, range, set } from "lodash";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import {
  getSigmas,
  getTemplateDashboardConfig,
  patchDashboardConfig,
  patchSigma,
  postDashboardConfigCalculateResults,
  postGenerateComponent,
} from "api/services/chatService";
import {
  CrossIcon,
  DownloadCloudIcon,
  FilesIcon,
  MonitorIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  TickIcon,
  TrashIcon,
} from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import RecordsTable from "components/RecordsTable";
import RecordsSummaryChart from "components/RecordsSummaryChart";
import styled from "styled-components";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import SmallButton from "components/ui/SmallButton";
import { postReRunJobs } from "api/services/jobService";
import NavHeader from "components/NavHeader";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import AutocompleteTextInputDropdown from "components/ui/AutocompleteTextInputDropdown";
import {
  COMPONENT_TYPES,
  NL_TO_DSL,
} from "components/ComponentCodeModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import {
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  List,
  ViewColumn,
} from "@material-ui/icons";
import GridDraggable from "components/GridDraggable";
import ButtonDark from "components/ui/ButtonDark";
import TooltipNew from "components/ui/TooltipNew";
import TooltipClick from "components/ui/TooltipClick";
import { MODEL_SELECT_OPTIONS } from "pages/trial-dashboards/:wordDocId/source";
import LayoutNew from "components/LayoutNew";

const TOP_LAYOUT = [".", ".", "."];

const ComponentGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20px 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  gap: 20px;

  padding-left: 8px;
  padding-right: 48px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: lightgrey;
  :hover {
    fill: ${props => props.theme.color.closest};
  }
`;

const StyledFilesIcon = styled(FilesIcon)`
  opacity: 0;
  cursor: pointer;
  fill: ${props => props.theme.color.closer2};

  :hover {
    opacity: 0.8;
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #0a1e54;

  display: flex;
  align-items: center;
  gap: 8px;

  :hover {
    ${StyledFilesIcon} {
      opacity: 1;
    }
  }
`;

const PulsingDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};

  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      background-color: ${props => props.theme.color.primary};
    }

    50% {
      background-color: ${props => props.theme.color.primary}55;
    }

    100% {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;

const TopMessageAndButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0px 48px;
  background-color: #f7f8fc;
  margin-bottom: 8px;
  padding-top: 8px;
  align-items: center;
`;

const BlueSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
  svg {
    height: 10px;
    width: 16px;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }
`;

const EmptySlot = styled.div`
  border: 2px dashed lightgrey;
  width: 100%;
  height: 200px;
  opacity: 0.5;
`;

const Container = styled.div``;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  position: relative;
  background-color: #f7f8fc;
  box-shadow: none;
`;

const TopBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 201;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto auto;
  gap: 12px;
  padding: 12px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 12px;
  padding-top: 8px;

  background-color: #f7f8fc;

  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const RoundPlusIcon = styled(PlusIcon)`
  background-color: ${props => props.theme.color.closer0};
  padding: 2px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

const RoundTrashIcon = styled(TrashIcon)`
  background-color: ${props => props.theme.color.closer0};
  padding: 2px;
  cursor: pointer;
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}

  :hover {
    opacity: 0.6;
  }
`;

const Select = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ChevronContainer = styled.div`
  cursor: pointer;
  ${props => props.isHidden && "opacity: 0; pointer-events: none;"}

  :hover {
    opacity: 0.6;
  }
`;

const TabLinks = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
`;

const TabLinkItem = styled(Link)`
  text-decoration: none;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  font-weight: 600;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props.isSelected ? props.theme.color.primary : props.theme.color.closer1};
`;

const OptionsContainer = styled.div`
  box-shadow: ${props => props.theme.shadow};
`;

const TickedIcon = styled(TickIcon)`
  width: 12px;
  height: 12px;
  fill: ${props => props.theme.color.primary};
`;

const UntickedCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Option = styled.div`
  line-height: 1.2;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 8px 12px;
  gap: 4px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const getGridString = (gridList = []) => {
  const grid = gridList
    .map(row => {
      return `". ${row.join(" ")}"`;
    })
    .join("\n");

  return grid;
};

const RESERVED_COLUMNS = ["emailSubject", "receivedAt"];

const getDsl = naturalLanguageCommand => {
  return NL_TO_DSL[naturalLanguageCommand] || naturalLanguageCommand;
};

const PencilContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ShortenedText = styled.span`
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconButtonContainer = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const LeftContent = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  align-items: center;
  padding-top: 8px;
  justify-content: start;
  grid-auto-flow: column;
  grid-template-columns: auto;
  justify-content: start;
  justify-items: start;
  padding-bottom: 16px;
`;

const RightContent = styled.div``;

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding: 0;
`;

const SelectCustom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  width: 150px;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const TrialDashboardPage = () => {
  const { wordDocId } = useParams();
  const navigate = useNavigate();

  const [wordDoc, setWordDoc] = useState({});
  const [dashboardConfig, setDashboardConfig] = useState(null);
  const [sigmas, setSigmas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initiallyOpenComponentId, setInitiallyOpenComponentId] =
    useState(null);

  const [naturalLanguageCommand, setNaturalLanguageCommand] = useState("");
  const [message, setMessage] = useState("");

  const [isEditingDashboard, setIsEditingDashboard] = useState(false);
  const [selectedModelValue, setSelectedModelValue] = useState(
    MODEL_SELECT_OPTIONS[0]?.value
  );

  useEffect(() => {
    doPopulateTemplateConfig();
    doPopulateSigmas();
    doPopulateWordDoc();

    const intervalId = setInterval(() => {
      doPopulateSigmas();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [wordDocId]);

  useEffect(() => {
    const doCalculateResultsInBackground = async () => {
      await postDashboardConfigCalculateResults(dashboardConfig?._id);
      await doPopulateTemplateConfig();
    };

    doCalculateResultsInBackground();
  }, [sigmas?.length]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
  };

  const sourceRecordsColumns = dashboardConfig?.config?.components?.find(
    component => component?.id === "source_records"
  )?.columns;

  useEffect(() => {
    if (!sourceRecordsColumns?.length) {
      return;
    }

    const blocks = [];
    sourceRecordsColumns
      ?.filter(
        column =>
          !RESERVED_COLUMNS.includes(column?.name) &&
          !column?.isExtractionDisabled
      )
      ?.forEach(column => {
        let newBlock = {
          isQuery: true,
          queryId: uuidv4(),
          text: `extract "${column?.name}"`,
        };
        if (column?.description) {
          newBlock.text = `${newBlock.text}, with description "${column?.description}"`;
        }
        if (column?.action) {
          newBlock.text = `${newBlock.text}, with action "${column?.action}"`;
        }
        if (column?.prediction) {
          newBlock.text = `${newBlock.text}, with prediction "${column?.prediction}"`;
        }
        blocks.push(newBlock);
      });

    const newWordDocBody = {
      content: {
        blocks,
      },
    };
    patchWordDoc(wordDocId, {}, newWordDocBody);
  }, [JSON.stringify(sourceRecordsColumns)]);

  const doPopulateSigmas = async () => {
    const { data } = await getSigmas({ templateId: wordDocId });

    const records = data || [];
    const recordBeingRefreshed = records?.find(
      sigma => sigma?.isBeingRefreshed
    );
    const beingRefreshedEmailId = recordBeingRefreshed?.emailId;
    const freshRecord = records?.find(
      sigma =>
        sigma?.emailId === beingRefreshedEmailId && !sigma?.isBeingRefreshed
    );

    if (freshRecord) {
      const { data } = await patchSigma(
        recordBeingRefreshed?._id,
        {},
        { isDeleted: true }
      );
      setSigmas(records?.filter(sigma => sigma?._id !== data?._id));
      return;
    }

    setSigmas(records);
  };

  const doPatchSigmaRecord = async (id, newFields) => {
    await patchSigma(id, {}, newFields);
    await doPopulateSigmas();
    await doCalculateResults();
  };

  const doPopulateTemplateConfig = async () => {
    const { data: dashConfig } = await getTemplateDashboardConfig({
      wordDocId,
    });

    if (!dashConfig?.config?.layout) {
      const { data: initialWordDoc } = await getWordDoc(wordDocId);
      const sourceRecordsColumns =
        initialWordDoc?.content?.dashboardConfig?.columns;
      const newConfig = {
        topLayout: TOP_LAYOUT,
        layout: [
          [".", "."],
          [".", "."],
          ["source_records", "source_records"],
        ],
        components: [
          {
            id: "source_records",
            name: "Your Records",
            columns: [
              // {
              //   name: "emailSubject",
              // },
              // {
              //   name: "receivedAt",
              // },
              ...sourceRecordsColumns,
            ],
            pythonCode: "print(input())",
            records: [],
          },
        ],
      };
      const { data: newDashboardConfig } = await patchDashboardConfig(
        dashConfig?._id,
        {},
        {
          ...dashConfig,
          config: newConfig,
        }
      );
      setDashboardConfig(newDashboardConfig);
      setIsLoading(false);
      return;
    }

    setDashboardConfig(dashConfig);
    setIsLoading(false);
  };

  const doAddBlankComponent = async (x, y) => {
    setIsLoading(true);
    const id = uuidv4();
    const newComponent = {
      id,
      name: "",
      results: {},
    };

    const newLayoutDraggable = cloneDeep(
      dashboardConfig?.config?.layoutDraggable || {}
    );
    newLayoutDraggable[id] = { x, y, w: 20, h: 16 };
    // const newDashboardConfig = await patchDashboardConfig(
    //   dashboardConfig?._id,
    //   {},
    //   {
    //     ...dashboardConfig,
    //     config: {
    //       ...dashboardConfig?.config,
    //       components: [
    //         ...(dashboardConfig?.config?.components || []),
    //         newComponent,
    //       ],
    //       layoutDraggable: newLayoutDraggable,
    //     },
    //   }
    // );
    setDashboardConfig({
      ...dashboardConfig,
      config: {
        ...dashboardConfig?.config,
        components: [
          ...(dashboardConfig?.config?.components || []),
          newComponent,
        ],
        layoutDraggable: newLayoutDraggable,
      },
    });

    setInitiallyOpenComponentId(id);
    setIsLoading(false);
  };

  const doDeleteComponent = async id => {
    const payloadConfig = cloneDeep(dashboardConfig);
    payloadConfig.config.components = payloadConfig.config.components.filter(
      component => component?.id !== id
    );

    const newLayoutDraggable = cloneDeep(
      dashboardConfig?.config?.layoutDraggable || {}
    );
    delete newLayoutDraggable[id];
    payloadConfig.config.layoutDraggable = newLayoutDraggable;

    // payloadConfig.config.layout = payloadConfig.config.layout.map(row =>
    //   row.map(column => (column === id ? "." : column))
    // );
    // payloadConfig.config.topLayout = payloadConfig.config.topLayout.map(
    //   column => (column === id ? "." : column)
    // );

    const newDashboardConfig = await patchDashboardConfig(
      dashboardConfig?._id,
      {},
      payloadConfig
    );
    setDashboardConfig(newDashboardConfig?.data);
  };

  const updateComponent = (id, payload) => {
    const newDashboardConfig = cloneDeep(dashboardConfig);
    newDashboardConfig.config.components =
      newDashboardConfig.config.components.map(component => {
        if (component?.id === id) {
          return {
            ...component,
            ...payload,
          };
        }
        return component;
      });
    setDashboardConfig(newDashboardConfig);

    return newDashboardConfig;
  };

  const doPatchConfig = async payloadConfig => {
    setIsLoading(true);
    const newDashboardConfig = await patchDashboardConfig(
      dashboardConfig?._id,
      {},
      payloadConfig
    );
    setDashboardConfig(newDashboardConfig?.data);
    setIsLoading(false);
  };

  const doCalculateResults = async () => {
    setIsLoading(true);
    await postDashboardConfigCalculateResults(dashboardConfig?._id);
    await doPopulateTemplateConfig();
    setIsLoading(false);
  };

  const doRefreshSigmaRecord = async id => {
    const emailId = sigmas?.find(sigma => sigma?._id === id)?.emailId;

    await postReRunJobs(
      {},
      {
        ids: [emailId],
      }
    );
    await doPatchSigmaRecord(id, { isBeingRefreshed: true });
    await doPopulateSigmas();
  };

  const doInsertComponentInBlankSpace = async () => {
    if (!naturalLanguageCommand) {
      return;
    }
    let type = "Table";
    COMPONENT_TYPES?.forEach(componentType => {
      if (
        naturalLanguageCommand
          ?.toLowerCase()
          .includes(componentType?.toLowerCase())
      ) {
        type = componentType;
      }
    });

    const id = uuidv4();
    const cleanNlCommand = naturalLanguageCommand?.replaceAll(
      /, show as .*/g,
      ""
    );
    const newComponent = {
      id,
      name: "New component",
      naturalLanguageCommand: cleanNlCommand,
      type,
      pythonCode: "",
      results: {},
    };

    const { data } = await postGenerateComponent(
      {},
      {
        naturalLanguageCommand: getDsl(cleanNlCommand)?.replaceAll(
          "Received Time",
          "Received At"
        ),
      }
    );

    newComponent.pythonCode = data?.pythonCode;

    const newLayout = cloneDeep(dashboardConfig?.config?.layout);
    let hasBeenPlaced = false;
    newLayout.forEach(newRow => {
      newRow.forEach((newColumn, newColumnIndex) => {
        if (newColumn === "." && !hasBeenPlaced) {
          newRow[newColumnIndex] = id;
          hasBeenPlaced = true;
        }
      });
    });

    if (!hasBeenPlaced) {
      setMessage("No blank space to place component");
      return;
    }

    const newDashboardConfig = cloneDeep(dashboardConfig);
    newDashboardConfig.config.components.push(newComponent);
    newDashboardConfig.config.layout = newLayout;

    setNaturalLanguageCommand("");
    await doPatchConfig(newDashboardConfig);
    await doCalculateResults();
  };

  const selectedModel = MODEL_SELECT_OPTIONS.find(
    option => option?.value === selectedModelValue
  );

  return (
    <LayoutNew>
      <Container>
        <TopBar>
          <Title>
            <ShortenedText>
              {wordDoc?.fileName?.replace("template_", "")}
            </ShortenedText>
            <StyledFilesIcon
              height="32px"
              onClick={() => {
                navigator.clipboard.writeText(
                  `Pipeline: ${wordDoc?.fileName?.replace("template_", "")}`
                );
              }}
            />
          </Title>
          {/* <ButtonDark onClick={() => {}}>
            <DownloadCloudIcon />
          </ButtonDark> */}
          <div />
          {/* <IconButtonContainer>
            <HelpOutline />
          </IconButtonContainer> */}
          <div />

          <TooltipNew
            tipContent={
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "2px",
                }}
              >
                Choose the model
              </div>
            }
          >
            <TooltipClick
              position="bottom"
              tipContent={
                <OptionsContainer>
                  {MODEL_SELECT_OPTIONS.map(option => (
                    <Option
                      onClick={() => setSelectedModelValue(option?.value)}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {option?.label}
                      </div>
                      {selectedModelValue === option?.value ? (
                        <TickedIcon style={{ gridRow: "span 2" }} />
                      ) : (
                        <UntickedCircle />
                      )}
                      <div>{option?.description}</div>
                    </Option>
                  ))}
                </OptionsContainer>
              }
            >
              <SelectCustom>
                {selectedModel?.label} <KeyboardArrowDown />
              </SelectCustom>
            </TooltipClick>
          </TooltipNew>

          {/* <Select>
            <option>Boltzbit LLM v1.1</option>
            <option>Llama v1.2</option>
            <option>ChatGPT v1.2</option>
          </Select> */}

          {/* <JobsTooltipTrigger /> */}
          <StyledNavHeader />
        </TopBar>

        <TopMessageAndButton>
          <LeftContent>
            <div style={{ height: "30px" }} />
            {/* <ButtonDark>Paste in document</ButtonDark> */}
          </LeftContent>

          <RightContent>
            <ButtonDark to={`/trial-dashboards/${wordDocId}/source`}>
              <MonitorIcon />
              Source
            </ButtonDark>
          </RightContent>
        </TopMessageAndButton>
        <Gap height="0px" />

        <div
          style={{
            position: "relative",
            padding: "0 32px",
            paddingRight: "48px",
          }}
        >
          <PencilContainer
            onClick={() => setIsEditingDashboard(!isEditingDashboard)}
          >
            {isEditingDashboard ? (
              <CrossIcon height="14px" />
            ) : (
              <PencilIcon height="16px" />
            )}
          </PencilContainer>
          <GridDraggable
            isEditingDisabled={!isEditingDashboard}
            style={{
              height: "calc(100vh - 180px)",
            }}
            initialLayout={dashboardConfig?.config?.layoutDraggable}
            onDragEnd={async newLayout => {
              const newDashboardConfig = await patchDashboardConfig(
                dashboardConfig?._id,
                {},
                {
                  ...dashboardConfig,
                  config: {
                    ...dashboardConfig?.config,
                    layoutDraggable: newLayout,
                  },
                }
              );
              setDashboardConfig(newDashboardConfig?.data);
            }}
            onClickEmptyCell={({ x, y }) => doAddBlankComponent(x, y)}
          >
            {dashboardConfig?.config?.components
              ?.filter(comp => comp?.id !== "source_records")
              ?.map((component, index) => {
                // if (component?.id === "source_records") {
                //   return (
                //     <div key={component?.id}>
                //       <RecordsTable
                //         columns={component?.columns}
                //         onNewColumns={newColumns => {
                //           const newDashboardConfig = updateComponent(
                //             component?.id,
                //             {
                //               columns: newColumns,
                //             }
                //           );
                //           doPatchConfig(newDashboardConfig);
                //         }}
                //         records={sigmas?.filter(sig => !sig?.isDeleted)}
                //         onConfirmChangeRecord={async (id, newFields) => {
                //           await doPatchSigmaRecord(id, newFields);
                //         }}
                //         onClickRefreshRecord={id => doRefreshSigmaRecord(id)}
                //       />
                //     </div>
                //   );
                // }

                return (
                  <div key={component?.id}>
                    <RecordsSummaryChart
                      isEditingDisabled={!isEditingDashboard}
                      isInitiallyOpen={
                        component?.id === initiallyOpenComponentId
                      }
                      onClose={async () => {
                        if (
                          component?.id === initiallyOpenComponentId &&
                          !isEmpty(component?.results)
                        ) {
                          const newDashboardConfig = await patchDashboardConfig(
                            dashboardConfig?._id,
                            {},
                            dashboardConfig
                          );
                          setDashboardConfig(newDashboardConfig?.data);
                        }

                        if (
                          component?.id === initiallyOpenComponentId &&
                          isEmpty(component?.results)
                        ) {
                          const newDashboardConfig = cloneDeep(dashboardConfig);
                          newDashboardConfig.config.components =
                            newDashboardConfig.config.components.filter(
                              comp => comp?.id !== component?.id
                            );
                          const newLayoutDraggable = cloneDeep(
                            dashboardConfig?.config?.layoutDraggable || {}
                          );
                          delete newLayoutDraggable[component?.id];
                          newDashboardConfig.config.layoutDraggable =
                            newLayoutDraggable;

                          setDashboardConfig(newDashboardConfig);
                        }

                        setInitiallyOpenComponentId(null);
                      }}
                      type={component?.type}
                      records={component?.results?.records}
                      columns={component?.results?.columns}
                      backgroundColor={component?.backgroundColor}
                      name={component?.name}
                      onNewName={async newName => {
                        const newDashboardConfig = updateComponent(
                          component?.id,
                          {
                            name: newName,
                          }
                        );
                        await doPatchConfig(newDashboardConfig);
                      }}
                      naturalLanguageCommand={component?.naturalLanguageCommand}
                      pythonCode={component?.pythonCode}
                      onConfirmEdit={async newComponent => {
                        const newDashboardConfig = updateComponent(
                          component?.id,
                          newComponent
                        );
                        await doPatchConfig(newDashboardConfig);
                        await doCalculateResults();
                      }}
                      onClickCross={() => doDeleteComponent(component?.id)}
                    />
                  </div>
                );
              })}
          </GridDraggable>
        </div>

        {/* <Gap height="100px" /> */}
        <ErrorMessageModal
          color="#d4a600"
          message={message}
          onDismiss={() => setMessage("")}
        />
      </Container>
    </LayoutNew>
  );
};

export default TrialDashboardPage;
