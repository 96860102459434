import { apiGetRawResponse } from "api/api-http-methods";
import {
  zapiDelete,
  zapiGet,
  zapiPatch,
  zapiPost,
  zapiPostFile,
} from "api/zapi";

export const postExcelModelUploadFile = async file => {
  const { data, error } = await zapiPostFile(
    `/solutions/ocr/api/v1/excel-models/upload`,
    {},
    file,
    () => {},
    "excelFile"
  );
  return { data, error };
};

export const triggerDownloadOfExcelFile = async excelModelId => {
  const res = await apiGetRawResponse(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/download`
  );
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const contentDispositionHeader = res?.headers.get("Content-Disposition");
  const fileName = contentDispositionHeader?.split("filename=")?.[1];

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = fileName || "file.xlsx";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const patchExcelModelSave = async id => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/excel-models/${id}/save`
  );
  return { data, error };
};

export const postExcelModelOpen = async id => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/excel-models/${id}/open`
  );
  return { data, error };
};

export const postExcelModelDraft = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/excel-models/draft`,
    params,
    body
  );
  return { data, error };
};

export const getExcelModelStatus = async excelModelId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/status`
  );
  return { data, error };
};

export const getExcelModelLoading = async excelModelId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/loading`
  );
  return { data, error };
};

export const getExcelModelSheetNames = async excelModelId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets`
  );
  return { data, error };
};

export const getExcelModelByIdAndSheetName = async (
  excelModelId,
  sheetName,
  params = {}
) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets/${sheetName}`,
    params
  );
  return { data, error };
};

export const getExcelModelByIdAndSheetNameCachedData = async (
  excelModelId,
  sheetName,
  params = {}
) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets/${sheetName}/cached-data`,
    params
  );
  return { data, error };
};

export const getExcelModelSheetGrid = async (
  excelModelId,
  sheetName,
  params = {}
) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets/${sheetName}/grid`,
    params
  );
  return { data, error };
};

export const patchExcelModelSheetGrid = async (
  excelModelId,
  sheetName,
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets/${sheetName}/grid`,
    params,
    body
  );
  return { data, error };
};

export const getExcelModels = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models`,
    params
  );
  return { data, error };
};

export const postExcelModel = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/excel-models`,
    params,
    body
  );
  return { data, error };
};

export const getExcelModelById = async (id, params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${id}`,
    params
  );
  return { data, error };
};

export const patchExcelModelById = async (id, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/excel-models/${id}/window`,
    params,
    body
  );
  return { data, error };
};

export const patchExcelModelMl = async (id, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/excel-models/${id}/window/ml`,
    params,
    body
  );
  return { data, error };
};

export const deleteExcelModelById = async id => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/excel-models/${id}`
  );
  return { data, error };
};

export const getGenerateExcelModelFromTable = async params => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/generate-excel-model`,
    params
  );
  return { data, error };
};

export const getExcelModelSheetSyncStatus = async (
  excelModelId,
  sheetIndex
) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets/${sheetIndex}/sync-status`
  );
  return { data, error };
};

export const patchExcelModelSheetSync = async (excelModelId, sheetIndex) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/sheets/${sheetIndex}/sync`
  );
  return { data, error };
};

export const deleteUnloadExcelModel = async excelModelId => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/unload`
  );
  return { data, error };
};

export const postExcelFlowGenerate = async (
  excelModelId,
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/chat-service/api/v1/excelflow/generate/${excelModelId}`,
    params,
    body
  );
  return { data, error };
};

export const deleteSheet = async (excelModelId, params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/excel-models/${excelModelId}/delete-sheet`,
    params
  );
  return { data, error };
};
