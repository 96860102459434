import { ChangeHistory } from "@material-ui/icons";
import { format } from "date-fns";
import { range } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ALTERNATE_COLORS = "columns";

const safeFormat = (date, formatString) => {
  try {
    return format(new Date(date), formatString);
  } catch {
    return "";
  }
};

const greyBgroundIfOdd = props => {
  if (ALTERNATE_COLORS === "columns" && props.col % 2 === 0) {
    return `background-color: ${props.theme.color.closer1}55;`;
  }
};

const getAllKeysFromObjects = objects => {
  const allKeys = objects?.map(object => Object?.keys(object))?.flat();
  return [...new Set(allKeys)];
};

const TableContainer = styled.div`
  overflow-x: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.color.closer1};
  border-left: 1px solid ${props => props.theme.color.closer1};
`;

const Triangle = styled(ChangeHistory)`
  opacity: ${props => (props.$isVisible ? 1 : 0)};
  transition: opacity 0.2s !important;
  height: 18px !important;
  transform: ${props => (props.direction === "asc" ? `rotate(0)` : `rotate(180deg)`)};
`;

const ColHeader = styled.div`
  font-weight: 600;
  grid-row: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1}99;
    ${Triangle} {
      opacity: 1;
    }
  }

  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  ${greyBgroundIfOdd};
`;

const DataCell = styled.div`
  display: grid;
  padding: 10px;
  overflow: auto;
  font-size: 10px;

  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  ${greyBgroundIfOdd}

  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.primary}22;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numCols}, 110px);
`;

const displayColNameMap = {
  time: "Time",
  senderEmail: "Sender Email",
  senderName: "Sender",
  entityName: "Entity",
  traderName: "Trader",
  isin: "ISIN",
  securityName: "Security",
  maturity: "Maturity",
  currency: "Currency",
  bidSize: "Bid Size",
  bidPrice: "Bid Price",
  bidSpread: "Bid Spread",
  askSize: "Ask Size",
  askPrice: "Ask Price",
  askSpread: "Ask Spread",
};

const TableSortable = ({ records, className, sortByColumn, onClickSort, columnsToInclude }) => {
  const colNames = getAllKeysFromObjects(records?.map(record => record?.row))?.filter(colName =>
    columnsToInclude?.includes(colName)
  );

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <TableContainer className={className}>
      <Table numCols={colNames.length + 1}>
        <Row numCols={colNames.length + 1}>
          {colNames.map((colName, colNameIndex) => (
            <ColHeader
              key={`${colName}-colheader`}
              col={colNameIndex + 1}
              row={1}
              onClick={() => {
                onClickSort(colName, sortByColumn?.direction === "asc" ? "desc" : "asc");
              }}
            >
              {displayColNameMap[colName]}
              <Triangle $isVisible={sortByColumn?.colName === colName} direction={sortByColumn?.direction} />
            </ColHeader>
          ))}
        </Row>
        {range(0, records.length)?.map(rowIndex => {
          const record = records[rowIndex];

          return (
            <Row numCols={colNames.length + 1}>
              {colNames.map((colName, colNameIndex) => {
                return (
                  <DataCell
                    key={`${colNameIndex}-${rowIndex}`}
                    row={rowIndex + 2}
                    col={colNameIndex + 1}
                    onClick={() => {
                      searchParams?.set("fileIdToView", record?.documentLocation?.fileId);
                      searchParams?.set("rowIdToHighlight", record?.documentLocation?.rowId);
                      navigate(`?${searchParams.toString()}`);
                    }}
                  >
                    {colName === "time"
                      ? safeFormat(new Date(record?.row?.time), "yyyy-MM-dd HH:mm:ss")
                      : record?.row[colName]}
                  </DataCell>
                );
              })}
            </Row>
          );
        })}
      </Table>
    </TableContainer>
  );
};

export default TableSortable;
