import styled from "styled-components";
import { useState } from "react";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SelectInput from "components/ui/SelectInput";
import { useEffect } from "react";
import { Loyalty } from "@material-ui/icons";
import Tooltip from "components/ui/Tooltip";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
  overflow: auto;

  display: grid;
  align-content: start;
  justify-content: start;
  align-items: center;
  gap: 10px;
  grid-template-columns: auto 1fr;
`;

const SelectedValue = styled.div`
  width: 200px;
  overflow: auto;
  font-size: 20px;
  color: ${props => props.theme.color.primary};
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
  grid-column: span 2;
`;

const NameText = styled.div``;

const TextInputSquareStyled = styled(TextInputSquare)`
  label {
    padding: 5px 15px;
  }
`;

const CreateButton = styled(Button)`
  justify-self: start;
  align-self: end;
  grid-template-columns: auto;
  gap: 0;
  margin-top: 20px;
`;

const ValueTypeText = styled.div``;

const ValueTypeInput = styled(SelectInput)``;

const TriggerButtonContainer = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const SeeMoreLink = styled.div`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  color: ${props => props.theme.color.primary};
  grid-column: span 2;
`;

const BottomAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  ${props => props.isTooltipRightAligned && "transform: translateX(calc(-100% + 15px)) translateY(10%);"}
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const AddCellLabelExpandableModalTrigger = ({
  isDisabled,
  selectedValue,
  onPartialAnnotationAdded = ({ tableName, rowName, colName, label, cellType }) => {},
  isTooltipRightAligned = false,
  initialPartialAnnotation = { tableName: "", rowName: "", colName: "", label: "", cellType: "DATA" },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [tableName, setTableName] = useState("");
  const [rowName, setRowName] = useState("");
  const [colName, setColName] = useState("");
  const [label, setLabel] = useState("");
  const [cellType, setCellType] = useState("DATA");

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
    setTableName(initialPartialAnnotation.tableName);
    setRowName(initialPartialAnnotation.rowName);
    setColName(initialPartialAnnotation.colName);
    setCellType(initialPartialAnnotation.cellType || "DATA");
  }, [JSON.stringify(initialPartialAnnotation)]);

  return (
    <>
      <BottomAlignedTooltip
        isTooltipRightAligned={isTooltipRightAligned}
        title={isDisabled ? "Select a cell to label it" : "Add label"}
      >
        <TriggerButtonContainer onClick={() => setIsOpen(true)} isDisabled={isDisabled}>
          <Loyalty />
        </TriggerButtonContainer>
      </BottomAlignedTooltip>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Add label</Title>

          <NameText>Cell text</NameText>
          <SelectedValue>{selectedValue}</SelectedValue>

          <NameText>Label</NameText>
          <TextInputSquareStyled value={label} onNewInput={newLabel => setLabel(newLabel)} />

          <ValueTypeText>Cell Type</ValueTypeText>
          <ValueTypeInput value={cellType} onSetNewValue={newCellType => setCellType(newCellType)}>
            <option>DATA</option>
            <option>HEADER</option>
          </ValueTypeInput>

          <SeeMoreLink onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "See less" : "See more"}</SeeMoreLink>
          {isExpanded && (
            <>
              <div>Table name</div>
              <TextInputSquareStyled value={tableName} onNewInput={newTableName => setTableName(newTableName)} />

              <div>Row name</div>
              <TextInputSquareStyled value={rowName} onNewInput={newRowName => setRowName(newRowName)} />

              <div>Column name</div>
              <TextInputSquareStyled value={colName} onNewInput={newColumnName => setColName(newColumnName)} />
            </>
          )}

          <CreateButton
            value="Add label"
            variant="highlighted"
            onClick={() => {
              const partialAnnotation = {
                tableName,
                rowName,
                colName,
                label,
                cellType,
              };
              onPartialAnnotationAdded(partialAnnotation);
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCellLabelExpandableModalTrigger;
