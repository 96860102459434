import { zapiGet } from "api/zapi";
import { useState, useEffect } from "react";

const useResource = ({
  url = "",
  params = {},
  pollInterval = 0,
  stopPollIf = data => true,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [pollIntervalId, setPollIntervalId] = useState(null);

  const doPopulateData = async () => {
    const { data: apiData, error: apiError } = await zapiGet(url, params);
    setData(apiData);
    setError(apiError);
  };

  useEffect(() => {
    if (!url) {
      setData(null);
      return;
    }
    setData(null);
    doPopulateData();
  }, [url, JSON.stringify(params)]);

  useEffect(() => {
    if (!url || !pollInterval) {
      return;
    }
    const intervalId = setInterval(doPopulateData, pollInterval);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [url, JSON.stringify(params)]);

  useEffect(() => {
    if (stopPollIf(data) || !!error) {
      clearInterval(pollIntervalId);
    }
  }, [JSON.stringify(data), JSON.stringify(error)]);

  return [data];
};

export default useResource;
