import { set, transform, uniq } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Visibility } from "@material-ui/icons";
import {
  deleteSigmasV2,
  getPipelineConfig,
  getSigmasV2,
  postSigmasFillupData,
  postSigmasV2,
  putPipelineConfig,
  putSigmasV2,
} from "api/backend/fileSystemEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import GridDraggable from "components/GridDraggable";
import InputWithEditMode from "components/InputWithEditMode";
import LayoutApp from "components/LayoutApp";
import ReferenceModalSpanWithContext from "components/ReferenceModalSpanWithContext";
import ButtonWord from "components/ui/ButtonWord";
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "components/ui/Icons";
import InputWithEditModeTableColumn from "components/InputWithEditModeTableColumn";
import {
  BinIcon,
  EllipsisIcon,
  LinkWithStarIcon,
  MoveIcon,
  PencilIconDetailed,
} from "components/IconsNew";
import { getColorFromString, isTruthy } from "utils/common";
import EditPipelineModal from "components/EditPipelineModal";
import WordDocArea from "components/WordDocArea";
import { getWordDoc, postWordDoc } from "api/backend/wordDocsEndpoints";
import TooltipClick from "components/ui/TooltipClick";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import ReferenceDebugModal from "components/ReferenceDebugModal";
import ExcelArea from "components/ExcelArea";
import SlideDocArea from "components/SlideDocArea";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100%;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const SideNav = styled.div`
  z-index: 1;
  overflow: auto;
  display: grid;
  grid-template-rows: 54px 1fr auto;
  position: relative;
`;

const RecordFields = styled.div`
  padding: 20px;
  padding-top: 60px;
  display: flex;
  gap: 20px;
  position: relative;
`;

const GroupCard = styled.div`
  background-color: #ffffff;
  padding: 0px;
  border-radius: 10px;
  height: 100%;
  align-content: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  border: 1px solid #f1f1f1;
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  background-color: ${props => props.color || "#f3f5f7"}22;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #f1f1f1;
  white-space: nowrap;
  grid-column: span 2;
`;

const GroupFields = styled.div`
  padding: 10px;
  align-content: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const Field = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;
  color-scheme: dark;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldLabel = styled.div`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 16px;
  }
`;

const SideNavItem = styled.div`
  padding: 12px;
  margin: 5px 10px;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  cursor: pointer;
  ${props => props.isSelected && "background-color: #E8ECEFBF;"}
  :hover {
    background-color: #e8ecefbf;
  }
`;

const SimpleButton = styled.button`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  outline: none;
  font-weight: 500;

  color: #b9b9b9;
  svg {
    height: 14px;
    fill: #b9b9b9;
  }

  :hover {
    background-color: #b9b9b9;
    color: white;
    svg {
      fill: white;
    }
  }

  ${props =>
    props.isPrimary &&
    `
    background-color: #0191FF; 
    color: white;
    border: 1px solid #0191FF;
    :hover {
      background-color: #0191FF;
      color: white;
    }
    `}
`;

const StyledButtonWord = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 8px;
  svg {
    fill: white;
    height: 14px;
  }
`;

const TopBar = styled.div`
  position: relative;
  height: 54px;
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  box-shadow: 0px 12px 40px -12px #0000000f;

  border-left: 1px solid #e8ecef;
  border-bottom: 1px solid #e8ecef;
`;

const TitleInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;
  color-scheme: dark;

  font-size: 24px;
  font-weight: 700;
  width: 300px;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledSelect = styled.select`
  height: 32px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const Tabs = styled.div`
  display: flex;
  gap: 10px;
`;

const Tab = styled.div`
  padding: 4px 16px;
  border-bottom: 2px solid ${props => (props.isSelected ? "#0191ff" : "white")};
  cursor: pointer;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const GridContainer = styled.div`
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);
  box-shadow: 0px 24px 60px 0px #0000001a;
  border-left: 1px solid #e8ecef;
`;

const AppNameText = styled.div`
  font-weight: 600;
  color: #b9b9b9;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterContainer = styled.div`
  padding: 10px;
  background-color: white;
  position: sticky;
  top: 0;
`;

const FilterInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const ToggleDiv = styled.div`
  border: 1px solid #424242;
  color: #424242;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    fill: #424242;
  }
  :hover {
    background-color: #424242;
    color: white;
    svg {
      fill: white;
    }
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #424242; 
    color: white;
    svg {
      fill: white;
    }
    `}
`;

const DATA_TYPE_TO_INPUT_TYPE = {
  TEXT: "text",
  NUMBER: "number",
  DATETIME: "datetime-local",
  TABLE: "table",
};

const INITIAL_COLS = 2;
const INITIAL_W = 24;
const INITIAL_H = 14;

const getKeyHeight = (groupName, columns) => {
  const groupColumns = columns.filter(col => col?.name?.startsWith(groupName));
  const nonTableCount = groupColumns?.filter(
    col => col?.type !== "TABLE"
  )?.length;
  const tableCount = groupColumns?.filter(col => col?.type === "TABLE")?.length;

  const h = (nonTableCount * 3) / 2 + tableCount * 9;
  return Math.ceil(h) + 3;
};

const getInitialLayoutFromColumns = ({ keys = [], columns = [] }) => {
  let layout = {};

  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  keys.filter(isTruthy).forEach(key => {
    layout[key] = { x, y, w, h: getKeyHeight(key, columns) };
    x += w + 1;
    if (x >= INITIAL_COLS * w) {
      x = 1;
      y += h + 1;
    }
  });

  return layout;
};

const AppRecordPage = () => {
  const navigate = useNavigate();
  const { pipelineConfigId, selectedRecordIdStr } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [fields, setFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingLayout, setIsEditingLayout] = useState(false);
  const [isFillingUp, setIsFillingUp] = useState(false);
  const [filterStr, setFilterStr] = useState("");

  const tableId = pipelineConfig?.sourceTables?.[0]?.id;
  const selectedRecordId = parseInt(selectedRecordIdStr);

  const selectedRecord = sigmaRecords?.find(
    record => record.id === selectedRecordId
  );

  const [selectedTab, setSelectedTab] = useState("fields");

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  useEffect(() => {
    if (!pipelineConfigId || !tableId) {
      return;
    }
    doPopulateSigmaRecords(pipelineConfigId, tableId);
    const intervalId = setInterval(() => {
      doPopulateSigmaRecords(pipelineConfigId, tableId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [pipelineConfigId, tableId, selectedRecordIdStr]);

  const doFillUp = async () => {
    setIsFillingUp(true);
    await postSigmasFillupData(selectedRecordId, {
      pipelineId: pipelineConfigId,
      tableName: tableId,
      gpt_assistance: true,
    });
    setIsFillingUp(false);
  };

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const doPopulateSigmaRecords = async (pipelineId, tableName) => {
    const { data } = await getSigmasV2({
      pipelineId,
      tableName,
    });
    setSigmaRecords(data || []);
    if (!selectedRecordIdStr) {
      navigate(`/apps/${pipelineConfigId}/records/${data?.[0]?.id}`);
    }
  };

  const doPutSelectedSigmaRecord = async payloadFields => {
    setIsLoading(true);
    const { data } = await putSigmasV2(
      selectedRecordId,
      {},
      {
        ...selectedRecord,
        fields: payloadFields,
      }
    );
    setIsLoading(false);
    setFields(data?.fields || {});
    doPopulateSigmaRecords(pipelineConfigId, tableId);
  };

  const doPostEmptyRecord = async () => {
    setIsLoading(true);

    const wordDocColumns = pipelineConfig?.sourceTables?.[0]?.columns?.filter(
      column => column?.groupName === "File Templates"
    );
    let wordDocFields = {};
    const wordDocPromises = wordDocColumns?.map(async column => {
      const { data: templateWordDoc } = await getWordDoc(column?.description);
      const { data: wordDoc } = await postWordDoc(
        {},
        {
          fileName: column?.name,
          content: templateWordDoc?.content,
        }
      );
      wordDocFields[column.name] = {
        value: wordDoc.id,
      };
    });
    await Promise.all(wordDocPromises);

    const { data } = await postSigmasV2(
      {},
      {
        pipelineId: pipelineConfig?.id,
        tableName: pipelineConfig?.sourceTables?.[0]?.id,
        fields: {
          [recordNameColumn?.name]: {
            value: "Blank Record",
          },
          ...wordDocFields,
        },
      }
    );
    setIsLoading(false);
    navigate(`/apps/${pipelineConfigId}/records/${data?.id}`);
  };

  const doPutPipelineConfigLayout = async newLayout => {
    await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...pipelineConfig.meta,
          layout: newLayout,
        },
      }
    );
  };

  const doPutPipelineConfigPlotState = async newPlotStateMap => {
    await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...pipelineConfig.meta,
          plotStateMap: newPlotStateMap,
        },
      }
    );
  };

  const doDeleteSelectedSigmaRecord = async () => {
    await deleteSigmasV2(selectedRecordId, {
      tableName: tableId,
      pipelineID: pipelineConfigId,
    });
    const nextId = sigmaRecords?.find(r => r.id !== selectedRecordId)?.id;
    await doPopulateSigmaRecords(pipelineConfigId, tableId);
    navigate(`/apps/${pipelineConfigId}/records/${nextId}`);
  };

  // USER UPDATES
  useEffect(() => {
    setFields(selectedRecord?.fields || {});
  }, [JSON.stringify(selectedRecord)]);

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];
  const groupNames = uniq(columns?.map(column => column.groupName));
  const recordNameColumn = columns?.find(column => !column?.groupName);

  // sort by createdAt
  const sigmaRecordsMostRecentFirst = sigmaRecords
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    ?.filter(record => {
      return record?.fields?.[recordNameColumn?.name]?.value
        ?.toLowerCase()
        ?.includes(filterStr?.toLowerCase());
    });

  const initialLayout = getInitialLayoutFromColumns({
    keys: groupNames?.filter(groupName => !!groupName),
    columns,
  });

  const wordDocColumnNames = columns
    ?.filter(column => column?.groupName === "File Templates")
    ?.map(column => column?.name);

  return (
    <LayoutApp>
      <Container isDisabled={isLoading}>
        <SideNav>
          <div style={{ padding: 20, borderBottom: "1px solid #E8ECEF" }}>
            <AppNameText>{pipelineConfig?.name}</AppNameText>
          </div>
          <div style={{ overflow: "auto" }}>
            <FilterContainer>
              <FilterInput
                placeholder="Filter records"
                value={filterStr}
                onChange={e => setFilterStr(e.target.value)}
              />
            </FilterContainer>
            {sigmaRecordsMostRecentFirst.map(record => (
              <SideNavItem
                isSelected={record.id === selectedRecordId}
                onClick={() =>
                  navigate(`/apps/${pipelineConfigId}/records/${record?.id}`)
                }
              >
                <div>
                  {record?.fields?.[recordNameColumn?.name]?.value || record.id}
                </div>
                {record.id === selectedRecordId && (
                  <div style={{ paddingTop: 10, display: "flex", gap: 10 }}>
                    <AddSigmaRecordModalNew
                      recordId={record.id}
                      style={{ width: "max-content" }}
                      trigger={<SimpleButton isPrimary>Ingest</SimpleButton>}
                      pipelineConfig={pipelineConfig}
                    />
                    <SimpleButton>
                      <PencilIconDetailed />
                    </SimpleButton>
                    <SimpleButton onClick={doDeleteSelectedSigmaRecord}>
                      <BinIcon />
                    </SimpleButton>
                  </div>
                )}
              </SideNavItem>
            ))}

            {/* <StyledPlusIcon
              onClick={doPostEmptyRecord}
              style={{ margin: "8px 20px" }}
            /> */}
          </div>

          <div style={{ borderTop: "1px solid #E8ECEF", padding: "10px 20px" }}>
            <AddSigmaRecordModalNew
              doPostEmptyRecord={doPostEmptyRecord}
              trigger={
                <ButtonWord style={{ width: "100%", height: "38px" }} isPrimary>
                  + Add record
                </ButtonWord>
              }
              pipelineConfig={pipelineConfig}
            />
          </div>
        </SideNav>
        <div style={{ width: "100%" }}>
          <TopBar>
            <div style={{ width: 300, display: "flex", gap: 10 }}>
              <InputWithEditMode
                style={{ fontSize: 18, fontWeight: 600 }}
                value={fields?.[recordNameColumn?.name]?.value}
                rawValue={fields?.[recordNameColumn?.name]?.value}
                onPressSave={(newValue, fieldPlotState) => {
                  const newFields = {
                    ...fields,
                    [recordNameColumn?.name]: {
                      ...fields?.[recordNameColumn?.name],
                      value: newValue,
                    },
                  };
                  setFields(newFields);
                  doPutSelectedSigmaRecord(newFields);
                }}
              />

              <ReferenceModalSpanWithContext
                isDisabled={!fields?.[recordNameColumn?.name]?.reference}
                isTooltipDisabled
                contentHtml={""}
                metas={[fields?.[recordNameColumn?.name]?.reference]}
              >
                <LinkWithStarIcon
                  style={{
                    fill: fields?.[recordNameColumn?.name]?.reference
                      ? "#0191ff"
                      : "#ccc",
                    marginTop: 2,
                  }}
                />
              </ReferenceModalSpanWithContext>
              <ReferenceDebugModal
                reference={fields?.[recordNameColumn?.name]?.reference}
              />
            </div>
            <div />
            <TooltipClick
              position="bottom"
              tipContent={
                <div
                  style={{
                    display: "grid",
                    gap: 10,
                    border: "1px solid #ccc",
                    padding: 10,
                    width: 150,
                  }}
                >
                  <ButtonWord
                    disabled={isLoading || !selectedRecordId}
                    onClick={doDeleteSelectedSigmaRecord}
                  >
                    Delete
                  </ButtonWord>
                  <ButtonWord disabled={isFillingUp} onClick={doFillUp}>
                    Fill up
                  </ButtonWord>
                </div>
              }
            >
              <EllipsisIcon style={{ cursor: "pointer" }} />
            </TooltipClick>
            <JobsTooltipTrigger />
          </TopBar>
          {pipelineConfig?.meta?.type === "file" && (
            <Tabs>
              <Tab
                isSelected={selectedTab === "fields"}
                onClick={() => setSelectedTab("fields")}
              >
                Fields
              </Tab>
              {wordDocColumnNames?.map(wordDocColumnName => (
                <Tab
                  isSelected={selectedTab === wordDocColumnName}
                  onClick={() => setSelectedTab(wordDocColumnName)}
                >
                  {wordDocColumnName?.replace("File Templates_", "")}
                </Tab>
              ))}
              {/* <Tab
                isSelected={selectedTab === "excel"}
                onClick={() => setSelectedTab("excel")}
              >
                excel
              </Tab>
              <Tab
                isSelected={selectedTab === "ppt"}
                onClick={() => setSelectedTab("ppt")}
              >
                ppt
              </Tab> */}
            </Tabs>
          )}
          <div
            style={{
              display: !["fields", "excel", "ppt"]?.includes(selectedTab)
                ? "block"
                : "none",
              height: "calc(100vh - 40px - 60px - 24px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <WordDocArea
              wordDocId={selectedRecord?.fields?.[selectedTab]?.value}
              isFillingUp={isFillingUp}
            />
          </div>
          <div
            style={{
              display: selectedTab === "excel" ? "block" : "none",
              height: "calc(100vh - 40px - 60px - 18px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedTab === "excel" && (
              <ExcelArea excelModelId="66c87045ca6bb039caf9cb9f" />
            )}
          </div>
          <div
            style={{
              display: selectedTab === "ppt" ? "block" : "none",
              height: "calc(100vh - 40px - 60px - 18px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedTab === "ppt" && (
              <SlideDocArea slideDocId="65e8c619f751327fd7a210ee" />
            )}
          </div>
          <GridContainer
            style={{
              display: selectedTab === "fields" ? "block" : "none",
              overflow: "auto",
              width: "calc(100vw - 90px - 20px - 280px)",
              height:
                pipelineConfig?.meta?.type === "file"
                  ? "calc(100vh - 40px - 54px - 22px)"
                  : "calc(100vh - 40px - 60px)",
            }}
          >
            <GridDraggable
              style={{
                width: "2000px",
                height: "1300px",
              }}
              initialLayout={pipelineConfig?.meta?.layout || initialLayout}
              onDragEnd={newLayout => doPutPipelineConfigLayout(newLayout)}
              isEditingDisabled={!isEditingLayout}
            >
              {groupNames
                ?.filter(
                  groupName => !!groupName && groupName !== "File Templates"
                )
                ?.map(groupName => (
                  <GroupCard key={groupName}>
                    <GroupTitle color={getColorFromString(groupName)}>
                      {groupName}
                    </GroupTitle>
                    <GroupFields>
                      {columns
                        ?.filter(column => column.groupName === groupName)
                        ?.map(column => {
                          const plotStateMap =
                            pipelineConfig?.meta?.plotStateMap || {};
                          const onPressSave = (newValue, fieldPlotState) => {
                            const newFields = {
                              ...fields,
                              [column.name]: {
                                ...fields?.[column.name],
                                value: newValue,
                              },
                            };

                            if (fieldPlotState) {
                              const newPlotStateMap = {
                                ...plotStateMap,
                                [column?.name]: fieldPlotState,
                              };
                              doPutPipelineConfigPlotState(newPlotStateMap);
                            }

                            setFields(newFields);
                            doPutSelectedSigmaRecord(newFields);
                          };

                          const contentHtml = `
                        <div style="font-weight: 600;">${column?.name?.replaceAll(
                          /_/g,
                          " / "
                        )}</div>
                        <div style="font-weight: 400; line-height: 1.5;">${
                          fields?.[column.name]?.value
                        }</div>
                      `;

                          return (
                            <Field
                              style={{
                                gridColumn:
                                  column?.type === "TABLE" ? "span 2" : "auto",
                              }}
                            >
                              <FieldLabel>
                                {column.name?.replaceAll(/.*_/g, "")}
                                <ReferenceModalSpanWithContext
                                  isDisabled={!fields?.[column.name]?.reference}
                                  isTooltipDisabled
                                  contentHtml={contentHtml}
                                  metas={[fields?.[column.name]?.reference]}
                                >
                                  <LinkWithStarIcon
                                    style={{
                                      fill: fields?.[column.name]?.reference
                                        ? "#0191ff"
                                        : "#ccc",
                                      marginTop: 2,
                                    }}
                                  />
                                </ReferenceModalSpanWithContext>
                                <ReferenceDebugModal
                                  reference={fields?.[column.name]?.reference}
                                />
                              </FieldLabel>
                              {column?.type === "TABLE" && (
                                <InputWithEditModeTableColumn
                                  initialPlotState={
                                    plotStateMap?.[column?.name]
                                  }
                                  tableColumns={column?.tableColumns}
                                  value={fields?.[column.name]?.value}
                                  onPressSave={onPressSave}
                                />
                              )}
                              {column?.type !== "TABLE" && (
                                <InputWithEditMode
                                  type={DATA_TYPE_TO_INPUT_TYPE?.[column.type]}
                                  value={fields?.[column.name]?.value}
                                  rawValue={
                                    fields?.[column.name]?.rawValue || ""
                                  }
                                  onPressSave={onPressSave}
                                />
                              )}
                            </Field>
                          );
                        })}
                    </GroupFields>
                  </GroupCard>
                ))}
            </GridDraggable>
          </GridContainer>
        </div>

        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            display: selectedTab === "fields" ? "flex" : "none",
            gap: 10,
            zIndex: 1,
          }}
        >
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            <MoveIcon />
            Rearrange
          </ToggleDiv>
          {/* <StyledButtonWord
            disabled={isLoading || !selectedRecordId}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            {isEditingLayout ? <CrossIcon /> : <PencilIcon />}
          </StyledButtonWord> */}
        </div>
      </Container>
    </LayoutApp>
  );
};

export default AppRecordPage;
