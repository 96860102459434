import {
  Code,
  Folder,
  FolderSpecial,
  Functions,
  Search,
} from "@material-ui/icons";
import Tooltip from "components/ui/Tooltip";
import SearchInput from "components/widgets/SearchInput";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import NavHeader from "./NavHeader";
import {
  BoltzbitLogo,
  BoltzhubLogoInner,
  ChatIcon,
  DatasetIcon,
  EmailIcon,
  ExcelIcon,
  FilesIcon,
  GearIcon,
  InternetIcon,
  Logo,
  LogoInner,
  PdfIcon,
  PlusIcon,
  SigmaIcon,
  SignUpIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import ItemListPopover from "components/ItemListPopover";
import { useState } from "react";
import { postWordDoc } from "api/services/wordDocsService";
import { postExcelModelDraft } from "api/services/excelModelsService";
import { postBulkUploadFormData } from "api/services/filesService";
import { postSlideDocsUpload } from "api/services/slideDocsService";
import NewDocConfigModalTrigger from "components/NewDocConfigModalTrigger";
import TooltipNew from "components/ui/TooltipNew";
import EmailPasswordModal from "components/EmailPasswordModal";
import { files } from "jszip";
import useSearchParamsState from "hooks/useSearchParamsState";

const NAV_HEIGHT = 61;

const LogoAndTitleLink = styled(Link)`
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  ${props =>
    props.isHidden &&
    `
  opacity: 0;
  pointer-events: none;
  `};
`;

const LogoImg = styled.img`
  width: 100%;
`;

const NavItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closer2};
  border-radius: 20px;
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  svg {
    fill: ${props => props.theme.color.closer2};
    height: 20px;
    width: 20px;
  }
`;

const CenteredContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr ${props => props.centerColumnMaxWidth} 1fr;
  }
  min-height: 100vh;
`;

const Content = styled.div`
  padding-top: ${props => NAV_HEIGHT + props.contentTopPadding || 40}px;
  grid-column: 2;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"};
`;

const CenteredContentContainerWide = styled.div`
  display: grid;
  grid-template-columns: ${props => props.sideGapWidth} 1fr ${props =>
      props.sideGapWidth};
  min-height: 100vh;
`;

const ContentWide = styled.div`
  padding-top: ${NAV_HEIGHT}px;
  grid-column: 2;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
`;

export const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftWidth || "300px"} ${props =>
      props.rightWidth || "1fr"};
  gap: 40px;
`;

export const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftWidth || "1fr"} ${props =>
      props.middleWidth || "1fr"} ${props => props.rightWidth || "1fr"};
  gap: 40px;
`;

export const Gap = styled.div`
  height: ${props => props.height || "20px"};
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

const FunctionsBtn = styled(Functions)`
  fill: ${props => props.theme.color.closer2} !important;
`;

const TopNavContainer = styled.div`
  background-color: ${props => props.theme.color.furthest};
  z-index: 100;
  width: calc(100% - 130px);
  position: fixed;
  top: 0;
  display: grid;
  grid-template-columns: 102px 1fr repeat(7, auto);
  padding: 10px 32px;
  padding-right: 0px;
  padding-left: 16px;
  height: ${NAV_HEIGHT}px;
  align-items: center;
  /* border-bottom: 1px solid ${props => props.theme.color.closer1}; */
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
`;

export const TopNav = ({ isLogoHidden = false }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isOnFilePage = window.location.pathname?.includes("/file/");

  return (
    <TopNavContainer>
      <LogoAndTitleLink to="/" isHidden={isLogoHidden}>
        <TopNavLogo />
      </LogoAndTitleLink>
      <SearchInput
        style={{
          width: "580px",
          height: "42px",
          marginLeft: "21px",
        }}
        placeholder={
          isOnFilePage ? "Search in document" : "What is the revenue of Tesla?"
        }
        onPressEnter={selectedValue => {
          if (isOnFilePage) {
            searchParams?.set("query", selectedValue);
            navigate(`?${searchParams}`);
            return;
          }

          searchParams.set("query", selectedValue);
          navigate(`/search?${searchParams}`);
        }}
        recommendationType="search_bar"
      />
      {/* <Tooltip title="My Dashboards">
        <NavItem
          to="/template-files"
          $isCurrentRoute={window.location.pathname === "/template-files"}
        >
          <FunctionsBtn />
        </NavItem>
      </Tooltip>
      <Tooltip title="Source Files">
        <NavItem
          to="/source-files"
          $isCurrentRoute={window.location.pathname === "/source-files"}
        >
          <Folder />
        </NavItem>
      </Tooltip>

      <Tooltip title="Working Files">
        <NavItem
          to="/working-files"
          $isCurrentRoute={window.location.pathname === "/working-files"}
        >
          <FolderSpecial />
        </NavItem>
      </Tooltip> */}

      <Tooltip title="Quick chat">
        <NavItem
          to="/quick-chat"
          $isCurrentRoute={window.location.pathname === "/quick-chat"}
        >
          <ChatIcon />
        </NavItem>
      </Tooltip>
      <Tooltip title="Search">
        <NavItem
          to="/start-search"
          $isCurrentRoute={window.location.pathname === "/start-search"}
        >
          <Search />
        </NavItem>
      </Tooltip>
    </TopNavContainer>
  );
};

export const CenteredWithTopNavLayoutNoSideBar = ({
  children,
  isProfileAndThemeOnly,
  centerColumnMaxWidth = "1000px",
  isDisabled = false,
  contentTopPadding = 40,
  className,
}) => (
  <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
    {!isProfileAndThemeOnly && <TopNav />}
    <NavHeader isBottomBorderHidden={isProfileAndThemeOnly} />
    <Content
      contentTopPadding={contentTopPadding}
      isDisabled={isDisabled}
      className={className}
    >
      {children}
    </Content>
  </CenteredContentContainer>
);

export const CenteredWithTopNavLayoutWide = ({
  children,
  isDisabled,
  className,
  sideGapWidth = "50px",
}) => (
  <CenteredContentContainerWide sideGapWidth={sideGapWidth}>
    {!isDisabled && <TopNav />}
    <NavHeader />
    <ContentWide className={className}>{children}</ContentWide>
  </CenteredContentContainerWide>
);

const CenteredContentContainerSideNav = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
`;

const ContentSideNav = styled.div`
  padding: 0 80px;
  padding-top: ${props => props.contentTopPadding}px;
  grid-column: 2;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
`;

const SideNavContainer = styled.div`
  background-color: black;
  z-index: 202;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60px;

  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  justify-items: center;
  align-content: start;

  /* sidebar color */
  background-color: #f5f5f5;
  box-shadow: ${props => props.theme.shadowWeak};
`;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #d9d9d9;
  opacity: 0.6;
  z-index: 100;
`;

const SideNavItem = styled(Link)`
  text-decoration: none;

  color: #636363;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 10px;
  padding: 12px 0px;
  width: 100%;
  min-width: 62px;
  align-items: center;
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer2}44;
    color: ${props => props.theme.color.furthest};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    ${props => props.isActive && `fill: ${props.borderColor};`}
  }

  border-right: 4px solid transparent;
  border-left: 4px solid
    ${props => (props.isActive ? props.borderColor : "transparent")};
`;

const SideNavItemDiv = styled(SideNavItem).attrs({ as: "div" })``;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;

  transform: translate(100%, -40px);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.2);
`;

const SideNavLogo = styled(BoltzbitLogo)`
  fill: ${props => props.theme.color.closest};
  height: 28px;
  margin-top: 15px;
  margin-bottom: 8px;
`;

const CustomLogo = styled.div`
  fill: ${props => props.theme.color.closest};
  height: 24px;
  width: 28px;
  margin-top: 15px;
  margin-bottom: 8px;

  background-image: url(${props => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const PoweredText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  opacity: 0.6;
  font-size: 10px;
`;

const TipContainer = styled.div`
  background-color: ${props => props.theme.color.closest};
  color: ${props => props.theme.color.furthest};
  padding: 4px;
`;

const TopNavLogo = styled(Logo)`
  fill: ${props => props.theme.color.closest};
  height: 28px;
`;

const GreyDivider = styled.div`
  width: 70%;
  height: 1px;
  background-color: #bebebe;
`;

const NoStyleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const GreyDividerTitle = styled.div`
  color: #e5e5e5;
  padding: 8px;
  font-size: 10px;
  font-weight: 600;

  :hover {
    color: #8f8f8f;
  }
`;

const CreationBtns = styled.div`
  background-color: ${props => props.theme.color.furthest};
  white-space: nowrap;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const ICON_SIZE = "20px";

const NewItemBtn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0_hover};
  }
`;

const TITLE_GREY = "#a0a0a0";
const NewItemsTitle = styled.div`
  padding: 8px;
  color: ${TITLE_GREY};
  font-size: 10px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NewItemFileInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  ::file-selector-button {
    display: none;
  }
`;

export const CenteredWithTopNavLayout = ({
  children,
  isHeaderEnabled = true,
  isDisabled,
  className = "",
  contentTopPadding = 91,
  isTopBarHidden = false,
  style = {},
  isSidebarDisabled = false,
  logoSrc = "",
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const [openSidebarType, setOpenSidebarType] = useSearchParamsState({
    paramName: "openSidebarType",
    initialValue: "",
  }); // docs, sources, pipelines

  const onClickSidebarIcon = type => {
    if (openSidebarType === type) {
      setOpenSidebarType("");
      return;
    }
    setOpenSidebarType(type);
  };

  const [isCreatingItem, setIsCreatingItem] = useState(false);

  const doCreateWordDoc = async () => {
    setIsCreatingItem(true);
    const { data, error } = await postWordDoc(
      { directoryPath: "/working-files" },
      {
        fileName: `Untitled Doc`,
        content: { blocks: [{ text: "" }] },
      }
    );
    setIsCreatingItem(false);
    if (!error) {
      navigate(`/word-docs/${data?.id}`);
    }
  };

  const doCreateExcelModel = async () => {
    setIsCreatingItem(true);
    const { data: newModel, error } = await postExcelModelDraft({
      directoryPath: "/working-files",
    });
    setIsCreatingItem(false);
    if (!error) {
      navigate(`/excel-views/${newModel?.id}`);
    }
  };

  const doPostFiles = async files => {
    setIsCreatingItem(true);
    const { error } = await postBulkUploadFormData(
      { directoryPath: "/source-files" },
      { files }
    );
    setIsCreatingItem(false);
    if (!error) {
      window.location.reload();
    }
  };

  return (
    <CenteredContentContainerSideNav>
      {!isTopBarHidden && <TopNav isLogoHidden />}
      {isHeaderEnabled && <NavHeader />}
      <SideNavContainer isSidebarDisabled={isSidebarDisabled}>
        {isSidebarDisabled && <DisabledOverlay />}
        <Link to="/">
          {/* {logoSrc ? (
            <CustomLogo src={`data:image/png;base64,${logoSrc}`} />
          ) : (
            <SideNavLogo />
          )} */}
          <SideNavLogo />
        </Link>
        <TooltipNew tipText="Docs" tipStyle={{ left: "4px" }}>
          <SideNavItemDiv
            borderColor="#5d9df6"
            isActive={openSidebarType === "docs"}
            onClick={() => onClickSidebarIcon("docs")}
          >
            <LogoInner style={{ height: ICON_SIZE }} />
          </SideNavItemDiv>
        </TooltipNew>
        <TooltipNew tipText="Sources" tipStyle={{ left: "4px" }}>
          <SideNavItemDiv
            borderColor="#5d9df6"
            isActive={openSidebarType === "sources"}
            onClick={() => onClickSidebarIcon("sources")}
          >
            <DatasetIcon style={{ height: ICON_SIZE }} />
          </SideNavItemDiv>
        </TooltipNew>
        <TooltipNew tipText="Pipelines" tipStyle={{ left: "4px" }}>
          <SideNavItemDiv
            borderColor="#5d9df6"
            isActive={openSidebarType === "pipelines"}
            onClick={() => onClickSidebarIcon("pipelines")}
          >
            <SigmaIcon style={{ height: ICON_SIZE }} />
          </SideNavItemDiv>
        </TooltipNew>
        <TooltipNew tipText="API" tipStyle={{ left: "4px" }}>
          <SideNavItemDiv
            borderColor="#5d9df6"
            isActive={window?.location?.pathname?.includes("/api-docs")}
            onClick={() => window.open("/api-docs", "_blank")}
          >
            <Code fontSize="20px" />
          </SideNavItemDiv>
        </TooltipNew>
        {isSidebarDisabled && (
          <div style={{ zIndex: 101 }}>
            <EmailPasswordModal
              onClickDone={() => window.location.reload()}
              triggerComponent={
                <SideNavItemDiv>
                  <SignUpIcon />
                  Sign up
                </SideNavItemDiv>
              }
            />
          </div>
        )}
      </SideNavContainer>
      <ContentSideNav
        style={style}
        className={className}
        isDisabled={isDisabled}
        contentTopPadding={contentTopPadding}
      >
        {children}
      </ContentSideNav>

      {logoSrc && (
        <PoweredText>
          Powered by Boltzbit AI
          <Logo />
        </PoweredText>
      )}
    </CenteredContentContainerSideNav>
  );
};
