import { Fade, Backdrop, Modal as MuiModal } from "@material-ui/core";
import styled from "styled-components";

const ModalContent = styled.div`
  outline: none;
  border-radius: 24px;
  background-color: ${props => props.theme.color.furthest};
  position: relative;
`;

const ModalTitle = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 22px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const CornerSquare = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
`;

const Modal = ({
  handleClose,
  open,
  children,
  title,
  cornerColor = "#dedede",
}) => (
  <MuiModal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    onMouseDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}
    onMouseMove={e => e.stopPropagation()}
  >
    <Fade in={open}>
      <ModalContent>
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
        {/* <CornerSquare
          style={{ top: 0, left: 0, backgroundColor: cornerColor }}
        />
        <CornerSquare
          style={{ top: 0, right: 0, backgroundColor: cornerColor }}
        />
        <CornerSquare
          style={{ bottom: 0, left: 0, backgroundColor: cornerColor }}
        />
        <CornerSquare
          style={{ bottom: 0, right: 0, backgroundColor: cornerColor }}
        /> */}
      </ModalContent>
    </Fade>
  </MuiModal>
);

export default Modal;
