import { Link } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";

import TableActionsDropdown from "components/TableActionsDropdown";
import usePollExcelModelProgress from "api/services/usePollExcelModelProgress";
import { getDownloadExcelModelById } from "api/services/excelModelsService";

const PackageRow = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr auto;
  align-items: start;

  gap: 20px;
  padding: 12px 20px;
  background-color: ${props =>
    props.rowIndex % 2 !== 0 ? props.theme.color.closer0 : `${props.theme.color.closer1}33`};

  :hover {
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.closer1}BB;
  }

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const ProgressAndActions = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const ExcelModelRow = ({ isDisabled, excelModel, onClickDelete }) => {
  const progress = usePollExcelModelProgress(excelModel.id);
  // const progress = 1;
  const [isLoading, setIsLoading] = useState(false);
  // const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   doPopulateProgress();
  // }, [excelModel?.id]);

  // const doPopulateProgress = async () => {
  //   setIsLoading(true);
  //   const { data } = await getExcelModelSizerFillUpProgress(excelModel.id);
  //   setProgress(parseFloat(data) || 0);
  //   setIsLoading(false);
  // };

  return (
    <PackageRow isDisabled={isDisabled || progress !== 1 || isLoading}>
      <Link to={`/excel-views/${excelModel.id}`}>{excelModel.name}</Link>
      <div>{excelModel.createdAt}</div>
      <ProgressAndActions>
        progress: {progress}
        <TableActionsDropdown
          onClickDelete={onClickDelete}
          onClickDownload={async () => {
            setIsLoading(true);
            await getDownloadExcelModelById(excelModel.id);
            setIsLoading(false);
          }}
        />
      </ProgressAndActions>
    </PackageRow>
  );
};

export default ExcelModelRow;
