import InputExpanding from "components/InputExpanding";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

const EditableDocNameInput = styled(InputExpanding)`
  display: block;
  outline: none;
  border: none;
  /* width: 300px; */
  z-index: 2;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const InputWithState = ({
  isDisabled = false,
  initialValue,
  onApplyValue = newVal => {},
  style = {},
  className = "",
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <EditableDocNameInput
      ref={inputRef}
      className={className}
      isDisabled={isDisabled}
      style={style}
      value={value}
      onClick={e => e.stopPropagation()}
      onChange={e => setValue(e.target.value)}
      onBlur={e => {
        if (e.target.value === initialValue) {
          return;
        }
        onApplyValue(e.target.value);
      }}
      onKeyDown={e => {
        if (e.key === "Escape") {
          setValue(initialValue);
        }
        if (e.key === "Enter") {
          onApplyValue(e.target.value);
        }
      }}
    />
  );
};

export default InputWithState;
