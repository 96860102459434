import styled from "styled-components";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import Modal from "components/ui/Modal";
import { format } from "date-fns";
import { useState } from "react";
import { uniq } from "lodash";
import MultiSelectWithFilter from "components/ui/MultiSelectWithFilter";
import { useEffect } from "react";

const safeFormat = (date, formatString) => {
  try {
    return format(new Date(date), formatString);
  } catch {
    return "";
  }
};

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 20px;
  border-radius: 5px;

  display: grid;
  grid-template-areas:
    "filter ."
    "price size";
  gap: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  padding-bottom: 10px;
`;

const PriceContainer = styled.div`
  grid-area: price;
`;

const SizeContainer = styled.div`
  grid-area: size;
`;

const PlotsModal = ({ open, handleClose, records }) => {
  const [selectedIsins, setSelectedIsins] = useState([]);

  useEffect(() => {
    setSelectedIsins(records?.map(record => record?.row?.isin)?.slice(0, 10));
  }, [records]);

  const priceData = records.map(record => {
    return {
      time: safeFormat(record?.row?.time, "yyyy-MM-dd HH:mm:ss"),
      [`bidPrice_${record?.row?.isin}`]: record?.row?.bidPrice,
      [`askPrice_${record?.row?.isin}`]: record?.row?.askPrice,
    };
  });

  const sizeData = records.map(record => {
    return {
      time: safeFormat(record?.row?.time, "yyyy-MM-dd HH:mm:ss"),
      [`bidSize_${record?.row?.isin}`]: record?.row?.bidSize / 1000,
      [`askSize_${record?.row?.isin}`]: record?.row?.askSize / 1000,
    };
  });

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <MultiSelectWithFilter
          title="ISIN"
          options={uniq(records?.map(record => record?.row?.isin))}
          selectedOptions={selectedIsins}
          onSelectOptions={newIsins => setSelectedIsins(newIsins)}
        />
        <PriceContainer>
          <Title>Price (USD)</Title>
          <LineChart width={600} height={300} data={priceData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            {selectedIsins?.map(isin => (
              <>
                <Line
                  type="monotone"
                  isAnimationActive={false}
                  dataKey={`bidPrice_${isin}`}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" isAnimationActive={false} dataKey={`askPrice_${isin}`} stroke="#82ca9d" />
              </>
            ))}
          </LineChart>
        </PriceContainer>

        <SizeContainer>
          <Title>Size (M)</Title>
          <LineChart width={600} height={300} data={sizeData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            {selectedIsins?.map(isin => (
              <>
                <Line
                  type="monotone"
                  isAnimationActive={false}
                  dataKey={`bidSize_${isin}`}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" isAnimationActive={false} dataKey={`askSize_${isin}`} stroke="#82ca9d" />
              </>
            ))}
          </LineChart>
        </SizeContainer>
      </ModalContent>
    </Modal>
  );
};

export default PlotsModal;
