import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";

import {
  getKeywordRecommendation,
  getRecommendationsForQuery,
} from "api/services/searchService";
import useClickOutside from "hooks/useClickOutside";

const PositiondContainer = styled.div`
  z-index: 10;
  position: relative;
`;

const Container = styled.label`
  position: absolute;
  cursor: text;
  background-color: ${props => props.theme.color.furthest};
  width: 100%;

  border: ${props => props.borderWidth}px solid;
  border-color: ${props => props.theme.color.primary};

  box-shadow: ${props => props.theme.shadow};
  :hover {
    /* box-shadow: ${props => props.theme.shadowHover}; */
  }

  ${props =>
    props.isBorderVisible &&
    `
  border-image: linear-gradient(to right, ${props.theme.color.feature}, ${props.theme.color.primary})
    1;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  `}
`;

const QueryInput = styled.input`
  display: block;
  font-family: "Montserrat";
  width: 100%;
  background-color: ${props => props.theme.color.furthest};
  border: none;
  color: ${props => props.theme.color.closest};
  padding: 10px 20px;
  outline: none;
  transition: border-color 0.2s;

  border: 2px solid transparent;
`;

const OuterContainer = styled.div`
  width: 100%;
  cursor: pointer;
  max-height: 250px;
  overflow: auto;
  /* border-top: 1px solid ${props => props.theme.color.primary}; */
`;

const TooltipContainer = styled.div`
  width: 100%;
  z-index: 10;
  overflow: hidden;
  color: ${props => props.theme.color.closest};
  height: ${props => (props.isExpanded ? "auto" : 0)};
`;

const PopoverNavLink = styled(Link)`
  width: 100%;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 52px 1fr;
  align-items: center;

  text-decoration: none;
  position: relative;
  color: ${props => props.theme.color.closest};
  background-color: ${props =>
    props.isHighlighed
      ? props.theme.color.closer1
      : props.theme.color.furthest};
  white-space: nowrap;
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
`;

const PopoverCategory = styled.div`
  color: ${props => props.theme.color.closer2}AA;
  font-weight: 600;
  padding: 4px;
`;

const SearchResultsPopover = ({ children, borderWidth = 2 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const outerContainerRef = useRef(null);

  useClickOutside(outerContainerRef, () => setIsExpanded(false));

  useEffect(() => {
    setIsExpanded(!!children);
  }, [children]);

  return (
    <OuterContainer ref={outerContainerRef}>
      <TooltipContainer borderWidth={borderWidth} isExpanded={isExpanded}>
        {children}
      </TooltipContainer>
    </OuterContainer>
  );
};

// recommendationType, optional, one of ['excel', 'word']
const SearchInput = ({
  autoFocus = false,
  className,
  style,
  dropdownOptionStyle,
  placeholder = "What is the revenue of Tesla?",
  onPressEnter,
  recommendationType,
  borderWidth = 2,
  bgColor = "#f1f1f1",
}) => {
  const [query, setQuery] = useState("");
  const [recommendations, setRecommendations] = useState([]);

  const [highlightedRecommendationIndex, setHighlightedRecommendationIndex] =
    useState(null);
  const [isFocussed, setIsFocussed] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams?.get("query")) {
      setQuery(searchParams?.get("query"));
    }
  }, [searchParams?.get("query")]);

  const inputRef = useRef();

  const categoryNames = Object.keys(recommendations || {});
  const areRecosCategorised = Array.isArray(
    recommendations?.[categoryNames?.[0]]
  );

  const allRecommendations = categoryNames.reduce((acc, categoryName) => {
    if (areRecosCategorised) {
      return [...acc, ...recommendations?.[categoryName]];
    }
    return acc;
  }, []);

  useClickOutside(inputRef, () => {
    setRecommendations([]);
    setIsFocussed(false);
  });

  useEffect(() => {
    highlightedRecommendationIndex === null && doPopulateRecommendations();
  }, [query]);

  const doPopulateRecommendations = async () => {
    if (recommendationType === "excel") {
      const { data } = await getKeywordRecommendation({
        query,
        context: "excel",
      });
      setRecommendations(data);
      return;
    }

    if (recommendationType === "search_bar") {
      const { data } = await getKeywordRecommendation({
        query,
        context: "search_bar",
      });
      setRecommendations(data);
      return;
    }

    const { data } = await getRecommendationsForQuery(query);
    setRecommendations(data);
  };

  let popoverContent = null;
  if (document.activeElement !== inputRef.current) {
    popoverContent = null;
  } else if (Array?.isArray(recommendations) && recommendations?.length > 0) {
    popoverContent = recommendations?.map((recommendation, i) => (
      <PopoverNavLink
        key={i}
        to={`/search?query=${recommendation}`}
        isHighlighed={
          recommendation === recommendations[highlightedRecommendationIndex]
        }
        style={dropdownOptionStyle}
        bgColor={bgColor}
      >
        {recommendation}
      </PopoverNavLink>
    ));
  } else if (areRecosCategorised) {
    popoverContent = categoryNames.map((categoryName, i) => (
      <>
        <PopoverCategory>{categoryName}</PopoverCategory>
        {recommendations[categoryName].map((recommendation, j) => (
          <PopoverNavLink
            key={j}
            to={`/search?query=${recommendation}`}
            isHighlighed={
              recommendation ===
              allRecommendations[highlightedRecommendationIndex]
            }
            style={dropdownOptionStyle}
            bgColor={bgColor}
          >
            {recommendation}
          </PopoverNavLink>
        ))}
      </>
    ));
  }

  return (
    <PositiondContainer style={style}>
      <Container
        bgColor={bgColor}
        borderWidth={borderWidth}
        isBorderVisible={isFocussed}
        onKeyDown={e => {
          if (!["ArrowUp", "ArrowDown", "Enter"].includes(e.key)) {
            setHighlightedRecommendationIndex(null);
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          let newHighlightedindex = highlightedRecommendationIndex;
          if (e.key === "ArrowUp") {
            if (
              highlightedRecommendationIndex === null ||
              highlightedRecommendationIndex === 0
            ) {
              newHighlightedindex = 0;
            }
            if (highlightedRecommendationIndex > 0) {
              newHighlightedindex = highlightedRecommendationIndex - 1;
            }
          }

          if (e.key === "ArrowDown") {
            if (highlightedRecommendationIndex === null) {
              newHighlightedindex = 0;
            } else {
              newHighlightedindex = highlightedRecommendationIndex + 1;
            }
          }

          setQuery(
            recommendations?.[newHighlightedindex] ||
              allRecommendations?.[newHighlightedindex] ||
              query
          );
          setHighlightedRecommendationIndex(newHighlightedindex);

          if (e.key === "Enter") {
            onPressEnter(query);
            inputRef.current.blur();
          }
        }}
      >
        <QueryInput
          onFocus={() => setIsFocussed(true)}
          onClick={doPopulateRecommendations}
          ref={inputRef}
          placeholder={placeholder}
          value={query}
          onChange={e => setQuery(e?.target?.value)}
          autoFocus={autoFocus}
          className={className}
          onMouseDown={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
          bgColor={bgColor}
        />
        <SearchResultsPopover borderWidth={borderWidth}>
          {popoverContent}
        </SearchResultsPopover>
      </Container>
    </PositiondContainer>
  );
};

export default SearchInput;
