import { first, last } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { formatLongNumber } from "utils/common";

const Container = styled.div`
  position: relative;
  padding-top: 28px;
  color: black;
  text {
    fill: black;
  }
`;

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

/*
column = {
  name: ...
}

record = {
  [columnName]: {
    value: ...
  }
}
*/

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const RecordsSummaryBarChart = ({ records = [], columns = [] }) => {
  const [selectedColumnName, setSelectedColumnName] = useState(
    last(columns)?.name || ""
  );

  useEffect(() => {
    setSelectedColumnName(last(columns)?.name || "");
  }, [JSON.stringify(columns)]);

  // TODO: the record will tell which column is a groupby column
  const categoryName = first(columns)?.name || "";
  const barsData = records?.map(record => ({
    [categoryName]: record?.[categoryName]?.value,
    [selectedColumnName]: record?.[selectedColumnName]?.value,
  }));

  return (
    <Container>
      <StyledSelect
        value={selectedColumnName}
        onChange={e => setSelectedColumnName(e.target.value)}
      >
        {columns
          .filter(col => col.name !== categoryName)
          .map(column => (
            <option>{column.name}</option>
          ))}
      </StyledSelect>
      <ResponsiveContainer width="99%" height={300}>
        <BarChart
          data={barsData}
          margin={{
            right: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            interval={0}
            style={{ fontSize: "9px" }}
            dataKey={categoryName}
            // tick={<CustomizedAxisTick />}
            tickFormatter={tick => {
              if (`${tick}`.length >= 8) {
                return `${tick}`.slice(0, 8) + "...";
              }
              return tick;
            }}
          />
          <YAxis
            tickFormatter={tick => {
              return formatLongNumber(tick);
            }}
          />
          <Bar
            dataKey={selectedColumnName}
            fill="#0191ff"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
          <Tooltip contentStyle={{ backgroundColor: "white" }} cursor={false} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default RecordsSummaryBarChart;
