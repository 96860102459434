import { postPromptRecommendation } from "api/backend/searchServiceEndpoints";
import SourcesCards from "components/SourcesCards";
import ButtonWord from "components/ui/ButtonWord";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const SuggestionsContainer = styled.div`
  position: fixed;
  /* box-shadow: ${props => props.theme.shadowWeak}; */
  background-color: white;
  padding: 12px 8px;
  height: 62px;
  overflow-y: hidden;
  overflow-x: auto;

  display: grid;
  grid-auto-flow: column;
  grid-column: 1fr;
  gap: 8px;
  border: 2px solid #e8ecef;
  border-bottom: 2px solid #e8ecef;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Suggestion = styled.div`
  padding: 4px 8px;
  white-space: nowrap;
  cursor: pointer;
  padding: 8px;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  line-height: 1.2;
  display: flex;
  align-items: center;

  :hover {
    background-color: lightgrey;
  }
`;

const ButtonWordPrompt = styled(ButtonWord)`
  background-color: white;
  color: black;
  border: 2px solid #e8ecef;

  font-size: 14px;
  padding: 2px;
  line-height: 16px;
  font-weight: 400;

  :hover {
    color: black;
    background-color: lightgrey;
  }
`;

const BlueSpan = styled.span`
  background-color: #38d0da44;
  color: transparent;
  pointer-events: none;
`;

const SUGGESTIONS = [
  // "create a word document about oranges",
  // "create an excel sheet about user tracking",
  // "create a database for a company of 10 employees",
  // "create a power point for medical services using AI",
  // "summarise the document",
  // "show me the high-level metrics in a table",
  // "how much did the company make last quarter?",
  // "what do we need to do with this form?",
];

const InputWithSuggestions = ({
  style = {},
  rows = 1,
  className,
  value,
  onChange = e => {},
  placeholder,
  disabled,
  onKeyDown = e => {},
  onClickSuggestion = text => {},
  onFocus = () => {},
  sources = [],
  userSources = [],
  setUserSources = () => {},
  areSuggestionsDisabled = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isFocussed, setIsFocussed] = useState(false);
  const [rect, setRect] = useState({});
  const ref = useRef(null);
  // const [sources] = useSearchParamsState({
  //   paramName: "sources",
  //   initialValue: [],
  // });

  useEffect(() => {
    doPopulateSuggestions();
  }, [JSON.stringify(sources)]);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }
    ref.current.style.height = "inherit";
    ref.current.style.height = `${ref.current.scrollHeight}px`;
    if (value === "") {
      ref.current.style.height = `41px`;
    }

    if (ref.current.scrollHeight > 264) {
      ref.current.style.height = `246px`;
    }
  }, [value, ref?.current]);

  useEffect(() => {
    const newRect = ref.current?.getBoundingClientRect();
    setRect(newRect);
  }, [rows, value, isFocussed]);

  useEffect(() => {
    if (disabled) {
      setIsFocussed(false);
    }
  }, [disabled]);

  useEffect(() => {
    doPopulateSuggestions();
  }, []);

  const doPopulateSuggestions = async () => {
    const { data } = await postPromptRecommendation(
      {},
      {
        sources,
      }
    );
    setSuggestions(data || []);
  };

  let sourcesMsg = `${sources?.length} sources added to the chat`;
  if (sources?.length !== userSources?.length) {
    sourcesMsg = `${sources?.length} sources added to the chat, ${userSources?.length} selected`;
  }

  return (
    <>
      <textarea
        style={{
          ...style,
          pointerEvents: "auto",
          paddingLeft: 40,
        }}
        rows={rows}
        className={className}
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={e => {
          onKeyDown(e);
        }}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => {
          doPopulateSuggestions();
          onFocus();
          setIsFocussed(true);
        }}
        onBlur={() => setIsFocussed(false)}
      />
      {/* <SuggestionsContainer
        style={{
          display: isFocussed ? "flex" : "none",
          top: rect?.top - 62,
          left: rect?.left,
          width: rect?.width,
        }}
      >
        {suggestions.map(suggestion => {
          let onMouseDown = e => {
            e.preventDefault();
            e.stopPropagation();
            let suggestionText = suggestion;
            if (value?.startsWith(SOURCES_PROMPT)) {
              suggestionText = `${SOURCES_PROMPT} ${suggestion}`;
            }
            if (value?.startsWith(NO_SOURCES_PROMPT)) {
              suggestionText = `${NO_SOURCES_PROMPT} ${suggestion}`;
            }

            onClickSuggestion(suggestionText);
          };

          return (
            <Suggestion key={suggestion} onMouseDown={onMouseDown}>
              {suggestion}
            </Suggestion>
          );
        })}
      </SuggestionsContainer> */}
      <SuggestionsContainer
        style={{
          display: isFocussed && !areSuggestionsDisabled ? "grid" : "none",
          gridAutoFlow: "row",
          top: rect?.top - 160,
          left: rect?.left,
          width: rect?.width,
          overflow: "auto",
          height: "auto",
          backgroundColor: "#f9f9f9",
        }}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div>{sourcesMsg}</div>
        <SourcesCards
          isExpandable={false}
          sourceOptions={sources}
          userSources={userSources}
          setUserSources={setUserSources}
        />
      </SuggestionsContainer>
    </>
  );
};

export default InputWithSuggestions;
