import { useState } from "react";
import styled from "styled-components";
import { cloneDeep, last } from "lodash";

import {
  CrossIcon,
  ExcelIcon,
  FolderIcon,
  InternetIcon,
  PdfIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  TickIcon,
  WordIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import SmallButton from "components/ui/SmallButton";
import { useEffect } from "react";
import { getDirectoryItems } from "api/services/fileSystem";
import SearchableSelectInput from "components/ui/SearchableSelectInput";
import { Gap } from "components/Layout";
import { uuidv4 } from "utils/common";

const getItemIconFromItem = (fileName, type = "", isItemInSources) => {
  if (isItemInSources) {
    return <TickIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".pdf")) {
    return <PdfIcon height="12px" style={{ opacity: 0.8, fill: "#ed1c24" }} />;
  }
  if (type === "WEB_PAGE") {
    return <InternetIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (type === "SIGMA_TEMPLATE") {
    return <SigmaIcon height="12px" style={{ opacity: 0.8 }} />;
  }
  if (fileName?.endsWith(".docx")) {
    return <WordIcon height="12px" style={{ opacity: 0.8, fill: "#175bbc" }} />;
  }
  if (fileName?.endsWith(".xlsx")) {
    return (
      <ExcelIcon height="12px" style={{ opacity: 0.8, fill: "#127e45" }} />
    );
  }
  if (type === "DIRECTORY") {
    return <FolderIcon height="10px" />;
  }
  return null;
};

const updateColumn = (
  schemaConfig,
  columnName,
  columnDescription,
  editColumnName,
  columnType
) => {
  const newConfig = cloneDeep(schemaConfig);

  newConfig.all_records.columnNames = newConfig.all_records.columnNames.map(
    colName => {
      if (colName === editColumnName) {
        return columnName;
      }
      return colName;
    }
  );

  delete newConfig.all_records.columnNameToDescription[editColumnName];
  newConfig.all_records.columnNameToDescription[columnName] = columnDescription;
  if (!newConfig.all_records.columnNameToType) {
    newConfig.all_records.columnNameToType = {};
  }
  newConfig.all_records.columnNameToType[columnName] = columnType;

  return newConfig;
};

const addNewColumn = (schemaConfig, columnName, columnDescription) => {
  const newConfig = cloneDeep(schemaConfig);
  newConfig.all_records.columnNames.push(columnName);
  if (!newConfig.all_records.columnNameToType) {
    newConfig.all_records.columnNameToType = {};
  }
  newConfig.all_records.columnNameToDescription[columnName] = columnDescription;

  return newConfig;
};

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};

  display: grid;
  grid-auto-flow: row;
  gap: 10px;

  min-width: 800px;

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const StyledTextArea = styled.textarea`
  height: 150px;
  width: 100%;
  resize: none;
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  padding: 8px;
  font-family: "Montserrat", sans-serif;
`;

const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  padding: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 50%;
`;

const BottomButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const TestHeading = styled.div`
  font-weight: 600;
`;

const PickSourcesContainer = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;
`;

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const FillTableButton = styled(SmallButton)`
  justify-self: end;
`;

const Td = styled.td`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  overflow: hidden;
  line-height: 1.2;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 14px;
  width: 50%;
  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
`;

const StyledTable = styled.table`
  /* width: 400px; */
`;

const TestSection = styled.div`
  margin-top: 20px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  align-content: start;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const InputAndSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const COLUMN_TYPES = ["TEXT", "CURRENCY"];

const NewDashboardColumnModalTrigger = ({
  schemaConfig,
  onChangeSchemaConfig = () => {},
  editColumnName = "",
  blocks = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [columnDescription, setColumnDescription] = useState("");

  const [sources, setSources] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [columnType, setColumnType] = useState("TEXT");

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(false);
  }, [isOpen]);

  let directoryPath = searchValue?.split("/")?.slice(0, -1)?.join("/");
  directoryPath = "/source-files" + directoryPath;

  useEffect(() => {
    doPopulateFileOptions();
  }, [directoryPath]);

  const doPopulateFileOptions = async () => {
    setAllItems([]);
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setAllItems(data?.items);
  };

  const selectOptions = allItems?.map(item => {
    const isItemInSources = sources.find(s => s.fileId === item.id);

    return {
      value: item?.id,
      label: item?.fileName || last(item?.path?.split("/")),
      icon: getItemIconFromItem(item?.fileName, item?.type, isItemInSources),
      type: item?.type,
      path: item?.path?.replace("/source-files", ""),
      isDisabled: isItemInSources,
    };
  });

  useEffect(() => {
    setColumnName(editColumnName);
    setColumnDescription(
      schemaConfig?.all_records?.columnNameToDescription[editColumnName]
    );
    setColumnType(
      schemaConfig?.all_records?.columnNameToType?.[editColumnName] || "TEXT"
    );
  }, [editColumnName, isOpen]);

  return (
    <>
      {editColumnName ? (
        <StyledPencilIcon onClick={() => setIsOpen(true)} />
      ) : (
        <StyledPlusIcon onClick={() => setIsOpen(true)} />
      )}
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isDisabled}>
          <ModalTitle>
            {editColumnName ? "Edit column" : "Add new column"}
          </ModalTitle>
          <InputAndSelect>
            <StyledInput
              value={columnName}
              onChange={e => setColumnName(e.target.value)}
              placeholder="Column name"
            />
            <div>
              Data format&nbsp;
              <select
                value={columnType}
                onChange={e => setColumnType(e.target.value)}
              >
                {COLUMN_TYPES?.map(type => (
                  <option>{type}</option>
                ))}
              </select>
            </div>
          </InputAndSelect>
          <StyledTextArea
            value={columnDescription}
            onChange={e => setColumnDescription(e.target.value)}
            placeholder="Column description prompt"
          />

          {/* <TestSection isVisible={columnName}>
            <TestHeading>Try column extraction rule</TestHeading>
            <PickSourcesContainer>
              <div>Choose test emails</div>
              <SearchableSelectInput
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                onClickOption={clickedOption => {
                  if (clickedOption?.type === "DIRECTORY") {
                    console.log({ clickedOption });
                    setSearchValue(`${clickedOption?.path}/`);
                    return;
                  }

                  const newFileId = clickedOption?.value;
                  const newSource = {
                    fileId: newFileId,
                    fileName: allItems.find(item => item.id === newFileId)
                      ?.fileName,
                    type: allItems.find(item => item.id === newFileId)?.type,
                  };
                  setSources([...sources, newSource]);
                }}
                options={selectOptions}
                placeholder="File or folder name"
              />
            </PickSourcesContainer>

            <StyledTable>
              <thead>
                <tr style={{ position: "relative" }}>
                  <Th>Email Subject</Th>
                  <Th>{columnName}</Th>
                </tr>
              </thead>
              <tbody>
                {sources?.map(source => (
                  <tr>
                    <Td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      {source?.fileName}{" "}
                      <StyledCrossIcon
                        onClick={() => {
                          const newSources = sources.filter(
                            s => s.fileId !== source.fileId
                          );
                          setSources(newSources);
                        }}
                      />
                    </Td>
                    <Td>-</Td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>

            <FillTableButton value="Test prompt" />
          </TestSection> */}

          <Gap height="40px" />

          <BottomButtons>
            <SmallButton
              value={editColumnName ? "Update" : "Add to table"}
              onClick={async () => {
                let newConfig = schemaConfig;
                let newBlocks = cloneDeep(blocks);
                if (editColumnName) {
                  newConfig = updateColumn(
                    schemaConfig,
                    columnName,
                    columnDescription,
                    editColumnName,
                    columnType
                  );

                  // replace column name in blocks
                  newBlocks = newBlocks.map(block => {
                    if (block?.text?.includes(`extract "${editColumnName}"`)) {
                      let textToAdd = `extract "${columnName}"`;
                      if (columnDescription) {
                        textToAdd = `extract "${columnName}", with description "${
                          columnDescription || ""
                        }"`;
                      }

                      return {
                        ...block,
                        text: textToAdd,
                      };
                    }

                    return block;
                  });
                } else {
                  newConfig = addNewColumn(
                    schemaConfig,
                    columnName,
                    columnDescription,
                    columnType
                  );
                  // add new block
                  newBlocks.push({
                    text: "",
                  });

                  let textToAdd = `extract "${columnName}"`;
                  if (columnDescription) {
                    textToAdd = `extract "${columnName}", with description "${
                      columnDescription || ""
                    }"`;
                  }

                  newBlocks.push({
                    isQuery: true,
                    text: textToAdd,
                    queryId: uuidv4(),
                  });
                }
                setIsDisabled(true);
                await onChangeSchemaConfig(newConfig, newBlocks);
                setIsDisabled(false);
                setIsOpen(false);
              }}
            />
            {/* {editColumnName && (
              <SmallButton
                value="Delete column"
                onClick={() => {
                  const newConfig = cloneDeep(schemaConfig);
                  let newBlocks = cloneDeep(blocks);

                  newConfig.all_records.columnNames =
                    newConfig.all_records.columnNames.filter(colName => {
                      if (colName === editColumnName) {
                        return false;
                      }
                      return true;
                    });

                  delete newConfig.all_records.columnNameToDescription[
                    editColumnName
                  ];

                  newBlocks = newBlocks.filter(block => {
                    if (!block?.isQuery) {
                      return true;
                    }

                    if (block?.text?.includes(`extract "${editColumnName}"`)) {
                      return false;
                    }

                    return true;
                  });

                  onChangeSchemaConfig(newConfig, newBlocks);
                  setIsOpen(false);
                }}
              />
            )} */}
          </BottomButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewDashboardColumnModalTrigger;
