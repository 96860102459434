import { useState } from "react";
import styled from "styled-components";

import ProgressBar from "components/ui/ProgressBar";
import { useEffect } from "react";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const TallProgressBar = styled(ProgressBar)`
  height: 10px;
`;

const ProgressBarFakeProgress = ({ className, isDone }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    if (isDone) {
      setCurrentValue(100);
      return;
    }

    if (currentValue >= 99) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setCurrentValue(currentValue + 1);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [currentValue, isDone]);

  return (
    <Container className={className}>
      <TallProgressBar currentValue={currentValue} maxValue={100} />
      {currentValue}%
    </Container>
  );
};

export default ProgressBarFakeProgress;
