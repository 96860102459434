import { useEffect, useState } from "react";
import styled from "styled-components";

import { getFile } from "api/services/filesService";

const BoldSpan = styled.span`
  font-weight: bold;
`;

const FileNameSpan = ({ fileId, className }) => {
  const [fileName, setFileName] = useState("...");

  useEffect(() => {
    doPopulateFileName();
  }, [fileId]);

  const doPopulateFileName = async () => {
    const { data } = await getFile(fileId);
    setFileName(data?.fileName);
  };

  return <BoldSpan className={className}>{fileName}, </BoldSpan>;
};

export default FileNameSpan;
