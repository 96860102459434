import { useRef, useEffect } from "react";

const TextAreaExpanding = ({
  value = "",
  onChange = () => {},
  className = "",
  style = {},
  disabled = false,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }
    ref.current.style.height = "inherit";
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  }, [value, ref?.current]);

  return (
    <textarea
      disabled={disabled}
      rows={1}
      ref={ref}
      style={{ ...style, overflowY: "hidden", resize: "none" }}
      className={className}
      value={value}
      onChange={onChange}
    />
  );
};

export default TextAreaExpanding;
