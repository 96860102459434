import { Close } from "@material-ui/icons";
import { Fragment } from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { parseJson } from "utils/common";

const Cell = styled.div`
  position: relative;
  padding: 5px 10px;
  border: 0.5px solid ${props => props.theme.color.closer2};
  cursor: pointer;
  :hover {
    border-color: ${props => props.theme.color.primary};
  }
`;

const TooltipToShowOnHover = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 2px;
  z-index: 15;
  pointer-events: none;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
`;

const AnnotationContainer = styled.div`
  background-color: ${props => props.theme.color.furthest};
  border: 1px solid ${props => (props.isGreen ? props.theme.color.data : props.theme.color.primary)}4D;
  color: ${props => props.theme.color.closest};
  display: grid;
  padding: 5px;
  grid-template-columns: 1fr 1fr auto;
  gap: 5px;

  font-size: 14px;
  user-select: none;
  font-weight: normal;
`;

const PropertyName = styled.div`
  grid-column: 1;
  font-weight: 600;
  white-space: nowrap;
`;

const PropertyValue = styled.div`
  grid-column: 2;
`;

const CloseButtonContainer = styled.div`
  padding: ${props => (props.isVisible ? "2px" : "0px")};
  height: 20px;
  width: ${props => (props.isVisible ? "20px" : "0px")};
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  transform: translate(5px, -5px);

  svg {
    height: 18px;
  }
`;

const getReshapedAnnotation = annotation => {
  if (typeof annotation === "string") {
    const labelProperties = [];

    const lines = annotation?.split("\n");
    lines.forEach(line => {
      const [name, value] = line.split(":");
      labelProperties.push({ name, value });
    });

    annotation = { labelProperties: JSON.stringify(labelProperties) };
  }

  return annotation;
};

const AnnoCell = ({
  annotations = [{ labelProperties: `[{ "name": "Property Name", "value": "Value" }]`, entityId: "" }],
  children,
  className,
  onClick = () => {},
  onClickDelete = () => {},
  onWheel = () => {},
}) => {
  const [isMouseOverSpan, setIsMouseOverSpan] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (isMouseOverSpan) {
      setIsTooltipVisible(true);
    }

    if (!isMouseOverSpan && !isMouseOverTooltip) {
      setIsTooltipVisible(false);
    }
  }, [isMouseOverSpan, isMouseOverTooltip]);

  return (
    <Cell
      onWheel={onWheel}
      onClick={onClick}
      className={className}
      onMouseEnter={() => setIsMouseOverSpan(true)}
      onMouseLeave={() => setIsMouseOverSpan(false)}
    >
      {children}
      <TooltipToShowOnHover
        style={{
          zIndex: isTooltipVisible ? 15 : 0,
          pointerEvents: isTooltipVisible ? "all" : "none",
          display: isTooltipVisible ? "grid" : "none",
        }}
        onMouseEnter={() => setIsMouseOverTooltip(true)}
        onMouseLeave={() => setIsMouseOverTooltip(false)}
      >
        {annotations
          ?.filter(a => !!a)
          ?.map((annotation, i) => {
            annotation = getReshapedAnnotation(annotation);
            const labelPropertiesJson = parseJson(annotation?.labelProperties);

            return (
              <AnnotationContainer key={i} isGreen={!!annotation?.entityId}>
                <CloseButtonContainer isVisible={!!annotation?.entityId} onClick={onClickDelete}>
                  <Close />
                </CloseButtonContainer>
                {labelPropertiesJson?.map((propertyNameAndValue, j) => (
                  <Fragment key={`${i}-${j}`}>
                    <PropertyName>{propertyNameAndValue?.name}:</PropertyName>
                    <PropertyValue>{propertyNameAndValue?.value}</PropertyValue>
                  </Fragment>
                ))}
              </AnnotationContainer>
            );
          })}
      </TooltipToShowOnHover>
    </Cell>
  );
};

export default AnnoCell;
