import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const postQuickStart = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/api/v1/quick-starts",
    params,
    body
  );
  return { data, error };
};

export const getQuickStarts = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/api/v1/quick-starts",
    params
  );
  return { data, error };
};

export const deleteQuickStart = async (id = "", params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/quick-starts/${id}`,
    params
  );
  return { data, error };
};

export const patchQuickStart = async (id = "", params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/quick-starts/${id}`,
    params,
    body
  );
  return { data, error };
};
