import { cloneDeep, first, last } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { formatLongNumber, safeFormat } from "utils/common";

const Container = styled.div`
  position: relative;
  height: 300px;
  padding-top: 28px;
  text {
    fill: grey;
  }
`;

const Selects = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  gap: 10px;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

/*
column = {
  name: ...
}

record = {
  [columnName]: {
    value: ...
  }
}
*/
const RecordsSummaryLineChart = ({ records = [], columns = [] }) => {
  const [selectedColumnName, setSelectedColumnName] = useState(
    last(columns)?.name || ""
  );
  const [categoryName, setCategoryName] = useState(first(columns)?.name || "");

  useEffect(() => {
    setSelectedColumnName(last(columns)?.name || "");
  }, [JSON.stringify(columns)]);

  // TODO: the record will tell which column is a groupby column
  // const categoryName = first(columns)?.name || "";
  const lineRecords = cloneDeep(records);
  const lineData = lineRecords
    ?.sort((recordA, recordB) => {
      const dateA = new Date(recordA?.[categoryName]?.value);
      const dateB = new Date(recordB?.[categoryName]?.value);
      return dateA - dateB;
    })
    .map(record => ({
      [categoryName]: record?.[categoryName]?.value,
      [selectedColumnName]: record?.[selectedColumnName]?.value,
    }));

  return (
    <Container>
      <Selects>
        <StyledSelect
          value={selectedColumnName}
          onChange={e => setSelectedColumnName(e.target.value)}
        >
          {columns
            .filter(col => col.name !== categoryName)
            .map(column => (
              <option>{column.name}</option>
            ))}
        </StyledSelect>
        against
        <StyledSelect
          value={categoryName}
          onChange={e => setCategoryName(e.target.value)}
        >
          {columns
            .filter(col => col.name !== selectedColumnName)
            .map(column => (
              <option>{column.name}</option>
            ))}
        </StyledSelect>
      </Selects>
      <ResponsiveContainer width="99%" height={300}>
        <LineChart data={lineData} margin={{ bottom: 10, right: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <Legend />
          <XAxis
            tickFormatter={tick => {
              const dateStr = safeFormat(tick, "d MMM");
              if (!dateStr) {
                if (`${tick}`.length >= 6) {
                  return `${tick}`.slice(0, 6) + "...";
                }
                return tick;
              }
              return dateStr;
            }}
            dataKey={categoryName}
          />
          <YAxis
            tickFormatter={tick => {
              return formatLongNumber(tick);
            }}
          />
          <Line
            type="monotone"
            dataKey={selectedColumnName}
            stroke="#0191ff"
            activeDot={{ r: 8 }}
          />
          <Tooltip contentStyle={{ backgroundColor: "white" }} />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default RecordsSummaryLineChart;
