import { Add, Close } from "@material-ui/icons";
import TextInputSquare from "components/inputs/TextInputSquare";
import styled from "styled-components";

const KeywordsValues = styled.div`
  grid-area: keywords-values;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const KeyNameInput = styled(TextInputSquare)`
  label {
    padding: 5px 15px;
  }
`;

const KeyValueInput = styled(KeyNameInput)``;

const KeywordInputAndDelete = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 30px;
  gap: 5px;
  align-items: center;
  align-self: start;
`;

const AddButton = styled.div`
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.closer0};
  }
  border-radius: 50%;
  padding: 0px;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(AddButton)``;

const KeywordsContainer = styled.div``;

const KeyValueEditor = ({ keyValueMap = {}, onNewKeyValueMap = () => {} }) => {
  return (
    <KeywordsContainer>
      <KeywordsValues>
        {Object.keys(keyValueMap).map((keyName, i) => (
          <KeywordInputAndDelete key={i}>
            <KeyNameInput
              value={keyName}
              placeholder="Field name"
              onNewInput={newKeyName => {
                delete keyValueMap[keyName];
                onNewKeyValueMap({
                  ...keyValueMap,
                  [newKeyName]: "",
                });
              }}
            />
            <KeyValueInput
              placeholder="Field value"
              value={keyValueMap[keyName]}
              onNewInput={newValue => {
                onNewKeyValueMap({
                  ...keyValueMap,
                  [keyName]: newValue,
                });
              }}
            />
            <DeleteButton
              onClick={() => {
                delete keyValueMap[keyName];
                onNewKeyValueMap({
                  ...keyValueMap,
                });
              }}
            >
              <Close />
            </DeleteButton>
          </KeywordInputAndDelete>
        ))}
        <AddButton
          onClick={() => {
            onNewKeyValueMap({
              ...keyValueMap,
              "": "",
            });
          }}
        >
          <Add />
        </AddButton>
      </KeywordsValues>
    </KeywordsContainer>
  );
};

export default KeyValueEditor;
