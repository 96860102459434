import RecordsPlotSql from "components/RecordsPlotSql";
import SubTablePlot from "components/SubTablePlot";
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  TickIcon,
  WarningIcon,
} from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";
import TooltipNew from "components/ui/TooltipNew";
import { isNil } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
  align-items: center;
  padding-right: 0px;
  min-width: 200px;
  width: 100%;

  svg {
    height: 12px;
    fill: #8d8d8d;
    cursor: pointer;
    :hover {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
  align-items: center;
  padding-right: 0px;
  min-width: 200px;
  width: 100%;
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const TipContainer = styled.div`
  background-color: black;
  color: white;
  padding: 4px;
`;

const DisplayValue = styled.div`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid #c0c0c0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const Td = styled.td`
  border: 1px solid #ccc;
  padding: 4px;
  text-align: left;
`;

const Th = styled.th`
  font-weight: 500;
  padding: 4px;
  border: 1px solid #ccc;
  text-align: left;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const InputWithEditMode = ({
  style = {},
  value = null,
  onPressSave = newValue => {},
  rawValue = "",
  displayValue = "",
  type = "text",
  tableColumns = [],
}) => {
  const [plotType, setPlotType] = useState("");
  const [editedValue, setEditedValue] = useState(value);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const addNewRow = () => {
    const newRow = {};
    tableColumns.forEach(column => {
      newRow[column.name] = { value: "" };
    });
    setEditedValue([...(editedValue || []), newRow]);
  };

  const editRow = (rowIndex, columnName, newValue) => {
    const newEditedValue = editedValue.map((row, i) => {
      if (i === rowIndex) {
        return {
          ...row,
          [columnName]: { value: newValue },
        };
      }
      return row;
    });
    setEditedValue(newEditedValue);
  };

  if (type !== "table" && isEditMode) {
    return (
      <Container style={style}>
        <FieldInput
          style={style}
          type={type}
          value={editedValue}
          onChange={e => setEditedValue(e.target.value)}
        />
        <TickIcon
          onClick={() => {
            onPressSave(editedValue);
            setIsEditMode(false);
          }}
        />
        <CrossIcon
          onClick={() => {
            setEditedValue(value);
            setIsEditMode(false);
          }}
        />
      </Container>
    );
  }

  if (type === "table" && isEditMode) {
    return (
      <TableContainer style={{ alignItems: "start" }}>
        <SubTablePlot
          type={plotType}
          tableColumns={tableColumns}
          rows={editedValue}
          editRow={editRow}
          addNewRow={addNewRow}
        />

        <TickIcon
          onClick={() => {
            onPressSave(editedValue);
            setIsEditMode(false);
          }}
        />
        <CrossIcon
          onClick={() => {
            setEditedValue(value);
            setIsEditMode(false);
          }}
        />
        <select value={plotType} onChange={e => setPlotType(e.target.value)}>
          <option value="">Table</option>
          <option value="Bar Chart">Bar Chart</option>
          <option value="Pie Chart">Pie Chart</option>
          <option value="Line Chart">Line Chart</option>
        </select>
      </TableContainer>
    );
  }

  if (type === "table" && !isEditMode) {
    return (
      <TableContainer style={{ alignItems: "start" }}>
        <table>
          <thead>
            <tr>
              {tableColumns.map((column, index) => (
                <Th key={index}>{column?.name}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {tableColumns.map((column, columnIndex) => (
                  <Td key={columnIndex}>{row?.[column?.name]?.value}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div />
        <PencilIcon onClick={() => setIsEditMode(true)} />
      </TableContainer>
    );
  }

  if (rawValue && isNil(value)) {
    return (
      <Container style={style}>
        <TooltipNew
          tipContent={
            <TipContainer>
              <div>Could not parse:</div>
              <br />
              <div>"{rawValue}"</div>
              <br />
              <div>Please fill in manually</div>
            </TipContainer>
          }
          style={{
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          <DisplayValue>{rawValue}</DisplayValue>
          <WarningIcon style={{ fill: "#d4a600", cursor: "default" }} />
        </TooltipNew>
        <PencilIcon onClick={() => setIsEditMode(true)} />
      </Container>
    );
  }

  return (
    <Container style={style}>
      <DisplayValue>{displayValue || value}</DisplayValue>
      <div />
      <PencilIcon onClick={() => setIsEditMode(true)} />
    </Container>
  );
};

export default InputWithEditMode;
