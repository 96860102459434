import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const COLORS = [
  "#0191ff",
  "#9650ff",
  "#009933",
  "#d9534f",
  "#a4b9a8",
  "#ffcc00",
  "#ff6600",
  "#BB0000",
  "#cc0066",
  "#660066",
  "#4d4da5",
  "#ff00ff",
];

const RADIAN = Math.PI / 180;

const Container = styled.div`
  .recharts-legend-wrapper {
    transform: translateX(30px);
    height: 90px !important;
    overflow: hidden;
  }
`;

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
  /* width: 200px; */
`;

const Name = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

const NameAndInput = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 8px;
`;

export const ANIMATION_MS = 1800;

export const PieParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, valueColumnName } = params;

  if (isEditing) {
    return (
      <div>
        <NameAndInput>
          <Name>Category:</Name>
          <StyledSelect
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose category column --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>

        <NameAndInput>
          <Name>Value:</Name>
          <StyledSelect
            value={valueColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                valueColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose value column --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>
      </div>
    );
  }

  return (
    <WrapContainer>
      <Label
        style={{ backgroundColor: `${getColorFromString(xColumnName)}55` }}
      >
        {valueColumnName}
      </Label>
    </WrapContainer>
  );
};

const PieChartSmall = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
}) => {
  const { xColumnName, valueColumnName } = params;

  const data = records?.map(record => {
    let dataPoint = {
      name: record?.[xColumnName]?.value,
      [valueColumnName]: record?.[valueColumnName]?.value,
    };

    return dataPoint;
  });

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {data?.[index]?.[valueColumnName]}
      </text>
    );
  };

  return (
    <Container style={{ marginBottom: 40 }}>
      {/* <PieParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      /> */}
      <ResponsiveContainer width="80%" height={100}>
        <PieChart margin={{ bottom: 0 }}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={12}
            outerRadius={40}
            labelLine={false}
            label={renderCustomizedLabel}
            dataKey={valueColumnName}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            margin={{ left: 20 }}
            align="right"
            verticalAlign="top"
            layout="vertical"
          />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default PieChartSmall;
