import { useState, useEffect } from "react";
import styled from "styled-components";

import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { useSearchParams } from "react-router-dom";
import { useRef } from "react";
import { postAndStreamResponseCompare } from "api/api-http-methods";

const ModalContent = styled.div`
  width: 800px;
  height: 90vh;
  padding: 20px;
  position: relative;
  overflow: auto;
  align-content: start;

  display: grid;
  gap: 8px;
`;

const BoldDiv = styled.div`
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Text = styled.div`
  width: 100%;
  white-space: pre-wrap;
  border: 1px solid #ccc;
  padding: 8px;
`;

const TextArea = styled.textarea`
  height: 500px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
`;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}

const MODEL_OPTIONS = ["bz_llm", "llama3", "gemini", "gpt-4o", "gpt-4o-mini"];

const ReferenceDebugModal = ({ reference = {} }) => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState(reference?.llmPrompt || "");
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState("bz_llm");
  const [abortController, setAbortController] = useState(new AbortController());

  const textRef = useRef();

  useEffect(() => {
    setEditedPrompt(reference?.llmPrompt || "");
  }, [reference?.llmPrompt]);

  const doSendQuery = async () => {
    setIsLoading(true);
    textRef.current.innerText = "";

    const payload = {
      query: "",
      overridePrompt: editedPrompt,
      model,
      maxNewTokens: 600,
    };

    textRef.current.innerText = "";
    await postAndStreamResponseCompare({
      url: `${BASE_URL}/bz-api/v1/ai/streamed-plain-queries`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        const currentText = textRef.current.innerText;
        textRef.current.innerText = currentText + data?.delta;
      },
    });
    setIsLoading(false);
  };

  const doStopGeneration = () => {
    abortController.abort();
    setAbortController(new AbortController());
    setIsLoading(false);
  };

  const isVisible = searchParams.get("debug") === "true";

  return (
    <>
      <ButtonWord
        style={{
          display: isVisible ? "block" : "none",
          fontSize: 10,
          padding: 4,
          lineHeight: "10px",
        }}
        onClick={() => setIsOpen(true)}
      >
        p
      </ButtonWord>
      <Modal
        open={isVisible ? isOpen : false}
        handleClose={() => setIsOpen(false)}
      >
        <ModalContent>
          <BoldDiv>
            llmPrompt
            <button
              onClick={() => {
                navigator.clipboard.writeText(reference?.llmPrompt);
              }}
            >
              copy
            </button>
          </BoldDiv>
          <TextArea
            value={editedPrompt}
            onChange={e => setEditedPrompt(e.target.value)}
            style={{ marginBottom: 5 }}
          />

          <div style={{ display: "flex", gap: 10, justifySelf: "end" }}>
            <select
              disabled={isLoading}
              value={model}
              onChange={e => setModel(e.target.value)}
            >
              {MODEL_OPTIONS.map(model => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>

            <button disabled={isLoading} onClick={doSendQuery}>
              Run
            </button>

            <button disabled={!isLoading} onClick={doStopGeneration}>
              Stop
            </button>
          </div>

          <BoldDiv>
            llmReply
            <button
              onClick={() => {
                navigator.clipboard.writeText(reference?.llmReply);
              }}
            >
              copy
            </button>
          </BoldDiv>
          <Text ref={textRef}>{reference?.llmReply}</Text>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferenceDebugModal;
