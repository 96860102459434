import { zapiGet, zapiPost } from "api/zapi";

export const getSeedLinks = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/datacrawler/api/v1/seed-links`,
    params
  );
  return { data, error };
};

export const getLinks = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/datacrawler/api/v1/links`,
    params
  );
  return { data, error };
};

export const postSeedLink = async (body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/datacrawler/api/v1/seed-links`,
    null,
    body
  );
  return { data, error };
};
