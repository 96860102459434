import styled from "styled-components";

const ProgressBarContainer = styled.div`
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #d9d9d9;
`;

const Progress = styled.div.attrs(props => ({
  style: {
    width: `${(props.currentValue / props.maxValue) * 100}%`,
  },
}))`
  transition: width 0.1s;
  border-radius: 0px;
  position: absolute;
  height: 100%;
  max-width: 100%;
  background-color: ${props => props.theme.color.primary};
`;

const ProgressBar = ({ currentValue, maxValue, className, style = {} }) => (
  <ProgressBarContainer className={className} style={style}>
    <Progress currentValue={currentValue} maxValue={maxValue} />
  </ProgressBarContainer>
);

export default ProgressBar;
