import * as datacrawlerBackend from "api/backend/datacrawlerEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getSeedLinks = async (params = {}) => {
  const { data, error } = await datacrawlerBackend.getSeedLinks(params);
  return { data, error: getHandlingFromError(error) };
};

export const getLinks = async (params = {}) => {
  const { data, error } = await datacrawlerBackend.getLinks(params);
  return { data, error: getHandlingFromError(error) };
};

export const postSeedLink = async (body = {}) => {
  const { data, error } = await datacrawlerBackend.postSeedLink(body);
  return { data, error: getHandlingFromError(error) };
};
