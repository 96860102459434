import Button from "components/ui/Button";
import SelectInput from "components/ui/SelectInput";
import { useState } from "react";
import styled from "styled-components";

const Tip = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  width: max-content;
  ${props => {
    if (props.placement === "right") {
      return `
        left: auto;
        right: 0;
        top: 0;
        transform: translateX(100%) translateY(10%);
      `;
    }
    if (props.placement === "left") {
      return `
        left: 0;
        top: 0;
        transform: translateX(-100%) translateY(10%);
      `;
    }
    if (props.placement === "top") {
      return `
        top: 0;
        transform: translateX(-50%) translateY(-100%);
      `;
    }
  }}
  background-color: ${props => props.theme.color.furthest};
  z-index: 12;
  box-shadow: 0 8px 24px ${props => (props.theme.name === "light" ? "rgba(140, 149, 159, 0.2)" : "rgba(0, 0, 0, 0.4)")};
  transition: opacity 0.2s;
  color: ${props => props.theme.color.closest};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: ${props => (props.isVisible ? "all" : "none")};
`;

const EditedDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  position: absolute;
  top: 2px;
  right: 2px;
`;

const TipContainer = styled.div`
  width: 100%;
  height: 100%;
  line-height: 20px;
  text-overflow: ellipsis;
  max-width: 200px;

  position: relative;
  :hover {
    z-index: 10;
    ${Tip} {
      opacity: 1;
    }
  }
`;

const TextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AutomaticLineItemGridContainer = styled.div`
  padding: 10px;
  display: grid;
  gap: 5px;
  grid-template-columns: auto auto;
  border: 1px dashed ${props => props.theme.color.primary};
  align-items: center;
`;

const LabelText = styled.div``;

const Label = styled.div`
  font-weight: 600;
`;

const LabelColorGradient = styled.div`
  font-weight: 600;
  color: ${props => `rgb(${(1 - props.score) * 255}, ${props.score * 200}, 0)`};
`;

const CellEntityEditor = ({ cellEntity, onNewCellEntity }) => {
  return (
    <AutomaticLineItemGridContainer>
      <Label>Entity type:</Label>
      <SelectInput
        value={cellEntity?.type}
        onSetNewValue={newType => onNewCellEntity({ ...cellEntity, type: newType })}
      >
        <option>-</option>
        <option>TABLE_HEADER</option>
        <option>TABLE_CELL</option>
      </SelectInput>
    </AutomaticLineItemGridContainer>
  );
};

const TooltipEditable = ({ className, children, value, placement = "bottom", cellEntity, onNewCellEntity }) => {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <TipContainer className={className} onMouseLeave={() => setIsEditing(false)}>
        {!!cellEntity && <EditedDot />}
        {children}
        <Tip placement={placement} isVisible={isEditing}>
          <CellEntityEditor cellEntity={cellEntity} onNewCellEntity={onNewCellEntity} />
        </Tip>
      </TipContainer>
    );
  }

  return (
    <TipContainer className={className} onClick={() => setIsEditing(true)}>
      {!!cellEntity && <EditedDot />}
      <TextContainer>{children}</TextContainer>
      {value && <Tip placement={placement}>{value}</Tip>}
    </TipContainer>
  );
};

export default TooltipEditable;
