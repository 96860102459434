import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { BigTitle, SmallTitle } from "components/ui/Text";
import { useEffect } from "react";
import { getFiles } from "api/services/filesService";
import { getTagById } from "api/services/tagsService";

const PackageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 300px 200px 200px;
  align-items: center;

  gap: 20px;
  padding: 12px 20px;
  color: white;
  background: linear-gradient(to right, ${props => props.theme.color.feature}, ${props => props.theme.color.primary});

  font-weight: 600;
`;

const PackageRow = styled(HeaderRow)`
  align-items: start;
  font-weight: normal;
  color: ${props => props.theme.color.closest};

  background: none;
  background-color: ${props =>
    props.rowIndex % 2 !== 0 ? props.theme.color.closer0 : `${props.theme.color.closer1}33`};

  :hover {
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.closer1}BB;
  }

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

const TagPage = () => {
  const { tagId } = useParams();

  const [tag, setTag] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    doPopulateTag();
    doPopulateFiles();
  }, [tagId]);

  const doPopulateFiles = async () => {
    const { data } = await getFiles({ pageSize: 2 });
    setFiles(data?.items || []);
  };

  const doPopulateTag = async () => {
    const { data } = await getTagById(tagId);
    setTag(data);
  };

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>{tag?.name}</BigTitle>
      <Gap height="20px" />
      <SmallTitle>Files</SmallTitle>
      <PackageListContainer>
        <HeaderRow>
          <div>Name</div>
          <div>Created at</div>
          <div>Pages</div>
        </HeaderRow>
        {files?.map(file => (
          <PackageRow key={file?.id}>
            <Link to={`/file/${file?.id}`}>{file?.fileName}</Link>
            <div>{file?.createdAt}</div>
            <div>{file?.numberOfPages} pages</div>
          </PackageRow>
        ))}
      </PackageListContainer>
      <Gap height="40px" />
      <SmallTitle>Records</SmallTitle>
      <Gap height="40px" />
    </CenteredWithTopNavLayout>
  );
};

export default TagPage;
