import styled from "styled-components";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useState } from "react";
import { US_STATES } from "api/services/mapData";
import { useEffect } from "react";
import { set } from "lodash";
import { CrossIcon } from "components/ui/Icons";
import { Editor } from "@monaco-editor/react";
import { Code } from "@material-ui/icons";
import CodeModalTrigger from "components/CodeModalTrigger";

const MAP = {
  Arizona: {
    "Total Volume": 945463,
    "# of Deals": 52,
  },
  Arkansas: {
    "Total Volume": 597263,
    "# of Deals": 191,
  },
  California: {
    "Total Volume": 87881,
    "# of Deals": 0,
  },
  Colorado: {
    "Total Volume": 943207,
    "# of Deals": 65,
  },
  Connecticut: {
    "Total Volume": 618987,
    "# of Deals": 7,
  },
  "District of Columbia": {
    "Total Volume": 541696,
    "# of Deals": 139,
  },
  Georgia: {
    "Total Volume": 202704,
    "# of Deals": 140,
  },
  Illinois: {
    "Total Volume": 215072,
    "# of Deals": 58,
  },
  Indiana: {
    "Total Volume": 166242,
    "# of Deals": 46,
  },
  Louisiana: {
    "Total Volume": 751537,
    "# of Deals": 134,
  },
  Minnesota: {
    "Total Volume": 273962,
    "# of Deals": 138,
  },
  Mississippi: {
    "Total Volume": 916911,
    "# of Deals": 185,
  },
  Montana: {
    "Total Volume": 576350,
    "# of Deals": 65,
  },
  "New Mexico": {
    "Total Volume": 406168,
    "# of Deals": 160,
  },
  "North Dakota": {
    "Total Volume": 679802,
    "# of Deals": 138,
  },
  Oklahoma: {
    "Total Volume": 624177,
    "# of Deals": 35,
  },
  Pennsylvania: {
    "Total Volume": 696091,
    "# of Deals": 11,
  },
  Tennessee: {
    "Total Volume": 893305,
    "# of Deals": 62,
  },
  Virginia: {
    "Total Volume": 8745,
    "# of Deals": 183,
  },
  Delaware: {
    "Total Volume": 780297,
    "# of Deals": 156,
  },
  "West Virginia": {
    "Total Volume": 128256,
    "# of Deals": 111,
  },
  Wisconsin: {
    "Total Volume": 376558,
    "# of Deals": 184,
  },
  Wyoming: {
    "Total Volume": 641756,
    "# of Deals": 54,
  },
  Alabama: {
    "Total Volume": 398107,
    "# of Deals": 162,
  },
  Florida: {
    "Total Volume": 915861,
    "# of Deals": 171,
  },
  Idaho: {
    "Total Volume": 114977,
    "# of Deals": 49,
  },
  Kansas: {
    "Total Volume": 545786,
    "# of Deals": 77,
  },
  Maryland: {
    "Total Volume": 95108,
    "# of Deals": 165,
  },
  "New Jersey": {
    "Total Volume": 804746,
    "# of Deals": 141,
  },
  "North Carolina": {
    "Total Volume": 636185,
    "# of Deals": 76,
  },
  "South Carolina": {
    "Total Volume": 579506,
    "# of Deals": 142,
  },
  Washington: {
    "Total Volume": 463803,
    "# of Deals": 158,
  },
  Vermont: {
    "Total Volume": 750067,
    "# of Deals": 58,
  },
  Utah: {
    "Total Volume": 490903,
    "# of Deals": 16,
  },
  Iowa: {
    "Total Volume": 207862,
    "# of Deals": 141,
  },
  Kentucky: {
    "Total Volume": 323781,
    "# of Deals": 64,
  },
  Maine: {
    "Total Volume": 546666,
    "# of Deals": 74,
  },
  Massachusetts: {
    "Total Volume": 879000,
    "# of Deals": 168,
  },
  Michigan: {
    "Total Volume": 509158,
    "# of Deals": 22,
  },
  Missouri: {
    "Total Volume": 692269,
    "# of Deals": 133,
  },
  Nebraska: {
    "Total Volume": 709836,
    "# of Deals": 135,
  },
  Nevada: {
    "Total Volume": 341993,
    "# of Deals": 159,
  },
  "New Hampshire": {
    "Total Volume": 277602,
    "# of Deals": 184,
  },
  "New York": {
    "Total Volume": 44060,
    "# of Deals": 27,
  },
  Ohio: {
    "Total Volume": 3102,
    "# of Deals": 166,
  },
  Oregon: {
    "Total Volume": 657127,
    "# of Deals": 13,
  },
  "Rhode Island": {
    "Total Volume": 34252,
    "# of Deals": 24,
  },
  "South Dakota": {
    "Total Volume": 560642,
    "# of Deals": 154,
  },
  Texas: {
    "Total Volume": 233943,
    "# of Deals": 69,
  },
  Alaska: {
    "Total Volume": 115989,
    "# of Deals": 69,
  },
  Hawaii: {
    "Total Volume": 353433,
    "# of Deals": 26,
  },
};

const MapContainer = styled.div`
  border: 1px solid ${props => props.theme.color.closer0};
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const StyledGeography = styled(Geography)`
  :hover {
    stroke: black;
    z-index: 100;
    stroke-width: 2px;
  }
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledLabel = styled.label`
  width: max-content;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const getStateNameToAlphas = stateNameToFields => {
  const fieldNames = Object.keys(Object.values(stateNameToFields)?.[0] || {});
  const fieldNameToMinAndMax = {};
  fieldNames.forEach(fieldName => {
    const values = Object.values(stateNameToFields).map(
      stateNameToField => stateNameToField[fieldName]
    );
    const min = Math.min(...values);
    const max = Math.max(...values);
    fieldNameToMinAndMax[fieldName] = { min: 0, max };
  });

  const stateNameToAlphas = {};
  Object.entries(stateNameToFields).forEach(([stateName, stateNameToField]) => {
    const alphas = {};
    Object.entries(stateNameToField).forEach(([fieldName, fieldValue]) => {
      const { min, max } = fieldNameToMinAndMax[fieldName] || {};
      const alpha = (fieldValue - min) / (max - min);
      alphas[fieldName] = alpha;
    });
    stateNameToAlphas[stateName] = alphas;
  });

  return stateNameToAlphas;
};

const RecordsSummaryGeomap = ({ records, style = {}, mapWidth = 420 }) => {
  const [fieldName, setFieldName] = useState("");
  const fieldNames = Object.keys(records[0] || {})?.filter(
    name => name !== "State"
  );

  useEffect(() => {
    setFieldName(fieldNames[0]);
  }, [JSON.stringify(fieldNames)]);

  const stateNameToFields = {};
  records.forEach(record => {
    const stateName = record["State"]?.value;
    stateNameToFields[stateName] = {};
    fieldNames.forEach(fieldName => {
      stateNameToFields[stateName][fieldName] = record[fieldName]?.value;
    });
  });

  const stateNameToAlphas = getStateNameToAlphas(stateNameToFields);

  const mapHeight = 250;

  return (
    <MapContainer style={{ ...style, height: mapHeight }}>
      <div
        style={{
          display: "flex",
          gap: "8px",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {/* <StyledSelect
          value={fieldName}
          onChange={e => setFieldName(e.target.value)}
        >
          {fieldNames.map(fieldName => (
            <option>{fieldName}</option>
          ))}
        </StyledSelect> */}
        <div
          style={{
            padding: "4px",
            display: "grid",
            gap: "8px",
            backgroundColor: "#ffffff88",
          }}
        >
          {fieldNames?.map(fieldNameOption => (
            <StyledLabel
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
            >
              <input
                style={{ margin: 0 }}
                type="radio"
                name="fieldName"
                checked={fieldNameOption === fieldName}
                onChange={e => setFieldName(fieldNameOption)}
              />
              {fieldNameOption}
            </StyledLabel>
          ))}
        </div>
      </div>
      <ComposableMap
        projection="geoAlbersUsa"
        projectionConfig={{
          scale: 520,
          center: [-100, 37],
        }}
        width={mapWidth}
        height={mapHeight}
      >
        <Geographies geography={US_STATES}>
          {({ geographies }) =>
            geographies.map((geo, i) => {
              const stateName = geo.properties.name;
              const alpha = stateNameToAlphas?.[stateName]?.[fieldName];

              return (
                <StyledGeography
                  // onMouseEnter={e => setStateInfo(geo)}
                  // onMouseLeave={e => setStateInfo(null)}
                  key={geo.rsmKey}
                  geography={geo}
                  stroke="darkgray"
                  strokeWidth={0.5}
                  fill={`rgba(150, 80, 255, ${alpha || 0})`}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </MapContainer>
  );
};

export default RecordsSummaryGeomap;
