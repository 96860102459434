import * as backend from "api/backend/quickStartsEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const postQuickStart = async (params = {}, body = {}) => {
  const { data, error } = await backend.postQuickStart(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getQuickStarts = async (params = {}) => {
  const { data, error } = await backend.getQuickStarts(params);
  return { data, error: getHandlingFromError(error) };
};

export const deleteQuickStart = async (id = "", params = {}) => {
  const { data, error } = await backend.deleteQuickStart(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const patchQuickStart = async (id = "", params = {}, body = {}) => {
  const { data, error } = await backend.patchQuickStart(id, params, body);
  return { data, error: getHandlingFromError(error) };
};
