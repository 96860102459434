import { zapiGet, zapiPost } from "api/zapi";

export const postDocImagesUploadImage = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/doc-images/${id}/upload-image`,
    params,
    body
  );
  return { data, error };
};

export const getDocImagesLoadImage = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/doc-images/load-image`,
    params
  );
  return { data, error };
};
