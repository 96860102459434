import { isMatch, range, sortBy, uniq } from "lodash";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getIntentionRecordsFromEmails } from "api/services/searchService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import TableSortable from "components/TableSortable";
import { parseJson } from "utils/common";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import LoadingSpinner from "components/ui/LoadingSpinner";
import PlotsModal from "components/widgets/PlotsModal";
import MultiSelectWithFilter from "components/ui/MultiSelectWithFilter";
import { getEmailContent } from "api/services/emailService";
import { getEmail, triggerDownloadOfEmailAttachment } from "api/backend/emailServiceEndpoints";
import { Replay } from "@material-ui/icons";
import { format } from "date-fns";

const getAllKeysFromObjects = objects => {
  const allKeys = objects?.map(object => Object?.keys(object))?.flat();
  return [...new Set(allKeys)];
};

const safeFormat = (date, formatString) => {
  try {
    return format(new Date(date), formatString);
  } catch {
    return "";
  }
};

const getFilteredRecords = (records, filterByColumns) => {
  const doesRecordMatchSomeValuesWithinSameColumn = record =>
    filterByColumns?.every(columnToValue => {
      const colName = Object.keys(columnToValue)?.[0];
      const allValuesOfColumn = filterByColumns?.filter(filterByColumns => !!filterByColumns?.[colName]);

      return allValuesOfColumn?.some(filterByColumn => isMatch(record?.row, filterByColumn));
    });

  const filteredRecords = records.filter(doesRecordMatchSomeValuesWithinSameColumn);
  if (filteredRecords?.length === 0) {
    const emptyRecord = { row: {} };
    Object.keys(records?.[0]?.row || {}).forEach(key => (emptyRecord.row[key] = ""));

    return [emptyRecord];
  }

  return filteredRecords;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 50px;
  padding-bottom: 100px;

  grid-template-areas:
    ". refetch see-plot"
    "filters table table";
`;

const RefetchButton = styled(Button)`
  grid-area: refetch;
  width: max-content;
  justify-self: end;
  padding: 10px;
`;

const Filters = styled.div`
  grid-area: filters;
  display: grid;
  gap: 20px;
  align-content: start;
`;

const ClearButton = styled(Button)`
  justify-self: end;
`;

const PlacedTable = styled(TableSortable)`
  grid-area: table;
  padding-top: 20px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  border-radius: 10px;
  width: 1000px;
  height: 700px;
  overflow: auto;
  padding: 10px;
`;

const LoadingContainer = styled.div`
  padding-top: 200px;
`;

const EmailHtmlContainer = styled.div`
  #${props => props.rowIdToHighlight} {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const SeePlotButton = styled(Button)`
  grid-area: see-plot;
  justify-self: end;
`;

const DownloadAttachmentsButtons = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
`;

const SmallButton = styled(Button)`
  padding: 0;
`;

const ViewEmailModal = ({ fileIdToView, rowIdToHighlight, open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [emailHtml, setEmailHtml] = useState("");

  const [numberOfAttachments, setNumberOfAttachments] = useState(0);

  useEffect(() => {
    doPopulateEmailContentAndNumberOfAttachments();
  }, [fileIdToView]);

  const doPopulateEmailContentAndNumberOfAttachments = async () => {
    if (!fileIdToView) {
      return;
    }

    setIsLoading(true);
    setEmailHtml("");
    const { data } = await getEmailContent(fileIdToView);
    setEmailHtml(data);

    const { data: email } = await getEmail(fileIdToView);
    setNumberOfAttachments(email?.numberOfAttachments);

    setIsLoading(false);
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <DownloadAttachmentsButtons>
          {range(0, numberOfAttachments).map(attachmentNumber => (
            <SmallButton
              value={`Download attachment ${attachmentNumber + 1}`}
              onClick={() => triggerDownloadOfEmailAttachment(fileIdToView, attachmentNumber)}
            />
          ))}
        </DownloadAttachmentsButtons>
        {isLoading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {!isLoading && (
          <EmailHtmlContainer rowIdToHighlight={rowIdToHighlight} dangerouslySetInnerHTML={{ __html: emailHtml }} />
        )}
      </ModalContent>
    </Modal>
  );
};

const MultiSelectForColumnValues = ({
  displayName,
  columnName,
  allIntentionRecords,
  filterByColumns,
  setFilterByColumns,
}) => {
  return (
    <MultiSelectWithFilter
      title={displayName || columnName}
      options={uniq(allIntentionRecords?.map(record => record?.row?.[columnName]))}
      selectedOptions={filterByColumns
        .filter(columnToValue => columnToValue?.[columnName])
        ?.map(columnToValue => columnToValue?.[columnName])}
      onSelectOptions={selectedColumnValues => {
        const filteredByColumnsWithoutField = filterByColumns.filter(columnToValue => !columnToValue?.[columnName]);
        const newFilteredByColumnsOfField = selectedColumnValues.map(selectedColumnValue => ({
          [columnName]: selectedColumnValue,
        }));
        setFilterByColumns([...filteredByColumnsWithoutField, ...newFilteredByColumnsOfField]);
      }}
    />
  );
};

const MultiSelectForColumnTimeValues = ({
  displayName,
  columnName,
  allIntentionRecords,
  filterByColumns,
  setFilterByColumns,
}) => {
  const timeOptions = uniq(allIntentionRecords?.map(record => record?.row?.[columnName]))?.sort((a, b) => a - b);

  const [startFrom, setStartFrom] = useState(timeOptions?.[0]);
  const [endAt, setEndAt] = useState(timeOptions?.[0]);

  const selectedOptions = filterByColumns
    .filter(columnToValue => columnToValue?.[columnName])
    ?.map(columnToValue => columnToValue?.[columnName]);

  useEffect(() => {
    const timeOptions = uniq(allIntentionRecords?.map(record => record?.row?.[columnName]));
    setStartFrom(timeOptions?.[0]);
    setEndAt(timeOptions?.[0]);
  }, [allIntentionRecords]);

  useEffect(() => {
    const newSelectedColumnValues = timeOptions?.filter(timeOption => timeOption >= startFrom && timeOption <= endAt);

    const filteredByColumnsWithoutField = filterByColumns.filter(columnToValue => !columnToValue?.[columnName]);
    const newFilteredByColumnsOfField = newSelectedColumnValues.map(selectedColumnValue => ({
      [columnName]: selectedColumnValue,
    }));
    setFilterByColumns([...filteredByColumnsWithoutField, ...newFilteredByColumnsOfField]);
  }, [startFrom, endAt]);

  return (
    <>
      <div>
        <select
          value={startFrom}
          onChange={e => {
            setStartFrom(parseInt(e.target.value));
          }}
        >
          {timeOptions?.map(timeOption => (
            <option value={timeOption}>{safeFormat(timeOption, "yyyy-MM-dd HH:mm:ss")}</option>
          ))}
        </select>
        <select
          value={endAt}
          onChange={e => {
            const endAt = parseInt(e.target.value);
            setEndAt(endAt);
          }}
        >
          {timeOptions?.map(timeOption => (
            <option value={timeOption}>{safeFormat(timeOption, "yyyy-MM-dd HH:mm:ss")}</option>
          ))}
        </select>
      </div>
      <MultiSelectWithFilter
        title={displayName || columnName}
        options={timeOptions}
        selectedOptions={selectedOptions}
        onSelectOptions={selectedColumnValues => {
          const filteredByColumnsWithoutField = filterByColumns.filter(columnToValue => !columnToValue?.[columnName]);
          const newFilteredByColumnsOfField = selectedColumnValues.map(selectedColumnValue => ({
            [columnName]: selectedColumnValue,
          }));
          setFilterByColumns([...filteredByColumnsWithoutField, ...newFilteredByColumnsOfField]);
        }}
      />
    </>
  );
};

const SearchEmailsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [allIntentionRecords, setAllIntentionRecords] = useState([]);
  const [isFetchingRecords, setIsFetchingRecords] = useState(false);
  const [error, setError] = useState(null);

  const [sortByColumn, setSortByColumn] = useState({ colName: "time", direction: "desc" });
  const [filterByColumns, setFilterByColumns] = useState([]);

  const fileIdToView = searchParams?.get("fileIdToView");
  const rowIdToHighlight = searchParams?.get("rowIdToHighlight");

  const [isPlotsModalOpen, setIsPlotsModalOpen] = useState(false);

  const [columnsToInclude, setColumnsToInclude] = useState([]);

  useEffect(() => {
    doPopulateAllIntentionRows();
  }, []);

  const doPopulateAllIntentionRows = async () => {
    setIsFetchingRecords(true);
    const { data, error } = await getIntentionRecordsFromEmails();
    const parsedRecords = data?.map(intention => parseJson(intention?.value));

    setAllIntentionRecords(parsedRecords);
    setColumnsToInclude(getAllKeysFromObjects(parsedRecords?.map(record => record?.row)));
    setError(error);
    setIsFetchingRecords(false);
  };

  let sortedRecords = sortBy(allIntentionRecords, [record => record?.row?.[sortByColumn?.colName]]);
  if (sortByColumn.direction === "desc") {
    sortedRecords = sortedRecords.reverse();
  }

  const sortedAndFilteredRecords = getFilteredRecords(sortedRecords, filterByColumns);
  const allColNames = getAllKeysFromObjects(allIntentionRecords?.map(record => record?.row));

  return (
    <CenteredWithTopNavLayout centerColumnMaxWidth="calc(100% - 200px)">
      {error && <div>{JSON.stringify(error)}</div>}
      <Container>
        <RefetchButton icon={<Replay />} onClick={doPopulateAllIntentionRows} />
        <SeePlotButton value="Plot" onClick={() => setIsPlotsModalOpen(true)} />
        <Filters>
          <MultiSelectWithFilter
            title="Columns"
            options={allColNames}
            selectedOptions={columnsToInclude}
            onSelectOptions={newCols => setColumnsToInclude(newCols)}
          />
          <Gap />
          <MultiSelectForColumnTimeValues
            displayName="Time"
            columnName="time"
            allIntentionRecords={allIntentionRecords}
            filterByColumns={filterByColumns}
            setFilterByColumns={setFilterByColumns}
          />
          <MultiSelectForColumnValues
            displayName="Entity Name"
            columnName="entityName"
            allIntentionRecords={allIntentionRecords}
            filterByColumns={filterByColumns}
            setFilterByColumns={setFilterByColumns}
          />
          <MultiSelectForColumnValues
            displayName="Trader Name"
            columnName="traderName"
            allIntentionRecords={allIntentionRecords}
            filterByColumns={filterByColumns}
            setFilterByColumns={setFilterByColumns}
          />
          <MultiSelectForColumnValues
            displayName="ISIN"
            columnName="isin"
            allIntentionRecords={allIntentionRecords}
            filterByColumns={filterByColumns}
            setFilterByColumns={setFilterByColumns}
          />
          <MultiSelectForColumnValues
            displayName="Maturity"
            columnName="maturity"
            allIntentionRecords={allIntentionRecords}
            filterByColumns={filterByColumns}
            setFilterByColumns={setFilterByColumns}
          />
          <MultiSelectForColumnValues
            displayName="Currency"
            columnName="currency"
            allIntentionRecords={allIntentionRecords}
            filterByColumns={filterByColumns}
            setFilterByColumns={setFilterByColumns}
          />
          <ClearButton
            value="Clear"
            onClick={() => {
              setFilterByColumns([]);
              setColumnsToInclude(allColNames);
              setSortByColumn({ colName: "time", direction: "desc" });
            }}
          />
        </Filters>
        <PlacedTable
          isDisabled={isFetchingRecords}
          columnsToInclude={columnsToInclude}
          records={sortedAndFilteredRecords}
          sortByColumn={sortByColumn}
          onClickSort={(colName, direction) => {
            setSortByColumn({ colName, direction });
          }}
        />
      </Container>
      <ViewEmailModal
        fileIdToView={fileIdToView}
        rowIdToHighlight={rowIdToHighlight}
        open={!!fileIdToView}
        handleClose={() => {
          navigate("?");
        }}
      />
      <PlotsModal
        open={isPlotsModalOpen}
        handleClose={() => setIsPlotsModalOpen(false)}
        records={sortedAndFilteredRecords}
      />
    </CenteredWithTopNavLayout>
  );
};

export default SearchEmailsPage;
