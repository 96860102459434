import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  deleteMetasRemoveKey,
  getMetas,
  patchMetasUpdate,
} from "api/services/chatService";
import { CrossIcon, PlusIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import TextInputSquare from "components/inputs/TextInputSquare";
import ErrorMessageModal from "components/ErrorMessageModal";
import BlackTooltip from "components/ui/BlackTooltip";
import TooltipNew from "components/ui/TooltipNew";

const COLORS = [
  "#7aecec",
  "#bfeeb7",
  "#feca74",
  "#ff9561",
  "#aa9cfc",
  "#c887fb",
  "#9cc9cc",
  "#ffeb80",
  "#ff8197",
  "#ff8197",
  "#f0d0ff",
  "#8bd2c1",
  "#bfe1d9",
  "#c8d387",
];

const getColorFromString = string => {
  if (typeof string !== "string") {
    return COLORS?.[0];
  }

  const digits = 1;
  var m = Math.pow(10, digits + 1) - 1;
  var phi = Math.pow(10, digits) / 2 - 1;
  var n = 0;
  for (var i = 0; i < string?.length; i++) {
    n = (n + phi * string?.charCodeAt(i)) % m;
  }
  const zeroTo100 = n.toString();

  const colorIndex = Math.floor((zeroTo100 / 100) * COLORS?.length);

  return COLORS?.[colorIndex] || "#e1e1e1";
};

const StyledPlusIcon = styled(PlusIcon)`
  height: 12px;
  transition: opacity 0.2s;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ModalContent = styled.div`
  ${props => props.isGreyedOut && "opacity: 0.5; pointer-events: none;"}
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 8px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  cursor: pointer;

  position: absolute;
  right: 4px;
`;

const Tag = styled.div`
  position: relative;
  max-width: 120px;
  width: max-content;
  padding: 4px 12px;

  background-color: ${props => props.bgColor}44;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  /* max-width: 100px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: 2px solid;
  border-image: linear-gradient(
    ${props => props.bgColor},
    ${props => props.bgColor}
  );

  :hover {
    ${StyledCrossIcon} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

const StyledBlackTooltip = styled(TooltipNew)``;

const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 600px;
`;

const AddNewMetaModalTrigger = ({
  fileMetas = [],
  className,
  onClickSave = async (key, value) => {},
  doDeleteMeta = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [isGreyedOut, setIsGreyedOut] = useState(false);

  useEffect(() => {
    setNewKey("");
    setNewValue("");
  }, [isOpen]);

  return (
    <>
      <TriggerContainer onClick={() => setIsOpen(true)}>
        {fileMetas?.length > 8 && <div>({fileMetas?.length} tags)</div>}
        <StyledPlusIcon className={className} />
      </TriggerContainer>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isGreyedOut={isGreyedOut}>
          <TagsContainer>
            {fileMetas.map((meta, i) => (
              <StyledBlackTooltip
                style={{ display: "block" }}
                tipText={meta?.key || ""}
              >
                <Tag key={i} bgColor={getColorFromString(meta?.key)}>
                  {typeof meta?.value === "string"
                    ? meta?.value
                    : JSON.stringify(meta?.value)}
                  <StyledCrossIcon
                    onClick={() => doDeleteMeta(meta?.key)}
                    height="10px"
                    style={{ backgroundColor: getColorFromString(meta?.key) }}
                  />
                </Tag>
              </StyledBlackTooltip>
            ))}
          </TagsContainer>
          <ModalTitle>Add new tag</ModalTitle>
          <TextInputSquare
            placeholder="key"
            value={newKey}
            onNewInput={newVal => setNewKey(newVal)}
          />
          <TextInputSquare
            placeholder="value"
            value={newValue}
            onNewInput={newVal => setNewValue(newVal)}
          />
          <Button
            isDisabled={!newKey || !newValue}
            value="Save"
            onClick={async () => {
              setIsGreyedOut(true);
              await onClickSave(newKey, newValue);
              setIsGreyedOut(false);
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

const FileTagFetcher = ({ fileId, isReadOnly = false }) => {
  const [fileMetas, setFileMetas] = useState([]);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    doPopulateTagData();
  }, [fileId]);

  const doPopulateTagData = async () => {
    const { data, error } = await getMetas({ fileId });
    if (error) {
      setIsDisabled(true);
      return;
    }
    setFileMetas(data);
  };

  const doDeleteMeta = async key => {
    // setIsDisabled(true);
    // const { error } = await deleteMetasRemoveKey(fileId, {}, { key });
    // setError(error);
    // setIsDisabled(false);
    // if (!error) {
    //   const newFileMetas = fileMetas?.filter(meta => meta?.key !== key);
    //   setFileMetas(newFileMetas);
    // }
  };

  const doPatchNewMeta = async (key, value) => {
    // const { error } = await patchMetasUpdate(fileId, {}, { key, value });
    // setError(error);
    // if (!error) {
    //   setFileMetas([...fileMetas, { fileId, key, value }]);
    // }
  };

  return (
    <Container isDisabled={isDisabled}>
      {fileMetas.map((meta, i) => (
        <StyledBlackTooltip
          style={{ display: "block" }}
          tipText={meta?.key || ""}
        >
          <Tag key={i} bgColor={getColorFromString(meta?.key)}>
            {typeof meta?.value === "string"
              ? meta?.value
              : JSON.stringify(meta?.value)}
            {/* <StyledCrossIcon
              onClick={() => doDeleteMeta(meta?.key)}
              height="10px"
              style={
                isReadOnly
                  ? {
                      opacity: 0,
                      pointerEvents: "none",
                      backgroundColor: getColorFromString(meta?.key),
                    }
                  : { backgroundColor: getColorFromString(meta?.key) }
              }
            /> */}
          </Tag>
        </StyledBlackTooltip>
      ))}
      {/* {!isReadOnly && (
        <AddNewMetaModalTrigger
          doDeleteMeta={doDeleteMeta}
          fileMetas={fileMetas}
          onClickSave={doPatchNewMeta}
        />
      )} */}
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </Container>
  );
};

export default FileTagFetcher;
