import { getExcelModelSizerFillUpProgress } from "api/services/ccService";
import { useState, useEffect } from "react";

const usePollExcelModelProgress = excelModelId => {
  const [progress, setProgress] = useState(0);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!excelModelId) {
      setProgress(0);
      return;
    }
    doPopulateProgress();

    const intervalId = setInterval(doPopulateProgress, 2000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [excelModelId]);

  useEffect(() => {
    if (progress === 1 || !!error) {
      clearInterval(pollIntervalId);
    }

    return () => clearInterval(pollIntervalId);
  }, [progress, error]);

  const doPopulateProgress = async () => {
    const { data, error } = await getExcelModelSizerFillUpProgress(excelModelId);
    setProgress(parseFloat(data) || 0);
    setError(error);
  };

  return progress;
};

export default usePollExcelModelProgress;
