import { postUsers } from "api/backend/authenticationServiceEndpoints";
import { postContactUsDetails } from "api/backend/marketingServiceEndpoints";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { BoltzhubLogoInner, CrossIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { COLOR2, SwitchLinksBar } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const Container = styled.div`
  display: grid;
  align-items: center;
  height: 100vh;
  background-color: black;
  padding: 50px;

  @media (min-width: 1240px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 1240px) {
    padding: 0;
  }
`;

const ContentCard = styled.div`
  color: white;
  padding: 10px;
  font-size: 16px;
  display: grid;
  gap: 0px;
  padding: 28px;
  border-radius: 20px;
  background-color: #232627;
  height: 100%;

  display: grid;
  align-content: center;
  justify-content: center;
`;

const CloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: white;
  height: 30px;
  cursor: pointer;

  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 40px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
  position: absolute;
  transform: translateX(-115%);
`;

const Msg = styled.div`
  font-size: 12px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;

  @media (min-width: 1240px) {
    width: 500px;
  }

  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid transparent;
  background-color: #141718;
  margin-bottom: 16px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 14px;
  font-weight: 500;

  height: 52px;
  padding: 14px 16px 14px 16px;
  gap: 12px;
  border-radius: 12px;

  ::placeholder {
    color: #80878baa;
  }
`;

const ErrorMsg = styled.div`
  color: #ff000094;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: start;
`;

const LeftContainer = styled.div`
  display: none;
  padding-right: 50px;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;

  background: linear-gradient(88.86deg, #ffffff 60.62%, #6f6f6f 105.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const LeftSubTitle = styled.div`
  font-size: 20px;
  color: #e8ecef80;
  line-height: 1.4;
  margin-bottom: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const TermsText = styled.div`
  width: 500px;
  line-height: 1.4;
  text-align: center;
  font-size: 12px;
  * {
    color: white;
    font-size: 12px;
  }
`;

const TermsLink = styled.a`
  font-weight: 500;
`;

const FIELDS = [
  {
    name: "email",
    label: "Your email",
    placeholder: "ada@mail.com",
  },
];

const TERMS_CONTENT_GENERAL = (
  <TermsText style={{ marginTop: "20px", justifySelf: "center" }}>
    Click here for&nbsp;
    <TermsLink style={{ justifySelf: "center" }} href="/terms" target="_blank">
      Terms of Services
    </TermsLink>
    &nbsp;and&nbsp;
    <TermsLink
      style={{ justifySelf: "center" }}
      href="/privacy-policy"
      target="_blank"
    >
      Privacy Policy
    </TermsLink>
  </TermsText>
);

export const TERMS_CONTENT_SIGNUP = (
  <TermsText style={{ marginTop: "20px" }}>
    By creating an account you agree to our&nbsp;
    <TermsLink style={{ justifySelf: "center" }} href="/terms" target="_blank">
      Terms of Services
    </TermsLink>
    &nbsp;and&nbsp;
    <TermsLink
      style={{ justifySelf: "center" }}
      href="/privacy-policy"
      target="_blank"
    >
      Privacy Policy
    </TermsLink>
  </TermsText>
);

const SUCCESS_MSG =
  "Thank you. Please check your email to log in to Boltzflow.";
const EXISTS_MSG =
  "You are already on the waitlist. Please check your email for a confirmation link.";

const SignupPageNew = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [payload, setPayload] = useState({ email: "" });
  const [hasSigndUp, setHasSigndUp] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const signUpMsg = searchParams.get("signUpMsg");

  useEffect(() => {
    if (!signUpMsg) {
      setIsOpen(false);
      return;
    }

    setIsOpen(true);
  }, [signUpMsg]);

  const doSend = async () => {
    if (!payload?.email) {
      setErrorMsg("Please enter your email.");
      return;
    }

    setErrorMsg("");
    setIsSending(true);
    const { error } = await postUsers(
      {},
      {
        email: payload?.email,
        userName: uuidv4(),
      }
    );
    setIsSending(false);
    if (error) {
      // if (error?.message === "Email has already been used to sign up.") {
      //   setErrorMsg(EXISTS_MSG);
      //   return;
      // }
      setErrorMsg(error?.message || "There was an error. Please try again.");
      return;
    }

    setHasSigndUp(true);
  };

  const closeModal = () => {
    searchParams.delete("signUpMsg");
    setIsOpen(false);
    navigate({ search: searchParams.toString() });
  };

  let modalContent = null;
  if (!hasSigndUp) {
    modalContent = (
      <>
        <Gap />
        <SwitchLinksBar />
        <Gap height="10px" />
        <Msg>{signUpMsg}</Msg>
        <Gap height="30px" />
        {FIELDS?.map(field => (
          <Input
            key={field?.name}
            value={payload[field.name]}
            onChange={e =>
              setPayload({ ...payload, [field.name]: e.target.value })
            }
            placeholder={field.label}
          />
        ))}
        <Gap height="94px" />
        <ErrorMsg>{errorMsg}</ErrorMsg>
        <ButtonWord
          style={{ height: 40, fontSize: 16, opacity: isSending ? 0.5 : 1 }}
          isPrimary
          onClick={doSend}
        >
          Sign up
        </ButtonWord>
        {TERMS_CONTENT_SIGNUP}
        {/* <Msg style={{ paddingTop: 20 }}>
          Already have an account? <a href="/login">Login</a>
        </Msg> */}
      </>
    );
  }

  if (hasSigndUp) {
    modalContent = (
      <>
        <Gap height="40px" />
        <Msg
          style={{
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.4,
            color: "white",
            width: 500,
          }}
        >
          {SUCCESS_MSG}
        </Msg>
        <Gap height="60px" />
        <ButtonWord
          style={{
            height: 52,
            width: "100%",
            justifySelf: "center",
          }}
          isPrimary
          onClick={() => navigate("/chat")}
        >
          Go back to free chat
        </ButtonWord>
        {TERMS_CONTENT_GENERAL}
      </>
    );
  }

  return (
    <Container>
      <LeftContainer>
        <LeftTitle>The AI you can trust.</LeftTitle>
        <LeftSubTitle>
          Search, chat, write, analyse, code, and plan for your everyday work
          and life.
        </LeftSubTitle>
        <Img src="/images/signup-img.png" />
      </LeftContainer>
      <ContentCard>
        <Title style={{ justifySelf: "center", marginBottom: "20px" }}>
          <StyledBoltzbitLogo />
          Boltzbit
        </Title>
        {modalContent}
      </ContentCard>
    </Container>
  );
};

export default SignupPageNew;
