import { useState } from "react";
import styled from "styled-components";

import RecordsSummaryBarChart from "components/chat-plots/RecordsSummaryBarChart";
import RecordsSummaryPieChart from "components/chat-plots/RecordsSummaryPieChart";
import RecordsSummaryTable from "components/chat-plots/RecordsSummaryTable";
import RecordsSummaryLineChart from "components/chat-plots/RecordsSummaryLineChart";
import { Code } from "@material-ui/icons";
import { Editor } from "@monaco-editor/react";

const PlotContainer = styled.div`
  position: relative;
  margin-top: 8px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const StyledEditor = styled(Editor)`
  height: 300px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const PlotSelect = styled.select`
  z-index: 1;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ShowCodeBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #eaeaea;
  }
  svg {
    fill: ${props => props.isActive && props.theme.color.primary};
  }
`;

const ControlButtons = styled.div`
  /* position: absolute;
  right: 0;
  transform: translate(0, -100%); */
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: start;

  /* position: absolute;
  left: -20px; */
  /* transform: translate(-105%, 0); */
`;

const EditorContainer = styled.div``;

const getPlotTypeFromQuery = (query = "") => {
  if (query.toLowerCase().includes("bar")) {
    return "bar";
  }
  if (query.toLowerCase().includes("time")) {
    return "time_series";
  }
  if (query.toLowerCase().includes("pie")) {
    return "pie_chart";
  }

  return "table";
};

const PLOT_TYPES = ["table", "bar", "time_series", "pie_chart"];

const RecordsPlot = ({
  genCode = "",
  records,
  columns,
  initialPlotType = "table",
}) => {
  const [plotType, setPlotType] = useState(initialPlotType);
  const [isShowingCode, setIsShowingCode] = useState(false);

  const typeToComponent = {
    table: <RecordsSummaryTable records={records} columns={columns} />,
    bar: <RecordsSummaryBarChart records={records} columns={columns} />,
    time_series: (
      <RecordsSummaryLineChart records={records} columns={columns} />
    ),
    pie_chart: <RecordsSummaryPieChart records={records} columns={columns} />,
  };

  return (
    <PlotContainer style={plotType === "table" ? { border: "none" } : {}}>
      <ControlButtons>
        <PlotSelect
          value={plotType}
          onChange={e => setPlotType(e.target.value)}
        >
          {PLOT_TYPES.map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </PlotSelect>
        <ShowCodeBtn
          isActive={isShowingCode}
          onClick={() => setIsShowingCode(prev => !prev)}
        >
          <Code />
        </ShowCodeBtn>
      </ControlButtons>
      {!isShowingCode && typeToComponent[plotType]}
      {isShowingCode && (
        <EditorContainer>
          <StyledEditor
            options={{
              readOnly: true,
            }}
            language="python"
            value={genCode}
          />
        </EditorContainer>
      )}
    </PlotContainer>
  );
};

export default RecordsPlot;
