import { PlusIcon } from "components/ui/Icons";
import { last } from "lodash";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

const Container = styled.div`
  position: relative;

  height: 200px;
  color: black;
  text {
    fill: black;
  }
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const Td = styled.td`
  border: 1px solid #ccc;
  padding: 4px;
  text-align: left;
`;

const Th = styled.th`
  font-weight: 500;
  padding: 4px;
  border: 1px solid #ccc;
  text-align: left;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const SubTablePlot = ({
  type = "",
  tableColumns = [],
  rows = [],
  editRow = () => {},
  addNewRow = () => {},
}) => {
  const [categoryColumnName, setCategoryColumnName] = useState(
    tableColumns?.[0]?.name
  );
  const [valueColumnName, setValueColumnName] = useState(
    last(tableColumns)?.name
  );

  if (type === "Bar Chart") {
    const barsData = rows?.map(record => ({
      [categoryColumnName]: record?.[categoryColumnName]?.value,
      [valueColumnName]: record?.[valueColumnName]?.value,
    }));

    return (
      <Container>
        <div style={{ display: "flex" }}>
          <select
            value={categoryColumnName}
            onChange={e => setCategoryColumnName(e.target.value)}
          >
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </select>
          <select
            value={valueColumnName}
            onChange={e => setValueColumnName(e.target.value)}
          >
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </select>
        </div>
        <ResponsiveContainer width="99%" height={200}>
          <BarChart data={barsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              interval={0}
              style={{ fontSize: "9px" }}
              dataKey={categoryColumnName}
            />
            <YAxis />
            <Bar
              dataKey={valueColumnName}
              fill="#0191ff"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <Tooltip
              contentStyle={{ backgroundColor: "white" }}
              cursor={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </Container>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          {tableColumns.map((column, index) => (
            <Th key={index}>{column?.name}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {tableColumns.map((column, columnIndex) => (
              <Td key={columnIndex}>
                <FieldInput
                  value={row?.[column?.name]?.value}
                  onChange={e =>
                    editRow(rowIndex, column?.name, e.target.value)
                  }
                />
              </Td>
            ))}
          </tr>
        ))}
        <tr>
          <StyledPlusIcon onClick={addNewRow} />
        </tr>
      </tbody>
    </table>
  );
};

export default SubTablePlot;
