import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clamp, clone, cloneDeep, inRange, merge, range, set } from "lodash";
import {
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Image as ImageIcon,
  Redo,
  Save,
  Undo,
  Link as LinkIcon,
  CloudDownload,
  ChevronRight,
  ChevronLeft,
  PlayArrow,
  StrikethroughS,
  CheckBox,
} from "@material-ui/icons";

import { CenteredWithTopNavLayoutNoSideBar } from "components/Layout";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import WordDoc from "components/WordDoc";
import {
  PAGE_WIDTH_PX,
  START_X,
  addStyleToBlocks,
  addToStyleFieldsToSelection,
  getSelectionFromBlocks,
  getSelectionTopBarState,
  removeSelectionStyle,
} from "utils/word-utils";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import { postChatflowGenerate } from "api/services/searchService";
import SmallButton from "components/ui/SmallButton";
import { postWordDocIngestToBoltzhub } from "api/services/chatService";
import {
  BoltzhubLogoInner,
  ChatIcon,
  CrossIcon,
  LogoInner,
  NotebookIcon,
  QuickStartIcon,
  WordIcon,
} from "components/ui/Icons";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import AddDocLabelModalTrigger from "components/AddDocLabelModalTrigger";
import { getBase64FromImageFile, sleep, uuidv4 } from "utils/common";
import ItemListPopover from "components/ItemListPopover";
import { postQuickStart } from "api/services/quickStarts";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import WordDocSearchResultsModal from "components/WordDocSearchResultsModal";
import NavHeader from "components/NavHeader";
import KnowledgeConfigs from "components/KnowledgeConfigs";
import BlackTooltip from "components/ui/BlackTooltip";
import LinkUrlTooltip from "components/ui/LinkUrlTooltip";
import useSearchParamsState from "hooks/useSearchParamsState";
import { ListItem } from "@material-ui/core";
import {
  getSlideDocsLoadImage,
  postSlideDocsUploadImage,
} from "api/services/slideDocsService";
import { postDocImagesUploadImage } from "api/backend/docImagesEndpoints";

const OuterContainer = styled.div`
  display: grid;
  height: 100vh;
  overflow: hidden;
  user-select: none;
`;

const Container = styled.div`
  display: grid;
  align-items: start;
  background-color: #f9fbfd;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const EditableDocNameInput = styled.input`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;

  display: block;
  align-self: center;
  border: none;
  width: 50%;
  outline: none;
  background-color: #f9fbfd;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  background-color: #f9fbfd;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 67px;
  padding-right: 14px;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const ToolbarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  padding: 1px 8px;
  margin: 0px 16px;
  margin-bottom: 0px;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.closer0};
`;

const ToolbarContainerLeft = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 2px;
  align-items: center;
`;

const ToolbarContainerRight = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: 2px;
  align-items: center;
`;

const RightSideButtons = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const IconButtonSmallIcon = styled(IconButton)`
  svg {
    height: 12px;
  }
`;

const IconButtonNoIconHeight = styled(IconButton)`
  svg {
    height: unset;
  }
`;

const PageBoundaryLine = styled.div`
  width: ${PAGE_WIDTH_PX}px;
  height: 2px;
  background-color: ${props => props.theme.color.primary};

  position: sticky;
  left: ${START_X}px;
  z-index: 0;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 5px;
`;

const ColoredIconButton = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }

    path:nth-child(2) {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ColoredIconButtonBucket = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path:nth-child(2) {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }
  }
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 2px 4px;
  grid-auto-flow: column;
  gap: 4px;
`;

const TopMenuBar = styled.div`
  display: flex;
`;

const TopMenuBarItem = styled.div`
  font-weight: normal;
  padding: 2px 8px;
  font-size: 14px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  cursor: pointer;
  z-index: 4;
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
  border: none;
  box-shadow: ${props => props.theme.shadow};
  left: 0;
  width: 200px;
  transform: translate(0, 4px);
`;

const PopoverItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 8px 14px;
  color: black;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PopoverItemBtn = styled(PopoverItemLink).attrs({ as: "div" })``;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${props => props.theme.color.closer1_5};
  height: 20px;
  padding: 10px 0;
  margin: 0 5px;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  height: 100%;
  position: relative;
  background-color: #f9fbfd;
  width: max-content;
  box-shadow: none;
`;

const TopBarLinkText = styled.a`
  margin-left: 4px;
  text-decoration: none;
  color: ${props => props.theme.color.primary};
  font-size: 12px;
`;

const WordDocPage = () => {
  const { wordDocId } = useParams();
  const navigate = useNavigate();

  const [wordDoc, setWordDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingApproval, setIsSavingApproval] = useState(false);
  const [isPostingQuickStart, setIsPostingQuickStart] = useState(false);
  const [shouldCancelGeneration, setShouldCancelGeneration] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [maxNewTokens, setMaxNewTokens] = useState(5);
  const [isSidebarOpen, setIsSidebarOpen] = useSearchParamsState({
    paramName: "isSidebarOpen",
    initialValue: false,
  });

  const [blocks, setBlocks] = useState([{ text: "" }]);
  const [blockIdsToRun, setBlockIdsToRun] = useState([]);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  const doAddIdsToBlocks = async () => {
    const newBlocks = blocks?.map(block => ({
      id: uuidv4(),
      ...block,
    }));

    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...wordDoc, content: { ...wordDoc?.content, blocks: newBlocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    await sleep(10);
  };

  const doRunAll = async () => {
    if (blocks?.some(block => !block?.id)) {
      await doAddIdsToBlocks();
    }

    const newBlockIdsToRun = blocks
      ?.filter(block => block?.isQuery)
      ?.map(block => block?.id);
    setBlockIdsToRun(newBlockIdsToRun);
  };

  const doPopulateBlocksFromChatflow = async (cursor, blocksToSend) => {
    setIsLoading(true);
    const sourceFileIds = wordDoc?.content?.sources?.map(s => s?.fileId);
    const { data, error } = await postChatflowGenerate(
      { max_new_tokens: 20, fileIds: sourceFileIds },
      {
        cursor,
        genContext: "word_tab",
        blocks: blocksToSend,
        sources: wordDoc?.content?.sources || [],
      }
    );

    if (!error && data?.code !== "FAILED") {
      const blocksWithoutSelection = removeSelectionStyle(data?.blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: data?.cursor?.blockIndex || 0,
        startLetterIndex: data?.cursor?.letterIndex || 0,
        endBlockIndex: data?.cursor?.blockIndex || 0,
        endLetterIndex: data?.cursor?.letterIndex || 0,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      setBlocks(blocksWithSelection);

      if (data?.sources?.length > 0) {
        setWordDoc({
          ...wordDoc,
          content: {
            ...wordDoc?.content,
            sources: data?.sources,
          },
        });
      }
    }

    setIsLoading(false);
  };

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doSaveDoc = async () => {
    setIsLoading(true);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...wordDoc, content: { ...wordDoc?.content, blocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doChangeApprovalBlocksAndSaveDoc = async newBlocks => {
    setIsSavingApproval(true);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...wordDoc, content: { ...wordDoc?.content, blocks: newBlocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsSavingApproval(false);
  };

  const doAddSelectedBlockLevelStyles = async ({
    fontSize,
    leftIndent,
    prefix,
    isQuery,
    isContext,
  }) => {
    const { startBlockIndex, endBlockIndex } = getSelectionFromBlocks(blocks);
    if (startBlockIndex === null) {
      return;
    }

    const newBlocks = blocks?.map((block, i) => {
      if (inRange(i, startBlockIndex, endBlockIndex + 1)) {
        return {
          ...block,
          blockStyles: merge(
            { ...block?.blockStyles },
            {
              fontSize,
              leftIndent: leftIndent ?? block?.blockStyles?.leftIndent,
              prefix,
              isContext,
            }
          ),
        };
      }

      return { ...block };
    });

    setBlocks(newBlocks);
  };

  const addToSelectionStyle = newStyleFields => {
    const newBlocks = addToStyleFieldsToSelection(blocks, newStyleFields);
    setBlocks(newBlocks);
  };

  const doIngestDocToBoltzhub = async () => {
    setIsLoading(true);
    const { data } = await postWordDocIngestToBoltzhub({}, { wordDocId });

    const boltzhubDatasetId = data?.dataIngestionJobId?.addToDatasetId;
    const { data: newWordDoc } = await patchWordDoc(
      wordDocId,
      {},
      {
        ...wordDoc,
        content: { ...wordDoc?.content, boltzhubDatasetId, blocks },
      }
    );
    setWordDoc(newWordDoc);
    setBlocks(newWordDoc.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doRemoveBoltzhuDatasetLink = async () => {
    setIsLoading(true);

    const newWordDoc = cloneDeep(wordDoc);
    newWordDoc.content.boltzhubDatasetId = null;

    await patchWordDoc(wordDocId, {}, newWordDoc);

    setWordDoc(newWordDoc);
    setIsLoading(false);
  };

  const doPatchDocSources = async newSources => {
    setIsLoading(true);

    const { data: newWordDoc } = await patchWordDoc(
      wordDocId,
      {},
      { content: { ...wordDoc?.content, sources: newSources } }
    );
    setWordDoc(newWordDoc);
    setIsLoading(false);
  };

  const sel = getSelectionTopBarState(blocks);

  const doPostQuickStart = async () => {
    setIsPostingQuickStart(true);
    // const quickStartBlocks = blocks?.filter(block => block?.isQuery);

    // let quickStartBlocksWithLineBreaks = quickStartBlocks
    //   ?.map(block => [{ text: "" }, block])
    //   .flat();

    // if (!quickStartBlocks?.length) {
    //   quickStartBlocksWithLineBreaks = [{ text: "" }];
    // }

    await postQuickStart(
      {},
      {
        fileName: wordDoc?.fileName,
        type: "WORD_DOC",
        content: {
          blocks,
        },
      }
    );
    setIsPostingQuickStart(false);
    window.open("/working-files?type=WORD_DOC", "_blank");
  };

  const hubBaseUrl = "https://hub.boltzbit.com";

  return (
    <OuterContainer
      style={{
        gridTemplateColumns: isSidebarOpen ? "auto 300px" : "auto 0px",
      }}
    >
      <Container isDisabled={isLoading}>
        <DocNameAndSourcesModal>
          <Link to="/">
            <WordIcon
              height="40px"
              style={{
                paddingLeft: "14px",
                paddingRight: "10px",
                fill: "#2b579a",
              }}
            />
          </Link>
          <InputAndMenuBar>
            <EditableDocNameInput
              value={wordDoc?.fileName}
              onChange={e => {
                setWordDoc({
                  ...wordDoc,
                  fileName: e.target.value,
                });
              }}
              onBlur={doSaveDoc}
            />
            <TopMenuBar>
              <StyledItemListPopover
                iconComponent={<TopMenuBarItem>File</TopMenuBarItem>}
              >
                <PopoverItemLink to="/working-files">Open</PopoverItemLink>
                <PopoverItemBtn onClick={doSaveDoc}>Save</PopoverItemBtn>
                <PopoverItemLink to="/working-files">New</PopoverItemLink>
                <PopoverItemLink to="/working-files">
                  Make a copy
                </PopoverItemLink>
                <PopoverItemBtn
                  onClick={() =>
                    triggerDownloadOfFile(wordDocId, {
                      fileType: "WORD_DOC",
                    })
                  }
                >
                  Download
                </PopoverItemBtn>
              </StyledItemListPopover>
              <StyledItemListPopover
                iconComponent={<TopMenuBarItem>Edit</TopMenuBarItem>}
              >
                <PopoverItemLink to="/working-files">Undo</PopoverItemLink>
                <PopoverItemLink to="/working-files">Redo</PopoverItemLink>
                <PopoverItemLink to="/working-files">Cut</PopoverItemLink>
                <PopoverItemLink to="/working-files">Copy</PopoverItemLink>
                <PopoverItemLink to="/working-files">Paste</PopoverItemLink>
              </StyledItemListPopover>
              <StyledItemListPopover
                iconComponent={<TopMenuBarItem>View</TopMenuBarItem>}
              >
                <PopoverItemLink to="/working-files">
                  Full Screen
                </PopoverItemLink>
                <PopoverItemLink to="/working-files">Web View</PopoverItemLink>
              </StyledItemListPopover>
              <StyledItemListPopover
                iconComponent={<TopMenuBarItem>Insert</TopMenuBarItem>}
              >
                <PopoverItemLink to="/working-files">Image</PopoverItemLink>
                <PopoverItemLink to="/working-files">Table</PopoverItemLink>
                <PopoverItemLink to="/working-files">Chart</PopoverItemLink>
              </StyledItemListPopover>
              <StyledItemListPopover
                iconComponent={<TopMenuBarItem>Help</TopMenuBarItem>}
              >
                <PopoverItemLink to="/working-files">
                  Getting started
                </PopoverItemLink>
                <PopoverItemLink to="/working-files">
                  Start tutorial
                </PopoverItemLink>
                <PopoverItemLink to="/working-files">
                  Keyboard shortcuts
                </PopoverItemLink>
              </StyledItemListPopover>
            </TopMenuBar>
          </InputAndMenuBar>
          <StyledNavHeader isProfileOnly />
          <DocSourcesModalTrigger
            sources={wordDoc?.content?.sources}
            onChangeSources={newSources => doPatchDocSources(newSources)}
          />
        </DocNameAndSourcesModal>

        <ToolbarContainer>
          <ToolbarContainerLeft>
            <WordDocSearchResultsModal />
            <IconButton>
              <Undo />
            </IconButton>
            <IconButton>
              <Redo />
            </IconButton>
            <VerticalLine />
            <StyledSelect
              value={sel?.fontSize}
              onChange={e =>
                doAddSelectedBlockLevelStyles({
                  fontSize: parseInt(e.target.value),
                })
              }
            >
              <option value="14">Normal text</option>
              <option value="18">Subtitle</option>
              <option value="26">Title</option>
            </StyledSelect>
            <VerticalLine />
            <IconButton
              isActive={sel?.fontWeight === "bold"}
              onClick={() => {
                addToSelectionStyle({
                  fontWeight: sel?.fontWeight === "bold" ? "normal" : "bold",
                });
              }}
            >
              <FormatBold />
            </IconButton>
            <IconButton
              isActive={sel?.fontStyle === "italic"}
              onClick={() => {
                addToSelectionStyle({
                  fontStyle: sel?.fontStyle === "italic" ? "normal" : "italic",
                });
              }}
            >
              <FormatItalic />
            </IconButton>
            <IconButton
              onClick={() => {
                addToSelectionStyle({
                  isUnderlined: true,
                });
              }}
            >
              <FormatUnderlined />
            </IconButton>
            <ColorPickerTooltip
              selectedColor={sel?.fontColor}
              onNewColor={newFontColor => {
                addToSelectionStyle({
                  fontColor: newFontColor,
                });
              }}
              triggerIcon={
                <ColoredIconButton color={sel?.fontColor}>
                  <FormatColorText />
                </ColoredIconButton>
              }
            />
            <ColorPickerTooltip
              selectedColor={sel?.bgColor}
              onNewColor={newBgColor => {
                addToSelectionStyle({
                  bgColor: newBgColor,
                });
              }}
              triggerIcon={
                <ColoredIconButtonBucket color={sel?.bgColor}>
                  <FormatColorFill />
                </ColoredIconButtonBucket>
              }
            />
            <IconButton
              isActive={sel?.isStrikethrough}
              onClick={() => {
                addToSelectionStyle({
                  isStrikethrough: !sel?.isStrikethrough,
                });
              }}
            >
              <StrikethroughS />
            </IconButton>
            <VerticalLine />
            <IconButton>
              <LinkUrlTooltip
                onNewUrl={newUrl => {
                  addToSelectionStyle({
                    url: newUrl,
                  });
                }}
                triggerIcon={<LinkIcon />}
              />
            </IconButton>
            <IconButton>
              <HiddenInput
                type="file"
                onChange={async e => {
                  const file = e.target.files[0];
                  const base64Str = await getBase64FromImageFile(file);

                  const id = uuidv4();
                  const { data: imagePath } = await postDocImagesUploadImage(
                    wordDocId,
                    {},
                    {
                      base64Image: base64Str?.split("base64,")?.[1],
                      fileName: `${id}.png`,
                    }
                  );

                  const imageToInsert = new Image();
                  imageToInsert.src = base64Str;
                  imageToInsert.onload = () => {
                    const imageSf = 400 / imageToInsert.width;
                    const imageBlock = {
                      text: "",
                      w: imageToInsert.width * imageSf,
                      h: imageToInsert.height * imageSf,
                      imagePath,
                      styles: [],
                    };

                    const { startBlockIndex } = getSelectionFromBlocks(blocks);
                    let newBlocks = cloneDeep(blocks);
                    newBlocks?.splice(startBlockIndex, 0, imageBlock);

                    setBlocks(newBlocks);
                    e.target.value = null;
                  };
                }}
              />
              <ImageIcon />
            </IconButton>
            <VerticalLine />
            <IconButton
              onClick={() => {
                const { startBlockIndex } = getSelectionFromBlocks(blocks);
                const leftIndent =
                  blocks?.[startBlockIndex]?.blockStyles?.leftIndent || 0;
                doAddSelectedBlockLevelStyles({
                  leftIndent: clamp(leftIndent + 20, 0, 100),
                });
              }}
            >
              <FormatIndentIncrease />
            </IconButton>
            <IconButton
              onClick={() => {
                const { startBlockIndex } = getSelectionFromBlocks(blocks);
                const leftIndent =
                  blocks?.[startBlockIndex]?.blockStyles?.leftIndent || 0;
                doAddSelectedBlockLevelStyles({
                  leftIndent: clamp(leftIndent - 20, 0, 100),
                });
              }}
            >
              <FormatIndentDecrease />
            </IconButton>
            <IconButton
              isActive={sel?.prefix === "●"}
              onClick={() => {
                doAddSelectedBlockLevelStyles({
                  leftIndent: sel?.prefix === "●" ? 0 : 20,
                  prefix: sel?.prefix === "●" ? "" : "●",
                });
              }}
            >
              <FormatListBulleted />
            </IconButton>
            <IconButton
              isActive={sel?.prefix?.includes("☐")}
              onClick={() => {
                doAddSelectedBlockLevelStyles({
                  leftIndent: sel?.prefix?.includes("☐") ? 0 : 20,
                  prefix: sel?.prefix?.includes("☐") ? "" : "☐",
                });
              }}
            >
              <CheckBox />
            </IconButton>
            <VerticalLine />
            {/* <IconButton
          onClick={() => {
            triggerDownloadOfFile(wordDocId, {
              fileType: "WORD_DOC",
            });
          }}
        >
          <CloudDownload />
        </IconButton> */}
            <IconButtonNoIconHeight onClick={doIngestDocToBoltzhub}>
              <BoltzhubLogoInner height="14px" />
            </IconButtonNoIconHeight>
            {wordDoc?.content?.boltzhubDatasetId && (
              <TopBarLinkText
                href={`${hubBaseUrl}/data/${wordDoc?.content?.boltzhubDatasetId}`}
                target="_blank"
              >
                Dataset
              </TopBarLinkText>
            )}
            <VerticalLine />
            <BlackTooltip title="Mark as query">
              <IconButtonNoIconHeight
                isActive={sel?.isQuery}
                onClick={() => {
                  doAddSelectedBlockLevelStyles({
                    isQuery: !sel?.isQuery,
                  });
                }}
              >
                <LogoInner
                  style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                  height="14px"
                />
              </IconButtonNoIconHeight>
            </BlackTooltip>
            <BlackTooltip title="Mark as context">
              <IconButton
                isActive={sel?.isContext}
                onClick={() => {
                  doAddSelectedBlockLevelStyles({
                    isContext: !sel?.isContext,
                  });
                }}
              >
                <NotebookIcon style={{ transform: "scale(0.8)" }} />
              </IconButton>
            </BlackTooltip>
            <IconButtonSmallIcon
              isDisabled={!isGenerating}
              onClick={() => setShouldCancelGeneration(true)}
            >
              <CrossIcon />
            </IconButtonSmallIcon>
            <BlackTooltip
              isGreyedOut={isPostingQuickStart}
              title="Save as quick start"
            >
              <IconButton onClick={doPostQuickStart}>
                <QuickStartIcon />
              </IconButton>
            </BlackTooltip>
            <BlackTooltip title="Add label">
              <IconButton>
                <AddDocLabelModalTrigger
                  onPressSave={newLabel => {
                    addToSelectionStyle({
                      meta: {
                        createdAt: new Date().toISOString(),
                        id: uuidv4(),
                        isUserLabel: true,
                        label: newLabel,
                      },
                    });
                  }}
                />
              </IconButton>
            </BlackTooltip>
            <BlackTooltip
              title="Run all"
              isDisabled={blockIdsToRun?.length > 0}
            >
              <IconButton onClick={doRunAll}>
                <PlayArrow />
              </IconButton>
            </BlackTooltip>
            <VerticalLine />
            <div style={{ whiteSpace: "nowrap" }}>max new tokens</div>
            <StyledSelect
              value={maxNewTokens}
              onChange={e => setMaxNewTokens(parseInt(e.target.value))}
            >
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="20">20</option>
              <option value="80">80</option>
              <option value="240">240</option>
            </StyledSelect>
            <IconButton
              onClick={() => {
                navigate("./chat");
              }}
            >
              <ChatIcon style={{ height: "14px" }} />
            </IconButton>
          </ToolbarContainerLeft>

          <ToolbarContainerRight>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              {isSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </ToolbarContainerRight>
        </ToolbarContainer>

        <WordDoc
          isSidebarOpen={isSidebarOpen}
          maxNewTokens={maxNewTokens}
          isGenerating={isGenerating}
          onNewIsGenerating={newFlag => setIsGenerating(newFlag)}
          shouldCancelGeneration={shouldCancelGeneration}
          onNewShouldCancelGeneration={newFlag =>
            setShouldCancelGeneration(newFlag)
          }
          sourceFileIds={
            wordDoc?.content?.sources?.length > 0
              ? wordDoc?.content?.sources?.map(s => s?.fileId)
              : []
          }
          blocks={blocks}
          onChangeSources={newSources => {
            setWordDoc({
              ...wordDoc,
              content: {
                ...wordDoc?.content,
                sources: newSources,
              },
            });
          }}
          onChangeBlocks={newBlocks => setBlocks(newBlocks)}
          onPressTab={extCursor =>
            doPopulateBlocksFromChatflow(extCursor, blocks)
          }
          onPressApproveOrReject={newBlocks =>
            doChangeApprovalBlocksAndSaveDoc(newBlocks)
          }
          isSavingApproval={isSavingApproval}
          sources={wordDoc?.content?.sources || []}
          blockIdsToRun={blockIdsToRun}
          onNewBlockIdsToRun={newBlockIdsToRun =>
            setBlockIdsToRun(newBlockIdsToRun)
          }
        />
      </Container>
      <div
        style={{
          zIndex: 2,
          backgroundColor: "white",

          width: "300px",
          borderLeft: "1px solid lightgrey",
        }}
      >
        <KnowledgeConfigs
          configIds={wordDoc?.content?.knowledgeConfigIds || []}
          onNewIds={newIds => {
            const newWordDoc = {
              ...wordDoc,
              content: {
                ...wordDoc?.content,
                knowledgeConfigIds: newIds,
              },
            };
            setWordDoc(newWordDoc);
            patchWordDoc(wordDocId, {}, newWordDoc);
          }}
        />
      </div>
    </OuterContainer>
  );
};

export default WordDocPage;
