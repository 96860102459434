import { cloneDeep } from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const ScatterParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, yColumnName } = params;

  if (isEditing) {
    return (
      <div>
        <div style={{ display: "inline", width: "100%" }}>
          <span>x axis:</span>
          <select
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose X Axis --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ display: "inline", width: "100%" }}>
          <span>y axis:</span>
          <select
            value={yColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                yColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose Y Axis --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  return (
    <WrapContainer>
      <div>x: {xColumnName}</div>
      <div>y: {yColumnName}</div>
    </WrapContainer>
  );
};

const ScatterChartParams = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
}) => {
  const { xColumnName, yColumnName } = params;

  const data = records?.map(record => {
    let dataPoint = {
      [xColumnName]: record?.[xColumnName]?.value,
      [yColumnName]: record?.[yColumnName]?.value,
    };

    return dataPoint;
  });

  return (
    <div>
      <ScatterParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      />
      <ResponsiveContainer width="99%" height={180}>
        <ScatterChart>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            interval={0}
            style={{ fontSize: "9px" }}
            dataKey={xColumnName}
          />
          <YAxis
            interval={0}
            style={{ fontSize: "9px" }}
            dataKey={yColumnName}
          />
          <Scatter name="" data={data} fill="#8884d8" />
          <Tooltip contentStyle={{ backgroundColor: "white" }} cursor={false} />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ScatterChartParams;
