import { postAndStreamResponse } from "api/api-http-methods";
import {
  getDocImagesLoadImage,
  postDocImagesUploadImage,
} from "api/backend/docImagesEndpoints";
import {
  getKeywordRecommendation,
  getKeywordRecommendationFirstCompletion,
  getWordKeywordRecommendation,
  postChatflowGenerate,
} from "api/services/searchService";
import { getSlideDocsLoadImage } from "api/services/slideDocsService";
import DocTableModalTrigger from "components/DocTableModalTrigger";
import ReferenceCard from "components/ReferenceCard";
import ReferenceCardUser from "components/ReferenceCardUser";
import WordToolbar from "components/WordToolbar";
import MultiPagePreviewTextAndTableModal from "components/widgets/MultiPagePreviewTextAndTableModal";
import PagePreviewTextAndTableModal from "components/widgets/PagePreviewTextAndTableModal";
import SearchInputWordDoc from "components/widgets/SearchInputWordDoc";
import useCacheImages from "hooks/useCacheImages";
import {
  clamp,
  clone,
  cloneDeep,
  groupBy,
  inRange,
  isNaN,
  isNil,
  last,
  set,
  uniq,
} from "lodash";
import { useState, useRef, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getBase64FromImageFile, parseJson, sleep, uuidv4 } from "utils/common";

import {
  SF,
  deleteCharAt,
  drawBoxes,
  getBoxes,
  getMouseEventLocation,
  getXandYForLineAndLetterIndex,
  insertCharAt,
  splitBlockAt,
  PAGE_WIDTH_PX,
  START_X,
  addStyleToBlocks,
  removeSelectionStyle,
  drawCursor,
  getSelectionBoxAndLetterIndex,
  getSelectionFromBlocks,
  isEndBeforeStart,
  selectWordUnderCursor,
  selectBlockUnderCursor,
  insertBlocksAtCursor,
  insertPlainTextAtCursor,
  drawPageBoundaries,
  deleteSelectionAndMergeBlocks,
  onCopy,
  extendSelectionWithArrowKey,
  extendSelection,
  getImageHoverLocation,
  changeTableStructure,
  getSelectionTopBarState,
  TICKBOX_PREFIXES,
  TICKED_PREFIX,
  UNTICKED_PREFIX,
  isRegionSelected,
} from "utils/word-utils";

const getImagePaths = blocks => {
  const imagePaths = [];
  blocks?.forEach(block => {
    if (block?.imagePath) {
      imagePaths.push(block?.imagePath);
    }
  });
  return imagePaths;
};

const MAX_FETCHES = 10;
const ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

const ContentCanvas = styled.canvas`
  position: fixed;
  left: 50%;
  /* transform: translateX(-50%); */
  /* top: ${props => props.topOffset}px; */
  width: ${props => props.viewportWidth}px;
  height: ${props => props.viewportHeight}px;
  pointer-events: none;
`;

const CursorCanvas = styled(ContentCanvas)`
  background-color: transparent;
`;

const CanvasContainer = styled.div`
  position: relative;
  display: grid;
  height: calc(100vh - 50px);
  overflow: auto;
  cursor: text;
`;

const StyledSearchInput = styled(SearchInputWordDoc)`
  padding: 0;
`;

const TallEmptyDiv = styled.div`
  width: 0px;
`;

const ContextMenu = styled.div`
  position: fixed;
  z-index: 100;
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  box-shadow: ${props => props.theme.shadow};
  padding: 2px 0;
`;

const PopoverItemButton = styled.div`
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 8px 14px;
  color: black;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PopoverItemLabel = styled.label`
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 8px 14px;
  color: black;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const Toolbar = styled.div`
  background-color: #929292;
  height: 30px;
  width: 600px;
`;

/*
// BE representation
blocks = [
  { text: "This could be very very long ..."}
  ...
]

// canvas representation
boxes = [
  { text: "This could be", blockIndex: 0, blockStartIndex: 0 },
  { text: "very very long ...", blockIndex: 0, blockStartIndex: 14 },
  ...
]
*/

const useCacheImagesNew = ({ imagePaths = [] }) => {
  const [imageCache, setImageCache] = useState({});

  const doPopulateImage = async path => {
    if (!path) {
      return;
    }
    const { data } = await getDocImagesLoadImage({ imagePath: path });
    const image = new Image();
    image.src = data;

    setImageCache(prev => ({
      ...prev,
      [path]: {
        base64Str: data,
        image,
      },
    }));
  };

  useEffect(() => {
    uniq(imagePaths).forEach(path => doPopulateImage(path));
  }, [JSON.stringify(imagePaths)]);

  return imageCache;
};

const isMouseOverCancelButton = (
  e,
  cursorCanvasRef,
  docState,
  contentCanvasRef,
  inProgressBlockIndex
) => {
  const [boxIndex, letterIndex, styleUnderMouse] = getMouseEventLocation({
    e,
    ctx: cursorCanvasRef.current.getContext("2d"),
    boxes: docState.current?.boxes,
    contentCanvasRef,
  });

  const boxUnderMouse = docState.current?.boxes?.[boxIndex];

  let { offsetX } = e.nativeEvent;
  offsetX = offsetX - contentCanvasRef?.current?.getBoundingClientRect().x;

  if (boxUnderMouse?.queryId) {
    if (
      letterIndex === 0 &&
      inRange(offsetX, boxUnderMouse?.x - 35, boxUnderMouse?.x - 10)
    ) {
      if (inProgressBlockIndex) {
        return true;
      }
    }
  }

  return false;
};

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com/";
}

const WordDoc = ({
  blocks = [{ text: "" }],
  onChangeBlocks = newBlocks => {},
  topOffset = 0,
  onPressTab = () => {},
  onPressApproveOrReject = newBlocks => {},
  cursorStrokeStyle,
  cursorLineWidth,
  cursorMsg,
  isSavingApproval,
  sourceFileIds = [],
  isReadOnly = false,
  shouldCancelGeneration = false,
  onNewShouldCancelGeneration = () => {},
  sources = [],
  isGenerating = false,
  onNewIsGenerating = () => {},
  maxNewTokens = 5,
  onChangeSources = () => {},
  isSidebarOpen = false,
  blockIdsToRun = [],
  onNewBlockIdsToRun = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const { wordDocId } = useParams();

  const canvasContainerRef = useRef(null);
  const contentCanvasRef = useRef(null);
  const cursorCanvasRef = useRef(null);

  const [canvasSize, setCanvasSize] = useState({ width: 1200, height: 700 });
  const [inputPosition, setInputPosition] = useState({ x: null, y: null });
  const [inputInitialValue, setInputInitialValue] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [pageTopY, setPageTopY] = useState(0);
  const [pageScrollIntervalId, setPageScrollIntervalId] = useState(null);
  const [sourceModalY, setSourceModalY] = useState(null);
  const [sourceModalMeta, setSourceModalMeta] = useState(null);
  const [sourceModalMetaLocation, setSourceModalMetaLocation] = useState({
    blockIndex: null,
    styleIndex: null,
  });
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [recommendationContext, setRecommendationContext] = useState("excel");
  const docState = useRef({ boxes: [] });
  const [abortController, setAbortController] = useState(new AbortController());
  const [numFetches, setNumFetches] = useState(0);

  const [isResizing, setIsResizing] = useState(false);
  const [contextMenuType, setContextMenuType] = useState(null); // table, insert
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [contextBlockIndex, setContextBlockIndex] = useState(null);

  const [commandSuggestion, setCommandSuggestion] = useState("");
  const [suggestionAbortController, setSuggestionAbortController] = useState(
    new AbortController()
  );
  const [toolbarRect, setToolbarRect] = useState(null);

  const imagePathToBase64 = useCacheImagesNew({
    imagePaths: getImagePaths(blocks),
  });

  const [expandedQueryIds, setExpandedQueryIds] = useState([]);
  const [inProgressBlockIndex, setInProgressBlockIndex] = useState(null);

  useEffect(() => {
    const ctx = contentCanvasRef.current?.getContext("2d");
    const cursorCtx = cursorCanvasRef.current?.getContext("2d");
    ctx.font = `${14 * SF}px Arial`;
    cursorCtx.font = `${14 * SF}px Arial`;

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("paste", onPaste);
    document.addEventListener("copy", e => onCopy(e, blocks));
    document.addEventListener("cut", onCut);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("paste", onPaste);
      document.removeEventListener("copy", e => onCopy(e, blocks));
      document.addEventListener("cut", onCut);
    };
  }, [
    cursorCanvasRef.current,
    contentCanvasRef.current,
    isGenerating,
    JSON.stringify(blocks),
    commandSuggestion,
  ]);

  const doPopulateCommandSuggestion = async () => {
    // setCommandSuggestion("");
    // suggestionAbortController.abort();
    // const newAbortController = new AbortController();
    // setSuggestionAbortController(newAbortController);
    // const { startBlockIndex, endLetterIndex } = getSelectionFromBlocks(blocks);
    // const cursorBlock = blocks?.[startBlockIndex];
    // if (cursorBlock?.isQuery && endLetterIndex === cursorBlock?.text?.length) {
    //   const query = cursorBlock?.text;
    //   const { data } = await getKeywordRecommendationFirstCompletion(
    //     { query, context: "word_doc" },
    //     newAbortController
    //   );
    //   setCommandSuggestion(data);
    // }
    // const commands = ["revenue", "remove", "change", "move", "merge", "split"];
    // setCommandSuggestion("");
    // clearTimeout(commandTimeoutId);
    // const timeoutId = setTimeout(() => {
    //   const randomCommand =
    //     commands[Math.floor(Math.random() * commands.length)];
    //   setCommandSuggestion(randomCommand);
    // }, 1000);
    // setCommandTimeoutId(timeoutId);
  };

  useEffect(() => {
    doPopulateCommandSuggestion();
  }, [JSON.stringify(blocks)]);

  useEffect(() => {
    const boxes = getBoxes({
      blocks,
      ctx: contentCanvasRef.current?.getContext("2d"),
      pageTopY,
      imagePathToBase64,
      expandedQueryIds,
    });
    drawPageBoundaries({
      ctx: contentCanvasRef.current?.getContext("2d"),
      pageTopY,
    });
    drawBoxes({
      ctx: contentCanvasRef.current?.getContext("2d"),
      boxes: boxes,
      isResizing,
      expandedQueryIds,
      inProgressBlockIndex,
    });
    drawCursor({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: boxes,
      commandSuggestion,
    });
    docState.current.boxes = boxes;

    const maxY = Math.max(...boxes.map(box => box.y || 0));
    if (canvasSize.height !== maxY - pageTopY + 100) {
      setCanvasSize(prev => ({
        ...prev,
        height: maxY - pageTopY + 100 || 700,
      }));
    }
  }, [
    JSON.stringify(blocks),
    Object.keys(imagePathToBase64).length,
    document.fonts.check("14px Arial"),
    pageTopY,
    isResizing,
    commandSuggestion,
    JSON.stringify(expandedQueryIds),
    inProgressBlockIndex,
    JSON.stringify(canvasSize),
  ]);

  const onPaste = e => {
    const clipboardText = e.clipboardData.getData("text");
    const clipboardBlocksStr = e.clipboardData.getData("json/bz-word-doc");

    const { startBlockIndex, endBlockIndex, startLetterIndex, endLetterIndex } =
      getSelectionFromBlocks(blocks);
    const isRegionSelected =
      startLetterIndex !== endLetterIndex || startBlockIndex !== endBlockIndex;
    let blocksToModify = cloneDeep(blocks);
    if (isRegionSelected) {
      blocksToModify = deleteSelectionAndMergeBlocks(blocks);
    }

    if (clipboardBlocksStr) {
      const clipboardBlocks = parseJson(clipboardBlocksStr);
      const newBlocks = insertBlocksAtCursor(
        blocksToModify,
        clipboardBlocks,
        docState.current.boxes
      );
      onChangeBlocks(newBlocks);
      return;
    }

    if (clipboardText) {
      const newBlocks = insertPlainTextAtCursor(
        blocksToModify,
        clipboardText,
        docState.current.boxes
      );
      onChangeBlocks(newBlocks);
      return;
    }
  };

  const onCut = e => {
    const blocksWithoutDeleted = deleteSelectionAndMergeBlocks(blocks);
    onChangeBlocks(blocksWithoutDeleted);
    onCopy(e, blocks);
  };

  const onCanvasMouseDown = e => {
    if (
      isGenerating &&
      isMouseOverCancelButton(
        e,
        cursorCanvasRef,
        docState,
        contentCanvasRef,
        inProgressBlockIndex
      )
    ) {
      onNewShouldCancelGeneration(true);
      return;
    }

    setContextMenuType(null);
    setContextBlockIndex(0);
    if (numFetches !== 0 || isGenerating) {
      return;
    }

    setIsMouseDown(true);
    setInputPosition({ x: null, y: null });

    const [boxIndex, letterIndex, styleUnderMouse] = getMouseEventLocation({
      e,
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current?.boxes,
      contentCanvasRef,
    });

    const boxUnderMouse = docState.current?.boxes?.[boxIndex];

    let { offsetX } = e.nativeEvent;
    offsetX = offsetX - contentCanvasRef?.current?.getBoundingClientRect().x;

    if (
      boxUnderMouse?.queryId &&
      !TICKBOX_PREFIXES?.includes(boxUnderMouse?.blockStyles?.prefix)
    ) {
      if (
        letterIndex === 0 &&
        inRange(offsetX, boxUnderMouse?.x - 20, boxUnderMouse?.x - 10)
      ) {
        setExpandedQueryIds(prev => {
          if (expandedQueryIds?.includes(boxUnderMouse?.queryId)) {
            return prev?.filter(id => id !== boxUnderMouse?.queryId);
          }
          return [...prev, boxUnderMouse?.queryId];
        });
        return;
      }
    }

    if (
      TICKBOX_PREFIXES?.includes(boxUnderMouse?.blockStyles?.prefix) &&
      inRange(offsetX, boxUnderMouse?.x - 20, boxUnderMouse?.x - 5)
    ) {
      const blockIndex = boxUnderMouse?.blockIndex;
      const isTicked = boxUnderMouse?.blockStyles?.prefix === TICKED_PREFIX;

      const newBlocks = blocks.map((block, i) => {
        if (i === blockIndex) {
          return {
            ...block,
            blockStyles: {
              ...block?.blockStyles,
              prefix: isTicked ? UNTICKED_PREFIX : TICKED_PREFIX,
            },
          };
        }
        return block;
      });

      onChangeBlocks(newBlocks);

      return;
    }

    const [location] = getImageHoverLocation(
      e,
      boxUnderMouse,
      contentCanvasRef
    );

    if (location === "bottomRightCorner") {
      setIsResizing(true);
    }

    const box = docState.current?.boxes?.[boxIndex];
    if (box?.image) {
      return;
    }
    const block = blocks?.[box?.blockIndex];

    let blockLetterIndex = box?.blockStartIndex + letterIndex;
    if (blockLetterIndex >= block?.text?.length) {
      blockLetterIndex = block?.text?.length;
    }

    const blocksWithoutSelection = removeSelectionStyle(blocks);
    const blocksWithSelection = addStyleToBlocks({
      startBlockIndex: box?.blockIndex,
      startLetterIndex: blockLetterIndex,
      endBlockIndex: box?.blockIndex,
      endLetterIndex: blockLetterIndex,
      blocks: blocksWithoutSelection,
      styleFields: {
        isSelection: true,
      },
    });
    onChangeBlocks(blocksWithSelection);

    if (block?.isTableCell) {
      setRecommendationContext("IN_TABLE");
    } else {
      setRecommendationContext("excel");
    }

    // if (box?.isQuery) {
    //   setIsMouseDown(false);
    //   const { y } = getXandYForLineAndLetterIndex({
    //     ctx: cursorCanvasRef.current.getContext("2d"),
    //     boxes: docState.current?.boxes,
    //     boxIndex,
    //     letterIndex,
    //   });

    //   setInputPosition({
    //     x: cursorCanvasRef.current.getBoundingClientRect().x + 280,
    //     y,
    //   });
    //   setInputInitialValue(block?.text);

    //   return;
    // }

    const { y } = getXandYForLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current.boxes,
      boxIndex,
      letterIndex,
    });
    setSourceModalY(y);
    const letterIndexMeta = box?.styles?.find(
      style => letterIndex >= style.start && letterIndex <= style.end
    )?.meta;
    setSourceModalMeta(letterIndexMeta);
    setSourceModalMetaLocation({
      blockIndex: box?.blockIndex,
      styleIndex: box?.styles?.findIndex(
        style => letterIndex >= style.start && letterIndex <= style.end
      ),
    });

    if (styleUnderMouse?.url) {
      setIsResizing(false);
      setIsMouseDown(false);
      window.open(styleUnderMouse?.url, "_blank");
    }
  };

  const onCanvasMouseMove = e => {
    if (e.nativeEvent.offsetY > 8 || e.nativeEvent.offsetY <= 0) {
      clearInterval(pageScrollIntervalId);
      setPageScrollIntervalId(null);
    }

    const [boxIndex, letterIndex, styleUnderMouse] = getMouseEventLocation({
      e,
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current?.boxes,
      contentCanvasRef,
    });

    let { offsetX } = e.nativeEvent;
    offsetX = offsetX - contentCanvasRef?.current?.getBoundingClientRect().x;
    const box = docState.current?.boxes?.[boxIndex];

    if (box?.queryId) {
      if (letterIndex === 0 && inRange(offsetX, box?.x - 20, box?.x - 10)) {
        canvasContainerRef.current.style.cursor = "pointer";
        return;
      }
    }

    if (
      TICKBOX_PREFIXES?.includes(box?.blockStyles?.prefix) &&
      inRange(offsetX, box?.x - 20, box?.x - 5)
    ) {
      canvasContainerRef.current.style.cursor = "pointer";
      return;
    }

    if (styleUnderMouse?.url) {
      canvasContainerRef.current.style.cursor = "pointer";
      return;
    } else {
      canvasContainerRef.current.style.cursor = "text";
    }

    const boxUnderMouse = docState.current?.boxes?.[boxIndex];
    const [location] = getImageHoverLocation(
      e,
      boxUnderMouse,
      contentCanvasRef
    );

    if (location === "bottomRightCorner") {
      canvasContainerRef.current.style.cursor = "nwse-resize";
    } else {
      canvasContainerRef.current.style.cursor = "text";
    }

    if (location === "bottomRightCorner" && e.buttons === 1) {
      let { movementY } = e.nativeEvent;
      const blockIndex = boxUnderMouse?.blockIndex;

      const { w, h } = boxUnderMouse;
      const aspectRatio = w / h;

      const newBlocks = cloneDeep(blocks);

      newBlocks[blockIndex].w += movementY * aspectRatio;
      newBlocks[blockIndex].h += movementY;
      onChangeBlocks(newBlocks);
      return;
    }

    if (!isMouseDown || !isNil(inputPosition?.x)) {
      return;
    }

    if (e.nativeEvent.offsetY < 12 && !pageScrollIntervalId) {
      const intervalId = setInterval(() => {
        e.target.scroll({
          top: e.target.scrollTop - 5,
        });
      }, 20);
      setPageScrollIntervalId(intervalId);
    }

    const blocksWithSelection = extendSelection(
      blocks,
      docState.current.boxes,
      boxIndex,
      letterIndex
    );
    onChangeBlocks(blocksWithSelection);
  };

  const updateToolbarPosition = ({ isForced = false, yOnly = false }) => {
    if (!isRegionSelected(blocks) && !isForced) {
      setToolbarRect(null);
      return;
    }

    const { boxIndex, letterIndex } = getSelectionBoxAndLetterIndex(
      docState.current.boxes
    );
    const { x, y } = getXandYForLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current.boxes,
      boxIndex,
      letterIndex,
    });

    if (yOnly) {
      setToolbarRect(prev => ({ ...prev, y }));
      return;
    }

    setToolbarRect({ x, y });
  };

  const onCanvasMouseUp = e => {
    setIsResizing(false);
    setIsMouseDown(false);
    updateToolbarPosition({ isForced: e.detail === 2 });
  };

  const onCanvasClick = e => {
    if (numFetches !== 0) {
      return;
    }

    if (e.detail === 2) {
      const newBlocks = selectWordUnderCursor(blocks);
      onChangeBlocks(newBlocks);
    }

    if (e.detail === 3) {
      const newBlocks = selectBlockUnderCursor(blocks);
      onChangeBlocks(newBlocks);
    }
  };

  const onKeyDown = e => {
    if (
      isReadOnly &&
      !["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"].includes(e.key)
    ) {
      return;
    }

    if (isGenerating) {
      return;
    }

    const { boxes } = docState.current;
    let { boxIndex, letterIndex } = getSelectionBoxAndLetterIndex(boxes, e.key);

    if (e.key === "Tab") {
      e.preventDefault();

      if (
        boxes?.[boxIndex]?.isQuery &&
        letterIndex === boxes?.[boxIndex]?.text?.length - 1
      ) {
        const newBlocks = blocks?.map((block, i) => {
          if (i === boxes?.[boxIndex]?.blockIndex) {
            return {
              ...block,
              text: block?.text + commandSuggestion,
            };
          }
          return block;
        });

        const blocksWithoutSelection = removeSelectionStyle(newBlocks);
        const blocksWithSelection = addStyleToBlocks({
          startBlockIndex: boxes?.[boxIndex]?.blockIndex,
          startLetterIndex:
            boxes?.[boxIndex]?.text?.length + commandSuggestion?.length - 1,
          endBlockIndex: boxes?.[boxIndex]?.blockIndex,
          endLetterIndex:
            boxes?.[boxIndex]?.text?.length + commandSuggestion?.length - 1,
          blocks: blocksWithoutSelection,
          styleFields: {
            isSelection: true,
          },
        });

        onChangeBlocks(blocksWithSelection);
        return;
      }

      const { startBlockIndex, startLetterIndex } =
        getSelectionFromBlocks(blocks);
      onPressTab({
        blockIndex: startBlockIndex,
        letterIndex: startLetterIndex,
      });
      return;
    }

    if (e.metaKey && e.shiftKey && e.key === "x") {
      const {
        startBlockIndex,
        startLetterIndex,
        endBlockIndex,
        endLetterIndex,
      } = getSelectionFromBlocks(blocks);

      const sel = getSelectionTopBarState(blocks);

      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex,
        startLetterIndex,
        endBlockIndex,
        endLetterIndex,
        blocks,
        styleFields: {
          isStrikethrough: !sel?.isStrikethrough,
        },
      });
      onChangeBlocks(blocksWithSelection);

      return;
    }

    if (e.metaKey && e.key === "a") {
      const blocksWithoutSelection = removeSelectionStyle(blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: 0,
        startLetterIndex: 0,
        endBlockIndex: blocks?.length - 1,
        endLetterIndex: last(blocks)?.text?.length,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      onChangeBlocks(blocksWithSelection);
      return;
    }

    if (e.metaKey || document.activeElement.tagName === "INPUT") {
      return;
    }
    e.preventDefault();
    setSourceModalY(null);

    let newBlocks = [...blocks];
    const { startBlockIndex, endBlockIndex, startLetterIndex, endLetterIndex } =
      getSelectionFromBlocks(blocks);
    const isRegionSelected =
      startLetterIndex !== endLetterIndex || startBlockIndex !== endBlockIndex;

    if (e.key === "Backspace" && isRegionSelected) {
      const blocksWithoutDeleted = deleteSelectionAndMergeBlocks(blocks);
      onChangeBlocks(blocksWithoutDeleted);
      return;
    }

    if (e.key === "Backspace" && !isRegionSelected) {
      // TODO adjust to new API later. Should take blockIndex and letterIndex as params.
      newBlocks = deleteCharAt({
        blocks,
        boxes,
        boxIndex,
        letterIndex,
      });

      const { startBlockIndex, startLetterIndex } =
        getSelectionFromBlocks(blocks);

      let newBlockIndex = startBlockIndex;
      let newLetterIndex = startLetterIndex;
      if (newBlocks?.length === blocks?.length) {
        newLetterIndex -= 1;
      }
      if (newLetterIndex < 0) {
        newLetterIndex = 0;
      }

      const newBlocksNoSelection = removeSelectionStyle(newBlocks);
      const newBlocksUpdatedSelection = addStyleToBlocks({
        startBlockIndex: newBlockIndex,
        startLetterIndex: newLetterIndex,
        endBlockIndex: newBlockIndex,
        endLetterIndex: newLetterIndex,
        blocks: newBlocksNoSelection,
        styleFields: {
          isSelection: true,
        },
      });
      onChangeBlocks(newBlocksUpdatedSelection);
      return;
    }

    if (e.key === "/" && boxes?.[boxIndex]?.text === "") {
      newBlocks[startBlockIndex].isQuery = true;
      // const { y } = getXandYForLineAndLetterIndex({
      //   ctx: cursorCanvasRef.current.getContext("2d"),
      //   boxes,
      //   boxIndex,
      //   letterIndex,
      // });
      // setInputPosition({
      //   x: cursorCanvasRef.current.getBoundingClientRect().x + 280,
      //   y,
      // });
      // return;
      onChangeBlocks(newBlocks);
      return;
    }

    // character key
    if (e.key.length === 1) {
      newBlocks = blocks;
      if (isRegionSelected) {
        newBlocks = deleteSelectionAndMergeBlocks(blocks);
      }
      newBlocks = insertCharAt({
        blocks: newBlocks,
        boxes,
        boxIndex,
        letterIndex,
        char: e.key,
      });
      const newBoxes = getBoxes({
        blocks: newBlocks,
        ctx: contentCanvasRef.current?.getContext("2d"),
        pageTopY,
        imagePathToBase64,
        expandedQueryIds,
      });

      letterIndex += 1;
      const box = newBoxes?.[boxIndex];
      // TODO: add better logic for detecting when it's time to jump to next line
      if (letterIndex > box?.text?.length) {
        letterIndex = 1;
        boxIndex += 1;
      }
    }

    const startBlock = blocks?.[startBlockIndex];
    if (e.key === "Enter" && blocks?.[startBlockIndex]?.isQuery) {
      onPressEnterInCommandInput({
        query: startBlock?.text,
        payloadBlocks: blocks,
        evalBlockId: startBlock?.id,
      });
      return;
    }

    const isOnLastEmptyLine =
      boxes?.[boxIndex]?.text === "" && boxIndex === boxes?.length - 1;

    if (e.key === "Enter") {
      if (isRegionSelected) {
        newBlocks = deleteSelectionAndMergeBlocks(blocks);
      }
      newBlocks = splitBlockAt({
        blocks: newBlocks,
        boxes,
        boxIndex,
        letterIndex,
      });
      letterIndex = 0;
      boxIndex += 1;
    }

    if (e.key === "ArrowUp") {
      boxIndex = Math.max(0, boxIndex - 1);
      letterIndex = Math.min(
        letterIndex,
        boxes?.[boxIndex]?.text?.length - 1 || 0
      );
      if (letterIndex < 0) {
        letterIndex = 0;
      }
    }
    if (e.key === "ArrowDown" && !isOnLastEmptyLine) {
      boxIndex = Math.min(boxes.length, boxIndex + 1);
      letterIndex = Math.min(
        letterIndex,
        boxes?.[boxIndex]?.text?.length - 1 || 0
      );
      if (letterIndex < 0) {
        letterIndex = 0;
      }
    }
    if (e.key === "ArrowLeft") {
      letterIndex -= 1;
      if (letterIndex < 0) {
        boxIndex -= 1;
        letterIndex = boxes?.[boxIndex]?.text?.length - 1 || 0;
      }
    }
    if (e.key === "ArrowRight" && !isOnLastEmptyLine) {
      letterIndex += 1;
      if (letterIndex > boxes?.[boxIndex]?.text?.length - 1) {
        boxIndex += 1;
        letterIndex = 0;
      }
    }

    if (!boxes?.[boxIndex] && boxes?.[boxIndex - 1]?.text !== "") {
      newBlocks = [...newBlocks, { text: "" }];
    }
    const newBoxes = getBoxes({
      blocks: newBlocks,
      ctx: contentCanvasRef.current?.getContext("2d"),
      pageTopY,
      imagePathToBase64,
      expandedQueryIds,
    });
    let cursorBlockIndex = newBoxes?.[boxIndex]?.blockIndex;
    let cursorLetterIndex = newBoxes?.[boxIndex]?.blockStartIndex + letterIndex;

    cursorBlockIndex = clamp(cursorBlockIndex, 0, newBlocks.length - 1);
    if (isNaN(cursorBlockIndex)) {
      cursorBlockIndex = 0;
    }
    cursorLetterIndex = clamp(cursorLetterIndex, 0, Infinity);
    if (isNaN(cursorLetterIndex)) {
      cursorLetterIndex = 0;
    }

    if (ARROW_KEYS?.includes(e.key) && e.shiftKey) {
      const blocksWithSelection = extendSelection(
        blocks,
        boxes,
        boxIndex,
        letterIndex
      );
      onChangeBlocks(blocksWithSelection);
      return;
    }

    const newBlocksNoSelection = removeSelectionStyle(newBlocks);
    const newBlocksUpdatedSelection = addStyleToBlocks({
      startBlockIndex: cursorBlockIndex,
      startLetterIndex: cursorLetterIndex,
      endBlockIndex: cursorBlockIndex,
      endLetterIndex: cursorLetterIndex,
      blocks: newBlocksNoSelection,
      styleFields: {
        isSelection: true,
      },
    });

    onChangeBlocks(newBlocksUpdatedSelection);
  };

  useEffect(() => {
    if (shouldCancelGeneration) {
      abortController.abort();
      setAbortController(new AbortController());
      onNewShouldCancelGeneration(false);
      setNumFetches(0);
      onNewIsGenerating(false);
      onNewBlockIdsToRun([]);
      setInProgressBlockIndex(null);
    }
  }, [shouldCancelGeneration, numFetches]);

  useEffect(() => {
    doRunBlocks();
  }, [blockIdsToRun?.length]);

  const doRunBlocks = async () => {
    if (!blockIdsToRun?.length) {
      return;
    }

    const blockToRun = blocks?.find(block => block?.id === blockIdsToRun?.[0]);
    const blockToRunIndex = blocks?.findIndex(
      block => block?.id === blockToRun?.id
    );

    const newBlocks = cloneDeep(blocks);
    const newBlocksNoSelection = removeSelectionStyle(newBlocks);
    const newBlocksUpdatedSelection = addStyleToBlocks({
      startBlockIndex: blockToRunIndex,
      startLetterIndex: blockToRun?.text?.length,
      endBlockIndex: blockToRunIndex,
      endLetterIndex: blockToRun?.text?.length,
      blocks: newBlocksNoSelection,
      styleFields: {
        isSelection: true,
      },
    });

    onChangeBlocks(newBlocksUpdatedSelection);
    await sleep(20);

    onPressEnterInCommandInput({
      query: blockToRun?.text,
      payloadBlocks: newBlocksUpdatedSelection,
      evalBlockId: blockToRun?.id,
    });
  };

  const onPressEnterInCommandInput = async ({
    query,
    payloadBlocks,
    continueWithQuery = {},
    isContinue = false,
    evalBlockId = null,
  }) => {
    if (isGenerating) {
      return;
    }
    onNewIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    setInProgressBlockIndex(prev => prev ?? blockIndex + 1);

    const payload = {
      searchBarQuery: null,
      slashQuery: query,
      cursor: { blockIndex, letterIndex },
      genContext: "word_query",
      blocks: payloadBlocks?.filter(block => !!block),
      sources,
    };

    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        onChangeBlocks(data?.blocks || []);
      },
    });

    if (error) {
      console.log("stream err", { error });
    }
    setInProgressBlockIndex(null);
    onNewIsGenerating(false);
  };

  const tableIdToBlocks = groupBy(blocks, "tableId");
  const tableIdToTopLeftBox = groupBy(
    docState.current.boxes?.filter(
      box => box?.rowIndex === 0 && box?.columnIndex === 0
    ),
    "tableId"
  );
  const tableIds = Object.keys(tableIdToBlocks || {})?.filter(
    id => id !== "undefined"
  );

  const contentCanvasRect = contentCanvasRef.current?.getBoundingClientRect();

  return (
    <CanvasContainer
      isDisabled={isGenerating}
      ref={canvasContainerRef}
      onScroll={e => {
        if (e.target.scrollTop <= 5) {
          clearInterval(pageScrollIntervalId);
          setPageScrollIntervalId(null);
        }
        setPageTopY(-e.target.scrollTop);
        const { boxIndex, letterIndex } = getSelectionBoxAndLetterIndex(
          docState.current.boxes
        );
        const { y } = getXandYForLineAndLetterIndex({
          ctx: cursorCanvasRef.current.getContext("2d"),
          boxes: docState.current.boxes,
          boxIndex,
          letterIndex,
        });
        setSourceModalY(y);
        updateToolbarPosition({ yOnly: true });
      }}
      onMouseOut={() => {
        clearInterval(pageScrollIntervalId);
        setPageScrollIntervalId(null);
      }}
      onMouseDown={onCanvasMouseDown}
      onMouseMove={onCanvasMouseMove}
      onMouseUp={onCanvasMouseUp}
      onClick={onCanvasClick}
      onContextMenu={e => {
        const [boxIndex] = getMouseEventLocation({
          e,
          ctx: cursorCanvasRef.current.getContext("2d"),
          boxes: docState.current?.boxes,
          contentCanvasRef,
        });
        const boxUnderMouse = docState.current?.boxes?.[boxIndex];

        e.preventDefault();
        setContextMenuType(boxUnderMouse?.isTableCell ? "table" : "insert");
        setContextBlockIndex(boxUnderMouse?.blockIndex);
        setContextMenuPosition({
          x: e.clientX,
          y: e.clientY,
        });
      }}
      topOffset={topOffset}
    >
      <TallEmptyDiv style={{ height: `${canvasSize?.height}px` }} />
      <ContentCanvas
        style={{
          transform: isSidebarOpen ? "translateX(-70%)" : "translateX(-50%)",
        }}
        ref={contentCanvasRef}
        viewportWidth={canvasSize?.width}
        viewportHeight={1000}
        width={canvasSize?.width * SF || 0}
        height={1000 * SF || 0}
        topOffset={topOffset}
      />
      <CursorCanvas
        style={{
          transform: isSidebarOpen ? "translateX(-70%)" : "translateX(-50%)",
        }}
        ref={cursorCanvasRef}
        viewportWidth={canvasSize?.width}
        viewportHeight={1000}
        width={canvasSize?.width * SF || 0}
        height={1000 * SF || 0}
        topOffset={topOffset}
      />
      {sourceModalMeta &&
        sourceModalY !== null &&
        !sourceModalMeta?.isUserLabel && (
          <ReferenceCard
            sourceModalMeta={sourceModalMeta}
            style={{
              position: "fixed",
              zIndex: 2,
              right: "20px",
              top: topOffset + sourceModalY,
            }}
            isSavingApproval={isSavingApproval}
            onClickFile={() => setIsSourceModalOpen(true)}
            onClickVerify={e => {
              e.stopPropagation();
              const { blockIndex, styleIndex } = sourceModalMetaLocation;
              const newBlocks = [...blocks];
              newBlocks[blockIndex].styles[styleIndex].meta.isApproved = true;
              onPressApproveOrReject(newBlocks);
              setSourceModalMeta({ ...sourceModalMeta, isApproved: true });
            }}
            onClickUnverify={e => {
              e.stopPropagation();
              const { blockIndex, styleIndex } = sourceModalMetaLocation;
              const newBlocks = [...blocks];
              newBlocks[blockIndex].styles[styleIndex].meta.isApproved = false;
              onPressApproveOrReject(newBlocks);
              setSourceModalMeta({
                ...sourceModalMeta,
                isApproved: false,
              });
            }}
          />
        )}
      {sourceModalMeta &&
        sourceModalY !== null &&
        sourceModalMeta?.isUserLabel && (
          <ReferenceCardUser
            sourceModalMeta={sourceModalMeta}
            style={{
              position: "fixed",
              zIndex: 2,
              right: "20px",
              top: sourceModalY,
              maxWidth: "400px",
            }}
            onClickDelete={e => {
              e.stopPropagation();
              const metaId = sourceModalMeta?.id;
              const { blockIndex } = sourceModalMetaLocation;
              const newBlocks = cloneDeep(blocks);

              const stylesWithoutMeta = newBlocks[blockIndex].styles.map(
                style => {
                  if (style?.meta?.id === metaId) {
                    return {
                      ...style,
                      meta: null,
                    };
                  }
                  return style;
                }
              );
              newBlocks[blockIndex].styles = stylesWithoutMeta;
              setSourceModalMeta(null);
              onChangeBlocks(newBlocks);
            }}
          />
        )}
      <MultiPagePreviewTextAndTableModal
        open={isSourceModalOpen}
        handleClose={() => setIsSourceModalOpen(false)}
        tableDocumentLocation={sourceModalMeta}
      />
      {/* <PagePreviewTextAndTableModal
        open={isSourceModalOpen}
        handleClose={() => setIsSourceModalOpen(false)}
        tableDocumentLocation={sourceModalMeta}
      /> */}
      {/* {tableIds?.map(tableId => (
        <DocTableModalTrigger
          blocks={tableIdToBlocks?.[tableId]}
          style={{
            position: "fixed",
            zIndex: 0,
            left: START_X + 108,
            top: tableIdToTopLeftBox?.[tableId]?.[0]?.y + 108,
          }}
        />
      ))} */}
      {contextMenuType === "insert" && (
        <ContextMenu
          style={{
            left: contextMenuPosition?.x,
            top: contextMenuPosition?.y,
          }}
        >
          <PopoverItemLabel
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <input
              style={{ display: "none" }}
              type="file"
              onChange={async e => {
                const file = e.target.files[0];
                const base64Str = await getBase64FromImageFile(file);

                const id = uuidv4();
                const { data: imagePath } = await postDocImagesUploadImage(
                  wordDocId,
                  {},
                  {
                    base64Image: base64Str?.split("base64,")?.[1],
                    fileName: `${id}.png`,
                  }
                );

                const imageToInsert = new Image();
                imageToInsert.src = base64Str;
                imageToInsert.onload = () => {
                  const imageSf = 400 / imageToInsert.width;
                  const imageBlock = {
                    text: "",
                    w: imageToInsert.width * imageSf,
                    h: imageToInsert.height * imageSf,
                    imagePath,
                    styles: [],
                  };

                  const { startBlockIndex } = getSelectionFromBlocks(blocks);
                  let newBlocks = cloneDeep(blocks);
                  newBlocks?.splice(startBlockIndex, 0, imageBlock);

                  onChangeBlocks(newBlocks);
                  e.target.value = null;
                  setContextMenuType(null);
                };
              }}
            />
            Insert Image
          </PopoverItemLabel>
        </ContextMenu>
      )}
      {contextMenuType === "table" && (
        <ContextMenu
          style={{
            left: contextMenuPosition?.x,
            top: contextMenuPosition?.y,
          }}
        >
          <PopoverItemButton
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
              const newBlocks = changeTableStructure({
                blocks,
                contextBlockIndex,
                action: "deleteRow",
              });
              onChangeBlocks(newBlocks);
              setContextMenuType(null);
              setContextBlockIndex(null);
            }}
          >
            Delete Row
          </PopoverItemButton>
          <PopoverItemButton
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
              const newBlocks = changeTableStructure({
                blocks,
                contextBlockIndex,
                action: "deleteColumn",
              });
              onChangeBlocks(newBlocks);
              setContextMenuType(null);
              setContextBlockIndex(null);
            }}
          >
            Delete Column
          </PopoverItemButton>
          <PopoverItemButton
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
              const newBlocks = changeTableStructure({
                blocks,
                contextBlockIndex,
                action: "deleteTable",
              });
              onChangeBlocks(newBlocks);
              setContextMenuType(null);
              setContextBlockIndex(null);
            }}
          >
            Delete Table
          </PopoverItemButton>
        </ContextMenu>
      )}
      {toolbarRect && (
        <WordToolbar
          style={{
            position: "fixed",
            zIndex: 2,
            top: toolbarRect?.y + 15,
            left: contentCanvasRect?.x + toolbarRect?.x - 80,
          }}
          blocks={blocks}
          onChangeBlocks={onChangeBlocks}
        />
      )}
    </CanvasContainer>
  );
};

export default WordDoc;
