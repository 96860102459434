import styled from "styled-components";

import { useNavigate, useSearchParams } from "react-router-dom";
import LayoutNew from "components/LayoutNew";
import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { useState } from "react";
import Button from "components/ui/Button";
import { CrossIcon } from "components/ui/Icons";

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 24px;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  justify-content: start;
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 12px;

  width: 236px;
`;

const CardTitle = styled.div`
  font-weight: 600;
  line-height: 1.5;
`;

const CardLogo = styled.img`
  height: 40px;
`;

const CardButton = styled(ButtonWord)`
  padding: 0 4px;
`;

const CARDS = [
  {
    title: "LinkedIn",
    logoSrc: "/images/logo-linkedin.png",
  },
  {
    title: "Gmail",
    logoSrc: "/images/logo-gmail.png",
  },
  {
    title: "Hubspot",
    logoSrc: "/images/logo-hubspot.png",
  },
  {
    title: "Google Drive",
    logoSrc: "/images/logo-googledrive.png",
  },
  {
    title: "One Drive",
    logoSrc: "/images/logo-onedrive.png",
  },
];

const ModalContent = styled.div`
  padding: 36px;
  display: grid;
  position: relative;
`;

const SeePlansModal = ({ trigger }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CardButton isPrimary onClick={() => setIsOpen(true)}>
        Connect
      </CardButton>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <div style={{ fontWeight: 600, marginBottom: 32 }}>
            Integrations are only available in the “Teams” plan.
          </div>

          <div style={{ justifySelf: "end", display: "flex", gap: 10 }}>
            <CardButton
              style={{ padding: "2px 20px" }}
              isPrimary
              onClick={() => {
                // go to /profile/plan, while keeping search params
                navigate(`/profile/plan?${searchParams.toString()}`);
              }}
            >
              See plans
            </CardButton>
            <CardButton
              onClick={() => setIsOpen(false)}
              style={{ padding: "2px 20px" }}
            >
              Cancel
            </CardButton>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const ProfilePageIntegrationsProviders = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <LayoutNew>
      <Container>
        <Title>Integrations</Title>
        <Cards>
          {CARDS.map((card, index) => (
            <Card key={index}>
              <CardLogo src={card.logoSrc} />
              <CardTitle>{card.title}</CardTitle>
              <SeePlansModal />
              <CardButton disabled>Disconnect</CardButton>
            </Card>
          ))}
        </Cards>
      </Container>
    </LayoutNew>
  );
};

export default ProfilePageIntegrationsProviders;
