import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { merge, range } from "lodash";
import {
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Image,
  Link as LinkIcon,
  Redo,
  Save,
  Undo,
} from "@material-ui/icons";

import { CenteredWithTopNavLayoutNoSideBar } from "components/Layout";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import WordDoc from "components/WordDoc";
import {
  PAGE_WIDTH_PX,
  START_X,
  addStyleToBlocks,
  addToStyleFieldsToSelection,
  getSelectionFromBlocks,
  getSelectionTopBarState,
  removeSelectionStyle,
} from "utils/word-utils";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import { postChatflowGenerate } from "api/services/searchService";
import SmallButton from "components/ui/SmallButton";
import { postWordDocIngestToBoltzhub } from "api/services/chatService";
import {
  BoltzhubLogoInner,
  CrossIcon,
  LogoInner,
  NotebookIcon,
  SigmaIcon,
} from "components/ui/Icons";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import AddDocLabelModalTrigger from "components/AddDocLabelModalTrigger";
import { uuidv4 } from "utils/common";

const StyledCenteredWithTopNavLayout = styled(
  CenteredWithTopNavLayoutNoSideBar
)`
  display: grid;
  align-items: start;

  background-color: ${props => props.theme.color.furthest};
`;

const Container = styled.div`
  display: grid;
  align-items: start;
  background-color: #f9fbfd;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const EditableDocNameInput = styled.input`
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;

  display: block;
  align-self: center;
  border: none;
  width: 300px;
  outline: none;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 67px;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};

  padding: 1px 8px;
  margin: 0px 16px;
  margin-bottom: 0px;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.closer0};

  position: sticky;
  z-index: 3;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const IconButtonSmallIcon = styled(IconButton)`
  svg {
    height: 12px;
  }
`;

const IconButtonNoIconHeight = styled(IconButton)`
  svg {
    height: unset;
  }
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${props => props.theme.color.closer1_5};
  height: 20px;
  padding: 10px 0;
  margin: 0 5px;
`;

const PageBoundaryLine = styled.div`
  width: ${PAGE_WIDTH_PX}px;
  height: 2px;
  background-color: ${props => props.theme.color.primary};

  position: sticky;
  left: ${START_X}px;
  z-index: 0;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 5px;
`;

const ColoredIconButton = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }

    path:nth-child(2) {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ColoredIconButtonBucket = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path:nth-child(2) {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }
  }
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 2px 4px;
  grid-auto-flow: column;
  gap: 4px;
`;

const TemplateFileEditPage = () => {
  const { wordDocId } = useParams();

  const [wordDoc, setWordDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingApproval, setIsSavingApproval] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [shouldCancelGeneration, setShouldCancelGeneration] = useState(false);

  const [blocks, setBlocks] = useState([{ text: "" }]);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  const doPopulateBlocksFromChatflow = async (cursor, blocksToSend) => {
    setIsLoading(true);
    const { data, error } = await postChatflowGenerate(
      { max_new_tokens: 20 },
      {
        cursor,
        genContext: "sigma_tab",
        blocks: blocksToSend,
      }
    );

    if (!error && data?.code !== "FAILED") {
      const blocksWithoutSelection = removeSelectionStyle(data?.blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: data?.cursor?.blockIndex || 0,
        startLetterIndex: data?.cursor?.letterIndex || 0,
        endBlockIndex: data?.cursor?.blockIndex || 0,
        endLetterIndex: data?.cursor?.letterIndex || 0,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      setBlocks(blocksWithSelection);
    }

    setIsLoading(false);
  };

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doSaveDoc = async () => {
    setIsLoading(true);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...wordDoc, content: { ...wordDoc?.content, blocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doChangeApprovalBlocksAndSaveDoc = async newBlocks => {
    setIsSavingApproval(true);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { content: { blocks: newBlocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks || [{ text: "" }]);
    setIsSavingApproval(false);
  };

  const doAddSelectedBlockLevelStyles = async ({
    fontSize,
    leftIndent,
    prefix,
    isQuery,
    isContext,
  }) => {
    const { startBlockIndex, endBlockIndex } = getSelectionFromBlocks(blocks);
    if (startBlockIndex === null) {
      return;
    }

    const newBlocks = [...blocks];
    range(startBlockIndex, (endBlockIndex || startBlockIndex) + 1).forEach(
      blockIndex => {
        const block = newBlocks?.[blockIndex];
        newBlocks[blockIndex].blockStyles = merge(block?.blockStyles, {
          fontSize,
          leftIndent: leftIndent ?? block?.blockStyles?.leftIndent,
          prefix,
          isContext,
        });

        newBlocks[blockIndex].isQuery = isQuery;
      }
    );

    setBlocks(newBlocks);
  };

  const addToSelectionStyle = newStyleFields => {
    const newBlocks = addToStyleFieldsToSelection(blocks, newStyleFields);
    setBlocks(newBlocks);
  };

  const doIngestDocToBoltzhub = async () => {
    setIsLoading(true);
    const { data } = await postWordDocIngestToBoltzhub({}, { wordDocId });

    const boltzhubDatasetId = data?.dataIngestionJobId?.addToDatasetId;
    const { data: newWordDoc } = await patchWordDoc(
      wordDocId,
      {},
      { content: { boltzhubDatasetId, blocks } }
    );
    setWordDoc(newWordDoc);
    setBlocks(newWordDoc.content?.blocks || [{ text: "" }]);
    setIsLoading(false);
  };

  const doPatchDocSources = async newSources => {
    setIsLoading(true);

    const { data: newWordDoc } = await patchWordDoc(
      wordDocId,
      {},
      { content: { ...wordDoc?.content, sources: newSources } }
    );
    setWordDoc(newWordDoc);
    setIsLoading(false);
  };

  const {
    isSelectionBold,
    isSelectionItalic,
    selectionFontColor,
    selectionFontSize,
    isSelectionList,
    selectionBgColor,
    isSelectionQuery,
    isSelectionContext,
  } = getSelectionTopBarState(blocks);

  return (
    <Container isDisabled={isLoading}>
      <DocNameAndSourcesModal>
        <Link to="/template-files">
          <SigmaIcon
            height="32px"
            style={{
              paddingLeft: "14px",
              paddingRight: "10px",
              fill: "#2b579a",
            }}
          />
        </Link>
        <EditableDocNameInput
          value={wordDoc?.fileName}
          onChange={e => {
            setWordDoc({
              ...wordDoc,
              fileName: e.target.value,
            });
          }}
          onBlur={doSaveDoc}
        />
        <DocSourcesModalTrigger
          sources={wordDoc?.content?.sources}
          onChangeSources={newSources => doPatchDocSources(newSources)}
        />
      </DocNameAndSourcesModal>
      <ToolbarContainer>
        <IconButton onClick={doSaveDoc}>
          <Save />
        </IconButton>
        <IconButton>
          <AddDocLabelModalTrigger
            onPressSave={newLabel => {
              addToSelectionStyle({
                meta: {
                  createdAt: new Date().toISOString(),
                  id: uuidv4(),
                  isUserLabel: true,
                  label: newLabel,
                },
              });
            }}
          />
        </IconButton>
        <VerticalLine />
        <IconButtonNoIconHeight
          isActive={isSelectionQuery}
          onClick={() => {
            doAddSelectedBlockLevelStyles({
              isQuery: !isSelectionQuery,
            });
          }}
        >
          <LogoInner
            style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
            height="14px"
          />
        </IconButtonNoIconHeight>
        <IconButton
          isActive={isSelectionContext}
          onClick={() => {
            doAddSelectedBlockLevelStyles({
              isContext: !isSelectionContext,
            });
          }}
        >
          <NotebookIcon style={{ transform: "scale(0.8)" }} />
        </IconButton>
        <IconButtonSmallIcon
          isDisabled={!isGenerating}
          onClick={() => setShouldCancelGeneration(true)}
        >
          <CrossIcon />
        </IconButtonSmallIcon>
        <VerticalLine />
      </ToolbarContainer>

      <WordDoc
        isGenerating={isGenerating}
        onNewIsGenerating={newFlag => setIsGenerating(newFlag)}
        shouldCancelGeneration={shouldCancelGeneration}
        onNewShouldCancelGeneration={newFlag =>
          setShouldCancelGeneration(newFlag)
        }
        sourceFileIds={
          wordDoc?.content?.sources?.length > 0
            ? wordDoc?.content?.sources?.map(s => s?.fileId)
            : []
        }
        blocks={blocks}
        onChangeBlocks={newBlocks => setBlocks(newBlocks)}
        onPressTab={extCursor =>
          doPopulateBlocksFromChatflow(extCursor, blocks)
        }
        topOffset={71 + 52 + 36}
        onPressApproveOrReject={newBlocks =>
          doChangeApprovalBlocksAndSaveDoc(newBlocks)
        }
        isSavingApproval={isSavingApproval}
      />
    </Container>
  );
};

export default TemplateFileEditPage;
