import { ArrowBack } from "@material-ui/icons";
import { putPipelineConfig } from "api/backend/fileSystemEndpoints";
import GridDraggableConnect from "components/GridDraggableConnect";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, PencilIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  background-color: white;
  padding: 32px;
  border-radius: 24px;
  width: 800px;
  height: 520px;
  /* display: grid;
  justify-content: center;
  justify-items: center;
  align-content: start; */
  position: relative;
  gap: 20px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  overflow: auto;
  padding-bottom: 0;
  padding-top: 0;
`;

const StepCard = styled.div`
  position: relative;
  height: 100px;
  width: 190px;
  background-color: #eaeaea;
  border-radius: 10px;
  padding: 12px;
  font-weight: 600;
`;

const StyledButtonWord = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 8px;
  svg {
    fill: white;
    height: 14px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const GreySpan = styled.span`
  color: #a2a2a2;
`;

const SmallSpan = styled.span``;

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

const StyledInput = styled.input`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const StyledSelect = styled.select`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const StyledTextArea = styled.textarea`
  height: 300px;
  resize: none;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const INITIAL_LAYOUT = {
  step0: {
    x: 12,
    y: 1,
    w: 10,
    h: 5,
  },
  step1: {
    x: 6,
    y: 9,
    w: 10,
    h: 5,
  },
  step2: {
    x: 19,
    y: 9,
    w: 10,
    h: 5,
  },
};

const INITIAL_EDGES = [
  { source: "step0", target: "step1" },
  { source: "step0", target: "step2" },
];

const CONIDIONS = [
  { value: "0min", label: "immediaetly after previous step completes" },
  { value: "10min", label: "10 mins after previous step" },
  { value: "other", label: "Other" },
];

const ACTION_TYPES = [
  { value: "email", label: "Send Email" },
  { value: "db write", label: "Write to Database" },
  { value: "sms", label: "Send SMS" },
  { value: "create-worddoc", label: "Create Word Doc" },
];

const EditStepModal = ({ step = {}, onSaveStep = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedStep, setEditedStep] = useState(step);

  return (
    <>
      <StyledPencilIcon height="18px" onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent
          style={{ display: "grid", gap: 10, alignContent: "start" }}
        >
          <Title
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              paddingTop: 20,
              paddingBottom: 10,
            }}
          >
            Edit Step
          </Title>
          <Gap height="10px" />
          <BoldDiv>Name</BoldDiv>
          <StyledInput
            placeholder="New step"
            value={editedStep.label}
            onChange={e =>
              setEditedStep({ ...editedStep, label: e.target.value })
            }
          />
          <Gap height="4px" />
          <BoldDiv>Run condition</BoldDiv>
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}
          >
            <StyledSelect
              value={editedStep.runCondition}
              onChange={e =>
                setEditedStep({ ...editedStep, runCondition: e.target.value })
              }
            >
              {CONIDIONS.map(condition => (
                <option key={condition.value} value={condition.value}>
                  {condition.label}
                </option>
              ))}
            </StyledSelect>
            <StyledInput
              value={
                step?.runCondition === "other" ? editedStep.otherCondition : ""
              }
              onChange={e =>
                setEditedStep({ ...step, otherCondition: e.target.value })
              }
              disabled={editedStep?.runCondition !== "other"}
              placeholder={
                editedStep?.runCondition === "other" ? "Enter condition" : ""
              }
            />
          </div>
          <Gap height="4px" />
          <BoldDiv>Action Type</BoldDiv>
          <StyledSelect
            value={editedStep.actionType}
            onChange={e =>
              setEditedStep({ ...editedStep, actionType: e.target.value })
            }
          >
            {ACTION_TYPES.map(action => (
              <option key={action.value} value={action.value}>
                {action.label}
              </option>
            ))}
          </StyledSelect>
          <Gap height="4px" />
          <BoldDiv>Output template</BoldDiv>
          <StyledTextArea
            placeholder="Enter template"
            value={editedStep.instruction}
            onChange={e =>
              setEditedStep({ ...editedStep, instruction: e.target.value })
            }
          />
          <div
            style={{
              position: "sticky",
              bottom: 5,
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
              backgroundColor: "white",
              gap: 10,
            }}
          >
            <ButtonWord
              style={{ padding: "4px 20px" }}
              isPrimary
              onClick={() => {
                onSaveStep(editedStep);
                setIsOpen(false);
              }}
            >
              Confirm
            </ButtonWord>
            <ButtonWord
              onClick={() => {
                setIsOpen(false);
                setEditedStep(step);
              }}
              style={{ padding: "4px 20px" }}
            >
              Cancel
            </ButtonWord>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const INITIAL_STEPS = [
  {
    key: "step0",
    label: "Send Email",
    actionType: "email",
    runCondition: "0min",
    otherCondition: "",
    instruction: "",
  },
  {
    key: "step1",
    label: "Send follow-up",
    actionType: "email",
    runCondition: "0min",
    otherCondition: "",
    instruction: "",
  },
  {
    key: "step2",
    label: "Mark as complete",
    actionType: "db write",
    runCondition: "0min",
    otherCondition: "",
    instruction: "",
  },
];

const SequencesModalNew = ({ pipelineConfig = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [steps, setSteps] = useState([]);
  const [stepsLayout, setStepsLayout] = useState({});
  const [stepsEdges, setStepsEdges] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isEditingLayout, setIsEditingLayout] = useState(false);

  useEffect(() => {
    const meta = pipelineConfig?.meta || {};
    setStepsLayout(meta?.stepsLayout || INITIAL_LAYOUT);
    setStepsEdges(meta?.stepsEdges || INITIAL_EDGES);
    setSteps(meta?.steps || INITIAL_STEPS);
  }, [JSON.stringify(pipelineConfig?.meta)]);

  const doSavePipelineConfig = async () => {
    setIsUpdating(true);
    const newPipelineConfig = {
      ...pipelineConfig,
      meta: {
        ...pipelineConfig.meta,
        steps: steps,
        stepsLayout: stepsLayout,
        stepsEdges: stepsEdges,
      },
    };
    await putPipelineConfig(pipelineConfig?.id, {}, newPipelineConfig);
    setIsUpdating(false);
  };

  return (
    <>
      <ButtonWord onClick={() => setIsOpen(true)}>Workflow</ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              paddingTop: 20,
              paddingBottom: 10,
              zIndex: 10,
              display: "grid",
              gridTemplateColumns: "1fr auto",
            }}
          >
            Edit Workflow
            <StyledButtonWord
              onClick={() => setIsEditingLayout(!isEditingLayout)}
            >
              {isEditingLayout ? <CrossIcon /> : <PencilIcon />}
            </StyledButtonWord>
          </Title>
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#f4f4f4",
            }}
          >
            <GridDraggableConnect
              isEditingDisabled={!isEditingLayout}
              initialLayout={stepsLayout}
              initialEdges={stepsEdges}
              onDragEnd={newLayout => setStepsLayout(newLayout)}
              onNewEdges={newEdges => setStepsEdges(newEdges)}
              onNewKey={key =>
                setSteps(prev => [
                  ...prev,
                  {
                    key,
                    label: "Untitled Step",
                    actionType: "email",
                    runCondition: "0min",
                    otherCondition: "",
                    instruction: "",
                  },
                ])
              }
              onDeleteKey={key =>
                setSteps(prev => prev.filter(item => item.key !== key))
              }
            >
              {steps.map(item => (
                <StepCard key={item.key}>
                  <div>{item.label}</div>
                  <Gap height="8px" />
                  <div>
                    <GreySpan>action: </GreySpan>
                    <SmallSpan>{item.actionType}</SmallSpan>
                  </div>

                  <div style={{ position: "absolute", top: 10, right: 10 }}>
                    <EditStepModal
                      step={item}
                      onSaveStep={newStep =>
                        setSteps(prev =>
                          prev.map(prevItem =>
                            prevItem.key === item.key ? newStep : prevItem
                          )
                        )
                      }
                    />
                  </div>
                </StepCard>
              ))}
            </GridDraggableConnect>
          </div>

          <div
            style={{
              position: "sticky",
              bottom: 5,
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
              backgroundColor: "white",
              gap: 10,
              zIndex: 1,
            }}
          >
            <ButtonWord
              style={{ padding: "4px 20px" }}
              isPrimary
              disabled={isUpdating}
              onClick={() => {
                doSavePipelineConfig();
                setIsOpen(false);
              }}
            >
              Save
            </ButtonWord>
            <ButtonWord
              onClick={() => {
                setIsOpen(false);
              }}
              style={{ padding: "4px 20px" }}
            >
              Cancel
            </ButtonWord>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SequencesModalNew;
