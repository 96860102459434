export const TERMS_HTML_STR = `<b id="docs-internal-guid-f2b2256c-7fff-51db-cfa9-7266d3926c9c">
  <br />
  <p dir="ltr">
    <span
      >Boltzbit Limited is a company incorporated and registered in England,
      with registered address at Office 202 221 Pentonville Road, London,
      England, N1 9UZ, company registration number 12398587 and VAT number
      GB354811596 (&ldquo;</span
    ><span>Boltzbit</span><span>&rdquo;, &ldquo;</span><span>we</span
    ><span>&rdquo;, &ldquo;</span><span>us</span
    ><span>&rdquo;). When we refer to &ldquo;</span><span>Customer</span
    ><span>&rdquo; or &ldquo;</span><span>you</span
    ><span
      >&rdquo;, we mean you the individual accessing the Boltzbit Platform and
      using the Services. You confirm that you are acting for purposes that are
      wholly or mainly outside your trade, business, craft or profession whilst
      using the Services.</span
    >
  </p>
  <p dir="ltr">
    <span>As used in this services agreement (the &ldquo;</span
    ><span>Agreement</span
    ><span
      >&rdquo;), capitalized terms shall have the meanings assigned to them in
      Section 14.</span
    >
  </p>
  <ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Services</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Boltzbit offers a low code machine learning platform, where
            registered customers may generate solutions optimized to their
            specific objectives by using Boltzbit&rsquo;s technology and
            Customer Data (&ldquo;</span
          ><span>Boltzbit Platform</span
          ><span
            >&rdquo;).&nbsp; Boltzbit offers, subject to the terms and
            conditions of the Agreement, access to the Boltzbit Platform and the
            related tools and services listed below for optimization/creation
            purposes (&ldquo;</span
          ><span>Services</span><span>&rdquo;).</span>
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>The Services comprise of the Customer Repository (&ldquo;</span
          ><span>Customer Repository</span
          ><span
            >&rdquo;, meaning an online area on the Boltzbit Platform designated
            to the Customer when Customer registers its Account where Customer
            can build, test, train, share and deploy machine learning models,
            datasets, and machine learning applications) and related services.
            Boltzbit does not provide the Customer with training,
            implementation, and support services related to the Services as part
            of the standard Boltzbit Platform offering.&nbsp; However, Customers
            can purchase such extra services on the terms and subject to the
            conditions set forth in the applicable Order Form(s) available on
            the Boltzbit Platform.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >The Services will not comprise any legal, accounting or tax advice
            or other services not defined in this Agreement.&nbsp; To the extent
            that regulatory requirements are required to be observed by the
            Customer with regard to the use of the Services (</span
          ><span>e.g.</span
          ><span
            >, legal requirements regarding the use of AI), the Customer will
            seek advice on such compliance requirements separately.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Boltzbit reserves the right to add new features, functions or
            services to the Boltzbit Platform and to implement legally required
            changes and updates (</span
          ><span>e.g.</span
          ><span
            >, to maintain the required security standard) or to adapt it to new
            technical standards.&nbsp; Furthermore, Boltzbit may change existing
            Services taking into account the legitimate interests of the
            Customer:</span
          >
        </p>
      </li>
      <ol>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >to adapt them to technical developments or innovations as well as
              to changed market requirements,&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >to improve existing functions, Services and system performance or
              to adapt them to changed user behavior, or</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >if this is necessary for operational reasons, for example to
              adapt the Services to a new technical environment of Boltzbit or
              its suppliers and service providers or to increase user
              numbers.&nbsp;</span
            >
          </p>
        </li>
      </ol>
    </ol>
  </ol>
  <p dir="ltr">
    <span
      >Boltzbit shall inform Customers of the characteristics and timing of the
      changes in advance in text form (</span
    ><span>e.g.</span
    ><span
      >, through postings in Customer&rsquo;s Account) with a reasonable notice
      period of not less than thirty (30) calendar days.&nbsp; In the event of
      any changes to the Services that it uses, Customer shall be free to
      terminate its Agreement with Boltzbit with immediate effect by closing
      their respective Account or otherwise.&nbsp; Any overpaid fees resulting
      from a termination pursuant to the preceding sentence shall be refunded to
      Customer without undue delay.&nbsp;</span
    >
  </p>
  <ol start="2">
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Customer Account.</span><span>&nbsp;</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >The Customer is obliged to provide valid and complete information
            when registering at the Platform and to keep this information
            up-to-date at all times.&nbsp;&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Boltzbit will send any declarations relating to the Agreement
            (</span
          ><span>e.g.</span
          ><span
            >, termination of access to the Services, etc.) to the email address
            the Customer provides during registration.&nbsp; The Customer is
            solely responsible for the security of its Customer username, any
            access codes, and any passwords related thereto (which may not be
            shared with third parties), and is responsible for use of the
            Services by any and all other users that it allows to access the
            Services.&nbsp; The Customer shall immediately notify Boltzbit of
            any unauthorized access to or use of its Customer username or access
            codes, or any other breach of security or misuse of the Service by
            its other users that access the Boltzbit Services via
            Customer&rsquo;s Account.&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Customer Data</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >To train the models and otherwise make use of the Services within
            Customer&rsquo;s Repository, Customer may use its own Customer
            Data.&nbsp; As between Customer and Boltzbit, Customer shall own all
            right, title and interest in and to the Customer Data, as well as
            any Customer-Specific Output.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >The Customer acknowledges that the quality of the output generated
            by the Services is dependent on Customer Data and other information
            that Customer provides on the Boltzbit Platform.&nbsp; Boltzbit does
            not have any obligation to conduct any independent investigation or
            verification of the accuracy, completeness or suitability for any
            purposes of any Customer Data.&nbsp; Customer has the sole
            responsibility for the accuracy, quality, integrity, legality,
            reliability, and appropriateness of Customer Data uploaded by
            Customer or processed using the Services.&nbsp; Our Services may in
            some situations result in inaccurate or incorrect output. You should
            evaluate the accuracy of any output as appropriate for your use
            case, including by using human review of the output.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Subject to Section 3.4, Boltzbit will use the Customer Data and
            Customer-Specific Output for both the purpose of delivering the
            services and to train and improve the Boltzbit Platform.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Customer grants to Boltzbit a limited, worldwide, non-exclusive,
            fully-paid, transferable, royalty-free licence during the Term of
            this Agreement to use Customer Data and Customer-Specific Output in
            order to: (i) provide the Services; and (ii) where the Customer is
            not a Premium Customer, to train the artificial intelligence model
            used to provide the Services or any other services provided by
            Boltzbit.&nbsp; Boltzbit may retain Customer Data, Customer-Specific
            Output or derivatives thereof as long as is required by Applicable
            Law.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Customer confirms that (i) Customer has right to use the Customer
            Data for the purposes of this Agreement, and (ii) Customer Data does
            not breach or infringe upon the rights of others (including without
            limitation the rights of privacy or publicity, copyright, or trade
            secret).&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Boltzbit will only use your Personal Data in accordance with its
            Privacy Notice (available <a href="/privacy-policy">here</a>).&nbsp;</span>
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Customer Content</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >As between Customer and Boltzbit, Customer is solely responsible
            and liable for the Customer Content that Customer posts, displays,
            or otherwise makes available on the Boltzbit
            Platform.&nbsp;&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Customer shall at all times ensure that it owns or has all
            necessary rights to use all Customer Content that the Customer
            posts, displays or otherwise makes available.&nbsp; The Customer
            confirms that the Customer Content posted, displayed or otherwise
            made available by the Customer does not and will not: (a) breach any
            third-party right, including copyright, trademark, patent, trade
            secret, moral right, privacy right or right of publicity; (b) breach
            any laws or regulations (including with respect to privacy); (c)
            contain any harassing, abusive, tortious, defamatory, false, or
            intentionally misleading content; or (d) contain any computer
            viruses, worms or other software intended to damage or alter a
            computer system or data.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Customer grants to Boltzbit a limited, worldwide, non-exclusive,
            fully-paid, sublicensable, royalty-free licence during the Term of
            this Agreement to use such Customer Content to provide the
            Services.&nbsp; Boltzbit may retain such Content or derivatives
            thereof as long as is required by Applicable Law.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >If the Customer sets the status of its Customer Repository to
            public, the Customer grants to Boltzbit and to other registered
            customers of the Boltzbit Platform a non-exclusive, perpetual,
            irrevocable, worldwide right to use, reproduce, perform, publish,
            display, modify, and create derivative works of the Customer
            Content.&nbsp;&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Payment Terms&nbsp;</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Application</span
          ><span
            >. This Section 5 applies when Customer is a Premium Customer.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Invoicing</span
          ><span
            >. Boltzbit will invoice Customer for the Fees in accordance with
            this Agreement.&nbsp; Boltzbit will provide Customer with a tool to
            enable Customer to review its usage of the Services for its internal
            purposes, including to allocate the Fees charged.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Payment</span
          ><span
            >. Customer will pay for all Fees no later than 30 days from receipt
            of Boltzbit&rsquo;s invoice.&nbsp; All payments are due in the
            currency stated in the invoice.&nbsp; Payments made electronically
            or by wire transfer must comply with the instructions specified on
            the invoice.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Overdue Payments</span
          ><span
            >. Customer&rsquo;s payment of undisputed Fees is overdue if
            Boltzbit has not yet received it by the payment due date.&nbsp; If a
            payment is overdue for more than 14 days after the payment due date,
            Boltzbit may Suspend the Services.&nbsp; Boltzbit will notify
            Customer at least 7 days before such Suspension.&nbsp; Boltzbit may
            terminate this Agreement for cause in accordance with </span
          ><span>Section 11.2</span
          ><span>
            (Termination) if such undisputed Fees remain unpaid for 30 days
            after the notice of Suspension.&nbsp;&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Cancellation Rights</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Under the Consumer Contracts (Information, Cancellation and
            Additional Charges) Regulations 2013 (the &quot;</span
          ><span>Regulations</span
          ><span
            >&quot;) you have the right to cancel this Agreement, for any
            reason, within 14 days of the date of entering into this Agreement
            (the &ldquo;</span
          ><span>Cooling-off Period</span><span>&rdquo;).&nbsp;&nbsp;</span>
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >However, if you wish to access the Services under the Agreement
            during the Cooling-off Period, then you may do so but you expressly
            agree that we may begin to supply the Services to you during the
            Cooling-off Period and you expressly acknowledge that your right to
            cancel the Agreement under the Regulations will be lost and you are
            not entitled to any refund under those cancellation
            rights.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Where your right to cancel has not been lost, then to exercise the
            right to cancel, you must inform us of your decision to cancel the
            Agreement with us by making a clear statement. You may do so by
            completing the cancellation form set out in Appendix 1 of this
            Agreement or by emailing us at info@boltzbit.com. We will send you
            an acknowledgement of receipt by email.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >If you exercise your right of cancellation in accordance with
            clause 6.1, this Agreement will come to an end and we will reimburse
            to you all payments received from you.&nbsp; We will make this
            reimbursement no later than 14 days after the day on which we are
            informed about your decision to cancel. We will make the
            reimbursement using the same means of payment you used to pay,
            unless you expressly agree otherwise.</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Customer Obligations</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Compliance</span><span>. Customer shall:&nbsp;</span>
        </p>
      </li>
      <ol>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >ensure that its use of the Services complies with this Agreement
              and all Applicable Laws;&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >use best efforts to prevent unauthorised use of the Services and
              to terminate any unauthorised use; and&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >promptly notify Boltzbit of any unauthorised use of, or access
              to, the Services of which Customer becomes aware.&nbsp; Where
              Boltzbit reasonably believes that Customer&rsquo;s use of the
              Services does not comply with this Agreement, Boltzbit will notify
              Customer of the grounds for its belief (unless prevented by
              Applicable Law) and Boltzbit reserves the right to review
              Customer&rsquo;s use of the Services to ensure Customer&rsquo;s
              compliance with this Agreement.&nbsp;</span
            >
          </p>
        </li>
      </ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Restrictions</span
          ><span
            >.&nbsp; Unless to the extent expressly permitted under this
            Agreement, Customer will not:</span
          >
        </p>
      </li>
      <ol>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >attempt to copy, modify, create a derivative work of, reverse
              engineer, decompile, translate, disassemble the Services or
              attempt to extract any or all of the source code comprised within
              the Services (</span
            ><span
              >except to the extent expressly permitted by mandatory Applicable
              Law</span
            ><span>s</span><span>);</span>
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span>sublicense, transfer or distribute the Services;</span>
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >sell, resell, or otherwise make the Services available as a
              commercial offering or part thereof to a third
              party;&nbsp;&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >access or use the Services: (i) for High Risk Activities; (ii) in
              a manner intended to avoid incurring Fees; or (iii) on behalf of
              or for the benefit of any entity or person who is legally
              prohibited from using the Services;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >interfere with or disrupt the integrity, operation or performance
              of the Boltzbit Platform;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >attempt to gain unauthorized access or alter to the Boltzbit
              Platform;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >create internet &ldquo;links&rdquo; to the Boltzbit Platform,
              create copy, or &ldquo;frame&rdquo;, or &ldquo;mirror&rdquo; any
              part of the Services, including any content, on any other server
              or device;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >conduct or request that any other person conduct any load testing
              or penetration testing on the Boltzbit Platform;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >use the Services in any way that causes, or may cause, damage to
              the Services or impairment of the availability or accessibility of
              the Boltzbit Platform or any related system or
              database;&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >upload Content to, connect to, or use the Boltzbit Platform to
              engage in activities that may damage, interfere with,
              surreptitiously intercept or expropriate the Boltzbit Platform or
              any related system, database, or program (including through the
              use or transmission of viruses, Trojan horses, malware, worms,
              time bombs or bots; or</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >use the Boltzbit platform in any way that is unlawful, illegal,
              fraudulent or harmful; or in connection with any unlawful,
              illegal, fraudulent or harmful purpose or activity.</span
            >
          </p>
        </li>
      </ol>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Suspension</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Boltzbit may stop offering its Services at any time in its sole
            discretion to the extent permitted by Applicable Law.&nbsp; In this
            case, we have the right to deactivate your Account and/or terminate
            this Agreement upon reasonable prior notice to you.&nbsp; If we
            terminate your Services before the end of the current billing term
            in such circumstances, you will be entitled to a pro rata refund of
            the unused Fees paid in respect of such Services. In no event will
            we be liable in any way for the discontinuation or disabling of the
            Services in such cases to the extent permitted by Applicable
            Law.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Breaches and Suspension</span
          ><span
            >. If Boltzbit becomes aware that Customer&rsquo;s use of the
            Services does not comply with this Agreement, Boltzbit will give
            Customer notice of such breach by requesting that Customer correct
            the breach.&nbsp; If Customer fails to correct such breach within 72
            hours, or if Boltzbit is otherwise required by Applicable Law to
            take action, then Boltzbit may Suspend all or part of
            Customer&rsquo;s use of the Services.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Effects of Suspension</span
          ><span>. Any Suspension under this </span><span>Section 8</span
          ><span>
            will be to the minimum extent and for the shortest duration to, as
            applicable: (a) prevent or terminate the offending use, (b) prevent
            or resolve an emergency security issue, or (c) comply with
            Applicable Laws, following which Boltzbit will restore the
            Services.&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Intellectual Property Rights; Feedback; Brand Features</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Intellectual Property Rights</span
          ><span
            >. Except as expressly stated in this Agreement, the Customer will
            not own or acquire any right, title, or interest in or to Boltzbit
            IP under this Agreement.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Open Source</span
          ><span
            >. Certain Repository Items may be made available by us under
            &ldquo;open source&rdquo; or &ldquo;creative commons&rdquo; or other
            similar licenses (collectively, &ldquo;</span
          ><span>Open Source</span
          ><span
            >&rdquo;).&nbsp; These Open Source Repository Items are labelled as
            such on the Boltzbit Platform.&nbsp; The Open Source license terms
            are not intended to be replaced or overridden by the license and
            other terms of these Terms; however, the limitations of liabilities,
            disclaimers, and this provision apply to any such Open Source.&nbsp;
            Nothing in these Terms limit your rights under, or grants you rights
            that supersede, the terms and conditions of any applicable Open
            Source license. If you use or make modifications to Open Source, you
            are solely responsible for complying with the applicable Open Source
            license.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Reserved Rights</span><span>. Subject to </span
          ><span>Section 8</span
          ><span
            >, nothing in this Agreement precludes or limits Boltzbit in any way
            from (i) providing materials or services that are similar to
            materials or services provided or contemplated in this Agreement, or
            (ii) developing deliverables or other materials or services that are
            similar to or compete with any materials or services developed as a
            result of this Agreement, regardless of their similarity to any
            materials developed under this Agreement.&nbsp; Boltzbit is free to
            use any </span
          ><span>general knowledge, skills, experience, </span
          ><span>concepts, processes, techniques, improvements</span
          ><span>,</span><span> ideas, approaches, designs</span
          ><span> or other know-how </span><span>used, </span
          ><span>developed </span
          ><span>or acquired by or on behalf of Boltzbit </span
          ><span
            >in the course of performance of this Agreement (even if similar to
            materials, products and Services provided hereunder) free from any
            use restriction or payment obligation.&nbsp; For the avoidance of
            doubt, but subject to this Agreement, including this </span
          ><span>Section 9</span><span> and </span><span>Section 4.4</span
          ><span
            >, Boltzbit does not claim any rights to Customer&rsquo;s
            Confidential Information or Customer Data due to this
            Agreement.&nbsp;&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Brand Features</span
          ><span
            >. Any use of a Party&rsquo;s Brand Features will inure to the
            benefit of the Party holding Intellectual Property Rights to those
            Brand Features.&nbsp; A Party may revoke the other Party&rsquo;s
            right to use its Brand Features with written notice to the other
            Party and a reasonable period to stop the use.&nbsp; Neither Party
            may display or use the other Party&rsquo;s Brand Features in
            connection with this Agreement beyond what is allowed in this
            Agreement without the other Party&rsquo;s prior written
            consent.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Feedback</span
          ><span
            >. Boltzbit may make use of the Feedback without obligation to
            Customer.&nbsp; Under this Section, Customer assigns to Boltzbit all
            right, title, and interest in the Intellectual Property Rights in
            the Feedback.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Analytics</span
          ><span
            >. To assist Boltzbit in maintaining and improving the Boltzbit
            Platform, Boltzbit may gather and analyze information about the
            usage of the Services.&nbsp; For example, it tracks and
            statistically evaluates how many visitors the Services have and what
            Repository Items are used most. Boltzbit does not track or collect
            any Personal Data (such as a name, email address, account number or
            billing information) for such purposes.</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Confidential Information</span><span>&nbsp;</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Obligations</span><span>. Subject to </span
          ><span>Section 10.2</span
          ><span>
            (Disclosure of Confidential Information), the recipient will
            (subject to the rest of this </span
          ><span>Section 10.1</span
          ><span
            >) keep confidential and not disclose the other Party&rsquo;s
            Confidential Information, except (in the case of Boltzbit) to
            employees, Affiliates, agents or professional advisors
            (&ldquo;</span
          ><span>Delegates</span
          ><span
            >&rdquo;) who need to know it and who have a legal obligation to
            keep it confidential.&nbsp; The recipient will use the Confidential
            Information only to exercise rights and fulfil obligations under
            this Agreement, while using reasonable care to protect it.&nbsp; The
            recipient will ensure that its Delegates are also subject to the
            same non-disclosure and use obligations.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Disclosure of Confidential Information</span
          ><span>.&nbsp;</span>
        </p>
      </li>
      <ol>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span>General</span><span>. Subject to </span
            ><span>Section 10.2 b</span
            ><span>
              (Notification) and notwithstanding any provision to the contrary
              in this Agreement, the recipient may disclose the other
              Party&rsquo;s Confidential Information: (i) in accordance with a
              Legal Process or (ii) with the other Party&rsquo;s written
              consent.</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span>Notification</span
            ><span
              >. Before the recipient discloses the other Party&rsquo;s
              Confidential Information in accordance with a Legal Process, the
              recipient will use commercially reasonable efforts to promptly
              notify the other Party.&nbsp; Notice will not be given before
              disclosure if the recipient is informed: (i) by an individual
              authorised to act on behalf of the issuer of the Legal Process or
              the recipient&rsquo;s legal advisor that it is legally prohibited
              from giving notice, or (ii) that the Legal Process relates to
              exceptional circumstances involving danger of death or serious
              physical injury to any person.</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span>Opposition</span
            ><span
              >. The recipient will comply with the other Party&rsquo;s
              reasonable requests to oppose or limit disclosure of its
              Confidential Information.&nbsp;</span
            >
          </p>
        </li>
      </ol>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Term and Termination&nbsp;</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Agreement Term</span
          ><span
            >. Without prejudice to any other provision in this Agreement or
            more special terms in the Plan (such as minimum subscription terms),
            either Party may terminate this Agreement and the Services performed
            thereunder with 30 days&rsquo; notice to the end of a month.&nbsp;
            Cancelling your Account or terminating this Agreement does not
            exempt you from fulfilling any payment obligations already
            accrued.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Termination</span
          ><span
            >. Boltzbit may immediately suspend performance of its obligations
            under, or terminate, this Agreement and/or a Plan if: (1) Customer:
            (a) is in material breach of this Agreement: (i) and, if that breach
            is curable, fails to cure that breach within 5 days after receipt of
            written notice; or (ii) more than 4 times, notwithstanding any cure
            of such breaches; or (2) it is required to do so in order to comply
            with Applicable Law.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Effects of Termination</span
          ><span
            >. When this Agreement terminates or expires, then: (a) the rights
            granted under this Agreement by Boltzbit to the Customer regarding
            the Services will cease immediately (except as stated in this </span
          ><span>Section 11.3</span><span> (Effects of Termination) and </span
          ><span>Section 13.12</span
          ><span>
            (Survival)); (b) Boltzbit will delete Customer Data unless retention
            thereof is required by Applicable Law.&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Limitation of Liability</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Notwithstanding any term to the contrary, nothing in this Agreement
            will exclude or limit either Party&rsquo;s liability:&nbsp;</span
          >
        </p>
      </li>
      <ol>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >for death or personal injury resulting from the negligence of
              either Party or in the case of Boltzbit, its servants, agents or
              employees;&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span>for fraud or fraudulent misrepresentation;&nbsp;</span>
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >for breach of any implied condition as to title or quiet
              enjoyment;&nbsp;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >for payment of sums properly due and owing to the other in the
              course of normal performance of this Agreement;</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >for wilful misconduct (being a deliberate and wrongful act or
              omission by a Party that: (i) is made without prior consultation
              with, and without the consent of the other Party, and either (ii)
              (A) is done with an intent to do harm to the other Party, and (B)
              it knows in so acting it is committing, and intends to commit, a
              breach of contract, or (iii) it is reckless in the sense of not
              caring whether or not such act or omission constitutes a breach of
              contract); and</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >for misuse (including unauthorized access or disclosure) of the
              other Party&rsquo;s Confidential Information, resulting from a
              breach of </span
            ><span>Section 10</span
            ><span> (Confidentiality Obligations); and</span>
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >for infringement of the other Party&rsquo;s Intellectual Property
              Rights.&nbsp;</span
            >
          </p>
        </li>
      </ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >If Boltzbit fails to comply with this Agreement, Boltzbit is
            responsible for loss or damage you suffer that is a foreseeable
            result of Boltzbit's breach of this Agreement or Boltzbit's
            negligence, but Boltzbit is not responsible for any loss or damage
            that is not foreseeable. Loss or damage is foreseeable if it is an
            obvious consequence of Boltzbit's breach or if it was contemplated
            by you and Boltzbit at the time of entering into this
            Agreement.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >The Customer agrees not to use the Services for any commercial or
            business purposes and subject to </span
          ><span>Section 12.1</span
          ><span
            >, Boltzbit will not be liable under this Agreement (whether in
            contract, tort (including negligence) or otherwise) for any of the
            following losses suffered or incurred by the Customer (whether or
            not such losses were within the contemplation of the Parties at the
            date of this Agreement):&nbsp;</span
          >
        </p>
      </li>
      <ol>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span
              >loss of actual or anticipated profits (including loss of profits
              on contracts); or</span
            >
          </p>
        </li>
        <li dir="ltr" aria-level="3">
          <p dir="ltr" role="presentation">
            <span>loss of business opportunity.</span>
          </p>
        </li>
      </ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span
            >Nothing in this Agreement affects your statutory rights. Advice
            about your statutory rights is available from your local Citizens'
            Advice Bureau or Trading Standards Office (or local
            equivalent).</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Subject to </span><span>Sections 12.1,</span><span> 12.2</span
          ><span> and 12.3</span
          ><span
            >, Boltzbit&rsquo;s total liability under or in connection with this
            Agreement (whether in contract, tort (including negligence) or
            otherwise), arising in any phase of this Agreement is limited to an
            aggregate amount not exceeding 100% of the total Fees paid and
            payable (including minimum payments committed to) to Boltzbit under
            this Agreement.&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Miscellaneous</span>
      </p>
    </li>
    <ol>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Notices</span
          ><span
            >. All notices required to be given under this Agreement, including
            in relation to termination or breach, must be in English.&nbsp;
            Notices are effective upon receipt.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Assignment</span
          ><span
            >. Customer may not assign any part of this Agreement without the
            written consent of Boltzbit. Any attempt to assign is void.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Force Majeure</span
          ><span
            >. Boltzbit will not be liable for failure or delay in performance
            to the extent caused by circumstances beyond its reasonable
            control.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Subcontracting</span
          ><span
            >. Boltzbit may subcontract any of its obligations under this
            Agreement but will remain liable to Customer for any subcontracted
            obligations.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>No Agency</span
          ><span
            >. This Agreement does not create any agency, partnership or joint
            venture between the Parties.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>No Waiver</span
          ><span
            >. Boltzbit will not be treated as having waived any rights by not
            exercising (or delaying the exercise of) any rights under this
            Agreement.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Severability</span
          ><span
            >. If any term (or part of a term) of this Agreement is invalid,
            illegal or unenforceable, the rest of this Agreement will remain in
            effect.</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>No Third-Party Beneficiaries</span
          ><span
            >. This Agreement does not confer any benefits on any third party
            unless it expressly states that it does.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Governing Law</span
          ><span
            >. This Agreement is governed by English law and the Parties submit
            to the exclusive jurisdiction of the English courts in relation to
            any dispute (contractual or non-contractual) concerning this
            Agreement, but either Party may apply to any court for an injunction
            or other relief to protect its Intellectual Property Rights. As a
            consumer, if you are resident in the United Kingdom or the European
            Union and you access the Services in your country of residence: (a)
            you will benefit from any mandatory provisions of the law of the
            country in which you are resident; (b) you may bring any dispute
            which may arise under this Agreement to, at your discretion, either
            the competent court of England, or to the competent court of your
            country of habitual residence if this country of habitual residence
            is within the United Kingdom or the European Union, which courts are
            (with the exclusion of any other court) competent to settle any such
            a dispute; and (c) we will bring any dispute which may arise under
            this Agreement to the competent court of your country of habitual
            residence if this is within the United Kingdom or the European Union
            or otherwise the competent court of England. Nothing in this
            Agreement affects your rights as a consumer to rely on any mandatory
            provisions of your local law.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Amendments</span
          ><span
            >. This Agreement may be changed only by a written agreement signed
            by an authorized agent of both Parties; this also applies to any
            amendment of this written and signing form requirement.&nbsp;</span
          >
        </p>
      </li>
      <li dir="ltr" aria-level="2">
        <p dir="ltr" role="presentation">
          <span>Survival</span
          ><span
            >. The following Sections will survive expiration or termination of
            this Agreement: 5 (Payment Terms), 9 (Intellectual Property Rights;
            Feedback; Brand Features), 10 (Confidential Information), 11.3
            (Effects of Termination), 12 (Limitation of Liability),&nbsp; 13
            (Miscellaneous), and 14 (Definitions).&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <li dir="ltr" aria-level="1">
      <p dir="ltr" role="presentation">
        <span>Definitions</span>
      </p>
    </li>
  </ol>
  <p dir="ltr">
    <span>&ldquo;</span><span>Account</span
    ><span
      >&rdquo; is the account that you will create on the website to access, use
      or purchase our Services.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Affiliate</span
    ><span
      >&rdquo; means any entity, which in relationship to an identified entity,
      directly or indirectly controls, is controlled by, or is under common
      control with the identified entity.&nbsp; &ldquo;Control&rdquo; means
      direct or indirect ownership or control of more than 50% of the voting
      interests of a legal entity.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Applicable Laws</span
    ><span
      >&rdquo; means: (a) any statute, regulation, by-law, ordinance or
      subordinate legislation in force from time to time to which a Party is
      subject; (b) any binding court order, judgment or decree; (c) any
      applicable industry code, policy or standard enforceable by law; in each
      case in any jurisdiction that is applicable to this Agreement.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Boltzbit IP</span
    ><span
      >&rdquo; means any and all Intellectual Property relating to the Services,
      the Boltzbit Platform and any Repository Item (except Open Source),
      including any underlying technology, template forms, logs, datasets,
      (including data set annotations and descriptions), models and
      designs.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Brand Features</span
    ><span
      >&rdquo; means each Party&rsquo;s trade names, trademarks, logos, domain
      names, and other distinctive brand features.&nbsp;</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Confidential Information</span
    ><span
      >&rdquo; means information that is marked as confidential or would
      normally be considered confidential information under the circumstances of
      disclosure or due to its nature.&nbsp; Customer Data is Customer&rsquo;s
      Confidential Information.&nbsp; Confidential Information does not include
      information that (i) is independently developed by the recipient, (ii) is
      rightfully given to the recipient by a third party without confidentiality
      obligations, (iii) becomes public through no fault of the recipient, (iv)
      at the time of disclosure was already known to the receiving party.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;Content&rdquo;</span
    ><span>
      means any material including but not limited to code, data, files,
      artifacts, text, graphics, images, applications, or software you, we, or
      any third party provide or make available.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;Customer Content&rdquo;</span
    ><span>
      means Content and Customer Data that you post, publish, display or
      otherwise make available on or via the Boltzbit Platform. Customer Content
      may also include output generated by you in your Customer Repository when
      applying the Services to your Customer Data.&nbsp;</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;Customer Data&rdquo;</span
    ><span>
      means any data Customer uploads to its Customer Repository for the purpose
      of developing, building, training, testing, evaluating or deploying any
      machine learning model or otherwise making use of the Services. It does
      not include Feedback.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;Customer-Specific Output&rdquo;</span
    ><span>
      means data (including data set annotations and descriptions), reports,
      logs and models generated by Customer making use of the Services based on
      Customer Data, provided that it does not include Boltzbit IP or
      constitutes a Derivative Work of Boltzbit IP.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Derivative Work</span
    ><span
      >&rdquo; means: (i) a work based upon one or more pre-existing works; and
      (ii) all improvements, modifications, alterations, adaptations,
      enhancements and new versions of any technology.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Feedback</span
    ><span
      >&rdquo; means feedback or suggestions about the Services provided to
      Boltzbit by Customer.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Fees</span
    ><span
      >&rdquo; means the fees set out in the Agreement or the applicable Plan
      for the provision of the Services.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>High Risk Activities</span
    ><span
      >&rdquo; means any activities defined as prohibited artificial
      intelligence practice or as high-risk uses of artificial intelligence
      systems under European artificial intelligence legislation.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Intellectual Property</span
    ><span
      >&rdquo; means anything protectable by an Intellectual Property
      Right.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Intellectual Property Rights</span
    ><span
      >&rdquo; means current and future worldwide (i) rights under patent,
      copyright, trade mark, or moral rights laws, (ii) design rights, (iii)
      rights in or relating to databases, (iv) rights in relation to domain
      names, and other similar rights (registered or unregistered).&nbsp;</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Legal Process</span
    ><span
      >&rdquo; means an information disclosure request made under law,
      governmental regulation, court order, subpoena, warrant, governmental
      regulatory or agency request, or other valid legal authority, legal
      procedure, or similar process.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Party</span
    ><span>&rdquo; means each of Boltzbit and the Customer, and &ldquo;</span
    ><span>Parties</span
    ><span>&rdquo; means Boltzbit and the Customer together.</span>
  </p>
  <p dir="ltr">
    <span>&ldquo;Personal Data&rdquo; </span
    ><span
      >means any information relating to an identified or identifiable natural
      person (&lsquo;data subject&rsquo;); an identifiable natural person is one
      who can be identified, directly or indirectly, in particular by reference
      to an identifier such as a name, an identification number, e-mail address,
      location data, an online identifier or to one or more factors specific to
      the physical, physiological, genetic, mental, economic, cultural or social
      identity of that natural person.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Plan</span
    ><span
      >&rdquo; means the plan purchased by Customer in connection with this
      Agreement (either online or as annex to this Agreement), identifying the
      Services in more details, for example regarding available storage
      volume.&nbsp;</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Premium Customer</span
    ><span>&rdquo; means where Customer pays for access to the Services.</span>
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Repository Item</span
    ><span
      >&rdquo; means any Content made available by Boltzbit on the Boltzbit
      Platform to Customers for use in their Customer Repository.&nbsp;</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Services</span
    ><span>&rdquo; has the meaning given in </span><span>Section 1</span
    ><span>.&nbsp;</span>
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Suspend</span><span>&rdquo; or &ldquo;</span
    ><span>Suspension</span
    ><span
      >&rdquo; means disabling access to or use of the Services or components of
      the Services.</span
    >
  </p>
  <p dir="ltr">
    <span>&ldquo;</span><span>Term</span
    ><span>&rdquo; has the meaning given in </span><span>Section 11.1</span
    ><span> (Agreement Term).&nbsp;</span>
  </p>
`;
