import * as backend from "api/backend/jobServiceEndpoints";

export const getQuotaConfigsLimit = async () => {
  const { data, error } = await backend.getQuotaConfigsLimit();
  return { data, error };
};

export const postReRunJobs = async (params, body) => {
  const { data, error } = await backend.postReRunJobs(params, body);
  return { data, error };
};
