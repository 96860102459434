import styled from "styled-components";
import { useState } from "react";

import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { WarningIcon } from "components/ui/Icons";

const ModalContent = styled.div`
  padding: 24px;
  border-radius: 20px;
  height: 200px;
  width: 400px;
  display: grid;
  gap: 10px;
  justify-items: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  font-size: 16px;
  line-height: 1.2;
  display: grid;
  grid-template-columns: 22px 1fr;
  gap: 20px;
  line-height: 1.4;
`;

const StyledWarningIcon = styled(WarningIcon)``;

const ErrorMessageModal = ({ message, onDismiss, color = "#d9534f" }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setIsOpen(true);
    }
  }, [message]);

  let messageContent = message;
  if (typeof messageContent !== "string") {
    messageContent = JSON.stringify(messageContent);
  }

  return (
    <Modal
      open={isOpen}
      handleClose={() => {
        onDismiss();
        setIsOpen(false);
      }}
      cornerColor="#d9534f88"
    >
      <ModalContent
        style={{
          backgroundColor: `${color}33`,
        }}
      >
        <StyledWarningIcon
          height="20px"
          style={{
            fill: color,
          }}
        />
        {messageContent}
      </ModalContent>
    </Modal>
  );
};

export default ErrorMessageModal;
