import { apiPostRawResponse } from "api/api-http-methods";
import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getSlideDoc = async (id = "", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/slide-docs/${id}`,
    params
  );
  return { data, error };
};

export const getSlideDocSlide = async (id = "", slideIndex, params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/slide-docs/${id}/slides/${slideIndex}`,
    params
  );
  return { data, error };
};

export const patchSlideDoc = async (id = "", params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/slide-docs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const postSlideDoc = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/slide-docs`,
    params,
    body
  );
  return { data, error };
};

export const deleteSlideDoc = async (id = "", params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/slide-docs/${id}`,
    params
  );
  return { data, error };
};

export const postSlideDocsUpload = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/slide-docs/upload`,
    params,
    body
  );
  return { data, error };
};

export const triggerDownloadOfSlideDoc = async (fileId, params = {}) => {
  const res = await apiPostRawResponse(
    `/solutions/ocr/api/v1/slide-docs/${fileId}/download`,
    params
  );
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const contentDispositionHeader = res?.headers.get("Content-Disposition");
  const fileName = contentDispositionHeader?.split("filename=")?.[1];

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = fileName || "file.pptx";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const getSlideDocsLoadImage = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/api/v1/slide-docs/load-image",
    params
  );
  return { data, error };
};

export const postSlideDocsUploadImage = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/slide-docs/${id}/upload-image`,
    params,
    body
  );
  return { data, error };
};
