import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import { ChatIcon, CrossIcon, PencilIcon, WordIcon } from "components/ui/Icons";
import NavHeader from "components/NavHeader";
import { Gap } from "components/Layout";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import { sleep } from "utils/common";
import { postChatflowGenerate } from "api/services/searchService";
import { isEqual } from "lodash";
import { Save } from "@material-ui/icons";
import ChatConfigModalTrigger from "components/ChatConfigModalTrigger";
import { useRef } from "react";
import PagePreviewTextAndTableModalSpanTrigger from "components/PagePreviewTextAndTableModalTrigger";
import SmallButton from "components/ui/SmallButton";

const Container = styled.div`
  display: grid;
  align-items: start;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const EditableDocNameInput = styled.input`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};

  display: block;
  align-self: center;
  border: none;
  width: 50%;
  outline: none;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 67px;
  padding-right: 14px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  position: relative;
  background-color: #f9fbfd;
  width: max-content;
  box-shadow: none;
`;

const TwoPanels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const TextBlocks = styled.div`
  height: calc(100vh - 148px);
  overflow: auto;
`;

const Panel = styled.div`
  height: calc(100vh - 71px);
  position: relative;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const UserTextInput = styled.input`
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 20px;
  border: none;
  border-radius: 0;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1};
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
`;

const BlockText = styled.div`
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 14px;
  padding: 0 10px;
`;

const StyledSave = styled(Save)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const Questions = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-bottom: 10px;
`;

const Question = styled.div`
  color: ${props => props.theme.color.closer2};
  cursor: pointer;
  padding: 8px;
  border: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ChatWordDocPublishedPage = () => {
  const { wordDocId } = useParams();

  const [userInput, setUserInput] = useState("");
  const [wordDoc, setWordDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [shouldCancelGeneration, setShouldCancelGeneration] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const scrollerRef = useRef(null);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  useEffect(() => {
    if (scrollerRef?.current) {
      scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
    }
  }, [JSON.stringify(blocks)]);

  useEffect(() => {
    if (shouldCancelGeneration) {
      abortController.abort();
      setAbortController(new AbortController());
      setShouldCancelGeneration(false);
      setIsGenerating(false);
    }
  }, [shouldCancelGeneration]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);
    setBlocks(data.content?.blocks || []);
    setIsLoading(false);
  };

  const doSaveDoc = async payloadWordDoc => {
    setIsLoading(true);
    const { data } = await patchWordDoc(
      wordDocId,
      {},
      { ...payloadWordDoc, content: { ...payloadWordDoc?.content, blocks } }
    );
    setWordDoc(data);
    setBlocks(data.content?.blocks);
    setIsLoading(false);
  };

  const onPressEnterInCommandInput = async (
    query,
    payloadBlocks,
    continueWithQuery = {},
    isContinue = false
  ) => {
    if (shouldCancelGeneration || isGenerating) {
      return;
    }
    setIsGenerating(true);

    const sources = wordDoc?.content?.sources || [];
    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    console.log({
      payload: {
        slashQuery: query,
        cursor: { blockIndex, letterIndex },
        genContext: "word_query",
        blocks: payloadBlocks?.filter(block => !!block),
        continueWithQuery,
      },
    });

    const { data, error } = await postChatflowGenerate(
      { max_new_tokens: 10, fileIds: sources?.map(s => s?.fileId) },
      isContinue
        ? {
            slashQuery: query,
            cursor: { blockIndex, letterIndex },
            genContext: "word_query",
            blocks: payloadBlocks?.filter(block => !!block),
            continueWithQuery,
            isContinue,
            sources,
          }
        : {
            slashQuery: query,
            genContext: "word_query",
            cursor: { blockIndex, letterIndex },
            blocks: payloadBlocks?.filter(block => !!block),
            sources,
          },
      abortController
    );

    console.log({
      resData: data,
      resError: error,
    });

    if (!error && data?.blocks) {
      const blocksWithoutSelection = removeSelectionStyle(data?.blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: data?.cursor?.blockIndex || 0,
        startLetterIndex: data?.cursor?.letterIndex || 0,
        endBlockIndex: data?.cursor?.blockIndex || 0,
        endLetterIndex: data?.cursor?.letterIndex || 0,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      setBlocks(blocksWithSelection);

      if (data?.isContinue) {
        await sleep(10);
        onPressEnterInCommandInput(
          query,
          blocksWithSelection,
          data?.continueWithQuery,
          data?.isContinue
        );
      } else {
        setIsGenerating(false);
      }
    }
  };

  return (
    <Container isDisabled={isLoading}>
      <DocNameAndSourcesModal>
        <Link to="/working-files?type=CHAT">
          <ChatIcon
            height="40px"
            style={{
              paddingLeft: "14px",
              paddingRight: "10px",
              fill: "#0191ff",
            }}
          />
        </Link>
        <InputAndMenuBar>
          <EditableDocNameInput
            value={wordDoc?.fileName?.replace("**CHAT**", "")}
            onChange={e => {
              setWordDoc({
                ...wordDoc,
                fileName: `**CHAT**${e.target.value}`,
              });
            }}
            onBlur={() => doSaveDoc(wordDoc)}
          />
        </InputAndMenuBar>
        <StyledNavHeader isProfileOnly />
      </DocNameAndSourcesModal>

      <div style={{ position: "relative" }}>
        <Panel>
          <TextBlocks ref={scrollerRef}>
            {blocks?.map((block, index) => {
              const prevBlock = blocks?.[index - 1];

              if (block?.imageBase64) {
                return (
                  <BlockText
                    style={{
                      backgroundColor: block?.isQuery
                        ? "transparent"
                        : "#f1f1f1",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        border: "1px solid #f1f1f1",
                      }}
                      src={`data:image/png;base64,${block?.imageBase64}`}
                      alt="plot"
                    />
                  </BlockText>
                );
              }

              const blockTextSegments = [];
              let currentSegment = {
                text: "",
                meta: null,
                bgColor: "transparent",
              };
              let i = 0;
              while (i < block?.text?.length) {
                const styleAtIndex = block?.styles?.find(
                  style => i >= style?.start && i < style?.end
                );
                const metaAtIndex = styleAtIndex?.meta || null;

                if (!isEqual(metaAtIndex, currentSegment?.meta)) {
                  blockTextSegments.push(currentSegment);
                  currentSegment = {
                    text: "",
                    meta: metaAtIndex,
                    bgColor: styleAtIndex?.bgColor || "transparent",
                  };
                }

                currentSegment.text += block?.text?.[i];
                i++;
              }
              blockTextSegments.push(currentSegment);

              return (
                <BlockText
                  style={{
                    backgroundColor: block?.isQuery ? "transparent" : "#f1f1f1",
                  }}
                >
                  {(block?.isQuery || prevBlock?.isQuery) && (
                    <Gap height="10px" />
                  )}
                  {blockTextSegments?.map(segment => {
                    if (segment?.meta) {
                      return (
                        <PagePreviewTextAndTableModalSpanTrigger
                          tableDocumentLocation={segment?.meta}
                          style={{ backgroundColor: segment?.bgColor }}
                        >
                          {segment?.text}
                        </PagePreviewTextAndTableModalSpanTrigger>
                      );
                    }
                    return segment?.text;
                  })}
                  {block?.isQuery && <Gap height="10px" />}
                </BlockText>
              );
            })}
            <Gap height="30px" />
          </TextBlocks>
          {blocks?.length === 0 && (
            <Questions
              style={{ position: "absolute", bottom: "80px", left: 0 }}
            >
              {wordDoc?.content?.exampleQuestions?.map(question => (
                <Question
                  onClick={() => {
                    const blocksWithoutSelection = removeSelectionStyle(blocks);
                    const payloadBlocks = [
                      ...blocksWithoutSelection,
                      {
                        isQuery: true,
                        text: question,
                        styles: [
                          {
                            isSelection: true,
                            start: question.length,
                            end: question.length,
                          },
                        ],
                      },
                    ];
                    setBlocks(payloadBlocks);
                    setUserInput("");
                    onPressEnterInCommandInput(question, payloadBlocks);
                  }}
                >
                  {question}
                </Question>
              ))}
            </Questions>
          )}
          <SmallButton
            onClick={() => setShouldCancelGeneration(true)}
            style={{
              opacity: isGenerating ? 1 : 0,
              position: "absolute",
              bottom: "80px",
              right: 0,
              padding: "4px",
              display: "block",
            }}
            value="Stop"
          />
          <UserTextInput
            disabled={isGenerating}
            value={userInput}
            onChange={e => {
              setUserInput(e.target.value);
            }}
            placeholder="Ask a question"
            onKeyDown={e => {
              if (e.key !== "Enter") {
                return;
              }

              const blocksWithoutSelection = removeSelectionStyle(blocks);
              const payloadBlocks = [
                ...blocksWithoutSelection,
                {
                  isQuery: true,
                  text: userInput,
                  styles: [
                    {
                      isSelection: true,
                      start: userInput.length,
                      end: userInput.length,
                    },
                  ],
                },
              ];
              setBlocks(payloadBlocks);
              setUserInput("");
              onPressEnterInCommandInput(userInput, payloadBlocks);
            }}
          />
        </Panel>
      </div>
    </Container>
  );
};

export default ChatWordDocPublishedPage;
