import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { cloneDeep, last } from "lodash";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import {
  postWordDoc,
  postWordDocsIdSaveAsTemplate,
} from "api/services/wordDocsService";
import { COLUMN_TYPES } from "components/DashboardColumnModalTrigger";
import { postPipelineConfigs } from "api/backend/fileSystemEndpoints";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  gap: 15px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 32px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
  z-index: 1;

  position: sticky;
  top: 0px;
`;

const TableContainer = styled.div`
  min-width: 800px;
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 120px;
  padding: 0;
`;

const StyledInput = styled(StyledBoldInput)`
  font-weight: 400;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const INITIAL_COLUMNS = [
  {
    name: "Source Name",
    description: "Where the record comes from",
    type: "TEXT",
  },
  {
    name: "Inserted Time",
    description: "When the the record was extracted",
    type: "DATETIME",
  },
];

const INITIAL_COLUMN_NAMES = INITIAL_COLUMNS.map(column => column.name);

const PipelineNameModalTriggerNew = ({ trigger = null }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [pipelineName, setPipelineName] = useState("");

  const [columns, setColumns] = useState(INITIAL_COLUMNS);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setColumns(INITIAL_COLUMNS);
  }, [isOpen]);

  const doSaveDocAsTemplate = async () => {
    setIsLoading(true);
    const trimmedColumns = columns.map(column => ({
      name: column?.name?.trim(),
      description: column?.description?.trim(),
      type: column?.type,
    }));

    const pipelineConfig = {
      name: pipelineName?.trim(),
      config: {
        layout: null,
        topLayout: null,
        components: [
          {
            id: "source_records",
            name: "Your Records",
            columns: trimmedColumns,
            pythonCode: "print(input())",
            records: [],
          },
        ],
      },
    };

    // const wordDocBody = {
    //   fileName: pipelineName?.trim(),
    //   content: {
    //     dashboardConfig: {
    //       columns: trimmedColumns,
    //     },
    //     blocks: trimmedColumns.map(column => {
    //       let text = `extract "${column?.name}"`;
    //       if (column?.description) {
    //         text = `extract "${column?.name}", with description "${column?.description}"`;
    //       }

    //       return {
    //         isQuery: true,
    //         text,
    //         queryId: uuidv4(),
    //       };
    //     }),
    //   },
    // };

    // const { data: newWordDoc, error: docCreateErr } = await postWordDoc(
    //   {},
    //   wordDocBody
    // );
    // if (docCreateErr) {
    //   setError(docCreateErr);
    //   setIsLoading(false);
    //   return;
    // }
    // const { data: newTemplateId, error: templateErr } =
    //   await postWordDocsIdSaveAsTemplate(newWordDoc?.id);
    // if (templateErr) {
    //   setError(templateErr);
    //   setIsLoading(false);
    //   return;
    // }

    const { data, error } = await postPipelineConfigs({}, pipelineConfig);
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    window.open(`/trial-dashboards/${data?.id}/source`, "_blank");
    window.location.reload();
    setIsLoading(false);
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>
        {trigger || <ButtonCreateNewAsset />}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Title>Pipeline Name</Title>
          <StyledTextInputSquare
            placeholder="Deal Extractor"
            value={pipelineName}
            onNewInput={newText => setPipelineName(newText)}
          />
          <Gap />
          Add columns you want extracted:
          <TableContainer>
            <table>
              <tr>
                <Th>Column Name</Th>
                <Th style={{ width: "100%" }}>Prompt</Th>
                <Th>Type</Th>
              </tr>
              {/* <tr>
                <Td>
                  <BoldSpan>Email Subject</BoldSpan>
                </Td>
                <Td>The subject of the email</Td>
              </tr>
              <tr>
                <Td>
                  <BoldSpan>Received Time</BoldSpan>
                </Td>
                <Td>When the email was received</Td>
              </tr>
              <tr>
                <Td>
                  <BoldSpan>Recieved At</BoldSpan>
                </Td>
                <Td>The date the email was recieved</Td>
              </tr> */}
              {columns?.map((column, i) => (
                <tr>
                  <Td key={i}>
                    <StyledBoldInput
                      style={{
                        pointerEvents: INITIAL_COLUMN_NAMES?.includes(
                          column?.name
                        )
                          ? "none"
                          : "all",
                      }}
                      value={column?.name}
                      placeholder="Column Name"
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].name = e.target.value;
                        setColumns(newColumns);
                      }}
                    />
                    <StyledTrashIcon
                      onClick={() => {
                        const newColumns = cloneDeep(columns);
                        newColumns.splice(i, 1);
                        setColumns(newColumns);
                      }}
                    />
                  </Td>
                  <Td>
                    <StyledInput
                      style={{
                        width: "100%",
                        pointerEvents: INITIAL_COLUMN_NAMES?.includes(
                          column?.name
                        )
                          ? "none"
                          : "all",
                      }}
                      value={column?.description}
                      placeholder="Description"
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].description = e.target.value;
                        setColumns(newColumns);
                      }}
                    />
                  </Td>
                  <Td>
                    <StyledSelect
                      disabled={INITIAL_COLUMN_NAMES?.includes(column?.name)}
                      value={column?.type}
                      onChange={e => {
                        const newColumns = cloneDeep(columns);
                        newColumns[i].type = e.target.value;
                        setColumns(newColumns);
                      }}
                    >
                      {COLUMN_TYPES?.map(type => (
                        <option>{type}</option>
                      ))}
                    </StyledSelect>
                  </Td>
                </tr>
              ))}
              <tr>
                <Td style={{ border: "none" }}>
                  <StyledPlusIcon
                    onClick={() => {
                      setColumns([
                        ...columns,
                        {
                          name: "",
                          description: "",
                          type: "TEXT",
                        },
                      ]);
                    }}
                  />
                </Td>
              </tr>
            </table>
          </TableContainer>
          <Gap />
          <StyledButton
            variant="highlighted"
            isDisabled={!pipelineName}
            value="Create"
            onClick={doSaveDocAsTemplate}
          />
          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default PipelineNameModalTriggerNew;
