import Tooltip from "components/ui/Tooltip";
import styled from "styled-components";

const BlackTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props =>
    props?.isGreyedOut ? props.theme.color.closer2 : props.theme.color.closest};
  padding: 2px 5px;
`;

export default BlackTooltip;
