import { useState, useRef } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";
import { useEffect } from "react";
import SmallButton from "components/ui/SmallButton";

const SHADES_OF_GREY = [
  "#000000",
  "#222222",
  "#444444",
  "#666666",
  "#888888",
  "#AAAAAA",
  "#CCCCCC",
  "#DDDDDD",
  "#EEEEEE",
  "#FFFFFF",
  "transparent",
];

const OTHER_COLORS = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#00FFFF",
  "#FF00FF",
  "#FF8000",
  "#00FF80",
  "#8000FF",
  "#FF0080",
];

const Container = styled.div`
  position: relative;
`;

const Tip = styled.div`
  position: absolute;
  left: 0;
  width: max-content;
  max-height: 300px;
  overflow: auto;

  background-color: ${props => props.theme.color.furthest};
  z-index: 110;
  box-shadow: 0 8px 24px
    ${props =>
      props.theme.name === "light"
        ? "rgba(140, 149, 159, 0.2)"
        : "rgba(0, 0, 0, 0.4)"};
  font-weight: 400;

  display: ${props => (props.isVisible ? "flex" : "none")};
  gap: 8px;
  padding: 4px;

  border: 1px solid ${props => props.theme.color.closer1};
`;

const Input = styled.input`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const LinkUrlTooltip = ({ onNewUrl, triggerIcon }) => {
  const tipRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl("");
  }, [isOpen]);

  useClickOutside(tipRef, () => setIsOpen(false));

  return (
    <Container>
      <div onClick={() => setIsOpen(true)}>{triggerIcon}</div>
      {isOpen && (
        <Tip ref={tipRef} isVisible={isOpen}>
          <Input
            value={url}
            onPaste={e => e.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
            onChange={e => setUrl(e.target.value)}
            placeholder="Enter URL"
          />
          <SmallButton
            value="Add"
            onClick={() => {
              onNewUrl(url);
              setIsOpen(false);
            }}
          />
        </Tip>
      )}
    </Container>
  );
};

export default LinkUrlTooltip;
