import { COMPONENT_TYPES } from "components/ComponentCodeModalTrigger";
import { useState, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OptionsContainer = styled.div`
  position: absolute;
  z-index: 200;
  background-color: ${props => props.theme.color.furthest};
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 4px 0 ${props => props.theme.color.closer1_5};
  white-space: nowrap;
  width: 100%;
`;

const Option = styled.div`
  width: 100%;
  padding: 4px;
  min-width: 200px;
  font-size: 12px;
  cursor: pointer;
  background-color: ${props => props.theme.color.closer0};
  ${props =>
    props.isHighlighted && `background-color: ${props.theme.color.closer1};`}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  font-family: "Montserrat";
  border: none;
  outline: none;
  background-color: ${props => props.theme.color.closer0};
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.closer2}22,
    ${props => props.theme.color.closer2}22
  );
`;

const CONTROL_KEYS = ["ArrowDown", "ArrowUp", "Enter", "Escape"];

const AutocompleteTextInputDropdown = ({
  value,
  placeholder,
  onChangeValue = newText => {},
  onPressEnterOnSelection = selectedValue => {},
  onPressEnterNoSelection = () => {},
  isDisabled = false,
  onFocus = e => {},
  onBlur = e => {},
  options = [],
  dropdownContent = null,
  style = {},
  autoFocus = false,
  onClick,
  className = "",
  inputStyle = {},
  onKeyDownAfterSelection = () => {},
  isOptionClickDisabled = false,
  isShowAsDisabled = false,
}) => {
  const inputRef = useRef(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  let filteredOptions = options?.filter(option =>
    option?.toLowerCase()?.includes(value?.toLowerCase())
  );

  if (!value?.includes("show as") && !isShowAsDisabled && value?.length) {
    filteredOptions = [
      ...filteredOptions,
      ...COMPONENT_TYPES?.map(type => `${value}, show as ${type}`),
    ]?.flat();
  }
  // const filteredOptions = filteredOptions1
  //   ?.map(option => {
  //     if (value?.endsWith(",") || value?.endsWith(", ")) {
  //       return ["Table", "Geomap", "Linechart"].map(
  //         val => `${option}, show as ${val}`
  //       );
  //     }
  //     return option;
  //   })
  //   ?.flat();

  const handleArrowKeysAndEnter = e => {
    // if (dropdownContent || !filteredOptions?.length) {
    //   return;
    // }

    if (CONTROL_KEYS.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "Escape") {
      inputRef.current.blur();
      setSelectedOptionIndex(-1);
    }

    if (e.key === "ArrowDown") {
      setSelectedOptionIndex(ind =>
        Math.min(ind + 1, filteredOptions?.length - 1)
      );
    }

    if (e.key === "ArrowUp") {
      setSelectedOptionIndex(ind => Math.max(ind - 1, -1));
    }

    if (e.key === "Enter" && selectedOptionIndex !== -1) {
      onChangeValue(filteredOptions[selectedOptionIndex]);
      onPressEnterOnSelection(filteredOptions[selectedOptionIndex]);
      setSelectedOptionIndex(-1);
    }

    if (e.key === "Enter" && selectedOptionIndex === -1) {
      onKeyDownAfterSelection(e);
    }
  };

  if (dropdownContent) {
    return (
      <Container style={style} className={className}>
        <StyledInput
          placeholder={placeholder}
          ref={inputRef}
          disabled={isDisabled}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={e => onChangeValue(e.target.value)}
          autoFocus={autoFocus}
          onClick={onClick}
        />
        {document.activeElement === inputRef.current && (
          <OptionsContainer>{dropdownContent}</OptionsContainer>
        )}
      </Container>
    );
  }

  return (
    <Container style={style} className={className}>
      <StyledInput
        style={inputStyle}
        placeholder={placeholder}
        ref={inputRef}
        disabled={isDisabled}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={e => onChangeValue(e.target.value)}
        onKeyDown={handleArrowKeysAndEnter}
        autoFocus={autoFocus}
        onClick={onClick}
      />
      {document.activeElement === inputRef.current && (
        <OptionsContainer>
          {filteredOptions?.map((option, i) => (
            <Option
              style={{
                ...inputStyle,
                pointerEvents: isOptionClickDisabled ? "none" : "all",
              }}
              key={i}
              isHighlighted={selectedOptionIndex === i}
              onClick={() => {
                onChangeValue(option);
                setSelectedOptionIndex(-1);
              }}
            >
              {option}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
};

export default AutocompleteTextInputDropdown;
