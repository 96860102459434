import * as filesBackend from "api/backend/filesEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { sleep } from "utils/common";

export const getFiles = async ({ pageSize, offset, strip } = {}) => {
  if (!offset) {
    const { data, error } = await filesBackend.getFiles({ pageSize, strip });
    return { data, error };
  }
  const { data, error } = await filesBackend.getFiles({
    pageSize,
    offset,
    strip,
  });
  return { data, error };
};

export const getDealNames = async params => {
  const { data, error } = await filesBackend.getDealNames(params);
  return { data, error };
};

export const getFile = async (fileId, params = {}) => {
  const { data, error } = await filesBackend.getFile(fileId, params);
  return { data, error };
};

export const postFile = async file => {
  const { data, error } = await filesBackend.postFile(file);
  return { data, error };
};

export const postBulkUploadFormData = async (
  params = {},
  body = { files: [] }
) => {
  const formDataBody = new FormData();
  body?.files?.forEach(file => {
    formDataBody.append("files", file);
  });

  const { data, error } = await filesBackend.postBulkUpload(
    params,
    formDataBody
  );
  return { data, error };
};

export const patchFileById = async (fileId, fileBody) => {
  const { data, error } = await filesBackend.patchFileById(fileId, fileBody);
  return { data, error };
};

export const importFileWithUrl = async url => {
  const { data, error } = await filesBackend.importFileWithUrl(url);
  return { data, error };
};

export const importFileWithUrlsAndRandomPage = async urls => {
  const { data, error } = await filesBackend.importFileWithUrlsAndRandomPage(
    urls
  );
  return { data, error };
};

export const deleteAllTrainingFiles = async () => {
  const { data, error } = await filesBackend.deleteAllTrainingFiles();
  return { data, error };
};

export const postContriubutor = async (fileId, params) => {
  const { data, error } = await filesBackend.postContriubutor(fileId, params);
  return { data, error };
};

export const deleteContributor = async (fileId, contributorUserName) => {
  const { data, error } = await filesBackend.deleteContributor(
    fileId,
    contributorUserName
  );
  return { data, error };
};

export const getFilePageResults = async (fileId, pageNumber) => {
  if (!fileId || !Number.isInteger(parseInt(pageNumber))) {
    return { data: null, error: null };
  }
  const { data, error } = await filesBackend.getFilePageResults(
    fileId,
    pageNumber
  );
  return { data, error: getHandlingFromError(error) };
};

export const getFileById = async fileId => {
  const { data, error } = await filesBackend.getFileById(fileId);
  return { data, error };
};

export const deleteFileById = async fileId => {
  const { data, error } = await filesBackend.deleteFileById(fileId);
  return { data, error };
};

export const getPreDefinedLabelSets = async () => {
  const { data, error } = await filesBackend.getPreDefinedLabelSets();
  return { data, error };
};

export const updatePreDefinedLabelSet = async userLabelSet => {
  const { data, error } = await filesBackend.updatePreDefinedLabelSet(
    userLabelSet
  );
  return { data, error };
};

export const addPreDefinedLabelSet = async userLabelSet => {
  const { data, error } = await filesBackend.addPreDefinedLabels(userLabelSet);
  return { data, error };
};

export const getActivatedLabelSetId = async () => {
  const { data, error } = await filesBackend.getActivatedLabelSetId();
  return { data, error };
};

export const shareLabelset = async (id, user) => {
  const { data, error } = await filesBackend.shareLabelset(id, user);
  return { data, error };
};

export const setAcitvatedLabelSetId = async labelSetId => {
  const { data, error } = await filesBackend.setAcitvatedLabelSetId(labelSetId);
  return { data, error };
};

export const getAnnotationsByFileAndPage = async (fileId, pageNumber) => {
  const { data, error } = await filesBackend.getAnnotationsByFileAndPage(
    fileId,
    pageNumber
  );
  return { data: data || [], error };
};

export const saveAnnotationsByFileAndPage = async (
  fileId,
  pageNumber,
  annotations
) => {
  const { data, error } = await filesBackend.saveAnnotationsByFileAndPage(
    fileId,
    pageNumber,
    {
      ...annotations,
      checked: true,
    }
  );
  return { data, error };
};

export const patchPageAnnotations = async (
  fileId,
  pageNumber,
  fieldsToUpdate
) => {
  const { data, error } = await filesBackend.saveAnnotationsByFileAndPage(
    fileId,
    pageNumber,
    fieldsToUpdate
  );
  return { data, error };
};

export const searchWithKeyWords = async query => {
  const { data, error } = await filesBackend.searchWithKeyWords(query);
  return { data, error };
};

export const postFilesRerunForFileId = async fileId => {
  const { data, error } = await filesBackend.postFilesRerunForFileId(fileId);
  return { data, error: getHandlingFromError(error) };
};

export const postFilesRerunForFileIdForPage = async (fileId, pageNumber) => {
  const { data, error } = await filesBackend.postFilesRerunForFileIdForPage(
    fileId,
    pageNumber
  );
  return { data, error: getHandlingFromError(error) };
};

export const patchTextAnnotationsByFileAndPage = async (
  fileId,
  pageNumber,
  body
) => {
  await sleep(500);
  return { data: null, error: null };
};

export const getCommentsByFileAndPage = async (fileId, pageNumber) => {
  const { data, error } = await filesBackend.getCommentsByFileAndPage(
    fileId,
    pageNumber
  );
  return { data, error: getHandlingFromError(error) };
};

export const postCommentsForFileAndPage = async (
  fileId,
  pageNumber,
  comments
) => {
  const { data, error } = await filesBackend.postCommentsForFileAndPage(
    fileId,
    pageNumber,
    { comments }
  );
  return { data, error: getHandlingFromError(error) };
};

export const getExcelFilePageResults = async fileId => {
  const { data, error } = await filesBackend.getExcelFilePageResults(fileId);
  return { data, error: getHandlingFromError(error) };
};

export const postIngestPublicContent = async (params = {}, body = {}) => {
  const { data, error } = await filesBackend.postIngestPublicContent(
    params,
    body
  );
  return { data, error };
};

export const postUploadForDatabase = async (params = {}, body = {}) => {
  const { data, error } = await filesBackend.postUploadForDatabase(
    params,
    body
  );
  return { data, error };
};

export const postAudioFiles = async (params = {}, body = {}) => {
  const { data, error } = await filesBackend.postAudioFiles(params, body);
  return { data, error };
};

export const postAudioFilesFormData = async (
  params = {},
  body = { files: [] }
) => {
  const formDataBody = new FormData();
  formDataBody.append("file", body?.files?.[0]);

  const { data, error } = await filesBackend.postAudioFiles(
    params,
    formDataBody
  );
  return { data, error };
};

export const getOneFile = async params => {
  const { data, error } = await filesBackend.getOneFile(params);
  return { data, error };
};
