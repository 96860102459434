import { clamp, cloneDeep, inRange, isNil, last, merge } from "lodash";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { postChatflowGenerate } from "api/services/searchService";
import { CrossIcon, MonitorIcon } from "components/ui/Icons";
import SmallButton from "components/ui/SmallButton";
import { uuidv4 } from "utils/common";
import useClickOutside from "hooks/useClickOutside";

export const SF = 4;
const FONT_SIZE = 16;
const LINE_HEIGHT = 16 * 1.2;
export const CANVAS_WIDTH = 960;
export const CANVAS_HEIGHT = 540;
const GRAB_DISTANCE = 10;

const Container = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 300px; */
  gap: 20px;
  position: relative;
`;

const Canvas = styled.canvas`
  border: 1px solid ${props => props.theme.color.closer1_5};
  width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  transform-origin: top center;
  /* background-color: ${props => props.theme.color.furthest}; */
`;

const StyledMonitorIcon = styled(MonitorIcon)`
  opacity: 0.4;
  cursor: pointer;
  :hover {
    fill: ${props => props.theme.color.primary};
    opacity: 1;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  border-right: 1px solid ${props => props.theme.color.closer1};
  outline: none;
  padding: 8px;
  font-family: "Montserrat", sans-serif;
  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}
`;

const StyledCrossIcon = styled(CrossIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const CONFIG = {
  boxes: [
    {
      id: "0",
      x: 50,
      y: 100,
      w: 200,
      h: 150,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque dolor, elementum in urna nec, pellentesque condimentum mi. Praesent nec vulputate felis.",
      styles: [],
      isSelected: true,
      boxStyle: {
        fontSize: 26,
      },
    },
    {
      id: "1",
      x: 300,
      y: 300,
      w: 400,
      h: 150,
      text: "whats up",
      styles: [],
      isSelected: false,
    },
  ],
};

const onSetSelectedBox = (boxId, config, setConfig) => {
  const newBoxes = config.boxes.map(box => {
    const stylesWithouSelection =
      box?.styles?.filter(s => !s.isSelection) || [];
    if (box.id === boxId) {
      return {
        ...box,
        styles: stylesWithouSelection,
        isSelected: true,
      };
    }
    return { ...box, styles: stylesWithouSelection, isSelected: false };
  });
  const newConfig = cloneDeep(config);
  newConfig.boxes = newBoxes;
  setConfig(newConfig);
};

const onMoveSelectedBox = (dx, dy, config, setConfig) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig.boxes.find(box => box.isSelected);
  if (!selectedBox) {
    return;
  }

  selectedBox.x += dx / SF;
  selectedBox.y += dy / SF;

  setConfig(newConfig);
};

const onResizeSelectedBox = (offsetX, offsetY, config, setConfig) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig.boxes.find(box => box.isSelected);
  if (!selectedBox) {
    return;
  }

  const originalW = selectedBox.w;
  const originalH = selectedBox.h;
  selectedBox.w = offsetX / SF - selectedBox.x;
  selectedBox.h = offsetY / SF - selectedBox.y;

  if (selectedBox?.canvasImage) {
    selectedBox.h = originalH * (selectedBox.w / originalW);
  }

  setConfig(newConfig);
};

const onDeleteSelectedBox = (config, setConfig) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig.boxes.find(box => box.isSelected);
  if (!selectedBox) {
    return;
  }

  newConfig.boxes = newConfig.boxes.filter(box => box.id !== selectedBox.id);
  setConfig(newConfig);
};

const onAddNewBox = (x, y, text, config, setConfig) => {
  const newConfig = cloneDeep(config);
  const id = uuidv4();
  const newBox = {
    id,
    x,
    y,
    w: 300,
    h: 200,
    text,
    styles: [],
    boxStyle: {
      bgColor: "transparent",
      fontSize: 16,
      fontWeight: 400,
      color: "#000000",
      lineWidth: 0,
      type: "text",
      lineStart: "",
      lineEnd: "",
    },
    isSelected: true,
  };
  newConfig.boxes.push(newBox);
  newConfig.boxes.forEach(box => {
    if (box?.id !== id) {
      box.isSelected = false;
    }
  });
  setConfig(newConfig);
  console.log({ newConfig });
};

const updateCursor = (
  dCursor = { start: null, end: null, dStart: 0, dEnd: 0 },
  config,
  setConfig
) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig.boxes.find(box => box.isSelected);
  const selectionStyle = selectedBox?.styles?.find(s => s.isSelection);

  let newStart =
    (dCursor?.start ?? selectionStyle?.start ?? 0) + (dCursor?.dStart ?? 0);
  let newEnd =
    (dCursor?.end ?? selectionStyle?.end ?? 0) + (dCursor?.dEnd ?? 0);

  newStart = clamp(newStart, 0, selectedBox?.text?.length);
  newEnd = clamp(newEnd, 0, selectedBox?.text?.length);

  const newSelectionStyle = {
    isSelection: true,
    start: newStart,
    end: newEnd,
  };

  selectedBox.styles = selectedBox.styles.filter(style => !style.isSelection);
  selectedBox.styles.push(newSelectionStyle);

  setConfig(newConfig);
};

const clearSelection = (config, setConfig) => {
  const newConfig = cloneDeep(config);
  newConfig.boxes.forEach(box => {
    box.isSelected = false;
    box.styles = box.styles.filter(style => !style.isSelection);
  });
  setConfig(newConfig);
};

const updateCursorSelectWord = (charIndex, config, setConfig) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig.boxes.find(box => box.isSelected);

  let newStart = charIndex;
  while (newStart >= 0 && selectedBox.text[newStart] !== " ") {
    newStart--;
  }
  let newEnd = charIndex;
  while (
    newEnd < selectedBox.text?.length &&
    selectedBox.text[newEnd] !== " "
  ) {
    newEnd++;
  }

  const newSelectionStyle = {
    isSelection: true,
    start: newStart + 1,
    end: newEnd - 1,
  };

  selectedBox.styles = selectedBox.styles.filter(style => !style.isSelection);
  selectedBox.styles.push(newSelectionStyle);

  setConfig(newConfig);
};

const insertTextAtSelection = (text, config, setConfig) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig?.boxes?.find(box => box.isSelected);
  const selectionStart = selectedBox?.styles?.find(s => s.isSelection)?.start;
  if (isNil(selectionStart)) {
    // onAddNewBox(100, 100, text, config, setConfig);
    return;
  }

  selectedBox.text =
    selectedBox.text?.slice(0, selectionStart) +
    text +
    selectedBox.text?.slice(selectionStart);

  selectedBox.styles = selectedBox.styles.map(style => {
    const newStyle = { ...style };

    if (selectionStart <= style.start) {
      newStyle.start += text?.length || 0;
    }

    if (selectionStart <= style.end) {
      newStyle.end += text?.length || 0;
    }

    return newStyle;
  });

  setConfig(newConfig);
};

const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

const insertBoxesFromHtml = async (htmlStr, config, setConfig) => {
  const src = htmlStr?.match(/src="([^"]*)"/)?.[1];

  const imageToInsert = new Image();
  imageToInsert.crossOrigin = "anonymous";

  const dataBase64 = await toDataURL(src);
  imageToInsert.src = dataBase64;

  imageToInsert.onload = () => {
    const newConfig = cloneDeep(config);

    const id = uuidv4();
    const newBox = {
      id,
      x: 100,
      y: 100,
      w: imageToInsert.width * (400 / imageToInsert.height),
      h: 400,
      styles: [],
      boxStyle: {
        bgColor: "transparent",
      },
      isSelected: true,
      canvasImage: imageToInsert,
    };

    newConfig.boxes.push(newBox);
    newConfig.boxes.forEach(box => {
      if (box?.id !== id) {
        box.isSelected = false;
      }
    });

    setConfig(newConfig);
  };
};

const BLANK_BOX = {
  id: "",
  x: 100,
  y: 100,
  w: 600,
  h: 500,
  text: "",
  styles: [],
  boxStyle: {
    bgColor: "transparent",
    fontSize: 16,
    fontWeight: 400,
    color: "#000000",
    lineWidth: 0,
    type: "text",
    lineStart: "",
    lineEnd: "",
  },
  isSelected: true,
};

const insertBlocksAtSelection = (blocks, config, setConfig) => {
  const lines = blocks
    ?.filter(block => !block?.isQuery)
    ?.map(block => block?.text);
  const text = lines?.join("\n");

  const newConfig = cloneDeep(config);
  const selectedBox = newConfig?.boxes?.find(box => box.isSelected);

  if (!selectedBox) {
    return;
  }

  selectedBox.text = text;

  setConfig(newConfig);
};

/*
     <--|
        |--->
this is the text
*/
const deleteTextSpan = (text, start, span) => {
  let textBefore = text?.slice(0, start);
  let textAfter = text?.slice(start + span);

  if (span < 0) {
    textBefore = text?.slice(0, start + span);
    textAfter = text?.slice(start);
  }

  text = textBefore + textAfter;
  return text;
};

const deleteTextSelection = (config, setConfig) => {
  const newConfig = cloneDeep(config);
  const selectedBox = newConfig.boxes.find(box => box.isSelected);
  const selectionStart = selectedBox.styles.find(s => s.isSelection)?.start;
  const selectionEnd = selectedBox.styles.find(s => s.isSelection)?.end;
  if (isNil(selectionStart)) {
    return;
  }

  const deletionSpan =
    selectionStart === selectionEnd ? -1 : selectionEnd - selectionStart + 1;
  selectedBox.text = deleteTextSpan(
    selectedBox.text,
    selectionStart,
    deletionSpan
  );

  selectedBox.styles = selectedBox.styles.map(style => {
    const newStyle = { ...style };

    if (selectionStart <= style.start) {
      newStyle.start -= Math.abs(deletionSpan);
    }

    if (selectionStart <= style.end + 1) {
      newStyle.end -= Math.abs(deletionSpan);
    }

    if (
      selectionEnd >= style.start &&
      selectionEnd <= style.end &&
      selectionStart !== selectionEnd
    ) {
      newStyle.start = selectionStart;
    }

    if (
      selectionStart <= style.end &&
      selectionEnd >= style.end &&
      selectionStart !== selectionEnd
    ) {
      newStyle.end = selectionStart - 1;
    }

    if (style?.isSelection && selectionStart !== selectionEnd) {
      newStyle.start = selectionStart;
      newStyle.end = selectionStart;
    }

    return newStyle;
  });

  setConfig(newConfig);
};

const drawTextBox = (ctx, config) => {
  ctx.beginPath();

  ctx.strokeStyle = "transparent";

  const { fontSize, fontWeight, color, lineWidth, type, lineEnd } =
    config.boxStyle || {};
  if (lineWidth) {
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color || "#000000";
  } else {
    ctx.lineWidth = 2;
  }

  if (config.isSelected) {
    ctx.strokeStyle = "blue";
  }

  if (type === "line") {
    ctx.moveTo(config.x, config.y);
    ctx.lineTo(config.x + config.w, config.y + config.h);
    ctx.stroke();
  }

  if (type === "line" && lineEnd === "arrow") {
    const angle = Math.atan2(config.h, config.w);
    const lineLength = Math.sqrt(config.w ** 2 + config.h ** 2) * 0.9;
    let a = angle + 0.05;

    let arrowStartX = Math.cos(a) * lineLength;
    let arrowStartY = Math.sin(a) * lineLength;
    ctx.beginPath();
    ctx.moveTo(config.x + arrowStartX, config.y + arrowStartY);
    ctx.lineTo(config.x + config.w, config.y + config.h);
    ctx.stroke();

    a = angle - 0.05;

    arrowStartX = Math.cos(a) * lineLength;
    arrowStartY = Math.sin(a) * lineLength;
    ctx.beginPath();
    ctx.moveTo(config.x + arrowStartX, config.y + arrowStartY);
    ctx.lineTo(config.x + config.w, config.y + config.h);
    ctx.stroke();
  }

  if (type === "line") {
    return;
  }

  ctx.fillStyle = config?.boxStyle?.bgColor || "#ffffff";
  ctx.fillRect(config.x, config.y, config.w, config.h);
  ctx.rect(config.x, config.y, config.w, config.h);
  ctx.stroke();

  if (config?.canvasImage) {
    ctx.drawImage(config?.canvasImage, config.x, config.y, config.w, config.h);
    return;
  }

  const lineHeight = fontSize * 1.2 || LINE_HEIGHT;
  ctx.fillStyle = color || "#000000";
  const fontStr = `${fontWeight || 400} ${
    fontSize * SF || FONT_SIZE * SF
  }px Montserrat`;
  ctx.font = fontStr;
  ctx.textBaseline = "top";

  let charIndex = 0;
  let currentX = config?.x || 0;
  let currentY = config?.y || 0;

  const selectionStart = config?.styles?.find(s => s.isSelection)?.start;
  while (charIndex < config?.text?.length) {
    // draw cursor
    if (charIndex === selectionStart) {
      const tempLineWidth = ctx.lineWidth;
      const tempStrokeStyle = ctx.strokeStyle;

      ctx.lineWidth = 3;
      ctx.strokeStyle = "black";

      ctx.beginPath();
      ctx.moveTo(currentX, currentY);
      ctx.lineTo(currentX, currentY + lineHeight * SF);
      ctx.stroke();

      ctx.lineWidth = tempLineWidth;
      ctx.strokeStyle = tempStrokeStyle;
    }

    const isLineTooLong = currentX > config.x + config.w - FONT_SIZE * SF;
    if (isLineTooLong || config?.text?.[charIndex] === "\n") {
      currentY += lineHeight * SF;
      currentX = config?.x || 0;
    }

    const charStyles =
      config?.styles?.filter(s => s.start <= charIndex && s.end >= charIndex) ||
      [];
    const charStylesCopy = cloneDeep(charStyles);
    const charStyle = merge(...charStylesCopy);
    ctx.font = fontStr;
    if (charStyle?.fontWeight) {
      ctx.font = `${charStyle.fontWeight} ${fontSize * SF}px Montserrat`;
    }

    const tempFillStyle = ctx.fillStyle;
    if (charStyle?.color) {
      ctx.fillStyle = charStyle.color;
    }

    let charToDraw = config?.text[charIndex];
    if (charToDraw === "\n") {
      charToDraw = "";
    }

    if (charStyle?.isSelection && charStyle?.start !== charStyle?.end) {
      const tempFillStyle = ctx.fillStyle;
      ctx.fillStyle = "#0000ff22";
      ctx.fillRect(
        currentX,
        currentY,
        ctx.measureText(charToDraw).width,
        lineHeight * SF
      );
      ctx.fillStyle = tempFillStyle;
    }

    if (currentY > config.y + config.h - lineHeight * SF) {
      break;
    }

    ctx.fillText(charToDraw, currentX, currentY);
    ctx.fillStyle = tempFillStyle;
    currentX += ctx.measureText(charToDraw).width;
    charIndex++;
  }

  if (charIndex === selectionStart) {
    const tempLineWidth = ctx.lineWidth;
    const tempStrokeStyle = ctx.strokeStyle;

    ctx.lineWidth = 3;
    ctx.strokeStyle = "black";

    ctx.beginPath();
    ctx.moveTo(currentX, currentY);
    ctx.lineTo(currentX, currentY + lineHeight * SF);
    ctx.stroke();

    ctx.lineWidth = tempLineWidth;
    ctx.strokeStyle = tempStrokeStyle;
  }
};

const scale = config => {
  const configToDraw = cloneDeep(config);
  configToDraw.boxes = configToDraw.boxes.map(box => ({
    ...box,
    x: box.x * SF,
    y: box.y * SF,
    w: box.w * SF,
    h: box.h * SF,
  }));
  return configToDraw;
};

export const drawConfig = (ctx, config) => {
  ctx.clearRect(0, 0, CANVAS_WIDTH * SF, CANVAS_HEIGHT * SF);

  const scaledConfig = scale(config);
  scaledConfig.boxes.forEach(box => {
    drawTextBox(ctx, box);
  });
};

const getBoxUnderMouse = (e, config, ctx = {}) => {
  const { offsetX, offsetY } = e.nativeEvent || e;
  const [mouseX, mouseY] = [offsetX * SF, offsetY * SF];

  const scaledConfig = scale(config);
  const boxesUnderMouse = scaledConfig.boxes.filter(box => {
    let dirX = box?.w < 0 ? -1 : 1;
    let dirY = box?.h < 0 ? -1 : 1;

    return (
      inRange(
        mouseX,
        box.x - GRAB_DISTANCE * dirX,
        box.x + box.w + GRAB_DISTANCE * dirX
      ) &&
      inRange(
        mouseY,
        box.y - GRAB_DISTANCE * dirY,
        box.y + box.h + GRAB_DISTANCE * dirY
      )
    );
  });

  const boxUnderMouse = last(boxesUnderMouse);

  if (!boxUnderMouse) {
    return [null, ""];
  }

  let location = "inside"; // inside, bottomEdge, bottomRightCorner, ...
  let rightEdgeX = boxUnderMouse?.x + boxUnderMouse?.w;
  let bottomEdgeY = boxUnderMouse?.y + boxUnderMouse?.h;

  let dirX = boxUnderMouse?.w < 0 ? -1 : 1;
  let dirY = boxUnderMouse?.h < 0 ? -1 : 1;

  if (
    inRange(
      mouseX,
      rightEdgeX - GRAB_DISTANCE * dirX,
      rightEdgeX + GRAB_DISTANCE * dirX
    ) &&
    inRange(
      mouseY,
      bottomEdgeY - GRAB_DISTANCE * dirY,
      bottomEdgeY + GRAB_DISTANCE * dirY
    )
  ) {
    location = "bottomRightCorner";
  }

  let charIndex = 0;
  let currentX = boxUnderMouse?.x || 0;
  let currentY = boxUnderMouse?.y || 0;
  const { fontSize, fontWeight } = boxUnderMouse?.boxStyle || {};
  const lineHeight = fontSize * 1.2 || LINE_HEIGHT;
  const fontStr = `${fontWeight || 400} ${
    fontSize * SF || FONT_SIZE * SF
  }px Montserrat`;

  while (charIndex < boxUnderMouse?.text?.length) {
    const isLineTooLong =
      currentX > boxUnderMouse?.x + boxUnderMouse?.w - FONT_SIZE * SF;
    if (isLineTooLong || boxUnderMouse?.text[charIndex] === "\n") {
      currentY += lineHeight * SF;
      currentX = boxUnderMouse?.x || 0;
    }

    const charStyles = scaledConfig.styles?.filter(
      s => s.start <= charIndex && s.end >= charIndex
    );
    const charStyle = merge(...(charStyles || []));
    ctx.font = fontStr;
    if (charStyle?.fontWeight) {
      ctx.font = `${charStyle.fontWeight} ${FONT_SIZE * SF}px Montserrat`;
    }

    let charToDraw = boxUnderMouse?.text[charIndex];
    if (charToDraw === "\n") {
      charToDraw = "";
    }

    if (currentY > boxUnderMouse?.y + boxUnderMouse?.h - lineHeight * SF) {
      break;
    }

    currentX += ctx.measureText(charToDraw).width;

    if (currentX > mouseX && currentY > mouseY - lineHeight * SF) {
      break;
    }

    charIndex++;
  }

  return [boxUnderMouse, location, charIndex];
};

const isEditingText = config =>
  config.boxes?.find(box => box.isSelected)?.styles?.find(s => s.isSelection);

const getInitialBody = aiCommand => {
  return {
    slashQuery: aiCommand,
    genContext: "word_query",
    cursor: {
      blockIndex: 0,
      letterIndex: 16,
    },
    blocks: [
      {
        isQuery: true,
        text: aiCommand,
        styles: [
          {
            isSelection: true,
            start: aiCommand.length,
            end: aiCommand.length,
          },
        ],
      },
    ],
    sources: [],
  };
};

const locationToCursor = {
  bottomRightCorner: "nwse-resize",
  inside: "move",
  "": "default",
};

const Slide = ({ config = { boxes: [] }, setConfig = () => {} }) => {
  const canvasRef = useRef(null);
  const [currentAction, setCurrentAction] = useState(""); // moving, resizing
  const [aiCommand, setAiCommand] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());
  const [isGenerating, setIsGenerating] = useState(false);

  useClickOutside(canvasRef, () => {
    clearSelection(config, setConfig);
  });

  const onKeyDown = e => {
    if (e.key === "ArrowRight") {
      updateCursor({ dStart: 1, dEnd: 1 }, config, setConfig);
    }

    if (e.key === "ArrowLeft") {
      updateCursor({ dStart: -1, dEnd: -1 }, config, setConfig);
    }

    // if character
    if (e.key.length === 1 && !e.ctrlKey && !e.metaKey) {
      insertTextAtSelection(e.key, config, setConfig);
    }

    if (e.key === "Enter") {
      insertTextAtSelection("\n", config, setConfig);
    }

    if (e.key === "Backspace" && !isEditingText(config)) {
      onDeleteSelectedBox(config, setConfig);
    }

    if (e.key === "Backspace" && isEditingText(config)) {
      deleteTextSelection(config, setConfig);
    }
  };

  const onPaste = e => {
    e.preventDefault();
    const htmlStr = e.clipboardData.getData("text/html");
    if (htmlStr?.includes("<img")) {
      insertBoxesFromHtml(htmlStr, config, setConfig);
      return;
    }

    const text = e.clipboardData.getData("text/plain");
    insertTextAtSelection(text, config, setConfig);
  };

  const doSendAiCommand = async (body, configToPopulate) => {
    setIsGenerating(true);

    const { data } = await postChatflowGenerate(
      { max_new_tokens: 10 },
      body,
      abortController
    );

    insertBlocksAtSelection(data?.blocks, configToPopulate, setConfig);

    if (data?.isContinue) {
      doSendAiCommand(
        {
          ...body,
          ...data,
        },
        configToPopulate
      );
      return;
    }

    setAiCommand("");
    setIsGenerating(false);
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    drawConfig(ctx, config);

    document.addEventListener("paste", onPaste);
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("paste", onPaste);
    };
  }, [JSON.stringify(config), setConfig]);

  const onSendAiCommand = () => {
    let prompt = aiCommand;
    const selectedBox = config?.boxes?.find(box => box.isSelected);
    if (selectedBox?.text) {
      prompt = `${aiCommand}:\n\n"${selectedBox?.text}"`;
    }

    if (!selectedBox) {
      const configWithBlankBox = cloneDeep(config);
      configWithBlankBox.boxes.push({ id: uuidv4(), ...BLANK_BOX });
      doSendAiCommand(getInitialBody(prompt), configWithBlankBox);
      return;
    }
    doSendAiCommand(getInitialBody(prompt), config);
  };

  const ctx = canvasRef.current?.getContext("2d");

  return (
    <Container>
      <StyledMonitorIcon
        style={{
          top: "8px",
          right: "14px",
          position: "absolute",
        }}
        onClick={() => {
          canvasRef?.current?.requestFullscreen();
        }}
      />
      <Canvas
        style={{
          background: config?.bgColor || "#ffffff",
        }}
        onMouseMove={e => {
          const [, location, charIndex] = getBoxUnderMouse(e, config, ctx);
          canvasRef.current.style.cursor = locationToCursor[location];

          if (e.buttons !== 1) {
            return;
          }

          if (isEditingText(config)) {
            updateCursor({ end: charIndex }, config, setConfig);
            return;
          }

          const { movementX, movementY, offsetX, offsetY } = e.nativeEvent;

          if (
            location === "bottomRightCorner" ||
            currentAction === "resizing"
          ) {
            onResizeSelectedBox(offsetX * SF, offsetY * SF, config, setConfig);
            setCurrentAction("resizing");
            canvasRef.current.style.cursor = "nwse-resize";
            return;
          }

          if (location === "inside" || currentAction === "moving") {
            onMoveSelectedBox(
              movementX * SF,
              movementY * SF,
              config,
              setConfig
            );
            canvasRef.current.style.cursor = "move";
            setCurrentAction("moving");
          }
        }}
        onMouseDown={e => {
          const [boxUnderMouse, location, charIndex] = getBoxUnderMouse(
            e,
            config,
            ctx
          );
          if (!boxUnderMouse) {
            clearSelection(config, setConfig);
            return;
          }

          if (e.detail === 2) {
            e.stopPropagation();
          }

          const currentlySelectedBox = config.boxes.find(box => box.isSelected);
          const shouldUpdateCursor =
            currentlySelectedBox?.id === boxUnderMouse?.id &&
            isEditingText(config) &&
            location === "inside";

          if (!shouldUpdateCursor) {
            onSetSelectedBox(boxUnderMouse?.id, config, setConfig);
            return;
          }

          // if double click
          if (e.detail === 2) {
            updateCursorSelectWord(charIndex, config, setConfig);
            return;
          }

          updateCursor({ start: charIndex, end: charIndex }, config, setConfig);
        }}
        onMouseUp={() => {
          setCurrentAction("");
        }}
        onDoubleClick={e => {
          e.stopPropagation();
          const [boxUnderMouse, , charIndex] = getBoxUnderMouse(e, config, ctx);
          if (!boxUnderMouse) {
            onAddNewBox(
              e.nativeEvent.offsetX / SF,
              e.nativeEvent.offsetY / SF,
              "Add text here",
              config,
              setConfig
            );
            return;
          }

          if (isEditingText(config)) {
            return;
          }

          updateCursor({ start: charIndex, end: charIndex }, config, setConfig);
        }}
        width={CANVAS_WIDTH * SF}
        height={CANVAS_HEIGHT * SF}
        ref={canvasRef}
      />

      {/* <div
        style={{
          borderLeft: "1px solid #d4d3d3",
          padding: "10px",
          height: "calc(100vh - 40px - 67px)",
          overflowY: "auto",
        }}
      >
        <ConfigViewer
          config={config}
          setVars={setConfig}
          topLevelConfig={config}
        />
      </div> */}
    </Container>
  );
};

export default Slide;
