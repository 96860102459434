import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import { getDirectoryItems } from "api/services/fileSystem";
import {
  CrossIcon,
  ExcelIcon,
  InternetIcon,
  PdfIcon,
  SearchIcon,
  SigmaIcon,
  WordIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";

const ModalTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  min-height: 300px;
  min-width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 12px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const WordDocSearchResultsModal = ({ searchResults }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Texts");

  return (
    <>
      <ModalTrigger onClick={() => setIsOpen(true)}>
        <IconButton>
          <SearchIcon />
        </IconButton>
      </ModalTrigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <NavWithTabsWithArrows
          tabNames={["Texts", "Tables"]}
          selectedTabName={selectedTab}
          onTabSelect={newTab => setSelectedTab(newTab)}
        />
        <ModalContent onCopy={e => e.stopPropagation()}>
          {selectedTab === "Texts" && "these are the texts"}
          {selectedTab === "Tables" && "these are the tables"}
        </ModalContent>
      </Modal>
    </>
  );
};

export default WordDocSearchResultsModal;
