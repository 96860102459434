import { cloneDeep, isEqual, last } from "lodash";
import { getColorFromString } from "utils/common";

const T_START = "\u0010";
const R_START = "\u0012";
const C_START = "\u001c";
const T_END = "\u0011";

const TABLE_CHARS = [T_START, R_START, C_START, T_END];

export const getContentFromBlocks = (
  blocks,
  cursor = { blockIndex: 0, letterIndex: 0 }
) => {
  let text = "";
  let textStyles = [];

  let paddedBlocks = blocks;
  if (paddedBlocks?.[0]?.text !== "") {
    paddedBlocks = [{ text: "", styles: [] }, ...blocks];
  }

  const blocksWithOriginalIndex = paddedBlocks?.map((block, i) => ({
    ...block,
    originalIndex: i,
  }));
  const filteredBlocks = blocksWithOriginalIndex
    ?.filter(block => !block?.isQuery)
    ?.filter(block => !block?.regenActions?.length);
  let caretIndex = 0;

  filteredBlocks?.forEach((block, blockIndex) => {
    let i = 0;

    if (block?.rowIndex === 0 && block?.columnIndex === 0) {
      text += T_START;
      textStyles.push(null);
    }
    if (block?.columnIndex === 0) {
      text += R_START;
      textStyles.push({
        numberOfColumns: block?.numberOfColumns,
      });
    }
    if (block?.isTableCell) {
      text += C_START;
      textStyles.push({
        isTableCell: true,
        rowIndex: block?.rowIndex,
        columnIndex: block?.columnIndex,
        tableId: block?.tableId,
        numberOfColumns: block?.numberOfColumns,
        numberOfRows: block?.numberOfRows,
      });
    }

    let prompt = blocks?.find(
      b => b?.isQuery && b?.queryId === block?.queryId
    )?.text;
    let queryId = block?.queryId;
    let charStyle = null;

    while (i < block?.text?.length) {
      text += block?.text?.[i];

      let blockCharStyle = block?.styles?.find(
        style => i >= style?.start && i < style?.end
      );
      if (queryId) {
        let idStr = `${queryId}${blockCharStyle?.start}${blockCharStyle?.end}`;
        let bgColor = "transparent";
        // if (blockCharStyle?.meta) {
        //   bgColor = `${getColorFromString(idStr)}17`;
        // }
        let fullLengthStyle =
          blockCharStyle?.end - blockCharStyle?.start === block?.text?.length;
        let isEndOfMeta =
          blockCharStyle?.metas?.[0] &&
          i === blockCharStyle?.end - 1 &&
          !fullLengthStyle;
        if (blockCharStyle?.metas?.[0]) {
          let { start, end } = blockCharStyle;
          blockCharStyle.meta = {
            value: block?.text?.slice(start, end),
          };
        }
        blockCharStyle = {
          ...(blockCharStyle || {}),
          isUnderlined: blockCharStyle?.meta
            ? false
            : blockCharStyle?.isUnderlined,
          queryId,
          blockIndex: block?.originalIndex,
          prompt,
          bgColor,
          isEndOfMeta,
          fullLengthStyle,
          // meta: isEndOfMeta ? blockCharStyle?.meta : null,
          // bgColor: `${getColorFromString(queryId)}17`,
        };
      }
      charStyle = blockCharStyle || null;

      textStyles.push(cloneDeep(charStyle));

      let hasCursorBlockBeenReached =
        block?.originalIndex >= cursor?.blockIndex;
      let hasCursorLetterBeenReached =
        hasCursorBlockBeenReached && i >= cursor?.letterIndex;
      if (!hasCursorBlockBeenReached) {
        caretIndex++;
      }
      if (hasCursorBlockBeenReached && !hasCursorLetterBeenReached) {
        caretIndex++;
      }
      i++;
    }
    text += "\n";
    let hasCursorBlockBeenReached = block?.originalIndex >= cursor?.blockIndex;
    if (!hasCursorBlockBeenReached) {
      caretIndex++;
    }
    textStyles.push(cloneDeep(charStyle));
    prompt = "";

    const nextBlock = filteredBlocks?.[blockIndex + 1];
    if (block?.isTableCell && !nextBlock?.isTableCell) {
      text += T_END;
      textStyles.push({
        numberOfColumns: block?.numberOfColumns,
      });
    }
  });

  text += "\n";
  // if (!text?.startsWith("\n")) {
  //   text = "\n" + text;
  //   textStyles = [null, ...textStyles];
  // }

  caretIndex = Math.min(caretIndex, text?.length - 1);
  const selectionStyles = new Array(text?.length).fill(null);
  selectionStyles[caretIndex] = { isCaret: true };

  console.log("getContentFromBlocks ", {
    blocks,
    text,
    textStyles,
    selectionStyles,
  });

  return {
    text,
    selectionStyles,
    textStyles,
  };
};

const INITIAL_FIELDS = {
  meta: null,
  isBold: false,
  isItalic: false,
  isUnderlined: false,
  bgColor: "transparent",
  textColor: "#000000",
  metas: [],
};

export const getBlocksFromContent = content => {
  let blocks = [];
  let block = { text: "", styles: [], startI: 0 };

  let currentStyleFields = INITIAL_FIELDS;
  let currentStyle = {
    start: 0,
  };

  let caretIndex = content?.selectionStyles?.findIndex(style => style?.isCaret);
  const selectionStart = content?.selectionStyles?.findIndex(
    style => style?.isSelection === true
  );
  if (caretIndex === -1 && selectionStart !== -1) {
    caretIndex = selectionStart;
  }
  let addedQueryIds = [];
  let i = 0;
  let blockIndex = 0;
  let letterIndex = 0;

  while (i < content?.text?.length) {
    const char = content?.text?.[i];
    const charStyle = content?.textStyles?.[i];

    if (charStyle?.queryId) {
      block.queryId = charStyle?.queryId;
    }
    if (
      charStyle?.prompt &&
      charStyle?.queryId &&
      !addedQueryIds.includes(charStyle?.queryId)
    ) {
      blocks?.push({
        text: charStyle?.prompt,
        isQuery: true,
        queryId: charStyle?.queryId,
      });
      addedQueryIds.push(charStyle?.queryId);
      if (i < caretIndex) {
        blockIndex++;
        letterIndex = 0;
      }
    }
    if (charStyle?.isTableCell) {
      block = { ...block, ...charStyle };
    }

    let { meta, isBold, isItalic, isUnderlined, bgColor, textColor, metas } =
      charStyle || {};
    let charFields = {
      meta,
      metas,
      isBold,
      isItalic,
      isUnderlined,
      bgColor,
      textColor,
    };

    if (!isEqual(currentStyleFields, charFields)) {
      // hotfix
      if (
        content?.text?.[i - 1] === "\n" &&
        (currentStyleFields?.metas || currentStyleFields?.meta)
      ) {
        currentStyle.end = i - last(blocks)?.startI - 1;
        if (last(blocks).styles) {
          last(blocks).styles.push(currentStyle);
          currentStyleFields = charFields;
          currentStyle = {
            start: i - last(blocks)?.startI - 1,
            ...charFields,
          };
        }
      } else {
        currentStyle.end = i - block?.startI - 1;
        block.styles.push(currentStyle);
        currentStyleFields = charFields;
        currentStyle = {
          start: i - block?.startI - 1,
          ...charFields,
        };
      }
    }

    if (TABLE_CHARS.includes(char)) {
      i++;
      continue;
    }

    if (char === "\n") {
      if (i < caretIndex) {
        blockIndex++;
        letterIndex = 0;
      }

      blocks.push(block);
      block = { text: "", styles: [], startI: i };
      i++;
      continue;
    }

    block.text += char;
    if (i < caretIndex) {
      letterIndex++;
    }
    i++;
  }

  console.log("getBlocksFromContent ", { content, blocks });
  return { blocks, cursor: { blockIndex, letterIndex } };
};
