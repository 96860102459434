import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  getUserProfileByUserName,
  updateUserProfile,
} from "api/services/projectService";
import FileInput from "components/inputs/FileInput";
import TextInputSquare from "components/inputs/TextInputSquare";
import Button from "components/ui/Button";
import LoadingSpinner from "components/ui/LoadingSpinner";
import { getBase64FromImageFile } from "utils/common";
import { getQuotaConfigsLimit } from "api/services/jobService";

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 300px;
`;

const HorizontalContent = styled.div`
  display: flex;
  gap: 5px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Container = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  min-width: 1000px;
`;

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 50px;
`;

const AvatarAndSocialContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const UserAvatar = styled.img`
  height: 250px;
  width: 250px;
  border-radius: 300px;
  border: 2px solid ${props => props.theme.color.primary};
  object-fit: cover;
`;

const AvatarFileInput = styled(FileInput)`
  position: absolute;
  top: 200px;
  left: 10px;
  padding: 5px 10px;
  padding-right: 12px;
  min-width: 0;
  min-height: 0;
  background-color: ${props => props.theme.color.furthest};
`;

const UserName = styled.div`
  width: max-content;
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  font-family: monospace;
  background-color: ${props => props.theme.color.closer1};
`;

const QuotaContainer = styled.div`
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const ProfileView = ({ user }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [userProfileMessage, setUserProfileMessage] = useState("");
  const [isUpdaing, setIsUpdating] = useState(false);
  const [userQuota, setUserQuota] = useState("-");

  useEffect(() => {
    if (user) {
      doFetchUserProfile();
    }
  }, [user]);

  useEffect(() => {
    doFetchUserQuota();
  }, []);

  const doFetchUserProfile = async () => {
    const { data, error } = await getUserProfileByUserName(user);

    if (!error) {
      setUserProfile(data);
    }
  };

  const doSaveProfile = async () => {
    setIsUpdating(true);
    const { error, data } = await updateUserProfile({}, userProfile);
    setIsUpdating(false);
    setUserProfile(data);
    if (error) {
      setUserProfileMessage("Failed when updating user's profile");
    } else {
      setUserProfileMessage("User's profile has been successfully updated.");
    }
  };

  const doFetchUserQuota = async () => {
    const { data } = await getQuotaConfigsLimit();
    setUserQuota(data?.coreLimit || "-");
  };

  const userAvatarSrc = userProfile?.image.includes("/images/")
    ? userProfile?.image
    : `data:image/png;base64,${userProfile?.image}`;

  if (!userProfile) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container isDisabled={isUpdaing}>
      <QuotaContainer>Maximum pages quota: {userQuota}</QuotaContainer>
      <TwoColumnLayout>
        <AvatarAndSocialContainer>
          <UserAvatar src={userAvatarSrc} />
          <AvatarFileInput
            buttonText="Edit"
            onFileSelect={async newFile => {
              const base64String = await getBase64FromImageFile(newFile);
              const base64StringData = base64String.split(",")[1];
              setIsUpdating(true);
              const { data, error } = await updateUserProfile(
                {},
                {
                  ...userProfile,
                  image: base64StringData,
                }
              );
              setIsUpdating(false);
              !error && setUserProfile(data);
            }}
            isShowingFileChosenText={false}
          />
          {/* <UserName>{user}</UserName> */}
          Image file not bigger than 1MB.
        </AvatarAndSocialContainer>
        <UserDetails>
          <HorizontalContent>
            <TextInputSquare
              placeholder="First name"
              title="First name"
              isRed={!userProfile?.firstName}
              value={userProfile?.firstName}
              onNewInput={firstName =>
                setUserProfile({ ...userProfile, firstName })
              }
            />
            <TextInputSquare
              placeholder="Last name"
              title="Last name"
              isRed={!userProfile?.lastName}
              value={userProfile?.lastName}
              onNewInput={lastName =>
                setUserProfile({ ...userProfile, lastName })
              }
            />
          </HorizontalContent>
          <TextInputSquare
            placeholder="Email"
            title="Email"
            isRed={!userProfile?.email}
            value={userProfile?.email}
            onNewInput={email => setUserProfile({ ...userProfile, email })}
          />
          <TextInputSquare
            placeholder="Company"
            title="Company"
            isRed={!userProfile?.organisation}
            value={userProfile?.organisation}
            onNewInput={organisation =>
              setUserProfile({ ...userProfile, organisation })
            }
          />
          <TextInputSquare
            placeholder="Position"
            title="Position"
            isRed={!userProfile?.position}
            value={userProfile?.position}
            onNewInput={position =>
              setUserProfile({ ...userProfile, position })
            }
          />
          <p>{userProfileMessage}</p>
          <Buttons>
            <Button isDisabled={false} value={"Save"} onClick={doSaveProfile} />
          </Buttons>
        </UserDetails>
      </TwoColumnLayout>
    </Container>
  );
};

export default ProfileView;
