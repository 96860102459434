import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  getLoggedInUserName,
  postUsersVerify,
} from "api/services/authenticationService";

const VerifyAccountPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const verificationCode = searchParams.get("verificationCode");

  useEffect(() => {
    doVerifyAndRedirect();
  }, [verificationCode]);

  const doVerifyAndRedirect = async () => {
    if (!verificationCode) {
      return;
    }

    const { error } = await postUsersVerify({ verificationCode });
    if (error) {
      navigate("/");
      return;
    }

    const userName = getLoggedInUserName();
    if (!userName) {
      window.location =
        "/login?message=Thank you for verifying. You can now log in.";
      return;
    }

    window.location = "/?showVerifiedModal=true";
  };

  return null;
};

export default VerifyAccountPage;
