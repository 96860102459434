import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import { format } from "date-fns";
import { useState } from "react";
import styled from "styled-components";

const safeFormat = (date, formatString) => {
  try {
    return format(new Date(date), formatString);
  } catch {
    return "";
  }
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "title filter"
    "selection-buttons selection-buttons"
    "options options";
  gap: 2.5px;
`;

const Option = styled.div`
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
  ${props => props.isSelected && `background-color: ${props.theme.color.primary}33;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const OptionsContainer = styled.div`
  grid-area: options;
  max-height: ${props => props.maxOptionsHeight};
  overflow: auto;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Title = styled.div`
  grid-area: title;
  font-weight: 900;
  align-self: end;
  max-width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: flex;
  gap: 10px;
  align-items: center;
`;

const FilterInput = styled.input`
  grid-area: filter;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
`;

const UploadDocumentPlusButton = styled(ButtonCreateNewAsset)`
  grid-area: upload-document-input;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  width: 20px;
  height: 20px;
`;

const SelectionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  grid-area: selection-buttons;
`;

const SelectionButton = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.closer2};
  text-decoration: underline;
  cursor: pointer;
`;

const OPTION_TO_PREFIX = {
  FD_Total_assets: "(1) ",
  FD_Shareholders_equity: "(1) ",
  "FD_Long-term_liabilities": "(1) ",
  "FD_Short-term_liabilities": "(1) ",

  MD_Berlin_office_rent: "(2) ",
  MD_Berlin_office_yield: "(2) ",
  MD_Berlin_retail_rent: "(2) ",
  MD_Berlin_retail_yield: "(2) ",
};

const MultiSelectWithFilter = ({
  className,
  options,
  title,
  selectedOptions = [],
  maxOptionsHeight = "300px",
  onSelectOptions = newSelectedOptions => {},
  shouldShowFakeNewButton = false,
  shouldShowSelectAllAndClearButtons = false,
  onClickNewButton,
}) => {
  const [filterText, setFilterText] = useState("");

  const filteredOptions = options?.filter(option =>
    `${OPTION_TO_PREFIX[option] || ""}${option}`?.toLowerCase()?.includes(filterText?.toLowerCase())
  );

  return (
    <Container className={className}>
      <Title>
        {title}
        {shouldShowFakeNewButton && <UploadDocumentPlusButton onClick={onClickNewButton} />}
      </Title>
      <FilterInput value={filterText} onChange={e => setFilterText(e.target.value)} />
      {shouldShowSelectAllAndClearButtons && (
        <SelectionButtonsContainer>
          <SelectionButton onClick={() => onSelectOptions(options)}>Select All</SelectionButton>
          <SelectionButton onClick={() => onSelectOptions([])}>Clear</SelectionButton>
        </SelectionButtonsContainer>
      )}
      <OptionsContainer maxOptionsHeight={maxOptionsHeight}>
        {filteredOptions?.map((option, i) => (
          <Option
            isSelected={selectedOptions?.includes(option)}
            onClick={() => {
              if (selectedOptions?.includes(option)) {
                onSelectOptions(selectedOptions?.filter(selectedOption => selectedOption !== option));
                return;
              }
              onSelectOptions([...selectedOptions, option]);
            }}
            key={`${option}-${i}`}
          >
            {title === "Time"
              ? safeFormat(option, "yyyy-MM-dd HH:mm:ss")
              : `${OPTION_TO_PREFIX[option] || ""}${option}`}
          </Option>
        ))}
      </OptionsContainer>
    </Container>
  );
};

export default MultiSelectWithFilter;
