import styled from "styled-components";
import { CenteredWithTopNavLayout } from "components/Layout";
import { getLoggedInUserName } from "api/services/authenticationService";
import ProfileView from "components/ProfileView";
import PasswordView from "components/PasswordView";
import AccountView from "components/AccountView";
import useSearchParamsState from "hooks/useSearchParamsState";
import TeamsView from "components/TeamsView";

const SideBarLayout = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
`;

const SideBarMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SideBarMenu = styled.div`
  width: 200px;
  padding: 8px;
  padding-left: 20px;
  background-color: ${props =>
    props.isSelected ? props.theme.color.closer0 : "transperant"};
  border-left: 4px solid
    ${props => (props.isSelected ? props.theme.color.primary : "transperant")};
  font-weight: ${props => (props.isSelected ? "bold" : "regular")};

  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.closer0};
  }
`;

const InformationContainer = styled.div``;

const viewOpitions = [
  "Account",
  "Password",
  "Teams",
  "Emails",
  "Billing & Plans",
  "Quota & Usage",
];

const ProfilePage = () => {
  const userName = getLoggedInUserName();
  const [selectedView, setSelectedView] = useSearchParamsState({
    paramName: "selectedView",
    initialValue: "Account",
  });

  if (!userName) {
    return (
      <CenteredWithTopNavLayout>
        Please first log in to use the application.
      </CenteredWithTopNavLayout>
    );
  }

  return (
    <CenteredWithTopNavLayout centerColumnMaxWidth="1200px">
      <SideBarLayout>
        <SideBarMenuContainer>
          {viewOpitions.map(option => (
            <SideBarMenu
              onClick={() => setSelectedView(option)}
              isSelected={option === selectedView}
            >
              {option}
            </SideBarMenu>
          ))}
        </SideBarMenuContainer>
        <InformationContainer>
          {selectedView === "Account" && <ProfileView user={userName} />}
          {selectedView === "Password" && <PasswordView user={userName} />}
          {selectedView === "Teams" && <TeamsView userName={userName} />}
          {selectedView === "Billing & Plans" && (
            <AccountView userName={userName} />
          )}
          {selectedView === "Quota & Usage" && <div>usage</div>}
        </InformationContainer>
      </SideBarLayout>
    </CenteredWithTopNavLayout>
  );
};

export default ProfilePage;
