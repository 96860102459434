import { useState, useEffect } from "react";
import styled from "styled-components";

import SelectInput from "components/ui/SelectInput";
import TextArea from "components/ui/TextArea";
import { parseJson } from "utils/common";
import KeywordsArrayEditor from "components/KeywordsArrayEditor";
import TextInputSquare from "components/inputs/TextInputSquare";

const SigmaConfigDetailsContainer = styled.div`
  grid-area: sigma-config-details;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  /* opacity: ${props => (props.isHidden ? 0 : 1)}; */

  display: grid;
  gap: 5px;
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-areas:
    "rule-details rule-details"
    "prompts-text prompts-text"
    "prompts prompts"
    "user-examples-text user-examples-text"
    "user-examples user-examples";
  align-content: start;
  align-items: center;
`;

const RuleDetails = styled.div`
  grid-area: rule-details;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  row-gap: 5px;
  align-items: center;
  grid-template-areas:
    "column-text column-text"
    "rule-type-text rule-type"
    "value-type-text value-type";
`;

const RuleTypeText = styled.div`
  grid-area: rule-type-text;
`;

const ValueTypeText = styled.div`
  grid-area: value-type-text;
`;

const ValueTypeSelectInput = styled(SelectInput)`
  grid-area: value-type;
  width: max-content;
`;

const RuleTypeSelectInput = styled(SelectInput)`
  grid-area: rule-type;
  width: max-content;
`;

const ColumnText = styled.div`
  grid-area: column-text;
  margin-bottom: 10px;
  font-size: 18px;
`;

const Column = styled.span`
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

const PromptsTextArea = styled(TextArea)`
  grid-area: prompts;
`;

const UserExamplesText = styled.div`
  grid-area: user-examples-text;
  align-self: end;
  margin-top: 10px;
`;

const UserExamplesTextArea = styled(TextArea)`
  grid-area: user-examples;
  align-self: end;
`;

const PromptsText = styled.div`
  grid-area: prompts-text;
  margin-top: 10px;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  display: inline-block;
`;

const SigmaConfigEditor = ({ sigmaConfig, isHidden, onNewSigmaConfig }) => {
  const [userExamplesStr, setUserExamplesStr] = useState("");
  const [originalSigmaConfig, setOriginalSigmaConfig] = useState({});

  useEffect(() => {
    setOriginalSigmaConfig(sigmaConfig);
  }, [sigmaConfig?.name]);

  useEffect(() => {
    const parsedDescription = parseJson(sigmaConfig?.description);

    if (parsedDescription?.keywords) {
      onNewSigmaConfig({ ...sigmaConfig, ruleType: "MANUAL" });
    }

    if (parsedDescription?.prompts) {
      onNewSigmaConfig({ ...sigmaConfig, ruleType: "GPT" });
    }

    setUserExamplesStr("");
  }, [sigmaConfig?.description]);

  useEffect(() => {
    const parsedDescription = parseJson(sigmaConfig?.description);

    if (sigmaConfig?.ruleType === "MANUAL") {
      onNewSigmaConfig({ ...sigmaConfig, description: JSON.stringify({ keywords: [sigmaConfig?.name] }) });
    }

    if (sigmaConfig?.ruleType === "GPT" && !parsedDescription?.prompts) {
      onNewSigmaConfig({
        ...sigmaConfig,
        description: originalSigmaConfig?.description,
      });
    }
  }, [sigmaConfig?.ruleType]);

  if (!sigmaConfig) {
    return null;
  }

  const promptsArr = parseJson(sigmaConfig?.description)?.prompts;
  const keywordsArr = parseJson(sigmaConfig?.description)?.keywords || [sigmaConfig?.name];

  return (
    <SigmaConfigDetailsContainer isHidden={isHidden}>
      <RuleDetails>
        <ColumnText>
          Data extraction rules for{" "}
          <Column>
            <StyledTextInputSquare
              value={sigmaConfig?.name}
              onNewInput={name => onNewSigmaConfig({ ...sigmaConfig, name })}
            />
          </Column>
        </ColumnText>

        <RuleTypeText>Rule type:</RuleTypeText>
        <RuleTypeSelectInput
          value={sigmaConfig?.ruleType}
          onSetNewValue={newRuleType => onNewSigmaConfig({ ...sigmaConfig, ruleType: newRuleType })}
        >
          <option>GPT</option>
          <option value="MANUAL">KEYWORD</option>
        </RuleTypeSelectInput>
        <ValueTypeText>Data type:</ValueTypeText>
        <ValueTypeSelectInput
          value={sigmaConfig?.valueType || parseJson(sigmaConfig?.description)?.value_type || "TEXT"}
          onSetNewValue={newValueType => onNewSigmaConfig({ ...sigmaConfig, valueType: newValueType })}
        >
          <option>TEXT</option>
          <option value="NUMERICAL">NUMBER</option>
          <option>DATE</option>
          <option>CURRENCY</option>
        </ValueTypeSelectInput>
      </RuleDetails>

      {(!sigmaConfig?.ruleType || sigmaConfig?.ruleType === "GPT") && (
        <>
          <PromptsText>Describe your rule:</PromptsText>
          <PromptsTextArea
            value={promptsArr?.join("\n")}
            onNewInput={newPromptsStr => {
              const newPromptsArr = newPromptsStr?.split("\n");
              const newSigmaConfigDescription = JSON.stringify({
                ...parseJson(sigmaConfig?.description),
                prompts: newPromptsArr,
              });
              onNewSigmaConfig({ ...sigmaConfig, description: newSigmaConfigDescription });
            }}
          />
        </>
      )}

      {sigmaConfig?.ruleType === "MANUAL" && (
        <>
          <PromptsText>Edit keywords:</PromptsText>
          <KeywordsArrayEditor
            keywords={keywordsArr}
            onNewKeywords={newKeywords => {
              const newSigmaConfigDescription = JSON.stringify({
                ...parseJson(sigmaConfig?.description),
                keywords: newKeywords,
              });
              onNewSigmaConfig({ ...sigmaConfig, description: newSigmaConfigDescription });
            }}
          />
        </>
      )}

      <UserExamplesText>Provide example extracted values (optional):</UserExamplesText>
      <UserExamplesTextArea
        value={userExamplesStr}
        onNewInput={newUserExamplesStr => setUserExamplesStr(newUserExamplesStr)}
      />
    </SigmaConfigDetailsContainer>
  );
};

export default SigmaConfigEditor;
