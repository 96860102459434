import { CreateNewFolder } from "@material-ui/icons";
import styled from "styled-components";
import { useState } from "react";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SmallButton from "components/ui/SmallButton";
import { postDirectories } from "api/services/fileSystem";
import { useEffect } from "react";
import ErrorMessageModal from "components/ErrorMessageModal";

const StyledButton = styled(Button)`
  padding: 8px;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  border-radius: 5px;
  display: grid;
  gap: 10px;
  justify-items: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const NewFolderText = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 4px 20px;
`;

const CreateDirectoryModalTrigger = ({
  onDirectryCreated,
  directoryPath = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newDirectoryName, setNewDirectoryName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNewDirectoryName("");
  }, [isOpen]);

  const doPostNewDirectory = async () => {
    setIsCreating(true);
    let path = `${directoryPath}${newDirectoryName}`;
    if (!directoryPath.endsWith("/")) {
      path = `${directoryPath}/${newDirectoryName}`;
    }
    const { error } = await postDirectories({
      path: path?.trim(),
    });
    if (error) {
      setIsOpen(false);
      setError(error);
      setIsCreating(false);
      return;
    }

    onDirectryCreated();
    setIsOpen(false);
    setIsCreating(false);
  };

  return (
    <>
      <StyledButton
        onClick={() => setIsOpen(true)}
        icon={<CreateNewFolder />}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isCreating}>
          <NewFolderText>New Folder</NewFolderText>
          <TextInputSquare
            value={newDirectoryName}
            onNewInput={newName => setNewDirectoryName(newName)}
          />
          <StyledSmallButton
            isDisabled={!newDirectoryName}
            onClick={doPostNewDirectory}
            value="Save"
          />
        </ModalContent>
      </Modal>
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </>
  );
};

export default CreateDirectoryModalTrigger;
