import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SwitchContainer = styled.div`
  width: 48px;
  height: 25px;
  border-radius: 25px;
  background-color: ${props => props.theme.color.closer1_5};
  display: flex;
  align-items: center;
  padding: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
`;

const InnerIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 10px;
  padding: 2px;
  transform: translateX(${props => (props.isOnLeft ? 0 : "22px")});
  transition: transform 0.2s;
`;

const Name = styled.div`
  color: ${props => (props.isHighlighted ? props.theme.color.primary : "inherit")};
`;

const ThemeSwitch = ({ leftName = "", rightName = "", isOnLeft, onSwitch, className }) => (
  <Container>
    <Name isHighlighted={isOnLeft}>{leftName}</Name>
    <SwitchContainer onClick={onSwitch} className={className}>
      <InnerIcon isOnLeft={isOnLeft} />
    </SwitchContainer>
    <Name isHighlighted={!isOnLeft}>{rightName}</Name>
  </Container>
);

export default ThemeSwitch;
