import styled from "styled-components";

import Modal from "components/ui/Modal";
import { useState } from "react";
import { Gap } from "components/Layout";
import { CrossIcon, EmailOutlined, TickIcon } from "components/ui/Icons";
import ButtonDark from "components/ui/ButtonDark";
import { postUpgradeTrialUser } from "api/services/authenticationService";
import { updateUserProfile } from "api/services/projectService";
import { uuidv4 } from "utils/common";
import { useEffect } from "react";
import { postContactUsDetails } from "api/services/marketingService";

const ModalContent = styled.div`
  padding: 32px;
  display: grid;
  ${props => props.isDisabled && "opacity: 0.6; pointer-events: none;"};
  box-shadow: ${props => props.theme.shadow};
  background-color: #cddefd;
  border-radius: 10px;
`;

const SignupContainer = styled.div`
  width: 600px;
`;

const StyledTextInput = styled.input`
  font-size: 14px;
  width: 100%;
  padding: 12px;
  margin: 0;
  font-weight: 400;

  background-color: white;
  color: ${props => props.theme.color.closest};
  border: 2px solid transparent;
  font-family: "Montserrat";
  border-radius: 10px;
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const StyledTextArea = styled.textarea`
  font-size: 14px;
  width: 100%;
  padding: 12px;
  margin: 0;
  height: 120px;
  font-weight: 400;
  resize: none;

  background-color: white;
  color: ${props => props.theme.color.closest};
  border: 2px solid transparent;
  border-radius: 10px;

  font-family: "Montserrat";
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const BoldSpan = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

const BoldEmailSpan = styled.span`
  font-weight: 700;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
`;

const TermsContainer = styled.label`
  cursor: pointer;
  font-size: 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 4px;
`;

const StyledA = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: black;
`;

const ErrorMsg = styled.div`
  height: 40px;
  padding-top: 8px;
  color: ${props => props.theme.color.error};
`;

const PLANS = [
  {
    type: "free",
    buttonText: "Continue using",
    title: "Free",
    subTitle: "Limited Capabilities",
    features: [
      {
        isAvailable: true,
        text: "X tokens per week",
      },
      {
        isAvailable: true,
        text: "X API calls per minute",
      },
      {
        isAvailable: true,
        text: "10 pages of documents",
      },
      {
        isAvailable: true,
        text: "X Users",
      },
      {
        isAvailable: true,
        text: "X Support",
      },
    ],
  },
  {
    type: "enterprise",
    buttonText: "Talk to sales",
    title: "Enterprise",
    subTitle: "Full Suite",
    features: [
      {
        isAvailable: true,
        text: "X tokens per week",
      },
      {
        isAvailable: true,
        text: "X API calls per minute",
      },
      {
        isAvailable: true,
        text: "Unlimited documents",
      },
      {
        isAvailable: true,
        text: "Unlimited contacts",
      },
      {
        isAvailable: true,
        text: "X Support",
      },
    ],
  },
];

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 40px;
`;

const Plan = styled.div`
  padding: 0px 80px;
  margin: 30px 0;
  display: grid;
  gap: 14px;
  text-align: center;
`;

const PlanTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #0a1e54;
`;

const PlanSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #0a1e54;
`;

const PlanFeatures = styled.div`
  display: grid;
`;

const PlanFeature = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 18px;
  line-height: 20px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

const ChooseTitle = styled.div`
  grid-column: span 2;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #000000;
  opacity: 0.68;
`;

const PlanStyledButtonDark = styled(ButtonDark)`
  background-color: #0957d0;
  color: white;
  font-size: 16px;
  padding: 12px 42px;
`;

const EmailPasswordModal = ({
  triggerComponent = (
    <ButtonDark>
      <EmailOutlined />
      Email document
    </ButtonDark>
  ),
  style = {},
  onClickDone = textFile => {},
  showEmailInstructions = false,
  userEmail = "",
  dataTutorialId = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [message, setMessage] = useState("");

  const [name, setName] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [position, setPosition] = useState("");

  useEffect(() => {
    if (isOpen) {
      setSelectedPlan(null);
      setName("");
      setEmail("");
      setPassword("");
      setOrganisation("");
      setPosition("");
      setHasAcceptedTerms(false);
      setErrorMsg("");
      setMessage("");
    }
  }, [isOpen]);

  const doSignUp = async () => {
    setIsDisabled(true);
    setErrorMsg("");

    const { error } = await postUpgradeTrialUser(
      {},
      {
        userName: uuidv4(),
        email,
        name,
      }
    );
    if (error?.message?.includes("already registered")) {
      setErrorMsg("Email already registered.");
      setIsDisabled(false);
      return;
    }

    if (error) {
      setErrorMsg("Please try another email.");
      setIsDisabled(false);
      return;
    }

    await updateUserProfile(
      {},
      {
        email,
        name,
        organisation,
        position,
      }
    );

    await onClickDone();

    setIsDisabled(false);
    setIsOpen(false);
  };

  const doSendDetails = async () => {
    setIsDisabled(true);
    setErrorMsg("");

    const { error } = await postContactUsDetails(
      {},
      {
        firstName: name?.split(" ")?.[0] || "",
        lastName: name?.split(" ")?.[1] || "",
        email,
        company: organisation,
        role: "",
        industry: "",
        phone: "",
        country: "",
        product: "launch",
      }
    );
    if (error) {
      setErrorMsg("Please try again.");
      setIsDisabled(false);
      return;
    }

    await onClickDone();

    setIsDisabled(false);
    setIsOpen(false);
  };

  const plansContent = (
    <PlansContainer>
      <ChooseTitle>Choose a plan that suits your business</ChooseTitle>
      {PLANS.map((plan, index) => (
        <Plan
          key={index}
          style={{
            borderRight: index === 0 ? "0.5px solid black" : "none",
            borderLeft: index === 1 ? "0.5px solid black" : "none",
          }}
        >
          <PlanTitle>{plan.title}</PlanTitle>
          <PlanSubTitle>{plan.subTitle}</PlanSubTitle>
          <PlanFeatures style={{ marginTop: "10px" }}>
            {plan.features.map(feature => (
              <PlanFeature>
                {feature.isAvailable ? <div>✓</div> : <CrossIcon />}
                {feature.text}
              </PlanFeature>
            ))}
          </PlanFeatures>
          <PlanStyledButtonDark
            style={{ justifySelf: "center", marginTop: "40px" }}
            onClick={() => setSelectedPlan(plan.type)}
          >
            {plan.buttonText}
          </PlanStyledButtonDark>
        </Plan>
      ))}
    </PlansContainer>
  );

  const signUpModalContent = (
    <SignupContainer>
      <Title
        style={{
          justifySelf: "center",
        }}
      >
        Sign up
      </Title>

      <BoldSpan>Your Name</BoldSpan>
      <StyledTextInput
        placeholder="Ada Lovelace"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Gap height="10px" />
      <BoldSpan>Your Email *</BoldSpan>
      <StyledTextInput
        placeholder="you@mail.com"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Gap height="10px" />
      {/* <BoldSpan>Password *</BoldSpan>
      <StyledTextInput
        type="password"
        placeholder="*****"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Gap height="10px" /> */}
      <BoldSpan>Company</BoldSpan>
      <StyledTextInput
        value={organisation}
        onChange={e => setOrganisation(e.target.value)}
        placeholder="Company Ltd"
      />
      <Gap height="10px" />
      {/* <BoldSpan>Job Role</BoldSpan>
      <StyledTextInput
        value={position}
        onChange={e => setPosition(e.target.value)}
        placeholder="CEO"
      />
      <Gap height="10px" /> */}
      <TermsContainer>
        <input
          type="checkbox"
          checked={hasAcceptedTerms}
          onChange={e => setHasAcceptedTerms(e.target.checked)}
        />
        <div>
          I accept the{" "}
          <StyledA href="https://boltzbit.com/terms" target="_blank">
            Terms & Conditions
          </StyledA>{" "}
          and{" "}
          <StyledA href="https://boltzbit.com/privacy-policy/" target="_blank">
            Privacy Policy
          </StyledA>
          &nbsp;*
        </div>
      </TermsContainer>
      {/* <Gap height="40px" /> */}
      <ErrorMsg>{errorMsg}</ErrorMsg>
      <PlanStyledButtonDark
        isDisabled={!hasAcceptedTerms}
        style={{
          justifySelf: "center",
          margin: "0 auto",
        }}
        onClick={doSignUp}
      >
        Sign up
      </PlanStyledButtonDark>
    </SignupContainer>
  );

  const talkToSalesModalContent = (
    <SignupContainer>
      <Title
        style={{
          justifySelf: "center",
        }}
      >
        Talk to sales
      </Title>

      <BoldSpan>Your Name</BoldSpan>
      <StyledTextInput
        placeholder="Ada Lovelace"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Gap height="10px" />
      <BoldSpan>Your Email *</BoldSpan>
      <StyledTextInput
        placeholder="you@mail.com"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Gap height="10px" />
      <BoldSpan>Company</BoldSpan>
      <StyledTextInput
        value={organisation}
        onChange={e => setOrganisation(e.target.value)}
        placeholder="Company Ltd"
      />
      <Gap height="10px" />
      <BoldSpan>Message</BoldSpan>
      <StyledTextArea
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder="Your use case"
      />
      <Gap height="10px" />
      <TermsContainer>
        <input
          type="checkbox"
          checked={hasAcceptedTerms}
          onChange={e => setHasAcceptedTerms(e.target.checked)}
        />
        <div>
          I accept the{" "}
          <StyledA href="https://boltzbit.com/terms" target="_blank">
            Terms & Conditions
          </StyledA>{" "}
          and{" "}
          <StyledA href="https://boltzbit.com/privacy-policy/" target="_blank">
            Privacy Policy
          </StyledA>
          &nbsp;*
        </div>
      </TermsContainer>
      {/* <Gap height="40px" /> */}
      <ErrorMsg>{errorMsg}</ErrorMsg>
      <PlanStyledButtonDark
        isDisabled={!hasAcceptedTerms}
        style={{
          justifySelf: "center",
          margin: "0 auto",
        }}
        onClick={doSendDetails}
      >
        Send
      </PlanStyledButtonDark>
    </SignupContainer>
  );

  let modalContent = signUpModalContent;
  // if (selectedPlan === "free") {
  //   modalContent = signUpModalContent;
  // }
  // if (selectedPlan === "enterprise") {
  //   modalContent = talkToSalesModalContent;
  // }
  if (showEmailInstructions) {
    modalContent = (
      <div
        style={{
          display: "grid",
          gap: "20px",
          fontSize: "20px",
          textAlign: "center",
          maxWidth: "600px",
          lineHeight: "1.4",
          padding: "90px 60px",
        }}
      >
        {/* <div>
          An email has been sent to <BoldEmailSpan>{userEmail}</BoldEmailSpan>,
          please verify your account.
        </div> */}
        <div>
          To send in documents or text into your account, you can email{" "}
          <BoldEmailSpan>data@boltzbit.io</BoldEmailSpan>.
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        data-tutorial-id={dataTutorialId}
        style={style}
        onClick={() => setIsOpen(true)}
      >
        {triggerComponent}
      </div>

      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isDisabled}>{modalContent}</ModalContent>
      </Modal>
    </>
  );
};

export default EmailPasswordModal;
