import { uniq } from "lodash";
import { useEffect, useState } from "react";

import { getSlideDocsLoadImage } from "api/services/slideDocsService";

const useCacheImages = ({ imagePaths = [] }) => {
  const [imageCache, setImageCache] = useState({});

  const doPopulateImage = async path => {
    if (!path) {
      return;
    }
    const { data } = await getSlideDocsLoadImage({ imagePath: path });
    setImageCache(prev => ({ ...prev, [path]: data }));
  };

  useEffect(() => {
    uniq(imagePaths).forEach(path => doPopulateImage(path));
  }, [JSON.stringify(imagePaths)]);

  return imageCache;
};

export default useCacheImages;
