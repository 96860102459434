import styled from "styled-components";

const Tip = styled.div`
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  width: max-content;
  ${props => {
    if (props.placement === "right") {
      return `
        left: auto;
        right: 0;
        top: 0;
        transform: translateX(100%) translateY(10%);
      `;
    }
    if (props.placement === "left") {
      return `
        left: 0;
        top: 0;
        transform: translateX(-100%) translateY(10%);
      `;
    }
    if (props.placement === "top") {
      return `
        top: 0;
        transform: translateX(-50%) translateY(-100%);
      `;
    }
  }}
  background-color: black;
  z-index: 12;
  box-shadow: 0 8px 24px
    ${props =>
      props.theme.name === "light"
        ? "rgba(140, 149, 159, 0.4)"
        : "rgba(0, 0, 0, 0.4)"};
  opacity: 0;
  color: white;
  pointer-events: none;
  white-space: pre;
  line-height: 1.5;
`;

const TipContainer = styled.span`
  position: relative;
  width: fit-content;
  :hover {
    z-index: 10;
    ${Tip} {
      opacity: 1;
    }
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Tooltip = ({
  isDisabled,
  className,
  children,
  title,
  style = {},
  placement = "bottom",
  dataTutorialId = "",
}) => {
  return (
    <TipContainer
      data-tutorial-id={dataTutorialId}
      style={style}
      isDisabled={isDisabled}
    >
      {children}
      {title && (
        <Tip className={className} placement={placement}>
          {title}
        </Tip>
      )}
    </TipContainer>
  );
};

export default Tooltip;
