import styled from "styled-components";
import { useEffect, useState } from "react";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { deleteSigmaTableConfig, getSigmaTableConfigs } from "api/services/sigmaService";
import { BigTitle } from "components/ui/Text";
import { Link, useNavigate } from "react-router-dom";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import TableActionsDropdown from "components/TableActionsDropdown";
import { getSigmaResultMetaValues } from "api/services/sigmaResultMetaService";

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 350px 180px 1fr auto auto;
  align-items: center;

  gap: 20px;
  padding: 12px 20px;
  color: white;
  background: linear-gradient(to right, ${props => props.theme.color.feature}, ${props => props.theme.color.primary});

  font-weight: 600;
`;

const PackageListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 668px;
  padding-bottom: 50px;
`;

const PackageRow = styled.div`
  display: grid;
  grid-template-columns: 350px 180px 1fr auto;
  align-items: start;

  gap: 20px;
  padding: 12px 20px;
  background-color: ${props =>
    props.rowIndex % 2 !== 0 ? props.theme.color.closer0 : `${props.theme.color.closer1}33`};

  :hover {
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.closer1}BB;
  }

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

const StyledButtonCreateNewAsset = styled(ButtonCreateNewAsset)``;

const FileNames = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: hidden;
`;

const SeeMoreText = styled.span`
  color: ${props => props.theme.color.primary};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;

const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileNamesList = ({ sigmaTableId, columnName }) => {
  const [fileNames, setFileNames] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    doPopulateFileNames();
  }, []);

  const doPopulateFileNames = async () => {
    const { data } = await getSigmaResultMetaValues({
      sigmaTableId,
      columnName,
    });
    setFileNames(data || []);
  };

  const fileNamesToDisplay = isExpanded ? fileNames : fileNames.slice(0, 3);

  return (
    <div>
      <FileNames isExpanded={isExpanded || fileNames.length <= 3}>
        {fileNamesToDisplay.map((fileName, i) => (
          <span key={i}>{fileName}</span>
        ))}
      </FileNames>
      {fileNames?.length > 3 ? (
        <SeeMoreText onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "See less" : "See more"}</SeeMoreText>
      ) : null}
    </div>
  );
};

const TableConfigsPage = () => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableIdBeingDeleted, setTableIdBeingDeleted] = useState(null);
  const [deleteErr, setDeleteError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    doPopulateTableConfigs();
  }, []);

  const doPopulateTableConfigs = async () => {
    const { data } = await getSigmaTableConfigs();
    setTableConfigs(data);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <TitleAndButton>
        <BigTitle>Sigma Database</BigTitle>
        <StyledButtonCreateNewAsset onClick={() => navigate("/table-configs/new")} />
      </TitleAndButton>
      <Gap />
      <PackageListContainer>
        <HeaderRow>
          <div>Table name</div>
          <div>Created at</div>
          <div>Files</div>
          <div>Actions</div>
        </HeaderRow>
        {tableConfigs?.map(tableConfig => (
          <PackageRow isDisabled={tableIdBeingDeleted === tableConfig?.id}>
            <Link to={`/table-configs/${tableConfig.id}/results`}>{tableConfig.name}</Link>
            <div>{tableConfig.createdAt}</div>
            <FileNames>
              <FileNamesList sigmaTableId={tableConfig?.id} columnName={tableConfig?.primarySigmaConfigNames?.[0]} />
            </FileNames>
            <div>
              <TableActionsDropdown
                onClickEdit={() => navigate(`/table-configs/${tableConfig.id}/edit`)}
                onClickDelete={async () => {
                  setTableIdBeingDeleted(tableConfig.id);
                  const { error } = await deleteSigmaTableConfig(tableConfig.id);
                  setDeleteError(error);
                  if (!error) {
                    setTableConfigs(tableConfigs.filter(t => t.id !== tableConfig.id));
                  }
                  setTableIdBeingDeleted(null);
                }}
              />
            </div>
          </PackageRow>
        ))}
        {deleteErr && <div>{JSON.stringify(deleteErr)}</div>}
      </PackageListContainer>
    </CenteredWithTopNavLayout>
  );
};

export default TableConfigsPage;
