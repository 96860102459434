import styled from "styled-components";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { merge } from "lodash";

import { CenteredWithTopNavLayoutNoSideBar } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import {
  getExcelModelByIdAndSheetName,
  getExcelModelByIdAndSheetNameCachedData,
  getExcelModelSheetGrid,
  getExcelModelSheetNames,
  postExcelModelOpen,
} from "api/services/excelModelsService";
import useSearchParamsState from "hooks/useSearchParamsState";
import usePollExcelModelLoadingPercentage from "api/services/usePollExcelModelLoadingPercentage";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import ExcelViewSheetWeb from "components/widgets/ExcelViewSheetWeb";
import { ExcelIcon } from "components/ui/Icons";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  height: calc(100vh - 81px);
  align-items: start;
`;

const ExcelModelName = styled(BigTitle)`
  padding-bottom: 8px;
`;

const SheetNamesContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 12;
  border-top: 1px solid ${props => props.theme.color.closer1};

  width: 100vw;
  overflow: auto;
`;

const SheetName = styled.div`
  padding: 15px;
  background-color: ${props =>
    props.isSelected ? props.theme.color.furthest : "transparent"};
  font-weight: 600;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  border-right: 0.5px solid ${props => props.theme.color.closer1};
  border-left: 0.5px solid ${props => props.theme.color.closer1};
  cursor: pointer;
  :hover {
    background-color: ${props =>
      !props.isSelected && props.theme.color.closer1};
  }
  white-space: nowrap;
`;

const ProgressBar = styled.div`
  width: 50%;
  height: 2px;
  background-color: ${props => props.theme.color.primary};
`;

const ModelNameAndWebLink = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

const ExcelViewPage = () => {
  const { excelModelId } = useParams();
  const [searchParams] = useSearchParams();

  const [excelModel, setExcelModel] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [isPatching] = useState(false);

  const [viewWindow, setViewWindow] = useState({
    startRow: 0,
    startCol: 0,
    endRow: 30,
    endCol: 30,
  });

  const [msSinceLastScroll, setMsSinceLastScroll] = useState(0);
  const [scrollIntervalId, setScrollIntervalId] = useState(null);

  const [selectedSheetName, setSelectedSheetName] = useSearchParamsState({
    paramName: "selectedSheetName",
    initialValue: "",
  });

  const loadingPercentage = usePollExcelModelLoadingPercentage(excelModelId);

  const [grid, setGrid] = useState(undefined);

  useEffect(() => {
    doOpenExcelModel();
  }, [excelModelId]);

  useEffect(() => {
    if (loadingPercentage !== 100 || !excelModelId) {
      return;
    }
    doPopulateSheetNamesAndSelectFirstSheet();
  }, [excelModelId, loadingPercentage]);

  useEffect(() => {
    if (!selectedSheetName) {
      return;
    }
    doPopulateCachedSheetData(selectedSheetName);
    doPopulateGridForSheet();
  }, [selectedSheetName]);

  useEffect(() => {
    if (msSinceLastScroll > 500 && loadingPercentage === 100 && !isPatching) {
      doPopulateSelectedSheetInWindow(selectedSheetName, viewWindow);
      setMsSinceLastScroll(0);
      clearInterval(scrollIntervalId);
    }
  }, [msSinceLastScroll, loadingPercentage, isPatching]);

  useEffect(() => {
    if (!selectedSheetName) {
      return;
    }

    const intervalId = setInterval(() => {
      setMsSinceLastScroll(prev => prev + 100);
    }, 100);
    setScrollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [viewWindow?.startRow, viewWindow?.startCol]);

  const doOpenExcelModel = async () => {
    await postExcelModelOpen(excelModelId);
  };

  const doPopulateGridForSheet = async () => {
    const { data: grid } = await getExcelModelSheetGrid(
      excelModelId,
      selectedSheetName
    );
    setGrid(grid);
  };

  const doPopulateSheetNamesAndSelectFirstSheet = async () => {
    const { data: sheetNames, error: errGettingSheets } =
      await getExcelModelSheetNames(excelModelId);
    if (errGettingSheets) {
      return;
    }
    setSheetNames(sheetNames);
    if (!selectedSheetName) {
      setSelectedSheetName(sheetNames?.[0]);
    }
  };

  const doPopulateSelectedSheetInWindow = async (sheetName, sheetWindow) => {
    const { data: newExcelModelWithSheet, error: errGettingSheetData } =
      await getExcelModelByIdAndSheetName(excelModelId, sheetName, sheetWindow);
    if (errGettingSheetData) {
      return;
    }
    setExcelModel(prevExcelModel => {
      if (
        newExcelModelWithSheet?.sheets?.[0]?.sheetName ===
        prevExcelModel?.sheets?.[0]?.sheetName
      ) {
        return merge({ ...prevExcelModel }, { ...newExcelModelWithSheet });
      }

      return newExcelModelWithSheet;
    });
  };

  const doPopulateCachedSheetData = async sheetName => {
    const { data: newExcelModelWithSheet, error: errGettingSheetData } =
      await getExcelModelByIdAndSheetNameCachedData(excelModelId, sheetName);
    if (errGettingSheetData) {
      return;
    }
    setExcelModel(newExcelModelWithSheet);
  };

  const cells = excelModel?.sheets?.find(
    sheet => sheet.sheetName === selectedSheetName
  )?.cells;

  return (
    <CenteredWithTopNavLayoutNoSideBar
      centerColumnMaxWidth="100%"
      contentTopPadding={10}
    >
      <Container>
        <ModelNameAndWebLink>
          <ExcelModelName>
            {excelModel?.originalName?.split(".xlsx")?.[0] || "Loading..."}
          </ExcelModelName>
          <Link
            style={{ marginTop: "-6px", marginLeft: "5px" }}
            to={`/excel-views/${excelModel?.id}?${searchParams?.toString()}`}
          >
            <ExcelIcon />
          </Link>
        </ModelNameAndWebLink>
        <ProgressBar
          style={{
            width: `${loadingPercentage}%`,
            opacity: loadingPercentage === 100 ? 0 : 1,
          }}
        />
        <SheetNamesContainer>
          <NavWithTabsWithArrows
            onTabSelect={newSheetName => setSelectedSheetName(newSheetName)}
            selectedTabName={selectedSheetName}
            tabNames={sheetNames}
          />
        </SheetNamesContainer>
        <ExcelViewSheetWeb
          viewWindow={viewWindow}
          onScrollViewWindow={newWindow => setViewWindow(newWindow)}
          sheetName={selectedSheetName}
          cells={cells}
          grid={grid}
        />
      </Container>
    </CenteredWithTopNavLayoutNoSideBar>
  );
};

export default ExcelViewPage;
