import { LinkWithStarIcon } from "components/IconsNew";
import ReferenceDebugModal from "components/ReferenceDebugModal";
import ReferenceModalSpanWithContext from "components/ReferenceModalSpanWithContext";
import { PLOT_TYPES, getPlotComponent } from "components/plots";
import { CrossIcon, PencilIcon, PlusIcon, TickIcon } from "components/ui/Icons";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const COLORS = [
  "#0191ff",
  "#9650ff",
  "#009933",
  "#d9534f",
  "#a4b9a8",
  "#ffcc00",
  "#ff6600",
  "#BB0000",
  "#cc0066",
  "#660066",
  "#4d4da5",
  "#ff00ff",
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
  align-items: center;
  padding-right: 0px;
  min-width: 200px;
  width: 100%;

  svg {
    height: 12px;
    fill: #8d8d8d;
    cursor: pointer;
    :hover {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-content: start;
  align-items: start;
  gap: 10px;
  padding-right: 0px;
  min-width: 200px;
  width: 100%;
  max-height: 220px;
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;
  width: 100%;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const TipContainer = styled.div`
  background-color: black;
  color: white;
  padding: 4px;
`;

const DisplayValue = styled.div`
  background-color: transparent;
  color: black;
  padding: 2px 0;
  border-bottom: 1px solid transparent;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const Td = styled.td`
  border: 1px solid #ccc;
  padding: 4px;
  text-align: left;
`;

const Th = styled.th`
  font-weight: 500;
  padding: 4px;
  border: 1px solid #ccc;
  text-align: left;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  height: 12px;
  fill: #8d8d8d;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 12px;
  fill: #8d8d8d;
  cursor: pointer;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 12px;
  fill: #8d8d8d;
  cursor: pointer;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledTickIcon = styled(TickIcon)`
  height: 12px;
  fill: #8d8d8d;
  cursor: pointer;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const Tr = styled.tr`
  ${props => props.disabled && "pointer-events: none; opacity: 0;"}
`;

const InputAndRef = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    height: 16px;
  }
`;

const InputWithEditModeTableColumn = ({
  value = null,
  onPressSave = newValue => {},
  initialPlotState = {},
  tableColumns = [],
}) => {
  const [editedValue, setEditedValue] = useState(value);
  const [isEditMode, setIsEditMode] = useState(false);

  const [plotState, setPlotState] = useState({
    plotType: "Table",
    xColumnName: tableColumns?.find(column => column?.type === "TEXT")?.name,
    yColumnNames: tableColumns
      ?.filter(column => column?.type === "NUMBER")
      ?.map(col => col?.name),
  });

  useEffect(() => {
    if (isEmpty(initialPlotState)) {
      return;
    }
    setPlotState(initialPlotState);
  }, [JSON.stringify(initialPlotState)]);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const addNewRow = () => {
    const newRow = {};
    tableColumns.forEach(column => {
      newRow[column.name] = { value: "" };
    });
    setEditedValue([...(editedValue || []), newRow]);
  };

  const editRow = (rowIndex, columnName, newValue) => {
    const newEditedValue = editedValue.map((row, i) => {
      if (i === rowIndex) {
        return {
          ...row,
          [columnName]: { value: newValue },
        };
      }
      return row;
    });
    setEditedValue(newEditedValue);
  };

  let rightButtons = <StyledPencilIcon onClick={() => setIsEditMode(true)} />;
  if (isEditMode) {
    rightButtons = (
      <>
        <StyledTickIcon
          onClick={() => {
            onPressSave(editedValue, plotState);
            setIsEditMode(false);
          }}
        />
        <StyledCrossIcon
          onClick={() => {
            setEditedValue(value);
            setIsEditMode(false);
          }}
        />
      </>
    );
  }

  let plotContent = (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {tableColumns.map((column, index) => (
            <Th key={index}>{column?.name}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {editedValue?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {tableColumns.map((column, columnIndex) => {
              return (
                <Td key={columnIndex}>
                  <InputAndRef>
                    <FieldInput
                      disabled={!isEditMode}
                      value={row?.[column?.name]?.value}
                      onChange={e =>
                        editRow(rowIndex, column?.name, e.target.value)
                      }
                    />
                    <ReferenceModalSpanWithContext
                      isDisabled={!row?.[column.name]?.reference}
                      isTooltipDisabled
                      contentHtml={""}
                      metas={[row?.[column.name]?.reference]}
                    >
                      <LinkWithStarIcon
                        style={{
                          fill: row?.[column.name]?.reference
                            ? "#0191ff"
                            : "#ccc",
                          marginTop: 2,
                        }}
                      />
                    </ReferenceModalSpanWithContext>
                    <ReferenceDebugModal
                      reference={row?.[column.name]?.reference}
                    />
                  </InputAndRef>
                </Td>
              );
            })}
          </tr>
        ))}
        <Tr disabled={!isEditMode}>
          <StyledPlusIcon style={{ margin: 4 }} onClick={addNewRow} />
        </Tr>
      </tbody>
    </table>
  );

  const { plotType } = plotState;
  const plotProps = {
    params: plotState,
    isEditing: isEditMode,
    onChangeParams: newParams => setPlotState(newParams),
    tableColumns,
    records: editedValue,
  };
  if (plotType !== "Table") {
    plotContent = getPlotComponent(plotType, plotProps);
  }

  let leftButtons = <div style={{ height: 14, gridColumn: "span 3" }} />;
  if (isEditMode) {
    leftButtons = (
      <div
        style={{
          gridColumn: "span 3",
          display: "grid",
          gridTemplateColumns: "1fr auto",
        }}
      >
        <select
          style={{ justifySelf: "start", marginBottom: 4 }}
          value={plotType}
          onChange={e =>
            setPlotState({ ...plotState, plotType: e.target.value })
          }
        >
          {PLOT_TYPES.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <TableContainer
      style={{
        gridTemplateColumns: isEditMode ? "1fr auto auto" : "1fr auto",
      }}
    >
      {leftButtons}
      <div>{plotContent}</div>
      {rightButtons}
    </TableContainer>
  );
};

export default InputWithEditModeTableColumn;
