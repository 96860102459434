import { postBulkUploadFormData } from "api/services/filesService";
import usePollFiles from "api/services/usePollFiles";
import { InfoMsg } from "components/DocSourcesModalTriggerNew";
import { Gap } from "components/Layout";
import ButtonIcon from "components/ui/ButtonIcon";
import {
  CrossIcon,
  InformationIcon,
  PdfIcon,
  TickIcon,
  UploadCloudIcon,
  UploadFileIcon,
  WarningIcon,
} from "components/ui/Icons";
import { uniqBy } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 20px auto auto;
  height: 100%;
`;

const Label = styled.label`
  cursor: pointer;
  width: max-content;
  display: flex;

  input {
    display: none;
  }
  :hover {
    opacity: 0.6;
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  padding: 0 22px;
  font-weight: 500;
`;

const StyledProgress = styled.progress`
  // pulsing opacity animation
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  animation: pulse 1s infinite;
`;

const DropArea = styled.div`
  width: 100%;
  border: 2px dashed #cdcdcd;
  color: #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  svg {
    height: 32px;
    margin-bottom: 16px;
    fill: #cdcdcd;
  }
  border-radius: 10px;
`;

const DirItem = styled.label`
  line-height: 1.5;
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  gap: 8px;
  grid-template-columns: 16px 1fr auto auto;
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

const ErrText = styled.div`
  font-weight: 500;
  line-height: 1.2;
  color: ${props => props.theme.color.error};
  padding: 4px 0;
`;

const MIN_PROGRESS = 5;

const BlueButton = styled(ButtonIcon)`
  font-weight: 500;
  justify-self: end;
  align-self: end;
  padding: 4px;
  margin-top: 20px;
  width: max-content;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileInputArea = ({
  sources = [],
  setSources = () => {},
  onClickDone = () => {},
  infoMsg = "",
}) => {
  const [filesToShow, setFilesToShow] = useState([]);
  const fileIds = filesToShow?.map(file => file?.id)?.filter(Boolean) || [];
  const [polledFiles] = usePollFiles(fileIds);

  const [uploadErr, setUploadErr] = useState(null);

  useEffect(() => {
    if (!polledFiles?.length) {
      return;
    }

    const newFilesToShow = filesToShow.map(file => {
      if (!file?.id) {
        return file;
      }

      const matchedPolledFile = polledFiles?.find(
        polledFile => polledFile?.id === file?.id
      );
      return matchedPolledFile;
    });
    setFilesToShow(newFilesToShow);

    // populate sources
    const newSourcesFromFiles = newFilesToShow
      .filter(file => file?.status === "DONE")
      .map(file => ({
        emailId: file?.emailId,
        fileId: file?.id,
        fileName: file?.fileName,
        type: "FILE",
      }));
    const newSources = uniqBy([...sources, ...newSourcesFromFiles], "fileId");
    setSources(newSources);
  }, [JSON.stringify(polledFiles)]);

  const doPostFiles = async () => {
    const filesToUpload = filesToShow?.filter(file => !file?.id);
    setUploadErr(null);
    const { data, error } = await postBulkUploadFormData(
      { directoryPath: "/source-files" },
      { files: filesToUpload }
    );
    setUploadErr(error);

    const newFilesToShow = filesToShow.map(file => {
      const matchedUploadedFile = data?.findLast(
        uploadedFile => uploadedFile?.originalName === file?.name
      );
      return matchedUploadedFile || file;
    });

    setFilesToShow(newFilesToShow);
  };

  const addToChat = () => {
    const newSourcesFromFiles = filesToShow
      .filter(file => file?.status === "DONE")
      .map(file => ({
        emailId: file?.emailId,
        fileId: file?.id,
        fileName: file?.fileName,
        type: "FILE",
      }));
    const newSources = uniqBy([...sources, ...newSourcesFromFiles], "fileId");
    setSources(newSources);
    onClickDone(newSources);
  };

  const areAnyFilesInProgress = filesToShow?.some(
    file => file?.status === "RUNNING"
  );
  const areAllFilesDone = filesToShow?.every(
    file => file?.status === "DONE" || file?.status?.includes("FAIL")
  );

  return (
    <Container>
      <DropArea
        onDrop={e => {
          e.preventDefault();
          setFilesToShow(prev => [...prev, ...e.dataTransfer.files]);
        }}
        onDragOver={e => {
          e.preventDefault();
        }}
      >
        <UploadFileIcon />
        Drop files here or use the "Upload content" button
        <Gap />
        <Label>
          <StyledButtonIcon isActive>Upload files</StyledButtonIcon>
          <input
            type="file"
            multiple
            accept=".pdf, .wav"
            onChange={e => {
              setFilesToShow(prev => [...prev, ...e.target.files]);
            }}
          />
        </Label>
      </DropArea>
      {uploadErr ? (
        <ErrText>
          {uploadErr?.message || "Upload error. Please try different files."}
        </ErrText>
      ) : (
        <div />
      )}
      <div style={{ minHeight: 60, height: "100%", overflow: "auto" }}>
        {filesToShow?.map((file, index) => {
          let progressContent = <div />;
          if (file?.status === "RUNNING") {
            progressContent = (
              <StyledProgress
                value={Math.max(file?.progress, MIN_PROGRESS)}
                max="100"
              />
            );
          }
          if (file?.status?.includes("FAIL")) {
            progressContent = <WarningIcon style={{ fill: "#d4a600" }} />;
          }

          if (file?.status?.includes("DONE")) {
            progressContent = <TickIcon style={{ fill: "#00a000" }} />;
          }

          return (
            <DirItem key={file?.id}>
              <PdfIcon style={{ fill: "#ed1c24" }} />
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {file?.fileName || file?.name}
              </div>
              {progressContent}
              <CrossIcon
                onClick={() => {
                  setFilesToShow(prev => prev.filter((_, i) => i !== index));
                }}
              />
            </DirItem>
          );
        })}
      </div>
      {infoMsg && filesToShow?.length > 0 && (
        <InfoMsg>
          <WarningIcon style={{ fill: "black" }} />
          <div dangerouslySetInnerHTML={{ __html: infoMsg }} />
        </InfoMsg>
      )}
      <div style={{ display: "flex", gap: 10, justifySelf: "end" }}>
        <BlueButton
          isDisabled={
            areAnyFilesInProgress || !filesToShow?.length || areAllFilesDone
          }
          isActive
          onClick={doPostFiles}
        >
          Upload
        </BlueButton>
        <BlueButton
          isDisabled={
            areAnyFilesInProgress || !filesToShow?.length || !areAllFilesDone
          }
          isActive
          onClick={addToChat}
        >
          Add to chat
        </BlueButton>
      </div>
    </Container>
  );
};

export default FileInputArea;
