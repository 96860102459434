import {
  CheckBox,
  Done,
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Link as LinkIcon,
  StrikethroughS,
} from "@material-ui/icons";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import LinkUrlTooltip from "components/ui/LinkUrlTooltip";
import TooltipNew from "components/ui/TooltipNew";
import { clamp, inRange, merge } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  addToStyleFieldsToSelection,
  getSelectionFromBlocks,
  getSelectionTopBarState,
} from "utils/word-utils";

const Container = styled.div`
  display: flex;
  background-color: white;
  box-shadow: ${props => props.theme.shadow};
  border-radius: 2px;
`;

const IconButton = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;
    fill: ${props => (props.isActive ? props.theme.color.primary : "black")};
  }

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
`;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const ACTIONS = [
  {
    icon: <FormatBold />,
    isActive: sel => sel?.fontWeight === "bold",
    onClick: sel => ({
      fontWeight: sel?.fontWeight === "bold" ? "normal" : "bold",
    }),
  },
  {
    icon: <FormatItalic />,
    isActive: sel => sel?.fontStyle === "italic",
    onClick: sel => ({
      fontStyle: sel?.fontStyle === "italic" ? "normal" : "italic",
    }),
  },
  {
    icon: <FormatUnderlined />,
    isActive: sel => sel?.isUnderlined,
    onClick: sel => ({
      isUnderlined: !sel?.isUnderlined,
    }),
  },
  {
    icon: <StrikethroughS />,
    isActive: sel => sel?.isStrikethrough,
    onClick: sel => ({
      isStrikethrough: !sel?.isStrikethrough,
    }),
  },
];

const WordToolbar = ({ style, blocks, onChangeBlocks = () => {} }) => {
  const [isVerified, setIsVerified] = useState(false);

  const sel = getSelectionTopBarState(blocks);

  useEffect(() => {
    setIsVerified(false);
  }, [JSON.stringify(sel)]);

  const doAddSelectedBlockLevelStyles = async ({
    fontSize,
    leftIndent,
    prefix,
    isQuery,
    isContext,
  }) => {
    const { startBlockIndex, endBlockIndex } = getSelectionFromBlocks(blocks);
    if (startBlockIndex === null) {
      return;
    }

    const newBlocks = blocks?.map((block, i) => {
      if (inRange(i, startBlockIndex, endBlockIndex + 1)) {
        return {
          ...block,
          blockStyles: merge(
            { ...block?.blockStyles },
            {
              fontSize,
              leftIndent: leftIndent ?? block?.blockStyles?.leftIndent,
              prefix,
              isContext,
            }
          ),
        };
      }

      return { ...block };
    });

    onChangeBlocks(newBlocks);
  };

  const addToSelectionStyle = newStyleFields => {
    const newBlocks = addToStyleFieldsToSelection(blocks, newStyleFields);
    onChangeBlocks(newBlocks);
  };

  return (
    <Container
      style={style}
      onMouseDown={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <IconButton>
        <StyledSelect
          value={sel?.fontSize}
          onChange={e =>
            doAddSelectedBlockLevelStyles({
              fontSize: parseInt(e.target.value),
            })
          }
        >
          <option value="14">Text</option>
          <option value="18">Subtitle</option>
          <option value="26">Title</option>
        </StyledSelect>
      </IconButton>
      {ACTIONS.map((action, i) => (
        <IconButton
          key={i}
          isActive={action.isActive(sel)}
          onClick={() => addToSelectionStyle(action.onClick(sel))}
        >
          {action.icon}
        </IconButton>
      ))}
      <ColorPickerTooltip
        selectedColor={sel?.fontColor}
        onNewColor={newFontColor => {
          addToSelectionStyle({
            fontColor: newFontColor,
          });
        }}
        triggerIcon={
          <IconButton>
            <FormatColorText />
          </IconButton>
        }
      />
      <ColorPickerTooltip
        selectedColor={sel?.bgColor}
        onNewColor={newBgColor => {
          addToSelectionStyle({
            bgColor: newBgColor,
          });
        }}
        triggerIcon={
          <IconButton>
            <FormatColorFill />
          </IconButton>
        }
      />
      <IconButton>
        <LinkUrlTooltip
          onNewUrl={newUrl => {
            addToSelectionStyle({
              url: newUrl,
            });
          }}
          triggerIcon={<LinkIcon />}
        />
      </IconButton>
      <IconButton
        onClick={() => {
          const { startBlockIndex } = getSelectionFromBlocks(blocks);
          const leftIndent =
            blocks?.[startBlockIndex]?.blockStyles?.leftIndent || 0;
          doAddSelectedBlockLevelStyles({
            leftIndent: clamp(leftIndent + 20, 0, 100),
          });
        }}
      >
        <FormatIndentIncrease />
      </IconButton>
      <IconButton
        onClick={() => {
          const { startBlockIndex } = getSelectionFromBlocks(blocks);
          const leftIndent =
            blocks?.[startBlockIndex]?.blockStyles?.leftIndent || 0;
          doAddSelectedBlockLevelStyles({
            leftIndent: clamp(leftIndent - 20, 0, 100),
          });
        }}
      >
        <FormatIndentDecrease />
      </IconButton>
      <IconButton
        isActive={sel?.prefix === "●"}
        onClick={() => {
          doAddSelectedBlockLevelStyles({
            leftIndent: sel?.prefix === "●" ? 0 : 20,
            prefix: sel?.prefix === "●" ? "" : "●",
          });
        }}
      >
        <FormatListBulleted />
      </IconButton>
      <IconButton
        isActive={sel?.prefix?.includes("☐")}
        onClick={() => {
          doAddSelectedBlockLevelStyles({
            leftIndent: sel?.prefix?.includes("☐") ? 0 : 20,
            prefix: sel?.prefix?.includes("☐") ? "" : "☐",
          });
        }}
      >
        <CheckBox />
      </IconButton>
      <TooltipNew
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        tipText={isVerified ? "Verified" : "Verify"}
      >
        <IconButton onClick={() => setIsVerified(true)} isActive={isVerified}>
          <Done />
        </IconButton>
      </TooltipNew>
    </Container>
  );
};

export default WordToolbar;
