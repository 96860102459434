import { getWordDoc } from "api/services/wordDocsService";
import { clamp, range } from "lodash";
import { WIKI_TEXT } from "pages/word-docs/:wordDocId/fixtures";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { sleep } from "utils/common";
import { getContentFromBlocks } from "utils/word-conversion-utils";
import {
  HEIGHT,
  SF_Y,
  VIEWPORT_WIDTH,
  VIEW_HEIGHT,
  WIDTH,
  deleteAtSelection,
  drawContent,
  insertAtSelection,
  moveCaret,
  moveCaretLine,
} from "utils/word-utils-new";

const Container = styled.div``;

const Canvas = styled.canvas`
  border: 1px dashed grey;
  position: fixed;
  top: 0;
  left: 0;
`;

const KEY_TO_NUM_CHARS = {
  ArrowRight: 1,
  ArrowLeft: -1,
  ArrowDown: 1,
  ArrowUp: -1,
};

const INITIAL_CONTENT = {
  text: `\n${range(0, 10)
    ?.map(() => WIKI_TEXT)
    ?.join("")}\n`,
  selectionStyles: [
    null,
    null,
    null,
    null,
    {
      isCaret: true,
    },
  ],
  textStyles: [],
};

let BASE_URL = "";
let IS_LOCAL_DEV = false;
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  IS_LOCAL_DEV = true;
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}

const WordDocPagePerf = () => {
  const { wordDocId } = useParams();
  const canvasRef = useRef(null);

  const [topY, setTopY] = useState(0);
  const [contentCoords, setContentCoords] = useState(null);
  const [wordDoc, setWordDoc] = useState(null);
  const [content, setContent] = useState(INITIAL_CONTENT);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);

    const newContent = getContentFromBlocks(data?.content?.blocks);
    newContent.selectionStyles = [
      ...range(0, 100)?.map(() => null),
      { isCaret: true },
    ];
    setContent(newContent);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("scroll", onScroll);
    };
  }, [content]);

  useEffect(() => {
    if (!canvasRef?.current) {
      return;
    }

    const ctx = canvasRef?.current?.getContext("2d");
    drawContent({ content, ctx, topY, contentCoords: {} });
  }, [canvasRef?.current, topY, contentCoords, content]);

  const onScroll = async e => {
    setTopY(window.scrollY * SF_Y);
  };

  const onKeyDown = e => {
    e.preventDefault();
    if (["ArrowLeft", "ArrowRight"].includes(e?.key)) {
      const newContent = moveCaret(content, KEY_TO_NUM_CHARS[e.key]);
      setContent(newContent);
      return;
    }

    if (["ArrowUp", "ArrowDown"].includes(e?.key)) {
      const newContent = moveCaretLine(
        content,
        contentCoords,
        KEY_TO_NUM_CHARS[e.key],
        topY
      );
      setContent(newContent);
      return;
    }

    if (e?.key === "Backspace") {
      const newContent = deleteAtSelection(content);
      setContent(newContent);
      return;
    }

    if (e?.key === "Enter") {
      const newContent = insertAtSelection("\n", content);
      setContent(newContent);
      return;
    }

    if (e?.key?.length > 1 || e?.metaKey || e?.ctrlKey || e?.altKey) {
      return;
    }

    const newContent = insertAtSelection(e.key, content);
    setContent(newContent);
  };

  return (
    <Container>
      <div style={{ height: 20000 }} />
      <Canvas
        style={{
          width: VIEWPORT_WIDTH,
          height: VIEW_HEIGHT * 1,
        }}
        width={WIDTH}
        height={HEIGHT * 1}
        ref={canvasRef}
      />
    </Container>
  );
};

export default WordDocPagePerf;
