import Modal from "components/ui/Modal";
import PagePreviewTextAndTableModal from "components/widgets/PagePreviewTextAndTableModal";
import { range } from "lodash";
import { useState } from "react";
import styled from "styled-components";

const Trigger = styled.div`
  border: 1px solid ${props => props.theme.color.closer2};
  cursor: pointer;
  border-right: none;
  height: 20px;
  opacity: 0.7;

  :hover {
    opacity: 0.4;
  }
`;

const ModalContent = styled.div`
  padding: 10px;
  max-width: 1000px;
  max-height: 800px;
  overflow: auto;
`;

const Td = styled.td`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 4px;
  max-width: 200px;
  line-height: 1.2;
  overflow: hidden;
`;

const BlockTextDiv = styled.div`
  min-height: 1em;
  outline: none;
`;

const StyledSpan = styled.span`
  ${props =>
    props.isBlue &&
    `
    background-color: ${props.theme.color.primary}22;
    cursor: pointer;    
  `};

  :hover {
    ${props => props.isBlue && "opacity: 0.7;"};
  }
`;

const Span = ({ meta, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledSpan onClick={() => setIsOpen(true)} isBlue={!!meta}>
        {children}
      </StyledSpan>
      {meta && (
        <PagePreviewTextAndTableModal
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          tableDocumentLocation={{
            fileId: meta?.fileId,
            pageNumber: meta?.pageNumber,
            fileName: meta?.fileName,
          }}
          searchResults={meta?.searchResults}
        />
      )}
    </>
  );
};

// segment{ text, meta }
const getBlockTextSegments = block => {
  const stylesWithMetas = block?.styles?.filter(s => s?.meta);

  const segments = [];
  let currentSegment = { text: "", meta: undefined };
  let charIndex = 0;

  while (charIndex < block?.text?.length) {
    const metaAtIndex = stylesWithMetas.find(
      s => charIndex >= s?.start && charIndex < s?.end
    )?.meta;

    if (JSON.stringify(metaAtIndex) !== JSON.stringify(currentSegment.meta)) {
      segments.push(currentSegment);
      currentSegment = { text: "", meta: metaAtIndex };
    }

    currentSegment.text += block.text[charIndex];
    charIndex++;
  }

  segments.push(currentSegment);

  return segments;
};

const DocTableModalTrigger = ({ style = {}, blocks = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const numberOfRows = blocks?.[0]?.numberOfRows;
  const numberOfColumns = blocks?.[0]?.numberOfColumns;

  return (
    <>
      <Trigger
        onClick={() => setIsOpen(true)}
        onMouseDown={e => e.stopPropagation()}
        style={style}
      >
        ...
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
        >
          <table>
            <tbody>
              {range(0, numberOfRows).map(rowIndex => (
                <tr key={rowIndex}>
                  {range(0, numberOfColumns).map(columnIndex => {
                    const blocksInCell = blocks.filter(
                      b =>
                        b.rowIndex === rowIndex && b.columnIndex === columnIndex
                    );

                    return (
                      <Td key={`${rowIndex}-${columnIndex}`}>
                        {blocksInCell?.map((b, indexInCell) => {
                          const segments = getBlockTextSegments(b);

                          return (
                            <BlockTextDiv onKeyDown={e => e.stopPropagation()}>
                              {segments?.map(seg => (
                                <Span meta={seg?.meta}>{seg.text}</Span>
                              ))}
                            </BlockTextDiv>
                          );
                        })}
                      </Td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          {/* <WordDoc blocks={blocks} /> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocTableModalTrigger;
