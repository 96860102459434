import { postContactUsDetails } from "api/backend/marketingServiceEndpoints";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { BoltzhubLogoInner, CrossIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: grid;
  grid-template-columns: 450px 1fr;
  padding: 20px;
`;

const ContentCard = styled.div`
  background-color: #232627;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const CloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: white;
  height: 30px;
  cursor: pointer;

  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 40px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
  position: absolute;
  transform: translateX(-115%);
`;

const Msg = styled.div`
  font-size: 16px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;

  @media (min-width: 1240px) {
    width: 500px;
  }

  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid transparent;
  background-color: #141718;
  margin-bottom: 16px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 14px;
  font-weight: 500;

  height: 52px;
  padding: 14px 16px 14px 16px;
  gap: 12px;
  border-radius: 12px;

  ::placeholder {
    color: #80878baa;
  }
`;

const ErrorMsg = styled.div`
  color: #ff000094;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: start;
`;

const LeftContainer = styled.div`
  padding: 30px 20px;
  padding-right: 40px;
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;

  background: linear-gradient(88.86deg, #ffffff 60.62%, #6f6f6f 105.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const LeftSubTitle = styled.div`
  font-size: 24px;
  color: #e8ecef80;
  line-height: 1.4;
  margin-bottom: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const FIELDS = [
  {
    name: "email",
    label: "Your email *",
    placeholder: "ada@mail.com",
  },
];

const SUCCESS_MSG =
  "Thank you. Please check your email for a confirmation link.";
const EXISTS_MSG =
  "You are already on the waitlist. Please check your email for a confirmation link.";

const SignupModal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [payload, setPayload] = useState({ email: "" });
  const [hasSigndUp, setHasSigndUp] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const signUpMsg = searchParams.get("signUpMsg");

  useEffect(() => {
    if (!signUpMsg) {
      setIsOpen(false);
      return;
    }

    setIsOpen(true);
  }, [signUpMsg]);

  const doSend = async () => {
    if (!payload?.email) {
      setErrorMsg("Please enter your email.");
      return;
    }

    setErrorMsg("");
    setIsSending(true);
    const { error } = await postContactUsDetails(
      {},
      {
        email: payload?.email,
      }
    );
    setIsSending(false);
    if (error) {
      // if (error?.message === "Email has already been used to sign up.") {
      //   setErrorMsg(EXISTS_MSG);
      //   return;
      // }
      setErrorMsg(error?.message || "There was an error. Please try again.");
      return;
    }

    setHasSigndUp(true);
  };

  const closeModal = () => {
    searchParams.delete("signUpMsg");
    setIsOpen(false);
    navigate({ search: searchParams.toString() });
  };

  let modalContent = null;
  if (!hasSigndUp) {
    modalContent = (
      <>
        <Gap height="10px" />
        <Msg>{signUpMsg}</Msg>
        <Gap height="30px" />
        {FIELDS?.map(field => (
          <Input
            key={field?.name}
            value={payload[field.name]}
            onChange={e =>
              setPayload({ ...payload, [field.name]: e.target.value })
            }
            placeholder={field.label}
          />
        ))}
        <ErrorMsg>{errorMsg}</ErrorMsg>
        <ButtonWord
          style={{ height: 52, fontSize: 16, opacity: isSending ? 0.5 : 1 }}
          isPrimary
          onClick={doSend}
        >
          Sign up
        </ButtonWord>
      </>
    );
  }

  if (hasSigndUp) {
    modalContent = (
      <>
        <Gap height="10px" />
        <Msg>{SUCCESS_MSG}</Msg>
        <Gap height="30px" />
        <ButtonWord
          style={{ height: 52, fontSize: 16 }}
          isPrimary
          onClick={closeModal}
        >
          Continue
        </ButtonWord>
      </>
    );
  }

  return (
    <Modal open={isOpen} handleClose={closeModal}>
      <ModalContent>
        <LeftContainer>
          <LeftTitle>Launching soon</LeftTitle>
          <LeftSubTitle>Trustworthy GenAI to control your data.</LeftSubTitle>
          <Img src="/images/signup-img.png" />
        </LeftContainer>
        <ContentCard>
          <CloseIcon onClick={closeModal} />
          <Title style={{ justifySelf: "center", marginBottom: "20px" }}>
            <StyledBoltzbitLogo />
            Boltzbit
          </Title>
          {modalContent}
        </ContentCard>
      </ModalContent>
    </Modal>
  );
};

export default SignupModal;
