import { apiDelete, apiGet, apiPatch, apiPost } from "api/api-http-methods";
import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getWordDocs = async (params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v1/word-docs`, params);
  return { data, error };
};

export const postWordDoc = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(`/bz-api/v1/word-docs`, params, body);
  return { data, error };
};

export const postWordDocsUpload = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/word-docs/upload`,
    params,
    body
  );
  return { data, error };
};

export const postWordDocsUploadSolutions = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/word-docs/upload`,
    params,
    body
  );
  return { data, error };
};

export const getWordDoc = async (id, params) => {
  const { data, error } = await apiGet(`/bz-api/v1/word-docs/${id}`, params);
  return { data, error };
};

export const patchWordDoc = async (id, params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    `/bz-api/v1/word-docs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const deleteWordDoc = async (id, params = {}) => {
  const { data, error } = await apiDelete(`/bz-api/v1/word-docs/${id}`, params);
  return { data, error };
};

export const postWordDocsIdSaveAsTemplate = async (
  id,
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/word-docs/${id}/save-as-template`,
    params,
    body
  );
  return { data, error };
};
