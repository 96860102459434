import { zapiGet } from "api/zapi";

export const getDatasetsFindByKey = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/data-service/api/v1/datasets/find-by-key",
    params
  );
  return { data, error };
};

export const getDataIngestionJob = async (jobId, params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/data-service/api/v1/data-ingestion-jobs/${jobId}`,
    params
  );
  return { data, error };
};
