import { Audiotrack } from "@material-ui/icons";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import ButtonIcon from "components/ui/ButtonIcon";
import {
  CrossIcon,
  ExcelIcon,
  FolderIcon,
  PdfIcon,
  PlusIcon,
  SigmaIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import useSearchParamsState from "hooks/useSearchParamsState";
import styled from "styled-components";

const Container = styled.div`
  /* position: fixed;
  top: 50px;
  width: 200px; */
  /* right: 0;
  height: calc(100vh - 60px); */
  /* border-left: 1px solid #e0e0e0; */
  padding-top: 10px;
  display: flex;
`;

const Title = styled.div`
  font-weight: bold;
  position: relative;
  padding-left: 10px;
`;

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 12px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0;
  height: 12px;
  padding-right: 4px;
  fill: #666666;
  cursor: pointer;
  :hover {
    fill: #000;
  }
`;

const DirectoryItem = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 8px 0;
  padding-right: 20px;
  gap: 8px;
  align-items: center;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  padding-left: 10px;

  :hover {
    background-color: #ededed;
    ${StyledCrossIcon} {
      opacity: 1;
    }
  }
`;

const DirItemName = styled.a`
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000bf;
  font-weight: 500;
`;

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 10px;
  margin-right: 2px;
`;

const StyledAudioIcon = styled(Audiotrack)``;

const getItemUrl = item => {
  if (item?.type === "SIGMA_TEMPLATE") {
    return `/trial-dashboards/${item?.fileId}/source`;
  }

  if (item?.type === "FILE") {
    return `/files/${item?.fileId}`;
  }

  if (item?.type === "WORD_DOC") {
    return `/word-docs/${item?.fileId}`;
  }

  if (item?.type === "EXCEL_MODEL") {
    return `/excel-views/${item?.fileId}`;
  }

  if (item?.type === "SLIDE_DOC") {
    return `/slide-docs/${item?.fileId}`;
  }

  return "";
};

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon />,
  EXCEL_MODEL: <StyledExcelIcon />,
  SLIDE_DOC: <StyledSlidesIcon />,
  DIRECTORY: <StyledFolderIcon />,
  FILE: <StyledPdfIcon />,
  SIGMA_TEMPLATE: <StyledSigmaIcon />,
};

const getItemIcon = item => {
  if (item?.isAudioFile) {
    return (
      <StyledAudioIcon
        style={{ width: "12px", height: "12px", marginTop: "2px" }}
      />
    );
  }

  return TYPE_TO_ICON[item?.type];
};

const SourceFilesListHorizontal = () => {
  const [sources, setSources] = useSearchParamsState({
    paramName: "sources",
    initialValue: [],
  });

  if (!sources?.length) {
    return null;
  }

  return (
    <Container>
      {/* <Title style={{ paddingBottom: "8px" }}>
        Sources ({sources?.length})
        <DocSourcesModalTrigger
          sources={sources}
          onChangeSources={newSources => setSources(newSources)}
          trigger={
            <ButtonIcon
              isActive={sources.length > 0}
              style={{
                position: "absolute",
                height: 16,
                width: 16,
                top: 0,
                right: 4,
                zIndex: 2,
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyledPlusIcon height="8px" />
            </ButtonIcon>
          }
        />
      </Title> */}
      {sources.map(source => (
        <DirectoryItem key={source.id}>
          {getItemIcon(source)}
          <DirItemName href={getItemUrl(source)} target="_blank">
            {source.name || source?.fileName}
          </DirItemName>
          <StyledCrossIcon
            style={{ position: "absolute", right: 0 }}
            onClick={() =>
              setSources(sources.filter(item => item.fileId !== source.fileId))
            }
          />
        </DirectoryItem>
      ))}
    </Container>
  );
};

export default SourceFilesListHorizontal;
