import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { SuggestedAnnotation } from "./annotationInputs";
import { includesObject } from "utils/common";
import { isEqual } from "lodash";

const getScaleFactor = (dataWidth, dataHeight, containerWidth, containerHeight) => {
  if (dataHeight > dataWidth) {
    return containerHeight / dataHeight;
  }
  return containerWidth / dataWidth;
};

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  ${({ dataWidth, dataHeight, width, height, scaleFactor, noCentering }) => {
    if (noCentering) {
      return;
    }
    if (dataWidth > dataHeight) {
      return `
        transform: translateY(${height / 2 - (dataHeight * scaleFactor) / 2}px);
      `;
    }
    return `transform: translateX(${(window.innerWidth - 50) / 14 - (dataWidth * scaleFactor) / 14}px)`;
  }}
`;

const CenteringWrapper = styled.div`
  position: relative;
  overflow: ${props => (props.isScrollingEnabled ? "auto" : "hidden")};
  background-color: ${props => props.theme.color.closer0};
  max-height: 100%;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${props => props.theme.color.closer0};
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const Canvas = styled.canvas`
  transform-origin: top left;
  transform: scale(${props => props.scaleFactor});
  image-rendering: pixelated;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  opacity: 1;
`;

const OcrAnnoCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: scale(${props => props.scaleFactor});
  image-rendering: pixelated;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const LabelCanvas = styled(Canvas)`
  position: absolute;
  top: 0;
  left: 0;
  background-image: none;
  pointer-events: none;
  opacity: 0.5;
`;

const RectCanvas = styled(LabelCanvas)``;

const ImageWithOCRAnnotationsAndHumanAnnottions = ({
  zoomScale = 1,
  isDisabled,
  width = 200,
  height = 200,
  base64Data,
  annotations_0 = [],
  annotations_1 = [],
  annotations_2 = [],
  annotations_3 = [],
  humanAnnotations = [],
  doPatchHumanAnnotations,
  onNewHumanAnnotations,
  preDefinedLabels,
  className,
}) => {
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const annoCanvasRef = useRef(null);
  const rectCanvasRef = useRef(null);

  const [dataWidth, setDataWidth] = useState(1);
  const [dataHeight, setDataHeight] = useState(1);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [topLeft, setTopLeft] = useState({ x: 0, y: 0 });
  const [rectWidth, setRectWidth] = useState(0);
  const [rectHeight, setRectHeight] = useState(0);

  const [hasImageBeenDrawn, setHasImageBeenDrawn] = useState(false);

  const [selectedHumanAnnotations, setSelectedHumanAnnotations] = useState([]);

  useEffect(() => {
    const deleteSelectedAnnosOnBackspace = e => {
      if (e?.key !== "Backspace") {
        return;
      }
      const newHumanAnnotations = humanAnnotations?.filter(anno => !includesObject(selectedHumanAnnotations, anno));
      onNewHumanAnnotations(newHumanAnnotations);
      // doPatchHumanAnnotations(newHumanAnnotations);
      setSelectedHumanAnnotations([]);
    };

    document.addEventListener("keydown", deleteSelectedAnnosOnBackspace);
    return () => document.removeEventListener("keydown", deleteSelectedAnnosOnBackspace);
  }, [selectedHumanAnnotations, humanAnnotations]);

  useEffect(() => {
    setHasImageBeenDrawn(false);
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current?.width, canvasRef.current?.height);

    const image = new Image();
    image.onload = () => {
      setDataWidth(image.width);
      setDataHeight(image.height);
      ctx.drawImage(image, 0, 0, image.width, image.height);
      setHasImageBeenDrawn(true);
    };
    image.src = `data:image/png;base64,${base64Data}`;
  }, [base64Data]);

  const clearAnnotationCanvas = () => {
    const annoCtx = annoCanvasRef.current.getContext("2d");
    annoCtx.clearRect(0, 0, annoCanvasRef.current?.width, annoCanvasRef.current?.height);
  };

  const drawAnnotations = () => {
    const annoCtx = annoCanvasRef.current.getContext("2d");
    annoCtx.clearRect(0, 0, annoCanvasRef.current?.width, annoCanvasRef.current?.height);

    annoCtx.fillStyle = "#22093311";
    annotations_0.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.fillStyle = "#c8540055";
    annotations_1.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.fillStyle = "#04788933";
    annotations_2.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.fillStyle = "#00C85322";
    annotations_3.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });
  };

  const getXandYOfEvent = e => {
    const canvasContainerRect = canvasContainerRef.current.getBoundingClientRect();
    const containerX = e.clientX - canvasContainerRect.left;
    const containerY = e.clientY - canvasContainerRect.top;

    const x = Math.floor(containerX / scaleFactor);
    const y = Math.floor(containerY / scaleFactor);
    return [x, y];
  };

  useEffect(() => {
    const rectCtx = rectCanvasRef.current.getContext("2d");
    rectCtx.clearRect(0, 0, dataWidth, dataHeight);
    rectCtx.fillStyle = "rgb(1, 145, 255)";
    rectCtx.fillRect(topLeft.x, topLeft.y, rectWidth, rectHeight);
  }, [topLeft, rectWidth, rectHeight]);

  useEffect(() => {
    if (!hasImageBeenDrawn || !base64Data) {
      clearAnnotationCanvas();
      return;
    }
    drawAnnotations();
  }, [
    annotations_0,
    annotations_1,
    annotations_2,
    annotations_3,
    annoCanvasRef?.current,
    dataWidth,
    dataHeight,
    base64Data,
  ]);

  const startDrawingRectangle = e => {
    setIsMouseDown(true);
    const [x, y] = getXandYOfEvent(e);
    setTopLeft({ x, y });
  };

  const updateRectangleBox = e => {
    if (!isMouseDown) {
      return;
    }
    const [x1, y1] = getXandYOfEvent(e);
    setRectWidth(x1 - topLeft.x);
    setRectHeight(y1 - topLeft.y);
  };

  const stopDrawingRectangleAndPatch = () => {
    setIsMouseDown(false);

    const newHumanAnnotation = {
      x: topLeft.x,
      y: topLeft.y,
      w: rectWidth,
      h: rectHeight,
      label: "-",
    };
    if (rectWidth > 0 && rectHeight > 0) {
      onNewHumanAnnotations([...humanAnnotations, newHumanAnnotation]);
      // doPatchHumanAnnotations([...humanAnnotations, newHumanAnnotation]);
    }

    setRectHeight(0);
  };

  const scaleFactor = getScaleFactor(dataWidth, dataHeight, width, height);

  return (
    <CenteringWrapper
      isDisabled={isDisabled}
      className={className}
      onClick={() => setSelectedHumanAnnotations([])}
      isScrollingEnabled
    >
      <ImageContainer
        id="image-container"
        dataWidth={dataWidth}
        dataHeight={dataHeight}
        width={width}
        height={height}
        scaleFactor={scaleFactor}
        ref={canvasContainerRef}
      >
        <Canvas ref={canvasRef} scaleFactor={scaleFactor} width={dataWidth} height={dataHeight} />
        <RectCanvas ref={rectCanvasRef} scaleFactor={scaleFactor} width={dataWidth} height={dataHeight} />
        <OcrAnnoCanvas
          ref={annoCanvasRef}
          scaleFactor={scaleFactor}
          width={dataWidth}
          height={dataHeight}
          onMouseDown={startDrawingRectangle}
          onMouseMove={updateRectangleBox}
          onMouseUp={stopDrawingRectangleAndPatch}
        />

        {hasImageBeenDrawn &&
          humanAnnotations?.map((annotation, annotationIndex) => {
            const { x, y, w, h, label } = annotation;
            const isSelected = includesObject(selectedHumanAnnotations, annotation);
            return (
              <SuggestedAnnotation
                key={`${x}-${y}-${w}-${h}-${label}-${annotationIndex}`}
                x={annotation?.x}
                y={annotation?.y}
                w={annotation?.w}
                h={annotation?.h}
                isSelected={isSelected}
                onClick={() => {
                  let newSelectedAnnotations = isSelected
                    ? selectedHumanAnnotations.filter(item => !isEqual(item, annotation))
                    : [...selectedHumanAnnotations, annotation];

                  setSelectedHumanAnnotations(newSelectedAnnotations);
                }}
                onChange={e => {
                  const newLabel = e.target.value;
                  const newAnnotations = humanAnnotations.map((a, i) => {
                    if (i === annotationIndex) {
                      return { ...a, label: newLabel };
                    }
                    return a;
                  });
                  onNewHumanAnnotations(newAnnotations);
                  // doPatchHumanAnnotations(newAnnotations);
                }}
                scaleFactor={scaleFactor}
                annotatedLabel={annotation?.label}
                labels={preDefinedLabels}
                onClickClose={() => {
                  const newAnnotations = humanAnnotations.filter((_, i) => i !== annotationIndex);
                  onNewHumanAnnotations(newAnnotations);
                  // doPatchHumanAnnotations(newAnnotations);
                }}
                onMouseDown={startDrawingRectangle}
                onMouseMove={updateRectangleBox}
                onMouseUp={stopDrawingRectangleAndPatch}
                onEditAnnotationBox={(newX, newY, newWidth, newHeight) => {
                  const newAnnotations = humanAnnotations.map((a, i) => {
                    if (i === annotationIndex) {
                      return { ...a, x: newX, y: newY, w: newWidth, h: newHeight };
                    }
                    return a;
                  });
                  onNewHumanAnnotations(newAnnotations);
                  // doPatchHumanAnnotations(newAnnotations);
                }}
              />
            );
          })}
      </ImageContainer>
    </CenteringWrapper>
  );
};

export default ImageWithOCRAnnotationsAndHumanAnnottions;
