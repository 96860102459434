import { useState } from "react";
import styled from "styled-components";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import { BigTitle, SmallTitle } from "components/ui/Text";
import TextInputSquare from "components/inputs/TextInputSquare";
import TextArea from "components/ui/TextArea";
import KeyValueEditor from "components/KeyValueEditor";
import Button from "components/ui/Button";
import { postTag } from "api/services/tagsService";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
  min-width: 600px;
  height: 600px;
  overflow: auto;

  display: grid;
  grid-template-rows: repeat(5, auto) 1fr;
  gap: 10px;
`;

const CreateButton = styled(Button)`
  align-self: end;
  justify-self: start;
`;

const NameAndColor = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const ColorRect = styled.div`
  background-color: ${props => props.color};
  width: 30px;
  height: 30px;
`;

const CreateTagModal = ({ onTagCreated = () => {}, className, renderTriggerButton = ({ onClick }) => null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tag, setTag] = useState({});
  const [keyValueMap, setKeyValueMap] = useState({});
  const [isCreatingTag, setIsCreatingTag] = useState(false);

  // const [color, setColor] = useState(["#0191ff", "#d4a600", "#ff7f0e", "#348686"][Math.floor(Math.random() * 4)]);

  return (
    <>
      {renderTriggerButton({ onClick: () => setIsOpen(true) }) || (
        <ButtonCreateNewAsset onClick={() => setIsOpen(true)} className={className} />
      )}
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <BigTitle>New tag</BigTitle>
          <NameAndColor>
            <TextInputSquare
              placeholder="Name"
              value={tag?.name}
              onNewInput={newName => setTag({ ...tag, name: newName })}
            />
            <ColorRect color="#000000" />
          </NameAndColor>
          <TextArea
            placeholder="Description"
            value={tag?.description}
            onNewInput={newDescription => setTag({ ...tag, description: newDescription })}
          />
          <SmallTitle>Additional fields</SmallTitle>
          <KeyValueEditor
            keyValueMap={keyValueMap}
            onNewKeyValueMap={newKeyValueMap => setKeyValueMap(newKeyValueMap)}
          />
          <CreateButton
            value="Create"
            isDisabled={isCreatingTag}
            onClick={async () => {
              setIsCreatingTag(true);
              const { data } = await postTag({ ...tag, metaJson: JSON.stringify(keyValueMap) });
              onTagCreated(data);
              setIsCreatingTag(false);
              setIsOpen(false);
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateTagModal;
