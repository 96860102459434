import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Edit, Email, Functions, GridOn, Title } from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { deleteFileById } from "api/services/filesService";
import { deleteDirectory, getDirectoryItems } from "api/services/fileSystem";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import { getLoggedInUserName } from "api/services/authenticationService";

import { CenteredWithTopNavLayout } from "components/Layout";
import useSearchParamsState from "hooks/useSearchParamsState";
import FileActionsDropdown from "components/FileActionsDropdown";
import { isTruthy } from "utils/common";
import MoveItemsToDirectoryModalTrigger from "components/MoveItemsToDirectoryModalTrigger";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";
import useDirectoryPagination from "hooks/useDirectoryPagination";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import { format } from "date-fns";
import { deleteExcelModelById } from "api/services/excelModelsService";
import { deleteWordDoc } from "api/services/wordDocsService";
import { ExcelIcon, WordIcon } from "components/ui/Icons";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Tooltip from "components/ui/Tooltip";
import PipelineNameModalTrigger from "components/PipelineNameModalTrigger";
import PipelineNameModalTriggerNew from "components/PipelineNameModalTriggerNew";

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding-bottom: 100px;
`;

const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const BigTitle = styled(Link)`
  font-size: 24px;
  font-weight: 600;

  text-decoration: none;
  color: ${props => props.theme.color.closest};
`;

const DirectoryPath = styled.div`
  font-size: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Td = styled.td`
  max-width: 780px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 22px;
  background-color: ${props => props.theme.color.closer0};
  vertical-align: middle;
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const HeaderTr = styled.tr`
  color: white;
  background: linear-gradient(
    to right,
    ${props => props.theme.color.feature},
    ${props => props.theme.color.primary}
  );
  font-weight: 600;

  ${Td} {
    background-color: transparent;
  }
`;

const IconAndName = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ItemName = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleAndPath = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DirLink = styled(Link)`
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CreationBtns = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 0;
`;

const LinkToTemplate = ({ directoryPath, children }) => {
  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    doPopulateTemplateId();
  }, [directoryPath]);

  const doPopulateTemplateId = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setTemplateId(data?.items?.find(item => item?.isTemplate)?.id);
  };

  return (
    <IconAndName
      target="_blank"
      href={`/trial-dashboards/${templateId}/source`}
    >
      {children}
    </IconAndName>
  );
};

const TemplateFilesPage = () => {
  const loggedInUserName = getLoggedInUserName();
  const navigate = useNavigate();

  const [directoryPath] = useSearchParamsState({
    paramName: "directoryPath",
    initialValue: "/template-files",
  });
  const [directoryItems, setDirectoryItems] = useState([]);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);

  const [
    pageSize,
    offset,
    prevUrl,
    nextUrl,
    hasMore,
    setNextOffset,
    setHasMore,
  ] = useDirectoryPagination();

  useEffect(() => {
    doPopulateDirectoryItems();
  }, [directoryPath]);

  const doPopulateDirectoryItems = async () => {
    setDirectoryItems([]);
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setDirectoryItems(data?.items || []);
    setNextOffset(data?.offset);
    setHasMore(data?.hasMore);
  };

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?.id);

    let error = null;

    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await deleteExcelModelById(item?.id));
    }

    if (item?.type === "FILE") {
      ({ error } = await deleteFileById(item?.id));
    }

    if (item?.type === "DIRECTORY") {
      ({ error } = await deleteDirectory({ directoryPath: item?.path }));
    }

    if (item?.type === "WORD_DOC") {
      ({ error } = await deleteWordDoc(item?.id));
    }

    setItemIdBeingDeleted(null);
    if (error) {
      setError(error);
      return;
    }
    setDirectoryItems(
      directoryItems.filter(dirItem => dirItem?.id !== item?.id)
    );
  };

  const dirNames = directoryPath?.split("/");
  const parentDirectoryPath = dirNames.slice(0, -1).join("/");

  return (
    <StyledCenteredWithTopNavLayout>
      <TopContent>
        <TitleAndPath>
          <BigTitle to="/template-files">Apps</BigTitle>
          <DirectoryPath>
            {dirNames?.length > 6 && "..."}
            {dirNames
              .filter(isTruthy)
              .filter(dirName => dirName !== "template-files")
              .map((dirName, i) => {
                if (dirNames?.length > 5 && i + 3 < dirNames?.length - 3) {
                  return null;
                }

                return (
                  <Fragment key={i}>
                    &nbsp;/&nbsp;
                    <DirLink
                      to={`?directoryPath=${dirNames
                        ?.slice(0, i + 3)
                        .join("/")}`}
                    >
                      {dirName}
                    </DirLink>
                  </Fragment>
                );
              })}
          </DirectoryPath>
        </TitleAndPath>
        {/* <Link target="_blank" to="/flow-templates">
          flow templates
        </Link> */}
      </TopContent>

      <CreationBtns>
        <Tooltip title="New pipeline">
          <PipelineNameModalTriggerNew />
        </Tooltip>
      </CreationBtns>

      <Table>
        <thead>
          <HeaderTr>
            <Td>Name</Td>
            <Td>Created at</Td>
            <Td>Owner</Td>
            <Td></Td>
          </HeaderTr>
        </thead>
        <tbody>
          {directoryItems?.map((item, i) => {
            let itemIcon = null;
            let itemName = item?.originalName || item?.id;
            let itemUrl = `/template-files?directoryPath=${directoryPath}`;
            if (item?.fileName?.includes(".pdf")) {
              itemIcon = <PictureAsPdfIcon style={{ fill: "#d9534f" }} />;
              itemName = item?.fileName;
              itemUrl = `/file/${item?.id}`;
            }
            if (item?.fileName?.includes(".xlsx")) {
              itemIcon = <GridOn style={{ fill: "#188038" }} />;
              itemName = item?.fileName;
              itemUrl = `/file/${item?.id}`;
            }
            if (item?.type === "DIRECTORY") {
              itemIcon = <Functions />;
              itemName = item?.path
                ?.replace(directoryPath, "")
                ?.replace("/", "");
              itemUrl = `/template-files?directoryPath=${item?.path}`;
            }
            if (item?.type === "EMAIL_FILE") {
              itemIcon = <Email style={{ fill: "grey" }} />;
              itemName = item?.emailSubject;
            }
            if (item?.type === "WORD_DOC") {
              itemIcon = <WordIcon />;
              itemName = item?.fileName;
              itemUrl = `/word-docs/${item?.id}`;
            }
            if (item?.type === "EXCEL_MODEL") {
              itemIcon = <ExcelIcon />;
              itemName = item?.name;
              itemUrl = `/excel-views/${item?.id}`;
            }
            if (item?.isTemplate) {
              itemIcon = <Title />;
              itemUrl = `/trial-dashboards/${item?.id}/source`;
            }

            const fileActions =
              item?.createdBy === loggedInUserName ? ["Delete"] : [];

            return (
              <Tr
                isDisabled={itemIdBeingDeleted === item?.id}
                key={`${item?.id}-${i}`}
              >
                <Td width="100%">
                  {item?.type === "DIRECTORY" ? (
                    <LinkToTemplate directoryPath={item?.path}>
                      {itemIcon}
                      <ItemName>{itemName}</ItemName>
                    </LinkToTemplate>
                  ) : (
                    <IconAndName target="_blank" href={itemUrl}>
                      {itemIcon}
                      <ItemName>{itemName}</ItemName>
                    </IconAndName>
                  )}
                </Td>
                <Td>
                  {item?.createdAt
                    ? format(new Date(item?.createdAt), "d MMM yyyy")
                    : ""}
                </Td>
                <Td>
                  {item?.createdBy === loggedInUserName
                    ? "me"
                    : item?.createdBy}
                </Td>
                <Td>
                  <ActionsContainer>
                    <FileActionsDropdown
                      visibleActions={fileActions}
                      onClickShare={() => {}}
                      onClickDelete={() => doDeleteItem(item)}
                      onClickDownload={() =>
                        triggerDownloadOfFile(item?.id, {
                          fileType: item?.type,
                        })
                      }
                      additionalActions={[
                        <MoveItemsToDirectoryModalTrigger
                          id={item?.id}
                          itemType={item?.type}
                          directoryPath={directoryPath}
                          parentDirectoryPath={parentDirectoryPath}
                          directoryOptionItems={directoryItems?.filter(
                            item => item?.type === "DIRECTORY"
                          )}
                          onItemMoved={doPopulateDirectoryItems}
                        />,
                        <ShareDirectoryItemModalTrigger directoryItem={item} />,
                      ]}
                    />
                  </ActionsContainer>
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <PrevNextPageButtons
        onClickPrev={() => navigate(prevUrl)}
        isPrevDisabled={!offset}
        onClickNext={() => navigate(nextUrl)}
        isNextDisabled={!hasMore}
      />
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </StyledCenteredWithTopNavLayout>
  );
};

export default TemplateFilesPage;
