import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { formatDistance } from "date-fns";

import { CrossIcon } from "components/ui/Icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";

const Container = styled.div`
  background-color: ${props => props.theme.color.closer0}AA;
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.color.closer1};

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
  cursor: default;
`;

const ProfilePic = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;

  object-fit: cover;
  width: 30px;
  height: 30px;
  border: 2px solid ${props => props.theme.color.primary};
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  overflow: hidden;
`;

const ModelAndVersion = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-auto-flow: column;
  gap: 2px;
  align-content: start;
`;

const ModelName = styled.div`
  font-weight: 600;
`;

const ModelVersion = styled.a`
  font-size: 12px;
  color: ${props => props.theme.color.closer2};
`;

const FileNameSpan = styled.div`
  grid-column: span 2;
  line-height: 1.2;
  gap: 10px;
  font-size: 16px;
  padding: 2px;
  border-radius: 4px;
  max-width: 300px;
`;

const CloseBtn = styled(CrossIcon)`
  padding: 2px;
  border-radius: 50%;
  height: 18px;
  margin-left: 20px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.furthest};
  }
`;

const ReferenceCardUser = ({
  sourceModalMeta,
  style,
  onClickDelete = metaId => {},
}) => {
  const userName = getLoggedInUserName();
  const [avatarSrc, setAvatarSrc] = useState(null);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [userName]);

  const doFetchCreatorAvatar = async () => {
    const { data } = await getUserProfileByUserName(userName);
    setAvatarSrc(
      data?.image?.includes("/images/")
        ? data?.image
        : `data:image/png;base64,${data?.image}`
    );
  };

  return (
    <Container
      style={style}
      onMouseDown={e => e.stopPropagation()}
      onMouseMove={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}
      isGreen={sourceModalMeta?.isApproved}
      isRed={sourceModalMeta?.isApproved === false}
    >
      <ProfilePic src={avatarSrc} />
      <ModelAndVersion>
        <ModelName>{userName}</ModelName>
        <ModelVersion>
          {sourceModalMeta?.createdAt &&
            `added ${formatDistance(
              new Date(sourceModalMeta?.createdAt),
              new Date()
            )} ago`}
        </ModelVersion>
        <CloseBtn onClick={e => onClickDelete(e)} />
      </ModelAndVersion>
      <FileNameSpan>{sourceModalMeta?.label}</FileNameSpan>
    </Container>
  );
};

export default ReferenceCardUser;
