import {
  getPipelineConfig,
  getSigmasV2,
} from "api/backend/fileSystemEndpoints";
import {
  deleteSequence,
  getEmail,
  getSequence,
  patchSequenceUpdate,
  postSequenceTrigger,
  postSequencesCreate,
} from "api/backend/projectServiceEndpoints";
import GridDraggableConnect from "components/GridDraggableConnect";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import ButtonWord from "components/ui/ButtonWord";
import { WordIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { cloneDeep, get, range, set } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const Container = styled.div`
  padding-top: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: calc(100vh - 20px - 20px);
`;

const TopContent = styled.div`
  padding: 40px;
`;

const BottomContent = styled.div`
  background-color: white;
  padding: 12px 20px;
  display: flex;
  gap: 20px;
  justify-content: end;
  border-top: 1px solid #ccc;
`;

const StyledInput = styled.input`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding: 8px;
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 40px;
  overflow: hidden;
`;

const Panel = styled.div`
  border-top: 1px solid #ccc;
  overflow: auto;
`;

const StepCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #eaeaea;
  border-radius: 10px;
  padding: 12px;
  font-weight: 600;
  border: 2px solid #eaeaea;
  ${props => props.isSelected && "border: 2px solid #0191ff;"}
`;

const StepName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 14px;
  line-height: 1.2;
`;

const GreySpan = styled.span`
  font-size: 12px;
  color: #a2a2a2;
`;

const FormFields = styled.div`
  padding: 20px;
`;

const SmallSpan = styled.span`
  font-size: 12px;
`;

const Select = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  height: 200px;
  resize: none;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;
`;

const TableContainer = styled.div`
  overflow: auto;
  /* border: 1px solid #ccc; */
  position: relative;
  overflow: hidden;
  min-height: 120px;
`;

const EmptyMsg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 20px;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #ccc;
  font-size: 18px;
`;

const STEP_FIELDS = {
  name: "Untitled Step",
  type: "email",
  function: {
    recipients: [],
    template: "",
    subject: "",
  },
  action: {},
  output: [],
  next: [],
};

const STEP_0 = {
  id: "step0",
  name: "Sequence trigger",
  type: "manually-triggered",
  action: {},
  output: [],
  next: [],
};

const STEP_0_INPUT_FIELDS = [
  {
    label: "Sequence trigger",
    type: "select",
    path: "type",
    options: [
      {
        label: "Record created",
        value: "record-created",
      },
      {
        label: "Record updated",
        value: "record-updated",
      },
      {
        label: "Manual trigger",
        value: "manually-triggered",
      },
    ],
  },
];

const STEP_INPUT_FIELDS = [
  {
    type: "text",
    path: "name",
    label: "Name",
  },
  {
    label: "Action type",
    type: "select",
    path: "type",
    options: [
      {
        label: "Send email",
        value: "email",
      },
      {
        label: "Generate word doc",
        value: "generate_word_doc",
      },
    ],
  },
];

const ACTION_TYPE_TO_INPUT_FIELDS = {
  email: [
    { label: "Recipient", type: "text", path: "function.recipients" },
    { label: "Subject", type: "text", path: "function.subject" },
    { label: "Template", type: "textarea", path: "function.template" },
  ],
  generate_word_doc: [
    { label: "Doc Prompt", type: "textarea", path: "function.prompt" },
  ],
};

const FieldInput = ({
  value = "",
  onChangeValue = newValue => {},
  type = "",
  options = [],
}) => {
  if (type === "textarea") {
    return (
      <TextArea value={value} onChange={e => onChangeValue(e.target.value)} />
    );
  }

  if (type === "select") {
    return (
      <Select value={value} onChange={e => onChangeValue(e.target.value)}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    );
  }

  return (
    <StyledInput value={value} onChange={e => onChangeValue(e.target.value)} />
  );
};

const updateSequenceNexts = (sequence, edges = []) => {
  const newSequence = cloneDeep(sequence);
  newSequence.steps.forEach(step => {
    step.next = [];
    edges.forEach(edge => {
      if (edge.source === step.id) {
        step.next.push(edge.target);
      }
    });
  });

  return newSequence;
};

const getEdgesFromSequence = sequence => {
  const edges = [];
  sequence?.steps?.forEach(step => {
    step?.next?.forEach(nextId => {
      edges.push({ source: step.id, target: nextId });
    });
  });

  return edges;
};

const ModalContent = styled.div`
  padding: 20px;
  width: 600px;
`;

const RunModal = ({ sequenceId, pipelineConfig = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const doPopulateSigmaRecords = async (pipelineId, tableName) => {
    const { data } = await getSigmasV2({
      pipelineId,
      tableName,
    });
    setSigmaRecords(data || []);
  };

  useEffect(() => {
    if (!pipelineConfig?.id) {
      return;
    }
    const tableId = pipelineConfig?.sourceTables?.[0]?.id;
    doPopulateSigmaRecords(pipelineConfig?.id, tableId);
  }, [pipelineConfig?.id]);

  const doRunSequence = async () => {
    setIsLoading(true);
    await postSequenceTrigger(
      sequenceId,
      {},
      {
        input: {
          sigmaIds: selectedRecordIds,
          pipelineId: pipelineConfig?.id,
        },
      }
    );
    setIsLoading(false);
    setIsOpen(false);
  };

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];

  return (
    <>
      <ButtonWord onClick={() => setIsOpen(true)}>Run</ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Select records</Title>
          <div style={{ width: "100%", overflow: "auto", height: 400 }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  {columns.map(column => (
                    <Th key={column.id}>
                      {column.name?.replaceAll("_", " / ")}
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sigmaRecords.map(record => {
                  const onChange = e => {
                    if (e.target.checked) {
                      setSelectedRecordIds(prev => [...prev, record.id]);
                      return;
                    }
                    setSelectedRecordIds(prev =>
                      prev.filter(id => id !== record.id)
                    );
                  };

                  return (
                    <tr key={record.id}>
                      <Td>
                        <input
                          type="checkbox"
                          checked={selectedRecordIds.includes(record.id)}
                          onChange={onChange}
                        />
                      </Td>
                      {columns.map(column => {
                        let recordValue = record?.fields?.[column.name]?.value;
                        if (column?.type === "TABLE") {
                          recordValue = `${recordValue?.length} rows`;
                        }
                        return <Td key={column.id}>{recordValue}</Td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Gap height="20px" />
          <ButtonWord disabled={isLoading} onClick={doRunSequence}>
            Run
          </ButtonWord>
        </ModalContent>
      </Modal>
    </>
  );
};

const IconA = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 14px;
  }
`;

const Outputs = styled.div`
  display: flex;
  gap: 20px;
  width: 500px;
`;

const ColoredDiv = styled.div`
  background-color: #e7e7e7;
  color: #333;
  width: max-content;
  font-weight: 500;
  padding: 2px;
  border-radius: 4px;
`;

export const StepOutput = ({ statusOnly = false, outputRecord }) => {
  const [email, setEmail] = useState(null);

  const output = JSON.parse(outputRecord?.fields?.Output?.value || "{}");

  const wordDocId = output?.generated_word_doc_ids?.[0];
  let emailId = "";
  if (output?.step_type === "email") {
    emailId = output?.result_ids?.[0];
  }

  useEffect(() => {
    if (!emailId) {
      return;
    }
    doPopulateEmail();
    const intervalId = setInterval(() => {
      doPopulateEmail();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [emailId]);

  const doPopulateEmail = async () => {
    const { data } = await getEmail(emailId);
    setEmail(data);
  };

  return (
    <Outputs style={statusOnly ? { width: "auto" } : {}}>
      {email && <ColoredDiv>status: {email?.status}</ColoredDiv>}
      {wordDocId && !statusOnly && (
        <IconA
          href={`/word-docs/${wordDocId}`}
          target="_blank"
          rel="noreferrer"
        >
          <WordIcon /> content
        </IconA>
      )}
    </Outputs>
  );
};

const EXECUTION_COLUMNS = [
  {
    label: "Execution Time",
    name: "Execution Time",
  },
  {
    label: "Step Name",
    name: "Step Name",
  },
  {
    label: "Step Type",
    name: "Step Type",
  },
  {
    label: "Output",
    name: "Output",
  },
];

const getStep = (outputRecord, sequence) => {
  const stepId = outputRecord?.fields?.StepId?.value;
  const step = sequence?.steps?.find(step => step?.id === stepId);
  return step;
};

const SequencePage = () => {
  const navigate = useNavigate();
  const { pipelineConfigId, sequenceId } = useParams();

  const [pipelineConfig, setPipelineConfig] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const [sequence, setSequence] = useState({
    pipelineId: pipelineConfigId,
    name: "Untitled Sequence",
    steps: [STEP_0],
  });
  const [layout, setLayout] = useState({
    step0: { x: 8, y: 1, w: 10, h: 4 },
  });
  const [selectedStepId, setSelectedStepId] = useState("step0");

  const [outputRecords, setOutputRecords] = useState([]);

  const selectedStep = sequence?.steps?.find(
    step => step?.id === selectedStepId
  );

  useEffect(() => {
    if (sequenceId === "new") {
      return;
    }
    doPopulateSequence();
  }, [sequenceId]);

  useEffect(() => {
    doPopulatePipelineConfig();
    doPopulateOutputSigmaRecords(pipelineConfigId);

    const intervalId = setInterval(() => {
      doPopulateOutputSigmaRecords(pipelineConfigId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [pipelineConfigId]);

  const doPopulateSequence = async () => {
    const { data } = await getSequence(sequenceId);
    setSequence(data || {});
    setLayout(data?.meta?.layout || {});
  };

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const doPopulateOutputSigmaRecords = async pipelineId => {
    const { data } = await getSigmasV2({
      pipelineId,
      tableName: "source_step_output",
    });
    const sequenceOutputRecords =
      data?.filter(record => record?.fields?.SeqId?.value === sequenceId) || [];
    setOutputRecords(sequenceOutputRecords);
  };

  const doSaveSequence = async () => {
    setIsSaving(true);
    const payload = {
      ...sequence,
      meta: { layout },
    };
    if (sequenceId === "new") {
      await postSequencesCreate({}, payload);
      setIsSaving(false);
      navigate(`/apps/${pipelineConfigId}/sequences`);
      return;
    }

    await patchSequenceUpdate(sequenceId, {}, payload);
    setIsSaving(false);
    navigate(`/apps/${pipelineConfigId}/sequences`);
  };

  const doDeleteSequence = async () => {
    setIsSaving(true);
    await deleteSequence(sequenceId);
    navigate(`/apps/${pipelineConfigId}/sequences`);
    setIsSaving(false);
  };

  const onChangeSelectedStep = (path, value) => {
    const newSequence = cloneDeep(sequence);
    const newStep = newSequence?.steps?.find(
      step => step?.id === selectedStepId
    );
    set(newStep, path, value);
    setSequence(newSequence);
  };

  const emailColumns =
    pipelineConfig?.sourceTables?.[0]?.columns?.filter(
      column => column?.type === "EMAIL"
    ) || [];

  const renderInputComponent = field => {
    if (field?.path === "function.recipients") {
      return (
        <>
          <BoldDiv>{field.label}</BoldDiv>
          <Gap height="8px" />
          <Select
            value={get(selectedStep, field.path)?.[0]}
            onChange={e => {
              const newRecipients = [e.target.value];
              onChangeSelectedStep(field.path, newRecipients);
            }}
          >
            <option value="">-</option>
            {emailColumns?.map(column => (
              <option value={column?.name}>
                {column?.name?.replaceAll("_", " / ")}
              </option>
            ))}
          </Select>
          <Gap height="20px" />
        </>
      );
    }

    return (
      <>
        <BoldDiv>{field.label}</BoldDiv>
        <Gap height="8px" />
        <FieldInput
          value={get(selectedStep, field.path)}
          onChangeValue={value => onChangeSelectedStep(field.path, value)}
          type={field.type}
          options={field.options}
        />
        <Gap height="20px" />
      </>
    );
  };

  const outputRecordsMostRecentFirst = outputRecords?.sort(
    (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
  );

  return (
    <LayoutApp>
      <Container>
        <TopContent>
          <StyledInput
            style={{ width: "400px", fontSize: 24, fontWeight: 600 }}
            value={sequence.name}
            onChange={e => setSequence({ ...sequence, name: e.target.value })}
            placeholder="Sequence Name"
          />
          <Gap height="20px" />
          <BoldDiv>Previous Executions</BoldDiv>
          <Gap height="8px" />
          <TableContainer>
            <table style={{ width: "100%" }}>
              <thead>
                <Tr>
                  {EXECUTION_COLUMNS.map(column => (
                    <Th key={column.name}>{column.label}</Th>
                  ))}
                </Tr>
              </thead>
              <tbody>
                {outputRecordsMostRecentFirst?.length === 0 && (
                  <EmptyMsg>No records</EmptyMsg>
                )}
                {outputRecordsMostRecentFirst?.length > 0 &&
                  range(0, 4).map(i => {
                    const record = outputRecordsMostRecentFirst?.[i];

                    if (!record) {
                      return (
                        <Tr key={i}>
                          {EXECUTION_COLUMNS.map(() => (
                            <Td />
                          ))}
                        </Tr>
                      );
                    }

                    return (
                      <Tr key={`${record?.id}-${i}`}>
                        {EXECUTION_COLUMNS.map(column => {
                          let cellValue = record?.fields?.[column.name]?.value;
                          if (column?.name === "Execution Time") {
                            cellValue = safeFormat(cellValue, "d MMM HH:mm:ss");
                          }
                          if (column?.name === "Step Name") {
                            cellValue = getStep(record, sequence)?.name || "";
                          }
                          if (column?.name === "Output") {
                            cellValue = <StepOutput outputRecord={record} />;
                          }
                          if (column?.name === "Step Type") {
                            cellValue = (
                              <ColoredDiv>
                                {getStep(record, sequence)?.type || ""}
                              </ColoredDiv>
                            );
                          }

                          return <Td key={column.name}>{cellValue}</Td>;
                        })}
                      </Tr>
                    );
                  })}
              </tbody>
            </table>
          </TableContainer>
        </TopContent>
        <TwoColumns>
          <Panel style={{ borderRight: "1px solid #ccc" }}>
            <Title>Design</Title>
            <GridDraggableConnect
              style={{ width: "1400px", height: "1400px" }}
              initialLayout={layout}
              initialEdges={getEdgesFromSequence(sequence)}
              onDragEnd={newLayout => setLayout(newLayout)}
              onNewEdges={newEdges => {
                setSequence(prev => updateSequenceNexts(prev, newEdges));
              }}
              onNewKey={key => {
                setSequence(prev => ({
                  ...prev,
                  steps: [...prev.steps, { ...STEP_FIELDS, id: key }],
                }));
              }}
              onDeleteKey={key => {
                setSequence(prev => ({
                  ...prev,
                  steps: prev?.steps?.filter((step, i) => step?.id !== key),
                }));
              }}
            >
              {sequence?.steps?.map(step => (
                <StepCard
                  key={step?.id}
                  isSelected={selectedStepId === step?.id}
                  onClick={() => setSelectedStepId(step?.id)}
                >
                  <StepName>{step?.name}</StepName>
                  <Gap height="8px" />
                  <div>
                    <GreySpan>action: </GreySpan>
                    <SmallSpan>{step?.type}</SmallSpan>
                  </div>
                </StepCard>
              ))}
            </GridDraggableConnect>
          </Panel>

          <Panel>
            <Title>Configure step</Title>
            <FormFields>
              {selectedStepId === "step0" &&
                STEP_0_INPUT_FIELDS.map(renderInputComponent)}
              {selectedStepId !== "step0" &&
                STEP_INPUT_FIELDS.map(renderInputComponent)}
              {ACTION_TYPE_TO_INPUT_FIELDS[selectedStep?.type]?.map(
                renderInputComponent
              )}
            </FormFields>
          </Panel>
        </TwoColumns>

        <BottomContent>
          {sequenceId !== "new" && (
            <RunModal sequenceId={sequenceId} pipelineConfig={pipelineConfig} />
          )}
          {sequenceId !== "new" && (
            <ButtonWord disabled={isSaving} onClick={doDeleteSequence}>
              Delete
            </ButtonWord>
          )}
          <ButtonWord disabled={isSaving} onClick={doSaveSequence}>
            {sequenceId === "new" ? "Create" : "Save"}
          </ButtonWord>
        </BottomContent>
      </Container>
    </LayoutApp>
  );
};

export default SequencePage;
