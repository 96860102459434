import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const DEFAULT_PAGE_SIZE = 15;

const useDirectoryPagination = () => {
  const [nextOffset, setNextOffset] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const [searchParams] = useSearchParams();
  const prevOffsets =
    searchParams
      .get("prevOffsetsStr")
      ?.split(",")
      ?.filter(s => !!s) || [];
  const offset = searchParams.get("offset");
  const pageSize = searchParams.get("pageSize") || DEFAULT_PAGE_SIZE;
  const directoryPath = searchParams.get("directoryPath");

  const prevUrl = `?pageSize=${pageSize}&offset=${prevOffsets?.slice(
    -1
  )}&prevOffsetsStr=${[
    prevOffsets.slice(0, -1),
  ]}&directoryPath=${directoryPath}`;

  const nextUrl = `?pageSize=${pageSize}&offset=${nextOffset}&prevOffsetsStr=${[
    ...prevOffsets,
    offset,
  ]}&directoryPath=${directoryPath}`;

  return [
    pageSize,
    offset,
    prevUrl,
    nextUrl,
    hasMore,
    setNextOffset,
    setHasMore,
  ];
};

export default useDirectoryPagination;
