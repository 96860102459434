import { UploadFileIcon } from "components/ui/Icons";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
`;

const Input = styled.input`
  ${props => props.isHidden && "display: none;"}
  width: 150px;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 0px;
  padding-left: 10px;
  ::file-selector-button {
    display: none;
  }
  color: ${props => props.theme.color.closest};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: max-content;
`;

const ChooseFileFakeButton = styled.div`
  position: relative;
  font-size: 14px;
  padding: 10px 20px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  font-family: "Montserrat";
  transition: background-color 0.1s, color 0.1s;
  font-weight: 600;
  cursor: pointer;

  display: grid;
  grid-template-columns: ${props =>
    props?.isIconOnly ? "auto" : "20px auto 20px"};
  align-items: center;
  gap: 10px;

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  border: 2px solid ${props => props.theme.color.closer1};

  ${props =>
    props.variant === "highlighted" &&
    `
    border: 2px solid;
    border-image: linear-gradient(
      to right,
      ${props.theme.color.feature},
      ${props.theme.color.primary}
    ) 1;
  `};

  svg {
    fill: ${props => props.theme.color.closest};
  }

  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.primary};
    ${props =>
      props.variant === "highlighted" &&
      `background: linear-gradient(
      to right,
      ${props.theme.color.feature},
      ${props.theme.color.primary}
    );`}

    ${props =>
      props.variant !== "highlighted" &&
      `border: 2px solid ${props.theme.color.primary}`};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }
`;

const InputTitle = styled.div`
  color: ${props => props.theme.color.closest};
  font-weight: bold;
  padding-bottom: 10px;
`;

const ErrorText = styled.div`
  padding-top: 10px;
  color: ${props => props.theme.color.error};
`;

const CornerRect = styled.div`
  width: 2px;
  height: 2px;
  position: absolute;
  background-color: ${props => props.theme.color.primary};
`;

const FileInputMulti = ({
  onFilesSelect,
  title,
  fileName,
  buttonText = "Upload a file",
  isShowingFileChosenText = true,
  className,
  dataTutorialId,
  maxFileSizeBytes = 1e9,
  isDisabled,
  variant = "highlighted",
  accept = "*",
}) => {
  const inputRef = useRef();
  const [error, setError] = useState("");

  useEffect(() => {
    if (!fileName && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [fileName]);

  return (
    <Container data-tutorial-id="choose-file-button" isDisabled={isDisabled}>
      {title && <InputTitle>{title}</InputTitle>}
      <Label>
        <ChooseFileFakeButton
          className={className}
          isIconOnly={!buttonText}
          variant={variant}
        >
          <CornerRect style={{ top: "-2px", left: "-2px" }} />
          <CornerRect style={{ top: "-2px", right: "-2px" }} />
          <CornerRect style={{ bottom: "-2px", left: "-2px" }} />
          <CornerRect style={{ bottom: "-2px", right: "-2px" }} />

          <UploadFileIcon />
          {buttonText}
        </ChooseFileFakeButton>
        {onFilesSelect && (
          <Input
            ref={inputRef}
            data-tutorial-id={dataTutorialId}
            isHidden={!isShowingFileChosenText}
            type="file"
            accept={accept}
            multiple
            onChange={e => {
              const filesArray = [...e.target.files];
              setError("");
              if (filesArray?.some(file => file.size > maxFileSizeBytes)) {
                setError("Max file size is 1GB");
                inputRef.current.value = "";
                onFilesSelect(null);
                return;
              }
              onFilesSelect(filesArray);
            }}
          />
        )}
      </Label>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default FileInputMulti;
