import styled from "styled-components";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import { Link, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteAccountSubscriptionsForceCancel,
  getActiveSubscription,
  patchSubscription,
  postPaymentSession,
} from "api/services/projectService";
import ErrorMessageModal from "components/ErrorMessageModal";
import { CircularProgress } from "@material-ui/core";
import { SIDEBAR_ITEMS, SideBarItem } from "pages/profile-new";
import FileSideBar from "components/FileSideBar";
import { patchSubscriptionCancel } from "api/backend/projectServiceEndpoints";
import LayoutNew from "components/LayoutNew";
import { CrossIcon, TickIcon } from "components/ui/Icons";
import { Cross } from "recharts";
import { COLOR1, COLOR2 } from "pages/login-v2";
import ButtonWord from "components/ui/ButtonWord";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";

const PLAN_CARDS = [
  {
    type: "",
    title: "Free",
    description: "Basic chat and writing",
    price: 0,
    priceDescription: "Free forever",
    features: [
      "Chat and word doc",
      "Speed up to 40 tokens per second",
      "Boltzbit LLM and Gemini",
      "Scan single web page as source",
    ],
  },
  {
    type: "PERSONAL",
    title: "Pro",
    description: "Pro GenAI tooling",
    price: 19.9,
    priceDescription: "Per month, up to 10 users",
    features: [
      "Chat, word, excel, powerpoint",
      "Up to 80 tokens per second",
      "Boltzbit LLM, Gemini, ChatGPT",
      "Scan website as source",
    ],
  },
  {
    type: "TEAM",
    title: "Team",
    description: "SME GenAI suite",
    price: 50,
    priceDescription: "Per month, from 3 seats",
    features: [
      "Chat, word, excel, powerpoint",
      "Workflow automation apps",
      "Up to 160 tokens per second",
      "Dedicated single GPU server",
      "Collaboration",
      "Scan website with auto-downloading PDFs",
      "API integration",
    ],
  },
  {
    type: "ENTERPRISE",
    title: "Enterprise",
    description: "Customised enterprise solution",
    price: 599,
    priceDescription: "Usage based",
    features: [
      "All features of Teams",
      "Customised automation apps",
      "300 tokens per second",
      "Dedicated GPU cluster",
      "On-prem deployment",
      "Cloud integration",
      "Database integration",
    ],
    buttonText: "Contact sales",
  },
];

const BLACK_CARD_INDEX = 2;

const PLAN_FIELDS = [
  "Access full to AI capabilities",
  "Users",
  "Priority",
  "Support",
  "Report Generation",
  "Pricing",
];

const ALL_TRUE_PLAN = {};
PLAN_FIELDS.forEach(field => {
  ALL_TRUE_PLAN[field] = true;
});

const PLANS = [
  {
    type: "PERSONAL",
    ...ALL_TRUE_PLAN,
    Support: false,
    "Report Generation": false,
    Pricing: false,
  },
  {
    type: "TEAM",
    ...ALL_TRUE_PLAN,
    "Report Generation": false,
    Pricing: false,
  },
  {
    type: "ENTERPRISE",
    ...ALL_TRUE_PLAN,
  },
];

const PlanTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

const Features = styled.div`
  display: grid;
  gap: 8px;
  padding-top: 12px;
  border-top: 1px solid #e8ecef;
  /* height: 240px; */
  align-content: start;
`;

const LIP = 16;
const PlanCard = styled.div`
  position: relative;
  background-color: white;
  padding: 28px;
  border-right: 1px solid #f5f5f5;
  display: grid;
  grid-template-rows: repeat(5, auto) 1fr;
  gap: 12px;
  align-content: start;
  /* height: 444px; */
  * {
    svg {
      fill: grey;
    }
  }

  ${props =>
    props.isBlack &&
    `
    background-color: #232627;
    top: -${LIP}px;
    height: calc(100% + ${2 * LIP}px);
    padding-top: calc(28px + ${LIP}px);
    padding-bottom: calc(28px + ${LIP}px);
    border-radius: 20px;

    * {
      color: white;
      svg {
        fill: grey;
      }
    }

    ${PlanTitle} {
      color: ${COLOR1};
    }
    ${Features} {
      border-top: 1px solid #343839;
    }
  `}

  ${props =>
    props?.isCurrent &&
    !props?.isBlack &&
    `
    background-color: #ffffff88;
  `}
`;

const PlanCards = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  ${PlanCard}:nth-child(1) {
    border-radius: 20px 0 0 20px;
  }
  // last
  ${PlanCard}:nth-child(4) {
    border-radius: 0 20px 20px 0;
    border-right: none;
  }
`;

const PlanDescriptionItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  height: 2.4em;
`;

const PlanPrice = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const PlanPriceDescription = styled.div`
  color: #6c7275;
  line-height: 1.2;
`;

const FeatureItem = styled.div`
  font-size: 14px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 10px;
  line-height: 1.2;

  svg {
    fill: ${props => props?.theme?.color?.primary};
  }
`;

const GreySpan = styled.span`
  font-size: 14px;
  color: #6c7275;
`;

const Container = styled.div`
  display: grid;
  align-content: start;
  padding: 80px 120px;
  min-height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%);
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 700;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: #6c7275;
  text-align: center;
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const ProfileContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px;
  gap: 50px;
  background-color: #ffffff;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const Table = styled.table`
  max-width: 900px;
`;

const Td = styled.td`
  border-top: 1px solid #6c727526;
  border-bottom: 1px solid #6c727526;
  vertical-align: middle;
  padding: 22px 4px;
  font-size: 14px;
  ${props => props.planType === "Free" && "background-color: #D9D9D988;"}
  ${props => props.planType === "Enterprise" && "background-color: #D9D9D9;"}
  width: 300px;
`;

const Th = styled.th`
  vertical-align: middle;
  padding: 22px 4px;
  font-size: 20px;
  ${props => props.planType === "Free" && "background-color: #D9D9D988;"}
  ${props => props.planType === "Enterprise" && "background-color: #D9D9D9;"}
`;

// const Button = styled.button`
//   outline: none;
//   border: none;
//   padding: 4px 12px;
//   cursor: pointer;
//   background-color: ${props =>
//     props.variant === "transparent" ? "transparent" : "#e0e0e0"};
//   font-family: "Montserrat";
//   border-radius: 4px;
//   font-weight: 500;
//   transition: background-color 0.2s;
//   pointer-events: ${props => (props.disabled ? "none" : "auto")};
//   :hover {
//     background-color: #d0d0d0;
//   }
// `;

const Button = styled(ButtonWord)`
  background-color: transparent;
  color: black;
  border: 1px solid #e8ecef;
  height: 42px;
  :hover {
    color: #959595;
  }

  :disabled {
    pointer-events: none;
    background: #efefef;
    color: grey;
  }

  ${props =>
    props?.isPrimary &&
    `
    border: none;
    :hover {
      color: #eaeaea;
    }
  `}
`;

const SUCCESS_URL = `${window.location.origin}/profile/plan`;
const CANCEL_URL = `${window.location.origin}/profile/plan`;

const ProfilePagePlan = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateActiveSubscription();
  }, []);

  const doPopulateActiveSubscription = async () => {
    setIsLoading(true);
    const { data } = await getActiveSubscription();
    setIsLoading(false);
    setActiveSubscription(data);
  };

  const doPostPaymentSession = async plan => {
    const { data, error } = await postPaymentSession(
      {},
      {
        plan,
        successUrl: SUCCESS_URL,
        cancelUrl: CANCEL_URL,
      }
    );
    if (error) {
      setError(error);
      return;
    }

    window.location = data.paymentUrl;
  };

  const doCancelSubscription = async () => {
    const { error: cancelErr } = await patchSubscriptionCancel(
      activeSubscription?.id || ""
    );
    setError(cancelErr);
    doPopulateActiveSubscription();
  };

  const rowHeaders = PLAN_FIELDS;
  let userPlanType = activeSubscription?.plan;
  if (!userPlanType) {
    userPlanType = "";
  }

  if (isLoading) {
    return (
      <LayoutNew>
        <Container style={{ display: "grid", justifyContent: "center" }}>
          <CircularProgress
            thickness={10}
            size={40}
            style={{ marginTop: "20px", color: "#0191ff" }}
          />
        </Container>
      </LayoutNew>
    );
  }

  return (
    <LayoutNew>
      <Container>
        <Title>Find your favorite plan</Title>
        <Gap />
        <SubTitle>Pricing Plans for every budget</SubTitle>
        <Gap height="40px" />
        <PlanCards>
          {PLAN_CARDS.map((plan, index) => {
            let planPricingCmp = (
              <PlanPrice>
                £{plan?.price} <GreySpan>/ mo</GreySpan>
              </PlanPrice>
            );
            if (plan?.type === "PERSONAL") {
              planPricingCmp = (
                <PlanPrice>
                  £{plan?.price?.toFixed(2)} <GreySpan>/ mo</GreySpan>
                </PlanPrice>
              );
            }
            if (plan?.type === "ENTERPRISE") {
              planPricingCmp = (
                <PlanPrice
                  style={{
                    fontSize: 20,
                    paddingTop: 12,
                  }}
                >
                  Special pricing
                </PlanPrice>
              );
            }

            let ctaCmp = (
              <ConfirmationModalTrigger
                style={{ alignSelf: "end" }}
                isDisabled={plan?.type === userPlanType}
                message={`Are you sure you want to select the "${plan?.title}" plan?`}
                icon={
                  <Button
                    style={{ width: "100%" }}
                    isPrimary={index === BLACK_CARD_INDEX}
                    disabled={plan?.type === userPlanType}
                  >
                    {plan?.type === userPlanType
                      ? "Current Plan"
                      : plan?.buttonText || "Select"}
                  </Button>
                }
                onClickYes={() => {
                  if (!plan?.type) {
                    doCancelSubscription();
                    return;
                  }

                  doPostPaymentSession(plan.type);
                }}
              />
            );
            if (plan?.type === "ENTERPRISE") {
              ctaCmp = null;
            }

            return (
              <PlanCard
                key={index}
                isBlack={index === BLACK_CARD_INDEX}
                isCurrent={plan?.type === userPlanType}
              >
                <PlanTitle>{plan.title}</PlanTitle>
                <PlanDescriptionItem>{plan.description}</PlanDescriptionItem>
                {planPricingCmp}
                <PlanPriceDescription>
                  {plan.priceDescription}
                </PlanPriceDescription>
                <Features>
                  {plan.features.map((feature, index) => (
                    <FeatureItem key={index}>
                      <TickIcon />
                      {feature}
                    </FeatureItem>
                  ))}
                </Features>
                {ctaCmp}
              </PlanCard>
            );
          })}
        </PlanCards>
        <Gap height="40px" />
        {/* <SectionTitle>Core features</SectionTitle>
        <Gap height="30px" /> */}
        {/* <Table>
          <tbody>
            {rowHeaders.map((header, index) => (
              <tr key={index}>
                <Td
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                  }}
                >
                  {header}
                </Td>
                {PLANS.map((plan, index) => (
                  <Td
                    planType={plan.type}
                    style={{
                      textAlign: "center",
                    }}
                    key={index}
                  >
                    {plan[header] ? <TickIcon /> : <CrossIcon />}
                  </Td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table> */}
        <Gap height="40px" />
        <div
          style={{
            gridColumn: 1,
            justifySelf: "start",
            marginBottom: "40px",
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <Button
            disabled={activeSubscription?.cancelOn || !activeSubscription?.id}
            onClick={doCancelSubscription}
          >
            Cancel
          </Button>
          {activeSubscription?.cancelOn ? (
            <div style={{ fontWeight: 500 }}>
              Your plan will stop on {activeSubscription?.cancelOn}
            </div>
          ) : (
            <div />
          )}
          {searchParams?.get("showReset") === "true" && (
            <button
              disabled={!activeSubscription?.id}
              onClick={async () => {
                await deleteAccountSubscriptionsForceCancel(
                  activeSubscription?.id
                );
                window.location.reload();
              }}
            >
              Reset
            </button>
          )}
        </div>
      </Container>
      <ErrorMessageModal
        message={error?.message}
        onDismiss={() => setError(null)}
      />
    </LayoutNew>
  );
};

export default ProfilePagePlan;
