import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { cloneDeep, last } from "lodash";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { uuidv4 } from "utils/common";
import {
  postWordDoc,
  postWordDocsIdSaveAsTemplate,
} from "api/services/wordDocsService";
import { COLUMN_TYPES } from "components/DashboardColumnModalTrigger";
import {
  postPipelineConfigs,
  postSigmas,
} from "api/backend/fileSystemEndpoints";
import { PieChartSharp } from "@material-ui/icons";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  gap: 15px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 32px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
  z-index: 1;

  position: sticky;
  top: 0px;
`;

const TableContainer = styled.div`
  min-width: 800px;
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 120px;
  padding: 0;
`;

const StyledInput = styled(StyledBoldInput)`
  font-weight: 400;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const INITIAL_COLUMNS = [
  {
    name: "Source Name",
    description: "Where the record comes from",
    type: "TEXT",
  },
  {
    name: "Inserted Time",
    description: "When the the record was extracted",
    type: "DATETIME",
  },
];

const INITIAL_COLUMN_NAMES = INITIAL_COLUMNS.map(column => column.name);

const AddSigmaRecordModal = ({
  trigger = <button>+</button>,
  pipelineConfig = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pipelineName, setPipelineName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [record, setRecord] = useState({
    fields: {},
  });

  const columns =
    pipelineConfig?.config?.components?.find(c => c?.id === "source_records")
      ?.columns || [];

  return (
    <>
      <div onClick={() => setIsOpen(true)}>
        {trigger || <ButtonCreateNewAsset />}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          Fill in values
          <TableContainer>
            <table>
              <tr>
                <Th>Column Name</Th>
                <Th>Value</Th>
              </tr>
              {columns?.map((column, i) => (
                <tr>
                  <Td key={i}>
                    <StyledBoldInput
                      style={{
                        pointerEvents: INITIAL_COLUMN_NAMES?.includes(
                          column?.name
                        )
                          ? "none"
                          : "all",
                      }}
                      value={column?.name}
                      placeholder="Column Name"
                      disabled
                    />
                  </Td>
                  <Td>
                    <StyledInput
                      style={{
                        width: "100%",
                        pointerEvents: INITIAL_COLUMN_NAMES?.includes(
                          column?.name
                        )
                          ? "none"
                          : "all",
                      }}
                      value={record?.fields?.[column?.name]?.value || ""}
                      onChange={e => {
                        setRecord({
                          ...record,
                          fields: {
                            ...record?.fields,
                            [column?.name]: {
                              value: e.target.value,
                            },
                          },
                        });
                      }}
                      placeholder="Value"
                    />
                  </Td>
                </tr>
              ))}
              <tr>
                <Td style={{ border: "none" }}></Td>
              </tr>
            </table>
          </TableContainer>
          <Gap />
          <StyledButton
            variant="highlighted"
            value="Create"
            onClick={async () => {
              await postSigmas(
                {},
                { pipelineId: pipelineConfig?.id, ...record }
              );
              setIsOpen(false);
            }}
          />
          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddSigmaRecordModal;
