import qs from "qs";
import axios from "axios";
import { errorTypes } from "api/error-handling";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";
import { last } from "lodash";

const EXPIRED_TEST_TOKEN = `eyJraWQiOiJiYjUxN2QzOS01NmZkLTRiZWYtYTNhYi00YmY1ZmVmYTMwNWUiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkZW1vLXVzZXIiLCJpc3MiOiJCb2x0emJpdCBMdGQiLCJleHAiOjE3MjAwOTAwOTEsImlhdCI6MTcxOTQ4NTI5MSwiYXV0aG9yaXRpZXMiOlsiUk9MRV9BRE1JTiJdfQ.FTAnx9qPTeIZFW7-mkpllBqHJIqxmiCPxW_XWGFdd5dVxGtGtwUsnus5oJ0w6e4dbe2TmydvQcV-3gJDwkPEnoGNSNzK_7DOqYcoY8qt6mrvNH7DmaHKmclsQNl8vZdD-xSa_R1EpSrLr3PqvLghwuypCaV7QwNytf879ZePhr9cj6TjrhqE8bShw567J32YXHQTbHH41ilnA9w9X-Q-eNohb6mUt7U66ON05Xo-db6gdZ4eMLQbN-6YmUbxrz8ygI-flYudG9sZ89o5O6PIjD2oHqGPQXB_08nmQ31Czyw-UQO6mb4OcnUFph1IxNil4JmMbd3zZz8NjgelYvlyJzuRhOoeUzcrqtqLMr1Dae0jK7lNsDtDVPM0Ppx8G3h4i_FyWZrFWlBS5nhhwpm3xiiJr_BXwhng_q7jfnWNfPuMNDQx1VwKvw8SjRYRUpKclx5BOqnKjX0DqbQxKhPOWlmIqCMGO0971_kFbyg6oS3HK_qKDqKvIsxL11J7X0yfz_dnBt1EgNRr7ngIcsPKTyJEDuwGY7vj7WuAHBupWYnKSfnj5r1k0AJlBShCULhNhLUNu4S3SUN_TJdXBHw76M48qBNqF9tll4L8-jHee4QoyAqKZkEP9eo0p7nkFuFRGsOhjzAh7w5K4_A__5Ryp5lYa1MaC0TkDt2KzVYl9y4`;

const getHeaders = () => ({
  Authorization: "Bearer " + getLoginTokenFromClientStorage(),
  // Authorization: `Bearer ${EXPIRED_TEST_TOKEN}`,
});

const axiosInstance = axios.create({
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "comma", encode: false });
  },
});

export const apiGetRawResponse = async (url, params = {}) => {
  try {
    const queryParameters = Object.entries(params).map(
      ([key, value]) => `${key}=${value}`
    );
    const queryParameterString = queryParameters.join("&");
    const res = await fetch(`${url}?${queryParameterString}`, {
      headers: getHeaders(),
    });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const apiPostRawResponse = async (url, params = {}, body) => {
  try {
    const queryParameters = Object.entries(params).map(
      ([key, value]) => `${key}=${value}`
    );
    const queryParameterString = queryParameters.join("&");
    const res = await fetch(`${url}?${queryParameterString}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { ...getHeaders(), "Content-Type": "application/json" },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const apiGet = async (url, params = {}, abortController = {}) => {
  try {
    const res = await axiosInstance.get(`${url}`, {
      params,
      headers: getHeaders(),
      signal: abortController?.signal,
    });

    if (res?.data?.code === "FAILED") {
      return {
        data: null,
        error: { type: errorTypes.GENERIC, message: res?.data?.message },
      };
    }

    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPost = async (
  url,
  params = {},
  body = {},
  abortController = {}
) => {
  try {
    const res = await axiosInstance.post(`${url}`, body, {
      params,
      headers: getHeaders(),
      signal: abortController?.signal,
    });

    if (res?.data?.code === "FAILED") {
      return {
        data: null,
        error: { type: errorTypes.GENERIC, message: res?.data?.message },
      };
    }

    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPatch = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.patch(`${url}`, body, {
      params,
      headers: getHeaders(),
    });

    if (res?.data?.code === "FAILED") {
      return {
        data: null,
        error: { type: errorTypes.GENERIC, message: res?.data?.message },
      };
    }

    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.status === 404) {
      return {
        data: null,
        error: { type: errorTypes.NOT_FOUND, message: url },
      };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiDelete = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.delete(`${url}`, {
      params,
      headers: getHeaders(),
      data: body,
    });

    if (res?.data?.code === "FAILED") {
      return {
        data: null,
        error: { type: errorTypes.GENERIC, message: res?.data?.message },
      };
    }

    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPut = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.put(`${url}`, body, {
      params,
      headers: getHeaders(),
    });

    if (res?.data?.code === "FAILED") {
      return {
        data: null,
        error: { type: errorTypes.GENERIC, message: res?.data?.message },
      };
    }

    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPostFile = async (
  url,
  params,
  file,
  onUploadProgress,
  fileParamName = "file"
) => {
  try {
    let body = new FormData();
    body.append(fileParamName, file);
    return await axiosInstance.post(url, body, {
      params,
      headers: getHeaders(),
      onUploadProgress,
    });
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.status === 413) {
      return { data: null, error: { type: errorTypes.PAYLOAD_TOO_LARGE } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPostFormDataGetBlob = async (
  url,
  params,
  formDataBody = {},
  onUploadProgress = () => {}
) => {
  try {
    let body = new FormData();
    Object.entries(formDataBody).forEach(([key, value]) => {
      body.append(key, value);
    });

    return await axiosInstance.post(url, body, {
      params,
      headers: getHeaders(),
      onUploadProgress,
      responseType: "blob",
    });
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.status === 413) {
      return { data: null, error: { type: errorTypes.PAYLOAD_TOO_LARGE } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const postAndStreamResponse = async ({
  url = "",
  reqBody = {},
  abortController = {},
  onDataReceived = jsonData => {},
}) => {
  const res = await fetch(url, {
    method: "POST",
    signal: abortController.signal,
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      Authorization: `Bearer ${getLoginTokenFromClientStorage()}`,
    },
    body: JSON.stringify({
      ...reqBody,
      language: localStorage.getItem("language") || "EN",
      model: localStorage.getItem("model") || "llama3",
    }),
  });

  if (!res.ok || !res.body) {
    return { error: { message: "Server error" } };
  }

  try {
    const reader = res.body.getReader();
    const decoder = new TextDecoder();
    let jsonStrBuffer = "";

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunkString = decoder.decode(value, { stream: true });
      jsonStrBuffer += chunkString;

      let eventEnd = jsonStrBuffer.indexOf("\n\n");
      while (eventEnd !== -1) {
        const eventString = jsonStrBuffer.slice(0, eventEnd).trim();
        jsonStrBuffer = jsonStrBuffer.slice(eventEnd + 2);

        if (eventString.startsWith("data: ")) {
          const dataString = eventString.slice(6); // Remove the "data: " prefix
          try {
            const jsonData = JSON.parse(dataString);
            onDataReceived(jsonData);
          } catch (e) {
            console.error("Failed to parse JSON:", e);
          }
        }

        eventEnd = jsonStrBuffer.indexOf("\n\n");
      }
    }

    // Process any remaining data in the buffer
    if (jsonStrBuffer.trim()) {
      const remainingEvents = jsonStrBuffer.split("\n\n");
      remainingEvents.forEach(eventString => {
        if (eventString.startsWith("data: ")) {
          const dataString = eventString.slice(6); // Remove the "data: " prefix
          try {
            const jsonData = JSON.parse(dataString);
            onDataReceived(jsonData);
          } catch (e) {
            console.error("Failed to parse JSON:", e);
          }
        }
      });
    }
  } catch (e) {
    return { error: e };
  }

  return { error: null };
};

export const postAndStreamResponseCompare = async ({
  url = "",
  reqBody = {},
  abortController = {},
  onDataReceived = jsonData => {},
}) => {
  const res = await fetch(url, {
    method: "POST",
    signal: abortController.signal,
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      Authorization: `Bearer ${getLoginTokenFromClientStorage()}`,
    },
    body: JSON.stringify(reqBody),
  });

  if (!res.ok || !res.body) {
    return { error: { message: "Server error" } };
  }

  try {
    const reader = res.body.getReader();
    const decoder = new TextDecoder();
    let jsonStrBuffer = "";

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunkString = decoder.decode(value, { stream: true });
      jsonStrBuffer += chunkString;

      let eventEnd = jsonStrBuffer.indexOf("\n\n");
      while (eventEnd !== -1) {
        const eventString = jsonStrBuffer.slice(0, eventEnd).trim();
        jsonStrBuffer = jsonStrBuffer.slice(eventEnd + 2);

        if (eventString.startsWith("data: ")) {
          const dataString = eventString.slice(6); // Remove the "data: " prefix
          try {
            const jsonData = JSON.parse(dataString);
            onDataReceived(jsonData);
          } catch (e) {
            console.error("Failed to parse JSON:", e);
          }
        }

        eventEnd = jsonStrBuffer.indexOf("\n\n");
      }
    }

    // Process any remaining data in the buffer
    if (jsonStrBuffer.trim()) {
      const remainingEvents = jsonStrBuffer.split("\n\n");
      remainingEvents.forEach(eventString => {
        if (eventString.startsWith("data: ")) {
          const dataString = eventString.slice(6); // Remove the "data: " prefix
          try {
            const jsonData = JSON.parse(dataString);
            onDataReceived(jsonData);
          } catch (e) {
            console.error("Failed to parse JSON:", e);
          }
        }
      });
    }
  } catch (e) {
    return { error: e };
  }

  return { error: null };
};

export const postAndStreamResponseOld = async ({
  url = "",
  reqBody = {},
  abortController = {},
  onDataReceived = jsonData => {},
}) => {
  const res = await fetch(url, {
    method: "POST",
    signal: abortController.signal,
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      Authorization: `Bearer ${getLoginTokenFromClientStorage()}`,
    },
    body: JSON.stringify(reqBody),
  });

  if (!res.ok || !res.body) {
    return { error: { message: "Server error" } };
  }

  try {
    const resBody = await res.body;
    const reader = resBody.getReader();
    const decoder = new TextDecoder();

    let jsonStrBuffer = "";

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const chunkString = decoder.decode(value, { stream: true }) || "";
      jsonStrBuffer += chunkString;

      try {
        const messages = jsonStrBuffer?.split('data: {"cursor": {');
        const latestStr = `{"cursor": {${last(messages)}`;
        const resultJson = JSON.parse(latestStr);
        jsonStrBuffer = "";
        onDataReceived(resultJson);
      } catch (e) {
        // console.log({ jsonStrBuffer });
      }
    }
  } catch (e) {
    return { error: e };
  }

  return { error: null };
};
