import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const SuggestionsContainer = styled.div`
  position: fixed;
  overflow-y: hidden;
  overflow-x: auto;

  display: grid;
  grid-auto-flow: row;
  /* border: 1px solid ${props => props.theme.color.primary}; */
  border-top: none;
  border-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  max-height: 400px;
  overflow: auto;
  box-shadow: 0px 0px 5px #b7b7b7;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Suggestion = styled.div`
  padding: 14px;
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.2;
  display: flex;
  align-items: center;
  background-color: white;

  :hover {
    background-color: #eaeaea;
  }
`;

const EmptySuggestion = styled.div`
  background-color: transparent;
  pointer-events: none;
`;

const DividerLineHorizontal = styled.div`
  height: 1px;
  background-color: #000000;
  opacity: 0.5;
  margin: 0 auto;
`;

const SUGGESTIONS = [
  "how to cook egg fried rice",
  "what is the loan amount in my email?",
  "write an essay about this report",
  "whats the top performing bond in this portfolio?",
];

const InputWithBottomSuggestions = ({
  className,
  value,
  onChange = e => {},
  placeholder,
  disabled,
  onKeyDown = e => {},
  onClickSuggestion = text => {},
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isFocussed, setIsFocussed] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (disabled) {
      setIsFocussed(false);
    }
  }, [disabled]);

  useEffect(() => {
    doPopulateSuggestions();
  }, []);

  const doPopulateSuggestions = async () => {
    setSuggestions(SUGGESTIONS || []);
  };

  const rect = ref.current?.getBoundingClientRect();

  return (
    <>
      <input
        className={className}
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={e => onKeyDown(e)}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
      />
      <SuggestionsContainer
        style={{
          display: isFocussed ? "grid" : "none",
          top: rect?.top,
          left: rect?.left,
          width: rect?.width,
        }}
      >
        <EmptySuggestion
          style={{
            height: rect?.height,
            width: rect?.width,
            borderBottom: "1px solid #535353",
          }}
        />
        <DividerLineHorizontal style={{ width: rect?.width - 28 }} />
        {suggestions.map(suggestion => {
          return (
            <Suggestion
              key={suggestion}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
                onClickSuggestion(suggestion);
              }}
            >
              {suggestion}
            </Suggestion>
          );
        })}
      </SuggestionsContainer>
    </>
  );
};

export default InputWithBottomSuggestions;
