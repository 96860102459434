import { MoonIcon } from "components/ui/Icons";
import styled from "styled-components";

const Container = styled.div`
  width: 48px;
  height: 25px;
  border-radius: 25px;
  background-color: ${props => props.theme.color.closest};
  display: flex;
  align-items: center;
  padding: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
`;

const InnerIcon = styled(MoonIcon)`
  height: 18px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 10px;
  padding: 2px;
  transform: translateX(${props => (props.isOnLeft ? 0 : "22px")});
  transition: transform 0.2s;
  fill: ${props => props.theme.color.closest};
`;

const ThemeSwitch = ({ isOnLeft, onSwitch, className }) => (
  <Container onClick={onSwitch} className={className}>
    <InnerIcon isOnLeft={isOnLeft} />
  </Container>
);

export default ThemeSwitch;
