import { useState, useEffect } from "react";
import styled from "styled-components";
import { parseJson } from "utils/common";
import { useSearchParams } from "react-router-dom";
import { Delete, Save } from "@material-ui/icons";

import { getTextSegmentConfig } from "utils/text-annotate-utils";
import {
  deleteEntity,
  getEntitesWithContext,
  postEntities,
} from "api/services/searchService";
import AnnoSpan from "components/AnnoSpan";
import AddLabelModalTrigger from "components/widgets/AddLabelModalTrigger";
import FileRerunButtonsAndProgress from "components/RerunButtonsAndProgress";
import SolrRecordEditorSimple from "components/widgets/SolrRecordEditorSimple";
import Tooltip from "components/ui/Tooltip";

const Container = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const TextContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  white-space: break-spaces;
`;

const ToolbarContainer = styled.div`
  padding: 0 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  height: 48px;
  z-index: 90;

  display: grid;
  align-items: center;
  grid-auto-flow: column;
`;

const AreaTypeContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledSelect = styled.select`
  border: 2px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  margin-right: 5px;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const IconButton = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const BottomLeftAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const TextOutputView = ({
  areaType,
  textContent,
  pageResultsAnnotations = [],
  fileId = "",
  fileProgress = 0,
  onNewAreaTypeSelected = () => {},
  onPressSaveAreaType = () => {},
  isPatchingAnnotations,
  onPressDeleteArea = () => {},
  isSaveButtonDisabled,
}) => {
  const [searchParams] = useSearchParams();
  const solrId = searchParams?.get("solrId");

  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState(null);
  const [selectedStr, setSelectedStr] = useState("");

  useEffect(() => {
    doPopulateEntities();
  }, [textContent]);

  const doPopulateEntities = async () => {
    setIsLoading(true);
    const { data, error } = await getEntitesWithContext({
      fileId,
      context: textContent,
    });
    setError(error);
    setEntities(data);
    setIsLoading(false);
  };

  const doDeleteEntity = async entityId => {
    // setIsLoading(true);
    // const { error } = await deleteEntity(entityId);
    // if (error) {
    //   setError(error);
    //   return;
    // }
    // const { data } = await getEntitesWithContext({
    //   fileId,
    //   context: textContent,
    // });
    // setEntities(data);
    // setIsLoading(false);
  };

  const doPostEntity = async newEntity => {
    // setIsLoading(true);
    // const { error: postErr } = await postEntities([newEntity]);
    // if (postErr) {
    //   setError(postErr);
    //   alert(JSON.stringify(postErr));
    //   return;
    // }
    // const { data, error } = await getEntitesWithContext({
    //   fileId,
    //   context: textContent,
    // });
    // setError(error);
    // setEntities(data);
    // setIsLoading(false);
  };

  const entitesAnnotations =
    entities?.map(entity => ({
      ...parseJson(parseJson(entity?.value?.[0])?.annotation),
      entityId: entity?.id,
    })) || [];
  const segments = getTextSegmentConfig(textContent, [
    ...pageResultsAnnotations,
    ...entitesAnnotations,
  ]);

  return (
    <Container isDisabled={isLoading}>
      <ToolbarContainer>
        <AddLabelModalTrigger
          isDisabled={!selectedStr}
          selectedStr={selectedStr}
          onPartialAnnotationAdded={({ content, labelProperties }) => {
            const fullAnnotation = {
              pos: textContent?.indexOf(content),
              span: content?.length,
              content,
              labelProperties,
            };

            doPostEntity({
              type: "TEXT_ANNOTATION",
              fileId,
              value: JSON.stringify({
                context: textContent,
                annotation: fullAnnotation,
              }),
            });
          }}
        />
        {/* {error && <div>{JSON.stringify(error)}</div>} */}
        <FileRerunButtonsAndProgress progress={fileProgress} />
      </ToolbarContainer>
      {solrId && <SolrRecordEditorSimple solrId={solrId} />}
      <AreaTypeContainer isDisabled={isPatchingAnnotations}>
        Area type:&nbsp;
        <StyledSelect
          value={areaType}
          onChange={e => onNewAreaTypeSelected(e.target.value)}
        >
          {[
            "-",
            "text",
            "table",
            "infograph",
            "image",
            "title",
            "sidebar",
            "note",
            "list",
            "row",
            "column",
          ]?.map(areaType => (
            <option key={areaType}>{areaType}</option>
          ))}
        </StyledSelect>
        {/* <BottomLeftAlignedTooltip title="Save areas">
          <IconButton onClick={onPressSaveAreaType}>
            <Save />
          </IconButton>
        </BottomLeftAlignedTooltip>
        <BottomLeftAlignedTooltip title="Delete area">
          <IconButton onClick={onPressDeleteArea}>
            <Delete />
          </IconButton>
        </BottomLeftAlignedTooltip> */}
      </AreaTypeContainer>
      <TextContainer
        onMouseUp={() => setSelectedStr(getSelection().toString())}
      >
        {segments?.map((segment, i) => {
          if (segment?.annotation?.entityId) {
            return (
              <AnnoSpan
                annotation={segment?.annotation}
                key={i}
                onClickDelete={() =>
                  doDeleteEntity(segment?.annotation?.entityId)
                }
              >
                {segment?.content}
              </AnnoSpan>
            );
          }
          if (segment?.annotation) {
            return (
              <AnnoSpan annotation={segment?.annotation} key={i}>
                {segment?.content}
              </AnnoSpan>
            );
          }
          return segment?.content;
        })}
      </TextContainer>
    </Container>
  );
};

export default TextOutputView;
