import * as slidesBackend from "api/backend/slideDocsEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getSlideDoc = async (id, params = {}) => {
  const { data, error } = await slidesBackend.getSlideDoc(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const getSlideDocSlide = async (id, slideIndex, params = {}) => {
  const { data, error } = await slidesBackend.getSlideDocSlide(
    id,
    slideIndex,
    params
  );
  return { data, error: getHandlingFromError(error) };
};

export const patchSlideDoc = async (id, params = {}, body = {}) => {
  const { data, error } = await slidesBackend.patchSlideDoc(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postSlideDoc = async (params = {}, body = {}) => {
  const { data, error } = await slidesBackend.postSlideDoc(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteSlideDoc = async (id, params = {}) => {
  const { data, error } = await slidesBackend.deleteSlideDoc(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const postSlideDocsUpload = async (params = {}, body = {}) => {
  const { data, error } = await slidesBackend.postSlideDocsUpload(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getSlideDocsLoadImage = async (params = {}) => {
  const { data, error } = await slidesBackend.getSlideDocsLoadImage(params);
  return { data, error: getHandlingFromError(error) };
};

export const postSlideDocsUploadImage = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await slidesBackend.postSlideDocsUploadImage(
    id,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};
