import styled from "styled-components";

import CircularProgressWithLabel from "components/ui/CicularProgressWithLabel";

const ProgressIndicator = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  height: 10px;
`;

const Progress = ({ fileProgress }) => {
  if (fileProgress === 100) {
    return null;
  }

  return (
    <ProgressIndicator>
      <CircularProgressWithLabel value={fileProgress} />
    </ProgressIndicator>
  );
};

export default Progress;
