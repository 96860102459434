import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { merge, range } from "lodash";
import {
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Image,
  Link as LinkIcon,
  Redo,
  Save,
  Undo,
} from "@material-ui/icons";

import { CenteredWithTopNavLayoutNoSideBar } from "components/Layout";
import {
  getWordDoc,
  patchWordDoc,
  postWordDoc,
  postWordDocsIdSaveAsTemplate,
} from "api/services/wordDocsService";
import WordDoc from "components/WordDoc";
import {
  PAGE_WIDTH_PX,
  START_X,
  addStyleToBlocks,
  addToStyleFieldsToSelection,
  removeSelectionStyle,
} from "utils/word-utils";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import { postChatflowGenerate } from "api/services/searchService";
import SmallButton from "components/ui/SmallButton";
import { postWordDocIngestToBoltzhub } from "api/services/chatService";
import { BoltzhubLogoInner, SigmaIcon } from "components/ui/Icons";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import AddDocLabelModalTrigger from "components/AddDocLabelModalTrigger";
import { uuidv4 } from "utils/common";
import BlackTooltip from "components/ui/BlackTooltip";
import ErrorMessageModal from "components/ErrorMessageModal";

const StyledCenteredWithTopNavLayout = styled(
  CenteredWithTopNavLayoutNoSideBar
)`
  display: grid;
  align-items: start;

  background-color: ${props => props.theme.color.furthest};
`;

const Container = styled.div`
  display: grid;
  align-items: start;
  background-color: #f9fbfd;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const EditableDocNameInput = styled.input`
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;

  display: block;
  align-self: center;
  border: none;
  width: 300px;
  outline: none;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};
  align-self: center;
  position: sticky;
  z-index: 3;
  height; 67px;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};

  padding: 1px 8px;
  margin: 0px 16px;
  margin-bottom: 0px;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.closer0};

  position: sticky;
  z-index: 3;
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const PageBoundaryLine = styled.div`
  width: ${PAGE_WIDTH_PX}px;
  height: 2px;
  background-color: ${props => props.theme.color.primary};

  position: sticky;
  top: calc(125px + 30px + 4px);
  left: ${START_X}px;
  z-index: 0;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 5px;
`;

const ColoredIconButton = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }

    path:nth-child(2) {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ColoredIconButtonBucket = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path:nth-child(2) {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }
  }
`;

const StyledSmallButton = styled(SmallButton)`
  padding: 2px 4px;
  grid-auto-flow: column;
  gap: 4px;
`;

const NewTemplateFilePage = () => {
  const navigate = useNavigate();
  const [wordDoc, setWordDoc] = useState({ fileName: "Untitled " });
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingApproval, setIsSavingApproval] = useState(false);
  const [error, setError] = useState(null);

  const [blocks, setBlocks] = useState([
    { text: "", styles: [{ isSelection: true, start: 0, end: 0 }] },
  ]);

  const doPopulateBlocksFromChatflow = async (cursor, blocksToSend) => {
    setIsLoading(true);
    const { data, error } = await postChatflowGenerate(
      { max_new_tokens: 20 },
      {
        cursor,
        genContext: "sigma_tab",
        blocks: blocksToSend,
      }
    );

    if (!error && data?.code !== "FAILED") {
      const blocksWithoutSelection = removeSelectionStyle(data?.blocks);
      const blocksWithSelection = addStyleToBlocks({
        startBlockIndex: data?.cursor?.blockIndex || 0,
        startLetterIndex: data?.cursor?.letterIndex || 0,
        endBlockIndex: data?.cursor?.blockIndex || 0,
        endLetterIndex: data?.cursor?.letterIndex || 0,
        blocks: blocksWithoutSelection,
        styleFields: {
          isSelection: true,
        },
      });
      setBlocks(blocksWithSelection);
    }

    setIsLoading(false);
  };

  const doSaveDocAsTemplate = async () => {
    setIsLoading(true);
    const { data: newWordDoc, error: docCreateErr } = await postWordDoc(
      {},
      {
        ...wordDoc,
        fileName: wordDoc?.fileName?.trim(),
        content: { ...wordDoc?.content, blocks },
      }
    );
    if (docCreateErr) {
      setError(docCreateErr);
      setIsLoading(false);
      return;
    }
    const { data: newTemplateId, error: templateErr } =
      await postWordDocsIdSaveAsTemplate(newWordDoc?.id);
    if (templateErr) {
      setError(templateErr);
      setIsLoading(false);
      return;
    }
    navigate(`/template-dashboards/${newTemplateId}`);

    setIsLoading(false);
  };

  const doChangeApprovalBlocksAndSaveDoc = async newBlocks => {
    setIsSavingApproval(true);
    setBlocks(newBlocks || [{ text: "" }]);
    setIsSavingApproval(false);
  };

  const addToSelectionStyle = newStyleFields => {
    const newBlocks = addToStyleFieldsToSelection(blocks, newStyleFields);
    setBlocks(newBlocks);
  };

  const doPatchDocSources = async newSources => {
    setIsLoading(true);
    setWordDoc({
      ...wordDoc,
      content: { ...wordDoc?.content, sources: newSources },
    });
    setIsLoading(false);
  };

  return (
    <Container isDisabled={isLoading}>
      <DocNameAndSourcesModal>
        <Link to="/template-files">
          <SigmaIcon
            height="32px"
            style={{
              paddingLeft: "14px",
              paddingRight: "10px",
              fill: "#2b579a",
            }}
          />
        </Link>
        <EditableDocNameInput
          value={wordDoc?.fileName}
          onChange={e => {
            setWordDoc({
              ...wordDoc,
              fileName: e.target.value,
            });
          }}
        />
        <DocSourcesModalTrigger
          sources={wordDoc?.content?.sources}
          onChangeSources={newSources => {
            doPatchDocSources(newSources);
          }}
        />
      </DocNameAndSourcesModal>
      <ToolbarContainer>
        {/* {wordDoc?.content?.boltzhubDatasetId ? (
          <StyledSmallButton
            variant="highlighted"
            icon={<BoltzhubLogoInner height="14px" />}
            onClick={() =>
              (window.location = `https://boltzhub.com/data/${wordDoc?.content?.boltzhubDatasetId}`)
            }
            value="Dataset"
          />
        ) : (
          <StyledSmallButton
            variant="highlighted"
            icon={<BoltzhubLogoInner height="14px" />}
            onClick={doIngestDocToBoltzhub}
            value=""
          />
        )} */}
        <BlackTooltip title="Save as template">
          <IconButton onClick={doSaveDocAsTemplate}>
            <Save />
          </IconButton>
        </BlackTooltip>
        {/* <IconButton>
          <Undo />
        </IconButton>
        <IconButton>
          <Redo />
        </IconButton>
        <IconButton
          isActive={isSelectionBold}
          onClick={() => {
            addToSelectionStyle({
              fontWeight: isSelectionBold ? "normal" : "bold",
            });
          }}
        >
          <FormatBold />
        </IconButton>
        <IconButton
          isActive={isSelectionItalic}
          onClick={() => {
            addToSelectionStyle({
              fontStyle: isSelectionItalic ? "normal" : "italic",
            });
          }}
        >
          <FormatItalic />
        </IconButton>
        <IconButton>
          <FormatUnderlined />
        </IconButton>
        <ColorPickerTooltip
          selectedColor={selectionFontColor}
          onNewColor={newFontColor => {
            addToSelectionStyle({
              fontColor: newFontColor,
            });
          }}
          triggerIcon={
            <ColoredIconButton color={selectionFontColor}>
              <FormatColorText />
            </ColoredIconButton>
          }
        />
        <ColorPickerTooltip
          selectedColor={selectionBgColor}
          onNewColor={newBgColor => {
            addToSelectionStyle({
              bgColor: newBgColor,
            });
          }}
          triggerIcon={
            <ColoredIconButtonBucket color={selectionBgColor}>
              <FormatColorFill />
            </ColoredIconButtonBucket>
          }
        />
        <StyledSelect
          value={selectionFontSize}
          onChange={e =>
            doAddSelectedBlockLevelStyles({
              fontSize: parseInt(e.target.value),
            })
          }
        >
          <option value="14">Normal text</option>
          <option value="18">Subtitle</option>
          <option value="26">Title</option>
        </StyledSelect>
        <IconButton
          onClick={() => {
            const { startBlockIndex } = getSelectionFromBlocks(blocks);
            const leftIndent =
              blocks?.[startBlockIndex]?.blockStyles?.leftIndent || 0;
            doAddSelectedBlockLevelStyles({
              leftIndent: leftIndent + 20,
            });
          }}
        >
          <FormatIndentIncrease />
        </IconButton>
        <IconButton
          onClick={() => {
            const { startBlockIndex } = getSelectionFromBlocks(blocks);
            const leftIndent =
              blocks?.[startBlockIndex]?.blockStyles?.leftIndent || 0;
            doAddSelectedBlockLevelStyles({
              leftIndent: leftIndent - 20,
            });
          }}
        >
          <FormatIndentDecrease />
        </IconButton>
        <IconButton
          isActive={isSelectionList}
          onClick={() => {
            doAddSelectedBlockLevelStyles({
              leftIndent: isSelectionList ? 0 : 20,
              prefix: isSelectionList ? "" : "●",
            });
          }}
        >
          <FormatListBulleted />
        </IconButton>
        <IconButton>
          <LinkIcon />
        </IconButton>
        <IconButton>
          <Image />
        </IconButton> */}
        <IconButton>
          <AddDocLabelModalTrigger
            onPressSave={newLabel => {
              addToSelectionStyle({
                meta: {
                  createdAt: new Date().toISOString(),
                  id: uuidv4(),
                  isUserLabel: true,
                  label: newLabel,
                },
              });
            }}
          />
        </IconButton>
      </ToolbarContainer>

      <WordDoc
        sourceFileIds={
          wordDoc?.content?.sources?.length > 0
            ? wordDoc?.content?.sources?.map(s => s?.fileId)
            : []
        }
        blocks={blocks}
        onChangeBlocks={newBlocks => setBlocks(newBlocks)}
        onPressTab={extCursor =>
          doPopulateBlocksFromChatflow(extCursor, blocks)
        }
        topOffset={71 + 52 + 36}
        onPressApproveOrReject={newBlocks =>
          doChangeApprovalBlocksAndSaveDoc(newBlocks)
        }
        isSavingApproval={isSavingApproval}
      />
      <ErrorMessageModal message={error?.message} />
    </Container>
  );
};

export default NewTemplateFilePage;
