import styled from "styled-components";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useState } from "react";
import { US_STATES } from "api/services/mapData";
import { useEffect } from "react";
import { set } from "lodash";
import { CrossIcon } from "components/ui/Icons";
import { Editor } from "@monaco-editor/react";
import { Code } from "@material-ui/icons";
import CodeModalTrigger from "components/CodeModalTrigger";

const MAP = {
  Arizona: {
    "Total Volume": 945463,
    "# of Deals": 52,
  },
  Arkansas: {
    "Total Volume": 597263,
    "# of Deals": 191,
  },
  California: {
    "Total Volume": 87881,
    "# of Deals": 0,
  },
  Colorado: {
    "Total Volume": 943207,
    "# of Deals": 65,
  },
  Connecticut: {
    "Total Volume": 618987,
    "# of Deals": 7,
  },
  "District of Columbia": {
    "Total Volume": 541696,
    "# of Deals": 139,
  },
  Georgia: {
    "Total Volume": 202704,
    "# of Deals": 140,
  },
  Illinois: {
    "Total Volume": 215072,
    "# of Deals": 58,
  },
  Indiana: {
    "Total Volume": 166242,
    "# of Deals": 46,
  },
  Louisiana: {
    "Total Volume": 751537,
    "# of Deals": 134,
  },
  Minnesota: {
    "Total Volume": 273962,
    "# of Deals": 138,
  },
  Mississippi: {
    "Total Volume": 916911,
    "# of Deals": 185,
  },
  Montana: {
    "Total Volume": 576350,
    "# of Deals": 65,
  },
  "New Mexico": {
    "Total Volume": 406168,
    "# of Deals": 160,
  },
  "North Dakota": {
    "Total Volume": 679802,
    "# of Deals": 138,
  },
  Oklahoma: {
    "Total Volume": 624177,
    "# of Deals": 35,
  },
  Pennsylvania: {
    "Total Volume": 696091,
    "# of Deals": 11,
  },
  Tennessee: {
    "Total Volume": 893305,
    "# of Deals": 62,
  },
  Virginia: {
    "Total Volume": 8745,
    "# of Deals": 183,
  },
  Delaware: {
    "Total Volume": 780297,
    "# of Deals": 156,
  },
  "West Virginia": {
    "Total Volume": 128256,
    "# of Deals": 111,
  },
  Wisconsin: {
    "Total Volume": 376558,
    "# of Deals": 184,
  },
  Wyoming: {
    "Total Volume": 641756,
    "# of Deals": 54,
  },
  Alabama: {
    "Total Volume": 398107,
    "# of Deals": 162,
  },
  Florida: {
    "Total Volume": 915861,
    "# of Deals": 171,
  },
  Idaho: {
    "Total Volume": 114977,
    "# of Deals": 49,
  },
  Kansas: {
    "Total Volume": 545786,
    "# of Deals": 77,
  },
  Maryland: {
    "Total Volume": 95108,
    "# of Deals": 165,
  },
  "New Jersey": {
    "Total Volume": 804746,
    "# of Deals": 141,
  },
  "North Carolina": {
    "Total Volume": 636185,
    "# of Deals": 76,
  },
  "South Carolina": {
    "Total Volume": 579506,
    "# of Deals": 142,
  },
  Washington: {
    "Total Volume": 463803,
    "# of Deals": 158,
  },
  Vermont: {
    "Total Volume": 750067,
    "# of Deals": 58,
  },
  Utah: {
    "Total Volume": 490903,
    "# of Deals": 16,
  },
  Iowa: {
    "Total Volume": 207862,
    "# of Deals": 141,
  },
  Kentucky: {
    "Total Volume": 323781,
    "# of Deals": 64,
  },
  Maine: {
    "Total Volume": 546666,
    "# of Deals": 74,
  },
  Massachusetts: {
    "Total Volume": 879000,
    "# of Deals": 168,
  },
  Michigan: {
    "Total Volume": 509158,
    "# of Deals": 22,
  },
  Missouri: {
    "Total Volume": 692269,
    "# of Deals": 133,
  },
  Nebraska: {
    "Total Volume": 709836,
    "# of Deals": 135,
  },
  Nevada: {
    "Total Volume": 341993,
    "# of Deals": 159,
  },
  "New Hampshire": {
    "Total Volume": 277602,
    "# of Deals": 184,
  },
  "New York": {
    "Total Volume": 44060,
    "# of Deals": 27,
  },
  Ohio: {
    "Total Volume": 3102,
    "# of Deals": 166,
  },
  Oregon: {
    "Total Volume": 657127,
    "# of Deals": 13,
  },
  "Rhode Island": {
    "Total Volume": 34252,
    "# of Deals": 24,
  },
  "South Dakota": {
    "Total Volume": 560642,
    "# of Deals": 154,
  },
  Texas: {
    "Total Volume": 233943,
    "# of Deals": 69,
  },
  Alaska: {
    "Total Volume": 115989,
    "# of Deals": 69,
  },
  Hawaii: {
    "Total Volume": 353433,
    "# of Deals": 26,
  },
};

const MapContainer = styled.div`
  border: 1px solid ${props => props.theme.color.closer1_5};
  position: relative;
`;

const StyledGeography = styled(Geography)`
  :hover {
    stroke: black;
    z-index: 100;
    stroke-width: 2px;
  }
`;

const StateInfo = styled.div`
  position: absolute;
  padding-top: 80px;
  left: 50%;
  transform: translateX(-50%);
`;

const Td = styled.td`
  padding: 4px 8px;
`;

const BoldTd = styled.td`
  font-weight: 600;
`;

const StateTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  padding: 8px 0;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 12px;
  margin-left: 8px;
  cursor: pointer;
  opacity: 0.5;

  position: absolute;
  top: 4px;
  right: 4px;

  :hover {
    opacity: 1;
  }
`;

const getStateNameToAlphas = stateNameToFields => {
  const fieldNames = Object.keys(Object.values(stateNameToFields)?.[0] || {});
  const fieldNameToMinAndMax = {};
  fieldNames.forEach(fieldName => {
    const values = Object.values(stateNameToFields).map(
      stateNameToField => stateNameToField[fieldName]
    );
    const min = Math.min(...values);
    const max = Math.max(...values);
    fieldNameToMinAndMax[fieldName] = { min: 0, max };
  });

  const stateNameToAlphas = {};
  Object.entries(stateNameToFields).forEach(([stateName, stateNameToField]) => {
    const alphas = {};
    Object.entries(stateNameToField).forEach(([fieldName, fieldValue]) => {
      const { min, max } = fieldNameToMinAndMax[fieldName] || {};
      const alpha = (fieldValue - min) / (max - min);
      alphas[fieldName] = alpha;
    });
    stateNameToAlphas[stateName] = alphas;
  });

  return stateNameToAlphas;
};

const StyledEditor = styled(Editor)`
  height: 375px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
  overflow: hidden;
`;

const StyledCodeIcon = styled(Code)`
  color: ${props =>
    props.isBlue ? props.theme.color.primary : props.theme.color.closer2};
  :hover {
    opacity: 0.6;
  }
`;

const GeoMap = ({
  style = {},
  isHoverDisabled = false,
  stateNameToFields = MAP,
  fieldNames,
  onClickCross = () => {},
  id = "",
  onSetIsDragging = () => {},
}) => {
  const [stateInfo, setStateInfo] = useState(null);
  const [isViewingVolume, setIsViewingVolume] = useState(true);
  const [fieldName, setFieldName] = useState("");
  const [isViewingCode, setIsViewingCode] = useState(false);

  useEffect(() => {
    setFieldName(fieldNames[0]);
  }, [stateNameToFields]);

  const mapWidth = 500;
  const mapHeight = 400;

  const tucsonCoords = [-110.9265, 32.2226];
  const miamiCoords = [-80.1918, 25.7617];

  const stateNameToAlphas = getStateNameToAlphas(stateNameToFields);

  const totalVolume =
    parseFloat(
      stateNameToFields?.[stateInfo?.properties?.name]?.["Total Volume"]
    ) || 0;
  const totalVolumeDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalVolume);

  return (
    <MapContainer
      draggable="true"
      onDragStart={e => {
        onSetIsDragging(true);
        e.dataTransfer.setData("text/plain", id);
      }}
      onDragEnd={() => {
        onSetIsDragging(false);
      }}
      style={{ ...style, width: mapWidth, height: mapHeight }}
    >
      <div
        style={{
          display: "flex",
          gap: "8px",
        }}
      >
        <select value={fieldName} onChange={e => setFieldName(e.target.value)}>
          {fieldNames.map(fieldName => (
            <option>{fieldName}</option>
          ))}
        </select>
        <CodeModalTrigger />
      </div>
      <StyledCrossIcon onClick={onClickCross} />

      <ComposableMap
        projection="geoAlbersUsa"
        projectionConfig={{
          scale: 680,
          center: [-100, 37],
        }}
        width={mapWidth}
        height={mapHeight}
      >
        <Geographies geography={US_STATES}>
          {({ geographies }) =>
            geographies.map((geo, i) => {
              const stateName = geo.properties.name;
              const alpha = stateNameToAlphas?.[stateName]?.[fieldName];

              const fill = isViewingVolume
                ? `rgba(0, 153, 51, ${alpha || 0})`
                : `rgba(150, 80, 255, ${alpha || 0})`;

              return (
                <StyledGeography
                  onMouseEnter={e => setStateInfo(geo)}
                  onMouseLeave={e => setStateInfo(null)}
                  key={geo.rsmKey}
                  geography={geo}
                  stroke="darkgray"
                  strokeWidth={0.5}
                  fill={fill}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </MapContainer>
  );
};

export default GeoMap;
