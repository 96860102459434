import { invert } from "lodash";

import * as backend from "api/backend/excelModelsEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getSmartRecords } from "api/services/searchService";
import { getSigmaTableConfig } from "api/services/sigmaService";
import {
  getSheetWithSmartRecordsFilledIn,
  getSheetWithTableWiped,
} from "utils/excel-utils";

export const SUPPORTED_FUNCTIONS = [
  "ABS",
  "ACOS",
  "ACOSH",
  "ADDRESS",
  "AND",
  "AREAS",
  "ASIN",
  "ASINH",
  "ATAN",
  "ATAN2",
  "ATANH",
  "AVEDEV",
  "AVERAGE",
  "AVERAGEIFS",
  "BESSELJ",
  "BIN2DEC",
  "CEILING",
  "CHAR",
  "CHOOSE",
  "CLEAN",
  "CODE",
  "COLUMN",
  "COLUMNS",
  "COMBIN",
  "COMPLEX",
  "CONCAT",
  "CONCATENATE",
  "COS",
  "COSH",
  "COUNT",
  "COUNTA",
  "COUNTBLANK",
  "COUNTIF",
  "COUNTIFS",
  "DATE",
  "DATEVALUE",
  "DAY",
  "DAYS360",
  "DEC2BIN",
  "DEC2HEX",
  "DEGREES",
  "DELTA",
  "DEVSQ",
  "DGET",
  "DMAX",
  "DMIN",
  "DOLLAR",
  "DOLLARDE",
  "DOLLARFR",
  "DSUM",
  "EDATE",
  "EOMONTH",
  "ERROR.TYPE",
  "EVEN",
  "EXACT",
  "EXP",
  "FACT",
  "FACTDOUBLE",
  "FALSE",
  "FIND",
  "FIXED",
  "FLOOR",
  "FREQUENCY",
  "FV",
  "GEOMEAN",
  "HEX2DEC",
  "HLOOKUP",
  "HOUR",
  "HYPERLINK",
  "IF",
  "IFERROR",
  "IFNA",
  "IFS",
  "IMAGINARY",
  "IMREAL",
  "INDEX",
  "INDIRECT",
  "INT",
  "INTERCEPT",
  "IPMT",
  "IRR",
  "ISBLANK",
  "ISERR",
  "ISERROR",
  "ISEVEN",
  "ISLOGICAL",
  "ISNA",
  "ISNONTEXT",
  "ISNUMBER",
  "ISODD",
  "ISREF",
  "ISTEXT",
  "LARGE",
  "LEFT",
  "LEN",
  "LN",
  "LOG",
  "LOG10",
  "LOOKUP",
  "LOWER",
  "MATCH",
  "MAX",
  "MAXA",
  "MAXIFS",
  "MDETERM",
  "MEDIAN",
  "MID",
  "MIN",
  "MINA",
  "MINIFS",
  "MINUTE",
  "MINVERSE",
  "MIRR",
  "MMULT",
  "MOD",
  "MODE",
  "MONTH",
  "MROUND",
  "NA",
  "NETWORKDAYS",
  "NORM.DIST",
  "NORM.INV",
  "NORM.S.DIST",
  "NORM.S.INV",
  "NORMDIST",
  "NORMINV",
  "NORMSDIST",
  "NORMSINV",
  "NOT",
  "NOW",
  "NPER",
  "NPV",
  "NUMBERVALUE",
  "OCT2DEC",
  "ODD",
  "OFFSET",
  "OR",
  "PERCENTILE",
  "PERCENTRANK",
  "PERCENTRANK.EXC",
  "PERCENTRANK.INC",
  "PI",
  "PMT",
  "POISSON",
  "POWER",
  "PPMT",
  "PRODUCT",
  "PROPER",
  "PV",
  "QUOTIENT",
  "RADIANS",
  "RAND",
  "RANDBETWEEN",
  "RANK",
  "RATE",
  "REPLACE",
  "REPT",
  "RIGHT",
  "ROMAN",
  "ROUND",
  "ROUNDDOWN",
  "ROUNDUP",
  "ROW",
  "ROWS",
  "SEARCH",
  "SECOND",
  "SIGN",
  "SIN",
  "SINGLE",
  "SINH",
  "SLOPE",
  "SMALL",
  "SQRT",
  "STANDARDIZE",
  "STDEV",
  "SUBSTITUTE",
  "SUBTOTAL",
  "SUM",
  "SUMIF",
  "SUMIFS",
  "SUMPRODUCT",
  "SUMSQ",
  "SUMX2MY2",
  "SUMX2PY2",
  "SUMXMY2",
  "SWITCH",
  "T",
  "T.DIST",
  "T.DIST.2T",
  "T.DIST.RT",
  "TAN",
  "TANH",
  "TDIST",
  "TEXT",
  "TEXTJOIN",
  "TIME",
  "TIMEVALUE",
  "TODAY",
  "TRANSPOSE",
  "TREND",
  "TRIM",
  "TRUE",
  "TRUNC",
  "UPPER",
  "VALUE",
  "VAR",
  "VARP",
  "VLOOKUP",
  "WEEKDAY",
  "WEEKNUM",
  "WORKDAY",
  "WORKDAY.INTL",
  "XLOOKUP",
  "XMATCH",
  "YEAR",
  "YEARFRAC",
];

export const FUNCTION_TO_DESCRIPTION = {
  "ERROR.TYPE": "",
  ISEVEN: "ISEVEN(value)",
  VARP: "",
  MONTH: "MONTH(serial_number)",
  RAND: "",
  TRIM: "",
  PV: "",
  RANK: "",
  LOG10: "",
  BESSELJ: "",
  FLOOR: "",
  YEAR: "YEAR(date)",
  ISNONTEXT: "ISNONTEXT(value)",
  DATEVALUE: "",
  ISTEXT: "ISTEXT(value)",
  NUMBERVALUE: "",
  LEFT: "",
  SUBSTITUTE: "",
  SEARCH: "",
  MID: "",
  QUOTIENT: "",
  SUM: "SUM(number1, [number2], [number3], ...)",
  SUMX2PY2: "",
  MAXIFS: "MAXIFS(max_range, range1, criteria1, [range2], [criteria2], ...)",
  ACOSH: "",
  SUMIF: "SUMIF(range, criteria, [sum_range])",
  MIN: "MIN(number1, [number2], ...)",
  IMREAL: "",
  DOLLAR: "",
  FACTDOUBLE: "",
  DELTA: "",
  IRR: "",
  FACT: "",
  RANDBETWEEN: "",
  UPPER: "",
  ABS: "",
  DOLLARFR: "",
  SMALL: "",
  T: "",
  OFFSET: "OFFSET(reference, rows, cols, [height], [width])",
  MEDIAN: "MEDIAN(number1, [number2], ...)",
  GEOMEAN: "",
  CHOOSE: "",
  POWER: "",
  MINA: "",
  DGET: "",
  MINVERSE: "",
  "NORM.S.DIST": "",
  PROPER: "",
  ISNA: "ISNA(value)",
  XLOOKUP: "",
  AVEDEV: "",
  HEX2DEC: "",
  NORMDIST: "",
  PRODUCT: "",
  HYPERLINK: "",
  INDEX: "INDEX(array, row_num, [col_num], [area_num])",
  MMULT: "",
  REPLACE: "",
  EDATE: "",
  DMAX: "",
  FIXED: "",
  "T.DIST.RT": "",
  INTERCEPT: "",
  NOT: "",
  CEILING: "",
  LOWER: "",
  SINH: "",
  NOW: "",
  SIN: "",
  SUMIFS: "",
  TANH: "",
  ROUNDDOWN: "",
  DEC2HEX: "",
  RATE: "",
  EOMONTH: "",
  ASIN: "",
  PERCENTILE: "",
  XMATCH: "",
  DEVSQ: "",
  FV: "",
  SUBTOTAL: "",
  AVERAGE: "AVERAGE(number1, [number2], ...)",
  NPV: "",
  VLOOKUP:
    "VLOOKUP(lookup_value, table_array, column_index_num, [range_lookup])",
  FREQUENCY: "",
  IFS: "IFS(test1, value1, [test2, value2], ...)",
  RADIANS: "",
  COLUMNS: "",
  PERCENTRANK: "",
  AREAS: "",
  INDIRECT: "",
  MOD: "",
  WEEKDAY: "",
  DOLLARDE: "",
  MINIFS: "MINIFS(min_range, range1, criteria1, [range2], [criteria2], ...)",
  TIME: "TIME(hour, minute, second)",
  COUNTBLANK: "",
  MIRR: "",
  DEC2BIN: "",
  FALSE: "FALSE()",
  IMAGINARY: "",
  "T.DIST": "",
  "NORM.S.INV": "",
  SQRT: "",
  VAR: "",
  NORMINV: "",
  TEXT: "",
  TEXTJOIN: "",
  ODD: "",
  "PERCENTRANK.INC": "",
  NORMSDIST: "",
  ATANH: "",
  IF: "IF(logical_test, [value_if_true], [value_if_false])",
  WEEKNUM: "",
  ISREF: "ISREF(value)",
  LOG: "",
  TIMEVALUE: "",
  ATAN2: "",
  EXACT: "",
  IPMT: "",
  HLOOKUP: "",
  ISNUMBER: "",
  COMBIN: "",
  COUNT: "COUNT(value1, [value2], ...)",
  EVEN: "",
  SLOPE: "",
  ISBLANK: "",
  MAX: "MAX(number1, [number2], ...)",
  TDIST: "",
  DSUM: "",
  CHAR: "",
  TRUNC: "",
  MROUND: "",
  STDEV: "",
  "T.DIST.2T": "",
  YEARFRAC: "",
  DMIN: "",
  SUMXMY2: "",
  REPT: "",
  LOOKUP: "",
  LARGE: "LARGE(array, k)",
  RIGHT: "",
  MAXA: "MAXA(value1, [value2], ...)",
  COS: "",
  OCT2DEC: "",
  DAYS360: "",
  SUMX2MY2: "",
  SUMSQ: "",
  ROMAN: "",
  "NORM.INV": "",
  WORKDAY: "",
  DAY: "DAY(date)",
  "PERCENTRANK.EXC": "",
  TAN: "",
  LN: "",
  PPMT: "",
  SINGLE: "",
  POISSON: "",
  COLUMN: "",
  MODE: "",
  VALUE: "",
  ISODD: "ISODD(value)",
  ACOS: "",
  BIN2DEC: "",
  FIND: "",
  MINUTE: "MINUTE(serial_number)",
  SIGN: "",
  CONCATENATE: "",
  NORMSINV: "",
  STANDARDIZE: "",
  TRUE: "",
  COUNTIF: "COUNTIF(range, criteria)",
  DATE: "DATE(year, month, day)",
  IFNA: "",
  NA: "",
  ADDRESS: "",
  AND: "AND(logical1, [logical2], ...)",
  SWITCH: "",
  "NORM.DIST": "",
  ROW: "",
  TREND: "",
  ATAN: "",
  AVERAGEIFS:
    "AVERAGEIFS(avg_rng, range1, criteria1, [range2], [criteria2], ...)",
  TRANSPOSE: "",
  HOUR: "HOUR(serial_number)",
  ISERROR: "ISERROR(value)",
  CONCAT: "CONCAT(text1, [text2], ...)",
  MDETERM: "",
  ROUND: "",
  ISLOGICAL: "ISLOGICAL(value)",
  NETWORKDAYS: "",
  NPER: "",
  INT: "",
  SUMPRODUCT: "",
  COSH: "",
  COMPLEX: "",
  OR: "",
  TODAY: "",
  COUNTA: "COUNTA(value1, [value2], ...)",
  IFERROR: "IFERROR(value, value_if_error)",
  ROUNDUP: "",
  ASINH: "",
  ISERR: "ISERR(value)",
  CODE: "",
  DEGREES: "",
  "WORKDAY.INTL": "",
  LEN: "",
  PMT: "",
  PI: "",
  CLEAN: "",
  SECOND: "",
  EXP: "",
  COUNTIFS: "COUNTIFS(range1, criteria1, [range2], [criteria2], ...)",
  MATCH: "MATCH(lookup_value, lookup_array, [match_type])",
  ROWS: "",
};

export const DATA_FORMAT_STR_TO_DATA_TYPE = {
  "0.00": "Decimal",
  "0.00;[Red]0.00": "Number",
  "yyyy-mm-dd;@": "Date",
  "0.00%": "Percentage",
  "0.0%": "Percentage",
  "#,##0": "Number",
  "# ?/?": "Fraction",
  "0.00E+00": "Scientific",
  "_(* #,##0.00_);_(* (#,##0.00);_(* “-”??_);_(@_)": "Accounting",
  "_([$$-409]* #,##0.00_);_([$$-409]* (#,##0.00);_([$$-409]* “-”??_);_(@_)":
    "Accounting",
  "0.0": "Decimal",
  "m/d;@": "Date",
  "m/d/yy;@": "Date",
  "mm/dd/yy;@": "Date",
  "[$-409]d-mmm;@": "Date",
  "[$-409]d-mmm-yy;@": "Date",
  "[$-409]mmm-yy;@": "Date",
  "[$-409]mmmm-yy;@": "Date",
  "[$-409]mmmm d, yyyy;@": "Date",
  "[$-409]m/d/yy h:mm am/pm;@": "Time",
  "m/d/yy h:mm;@": "Date",
  "[$-409]mmmmm;@": "Date",
  "[$-409]mmmmm-yy;@": "Date",
  "m/d/yyyy;@": "Date",
  "[$-409]d-mmm-yyyy;@": "Date",
  "d-mmm-yy": "Date",
  "m/d/yy h:mm": "Date",
  "mm:ss": "Time",
  "h:mm:ss AM/PM": "Time",
  "h:mm:ss;@": "Time",
  "[$-409]h:mm:ss am/pm;@": "Time",
  "mm:ss.0;@": "Time",
  "[h]:mm:ss;@": "Time",
  "“£”#,##0.00": "Currency",
  "“£”#,##0.00;[Red]“£”#,##0.00": "Currency",
  "“£”#,##0.00_);(“£”#,##0.00)": "Currency",
  "“£”#,##0.00_);[Red](“£”#,##0.00)": "Currency",
  '"$"#,##0': "Currency",
  "": "General",
  General: "General",
  Text: "Text",
};

export const DATA_TYPE_TO_DATA_FORMAT_STR = invert(
  DATA_FORMAT_STR_TO_DATA_TYPE
);

export const DATA_TYPES = [
  "General",
  "Decimal",
  "Number",
  "Date",
  "Percentage",
  "Fraction",
  "Scientific",
  "Accounting",
  "Time",
  "Currency",
  "Text",
  "Custom",
];

export const getDownloadExcelModelById = async excelModelId => {
  try {
    await backend.triggerDownloadOfExcelFile(excelModelId);
    return { data: null, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const postExcelModelOpen = async excelModelId => {
  const { data, error } = await backend.postExcelModelOpen(excelModelId);
  return { data, error: getHandlingFromError(error) };
};

export const patchExcelModelSave = async id => {
  const { data, error } = await backend.patchExcelModelSave(id);
  return { data, error: getHandlingFromError(error) };
};

export const postExcelModelUploadFile = async file => {
  const { data, error } = await backend.postExcelModelUploadFile(file);
  return { data, error: getHandlingFromError(error) };
};

export const postExcelModelDraft = async (params = {}, body = {}) => {
  const { data, error } = await backend.postExcelModelDraft(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModels = async (params = {}) => {
  const { data, error } = await backend.getExcelModels(params);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelById = async (id, params = {}) => {
  const { data, error } = await backend.getExcelModelById(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelSheetGrid = async (
  excelModelId,
  sheetName,
  params = {}
) => {
  const { data, error } = await backend.getExcelModelSheetGrid(
    excelModelId,
    sheetName,
    params
  );

  const grid = { ...data };
  grid?.hiddenColIndices?.forEach(columnIndex => {
    grid.columnIndexToWidth[columnIndex] = 0;
  });

  grid?.hiddenRowIndices?.forEach(rowIndex => {
    grid.rowIndexToHeight[rowIndex] = 0;
  });

  return { data: grid, error: getHandlingFromError(error) };
};

export const patchExcelModelSheetGrid = async (
  excelModelId,
  sheetName,
  params = {},
  body = {}
) => {
  const { data, error } = await backend.patchExcelModelSheetGrid(
    excelModelId,
    sheetName,
    params,
    body
  );

  const grid = { ...data };
  grid?.hiddenColIndices?.forEach(columnIndex => {
    grid.columnIndexToWidth[columnIndex] = 0;
  });

  grid?.hiddenRowIndices?.forEach(rowIndex => {
    grid.rowIndexToHeight[rowIndex] = 0;
  });

  return { data: grid, error: getHandlingFromError(error) };
};

export const patchExcelModelById = async (id, params = {}, body) => {
  // if (params.w === -1) {
  //   params.w = 30;
  // }
  // if (params.h === -1) {
  //   params.h = 30;
  // }
  // const newParams = {
  //   x: params.startRow,
  //   y: params.startCol,
  //   w: params.endCol - params.startCol,
  //   h: params.endRow - params.startRow,
  // };
  const { data, error } = await backend.patchExcelModelById(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteExcelModelById = async id => {
  const { data, error } = await backend.deleteExcelModelById(id);
  return { data, error: getHandlingFromError(error) };
};

export const getGenerateExcelModelFromTable = async params => {
  const { data, error } = await backend.getGenerateExcelModelFromTable(params);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelSheetSyncStatus = async (
  excelModelId,
  sheetIndex
) => {
  const { data, error } = await backend.getExcelModelSheetSyncStatus(
    excelModelId,
    sheetIndex
  );
  return { data, error: getHandlingFromError(error) };
};

export const patchExcelModelSheetSync = async (excelModelId, sheetIndex) => {
  const { data, error } = await backend.patchExcelModelSheetSync(
    excelModelId,
    sheetIndex
  );
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelStatus = async excelModelId => {
  const { data, error } = await backend.getExcelModelStatus(excelModelId);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelLoading = async excelModelId => {
  const { data, error } = await backend.getExcelModelLoading(excelModelId);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelSheetNames = async excelModelId => {
  const { data, error } = await backend.getExcelModelSheetNames(excelModelId);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelByIdAndSheetName = async (
  excelModelId,
  sheetName,
  params = {}
) => {
  // const newParams = {
  //   x: params.startRow,
  //   y: params.startCol,
  //   w: params.endCol - params.startCol,
  //   h: params.endRow - params.startRow,
  // };
  const { data, error } = await backend.getExcelModelByIdAndSheetName(
    excelModelId,
    sheetName,
    params
  );
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelByIdAndSheetNameCachedData = async (
  excelModelId,
  sheetName,
  params = {}
) => {
  // const newParams = {
  //   x: params.startRow,
  //   y: params.startCol,
  //   w: params.endCol - params.startCol,
  //   h: params.endRow - params.startRow,
  // };
  const { data, error } = await backend.getExcelModelByIdAndSheetNameCachedData(
    excelModelId,
    sheetName,
    params
  );
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelSheetWithCellsFilledInFromItsSigmaTable =
  async sheet => {
    const { data, error } = await getSmartRecords({
      sigmaTableConfigId: sheet?.sigmaTableId,
      query: "",
    });
    if (error) {
      return { data: null, error };
    }

    const { data: sigmaTable, error: sigmaTableFetchErr } =
      await getSigmaTableConfig(sheet?.sigmaTableId);
    if (sigmaTableFetchErr) {
      return { data: null, error: getHandlingFromError(sigmaTableFetchErr) };
    }

    const newSheetWithTableWiped = getSheetWithTableWiped(sheet);
    const newSheetWithSmartRecords = getSheetWithSmartRecordsFilledIn({
      sheet: newSheetWithTableWiped,
      upperLeftCornerLocation: sheet?.tableLocation?.split(":")?.[0],
      smartRecords: data,
      columnNamesToFill: sheet?.selectedSigmaConfigIds?.map(
        sigmaConfigId =>
          sigmaTable?.sigmaConfigs.find(
            sigmaConfig => sigmaConfig.id === sigmaConfigId
          ).name
      ),
    });

    const sheetWithUpdatedCells = {
      ...sheet,
      ...newSheetWithSmartRecords,
    };

    return { data: sheetWithUpdatedCells, error: null };
  };

export const deleteUnloadExcelModel = async excelModelId => {
  const { data, error } = await backend.deleteUnloadExcelModel(excelModelId);
  return { data, error: getHandlingFromError(error) };
};

export const patchExcelModelMl = async (id, params, body) => {
  const { data, error } = await backend.patchExcelModelMl(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postExcelFlowGenerate = async (excelModelId, params, body) => {
  const { data, error } = await backend.postExcelFlowGenerate(
    excelModelId,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const deleteSheet = async (excelModelId, params) => {
  const { data, error } = await backend.deleteSheet(excelModelId, params);
  return { data, error: getHandlingFromError(error) };
};
