import styled from "styled-components";
import { useState, useEffect } from "react";

import { getArrayWithUpsertedItem, getColorFromString, parseJson } from "utils/common";
import LegendLabelInput from "components/widgets/TextLabellingArea/LegendLabelInput";
import AnnotationSpan from "components/widgets/TextLabellingArea/AnnotationSpan";
import { getIndicesOfSubstring, getTextSegmentConfig, SENTIMENT_LABELS } from "utils/text-annotate-utils";
import MultiOptionSelector from "components/ui/MultiOptionSelector";
import CreateRuleModal from "components/widgets/CreateRuleModal";
import { useSearchParams } from "react-router-dom";
import SmallButton from "components/ui/SmallButton";
import AddLabelModal from "components/widgets/AddLabelModal";
import { deleteEntity } from "api/services/searchService";

const TextContainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  overflow-y: auto;
  white-space: pre-wrap;
`;

const LegendContainer = styled.div`
  width: 100%;
  min-height: 48px;
  overflow-y: auto;
  padding: 0 10px;
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  gap: 5px;
  padding: 10px 0;
  padding-top: 5px;
  border-top: 1px solid ${props => props.theme.color.closer1_5};
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  background-color: ${props => props.theme.color.closer0};
  overflow: auto;
`;

const ModeSelector = styled(MultiOptionSelector)``;

const ModeSelectorAndCreateRule = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 10px 0 10px;
`;

/*
PUT /file/{fileId}/page/{pageNum}/text-annotations
--->
{
  outputId: ...,
  content: ...,
  annotations: [
    {
        "pos": 44,
        "span": 6,
        "label": "number"
    },
    {
        "pos": 1158,
        "span": 6,
        "label": "number"
    }
  ]
}

GET /solutions/ocr/api/v1/files/{filId}/page-results/{pageId}
{
  ...
  output: {
    ...
    { id, type, content, annotations }
  }
}
*/
const SentimentTextSpan = styled.span`
  ${props => props.sentiment === "POSITIVE" && `color: ${props.theme.color.success}`}
  ${props => props.sentiment === "NEGATIVE" && `color: ${props.theme.color.error}`}
  ${props => props.sentiment === "NEUTRAL" && `color: ${props.theme.color.in_progress}`}
`;

// const FILTERING_MODES = ["Normal", "Sentiment", "Entity", "AI labels", "User entity"];
const FILTERING_MODES = ["Normal", "AI labels"];

const TextLabellingArea = ({
  outputItem,
  onNewTextAnnotations,
  entities = [],
  onNewEntities,
  fileId,
  onAddReferenceCandidate = ({ pageNumber, annotationId, annotationContent }) => {},
}) => {
  const [annotations, setAnnotations] = useState(outputItem?.annotations || []);
  const [annotationsFilteringMode, setAnnotationsFilteringMode] = useState("Normal");

  const [selectedStrForRuleCreation, setSelectedStrForRuleCreation] = useState("");

  const [searchParams] = useSearchParams();

  const pageNumber = parseInt(searchParams.get("pageNumber")) || 0;
  const isEditngSolrRecord = !!searchParams.get("solrId");

  useEffect(() => {
    onNewTextAnnotations(annotations);
  }, [annotations]);

  useEffect(() => {
    setAnnotations(outputItem?.annotations || []);
  }, [outputItem?.id]);

  let filteredAnnotations = annotations?.filter(annotation => {
    if (annotationsFilteringMode === "Sentiment") {
      return SENTIMENT_LABELS?.includes(annotation?.label);
    }

    if (annotationsFilteringMode === "Entity") {
      return !SENTIMENT_LABELS?.includes(annotation?.label) && annotation?.label !== "-";
    }

    if (annotationsFilteringMode === "AI labels") {
      return annotation?.label === "-";
    }

    if (annotationsFilteringMode === "User entity") {
      return annotation?.label === "_entity_";
    }

    return true;
  });
  filteredAnnotations = [
    ...filteredAnnotations,
    ...entities
      ?.filter(entity => {
        const entityJson = parseJson(entity?.value);
        return (
          entity?.fileId === fileId &&
          entityJson?.pageNumber === pageNumber &&
          entityJson?.outputItemId === outputItem?.id
        );
      })
      ?.map(entity => {
        const entityJson = parseJson(entity?.value);
        return {
          pos: entityJson?.pos,
          span: entityJson?.span,
          type: "TEXT_LABEL",
          label: "-",
          additionalLabel: entityJson?.additionalLabel,
        };
      }),
  ];

  const isThereAnyLabelsInAnnotationsForMode = mode => {
    if (mode === "Sentiment") {
      return annotations?.some(annotation => SENTIMENT_LABELS?.includes(annotation?.label));
    }

    if (mode === "Entity") {
      return annotations?.some(
        annotation => !SENTIMENT_LABELS?.includes(annotation?.label) && annotation?.label !== "-"
      );
    }

    if (mode === "AI labels") {
      return annotations?.some(annotation => annotation?.label === "-");
    }

    if (annotationsFilteringMode === "User entity") {
      return annotations?.some(annotation => annotation?.label === "_entity_");
    }

    return true;
  };

  const greyedOutModes = [];
  // FILTERING_MODES?.filter(mode => !isThereAnyLabelsInAnnotationsForMode(mode))?.filter(
  //   mode => mode !== "User entity" && mode !== "Normal"
  // );

  let annotationLabels = [...new Set(filteredAnnotations?.map(annotation => annotation?.label))];
  const textSegmentsConfig = getTextSegmentConfig(outputItem?.content, filteredAnnotations);

  if (annotationsFilteringMode === "Normal") {
    return (
      <Container>
        <ModeSelectorAndCreateRule>
          <ModeSelector
            options={FILTERING_MODES}
            selectedOption={annotationsFilteringMode}
            disabledOptions={greyedOutModes}
            onOptionSelect={newOption => setAnnotationsFilteringMode(newOption)}
          />
          {isEditngSolrRecord && (
            <SmallButton
              value="Add to references"
              onClick={() => {
                onAddReferenceCandidate({
                  pageNumber,
                  annotationId: outputItem?.id,
                  annotationContent: outputItem?.content,
                });
              }}
            />
          )}
        </ModeSelectorAndCreateRule>
        <TextContainer>{outputItem?.content}</TextContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ModeSelectorAndCreateRule>
        <ModeSelector
          options={FILTERING_MODES}
          selectedOption={annotationsFilteringMode}
          disabledOptions={greyedOutModes}
          onOptionSelect={newOption => setAnnotationsFilteringMode(newOption)}
        />
        {/* {selectedStrForRuleCreation && (
          <CreateRuleModal
            selectedStr={selectedStrForRuleCreation}
            onRuleCreated={rule => {
              const newAnnotations = getIndicesOfSubstring(outputItem?.content, selectedStrForRuleCreation).map(
                pos => ({
                  pos,
                  span: selectedStrForRuleCreation?.length,
                  additionalLabel: "-",
                  score: 1,
                  fakeRule: rule,
                  label: "-",
                })
              );
              setAnnotations([...annotations, ...newAnnotations]);
            }}
          />
        )} */}
        {selectedStrForRuleCreation && (
          <AddLabelModal
            selectedStr={selectedStrForRuleCreation}
            onLabelAdded={(labelStr, fakeRule) => {
              const newAnnotations = getIndicesOfSubstring(outputItem?.content, selectedStrForRuleCreation).map(
                pos => ({
                  pos,
                  span: selectedStrForRuleCreation?.length,
                  additionalLabel: labelStr,
                  fakeRule,
                  score: 1,
                  label: "-",
                  type: "TEXT_LABEL",
                })
              );

              onNewEntities([
                ...entities,
                ...newAnnotations?.map(annotation => ({
                  fileId,
                  type: annotation?.type,
                  value: JSON.stringify({
                    label: "-",
                    additionalLabel: labelStr,
                    originalText: selectedStrForRuleCreation,
                    pos: annotation?.pos,
                    span: annotation?.span,
                    pageNumber,
                    outputItemId: outputItem?.id,
                  }),
                })),
              ]);

              setAnnotations([...annotations, ...newAnnotations]);
            }}
          />
        )}
        {isEditngSolrRecord && (
          <SmallButton
            value="Add to references"
            onClick={() => {
              onAddReferenceCandidate({
                pageNumber,
                annotationId: outputItem?.id,
                annotationContent: outputItem?.content,
              });
            }}
          />
        )}
      </ModeSelectorAndCreateRule>
      <TextContainer
        onMouseUp={() => {
          const selectedString = getSelection()?.toString();

          if (annotationsFilteringMode === "AI labels") {
            setSelectedStrForRuleCreation(selectedString);
          }

          if (!selectedString) {
            return;
          }

          const newAnnotations = getIndicesOfSubstring(outputItem?.content, selectedString).map(pos => ({
            pos,
            span: selectedString?.length,
            label: annotationsFilteringMode === "User entity" ? "_entity_" : "",
          }));
          setAnnotations([...annotations, ...newAnnotations]);
        }}
      >
        {textSegmentsConfig?.map(segment => {
          if (segment.annotation) {
            // if (SENTIMENT_LABELS?.includes(segment.annotation?.label)) {
            //   return (
            //     <SentimentTextSpan sentiment={segment?.annotation?.label}>
            //       {outputItem?.content?.slice(segment.start, segment.end)}
            //     </SentimentTextSpan>
            //   );
            // }
            const spanContent = outputItem?.content?.slice(segment.start, segment.end);

            return (
              <AnnotationSpan
                isHumanLabel={segment?.annotation?.type === "TEXT_LABEL"}
                contentStr={outputItem?.content?.slice(segment.start, segment.end)}
                key={segment?.start}
                bgColor={
                  segment?.annotation?.type === "TEXT_LABEL"
                    ? "darkblue"
                    : getColorFromString(segment?.annotation?.label)
                }
                additionalLabelUrl={segment?.annotation?.additionalLabelUrl}
                hoverText={segment?.annotation?.additionalLabel}
                scoreValue={segment?.annotation?.score}
                fakeRule={segment?.annotation?.fakeRule}
                thumb0or1={segment?.annotation?.thumb0or1}
                onClick={async () => {
                  const newAnnotations = annotations.filter(annotation => {
                    return (
                      annotation?.pos !== segment?.annotation?.pos || SENTIMENT_LABELS?.includes(annotation?.label)
                    );
                  });

                  const entityToDelete = entities?.find(entity => {
                    const entityValue = parseJson(entity?.value);
                    return (
                      entityValue?.pos === segment?.annotation?.pos && entityValue?.outputItemId === outputItem?.id
                    );
                  });
                  await deleteEntity(entityToDelete?.id);
                  const newEntities = entities?.filter(entity => entity?.id !== entityToDelete?.id);

                  onNewEntities(newEntities);
                  setAnnotations(newAnnotations);
                }}
                onClickThumb={thumb0or1 => {
                  const newAnnotations = annotations.map(annotation => {
                    if (annotation?.pos === segment?.annotation?.pos) {
                      return {
                        ...annotation,
                        thumb0or1,
                      };
                    }
                    return annotation;
                  });
                  setAnnotations(newAnnotations);
                }}
                isUserEntitySpan={annotationsFilteringMode === "User entity"}
                spanEntity={entities?.find(
                  entity => entity?.pageNumOutputIdContent === `${pageNumber}-${outputItem?.id}-${spanContent}`
                )}
                onNewSpanEntity={newSpanEntity => {
                  onNewEntities(
                    getArrayWithUpsertedItem({
                      array: entities,
                      item: {
                        fileId,
                        ...newSpanEntity,
                        pageNumOutputIdContent: `${pageNumber}-${outputItem?.id}-${spanContent}`,
                        value: JSON.stringify({
                          pageNumber,
                          content: spanContent,
                        }),
                      },
                      key: "pageNumOutputIdPos",
                    })
                  );
                }}
                outputItemId={outputItem?.id}
              >
                {spanContent}
              </AnnotationSpan>
            );
          }
          return outputItem?.content?.slice(segment.start, segment.end);
        })}
      </TextContainer>
      {annotationsFilteringMode !== "AI labels" && (
        <LegendContainer>
          <Legend>
            {annotationLabels.map((label, i) => (
              <LegendLabelInput
                key={`${label}-${i}`}
                label={label}
                annotations={annotations}
                onNewAnnotations={newAnnos => setAnnotations(newAnnos)}
              />
            ))}
          </Legend>
        </LegendContainer>
      )}
    </Container>
  );
};

export default TextLabellingArea;
