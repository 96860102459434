import { useState } from "react";
import styled from "styled-components";

import { DownloadIcon, ExcelIcon, FilesIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import SmallButton from "components/ui/SmallButton";
import TextInput from "components/inputs/TextInput";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Fragment } from "react";
import { Editor } from "@monaco-editor/react";
import { FileCopy } from "@material-ui/icons";

const DB_TYPES = [
  { name: "MONGO", label: "MongoDB" },
  { name: "MYSQL", label: "MySQL" },
];
const FIELDS = [
  {
    name: "username",
    type: "string",
    _meta: {
      dbTypes: ["MONGO", "MYSQL"],
      placeholder: "win-test",
      placeholderMongo: "test-user",
    },
  },
  {
    name: "password",
    type: "password",
    _meta: {
      dbTypes: ["MONGO", "MYSQL"],
      placeholder: "admin",
      placeholderMongo: "test-password",
    },
  },
  {
    name: "host",
    type: "string",
    _meta: {
      dbTypes: ["MONGO", "MYSQL"],
      placeholder: "35.189.120.201",
      placeholderMongo: "35.189.120.201",
    },
  },
  {
    name: "port",
    type: "number",
    _meta: {
      dbTypes: ["MONGO", "MYSQL"],
      placeholder: "3306",
      placeholderMongo: "27017",
    },
  },
  {
    name: "database",
    type: "string",
    _meta: {
      dbTypes: ["MONGO", "MYSQL"],
      placeholder: "cabbage",
      placeholderMongo: "test_db",
    },
  },

  {
    name: "collection",
    type: "string",
    _meta: { dbTypes: ["MONGO"], placeholderMongo: "country" },
  },
  {
    name: "table",
    type: "string",
    _meta: { dbTypes: ["MYSQL"], placeholder: "myTable" },
  },
];

const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ModalContent = styled.div`
  padding: 8px;
  width: 600px;
  height: 400px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const TabLinks = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
`;

const TabLinkItem = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  font-weight: 600;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props.isSelected ? props.theme.color.primary : props.theme.color.closer1};
`;

const Select = styled.select`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledInput = styled(TextInputSquare)`
  /* font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid ${props => props.theme.color.closer1};
  width: 100%;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  } */
`;

const Input = styled.input`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  padding: 4px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  padding: 8px;
  svg {
    height: 14px;
    width: 14px;
    opacity: 0.6;
  }
`;

const TABS = ["File", "Database", "API"];

const CURL_CODE = `curl --location --request GET 'https://api.flow.boltzbit.com/v1/source?templateId=65576968aef30f2f6423f56a' \\
--header 'Content-Type: application/json' \\
`;

const DownloadSourceTableModal = ({ onClickDownloadFile = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);

  const [dbTypeName, setDbTypeName] = useState(DB_TYPES[0]?.name);
  const [dbSource, setDbSource] = useState({
    username: "",
    password: "",
    host: "",
    port: "",
    database: "",
    collection: "",
    table: "",
  });

  const fields = FIELDS.filter(field =>
    field._meta.dbTypes.includes(dbTypeName)
  );

  let content = null;
  if (selectedTab === "File") {
    content = (
      <div
        style={{
          marginTop: "10px",
          display: "grid",
          width: "160px",
        }}
      >
        <SmallButton
          icon={<ExcelIcon />}
          style={{
            marginBottom: "10px",
            padding: "4px 8px",
            gridTemplateColumns: "auto auto",
            gap: "4px",
          }}
          value="Download Excel"
          onClick={onClickDownloadFile}
        />
        <SmallButton
          icon={<FilesIcon height="18px" />}
          style={{
            marginBottom: "10px",
            padding: "4px 8px",
            gridTemplateColumns: "auto auto",
            gap: "4px",
          }}
          value="Download CSV"
          onClick={onClickDownloadFile}
        />
      </div>
    );
  }

  if (selectedTab === "Database") {
    content = (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: "8px",
          rowGap: "4px",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <div style={{ gridColumn: "span 2" }}>
          <Select
            value={dbTypeName}
            onChange={e => setDbTypeName(e.target.value)}
          >
            {DB_TYPES.map(dbType => (
              <option key={dbType.name} value={dbType.name}>
                {dbType.label}
              </option>
            ))}
          </Select>
        </div>
        {fields.map(field => (
          <Fragment key={field.name}>
            <label>{field.name}</label>
            <Input
              value={dbSource[field.name]}
              onChange={e =>
                setDbSource({ ...dbSource, [field.name]: e.target.value })
              }
              type={field.type}
              placeholder={
                dbTypeName === "MONGO"
                  ? field._meta.placeholderMongo
                  : field._meta.placeholder
              }
              onKeyDown={e => {
                const placeholder =
                  dbTypeName === "MONGO"
                    ? field._meta.placeholderMongo
                    : field._meta.placeholder;
                if (e.key === "Tab") {
                  setDbSource({ ...dbSource, [field.name]: placeholder });
                }
              }}
            />
          </Fragment>
        ))}

        <div style={{ gridColumn: "span 2" }}>
          <SmallButton
            style={{ marginTop: "80px", padding: "4px 8px" }}
            value={`Export to database`}
          />
        </div>
      </div>
    );
  }

  if (selectedTab === "API") {
    content = (
      <div style={{ height: "100px" }}>
        <IconContainer
          onClick={() => {
            navigator.clipboard.writeText(CURL_CODE);
          }}
        >
          <FileCopy />
        </IconContainer>
        <Editor
          value={CURL_CODE}
          language="shell"
          options={{
            readOnly: true,
          }}
          style={{ height: "100px" }}
        />
      </div>
    );
  }

  return (
    <>
      <StyledDownloadIcon onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Download</Title>
          <TabLinks>
            {TABS.map(tab => (
              <TabLinkItem
                key={tab}
                isSelected={tab === selectedTab}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </TabLinkItem>
            ))}
          </TabLinks>
          {content}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DownloadSourceTableModal;
