import styled from "styled-components";

export const DataCellContainer = styled.div.attrs(props => ({
  style: {
    gridRow: props.row,
    gridColumn: props.col,
    gridAutoColumns: `calc(100% / ${props.numCols})`,
  },
}))`
  display: grid;
  grid-auto-flow: column;
`;
