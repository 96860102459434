import { apiGet, apiPost } from "api/api-http-methods";
import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";
import { parseJson } from "utils/common";

export const getTextSearchResults = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/text-search",
    params
  );
  return { data, error };
};

export const getTableSearchResults = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/table-search",
    params
  );
  return { data, error };
};

export const getSmartSearchResults = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/smartrecords",
    params
  );
  return { data, error };
};

export const getSmartSearchResultsV2 = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/smartrecords/search",
    params
  );
  return { data, error };
};

export const getSmartSearchSingleTableResults = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/tables/search",
    params
  );
  return { data, error };
};

export const getTablesResultsByValue = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/tables/values",
    params
  );
  return { data, error };
};

export const getMetaRecordByFileId = async fileId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/search-service/api/v1/metas/${fileId}`
  );
  return { data, error };
};

export const patchMetaRecord = async (metaRecordId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/search-service/api/v1/metas/${metaRecordId}`,
    null,
    fieldsToUpdate
  );
  return { data, error };
};

export const getMetaRecords = async () => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/metas"
  );
  return { data, error };
};

export const getRecommendationsForQuery = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/recommend",
    params
  );
  return { data, error };
};

export const getSmartRecordsForAxesEmails = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/smartrecords/axes-emails",
    params
  );
  return { data, error };
};

export const getSearchWithinDocument = async (fileId, params) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/search-service/api/v1/documents/${fileId}/search`,
    params
  );
  return { data, error };
};

export const getTableLineItems = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/search-service/api/v1/table-line-items",
    params
  );
  return { data, error };
};

export const postTableLineItems = async (params, body) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/search-service/api/v1/table-line-items",
    params,
    body
  );
  return { data, error };
};

export const getEntities = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/chat-service/api/v1/entities/search",
    params
  );
  return { data, error };
};

export const postEntities = async (params, body) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/chat-service/api/v1/entities/ingest",
    params,
    body
  );
  return { data, error };
};

export const deleteEntity = async entityId => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/search-service/api/v1/entities/${entityId}`
  );
  return { data, error };
};

export const getSmartRecord = async smartRecordId => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/chat-service/api/v1/smartrecords/${smartRecordId}`
  );
  return { data, error };
};

export const patchSmartRecord = async (smartRecordId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/chat-service/api/v1/smartrecords/${smartRecordId}`,
    null,
    fieldsToUpdate
  );
  return { data, error };
};

export const getConversationsChatSearchResults = async params => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/api/v1/conversations/chat-search",
    params
  );
  return { data, error };
};

export const getChatSearchResults = async params => {
  const { data, error } = await apiGet(
    "/solutions/ocr/chat-service/api/v1/chat/chat-search",
    params
  );
  return { data, error };
};

export const getKeywordRecommendation = async (
  params = {},
  abortController = {}
) => {
  const { data, error } = await apiGet(
    "/solutions/ocr/chat-service/api/v1/keywords/keyword-recommendation",
    params,
    abortController
  );
  if (data?.objectJSON) {
    return {
      data: parseJson(data.objectJSON),
      error: null,
    };
  }
  return { data, error };
};

export const postChatflowGenerate = async (
  params = {},
  body = {},
  abortController = {}
) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/chat-service/api/v1/chatflow/generate",
    params,
    body,
    abortController
  );
  if (data?.objectJSON) {
    return {
      data: parseJson(data.objectJSON),
      error: null,
    };
  }
  return { data, error };
};

export const postChatflowSearch = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/chat-service/api/v1/chatflow/search",
    params,
    body
  );
  if (data?.objectJSON) {
    return {
      data: parseJson(data.objectJSON),
      error: null,
    };
  }
  return { data, error };
};

export const postPromptRecommendation = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/bz-api/v1/ai/prompt-recommendations",
    params,
    body
  );
  return { data, error };
};

export const postRegenAll = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/chat-service/api/v1/chatflow/regen-all",
    params,
    body
  );
  return { data, error };
};
