import styled from "styled-components";
import { uniq } from "lodash";
import { Link } from "react-router-dom";

import { parseJson } from "utils/common";

const getAllValuesOfKey = (records, fieldName) => {
  const names = records?.map(rec => rec[fieldName]);
  return uniq(names).sort();
};

const Table = styled.div`
  display: grid;
  grid-template-rows: repeat(${props => props.numRows}, auto);
  grid-template-columns: repeat(${props => props.numCols}, auto);
  width: max-content;
  border: 0.5px solid ${props => props.theme.color.closer2};
  overflow: auto;
`;

const ColHeader = styled.div`
  font-weight: 600;
  grid-row: 1;
  padding: 2px 10px;
  border: 0.5px solid ${props => props.theme.color.closer2};
  display: flex;
  align-items: center;
  gap: 5px;
`;

const DataCellContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc(100% / ${props => props.numCols});
  border: 0.5px solid ${props => props.theme.color.closer2};

  padding: 2px 10px;
`;

const ResultsTableSimple = ({ rowKey = "ID", records = [], columnNames = [], tableRowNames = [], className }) => {
  const rowNames = !tableRowNames?.length ? getAllValuesOfKey(records, rowKey) : tableRowNames;

  return (
    <Table numCols={columnNames.length + 1} numRows={records.length} className={className}>
      {columnNames.map((colName, colNameIndex) => {
        return (
          <ColHeader key={`${colName}-colheader`} col={colNameIndex + 1}>
            {colName}
          </ColHeader>
        );
      })}
      {columnNames
        ?.map((colName, colNameIndex) =>
          rowNames.map((rowName, rowNameIndex) => {
            const cellRecord = records?.find(record => record?.ID === rowName && record?.TOPIC === colName);
            const valueJson = parseJson(cellRecord?.Value);

            return (
              <DataCellContainer
                key={`${colNameIndex}-${rowNameIndex}`}
                style={{ gridRow: rowNameIndex + 3, gridColumn: colNameIndex + 1 }}
              >
                {valueJson?.ClickUrl ? (
                  <Link to={`${valueJson?.ClickUrl}&isChatVisible=true`}>{valueJson?.RawValue}</Link>
                ) : (
                  valueJson?.RawValue
                )}
              </DataCellContainer>
            );
          })
        )
        ?.flat()}
    </Table>
  );
};

export default ResultsTableSimple;
