import { ChevronLeft, Done } from "@material-ui/icons";
import { postChatIngestToBoltzhb } from "api/services/chatService";
import { getDataIngestionJobUntilFinish } from "api/services/dataService";
import { getCubesFindByKey } from "api/services/projectService";
import { Gap } from "components/Layout";
import ReferenceModalSpan, { useIsDebug } from "components/ReferenceModalSpan";
import ReferenceModalSpanWithContext, {
  getMetaId,
} from "components/ReferenceModalSpanWithContext";
import ButtonWord from "components/ui/ButtonWord";
import {
  BoltzhubLogoInner,
  LogoInner,
  SaveTickIcon,
  TickIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { isNil, range, set } from "lodash";
import { IconButton } from "pages/word-docs/:wordDocId/new-shape";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getBlocksFromContent } from "utils/word-conversion-utils";

const StyledBoltzflowLogo = styled(BoltzhubLogoInner)`
  margin-top: 4px;
  fill: white;
`;

const LogoContainer = styled.div`
  cursor: pointer;
  background: linear-gradient(176.39deg, #497ff3 39.89%, #38d0da 105.35%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  position: relative;
  z-index: 1;
  /* box-shadow: 0px 9px 10px 0px #00000012; */
`;

const Container = styled.div`
  background-color: white;
  padding: 16px;
  white-space: pre-wrap;
  border-radius: 8px;
  z-index: 0;

  top: 36px;
  position: absolute;
  left: -230px;
  width: 270px;

  box-shadow: 0px 9px 27px 0px #00000022;
`;

const ReferenceContainer = styled.div`
  background-color: white;
  padding: 8px;
  white-space: pre-wrap;

  position: absolute;
  left: -180px;
  border: 1px solid #e0e0e0;
  width: 200px;
  line-height: 1.2;
`;

const LinkText = styled.div`
  color: ${props => props.theme.color.primary};
  text-decoration: underline;
  font-weight: 500;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTextArea = styled.textarea`
  resize: none;
  padding: 8px;
  height: 100px;
  width: 100%;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  border: 2px solid #e8ecef;
  border-radius: 6px;
  padding: 11px 15px;
  font-weight: 400;
  font-family: "Montserrat";
  outline: none;
  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
  ::placeholder {
    color: ${props => props.theme.color.closer1_5};
  }
`;

const PulsingDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const ContentText = styled.div`
  margin: 10px 0;
`;

const HUB_BASE_URL = "https://hub.boltzbit.com";

const WordDocModalTrigger = ({
  context,
  onNewStyles = () => {},
  textStyles = [],
  onPressSend = prompt => {},
  onPressCancel = () => {},
  onFocus = () => {},
  isGenerating = false,
  initialPrompt = "",
  content = "",
  modalBlocks = [],
  sources = [],
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [isIngesting, setIsIngesting] = useState(false);
  const [ingestedQueryIds, setIngestedQueryIds] = useState([]);
  const [flowCube, setFlowCube] = useState(null);

  useEffect(() => {
    doPopulateFlowCube();
  }, []);

  useEffect(() => {
    setPrompt(initialPrompt || "");
    // if (!initialPrompt) {
    //   setIsOpen(false);
    // }
  }, [initialPrompt]);

  useEffect(() => {
    if (prompt) {
      setIsOpen(true);
    }
  }, [prompt]);

  const doPopulateFlowCube = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCube(data);
  };

  const meta = context?.textStyle?.meta;
  const metas = context?.textStyle?.metas;

  const doIngestDataPoint = async queryId => {
    setIsIngesting(true);

    const { blocks } = getBlocksFromContent(content);
    const ingestBody = {
      content: {
        blocks,
        sources,
      },
    };

    const { data: job } = await postChatIngestToBoltzhb(
      { queryId },
      ingestBody
    );
    await getDataIngestionJobUntilFinish(job?.dataIngestionJobId?.id);

    setIsIngesting(false);
    setIngestedQueryIds([...ingestedQueryIds, queryId]);
  };

  if (meta || metas) {
    let tabMetas = metas || [meta];
    tabMetas = tabMetas?.map(meta => ({
      id: getMetaId(meta, ""),
      ...meta,
    }));
    return (
      <>
        <LogoContainer onClick={() => setIsOpen(prev => !prev)}>
          <StyledBoltzflowLogo height="20px" isGenerating={isGenerating} />
        </LogoContainer>
        <ReferenceContainer>
          <div style={{ fontWeight: 500 }}>Content</div>
          <ContentText style={{ maxHeight: "100px", overflow: "auto" }}>
            {meta?.value}
          </ContentText>
          <div style={{ fontWeight: 500, marginBottom: 10 }}>Sources</div>
          <ReferenceModalSpanWithContext
            blocks={modalBlocks}
            isTooltipDisabled
            metas={tabMetas}
          >
            {tabMetas?.map(meta => (
              <LinkText>
                {meta?.fileName} (page {meta?.pageNumber + 1})
              </LinkText>
            ))}
          </ReferenceModalSpanWithContext>
        </ReferenceContainer>
      </>
    );
  }

  const queryId = context?.textStyle?.queryId;

  return (
    <>
      <LogoContainer onClick={() => setIsOpen(prev => !prev)}>
        <StyledBoltzflowLogo height="20px" isGenerating={isGenerating} />
      </LogoContainer>
      {/* <Modal open={isOpen} handleClose={() => setIsOpen(false)}> */}
      <Container style={{ display: isOpen ? "block" : "none" }}>
        {isGenerating && (
          <div
            style={{
              position: "absolute",
              top: 5,
              right: 36,
              display: "flex",
              gap: 4,
            }}
          >
            <PulsingDot style={{ animationDelay: "0s" }} />
            <PulsingDot style={{ animationDelay: "0.2s" }} />
            <PulsingDot
              style={{
                animationDelay: "0.2s",
              }}
            />
          </div>
        )}
        {/* <div
          style={{
            display: areBtnsVisible ? "flex" : "none",
            gap: "10px",
          }}
        >
          <button onClick={boldenSelection}>Bold</button>
          <button onClick={highlightSelection}>Highlight</button>
          <button onClick={clearSelectionStyles}>Clear styles</button>
        </div> */}
        {/* {isDebug && <div>{JSON.stringify(context, null, 2)}</div>} */}
        <StyledTextArea
          disabled={isGenerating}
          placeholder="Prompt"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
          onFocus={onFocus}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              queryId && !ingestedQueryIds?.includes(queryId)
                ? "1fr 1fr auto"
                : "1fr 1fr",
            gap: "8px",
            marginTop: "8px",
            height: "32px",
            alignItems: "center",
          }}
        >
          <ButtonWord
            isPrimary
            disabled={isGenerating || !prompt}
            onClick={() => onPressSend(prompt)}
          >
            Send
          </ButtonWord>
          <ButtonWord
            disabled={!isGenerating}
            isPrimary
            onClick={() => onPressCancel()}
          >
            Cancel
          </ButtonWord>
          {queryId && !ingestedQueryIds?.includes(queryId) && (
            <IconButton
              disabled={isIngesting}
              onClick={() => doIngestDataPoint(queryId)}
            >
              <Done />
            </IconButton>
          )}
          {ingestedQueryIds?.includes(queryId) && (
            <IconButton
              onClick={() => {
                const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                window.open(url, "_blank");
              }}
            >
              <Done style={{ fill: "#0191ff" }} />
            </IconButton>
          )}
        </div>
      </Container>
      {/* </Modal> */}
    </>
  );
};

export default WordDocModalTrigger;
