import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/chatServiceEndpoints";
import { last, shuffle } from "lodash";

export const postWordDocIngestToBoltzhub = async (params = {}, body = {}) => {
  const { data, error } = await backend.postWordDocIngestToBoltzhub(
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const postTableHeaderLabel = async (params = {}, body = {}) => {
  const { data, error } = await backend.postTableHeaderLabel(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getMetas = async (params = {}) => {
  const { data, error } = await backend.getMetas(params);
  return { data, error: getHandlingFromError(error) };
};

export const deleteMetasRemoveKey = async (fileId, params = {}, body = {}) => {
  const { data, error } = await backend.deleteMetasRemoveKey(
    fileId,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const patchMetasUpdate = async (fileId, params = {}, body = {}) => {
  const { data, error } = await backend.patchMetasUpdate(fileId, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getSigmas = async (params = {}) => {
  const { data, error } = await backend.getSigmas(params);
  // data[0] = {
  //   ...data[0],
  //   // columnNameToMeta: {
  //   //   "Year Built": {
  //   //     fileId: "6526782c46efdb257a67fbfb",
  //   //     fileName: "Ferrari Report",
  //   //     pageNumber: 19,
  //   //     annotationId: 1,
  //   //   },
  //   // },
  // };
  const filteredRecords = data?.filter(sigma => !sigma?.isDeleted) || [];
  // filteredRecords[filteredRecords.length - 1] = {
  //   ...filteredRecords[filteredRecords.length - 1],
  //   status: "IN_PROGRESS",
  // };
  return { data: filteredRecords, error: getHandlingFromError(error) };
};

export const patchSigma = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchSigma(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postEmailsIngestToBoltzhub = async (params = {}, body = {}) => {
  const { data, error } = await backend.postEmailsIngestToBoltzhub(
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const getTemplateDashboardConfig = async (params = {}) => {
  const { data, error } = await backend.getTemplateDashboardConfig(params);
  return { data, error: getHandlingFromError(error) };
};

export const patchDashboardConfig = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchDashboardConfig(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postGenerateComponent = async (params = {}, body = {}) => {
  const { data, error } = await backend.postGenerateComponent(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postDashboardConfigCalculateResults = async (
  id = "",
  params = {},
  body = {}
) => {
  if (!id) {
    return { data: null, error: null };
  }

  const { data, error } = await backend.postDashboardConfigCalculateResults(
    id,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const postDashboardConfigExecute = async (params = {}, body = {}) => {
  const { data, error } = await backend.postDashboardConfigExecute(
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const getCommandRecommendations = async (params = {}) => {
  const { data: dashConfig } = await getTemplateDashboardConfig({
    wordDocId: params?.wordDocId,
  });

  const columns =
    dashConfig?.config?.components?.find(comp => comp?.id === "source_records")
      ?.columns || [];

  const recos = [];

  for (let i = 0; i < 3; i++) {
    const randomColumns = shuffle(columns);
    let endIndex = Math.floor(Math.random() * (randomColumns?.length / 2));
    if (endIndex <= 1) {
      endIndex = 2;
    }
    const randomColumnNames = randomColumns
      ?.map(column => column?.name)
      ?.slice(0, endIndex)
      ?.map(name => {
        if (name === "emailSubject") {
          return "Email Subject";
        }
        if (name === "receivedAt") {
          return "Received Time";
        }
        return name;
      });

    let command = `sum ${randomColumnNames
      ?.slice(0, -1)
      ?.join(", ")} grouping by ${last(randomColumnNames)}`;

    if (Math.random() > 0.6) {
      command = `total ${randomColumnNames?.[0]}`;
    }

    recos.push(command);
  }

  return { data: recos, error: null };
};

export const getKnowledgeConfigs = async (params = {}) => {
  const { data, error } = await backend.getKnowledgeConfigs(params);
  return { data, error: getHandlingFromError(error) };
};

export const getKnowledgeConfig = async (id, params = {}) => {
  const { data, error } = await backend.getKnowledgeConfig(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const patchKnowledgeConfig = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchKnowledgeConfig(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postKnowledgeConfig = async (params = {}, body = {}) => {
  const { data, error } = await backend.postKnowledgeConfig(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteKnowledgeConfig = async (id, params = {}) => {
  const { data, error } = await backend.deleteKnowledgeConfig(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const postAiAgentGenerate = async (params = {}, body = {}) => {
  const { data, error } = await backend.postAiAgentGenerate(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postFlowDatabaseTemplatesBuild = async (
  templatedId,
  params = {},
  body = {}
) => {
  const { data, error } = await backend.postFlowDatabaseTemplatesBuild(
    templatedId,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const getFlowDatabaseTemplates = async (params = {}) => {
  const { data, error } = await backend.getFlowDatabaseTemplates(params);
  return { data, error: getHandlingFromError(error) };
};

export const deleteFlowDatabaseTemplates = async (id, params = {}) => {
  const { data, error } = await backend.deleteFlowDatabaseTemplates(id, params);
  return { data, error: getHandlingFromError(error) };
};

export const patchFlowDatabaseTemplates = async (
  id,
  params = {},
  body = {}
) => {
  const { data, error } = await backend.patchFlowDatabaseTemplates(
    id,
    params,
    body
  );
  return { data, error: getHandlingFromError(error) };
};

export const postChatIngestToBoltzhb = async (params = {}, body = {}) => {
  const { data, error } = await backend.postChatIngestToBoltzhb(params, body);
  return { data, error: getHandlingFromError(error) };
};
