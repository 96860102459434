import styled from "styled-components";
import { Link } from "react-router-dom";

import { ChatIcon } from "components/ui/Icons";
import NavHeader from "components/NavHeader";
import ChatAssistant from "components/ChatAssistant";

const Container = styled.div`
  display: grid;
  align-items: start;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 67px;
  padding-right: 14px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  position: relative;
  background-color: #f9fbfd;
  width: max-content;
  box-shadow: none;
`;

const AssistantTitle = styled.div`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
`;

const ChatContainer = styled.div`
  margin-bottom: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const QuickChatPage = () => {
  return (
    <Container>
      <DocNameAndSourcesModal>
        <Link to="/working-files?type=CHAT">
          <ChatIcon
            height="40px"
            style={{
              paddingLeft: "14px",
              paddingRight: "10px",
              fill: "#0191ff",
            }}
          />
        </Link>
        <InputAndMenuBar>
          <AssistantTitle>Assistant</AssistantTitle>
        </InputAndMenuBar>
        <StyledNavHeader isProfileOnly />
      </DocNameAndSourcesModal>
      <ChatContainer
        style={{
          margin: "0 auto",
          marginBottom: "20px",
          overflow: "auto",
          height: "calc(100% - 20px)",
          width: "800px",
        }}
      >
        <ChatAssistant
          initialPrompts={[
            "Slides for fundraising of a startup developing ai powered pet care platform",
            "Slides for a quarterly company update",
            "Database schema for a company of 10 emplyees",
          ]}
        />
      </ChatContainer>
    </Container>
  );
};

export default QuickChatPage;
