import { postIngestWebpage } from "api/backend/filesEndpoints";
import {
  postAudioFilesFormData,
  postBulkUploadFormData,
} from "api/services/filesService";
import usePollFile from "api/services/usePollFile";
import ButtonIcon from "components/ui/ButtonIcon";
import { UploadCloudIcon } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Label = styled.label`
  cursor: pointer;
  width: max-content;
  display: flex;

  input {
    display: none;
  }
  :hover {
    opacity: 0.6;
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  padding: 0 22px;
  font-weight: 600;
  white-space: nowrap;
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #d1d1d1;
  padding: 0px 4px;
  width: 100%;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FileFromUrlInput = ({ onFileUploaded = newFile => {} }) => {
  const [hasTriggeredIngestion, setHasTriggeredIngestion] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [uploadErr, setUploadErr] = useState(null);
  const [file] = usePollFile(fileId);
  const [url, setUrl] = useState("");

  const doPostIngestWebPage = async () => {
    setHasTriggeredIngestion(true);
    setUrl("");
    const { data, error } = await postIngestWebpage({}, { url });
    setUploadErr(error);
    setFileId(data?.id);
  };

  const doPostFiles = async files => {
    const { data, error } = await postBulkUploadFormData(
      { directoryPath: "/source-files" },
      { files }
    );
    setUploadErr(error);
    setFileId(data?.[0]?.id);
  };

  const doPostAudioFiles = async files => {
    const { data, error } = await postAudioFilesFormData(
      { directoryPath: "/source-files" },
      { files }
    );
    setUploadErr(error);
    setFileId(data?.id);
  };

  useEffect(() => {
    if (file?.progress === 100) {
      onFileUploaded(file);
      setFileId(null);
      setHasTriggeredIngestion(false);
    }
  }, [JSON.stringify(file)]);

  let loadingMsg = "";
  if (hasTriggeredIngestion && !fileId) {
    loadingMsg = "Scanning URL...";
  }
  if (fileId) {
    loadingMsg = "Processing...";
  }

  return (
    <div>
      {!fileId && !hasTriggeredIngestion && (
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <Input
            value={url}
            onChange={e => setUrl(e.target.value)}
            placeholder="Enter URL"
          />
          {/* <StyledButtonIcon isActive>
            <UploadCloudIcon
              height="14px"
              style={{ stroke: "white", marginRight: "8px" }}
            />
            Upload
          </StyledButtonIcon>
          <input
            type="file"
            multiple
            accept=".pdf, .wav"
            onChange={e => {
              setHasPickedFile(true);
              const filesArray = [...e.target.files];
              const firstFile = filesArray?.[0];
              if (firstFile?.type === "audio/wav") {
                doPostAudioFiles(filesArray);
                return;
              }
              doPostFiles(filesArray);
            }}
          /> */}
          <StyledButtonIcon
            isActive
            isDisabled={!url}
            onClick={doPostIngestWebPage}
          >
            Convert to PDF
          </StyledButtonIcon>
        </div>
      )}
      {(!!fileId || hasTriggeredIngestion) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            padding: "4px 0",
          }}
        >
          <progress value={file?.progress || 0} max="100" />
          <div style={{ color: "#6e6e6e" }}>{loadingMsg}</div>
        </div>
      )}
      {uploadErr && <div>{uploadErr?.message}</div>}
    </div>
  );
};

export default FileFromUrlInput;
