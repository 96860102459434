import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useSearchParams } from "react-router-dom";

const getScaleFactor = (dataWidth, dataHeight, containerWidth, containerHeight) => {
  if (dataHeight > dataWidth) {
    return containerHeight / dataHeight;
  }
  return containerWidth / dataWidth;
};

const getAnnotationUnderMouse = (mouseEvent, allAnnotations, scaleFactor = 1, annoCanvasRef) => {
  const { clientX, clientY } = mouseEvent;
  const annoCanvasRect = annoCanvasRef.current.getBoundingClientRect();
  const canvasX = clientX - annoCanvasRect.x;
  const canvasY = clientY - annoCanvasRect.y;

  let annoUnderMouse = null;
  allAnnotations.forEach(annotation => {
    if (
      canvasX >= annotation?.x * scaleFactor &&
      canvasX <= (annotation?.x + annotation?.w) * scaleFactor &&
      canvasY >= annotation?.y * scaleFactor &&
      canvasY <= (annotation?.y + annotation?.h) * scaleFactor
    ) {
      annoUnderMouse = annotation;
    }
  });
  return annoUnderMouse;
};

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  ${({ dataWidth, dataHeight, width, height, scaleFactor, noCentering }) => {
    if (noCentering) {
      return;
    }
    if (dataWidth > dataHeight) {
      return `
        transform: translateY(${height / 2 - (dataHeight * scaleFactor) / 2}px);
      `;
    }
    return `transform: translateX(${(window.innerWidth - 50) / 14 - (dataWidth * scaleFactor) / 14}px)`;
  }}
`;

const CenteringWrapper = styled.div`
  position: relative;
  overflow: ${props => (props.isScrollingEnabled ? "auto" : "hidden")};
  background-color: ${props => props.theme.color.closer0};
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${props => props.theme.color.closer0};
`;

const Canvas = styled.canvas`
  transform-origin: top left;
  transform: scale(${props => props.scaleFactor});
  image-rendering: pixelated;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const AnnoCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: scale(${props => props.scaleFactor});
  image-rendering: pixelated;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`;

const ImageWithOCRAnnotations = ({
  zoomScale = 1,
  width = 90,
  height = 90,
  base64Data,
  annotations_0 = [],
  annotations_1 = [],
  annotations_2 = [],
  annotations_3 = [],
  className,
  onClickAnnotation = () => {},
  selectedOutputItem,
}) => {
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const annoCanvasRef = useRef(null);

  const [highlightedAnnotation, setHighlightedAnnotation] = useState(null);

  const [dataWidth, setDataWidth] = useState(1);
  const [dataHeight, setDataHeight] = useState(1);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current?.width, canvasRef.current?.height);

    const image = new Image();
    image.onload = () => {
      setDataWidth(image.width);
      setDataHeight(image.height);
      ctx.drawImage(image, 0, 0, image.width, image.height);
    };
    image.src = `data:image/png;base64,${base64Data}`;
  }, [base64Data]);

  useEffect(() => {
    if (!highlightedAnnotation) {
      drawAnnotations();
      return;
    }

    const annoCtx = annoCanvasRef.current.getContext("2d");
    annoCtx.fillStyle = "#b701ff22";
    if (annotations_3?.find(anno => anno?.id === highlightedAnnotation?.id)) {
      annoCtx.fillStyle = "#00C85322";
    }
    annoCtx.fillRect(
      highlightedAnnotation?.x,
      highlightedAnnotation?.y,
      highlightedAnnotation?.w,
      highlightedAnnotation?.h
    );
  }, [highlightedAnnotation]);

  useEffect(() => {
    selectSearchResultAnnotation();
  }, [searchParams?.get("annotationIdToHighlight"), dataWidth, dataHeight]);

  useEffect(() => {
    drawAnnotations();
  }, [
    annotations_0,
    annotations_1,
    annotations_2,
    annotations_3,
    annoCanvasRef?.current,
    dataWidth,
    dataHeight,
    base64Data,
    selectedOutputItem,
  ]);

  const selectSearchResultAnnotation = () => {
    const annotationIdToHighlight = parseInt(searchParams?.get("annotationIdToHighlight"));
    const annotationToHighlight = [annotations_0, annotations_1, annotations_2, annotations_3]
      ?.flat()
      ?.find(anno => anno?.id === annotationIdToHighlight);

    onClickAnnotation(annotationToHighlight);
  };

  const drawAnnotations = () => {
    const annoCtx = annoCanvasRef.current.getContext("2d");
    annoCtx.clearRect(0, 0, annoCanvasRef.current?.width, annoCanvasRef.current?.height);

    annoCtx.fillStyle = "#22093300";
    annotations_0.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.fillStyle = "#c8540000";
    annotations_1.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.fillStyle = "#04788900";
    annotations_2.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.fillStyle = "#00C85300";
    annotations_3.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });

    annoCtx.strokeStyle = "#b701ff55";
    annoCtx.lineWidth = 5;
    annoCtx.beginPath();

    const { x, y, w, h } =
      [annotations_0, annotations_1, annotations_2, annotations_3]
        ?.flat()
        ?.find(anno => anno?.id === selectedOutputItem?.id) || {};
    annoCtx.rect(x, y, w, h);
    annoCtx.stroke();

    selectSearchResultAnnotation();
  };

  const scaleFactor = getScaleFactor(dataWidth, dataHeight, width, height);

  return (
    <CenteringWrapper className={className} isScrollingEnabled>
      {!base64Data && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      <ImageContainer
        dataWidth={dataWidth}
        dataHeight={dataHeight}
        width={width}
        height={height}
        scaleFactor={scaleFactor}
        ref={canvasContainerRef}
      >
        <Canvas ref={canvasRef} scaleFactor={scaleFactor} width={dataWidth} height={dataHeight} />
        <AnnoCanvas
          onMouseMove={e => {
            const allAnnotations = [annotations_0, annotations_1, annotations_2, annotations_3]?.flat();
            const annotationToHighlight = getAnnotationUnderMouse(e, allAnnotations, scaleFactor, annoCanvasRef);
            setHighlightedAnnotation(annotationToHighlight);
          }}
          onClick={() => onClickAnnotation(highlightedAnnotation)}
          ref={annoCanvasRef}
          scaleFactor={scaleFactor}
          width={dataWidth}
          height={dataHeight}
        />
      </ImageContainer>
    </CenteringWrapper>
  );
};

export default ImageWithOCRAnnotations;
