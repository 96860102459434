import { getExcelModelLoading } from "api/services/excelModelsService";
import { useState, useEffect } from "react";

const usePollExcelModelLoadingPercentage = excelModelId => {
  const [loadingNumber, setLoadingNumber] = useState(0);
  const [error, setError] = useState(null);
  const [pollIntervalId, setPollIntervalId] = useState(null);

  useEffect(() => {
    if (!excelModelId) {
      setLoadingNumber(0);
      return;
    }

    const intervalId = setInterval(doPopulateExcelModelLoading, 1000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [excelModelId]);

  useEffect(() => {
    if (loadingNumber === 1) {
      clearInterval(pollIntervalId);
    }
  }, [loadingNumber]);

  const doPopulateExcelModelLoading = async () => {
    const { data, error: statusErr } = await getExcelModelLoading(excelModelId);
    setError(statusErr);
    setLoadingNumber(parseFloat(data));
  };

  return Math.max(0, loadingNumber * 100);
};

export default usePollExcelModelLoadingPercentage;
