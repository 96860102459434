import { useState } from "react";
import styled from "styled-components";

const Input = styled.input`
  border: none;
  font-family: "Montserrat", sans-serif;
  outline: none;
  width: 200px;
`;

const DropContent = styled.div`
  position: fixed;
  background-color: white;
`;

const DropShadow = styled.div`
  position: absolute;
  z-index: -1;
  box-shadow: 0px 0px 5px #b7b7b7;
`;

const InputFocusDropdown = ({
  value = "",
  placeholder = "",
  onChange = e => {},
  dropContent = null,
  style = {},
  className = "",
}) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const [inputElement, setInputElement] = useState(null);
  const [contentElement, setContentElement] = useState(null);

  const inputRect = inputElement?.getBoundingClientRect();
  const contentRect = contentElement?.getBoundingClientRect();

  let shadowHeight = inputRect?.height;
  if (isFocussed) {
    shadowHeight = inputRect?.height + contentRect?.height || inputRect?.height;
  }

  return (
    <>
      <Input
        style={style}
        className={className}
        ref={newRef => setInputElement(newRef)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
      />
      <DropShadow
        style={{
          width: inputRect?.width,
          height: shadowHeight,
          top: inputRect?.top,
          left: inputRect?.left,
        }}
      />
      <DropContent
        onMouseDown={e => {
          e.preventDefault();
        }}
        ref={newRef => setContentElement(newRef)}
        style={{
          visibility: isFocussed ? "visible" : "hidden",
          width: inputRect?.width,
        }}
      >
        {dropContent}
      </DropContent>
    </>
  );
};

export default InputFocusDropdown;
