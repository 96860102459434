import { Gap } from "components/Layout";
import Modal from "components/ui/Modal";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import PagePreviewModalContent from "components/widgets/PagePreviewModalContent";
import { isNil, range } from "lodash";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  min-height: 620px;
  min-width: 1000px;
  max-width: 1000px;
  overflow: auto;
  border-radius: 0;

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"};
`;

const FileTextAndLink = styled.div`
  font-size: 22px;
`;

const FileLink = styled.div`
  display: inline;
  text-decoration: none;
  color: ${props => props.theme.color.primary};
`;

const RefText = styled.div`
  max-width: 800px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  white-space: pre-wrap;
`;

const MultiPagePreviewTextAndTableModal = ({
  open,
  handleClose,
  cellValue,
  tableDocumentLocation = {},
  cellLineItem = "",
  solrId = "",
  solrValue = "",
  tableName = "",
  tableId = "",
  cellRowIdValue = "",
  rowIdName = "",
  searchResults,
  isReferenceEditable = false,
  columnName = "",
  columnValue = "",
  columnDescription = "",
  onPressSaveValue = (newValue, newAnnotationId) => {},
  onPressVerify = checked => {},
  checked = false,
  refText = "",
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { fileId, pageNumber, annotationId, fileName, description } =
    tableDocumentLocation || {};

  const inputMetas = tableDocumentLocation?.inputMetas;
  const tabNames = range(0, inputMetas?.length)?.map(
    i => inputMetas?.[i]?.tabName || `Input ${i + 1}`
  );

  if (!inputMetas?.length) {
    return (
      <Modal open={open} handleClose={handleClose}>
        <ModalContent
          onMouseDown={e => {
            e.stopPropagation();
          }}
          onMouseUp={e => {
            e.stopPropagation();
          }}
        >
          <PagePreviewModalContent
            tableDocumentLocation={tableDocumentLocation}
          />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent
        onMouseDown={e => {
          e.stopPropagation();
        }}
        onMouseUp={e => {
          e.stopPropagation();
        }}
      >
        <FileTextAndLink>
          <FileLink>{fileName}</FileLink>
        </FileTextAndLink>
        <Gap />
        <RefText>{description}</RefText>
        <Gap />
        <NavWithTabsWithArrows
          selectedTabName={tabNames?.[selectedTabIndex]}
          tabNames={tabNames}
          onTabSelect={(_, i) => setSelectedTabIndex(i)}
        />
        <Gap />
        <PagePreviewModalContent
          tableDocumentLocation={inputMetas?.[selectedTabIndex]}
        />
      </ModalContent>
    </Modal>
  );
};

export default MultiPagePreviewTextAndTableModal;
