import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import CreateUserGroupModalTrigger from "components/CreateUserGroupModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import { Gap } from "components/Layout";
import TableFlat from "components/TableFlat";
import { CrossIcon, UploadFileIcon } from "components/ui/Icons";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import Tooltip from "components/ui/Tooltip";
import {
  getUserGroups,
  postUserGroups,
} from "api/services/authenticationService";
import useSearchParamsState from "hooks/useSearchParamsState";
import AddMemberToUserGroupModalTrigger from "components/AddMemberToUserGroupModalTrigger";

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  grid-column: span 2;
`;

const TeamNamesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTooltip = styled(Tooltip)`
  padding: 4px 8px;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.3);
  transform: translateX(0%) translateY(10%);
`;

const KickButton = styled(CrossIcon)`
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  width: 18px;
  height: 18px;
`;

const TransferButton = styled(UploadFileIcon)`
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  width: 18px;
  height: 18px;
`;

const TeamsView = ({ userName }) => {
  const [error, setError] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroupId, setSelectedUserGroupId] = useSearchParamsState({
    paramName: "selectedUserGroupId",
    initialValue: "",
  });

  useEffect(() => {
    doPopulateUserGroups();
  }, []);

  const doPopulateUserGroups = async () => {
    const { data } = await getUserGroups();
    setUserGroups(data);
    if (!selectedUserGroupId && data?.[0]?.id) {
      setSelectedUserGroupId(data?.[0]?.id);
    }
  };

  const doRemoveMember = async userGroupId => {
    const { error } = await postUserGroups(
      {},
      {
        id: selectedUserGroup?.id,
        sharedWith: selectedUserGroup?.sharedWith.filter(
          sharedWith => sharedWith?.userGroupId !== userGroupId
        ),
      }
    );
    setError(error);
    if (!error) {
      doPopulateUserGroups();
    }
  };

  const selectedUserGroup = userGroups.find(
    userGroup => userGroup?.id === selectedUserGroupId
  );
  const memberRows = selectedUserGroup?.sharedWith
    ?.map(sharedWith => {
      if (sharedWith?.accessLevel === "OWNER") {
        return [
          sharedWith?.userGroupId,
          sharedWith?.accessLevel,
          "ACTIVE",
          <div />,
          <div />,
        ];
      }

      return [
        sharedWith?.userGroupId,
        sharedWith?.accessLevel,
        "ACTIVE",
        <StyledTooltip title="Remove">
          <KickButton onClick={() => doRemoveMember(sharedWith?.userGroupId)} />
        </StyledTooltip>,
        <StyledTooltip title="Transfer ownership">
          <TransferButton />
        </StyledTooltip>,
      ];
    })
    ?.sort(row => {
      if (row[1] === "OWNER") {
        return -1;
      }
      return 1;
    });

  return (
    <>
      <Title>Your Teams</Title>
      <Gap />
      <TeamNamesContainer>
        <NavWithTabsWithArrows
          tabNames={userGroups.map(userGroup => userGroup?.name)}
          selectedTabName={
            userGroups?.find(userGroup => userGroup?.id === selectedUserGroupId)
              ?.name
          }
          onTabSelect={tabName => {
            const selectedUserGroup = userGroups.find(
              userGroup => userGroup?.name === tabName
            );
            setSelectedUserGroupId(selectedUserGroup?.id);
          }}
          tabOptionStyle={{
            padding: "8px 14px",
          }}
        />
        <StyledTooltip title="Add team">
          <CreateUserGroupModalTrigger
            onCreationSuccess={doPopulateUserGroups}
          />
        </StyledTooltip>
      </TeamNamesContainer>
      <Gap />
      <TableFlat
        style={{ width: "100%" }}
        tdStyle={{ padding: "8px 20px" }}
        headerRow={["Username", "Role", "Status", "", ""]}
        bodyRows={memberRows}
        footerRows={[
          [
            <td style={{ padding: "8px 12px" }}>
              <StyledTooltip title="Add member">
                <AddMemberToUserGroupModalTrigger
                  userGroup={selectedUserGroup}
                  onCreationSuccess={doPopulateUserGroups}
                />
              </StyledTooltip>
            </td>,
            <td />,
            <td />,
            <td />,
            <td />,
          ],
        ]}
      />
      <ErrorMessageModal message={error?.message} />
    </>
  );
};

export default TeamsView;
