import { postDownloadExcelFileOfTable } from "api/services/projectService";
import ComponentCodeModalTrigger from "components/ComponentCodeModalTrigger";
import InputWithState from "components/InputWithState";
import RecordsPieChart from "components/RecordsPieChart";
import RecordsSingleNumber from "components/RecordsSingleNumber";
import RecordsSummaryBarChart from "components/RecordsSummaryBarChart";
import RecordsSummaryGeomap from "components/RecordsSummaryGeomap";
import RecordsSummaryLineChart from "components/RecordsSummaryLineChart";
import RecordsSummaryMultiBarChart from "components/RecordsSummaryMultiBarChart";
import { CrossIcon, DownloadIcon } from "components/ui/Icons";
import styled from "styled-components";
import { getDisplayValue } from "utils/dashboard-utils";

const doDownloadTable = async (columns, records) => {
  const tableColumns = columns?.map(column => column?.name);
  const tablePreview = records?.map(record => {
    const newRow = {};
    tableColumns?.forEach(columnName => {
      const val =
        typeof record?.[columnName] === "string"
          ? record?.[columnName]
          : record?.[columnName]?.value || "";
      newRow[columnName] = {
        Value: val,
        RawValue: val,
      };
    });

    return newRow;
  });

  const body = {
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;
`;

const Container = styled.div`
  :hover {
    ${Actions} {
      opacity: 1;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 14px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;

  position: sticky;
  top: 0;

  z-index: 1;
`;

const CrossTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 4px;
`;

const RefreshTd = styled(Td)`
  border: none;
  opacity: 0;
  padding-left: 0;
  padding-right: 0;

  cursor: pointer;

  svg {
    fill: ${props => props.theme.color.closest};
    height: 16px;
    :hover {
      opacity: 0.6;
    }
  }
`;

const Tr = styled.tr`
  :hover {
    background-color: ${props => props.theme.color.closer0}AA;
    ${CrossTd} {
      opacity: 1;
    }
    ${RefreshTd} {
      opacity: 1;
    }
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const RecordsSummaryChart = ({
  name = "",
  onNewName = newName => {},
  sourceRecordsColumns = [],
  columns = [],
  records = [],
  naturalLanguageCommand = "",
  pythonCode = "",
  type = "",
  onConfirmEdit = newComponent => {},
  onClickCross = () => {},
  backgroundColor = "",
  isInitiallyOpen = false,
  onClose = () => {},
  isEditingDisabled = false,
}) => {
  return (
    <Container style={{ minWidth: "200px" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
      >
        <InputWithState
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            width: "200px",
            backgroundColor: "transparent",
            pointerEvents: isEditingDisabled ? "none" : "auto",
          }}
          initialValue={name}
          onApplyValue={newName => {
            onNewName(newName);
          }}
        />

        <Actions
          style={{
            visibility: isEditingDisabled ? "hidden" : "visible",
          }}
        >
          <StyledDownloadIcon
            onClick={() => doDownloadTable(columns, records)}
          />
          <ComponentCodeModalTrigger
            isInitiallyOpen={isInitiallyOpen}
            sourceRecordsColumns={sourceRecordsColumns}
            component={{
              type,
              naturalLanguageCommand,
              pythonCode,
              backgroundColor,
            }}
            onConfirmEdit={async newComponent => {
              onConfirmEdit(newComponent);
            }}
            onClose={onClose}
          />
          <CrossIcon
            height="12px"
            style={{ marginRight: "4px" }}
            onClick={onClickCross}
          />
        </Actions>
      </div>

      {type === "Table" && (
        <StyledTable>
          <thead>
            <Tr>
              {columns?.map(column => (
                <Th>{column?.name}</Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {records?.map(record => (
              <Tr>
                {columns?.map(column => (
                  <Td>
                    {getDisplayValue({ record, columnName: column?.name })}
                  </Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </StyledTable>
      )}

      {type === "Geomap" && <RecordsSummaryGeomap records={records} />}

      {type === "Bar Chart" && (
        <RecordsSummaryBarChart columns={columns} records={records} />
      )}

      {type === "Multi Bar Chart" && (
        <RecordsSummaryMultiBarChart columns={columns} records={records} />
      )}

      {type === "Line Chart" && (
        <RecordsSummaryLineChart columns={columns} records={records} />
      )}

      {type === "Single Number" && (
        <RecordsSingleNumber style={{ backgroundColor }} records={records} />
      )}

      {type === "Pie Chart" && (
        <RecordsPieChart columns={columns} records={records} />
      )}
    </Container>
  );
};

export default RecordsSummaryChart;
