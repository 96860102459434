import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";

import Modal from "components/ui/Modal";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { parseJson } from "utils/common";
import { patchFlowDatabaseTemplates } from "api/services/chatService";
import { PlusIcon, TrashIcon } from "components/ui/Icons";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  gap: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const BubblesContainer = styled.div`
  height: 600px;
  width: 580px;
  display: grid;
  gap: 4px;
  align-content: start;
  overflow: auto;
`;

const BubbleContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 20px;
  gap: 20px;
  background-color: ${props =>
    props.author === "user" ? props.theme.color.closer0 : "#D3E2FD"};
  padding: 8px;
  width: max-content;
  align-content: start;
  align-items: start;
`;

const StyledTextArea = styled.input`
  resize: none;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  width: 400px;
  border: 1px solid darkgrey;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const actionBubble = {
  author: "action",
  items: [{ type: "button", text: "See it in action" }],
};

const getBubblesWithTable = bubbles => {
  const newBubbles = bubbles
    ?.filter(bubble => bubble?.author !== "action")
    ?.map(bubble => {
      return {
        ...bubble,
        items: bubble?.items?.filter(item => !item?.columns),
      };
    });

  const lastBotIndex = newBubbles?.findLastIndex(
    bubble => bubble?.author === "bot"
  );
  if (lastBotIndex === -1) {
    return [...newBubbles, actionBubble];
  }

  newBubbles[lastBotIndex].items.push({
    columns: [
      { name: "name", label: "Column name" },
      { name: "description", label: "LLM instruction" },
    ],
    records: [],
  });

  return [...newBubbles, actionBubble];
};

const EditFlowDatabaseChatModal = ({ template = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pipelineName, setPipelineName] = useState("");
  const [prompt, setPrompt] = useState("");

  const [exampleInput, setExampleInput] = useState({
    text: "",
    subject: "",
    chatBubbles: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (template?._id) {
      setPipelineName(
        template.wordDocTemplate?.fileName?.replaceAll("template_", "")
      );
      setPrompt(template?.prompt);
      setExampleInput(parseJson(template?.exampleInput));
    }
  }, [JSON.stringify(template)]);

  const doPatchTemplate = async () => {
    setIsLoading(true);

    const { error } = await patchFlowDatabaseTemplates(
      template?._id,
      {},
      {
        prompt,
        exampleInput: JSON.stringify(exampleInput),
      }
    );

    setError(error);
    setIsLoading(false);
    window.location.reload();
    if (!error) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <button style={{ marginRight: "8px" }} onClick={() => setIsOpen(true)}>
        Edit chat
      </button>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <BubblesContainer>
            {exampleInput?.chatBubbles?.map((bubble, bubbleIndex) => {
              if (bubble?.author === "action") {
                return (
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      padding: "8px",
                    }}
                  >
                    ---GET STARTED BUTTON---
                  </div>
                );
              }

              return (
                <BubbleContainer author={bubble?.author}>
                  <select
                    value={bubble?.author}
                    onChange={e => {
                      const newBubbles = [...exampleInput?.chatBubbles];
                      newBubbles[bubbleIndex].author = e.target.value;
                      setExampleInput({
                        ...exampleInput,
                        chatBubbles: getBubblesWithTable(newBubbles),
                      });
                    }}
                  >
                    <option value="user">user</option>
                    <option value="bot">bot</option>
                  </select>
                  <div>
                    {bubble?.items.map((item, itemIndex) => {
                      if (item?.columns) {
                        return (
                          <div style={{ padding: "8px 0" }}>
                            ---TABLE HERE---
                          </div>
                        );
                      }

                      return (
                        <StyledTextArea
                          value={item?.text}
                          onChange={e => {
                            const newBubbles = [...exampleInput?.chatBubbles];
                            newBubbles[bubbleIndex].items[itemIndex].text =
                              e.target.value;
                            setExampleInput({
                              ...exampleInput,
                              chatBubbles: newBubbles,
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                  <StyledTrashIcon
                    onClick={() => {
                      const newBubbles = [...exampleInput?.chatBubbles];
                      newBubbles.splice(bubbleIndex, 1);
                      setExampleInput({
                        ...exampleInput,
                        chatBubbles: newBubbles,
                      });
                    }}
                  />
                </BubbleContainer>
              );
            })}
            <StyledPlusIcon
              onClick={() => {
                setExampleInput({
                  ...exampleInput,
                  chatBubbles: [
                    ...(exampleInput?.chatBubbles || []),
                    {
                      author: "user",
                      items: [{ text: "" }],
                    },
                  ],
                });
              }}
            />
          </BubblesContainer>
          <Gap />
          <div
            style={{
              padding: "12px",
            }}
          >
            ⓘ The table content is automatically added to the last bot bubble
          </div>
          <StyledButton
            variant="highlighted"
            isDisabled={!pipelineName || !prompt}
            value="Save"
            onClick={doPatchTemplate}
          />

          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditFlowDatabaseChatModal;
