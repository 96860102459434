import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { parseJson } from "utils/common";

import {
  getChatPromptRecommendation,
  postConversation,
  postConversationChat,
} from "api/services/conversationsService";
import { postTableConfigFromConversation } from "api/services/sigmaService";
import { getLoggedInUserProfileAvatarSrc } from "api/services/projectService";
import Button from "components/ui/Button";
import ResultsTableSimple from "components/ResultsTableSimple";
import useClickOutside from "hooks/useClickOutside";
import TextArea from "components/ui/TextArea";
import { KeyboardTab } from "@material-ui/icons";

const Container = styled.div`
  /* height: calc(100vh - 48px - 71px - 50px); */
  display: grid;
  grid-template-rows: 1fr 100px;
`;

const StyledTextInput = styled(TextArea)`
  width: 100%;
  height: 60px;
  font-size: 14px;
  padding-right: 75px;
`;

const ColumnNameInput = styled.input`
  padding: 5px 10px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  border: 2px solid ${props => props.theme.color.closer2}99;
  transition: opacity 0.2s;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  outline: none;
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
  ::placeholder {
    opacity: 0.5;
  }
  align-self: start;
  width: 150px;
  grid-area: column-name-input;
`;

const ChatBubble = styled.div`
  display: grid;
  grid-template-columns: 50px 28px 1fr auto 50px;
  grid-template-areas: ${props =>
    props.doesBubbleContainTable
      ? `". profile-img bubble-text bubble-text ."`
      : `". profile-img bubble-text column-name-input ."`};

  background-color: ${props =>
    props.isUserBubble ? "transparent" : props.theme.color.closer0};
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  overflow: auto;

  /* ${props =>
    !props.isUserBubble &&
    !props.doesBubbleContainTable &&
    `cursor: pointer;
    :hover {
      ${ColumnNameInput} {
        opacity: 1;
      }
      background-color: ${props.theme.color.closer1};
    }`}; */
`;

const BubblesContainer = styled.div`
  overflow: auto;
`;

const TextInputPanel = styled.div`
  width: max-content;
  border: 1px solid ${props => props.theme.color.closer1};
  background-color: ${props => props.theme.color.closer0_5};
  padding: 20px;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  height: 100px;
  align-items: start;
  gap: 20px;
`;

const TextInputContainer = styled.div`
  position: relative;
`;

const SmallButton = styled(Button)`
  padding: 2px;
  grid-template-columns: auto;
  gap: 0;
`;

const ProfileImg = styled.img`
  height: 28px;
  border-radius: 5px;
  grid-area: profile-img;
`;

const BubbleTextContainer = styled.div`
  padding-top: 4px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.5;
  grid-area: bubble-text;
  white-space: pre-wrap;
`;

const BubbleRichContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const getContentWithLinkIfNeeded = mdRawTextContent => {
  const mdTextContent = mdRawTextContent?.replaceAll("\n", "");

  const linkTextAndUrl = mdTextContent?.match(/\[(.*?)\]\((.*?)\)/);
  if (linkTextAndUrl?.length !== 3) {
    return mdRawTextContent;
  }

  const linkText = linkTextAndUrl?.[1];
  const linkUrl = linkTextAndUrl?.[2];

  const linkTextStartIndex = linkTextAndUrl?.index;
  const linkUrlEndIndex = linkTextStartIndex + linkTextAndUrl?.[0]?.length;

  return (
    <>
      {mdTextContent?.slice(0, linkTextStartIndex - 1)}
      &nbsp;
      <Link to={linkUrl || ""}>{linkText}</Link>
      {mdTextContent?.slice(linkUrlEndIndex + 1)}
    </>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

const TooltipContainer = styled.div`
  width: 100%;
  z-index: 10;
  overflow: hidden;
  background-color: ${props => props.theme.color.closer0};
  color: ${props => props.theme.color.closest};
  height: ${props => (props.isExpanded ? "auto" : 0)};
`;

const PopoverOption = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  text-decoration: none;
  position: relative;
  color: ${props => props.theme.color.closer2};
  border: 1px solid ${props => props.theme.color.closer1};
  border-top: none;
  background-color: ${props =>
    props.isHighlighed
      ? props.theme.color.closer1
      : props.theme.color.furthest};
  white-space: nowrap;
`;

const RecommendationOverlay = styled.div`
  position: absolute;
  color: ${props => props.theme.color.closer2};
  top: 2px;
  left: 2px;
  padding: 10px 15px;
  pointer-events: none;
  line-height: normal;
  padding-right: 75px;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;

  height: 60px;
  overflow: hidden;
`;

const PressTabIcon = styled.div`
  padding: 4px;
  font-size: 14px;
  border-radius: 5px;
  position: absolute;
  top: 12px;
  right: 17px;
  border: 0.5px solid ${props => props.theme.color.closer2};
  color: ${props => props.theme.color.closer2};
  display: flex;
  align-items: center;
  gap: 2px;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;

  svg {
    height: 14px;
    width: 14px;
    opacity: 0.9;
  }
`;

const InvisibleSpan = styled.span`
  opacity: 0;
`;

const SearchResultsPopover = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const outerContainerRef = useRef(null);

  useClickOutside(outerContainerRef, () => setIsExpanded(false));

  useEffect(() => {
    setIsExpanded(!!children);
  }, [children]);

  return (
    <OuterContainer ref={outerContainerRef}>
      <TooltipContainer isExpanded={isExpanded}>{children}</TooltipContainer>
    </OuterContainer>
  );
};

const BubbleContent = ({ textContent }) => {
  const parsedContent = parseJson(textContent);

  if (typeof parsedContent === "string") {
    return getContentWithLinkIfNeeded(parsedContent);
  }

  return (
    <BubbleRichContent>
      {parsedContent?.map((result, i) => {
        if (result?.textContent) {
          return <div>{getContentWithLinkIfNeeded(result?.textContent)}</div>;
        }
        if (result?.tableRecordsContent) {
          return (
            <ResultsTableSimple
              key={i}
              records={result?.tableRecordsContent}
              columnNames={result?.tableColumns}
              tableRowNames={result?.tableRowNames}
            />
          );
        }

        return <div>{JSON.stringify(result)}</div>;
      })}
    </BubbleRichContent>
  );
};

// get a string difference
// e.g. "what" and "whatever" => "ever"
const getStringDifference = (str1, str2) => {
  const str1Length = str1?.length;
  const str2Length = str2?.length;

  let i = 0;
  while (i < str1Length && i < str2Length && str1[i] === str2[i]) {
    i++;
  }

  return str2?.slice(i);
};

const ConversationChat = ({ fileId, fileName }) => {
  const navigate = useNavigate();

  const [conversation, setConversation] = useState({
    name: `Table from ${fileName || fileId}`,
    fileIds: [fileId],
    chatBubbles: [],
  });
  const [loggedInUserAvatarSrc, setLoggedInUserAvatarSrc] = useState(null);
  const [userInputString, setUserInputString] = useState("");
  const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false);
  const [isSavingTable, setIsSavingTable] = useState(false);
  const [error, setError] = useState(null);
  const [recommendationStr, setRecommendationStr] = useState("");

  const [recoTimeoutId, setRecoTimeoutId] = useState(null);

  const bubblesContainerRef = useRef(null);

  useEffect(() => {
    if (!bubblesContainerRef?.current) {
      return;
    }
    bubblesContainerRef.current.scrollTop =
      bubblesContainerRef.current.scrollHeight;
  }, [conversation?.chatBubbles?.length]);

  const doPopulateRecommendation = async () => {
    clearTimeout(recoTimeoutId);
    if (!userInputString || userInputString?.length > 85) {
      setRecommendationStr("");
      return;
    }

    setRecommendationStr("");
    const timeoutId = setTimeout(async () => {
      const { data, error } = await getChatPromptRecommendation({
        fileId,
        userInputString,
      });
      if (error) {
        setRecommendationStr("");
        return;
      }
      setRecommendationStr(data);
    }, 1000);
    setRecoTimeoutId(timeoutId);
  };

  useEffect(() => {
    doPopulateLoggedInUserAvatarSrc();
  }, [fileId]);

  useEffect(() => {
    doPopulateRecommendation();
  }, [userInputString]);

  const doPopulateLoggedInUserAvatarSrc = async () => {
    const avatarSrc = await getLoggedInUserProfileAvatarSrc();
    setLoggedInUserAvatarSrc(avatarSrc);
  };

  const addChatBubblesForUserInput = async () => {
    setIsWaitingForAnswer(true);
    const chatBubblesSoFar = [
      ...conversation?.chatBubbles,
      { author: "USER", textContent: userInputString },
    ];
    const chatBubblesWithLoading = [
      ...chatBubblesSoFar,
      { textContent: "..." },
    ];
    setConversation({ ...conversation, chatBubbles: chatBubblesWithLoading });

    const { data: resultBubble } = await postConversationChat({
      ...conversation,
      chatBubbles: chatBubblesSoFar,
    });

    const chatBubblesWithResult = [
      ...chatBubblesWithLoading?.slice(0, -1),
      resultBubble,
    ];
    setConversation({ ...conversation, chatBubbles: chatBubblesWithResult });
    setUserInputString("");
    setIsWaitingForAnswer(false);
  };

  const postConversationAndTableConfig = async () => {
    setIsSavingTable(true);
    const { data: savedConversation, error } = await postConversation(
      conversation
    );
    if (error) {
      setIsSavingTable(false);
      setError(error);
      return;
    }
    const { data: tableConfig, error: createTableErr } =
      await postTableConfigFromConversation(savedConversation?.id);
    setIsSavingTable(false);
    if (createTableErr) {
      setError(createTableErr);
      return;
    }
    navigate(`/table-configs/${tableConfig?.id}/results`);
  };

  let popoverContent = null;
  // if (recommendationStr && userInputString) {
  //   popoverContent = (
  //     <PopoverOption>
  //       {recommendationStr}
  //       <div>(press Tab)</div>
  //     </PopoverOption>
  //   );
  // }

  return (
    <Container>
      <BubblesContainer ref={bubblesContainerRef}>
        {conversation?.chatBubbles?.map((bubble, i) => {
          const isUserBubble = bubble?.author === "USER";

          let doesBubbleContainTable = false;
          if (Array.isArray(parseJson(bubble?.textContent))) {
            doesBubbleContainTable = parseJson(bubble?.textContent)?.find(
              result => !!result?.tableRecordsContent
            );
          }

          return (
            <ChatBubble
              isUserBubble={isUserBubble}
              doesBubbleContainTable={doesBubbleContainTable}
              key={i}
            >
              <ProfileImg
                src={
                  bubble?.author === "USER"
                    ? loggedInUserAvatarSrc
                    : "/images/avatar-placeholder.jpeg"
                }
              />
              <BubbleTextContainer>
                <BubbleContent textContent={bubble?.textContent} />
              </BubbleTextContainer>
              <ColumnNameInput
                placeholder="Column name"
                disabled={isUserBubble || doesBubbleContainTable}
                isVisible={!!bubble?.bubbleName}
                value={bubble?.bubbleName}
                onChange={e => {
                  const newBubbleName = e.target.value;

                  let newChatBubble = { ...bubble, bubbleName: newBubbleName };
                  if (!newBubbleName) {
                    delete newChatBubble.bubbleName;
                  }

                  const newChatBubbles = [...conversation?.chatBubbles];
                  newChatBubbles[i] = newChatBubble;
                  setConversation({
                    ...conversation,
                    chatBubbles: newChatBubbles,
                  });
                }}
              />
            </ChatBubble>
          );
        })}
      </BubblesContainer>

      <TextInputPanel>
        {/* <SmallButton isDisabled={isSavingTable} value="Save to table" onClick={postConversationAndTableConfig} /> */}
        <TextInputContainer>
          <StyledTextInput
            maxLength={90}
            isDisabled={isWaitingForAnswer}
            value={userInputString}
            onNewInput={newVal => setUserInputString(newVal)}
            onKeyDown={e => {
              e.stopPropagation();
              if (e.key === "Tab") {
                e.preventDefault();
                if (recommendationStr) {
                  setUserInputString(recommendationStr);
                }
              }
              if (e.key === "Enter") {
                addChatBubblesForUserInput();
              }
            }}
          />
          <RecommendationOverlay
            isVisible={
              !!recommendationStr && recommendationStr !== userInputString
            }
          >
            <InvisibleSpan>{userInputString}</InvisibleSpan>
            {getStringDifference(userInputString, recommendationStr)}
          </RecommendationOverlay>
          <PressTabIcon
            isVisible={
              !!recommendationStr && recommendationStr !== userInputString
            }
          >
            Tab
            <KeyboardTab />
          </PressTabIcon>
          <SearchResultsPopover>{popoverContent}</SearchResultsPopover>
        </TextInputContainer>
        {error && <div>{JSON.stringify(error)}</div>}
      </TextInputPanel>
    </Container>
  );
};

export default ConversationChat;
