import {
  patchUpdatePage,
  postPageMetas,
  postPages,
} from "api/services/dataTrainingAuditService";
import DocumentViewAudit from "components/DocumentViewAudit";
import ErrorMessageModal from "components/ErrorMessageModal";
import { CenteredWithTopNavLayoutWide } from "components/Layout";
import { TickIcon } from "components/ui/Icons";
import useSearchParamsState from "hooks/useSearchParamsState";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const CenteredWithTopNavLayoutWideStyled = styled(CenteredWithTopNavLayoutWide)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  gap: 10px;
`;

const Sidebar = styled.div`
  position: relative;
  width: 310px;
  border-right: 1px solid ${props => props.theme.color.closer1};
  height: calc(100vh - 61px);
  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr;
`;

const ScrollContent = styled.div`
  height: 100%;
  overflow: auto;
`;

const MetaItem = styled.div`
  font-size: 12px;
  padding: 8px 2px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  ${props =>
    props.isHighlighted &&
    `
    background-color: #eee;
    color: blue;
    `}
`;

const MetaData = styled.div`
  height: 150px;
  overflow: auto;
  white-space: pre-wrap;
`;

const PageTopBar = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto 1fr;
  gap: 10px;
  height: 30px;
  align-items: center;
  padding: 0 10px;
`;

const StyledTickIcon = styled(TickIcon)`
  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const PREFIX = "boltzflow_training_data/staging/results/";

const BOX_SOURCE_FIELDS = [
  // "annotations_2",
  // "annotations_3",
  "userAnnotations",
  "table_annotations",
  // "ocrWordBoxes",
];

const TABLE_BOX_OPTIONS = ["label: table", "label: row", "label: col"];
const LABEL_TO_COLOR = {
  title: "#009933",
  table: "#9650ff",
  text: "#0191ff",
  list: "#5c3129",
  infograph: "#ff7f0e",
  image: "#df5d00",
  sidebar: "#00eeff",
  note: "#bcbd22",
  "-": "#aec7e8",
};
const LABELS = Object.keys(LABEL_TO_COLOR);

const AuditPage = () => {
  const [filterValue, setFilterValue] = useState("");
  const [pageMetas, setPageMetas] = useState([]);
  const [selectedMetaName, setSelectedMetaName] = useSearchParamsState({
    paramName: "metaName",
    initialValue: "",
  });
  const [pages, setPages] = useState([]);
  const [boxesSourceField, setBoxesSourceField] = useState(
    BOX_SOURCE_FIELDS[0]
  );
  const [tableBoxFilter, setTableBoxFilter] = useState(TABLE_BOX_OPTIONS[0]);
  const [boxes, setBoxes] = useState([]);
  const [isPatching, setIsPatching] = useState(false);
  const [error, setError] = useState(null);
  const [selectedBoxId, setSelectedBoxId] = useState(null);
  const [paintLabel, setPaintLabel] = useState(LABELS[0]);

  useEffect(() => {
    doPopulatePageMetas();
  }, []);

  useEffect(() => {
    const onKeyDown = async e => {
      if (e.key === "Backspace") {
        const newBoxes = boxes?.filter(box => box?.id !== selectedBoxId);
        setBoxes(newBoxes);
      }
      if (LABELS?.[parseInt(e?.key)]) {
        const newPaintLabel = LABELS?.[parseInt(e?.key) - 1];
        setPaintLabel(newPaintLabel);

        if (selectedBoxId !== null) {
          const newBoxes = boxes?.map(box => {
            if (box?.id === selectedBoxId) {
              return { ...box, label: newPaintLabel };
            }
            return box;
          });
          setBoxes(newBoxes);
        }
      }
      if (e.key === "ArrowDown") {
        e.preventDefault();
        if (e.shiftKey) {
          await doPatchBoxes();
        }
        const selectedMetaIndex = pageMetas?.findIndex(
          pageMeta => pageMeta?.name === selectedMetaName
        );
        const newSelectedMetaIndex = selectedMetaIndex + 1;
        setSelectedMetaName(pageMetas?.[newSelectedMetaIndex]?.name);
      }
      if (e.key === "ArrowUp") {
        e.preventDefault();
        if (e.shiftKey) {
          await doPatchBoxes();
        }
        const selectedMetaIndex = pageMetas?.findIndex(
          pageMeta => pageMeta?.name === selectedMetaName
        );
        const newSelectedMetaIndex = selectedMetaIndex - 1;
        setSelectedMetaName(pageMetas?.[newSelectedMetaIndex]?.name);
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, [
    JSON.stringify(boxes),
    JSON.stringify(pageMetas),
    selectedBoxId,
    paintLabel,
    selectedMetaName,
  ]);

  useEffect(() => {
    doPopulatePages();
  }, [selectedMetaName]);

  useEffect(() => {
    const selectedPage = pages?.[0];
    let newBoxes = selectedPage?.[boxesSourceField];
    if (boxesSourceField === "table_annotations") {
      if (tableBoxFilter === "label: row") {
        newBoxes = newBoxes
          ?.map(box => box?.items?.filter(item => item?.label === "row"))
          ?.flat();
      }
      if (tableBoxFilter === "label: col") {
        newBoxes = newBoxes
          ?.map(box => box?.items?.filter(item => item?.label === "col"))
          ?.flat();
      }
    }

    setBoxes(newBoxes);
  }, [boxesSourceField, tableBoxFilter, JSON.stringify(pages)]);

  const doPopulatePageMetas = async () => {
    const { data } = await postPageMetas();
    setPageMetas(data);
  };

  const doPopulatePages = async () => {
    const { data } = await postPages({}, [selectedMetaName]);
    setPages(data);
  };

  const doVerifyPage = async isChecked => {
    setIsPatching(true);
    const patchBody = {
      page: selectedMetaName,
      metaPatch: {
        isVerified: isChecked,
      },
    };

    const { error } = await patchUpdatePage({}, patchBody);
    setError(error);
    setIsPatching(false);
    doPopulatePageMetas();
  };

  const doPatchBoxes = async () => {
    setIsPatching(true);
    const patchBody = {
      page: selectedMetaName,
      pagePatch: {
        [boxesSourceField]: boxes,
      },
      metaPatch: {
        isVerified: true,
      },
    };

    const { error } = await patchUpdatePage({}, patchBody);
    setError(error);
    setIsPatching(false);
    doPopulatePages();
    doPopulatePageMetas();
  };

  const selectedMeta = pageMetas?.find(
    pageMeta => pageMeta?.name === selectedMetaName
  );
  const isVerified = selectedMeta?.metadata?.isVerified === "True";

  const selectedPage = pages?.[0];
  const haveBoxesBeenEdited = !isEqual(boxes, selectedPage?.[boxesSourceField]);

  return (
    <CenteredWithTopNavLayoutWideStyled sideGapWidth={"0"}>
      <Sidebar>
        <TopContent>
          {PREFIX}
          <input
            value={filterValue}
            onChange={e => setFilterValue(e.target.value)}
            placeholder="Filter"
          />
          <MetaData>
            {selectedMeta && JSON.stringify(selectedMeta?.metadata, null, " ")}
          </MetaData>
        </TopContent>
        <ScrollContent>
          {pageMetas
            ?.filter(pageMeta => {
              return JSON.stringify(pageMeta, null, " ")
                ?.toLowerCase()
                ?.includes(filterValue.toLowerCase());
            })
            ?.map(pageMeta => (
              <MetaItem
                isHighlighted={selectedMetaName === pageMeta?.name}
                onClick={() => setSelectedMetaName(pageMeta?.name)}
                style={{
                  color:
                    pageMeta?.metadata?.isVerified === "True"
                      ? "#009933"
                      : "#a68200",
                }}
              >
                {pageMeta?.name?.replaceAll(PREFIX, "")}
              </MetaItem>
            ))}
        </ScrollContent>
      </Sidebar>

      <div>
        <PageTopBar>
          <StyledTickIcon
            onClick={() => {
              if (isVerified) {
                doVerifyPage(false);
                return;
              }
              doVerifyPage(true);
            }}
            isDisabled={isPatching}
            style={{
              fill: isVerified ? "#009933" : "#d4a600",
            }}
          />
          <button onClick={doPatchBoxes} disabled={isPatching}>
            {haveBoxesBeenEdited && "*"} Save
          </button>
          <div>
            boxes source
            <select
              value={boxesSourceField}
              onChange={e => setBoxesSourceField(e.target.value)}
            >
              {BOX_SOURCE_FIELDS?.map(fieldName => (
                <option>{fieldName}</option>
              ))}
            </select>
          </div>
          <div>
            {boxesSourceField === "table_annotations" && (
              <select
                value={tableBoxFilter}
                onChange={e => setTableBoxFilter(e.target.value)}
              >
                {TABLE_BOX_OPTIONS?.map(option => (
                  <option>{option}</option>
                ))}
              </select>
            )}
          </div>
          <div
            style={{
              justifySelf: "end",
            }}
          >
            <span>paint label</span>
            <select
              style={{ backgroundColor: `${LABEL_TO_COLOR[paintLabel]}99` }}
              value={paintLabel}
              onChange={e => setPaintLabel(e.target.value)}
            >
              {LABELS?.map(label => (
                <option>{label}</option>
              ))}
            </select>
          </div>
        </PageTopBar>
        <DocumentViewAudit
          pageBase64Img={selectedPage?.image}
          pageAnnotations={boxes}
          selectedOutputItemId={selectedBoxId}
          onClickOutputBox={id => {
            if (id === "") {
              return;
            }
            const newBoxes = boxes?.map(box => {
              if (box?.id === id) {
                return { ...box, label: paintLabel };
              }
              return box;
            });
            setBoxes(newBoxes);
            setSelectedBoxId(id);
          }}
          onMouseUpAfterResizingAnnotationAtXAndY={(x, y, w, h, id) => {
            if (id === "") {
              return;
            }
            const newBoxes = boxes?.map(box => {
              if (box?.id === id) {
                return { ...box, x, y, w, h };
              }
              return box;
            });
            setBoxes(newBoxes);
          }}
          onNewAnnotationBox={({ x, y, w, h }) => {
            const id = uuidv4();
            setBoxes([...boxes, { x, y, w, h, id, label: paintLabel }]);
            setSelectedBoxId(id);
          }}
        />
      </div>
      {error && (
        <ErrorMessageModal
          message={error?.message || JSON.stringify(error)}
          onDismiss={() => setError(null)}
        />
      )}
    </CenteredWithTopNavLayoutWideStyled>
  );
};

export default AuditPage;
