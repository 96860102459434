import useClickOutside from "hooks/useClickOutside";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";

const Tip = styled.div`
  background-color: ${props => props.theme.color.furthest};
  z-index: 10001;
  visibility: hidden;
  border-radius: 10px;
  /* box-shadow: ${props => props.theme.shadow}; */
`;

const TooltipClick = ({
  style = {},
  tipStyle = {},
  className = "",
  children,
  tipContent = "Tip Content",
  position = "bottomRight",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  const tipRef = useRef(null);
  const rect = ref.current?.getBoundingClientRect();
  const tipRect = tipRef.current?.getBoundingClientRect();

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref} className={className} style={style}>
      <div
        style={{ width: "max-content" }}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(prev => !prev);
        }}
      >
        {children}
      </div>
      <Tip
        ref={tipRef}
        style={{
          position: "fixed",
          top: rect?.top + rect?.height + 4 ?? 0,
          left:
            position === "bottom"
              ? rect?.right - tipRect?.width
              : rect?.right ?? 0,
          visibility: isOpen ? "visible" : "hidden",
          ...tipStyle,
          backgroundColor: "white",
        }}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(false);
        }}
      >
        {tipContent}
      </Tip>
    </div>
  );
};

export default TooltipClick;
