import { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { clamp, cloneDeep, get, range, set } from "lodash";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import {
  getTemplateDashboardConfig,
  patchDashboardConfig,
  patchSigma,
  postDashboardConfigCalculateResults,
  postGenerateComponent,
} from "api/services/chatService";
import {
  ClipboardIcon,
  DownloadCloudIcon,
  EmailIcon,
  FilesIcon,
  Logo,
  MonitorIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  TickIcon,
  TrashIcon,
  UploadCloudIcon,
} from "components/ui/Icons";
import { parseJson, sleep, uuidv4 } from "utils/common";
import RecordsTable from "components/RecordsTable";
import RecordsSummaryChart from "components/RecordsSummaryChart";
import styled from "styled-components";
import {
  getWordDoc,
  patchWordDoc,
  postWordDoc,
} from "api/services/wordDocsService";
import SmallButton from "components/ui/SmallButton";
import { postReRunJobs } from "api/services/jobService";
import NavHeader from "components/NavHeader";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import AutocompleteTextInputDropdown from "components/ui/AutocompleteTextInputDropdown";
import {
  COMPONENT_TYPES,
  NL_TO_DSL,
} from "components/ComponentCodeModalTrigger";
import ErrorMessageModal from "components/ErrorMessageModal";
import {
  ArrowDownward,
  ChevronRight,
  CloudUpload,
  Email,
  Help,
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  QuestionAnswer,
  Send,
  ViewColumn,
} from "@material-ui/icons";
import GridDraggable from "components/GridDraggable";
import {
  postSigmaRerunEmailJobs,
  postSigmaRerunExtractionJobs,
} from "api/services/sigmaService";
import {
  getLoggedInUserName,
  postUpgradeTrialUser,
} from "api/services/authenticationService";
import AddTextFileModal from "components/AddTextFileModal";
import { postUploadForDatabase } from "api/services/filesService";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import {
  getUserProfile,
  postDownloadExcelFileOfTable,
} from "api/services/projectService";
import EmailPasswordModal from "components/EmailPasswordModal";
import FileInput from "components/inputs/FileInput";
import Tooltip from "components/ui/Tooltip";
import TutorialOverlay, {
  startTutorial,
} from "components/widgets/TutorialOverlay";
import RecordsTableTrial from "components/RecordsTableTrial";
import ButtonDark from "components/ui/ButtonDark";
import TooltipClick from "components/ui/TooltipClick";
import TooltipNew from "components/ui/TooltipNew";
import { Input } from "@material-ui/core";
import FileSideBar from "components/FileSideBar";
import LayoutNew from "components/LayoutNew";
import Sequences from "components/Sequences";
import { postSigmasUploadCsv } from "api/backend/chatServiceEndpoints";
import ButtonWord from "components/ui/ButtonWord";
import {
  getPipelineConfig,
  getSigmas,
  putPipelineConfig,
  putSigmas,
} from "api/backend/fileSystemEndpoints";
import SharePipelineModalTrigger from "components/SharePipelineModalTrigger";
import AddSigmaRecordModal from "components/AddSigmaRecordModal";
import { format } from "date-fns";

const doDownloadTable = async (columns, records, name = "") => {
  const tableColumns = columns?.map(column => column?.name);
  const tablePreview = records?.map(record => {
    const newRow = {};
    tableColumns?.forEach(columnName => {
      const val =
        typeof record?.[columnName] === "string"
          ? record?.[columnName]
          : record?.[columnName]?.value || "";
      newRow[columnName] = {
        Value: val,
        RawValue: val,
      };
    });

    return newRow;
  });

  const body = {
    name,
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const TOP_LAYOUT = [".", ".", "."];

const ADD_RECORD_TUTORIAL = {
  firstStepId: "HIGHLIGHT_TABLE",
  steps: {
    HIGHLIGHT_TABLE: [
      {
        elementTutorialId: "table",
        nextStepId: "HIGHLIGHT_COPY_PASTE",
        nextStepTriggerEvent: "click-next",
        message: "I created the table for you.",
        messagePosition: "bottom",
      },
    ],
    HIGHLIGHT_COPY_PASTE: [
      {
        elementTutorialId: "copy-paste-btn",
        nextStepId: "MODIFY_TEXT",
        nextStepTriggerEvent: "click",
        message:
          "Let’s send in some text, to be extracted and populated into the table.",
        messagePosition: "bottom",
      },
    ],
    MODIFY_TEXT: [
      {
        elementTutorialId: "text-area",
        message:
          "A text sample has been loaded. Feel free to modify it or add in new text.",
        nextStepTriggerEvent: "click-next",
        nextStepId: "SEND_TO_MODEL",
        messagePosition: "top",
      },
    ],
    SEND_TO_MODEL: [
      {
        elementTutorialId: "text-area",
      },
      {
        elementTutorialId: "send-btn",
        message: "Now, let’s extract the contents of this text into the table",
        nextStepTriggerEvent: "click",
        nextStepId: "WAIT_FOR_RESULTS",
        messagePosition: "bottom-left",
      },
    ],
    WAIT_FOR_RESULTS: [
      {
        elementTutorialId: "table",
        message: "Please wait while record is processing...",
        messagePosition: "bottom",
      },
    ],
  },
};

const AFTER_ADD_RECORD_TUTORIAL = {
  firstStepId: "HIGHLIGHT_TABLE",
  steps: {
    HIGHLIGHT_TABLE: [
      {
        elementTutorialId: "table",
        nextStepId: "HIGHLIGHT_REFERENCES",
        nextStepTriggerEvent: "click-next",
        message: "Hoorray! The data has been extracted and sorted.",
        messagePosition: "bottom",
      },
    ],
    HIGHLIGHT_REFERENCES: [
      {
        elementTutorialId: "table",
        nextStepId: "HIGHLIGHT_CONNECT_EMAIL",
        nextStepTriggerEvent: "click-next",
        message: "Click on any data point to check its origin and accuracy.",
        messagePosition: "bottom",
      },
    ],
    HIGHLIGHT_CONNECT_EMAIL: [
      {
        elementTutorialId: "email-password",
        message: "Try it on your own document by emailing it in.",
        nextStepTriggerEvent: "click",
        nextStepId: "HIGHLIGHT_SEND_EMAIL",
      },
    ],
  },
};

const AFTER_ADD_RECORD_TUTORIAL_LOGGED_IN = {
  firstStepId: "HIGHLIGHT_TABLE",
  steps: {
    HIGHLIGHT_TABLE: [
      {
        elementTutorialId: "table",
        nextStepTriggerEvent: "click-next",
        message: "Great! The data has been extracted and sorted.",
        messagePosition: "bottom",
      },
    ],
  },
};

const StyledCenteredWithTopNavLayout = styled(CenteredWithTopNavLayout)`
  padding: 0;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  position: relative;
  background-color: #f7f8fc;
  box-shadow: none;
`;

const ComponentGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20px 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  gap: 20px;

  padding-left: 8px;
  padding-right: 48px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: lightgrey;
  :hover {
    fill: ${props => props.theme.color.closest};
  }
`;

const StyledFilesIcon = styled(FilesIcon)`
  cursor: pointer;
  fill: ${props => props.theme.color.closer2};
  opacity: 0;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #0a1e54;

  display: flex;
  align-items: center;
  gap: 8px;

  :hover {
    ${StyledFilesIcon} {
      opacity: 1;
    }
  }
`;

const ShortenedText = styled.span`
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PulsingDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};

  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      background-color: ${props => props.theme.color.primary};
    }

    50% {
      background-color: ${props => props.theme.color.primary}55;
    }

    100% {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;

const TopMessageAndButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0px 48px;
  background-color: #f7f8fc;
  margin-bottom: 8px;
  padding-top: 8px;
  align-items: center;
`;

const LeftContent = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  align-items: center;
  padding-top: 8px;
  justify-content: start;
  grid-auto-flow: column;
  grid-template-columns: auto;
  justify-content: start;
  justify-items: start;
  padding-bottom: 16px;
`;

const RightContent = styled.div`
  display: flex;
  gap: 10px;
`;

const BlueSpan = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
  svg {
    height: 10px;
    width: 16px;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }
`;

const EmptySlot = styled.div`
  border: 2px dashed lightgrey;
  width: 100%;
  height: 200px;
  opacity: 0.5;
`;

const Container = styled.div`
  height: calc(100vh - 40px);
  display: grid;
  grid-template-rows: auto auto 1fr;
  font-weight: 300;
`;

const TopBar = styled.div`
  position: sticky;
  top: 0;
  /* z-index: 199; */

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto auto;
  gap: 12px;
  padding: 12px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 12px;
  padding-top: 8px;

  background-color: transparent;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const RoundPlusIcon = styled(PlusIcon)`
  background-color: ${props => props.theme.color.closer0};
  padding: 2px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

const RoundTrashIcon = styled(TrashIcon)`
  background-color: ${props => props.theme.color.closer0};
  padding: 2px;
  cursor: pointer;
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}

  :hover {
    opacity: 0.6;
  }
`;

const Select = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ChevronContainer = styled.div`
  cursor: pointer;
  ${props => props.isHidden && "opacity: 0; pointer-events: none;"}

  :hover {
    opacity: 0.6;
  }
`;

const TabLinks = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
`;

const TabLinkItem = styled(Link)`
  text-decoration: none;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  font-weight: 600;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props.isSelected ? props.theme.color.primary : props.theme.color.closer1};
`;

const ButtonLabel = styled.label`
  /* border: 2px solid ${props => props.theme.color.closer1}; */
  background-color: ${props => props.theme.color.closest};
  color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }
`;

const AddRecordsPanel = styled.div`
  box-shadow: ${props => props.theme.shadow};
  transition: opacity 0.8s;
  /* border: 2px solid ${props => props.theme.color.primary}; */
`;

const HelpButton = styled.div`
  position: relative;
  display: block;
  text-decoration: none;
  color: ${props =>
    props.$isCurrentRoute
      ? props.theme.color.closest
      : props.theme.color.closer2};
  border-radius: 20px;
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;

const EmailClientBanner = styled.div`
  padding: 8px 12px;
  display: grid;
  align-items: center;
  background-color: ${props => props.theme.color.closer0};
  grid-auto-flow: column;
  justify-content: start;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-weight: 600;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.closer1};
`;

const Recipient = styled.div`
  opacity: 0.5;
  padding: 8px 12px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const GreySpan = styled.span`
  color: ${props => props.theme.color.closer2};
`;

const StyledInput = styled.input`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  padding: 8px 12px;
`;

const StyledTextArea = styled.textarea`
  font-family: "Montserrat", sans-serif;
  resize: none;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
  padding: 8px 12px;
  background-color: white;

  height: 300px;
  padding-bottom: 20px;
`;

const InputArea = styled.div`
  /* border: 1px solid lightgrey; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: start;
  align-self: end;
  /* opacity: ${props => (props.isSendingRecord ? 0.5 : 1)}; */
  pointer-events: ${props => (props.isSendingRecord ? "none" : "all")};
  background-color: white;
  ${props => props.isSendingRecord && "background-color: #eaeaea;"}
  height: ${props => (props.isInputPanelOpen ? "auto" : 0)};
  overflow: hidden;
  z-index: 1000;
`;

const getGridString = (gridList = []) => {
  const grid = gridList
    .map(row => {
      return `". ${row.join(" ")}"`;
    })
    .join("\n");

  return grid;
};

const RESERVED_COLUMNS = ["emailSubject", "receivedAt"];

const getDsl = naturalLanguageCommand => {
  return NL_TO_DSL[naturalLanguageCommand] || naturalLanguageCommand;
};

export const MODEL_SELECT_OPTIONS = [
  {
    label: "Boltzbit LLM v1.1",
    value: "Boltzbit LLM v1.1",
    description: "Our custom LLM, great for everyday tasks.",
  },
  {
    label: "Llama v1.2",
    value: "Llama v1.2",
    description: "Meta's open source model.",
  },
];

const SentText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.theme.color.closer0};
  svg {
    height: 12px;
    width: 12px;
  }
  font-size: 14px;
`;

const FakeEmailNavItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding: 8px 12px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  background-color: ${props =>
    props.isHighlighted ? `${props.theme.color.closer0}88` : "transparent"};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const SendMessageBanner = styled.div`
  line-height: 1.2;
  background-color: ${props => props.theme.color.closer0};
  padding: 8px 12px;
  /* box-shadow: ${props => props.theme.shadow}; */
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-size: 14px;
`;

const CornerIconContainer = styled.div`
  border-radius: 50%;
  box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 22px;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const RecordTableContainer = styled.div`
  overflow: auto;
  margin: 0px 48px;
  height: 100%;
  padding-bottom: 40px;
  background-color: white;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SelectCustom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  width: 150px;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const OptionsContainer = styled.div`
  box-shadow: ${props => props.theme.shadow};
`;

const TickedIcon = styled(TickIcon)`
  width: 12px;
  height: 12px;
  fill: ${props => props.theme.color.primary};
`;

const UntickedCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Option = styled.div`
  line-height: 1.2;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 8px 12px;
  gap: 4px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const SendButtonDark = styled(ButtonDark)`
  padding: 8px 40px;
  z-index: 1202;
`;

const IconButtonContainer = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const TrialDashboardPageSource = () => {
  const { wordDocId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [wordDoc, setWordDoc] = useState({});
  const [dashboardConfig, setDashboardConfig] = useState(null);
  const [sigmas, setSigmas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [naturalLanguageCommand, setNaturalLanguageCommand] = useState("");
  const [message, setMessage] = useState("");

  const [userProfile, setUserProfile] = useState({});

  const [msSinceLoad, setMsSinceLoad] = useState(0);

  const [textBody, setTextBody] = useState("");
  const [textSubject, setTextSubject] = useState("");
  const [isSendingRecord, setIsSendingRecord] = useState(false);

  const [fakeEmails, setFakeEmails] = useState([]);
  const [selectedFakeIndex, setSelectedFakeIndex] = useState(null);
  const [isInputPanelOpen, setIsInputPanelOpen] = useState(false);
  const [haveInitialRecordsBeenLoaded, setHaveInitialRecordsBeenLoaded] =
    useState(false);

  const [selectedModelValue, setSelectedModelValue] = useState(
    MODEL_SELECT_OPTIONS[0]?.value
  );
  const [isWaitingForRecord, setIsWaitingForRecord] = useState(false);
  const [isTutorialInProgress, setIsTutorialInProgress] = useState(false);

  const [selectedSigmaIds, setSelectedSigmaIds] = useState([]);
  const [sliceStart, setSliceStart] = useState(0);

  useEffect(() => {
    doPopulateUserProfile();
    doPopulateTemplateConfig();
    doPopulateSigmas();
    doPopulateWordDoc();

    const intervalId = setInterval(() => {
      doPopulateSigmas();
      doPopulateTemplateConfig();
      setMsSinceLoad(prev => prev + 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [wordDocId]);

  useEffect(() => {
    const doCalculateResultsInBackground = async () => {
      await postDashboardConfigCalculateResults(dashboardConfig?._id);
      await doPopulateTemplateConfig();
    };

    doCalculateResultsInBackground();
  }, [sigmas?.length]);

  const doPopulateUserProfile = async () => {
    const { data } = await getUserProfile();
    setUserProfile(data);
  };

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);

    const exampleInput = parseJson(data?.content?.exampleInput);

    setTextBody(exampleInput?.text);
    setTextSubject(exampleInput?.subject);
  };

  const sourceRecordsColumns = dashboardConfig?.config?.components?.find(
    component => component?.id === "source_records"
  )?.columns;

  useEffect(() => {
    if (!sourceRecordsColumns?.length || !wordDoc?.id) {
      return;
    }

    const blocks = [];
    sourceRecordsColumns
      ?.filter(
        column =>
          !RESERVED_COLUMNS.includes(column?.name) &&
          !column?.isExtractionDisabled
      )
      ?.forEach(column => {
        let newBlock = {
          isQuery: true,
          queryId: uuidv4(),
          text: `extract "${column?.name}"`,
        };
        if (column?.description) {
          newBlock.text = `${newBlock.text}, with description "${column?.description}"`;
        }
        if (column?.action) {
          newBlock.text = `${newBlock.text}, with action "${column?.action}"`;
        }
        if (column?.prediction) {
          newBlock.text = `${newBlock.text}, with prediction "${column?.prediction}"`;
        }
        blocks.push(newBlock);
      });

    const newWordDocBody = {
      content: {
        ...(wordDoc?.content || {}),
        blocks,
      },
    };
    patchWordDoc(wordDocId, {}, newWordDocBody);
  }, [JSON.stringify(sourceRecordsColumns)]);

  useEffect(() => {
    const doStartTutorial = async () => {
      await sleep(1000);
      startTutorial(ADD_RECORD_TUTORIAL);
    };

    const isTutorialDone = localStorage.getItem("isTutorialDone");
    if (isTutorialDone !== "true") {
      doStartTutorial();
    }
  }, []);

  useEffect(() => {
    const doStartTutorial = async () => {
      await sleep(1000);
      startTutorial(ADD_RECORD_TUTORIAL);
    };

    if (searchParams?.get("shouldStartTutorial") === "true") {
      navigate(`?`);
      setIsTutorialInProgress(true);
      doStartTutorial(ADD_RECORD_TUTORIAL);
    }
  }, [searchParams?.get("shouldStartTutorial")]);

  useEffect(() => {
    setIsWaitingForRecord(false);
    if (
      !haveInitialRecordsBeenLoaded ||
      sigmas?.length !== 1 ||
      !isTutorialInProgress
    ) {
      return;
    }

    const doStartSecondTutorial = async () => {
      await sleep(500);
      startTutorial(
        doesUserHaveGeneratedEmail
          ? AFTER_ADD_RECORD_TUTORIAL
          : AFTER_ADD_RECORD_TUTORIAL_LOGGED_IN
      );
    };

    doStartSecondTutorial();
  }, [sigmas?.length]);

  useEffect(() => {
    const checkIsTutorialDone = () => {
      const isTutorialDone = localStorage.getItem("isTutorialDone");
      if (isTutorialDone === "true") {
        setIsTutorialInProgress(false);
      }
    };

    checkIsTutorialDone();
    window.addEventListener("storage", checkIsTutorialDone);
    return () => {
      window.removeEventListener("storage", checkIsTutorialDone);
    };
  }, []);

  const doPopulateSigmas = async () => {
    const { data } = await getSigmas({ pipelineId: wordDocId });

    const records = data || [];
    setSigmas(records);
    setHaveInitialRecordsBeenLoaded(true);
  };

  const doPatchSigmaRecord = async (id, newRecordFields) => {
    const existingRecord = sigmas?.find(record => record?.id === id);
    const updatedRecord = { ...existingRecord, ...newRecordFields };
    await putSigmas(id, {}, updatedRecord);
    await doPopulateSigmas();
    await doCalculateResults();
  };

  const doPopulateTemplateConfig = async () => {
    const { data: dashConfig } = await getPipelineConfig(wordDocId);

    // if (!dashConfig?.config?.layout) {
    //   const { data: initialWordDoc } = await getWordDoc(wordDocId);
    //   const sourceRecordsColumns =
    //     initialWordDoc?.content?.dashboardConfig?.columns;
    //   const newConfig = {
    //     topLayout: TOP_LAYOUT,
    //     layout: [
    //       [".", "."],
    //       [".", "."],
    //       ["source_records", "source_records"],
    //     ],
    //     components: [
    //       {
    //         id: "source_records",
    //         name: "Your Records",
    //         columns: [
    //           // {
    //           //   name: "emailSubject",
    //           // },
    //           // {
    //           //   name: "receivedAt",
    //           // },
    //           ...sourceRecordsColumns,
    //         ],
    //         pythonCode: "print(input())",
    //         records: [],
    //       },
    //     ],
    //   };
    //   const { data: newDashboardConfig } = await patchDashboardConfig(
    //     dashConfig?._id,
    //     {},
    //     {
    //       ...dashConfig,
    //       config: newConfig,
    //     }
    //   );
    //   setDashboardConfig(newDashboardConfig);
    //   setIsLoading(false);
    //   return;
    // }

    setDashboardConfig(dashConfig);
    setIsLoading(false);
  };

  const updateComponent = (id, payload) => {
    const newDashboardConfig = cloneDeep(dashboardConfig);
    newDashboardConfig.config.components =
      newDashboardConfig.config.components.map(component => {
        if (component?.id === id) {
          return {
            ...component,
            ...payload,
          };
        }
        return component;
      });
    setDashboardConfig(newDashboardConfig);

    return newDashboardConfig;
  };

  const doPatchConfig = async payloadConfig => {
    setIsLoading(true);
    const newDashboardConfig = await putPipelineConfig(
      dashboardConfig?.id,
      {},
      payloadConfig
    );
    setDashboardConfig(newDashboardConfig?.data);
    setIsLoading(false);
  };

  const doCalculateResults = async () => {
    setIsLoading(true);
    await postDashboardConfigCalculateResults(dashboardConfig?._id);
    await doPopulateTemplateConfig();
    setIsLoading(false);
  };

  const doRefreshSigmaRecord = async id => {
    const extractionJobId = sigmas?.find(
      sigma => sigma?._id === id
    )?.extractionJobId;

    await postSigmaRerunExtractionJobs(
      {},
      {
        ids: [extractionJobId],
      }
    );
    await doPopulateSigmas();
  };

  const doInsertComponentInBlankSpace = async () => {
    if (!naturalLanguageCommand) {
      return;
    }
    let type = "Table";
    COMPONENT_TYPES?.forEach(componentType => {
      if (
        naturalLanguageCommand
          ?.toLowerCase()
          .includes(componentType?.toLowerCase())
      ) {
        type = componentType;
      }
    });

    const id = uuidv4();
    const cleanNlCommand = naturalLanguageCommand?.replaceAll(
      /, show as .*/g,
      ""
    );
    const newComponent = {
      id,
      name: "New component",
      naturalLanguageCommand: cleanNlCommand,
      type,
      pythonCode: "",
      results: {},
    };

    const { data } = await postGenerateComponent(
      {},
      {
        naturalLanguageCommand: getDsl(cleanNlCommand)?.replaceAll(
          "Received Time",
          "Received At"
        ),
      }
    );

    newComponent.pythonCode = data?.pythonCode;

    const newLayout = cloneDeep(dashboardConfig?.config?.layout);
    let hasBeenPlaced = false;
    newLayout.forEach(newRow => {
      newRow.forEach((newColumn, newColumnIndex) => {
        if (newColumn === "." && !hasBeenPlaced) {
          newRow[newColumnIndex] = id;
          hasBeenPlaced = true;
        }
      });
    });

    if (!hasBeenPlaced) {
      setMessage("No blank space to place component");
      return;
    }

    const newDashboardConfig = cloneDeep(dashboardConfig);
    newDashboardConfig.config.components.push(newComponent);
    newDashboardConfig.config.layout = newLayout;

    setNaturalLanguageCommand("");
    await doPatchConfig(newDashboardConfig);
    await doCalculateResults();
  };

  const component = dashboardConfig?.config?.components?.find(
    component => component?.id === "source_records"
  );

  const doesUserHaveGeneratedEmail =
    userProfile?.email === `${userProfile?.userName}@boltzbit.io`;

  const selectedModel = MODEL_SELECT_OPTIONS.find(
    option => option?.value === selectedModelValue
  );

  const tableRecords = sigmas
    ?.filter(sig => !sig?.isDeleted)
    ?.slice(sliceStart, sliceStart + 14);

  return (
    <LayoutNew>
      <Container>
        <FileSideBar />
        <TopBar>
          <Title>
            <ShortenedText>{dashboardConfig?.name}</ShortenedText>
            <StyledFilesIcon
              height="32px"
              onClick={() => {
                navigator.clipboard.writeText(
                  `Pipeline: ${dashboardConfig?.name}`
                );
              }}
            />
          </Title>
          <ButtonDark
            onClick={() =>
              doDownloadTable(
                component?.columns,
                sigmas?.filter(sig => !sig?.isDeleted),
                dashboardConfig?.name
              )
            }
          >
            <DownloadCloudIcon />
          </ButtonDark>
          {/* <IconButtonContainer
            onClick={() => {
              setIsInputPanelOpen(false);
              setIsTutorialInProgress(true);
              startTutorial(ADD_RECORD_TUTORIAL);
            }}
          >
            <HelpOutline />
          </IconButtonContainer> */}

          {/* <TooltipNew
            isDisabled={doesUserHaveGeneratedEmail}
            tipContent={
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "2px",
                }}
              >
                Choose the model
              </div>
            }
          >
            <TooltipClick
              position="bottom"
              tipContent={
                <OptionsContainer>
                  {MODEL_SELECT_OPTIONS.map(option => (
                    <Option
                      onClick={() => setSelectedModelValue(option?.value)}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {option?.label}
                      </div>
                      {selectedModelValue === option?.value ? (
                        <TickedIcon style={{ gridRow: "span 2" }} />
                      ) : (
                        <UntickedCircle />
                      )}
                      <div>{option?.description}</div>
                    </Option>
                  ))}
                </OptionsContainer>
              }
            >
              <SelectCustom>
                {selectedModel?.label} <KeyboardArrowDown />
              </SelectCustom>
            </TooltipClick>
          </TooltipNew> */}

          {/* <Select>
            <option>Boltzbit LLM v1.1</option>
            <option>Llama v1.2</option>
            <option>ChatGPT v1.2</option>
          </Select> */}

          {/* <JobsTooltipTrigger /> */}
          {/* <StyledNavHeader /> */}
        </TopBar>

        <TopMessageAndButton>
          <LeftContent>
            {/* <ButtonDark
              onClick={() => setIsInputPanelOpen(prev => !prev)}
              dataTutorialId="copy-paste-btn"
            >
              <ClipboardIcon />
              Paste in document
            </ButtonDark> */}
            <ButtonDark>
              <UploadCloudIcon />
              Upload documents
              <input
                style={{
                  width: 0,
                  height: 0,
                }}
                type="file"
                onChange={e => {
                  const file = e.target.files[0];
                  const formDataBody = new FormData();
                  formDataBody.append("file", file);
                  postUploadForDatabase(
                    { pipelineId: wordDocId },
                    formDataBody
                  );
                }}
              />
            </ButtonDark>

            <EmailPasswordModal
              dataTutorialId="email-password"
              // style={{ width: doesUserHaveGeneratedEmail ? "100%" : 0 }}
              onClickDone={async () => {
                doPopulateUserProfile();
                window.location.reload();
              }}
              showEmailInstructions={!doesUserHaveGeneratedEmail}
              userEmail={userProfile?.email}
            />

            <ButtonDark>
              From CSV
              <input
                style={{
                  width: 0,
                  height: 0,
                }}
                type="file"
                onChange={e => {
                  const file = e.target.files[0];
                  const formDataBody = new FormData();
                  formDataBody.append("file", file);
                  postSigmasUploadCsv(wordDocId, {}, formDataBody);
                }}
              />
            </ButtonDark>
            <AddSigmaRecordModal
              trigger={<ButtonWord>Add record</ButtonWord>}
              pipelineConfig={dashboardConfig}
            />
          </LeftContent>

          <RightContent>
            <SharePipelineModalTrigger
              pipelineConfig={dashboardConfig}
              trigger={
                <ButtonWord style={{ height: "32px" }}>Share</ButtonWord>
              }
              pipelineId={wordDocId}
            />
            <Sequences
              columns={component?.columns}
              selectedSigmaIds={selectedSigmaIds}
              pipelineId={wordDocId}
            />
            <ButtonWord
              onClick={async () => {
                const wordDocPayload = {
                  fileName: `Chat on ${format(
                    new Date(),
                    "dd MMM HH:mm"
                  )} (analytics)`,
                  isChat: true,
                  content: {
                    sources: [],
                    blocks: [],
                  },
                };

                const { data, error } = await postWordDoc(
                  { directoryPath: "/working-files" },
                  wordDocPayload
                );
                if (error) {
                  return;
                }

                window.open(
                  `/chat-docs/${data?.id}?pipelineId=${wordDocId}`,
                  "_blank"
                );
              }}
            >
              Analytics
            </ButtonWord>
            {!doesUserHaveGeneratedEmail && (
              <ButtonDark to={`/trial-dashboards/${wordDocId}`}>
                <MonitorIcon />
                Dashboard
              </ButtonDark>
            )}
          </RightContent>
        </TopMessageAndButton>

        <RecordTableContainer data-tutorial-id="table" key={component?.id}>
          <RecordsTableTrial
            selectedSigmaIds={selectedSigmaIds}
            setSelectedSigmaIds={setSelectedSigmaIds}
            isDownloadBtnHidden
            arePromptsVisible
            columns={component?.columns}
            onNewColumns={newColumns => {
              const newDashboardConfig = updateComponent(component?.id, {
                columns: newColumns,
              });
              doPatchConfig(newDashboardConfig);
            }}
            allRecords={sigmas?.filter(sig => !sig?.isDeleted)}
            records={tableRecords}
            onConfirmChangeRecord={async (id, newRecord) => {
              await doPatchSigmaRecord(id, newRecord);
            }}
            onClickRefreshRecord={id => doRefreshSigmaRecord(id)}
            isWaitingForRecord={isWaitingForRecord}
          />
        </RecordTableContainer>
        <div
          style={{
            padding: 20,
            paddingTop: 0,
            display: "flex",
            gap: "10px",
            justifySelf: "end",
          }}
        >
          <ButtonWord
            disabled={sliceStart === 0}
            onClick={() =>
              setSliceStart(prev => clamp(prev - 14, 0, sigmas?.length))
            }
          >
            Prev
          </ButtonWord>
          <ButtonWord
            disabled={sliceStart + 14 >= sigmas?.length}
            onClick={() =>
              setSliceStart(prev => clamp(prev + 14), 14, sigmas?.length)
            }
          >
            Next
          </ButtonWord>
        </div>

        <InputArea
          isSendingRecord={isSendingRecord}
          isInputPanelOpen={isInputPanelOpen}
          data-tutorial-id="text-area"
        >
          <EmailClientBanner
            style={{
              gridColumn: "span 2",
              width: "100%",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                marginRight: "12px",
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <Dot />
              <Dot
                onClick={() => setIsInputPanelOpen(false)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#d4a600",
                }}
              />
              <Dot />
            </div>
          </EmailClientBanner>
          <div
            style={{
              width: "100%",
              borderLeft: "1px solid #e1e1e1",
              position: "relative",
            }}
          >
            {/* <Recipient>
              <GreySpan>To:</GreySpan> yourLLM@boltzbit.io
            </Recipient>
            <StyledInput
              style={{ borderBottom: "1px solid #e1e1e1" }}
              placeholder="Subject"
              value={textSubject}
              onChange={e => setTextSubject(e.target.value)}
            /> */}
            <StyledTextArea
              placeholder="Paste your text here"
              value={textBody}
              onChange={e => setTextBody(e.target.value)}
            />
            <div
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
              }}
            >
              <SendButtonDark
                dataTutorialId="send-btn"
                isDisabled={isSendingRecord}
                variant="highlighted"
                onClick={async () => {
                  setIsSendingRecord(true);
                  const file = new File(
                    [textBody],
                    `${textSubject || "text"}.txt`,
                    {
                      type: "text/plain",
                    }
                  );

                  const formDataBody = new FormData();
                  formDataBody.append("file", file);
                  formDataBody.append("pipelineId", wordDocId);

                  await postUploadForDatabase({}, formDataBody);
                  setIsWaitingForRecord(true);
                  setIsSendingRecord(false);
                  setTextBody("");
                  setTextSubject("");
                  setFakeEmails([
                    ...fakeEmails,
                    { subject: textSubject, text: textBody },
                  ]);
                }}
              >
                Extract
              </SendButtonDark>
            </div>
          </div>
        </InputArea>

        {/* <Gap height="100px" /> */}
        <ErrorMessageModal
          color="#d4a600"
          message={message}
          onDismiss={() => setMessage("")}
        />
      </Container>
      <TutorialOverlay />
    </LayoutNew>
  );
};

export default TrialDashboardPageSource;
