import { useState } from "react";
import styled from "styled-components";
import { Loyalty } from "@material-ui/icons";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import SmallButton from "components/ui/SmallButton";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  display: grid;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const SaveButton = styled(SmallButton)`
  justify-self: end;
  padding: 4px 8px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"};
`;

const StyledLoyalty = styled(Loyalty)`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"};
`;

const AddDocLabelModalTrigger = ({
  isDisabled,
  onPressSave = newLabel => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [labelValue, setLabelValue] = useState("");

  return (
    <>
      <StyledLoyalty isDisabled={isDisabled} onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>Add label</ModalTitle>
          <TextInputSquare
            value={labelValue}
            onNewInput={newValue => setLabelValue(newValue)}
          />
          <SaveButton
            value="Save"
            onClick={() => {
              onPressSave(labelValue);
              setIsOpen(false);
              setLabelValue("");
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDocLabelModalTrigger;
