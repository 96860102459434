import styled from "styled-components";
import { difference, uniq } from "lodash";
import { ErrorOutline } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Tooltip from "components/ui/Tooltip";
import { getArrayWithUpsertedItem, parseJson } from "utils/common";
import FilterTooltip from "components/ui/FilterTooltip";
import { useEffect } from "react";
import { getSmartRecordsV2 } from "api/services/searchService";
import { DataCellContainer } from "components/ui/StyledAttrsContainers";
import TooltipFillFullWidth from "components/ui/TooltipFillFullWidth";

const getAllValuesOfKey = (records, fieldName) => {
  const names = records?.map(rec => rec[fieldName]);
  return uniq(names).sort();
};

const ALTERNATE_COLORS = "columns";

const greyBgroundIfOdd = props => {
  if (ALTERNATE_COLORS === "columns" && props.col % 2 === 0) {
    return `background-color: ${props.theme.color.closer0_5}`;
  }
  return `background-color: ${props.theme.color.furthest}`;
};

const getFilterOptionsForColumn = (targetColName, columnFilters, records) => {
  let excludedRecordIdsUpToColumn = [];
  columnFilters?.forEach(({ colName, excludedRecordIds }) => {
    if (colName === targetColName) {
      return;
    }
    excludedRecordIdsUpToColumn = [...excludedRecordIdsUpToColumn, ...excludedRecordIds];
  });

  const recordsForColumn = records
    ?.filter(rec => rec?.TOPIC === targetColName)
    ?.filter(rec => !excludedRecordIdsUpToColumn.includes(rec?.ID));
  const columnValues = recordsForColumn?.map(rec => parseJson(rec?.Value)?.RawValue);

  return uniq(columnValues).sort();
};

const getRecordIdsMatchingColumnValues = (colName, newAllowedValues, records) => {
  const recordsForColumn = records?.filter(rec => rec?.TOPIC === colName);
  const matchingRecordIds = recordsForColumn
    ?.filter(rec => newAllowedValues.includes(parseJson(rec?.Value)?.RawValue))
    ?.map(rec => rec?.ID);
  return matchingRecordIds;
};

const Table = styled.div`
  display: grid;
  grid-template-rows: repeat(${props => props.numRows}, auto);
  grid-template-columns: repeat(${props => props.numCols}, auto);
  width: max-content;
`;

const ColHeader = styled.div`
  font-weight: 600;
  grid-row: 1;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.closer0};
  ${greyBgroundIfOdd};
  display: flex;
  align-items: center;
  gap: 5px;
`;

const DataCellContainerStyled = styled(DataCellContainer)`
  ${greyBgroundIfOdd};
`;

const DataCellValue = styled.div`
  overflow: hidden;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.closer0};
  cursor: pointer;
  height: 100%;
  ${props => props.isOutlined && `border: 1px solid ${props.theme.color.primary};`};
  background-color: ${props => (props.isRedBackground ? `${props.theme.color.in_progress}22` : "transparent")};

  svg {
    height: 16px;
    width: 16px;
    fill: ${props => props.theme.color.in_progress};
  }

  :hover {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const WarningIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataCell = ({ row, col, cellRecord, outlinedCellValue, onCellClick, isCollapsed }) => {
  const navigate = useNavigate();

  const valueJson = parseJson(cellRecord?.Value);
  const recordValue = valueJson?.Value;

  let tooltipText = valueJson?.tableDocumentLocation?.fileName;
  if (!cellRecord?.Value) {
    tooltipText = "";
  }

  return (
    <DataCellContainerStyled row={row} col={col} numCols={1} isCollapsed={isCollapsed}>
      <TooltipFillFullWidth title={tooltipText}>
        <DataCellValue
          isRedBackground={valueJson?.RawValue === "?"}
          isClickable
          onClick={() => {
            if (valueJson?.ClickUrl) {
              navigate(valueJson?.ClickUrl);
              return;
            }
            onCellClick(recordValue, valueJson, cellRecord?.TOPIC, cellRecord?.SolarId, cellRecord?.ID);
          }}
          isOutlined={!!outlinedCellValue && outlinedCellValue === recordValue}
        >
          {valueJson?.RawValue?.[0] === "?" ? (
            <WarningIconContainer>
              {valueJson?.RawValue?.slice(1)}
              <ErrorOutline />
            </WarningIconContainer>
          ) : (
            valueJson?.RawValue
          )}
        </DataCellValue>
      </TooltipFillFullWidth>
    </DataCellContainerStyled>
  );
};

const ResultsTable = ({
  columnNames,
  className,
  sigmaTableConfigId,
  outlinedCellValue,
  onCellClick = () => {},
  primarySigmaConfigNames,
}) => {
  const [columnName, setColumnName] = useState(null);
  const [columnValue, setColumnValue] = useState(null);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    doPopulateRecords();
  }, [columnName, columnValue]);

  const doPopulateRecords = async () => {
    const { data } = await getSmartRecordsV2({
      sigmaTableConfigId,
      columnName,
      columnValue,
    });
    setRecords(data);
  };

  const rowNames = getAllValuesOfKey(records, "ID");

  return (
    <Table numCols={columnNames?.length + 1} numRows={records?.length} className={className}>
      {columnNames?.map((colName, colNameIndex) => {
        return (
          <>
            <ColHeader key={`${colName}-colheader`} col={colNameIndex + 1}>
              {colName}
              {primarySigmaConfigNames?.includes(colName) && (
                <FilterTooltip
                  isActive={columnName === colName}
                  columnName={colName}
                  sigmaTableId={sigmaTableConfigId}
                  isFirstPrimaryColumn={primarySigmaConfigNames?.[0] === colName}
                  selectedOption={columnName === colName ? columnValue : ""}
                  onSelectOption={newColValue => {
                    if (!newColValue) {
                      setColumnName(null);
                      setColumnValue(null);
                      return;
                    }

                    setColumnName(colName);
                    setColumnValue(newColValue);
                  }}
                />
              )}
            </ColHeader>
          </>
        );
      })}
      {columnNames
        ?.map((colName, colNameIndex) =>
          rowNames.map((rowName, rowNameIndex) => {
            const cellRecord = records?.find(record => record?.ID === rowName && record?.TOPIC === colName);

            return (
              <DataCell
                outlinedCellValue={outlinedCellValue}
                onCellClick={onCellClick}
                key={`${colNameIndex}-${rowNameIndex}`}
                row={rowNameIndex + 3}
                col={colNameIndex + 1}
                cellRecord={cellRecord}
              />
            );
          })
        )
        ?.flat()}
    </Table>
  );
};

export default ResultsTable;
