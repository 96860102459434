import ChatView from "components/ChatView";
import Modal from "components/ui/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 1000px;
  height: 90vh;
  padding: 10px 20px;
  position: relative;
`;

const ChatModal = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isOpen = !!searchParams?.get("initialQuery");

  return (
    <Modal
      open={isOpen}
      handleClose={() => {
        searchParams.delete("initialQuery");
        navigate({ search: searchParams.toString() });
      }}
    >
      <ModalContent>
        <ChatView
          style={{
            height: "100%",
            gridTemplateRows: "1fr 60px",
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default ChatModal;
