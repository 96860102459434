import * as backend from "api/backend/ccServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getSizerConfigs = async () => {
  const { data, error } = await backend.getSizerConfigs();
  return { data, error: getHandlingFromError(error) };
};

export const postSizerFillUp = async params => {
  const { data, error } = await backend.postSizerFillUp(params);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelModelSizerFillUpProgress = async excelModelId => {
  const { data, error } = await backend.getExcelModelSizerFillUpProgress(excelModelId);
  return { data, error: getHandlingFromError(error) };
};

export const getExcelAnnotationsByFileId = async fileId => {
  const { data, error } = await backend.getExcelAnnotationsByFileId(fileId);
  return { data, error: getHandlingFromError(error) };
};

export const postExcelAnnotationForFile = async (fileId, annotation) => {
  const { data, error } = await backend.postExcelAnnotationForFile(fileId, annotation);
  return { data, error: getHandlingFromError(error) };
};

export const deleteExcelAnnotation = async annotationId => {
  const { error } = await backend.deleteExcelAnnotation(annotationId);
  return { error: getHandlingFromError(error) };
};
