import styled from "styled-components";

const Input = styled.input`
  border: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  color: ${props => props.theme.color.closest};
  width: 100%;
  outline: none;
`;

const Container = styled.label`
  display: block;
  cursor: text;

  padding: 10px 15px;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.color.closest};
  font-family: "Montserrat";
  width: 100%;

  border: 2px solid ${props => props.theme.color.closer1_5};
  position: relative;
  display: inline-block;
  height: max-content;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const CornerRect = styled.div`
  width: 2px;
  height: 2px;
  position: absolute;
  background-color: ${props => props.theme.color.primary};
`;

const OuterContainer = styled.div``;

const TitleAbove = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const TextInputSquare = ({
  value = "",
  titleAbove,
  type = "text",
  onNewInput,
  placeholder,
  className,
  isDisabled,
  onBlur = () => {},
  onKeyDown = () => {},
}) => (
  <OuterContainer className={className}>
    {titleAbove && <TitleAbove>{titleAbove}</TitleAbove>}
    <Container isDisabled={isDisabled}>
      <CornerRect style={{ top: "-2px", left: "-2px" }} />
      <CornerRect style={{ top: "-2px", right: "-2px" }} />
      <CornerRect style={{ bottom: "-2px", left: "-2px" }} />
      <CornerRect style={{ bottom: "-2px", right: "-2px" }} />

      <Input
        onBlur={onBlur}
        type={type}
        value={value}
        onChange={e => onNewInput(e.target.value)}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
    </Container>
  </OuterContainer>
);

export default TextInputSquare;
