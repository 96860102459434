import { useState, useEffect } from "react";

export const useIsOverlayVisible = isOpen => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsOverlayVisible(false);
      return;
    }

    const tutorialOverlay = document.querySelector("[data-tutorial-overlay]");
    const isTutorialActive = tutorialOverlay.style.display === "block";

    if (isTutorialActive) {
      setIsOverlayVisible(true);
    }
  }, [isOpen]);

  return isOverlayVisible;
};

const TutorialOverlayModal = ({ isVisible = false }) => {
  return (
    <div
      style={{
        display: isVisible ? "block" : "none",
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "#00000055",
        zIndex: 1,
      }}
    />
  );
};

export default TutorialOverlayModal;
