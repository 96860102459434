import styled from "styled-components";
import { useState } from "react";
import { FilterList } from "@material-ui/icons";
import { useRef } from "react";
import useClickOutside from "hooks/useClickOutside";
import { useEffect } from "react";
import { getSigmaResultMetaValues } from "api/services/sigmaResultMetaService";
import SingleSelect from "components/ui/SingleSelect";

const TriggerButtonContainer = styled.div`
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  width: max-content;

  svg {
    color: ${props => (props.isIconBlue ? props.theme.color.primary : props.theme.color.closest)};
  }
`;

const Container = styled.div`
  position: relative;
`;

const Tip = styled.div`
  position: absolute;
  left: 100%;
  width: max-content;
  background-color: ${props => props.theme.color.furthest};
  z-index: 100;
  box-shadow: 0 8px 24px ${props => (props.theme.name === "light" ? "rgba(140, 149, 159, 0.2)" : "rgba(0, 0, 0, 0.4)")};
  transition: opacity 0.2s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  font-weight: 400;
  padding: 10px;

  display: flex;
  flex-direction: column;
`;

const FilterTooltip = ({
  isActive,
  sigmaTableId,
  columnName,
  selectedOption = "",
  onSelectOption,
  isFirstPrimaryColumn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tipRef = useRef(null);

  useClickOutside(tipRef, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  const [colValues, setColValues] = useState([]);

  useEffect(() => {
    doPopulateColValues();
  }, [sigmaTableId, columnName]);

  const doPopulateColValues = async () => {
    const { data } = await getSigmaResultMetaValues({ sigmaTableId, columnName });
    setColValues(data || []);
    if (isFirstPrimaryColumn) {
      onSelectOption(data?.[0]);
    }
  };

  return (
    <Container>
      <TriggerButtonContainer isIconBlue={isActive} onClick={() => setIsOpen(true)}>
        <FilterList />
      </TriggerButtonContainer>

      {isOpen && (
        <Tip ref={tipRef} isVisible>
          <SingleSelect
            options={[{ value: "", label: "-" }, ...colValues?.map(colValue => ({ value: colValue, label: colValue }))]}
            selectedValue={selectedOption}
            onSelectOption={newValue => onSelectOption(newValue)}
          />
          {/* <Select
            value={selectedOption}
            onChange={e => {
              onSelectOption(e.target.value);
              setIsOpen(false);
            }}
          >
            <option value="">-</option>
            {colValues?.map(colValue => (
              <option>{colValue}</option>
            ))}
          </Select> */}
        </Tip>
      )}
    </Container>
  );
};

export default FilterTooltip;
