import { apiPost } from "api/api-http-methods";
import { zapiGet, zapiPost } from "api/zapi";
import { sleep } from "utils/common";

export const getQuotaConfigsLimit = async params => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/job-service/api/v1/quota-configs`,
    params
  );
  return { data, error };
};

export const postReRunJobs = async (params, body) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/files/rerun-jobs`,
    params,
    body
  );
  return { data, error };
};

export const getJob = async (id, params) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/job-service/api/v1/jobs/${id}`,
    params
  );
  return { data, error };
};

export const getAggregateProgress = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/jobs/aggregate-progress`,
    params,
    body
  );
  return { data, error };
};
