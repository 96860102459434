import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CenteredWithTopNavLayoutNoSideBar, Gap } from "components/Layout";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import { BigTitle } from "components/ui/Text";
import TableActionsDropdown from "components/TableActionsDropdown";
import {
  deleteExcelModelById,
  getExcelModels,
  patchExcelModelSave,
  postExcelModelById,
  postExcelModelDraft,
  postExcelModelUploadFile,
} from "api/services/excelModelsService";
import FileInput from "components/inputs/FileInput";
import CreateSizerModalTrigger from "components/CreateSizerModalTrigger";
import ExcelModelRow from "components/ExcelModelRow";

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr auto;
  align-items: center;

  gap: 20px;
  padding: 12px 20px;
  color: white;
  background: linear-gradient(
    to right,
    ${props => props.theme.color.feature},
    ${props => props.theme.color.primary}
  );

  font-weight: 600;
`;

const PackageListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 668px;
  padding-bottom: 50px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const TitleAndButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 10px;
`;

const SmallFileInput = styled(FileInput)`
  padding: 8px;

  svg {
    fill: ${props => props.theme.color.closer2};
  }
`;

const ExcelViewsPage = () => {
  const navigate = useNavigate();

  const [excelModels, setExcelModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingNewModel, setIsCreatingNewModel] = useState(false);

  const [excelModelIdBeingDeleted, setExcelModelIdBeingDeleted] =
    useState(null);
  const [error, setError] = useState(null);

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  useEffect(() => {
    doPopulateExcelModels();
  }, []);

  const doPopulateExcelModels = async () => {
    const { data } = await getExcelModels();
    setExcelModels(data);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <CenteredWithTopNavLayoutNoSideBar>
        Loading...
      </CenteredWithTopNavLayoutNoSideBar>
    );
  }

  return (
    <CenteredWithTopNavLayoutNoSideBar>
      <TitleAndButton>
        <BigTitle>Excel Views</BigTitle>
        <ButtonCreateNewAsset
          isDisabled={isCreatingNewModel}
          onClick={async () => {
            setIsCreatingNewModel(true);
            const { data: newModel, error } = await postExcelModelDraft();
            setError(error);
            if (!error) {
              navigate(`/excel-views/${newModel?.id}`);
            }
            setIsCreatingNewModel(false);
          }}
        />
        <SmallFileInput
          buttonText=""
          isShowingFileChosenText={false}
          isDisabled={isUploadingFile}
          onFileSelect={async newFile => {
            setIsUploadingFile(true);
            const { data, error: errUpload } = await postExcelModelUploadFile(
              newFile
            );
            if (errUpload) {
              setError(errUpload);
              return;
            }

            navigate(`/excel-views/${data?.id}`);
            setIsUploadingFile(false);
          }}
        />
      </TitleAndButton>
      <Gap />
      <PackageListContainer isDisabled={isCreatingNewModel}>
        <HeaderRow>
          <div>Name</div>
          <div>Created at</div>
          <div>Actions</div>
        </HeaderRow>
        {excelModels?.map(excelModel => (
          <ExcelModelRow
            key={excelModel?.id}
            excelModel={excelModel}
            isDisabled={excelModelIdBeingDeleted === excelModel?.id}
            onClickDelete={async () => {
              setExcelModelIdBeingDeleted(excelModel.id);
              const { error } = await deleteExcelModelById(excelModel.id);
              setError(error);
              if (!error) {
                setExcelModels(excelModels.filter(t => t.id !== excelModel.id));
              }
              setExcelModelIdBeingDeleted(null);
            }}
          />
          // <PackageRow key={excelModel?.id} isDisabled={excelModelIdBeingDeleted === excelModel?.id}>
          //   <Link to={`/excel-views/${excelModel.id}`}>{excelModel.name}</Link>
          //   <div>{excelModel.createdAt}</div>
          //   <div>
          //     <TableActionsDropdown
          // onClickDelete={async () => {
          //   setExcelModelIdBeingDeleted(excelModel.id);
          //   const { error } = await deleteExcelModelById(excelModel.id);
          //   setError(error);
          //   if (!error) {
          //     setExcelModels(excelModels.filter(t => t.id !== excelModel.id));
          //   }
          //   setExcelModelIdBeingDeleted(null);
          // }}
          //     />
          //   </div>
          // </PackageRow>
        ))}
        {error && <div>{JSON.stringify(error)}</div>}
      </PackageListContainer>
    </CenteredWithTopNavLayoutNoSideBar>
  );
};

export default ExcelViewsPage;
