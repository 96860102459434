import * as backend from "api/backend/projectServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getLoggedInUserName } from "api/services/authenticationService";
import { cloneDeep, groupBy } from "lodash";
import { by, parseJson } from "utils/common";

const placeholderUserProfile = {
  image: "/images/avatar-placeholder.jpeg",
};

export const getActiveSubscription = async () => {
  const { data, error } = await backend.getActiveSubscription();
  return { data, error: getHandlingFromError(error) };
};

export const patchSubscription = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchSubscription(id, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const deleteAccountSubscriptionsForceCancel = async id => {
  const { data, error } = await backend.deleteAccountSubscriptionsForceCancel(
    id
  );
  return { data, error: getHandlingFromError(error) };
};

export const postPaymentSession = async (params = {}, body = {}) => {
  const { data, error } = await backend.postPaymentSession(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getUserProfile = async () => {
  const { data, error } = await backend.getUserProfile();
  return { data, error: getHandlingFromError(error) };
};

export const getUserProfileByUserName = async userName => {
  const { data, error } = await backend.getUserProfileByUserName(userName);
  if (error) {
    if (error?.message?.includes("UserProfile not found")) {
      return { data: placeholderUserProfile, error: null };
    }

    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const updateUserProfile = async (params = {}, profileData) => {
  const { data, error } = await backend.updateUserProfile(params, profileData);
  return { data, error: getHandlingFromError(error) };
};

export const postDownloadExcelFileOfTable = async table => {
  const { data, error } = await backend.postDownloadExcelFileOfTable(table);
  return { data, error };
};

export const postDownloadExcelFileOfTableFromPageResults = async table => {
  const newTable = cloneDeep(table);
  newTable.tablePreview = newTable.tablePreview?.map(row => {
    const newRow = { ...row };
    Object.entries(row).forEach(([key, value]) => {
      newRow[key] = JSON.stringify({ RawValue: value, Value: value, Unit: "" });
    });
    return newRow;
  });

  const { data, error } = await backend.postDownloadExcelFileOfTable(newTable);
  return { data, error };
};

export const getTablePreviewFromCells = (cells, idColumnName) => {
  const groupedCellsById = groupBy(cells, cell => cell?.ID);

  const tablePreview = Object.entries(groupedCellsById)
    .map(([id, cellsInRow]) => {
      const row = { [idColumnName]: id };
      cellsInRow?.forEach(cell => {
        const cellValueJson = parseJson(cell?.Value);
        const cleanedCellValueJson = {
          RawValue: cellValueJson?.RawValue,
          Value: cellValueJson?.Value,
          Unit: cellValueJson?.Unit,
        };
        row[cell?.TOPIC] = JSON.stringify(cleanedCellValueJson);
      });
      return row;
    })
    ?.sort(by(idColumnName));

  return tablePreview;
};

export const postDownloadExcelFileOfCells = async ({
  cells,
  tableColumns,
  tableName = "table",
  idColumnName,
}) => {
  const tablePreview = getTablePreviewFromCells(cells, idColumnName);

  const { error } = await postDownloadExcelFileOfTable({
    tableColumns,
    tablePreview,
    tableName,
  });

  return { data: null, error };
};

export const getLoggedInUserProfileAvatarSrc = async () => {
  const userName = getLoggedInUserName();
  if (!userName) {
    return null;
  }
  const { data, error } = await getUserProfileByUserName(userName);
  if (error) {
    return null;
  }

  return data?.image?.includes("/images/")
    ? data?.image
    : `data:image/png;base64,${data?.image}`;
};

export const getCubesFindByKey = async (params = {}) => {
  const { data, error } = await backend.getCubesFindByKey(params);
  return { data, error: getHandlingFromError(error) };
};

export const getPipelineConfigs = async (id = "", params = {}) => {
  const { data, error } = await backend.getPipelineConfigs(id, params);
  return { data, error: getHandlingFromError(error) };
};
